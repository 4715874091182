import { number } from 'prop-types';
import { useTranslation } from 'react-i18next';

import TABS from '../../helpers/constants';

import ProjectsLayout from './ProjectsLayout';

const ProjectsLayoutContainer = ({ index, ...props }) => {
  const { t } = useTranslation();

  const getEmptyText = () => {
    switch (index) {
      case TABS.JOIN_REQUEST: {
        return t('defaultInformation.noJoin');
      }
      default: {
        return t('defaultInformation.noProjects');
      }
    }
  };

  return <ProjectsLayout index={index} emptyText={getEmptyText()} {...props} />;
};

ProjectsLayoutContainer.propTypes = {
  index: number,
  tab: number,
};

ProjectsLayoutContainer.defaultProps = {
  index: undefined,
  tab: undefined,
  handleFetchMore: () => {},
};

export default ProjectsLayoutContainer;
