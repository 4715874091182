import { Box, Typography, makeStyles } from '@material-ui/core';
import { oneOfType, string, number, object } from 'prop-types';

const useStyles = makeStyles({
  interactionsTitlesMobile: {
    '& svg': {
      color: '#fff',
      fontSize: 20,
    },
    '& h6': {
      color: '#fff',
      fontSize: 13,
    },
  },
});

const StatsItem = ({ label, icon: Icon, data }) => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-start" className={classes.interactionsTitlesMobile}>
      <Icon color="primary" />
      <Typography variant="h6" style={{ paddingLeft: '8px' }}>
        {data !== undefined ? data : '--'} {label}
      </Typography>
    </Box>
  );
};

StatsItem.propTypes = {
  data: oneOfType([number, string]),
  icon: object,
  label: string,
};

export default StatsItem;
