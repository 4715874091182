import { gql } from '@apollo/client';

// done
// updated
export const MARK_NOTIFICATION_SEEN = gql`
  mutation markNotificationSeen($notification: NotificationInput!) {
    markNotificationSeen(notification: $notification) {
      description
      status
    }
  }
`;
