import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
// done
// updated
export const INVITE_PROJECT_MEMBER = gql`
  mutation inviteProjectMember($invitations: NewProjectMemberInvitations!) {
    inviteProjectMember(invitations: $invitations) {
      status
      description
    }
  }
`;

// done
// updated
export const CANCEL_PROJECT_MEMBER = gql`
  mutation cancelProjectMember($invitation: CancelProjectMemberInvitation!) {
    cancelProjectMember(invitation: $invitation) {
      status
      description
    }
  }
`;

// done
// updated
export const REMOVE_PROJECT_MEMBER = gql`
  mutation removeProjectMember($invitation: CancelProjectMemberInvitation!) {
    removeProjectMember(invitation: $invitation) {
      status
      description
    }
  }
`;
