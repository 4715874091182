import { useMutation } from '@apollo/client';
import { Typography, Grid, Button, Box, List, ListItem } from '@material-ui/core';
import { object, arrayOf, func, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ACCEPT_PROJECT_INVITATION, REJECT_PROJECT_INVITATION } from 'apollo/mutations/user-invitation-mutation';
import Image from 'components/image/Image';
import { getDisplayName, convertToSlug } from 'helpers/common';
import { useSnackbar } from 'hooks';

import useStyles from './invitationsStyle';

const Invitations = ({ invitations, refetchInvitations, closeInvitations, desktop }) => {
  const classes = useStyles();

  const { t } = useTranslation(['dialog', 'common']);

  const { openSnackbarSuccess, openSnackbarError } = useSnackbar();

  const [acceptInvitation] = useMutation(ACCEPT_PROJECT_INVITATION);
  const [rejectInvitation] = useMutation(REJECT_PROJECT_INVITATION);

  const handleAcceptInvitation = async (event, projectId) => {
    try {
      const acceptInvitationMutation = await acceptInvitation({
        variables: {
          invitation: { projectId },
        },
      });
      const { data } = acceptInvitationMutation;
      if (data) {
        openSnackbarSuccess(t('dialog:openSnackbarSuccess.invitationAccepted'));
      }
      refetchInvitations();
    } catch (errorAcceptInvitation) {
      openSnackbarError('error', errorAcceptInvitation.message);
    }
  };

  const handleRejectInvitation = async (event, projectId) => {
    try {
      const rejectInvitationMutation = await rejectInvitation({
        variables: {
          invitation: { projectId },
        },
      });
      const { data } = rejectInvitationMutation;
      if (data) {
        openSnackbarSuccess(t('dialog:openSnackbarSuccess.invitationRejected'));
      }
      refetchInvitations();
    } catch (errorRejectInvitation) {
      openSnackbarError(errorRejectInvitation.message);
    }
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
        className={classes.header}
        borderBottom={1}
      >
        <Typography variant="h6" color="primary">
          {t('common:invitation.invitations')}
        </Typography>
        {/* <Typography variant="caption" className={classes.link}>
          <Link to={getLinkAllNotifications}>View all</Link>
        </Typography> */}
      </Box>
      <List className={classes.list}>
        {invitations.length === 0 && (
          <Box
            height={desktop ? 50 : 30}
            width={desktop ? 300 : 220}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography className={classes.emptyInvitations}>{t('common:defaultInformation.noInvitation')}</Typography>
          </Box>
        )}
        {invitations.length !== 0 &&
          invitations.map((item, index) => (
            <ListItem className={classes.listItem} key={index}>
              <div>
                <Typography className={classes.normalText}>
                  <a
                    className={classes.owner}
                    onClick={() => closeInvitations()}
                    href={`/users/${item.inviter.id}/${convertToSlug(
                      `${item.inviter.firstName} ${item.inviter.lastName}`,
                    )}/profile`}
                  >
                    {getDisplayName(item.inviter)}
                  </a>{' '}
                  {t('common:invitation.hasInvited')}{' '}
                  <a
                    onClick={() => closeInvitations()}
                    className={classes.projectName}
                    href={`/projects/${item.project.id}/${convertToSlug(item.project.name)}`}
                  >
                    {item.project.name}.{' '}
                  </a>
                  {t('common:invitation.checkItOut')}
                </Typography>
              </div>
              <Grid className={classes.invitationWrapper} container>
                <Grid item xs={4}>
                  <Image alt={item.project.name} src={item.project.assetLink} aspectRatio={1} />
                </Grid>
                <Box
                  component={Grid}
                  item
                  xs={8}
                  container
                  justify="space-between"
                  pl={desktop ? 2 : 1.5}
                  py={desktop ? 1 : 0.5}
                >
                  <Grid item xs={12}>
                    <a
                      className={classes.projectInvitationName}
                      onClick={() => closeInvitations()}
                      href={`/projects/${item.project.id}/${convertToSlug(item.project.name)}`}
                    >
                      {item.project.name}
                    </a>
                    <Typography className={classes.projectInvitationDescription}>{item.project.description}</Typography>
                  </Grid>
                  <Box component={Grid} item xs={12} container alignItems="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      size={desktop ? 'medium' : 'small'}
                      className={classes.button}
                      onClick={(e) => handleAcceptInvitation(e, item.project.id)}
                    >
                      {t('common:button.accept')}
                    </Button>
                    <Button
                      variant="outlined"
                      size={desktop ? 'medium' : 'small'}
                      className={classes.button}
                      onClick={(e) => handleRejectInvitation(e, item.project.id)}
                    >
                      {t('common:button.decline')}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </ListItem>
          ))}
      </List>
    </>
  );
};

Invitations.propTypes = {
  invitations: arrayOf(object).isRequired,
  refetchInvitations: func.isRequired,
  closeInvitations: func,
  desktop: bool.isRequired,
};

Invitations.defaultProps = {
  closeInvitations: undefined,
};

export default Invitations;
