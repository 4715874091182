export default {
  sign: {
    up: {
      password: 'Your password is not correct',
    },
    in: {
      password: 'Your password is not correct',
    },
  },
  project: {
    limitCharacterProjectName: 'The project name should not exceed 50 characters!',
    descriptionPlaceHolder: 'Briefly describe your project with 200 characters or less',
    limitCharacterProjectDes: 'The project description should not exceed 200 characters!',
  },
  dialog: {
    contestName: 'Please enter contest name',
    role: 'Please enter your role',
    company: 'Please enter a company',
  },
};
