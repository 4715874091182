import { Box, Typography, Divider } from '@material-ui/core';
import { object } from 'prop-types';

const renderItem = ({ type, list, sentences }) => {
  switch (type) {
    case 'list':
      return (
        list &&
        list.map((l, i) => {
          if (l.assets) {
            return l.assets.map((a) => (
              <Box key={a} mb={3} maxWidth={300}>
                <img src={a} alt={a} />
              </Box>
            ));
          }
          return (
            <Typography key={i} variant="h6" gutterBottom color="primary">
              {l.text}
            </Typography>
          );
        })
      );
    default:
      return (
        sentences &&
        sentences.map((s, i) => (
          <Typography key={i} variant="body1" gutterBottom component="p" color="textPrimary">
            {s}
          </Typography>
        ))
      );
  }
};

const Paragraph = ({ p }) => (
  <Box my={3}>
    <Typography variant="h5" gutterBottom color="primary">
      {p.title}
    </Typography>
    {renderItem(p)}
    {p.divider && (
      <Box my={2}>
        <Divider />
      </Box>
    )}
  </Box>
);

Paragraph.propTypes = {
  p: object.isRequired,
};

export default Paragraph;
