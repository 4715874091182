import { Grid, Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import FontImage from './components/fontImage.png';
import NotFoundPageBackgroundSVG from './components/NotFoundPageBackgroundSVG';

const useStyles = makeStyles({
  imageStyle: {
    position: 'relative',
    paddingTop: 85,
  },
  paddingYeah: {
    paddingTop: 140,
  },
});

const NotFoundPage = () => {
  const classes = useStyles();
  const { t } = useTranslation('panel');
  return (
    <div style={{ overflow: 'hidden', height: '100%', width: '100%' }}>
      <NotFoundPageBackgroundSVG />
      <Grid container direction="column" justify="center" alignItems="center" className={classes.paddingYeah}>
        <Grid item xs={12}>
          <Typography variant="h2" align="center" color="inherit">
            {t('content.firstLine')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" align="center" color="inherit">
            {t('content.secondLine')}
            <br></br>
            {t('content.thirdLine')}
          </Typography>
        </Grid>
        <img src={FontImage} className={classes.imageStyle} />
      </Grid>
    </div>
  );
};

export default NotFoundPage;
