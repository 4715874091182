import { useMutation } from '@apollo/client';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
} from '@material-ui/core';
import { bool, object, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { DEACTIVATE_PROJECT, REACTIVATE_PROJECT } from 'apollo/mutations/project-mutation';

const DeactivateDialog = ({ open, options, openSnackbarSuccess, openSnackbarError, handleCloseDialog }) => {
  const history = useHistory();
  const location = useLocation();

  const {
    project: { ownerId, id, name },
    isReactivate,
  } = options;

  const [deactivateProject] = useMutation(DEACTIVATE_PROJECT);
  const [reactivateProject] = useMutation(REACTIVATE_PROJECT);

  const { t } = useTranslation(['common', 'dialog']);

  const handleDeactivateProject = async (e) => {
    e.preventDefault();

    try {
      if (isReactivate) {
        const { data } = await reactivateProject({
          variables: { project: { id } },
        });

        if (data) {
          handleCloseDialog();
          openSnackbarSuccess(
            `${t('dialog:openSnackbarSuccess.theProject')} ${data.reactivateProject.name} ${t(
              'dialog:openSnackbarSuccess.isReactivate',
            )}`,
          );
          history.push({
            pathname: location.pathname,
            state: { isRefetchProjects: true },
          });
        }
      } else {
        const { data: dataProjectDeactivated } = await deactivateProject({
          variables: { project: { id } },
        });

        if (dataProjectDeactivated) {
          handleCloseDialog();
          openSnackbarSuccess(
            `${t('dialog:openSnackbarSuccess.theProject')} ${dataProjectDeactivated.deactivateProject.name} ${t(
              'dialog:openSnackbarSuccess.isDeactivate',
            )}`,
          );
          history.push({
            pathname: location.pathname,
            state: { isRefetchProjects: true },
          });
        }
      }
    } catch (error) {
      openSnackbarError(error.message);
    }
  };

  return open ? (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="deactive-dialog-title"
      aria-describedby="deactive-dialog-description"
    >
      <DialogTitle disableTypography id="deactive-dialog-title">
        <Typography color="error" variant="h6">
          {isReactivate ? t('common:common.reactivate') : t('common:common.deactivate')} {t('common:common.project')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="deactive-dialog-description">
          {t('dialog:deactivateDialog.reminder')}{' '}
          {isReactivate ? t('common:common.reactivate') : t('common:common.deactivate')}{' '}
          {t('dialog:deactivateDialog.thisProject')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Typography color="error" variant="h6">
          <Button onClick={handleCloseDialog}>{t('common:button.cancel')}</Button>
          <Button onClick={handleDeactivateProject} color="inherit" autoFocus>
            {isReactivate ? t('common:common.reactivate') : t('common:common.deactivate')}
          </Button>
        </Typography>
      </DialogActions>
    </Dialog>
  ) : null;
};

DeactivateDialog.propTypes = {
  open: bool.isRequired,
  options: object.isRequired,
  openSnackbarError: func.isRequired,
  openSnackbarSuccess: func.isRequired,
  handleCloseDialog: func.isRequired,
};

export default DeactivateDialog;
