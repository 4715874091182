import About from './About';
import img3Dmeo from './images/3Dmeo.png';
import imgDevIoT from './images/DevIoT.png';
import imgElec2PCB from './images/elec2PCB.png';
import imgHTPro from './images/HTPro.svg';
import imgFablab from './images/logo-fablab.png';
import imgMakerStoreVn from './images/MakerStoreVn.png';
import imgSkyPCB from './images/SkyPCB.jpg';

const createMember = (imgPath, name, title) => ({ imgPath, name, title });

const industrialPartners = [
  createMember(imgSkyPCB, 'SkyPCB', [
    `- <span className="saleoff">10% off</span> for your first design, 
    free-of-charge setup fee from your second design\n- 24h PCB manufacturing`,
  ]),
  createMember(imgMakerStoreVn, 'MakerStore', [
    '- <span className="saleoff">10% off</span>',
    '- Educational kits and electronic components',
  ]),
  createMember(imgHTPro, 'HTPro', ['- <span className="saleoff">5% off</span>', '- Electronic components and modules']),
  createMember(img3Dmeo, '3DMeo', ['- <span className="saleoff">10% off</span>', '- 3D printing and laser cutting']),
  createMember(imgElec2PCB, 'Elec2PCB', [
    '- <span className="saleoff">5% off</span>',
    '- SMT service and PCB manufacturing',
  ]),
  createMember(imgFablab, 'Fab Lab Saigon', [
    '- <span className="saleoff">10% off</span>',
    '- Services offered at Fab Lab Saigon',
  ]),
];

const educationalPartners = [
  createMember(imgElec2PCB, 'Elec2PCB', [
    '- <span className="saleoff">25% off</span>',
    '- Programming STM32',
    '- PCB design from basic to professional',
  ]),
  createMember(imgDevIoT, 'DevIoT', ['- ESP32/8266 and IoT']),
];

const text = [
  {
    title: 'Showcase your projects',
    sentences: ['Build your audience from the beginning through series of logs and updates.'],
  },
  {
    title: 'Build your profiles',
    sentences: [
      'Create your interactive portfolio and measure your community impact by simply sharing your first projects on Project Cocoon.',
    ],
  },
  {
    title: 'Collaborate with members',
    sentences: ['Keep track tasks and progress of your projects through our dedicated PMS tools.'],
  },
  {
    title: 'Get community feedback',
    sentences: [
      'Get feedback on your projects from our network of mentors and Project Cocoon community from the early days.',
    ],
  },
  {
    title: 'Find what you need at a much lower cost from our network of industrial partners',
  },
];

export default function AboutContainer() {
  return <About text={text} industrialPartners={industrialPartners} educationalPartners={educationalPartners} />;
}
