import { Grid, Box, AppBar, Toolbar, IconButton, useMediaQuery, makeStyles } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import clsx from 'clsx';
import { bool, number, func, object } from 'prop-types';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import logoH from '@images/logo/logo-h.svg';
import UnauthorizedError from 'screens/error/UnauthorizedError';

import MainContent from './components/contents/MainContent';
import DrawerPanel from './components/drawer/Drawer';
import UserButtons from './components/mobile/buttons/UserButtons';
import NavigationBar from './components/navigation/NavigationBar';

const useStyles = makeStyles((theme) => {
  const borderStyle = `1px solid ${theme.palette.divider}`;
  return {
    fullHeight: {
      height: '100%',
    },
    container: {
      padding: 0,
    },
    content: {
      height: '100%',
      width: 'calc(100% - 75px)',
      flexGrow: 1,
      // padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: '75px',
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      // marginLeft: `${drawerWidth + 75}px`,
    },
    appBar: {
      backgroundColor: theme.palette.common.white,
      color: '#333',
      borderBottom: borderStyle,
      height: 80,
      [theme.breakpoints.down('sm')]: {
        height: 56,
      },
    },
    toolbar: {
      flexGrow: 1,
      flexWrap: 'wrap',
      height: '80px',
      padding: theme.spacing(0, 6),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 2),
        height: 56,
      },
    },
    logoLink: {
      '& img': {
        height: 56,
        [theme.breakpoints.down('sm')]: {
          height: 42,
        },
      },
      '& a': {
        display: 'block',
      },
    },
    link: {
      '&': {
        ...theme.typography.h5,
        color: '#333',
        fontWeight: 'bold',
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1, 2, 1, 0),
      },
    },
  };
});

const Panel = ({
  openDrawer,
  handleDrawerClose,
  navigationTab,
  dashboardTab,
  phaseTab,
  project,
  handleChangeNavigationTab,
  handleChangeDashboardTab,
  handleChangePhaseTab,
  isMember,
  refetchPanel,
  isReload,
  reloadComponent,
  profile,
}) => {
  const classes = useStyles();
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const [openMenuBar, setOpenMenuBar] = useState(true);

  useEffect(() => {
    if (!desktop) {
      setOpenMenuBar(false);
    }
  }, [location.pathname]);

  const handleLogout = () => {
    //
  };

  const renderNavigationBarAndDrawerDesktop = (
    <>
      <NavigationBar
        navigationTab={navigationTab}
        handleChangeNavigationTab={handleChangeNavigationTab}
        project={project}
        desktop={desktop}
      />
      <DrawerPanel
        dashboardTab={dashboardTab}
        handleChangeDashboardTab={handleChangeDashboardTab}
        project={project}
        openDrawer={openDrawer}
        handleDrawerClose={handleDrawerClose}
        profile={profile}
        desktop={desktop}
      />
    </>
  );

  const renderNavigationBarAndDrawerMobile = (
    <>
      <AppBar position="sticky" elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs className={classes.logoLink}>
              <Link to="/">
                <img src={logoH} alt="logo" />
              </Link>
            </Grid>
            <Box component={Grid} item xs textAlign="right">
              <IconButton
                edge="end"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={() => {
                  setOpenMenuBar(!openMenuBar);
                }}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );

  return isMember ? (
    <Grid container direction="row" justify="flex-start" alignItems="flex-start" className={classes.fullHeight}>
      {desktop ? renderNavigationBarAndDrawerDesktop : renderNavigationBarAndDrawerMobile}
      {openMenuBar && !desktop && (
        <Box
          width="100%"
          flexGrow={1}
          borderBottom={1}
          alignSelf="flex-start"
          style={{ backgroundColor: '#fff' }}
          px={2}
        >
          <Grid container alignItems="center">
            <Grid item xs={12} container alignItems="center" justify="flex-start">
              <Grid item className={classes.link}>
                {project.name}
              </Grid>
            </Grid>
            <Grid item xs={12} container alignItems="center" justify="flex-start" spacing={2}>
              <UserButtons
                handleLogout={handleLogout}
                desktop={desktop}
                setOpenMenuBar={setOpenMenuBar}
                dashboardTab={dashboardTab}
                handleChangeDashboardTab={handleChangeDashboardTab}
                project={project}
              />
            </Grid>
          </Grid>
        </Box>
      )}
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
        className={
          desktop &&
          clsx(classes.content, {
            [classes.contentShift]: openDrawer,
          })
        }
      >
        <MainContent
          project={project}
          navigationTab={navigationTab}
          dashboardTab={dashboardTab}
          phaseTab={phaseTab}
          handleChangeDashboardTab={handleChangeDashboardTab}
          handleChangeNavigationTab={handleChangeNavigationTab}
          handleChangePhaseTab={handleChangePhaseTab}
          refetchPanel={refetchPanel}
          isReload={isReload}
          reloadComponent={reloadComponent}
          desktop={desktop}
        />
      </Grid>
    </Grid>
  ) : (
    <UnauthorizedError />
  );
};

Panel.propTypes = {
  openDrawer: bool.isRequired,
  handleDrawerClose: func.isRequired,
  project: object.isRequired,
  dashboardTab: number.isRequired,
  navigationTab: number.isRequired,
  phaseTab: number.isRequired,
  handleChangeNavigationTab: func.isRequired,
  handleChangeDashboardTab: func.isRequired,
  handleChangePhaseTab: func.isRequired,
  isMember: bool.isRequired,
  refetchPanel: func.isRequired,
  isReload: bool,
  reloadComponent: func,
  profile: object,
};

export default Panel;
