import { List, ListItem, ListItemText } from '@material-ui/core';
import { arrayOf, func, string } from 'prop-types';

import verticalTabMenuStyle from './verticalTabMenuStyle';

function VerticalTabMenu({ menuItems, activeTab, setActiveTab }) {
  const classes = verticalTabMenuStyle();

  return (
    <List className={classes.verticalList} component="nav" aria-label="main mailbox folders">
      {menuItems.map((value, index) => (
        <ListItem
          button
          disableRipple
          key={value}
          className={classes.item}
          classes={{
            selected: classes.selectedItem,
          }}
          selected={activeTab === value}
          onClick={() => setActiveTab(menuItems[index])}
          disabled={index > 1}
        >
          <ListItemText
            primaryTypographyProps={{
              className: classes.itemText,
              variant: 'h6',
            }}
            primary={value}
          />
        </ListItem>
      ))}
    </List>
  );
}

VerticalTabMenu.propTypes = {
  menuItems: arrayOf(string).isRequired,
  activeTab: string.isRequired,
  setActiveTab: func.isRequired,
};

export default VerticalTabMenu;
