import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  list: {
    width: '100%',
    maxHeight: 300,
    maxWidth: 600,
    padding: 10,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      padding: 8,
    },
  },
  emptyInvitations: {
    ...theme.typography.h6,
    color: '#c4c4c4',
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
  },
  listItem: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 500,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: theme.spacing(0, 0.5),
      marginBottom: theme.spacing(1),
    },
  },
  owner: {
    ...theme.typography.h4,
    color: '#000',
    fontWeight: '700',
    fontSize: 16,
    lineHeight: '25px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
  },
  normalText: {
    ...theme.typography.h4,
    color: '#000',
    fontWeight: '500',
    fontSize: 16,
    lineHeight: '25px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
      lineHeight: 1,
    },
  },
  projectName: {
    ...theme.typography.h4,
    color: '#4791CE',
    fontWeight: '700',
    fontSize: 16,
    lineHeight: '25px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
  },
  invitationWrapper: {
    backgroundColor: '#F5F5F5',
    marginTop: 10,
    marginBottom: 10,
  },
  projectInvitationName: {
    ...theme.typography.h4,
    color: '#000',
    fontWeight: '700',
    fontSize: 20,
    lineHeight: '25px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  projectInvitationDescription: {
    ...theme.typography.h4,
    fontSize: 16,
    lineHeight: '25px',
    color: '#666666',
    fontWeight: '300',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
  },
  button: {
    borderColor: theme.palette.text.secondary,
    marginRight: 10,
    textTransform: 'none',
    borderRadius: '6px',
    borderWidth: '1px',
    padding: theme.spacing(0.25, 1.5),
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      padding: theme.spacing(0.5, 1),
      marginRight: 8,
    },
  },
  header: {
    '& h6': {
      fontWeight: 700,
    },
    padding: theme.spacing(1, 2),
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      padding: theme.spacing(0.5, 1),
    },
  },
  link: {
    '& a': { color: theme.palette.primary.main },
    '& :hover': {
      color: theme.palette.primary.main,
    },
  },
}));

export default useStyles;
