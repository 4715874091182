import { Grid, useMediaQuery, makeStyles } from '@material-ui/core';
import { object, func, arrayOf, string, bool } from 'prop-types';

import { CocoonHelmet } from 'components';
import { getDisplayName, getImg } from 'helpers/common';
import { PROFILE_TYPES } from 'helpers/constants';

import ProfileDescription from '../components/profile-description/ProfileDescription';
import ProfileHeader from '../components/profile-header';
import ProfileNavigation from '../components/profile-navigation/ProfileNavigation';
import ProfileProjects from '../components/profile-projects';

const useStyles = makeStyles((theme) => ({
  profileDescriptionWrapper: {
    padding: theme.spacing(3, 6),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 0, 1.5),
    },
  },
}));

const UserProfile = ({
  profile,
  refetchProfile,
  tabs,
  mobileTabs,
  currentTab,
  setCurrentTab,
  profileHidden,
  setProfileHidden,
  isArchivedQueryCallback,
}) => {
  const classes = useStyles();
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  return (
    <>
      <CocoonHelmet
        title={getDisplayName(profile)}
        description={profile.description}
        image={getImg(profile.avatarLink)}
      />
      <Grid container>
        <Grid item xs={12}>
          <ProfileHeader
            desktop={desktop}
            profile={profile}
            profileType={PROFILE_TYPES.USER_PROFILE}
            refetchProfile={refetchProfile}
          />
        </Grid>
        <Grid item xs={12}>
          <ProfileNavigation
            desktop={desktop}
            tabs={tabs}
            mobileTabs={mobileTabs}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            profile={profile}
            profileType={PROFILE_TYPES.USER_PROFILE}
            descriptionHidden={profileHidden}
            setDescriptionHidden={setProfileHidden}
            isGuest={!isArchivedQueryCallback}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.profileDescriptionWrapper}>
        {desktop && (
          <Grid item xs={4} hidden={profileHidden}>
            <ProfileDescription desktop={desktop} profile={profile} profileType={PROFILE_TYPES.USER_PROFILE} />
          </Grid>
        )}
        <Grid item xs={profileHidden || !desktop ? 12 : 8}>
          <ProfileProjects
            desktop={desktop}
            currentTab={currentTab}
            profile={profile}
            profileType={PROFILE_TYPES.USER_PROFILE}
            profileHidden={profileHidden}
            setProfileHidden={setProfileHidden}
          />
        </Grid>
      </Grid>
    </>
  );
};

UserProfile.propTypes = {
  profile: object.isRequired,
  refetchProfile: func.isRequired,
  tabs: arrayOf(object).isRequired,
  mobileTabs: arrayOf(object).isRequired,
  currentTab: string.isRequired,
  setCurrentTab: func.isRequired,
  profileHidden: bool.isRequired,
  setProfileHidden: func.isRequired,
  isArchivedQueryCallback: bool.isRequired,
};

export default UserProfile;
