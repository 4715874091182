import { gql } from '@apollo/client';

// done
// updated
export const ADD_TUTORIAL_STEP = gql`
  mutation addProjectTutorialStep($step: NewProjectStepInput!) {
    addProjectTutorialStep(step: $step) {
      id
      stepNumber
      name
    }
  }
`;

// done
// updated
export const EDIT_TUTORIAL_STEP = gql`
  mutation editProjectTutorialStep($step: UpdateProjectStepInput!) {
    editProjectTutorialStep(step: $step) {
      status
      description
    }
  }
`;

// done
// updated
export const DELETE_TUTORIAL_STEP = gql`
  mutation deleteProjectTutorialStep($step: DeleteProjectStepInput!) {
    deleteProjectTutorialStep(step: $step) {
      status
      description
    }
  }
`;

// done
// updated
export const ADD_STEP_SECTION = gql`
  mutation addProjectTutorialSection($section: NewProjectTutorialSectionInput!) {
    addProjectTutorialSection(section: $section) {
      id
      title
      content
    }
  }
`;

// done
// updated
export const EDIT_STEP_SECTION = gql`
  mutation editProjectTutorialSection($section: UpdateProjectTutorialSectionInput!) {
    editProjectTutorialSection(section: $section) {
      status
      description
    }
  }
`;

// done
// updated
export const DELETE_STEP_SECTION = gql`
  mutation deleteProjectTutorialSection($section: DeleteProjectTutorialSectionInput!) {
    deleteProjectTutorialSection(section: $section) {
      status
      description
    }
  }
`;
