import { TextField, Typography, Box, IconButton, ClickAwayListener, makeStyles } from '@material-ui/core';
import { Edit as EditIcon, Check as CheckIcon, Close as CloseIcon } from '@material-ui/icons';
import { shape, string, func, object, bool } from 'prop-types';
import { useState, useEffect } from 'react';

const styles = makeStyles(() => ({
  displayValueWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    width: '80%',
    marginRight: 10,
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
    },
  },
}));

function TextInput({ disabled, name, inputRef, onChange, value, error, save, resetField }) {
  const [isEditing, setIsEditing] = useState(false);
  const classes = styles();

  const hasError = Object.keys(error).length !== 0;
  useEffect(() => {
    if (hasError) {
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }, [hasError]);

  const handleCancel = () => {
    resetField();
    setIsEditing(false);
  };

  if (!isEditing) {
    return (
      <Box className={classes.displayValueWrapper}>
        <Typography variant="body1">{value}</Typography>
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            setIsEditing(true);
          }}
          disabled={disabled}
          variant="contained"
          color="primary"
        >
          <EditIcon color="primary" fontSize="small" />
        </IconButton>
      </Box>
    );
  }
  return (
    <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
      <ClickAwayListener onClickAway={() => setIsEditing(false)}>
        <TextField
          className={classes.wrapper}
          variant="outlined"
          size="small"
          name={name}
          value={value}
          onChange={onChange}
          inputRef={inputRef}
          autoFocus
          error={hasError}
          helperText={hasError ? error.message : ''}
        />
      </ClickAwayListener>
      <IconButton type="submit" onClick={save} disableRipple variant="contained">
        <CheckIcon />
      </IconButton>
      <IconButton disableRipple onClick={handleCancel} variant="contained">
        <CloseIcon />
      </IconButton>
    </Box>
  );
}

TextInput.propTypes = {
  name: string.isRequired,
  value: string,
  disabled: bool.isRequired,
  inputRef: shape({
    current: shape({}),
  }).isRequired,
  onChange: func.isRequired,
  save: func.isRequired,
  resetField: func.isRequired,
  error: object,
};

TextInput.defaultProps = {
  error: {},
};

export default TextInput;
