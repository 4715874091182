import { gql } from '@apollo/client';

// done
// updated
export const ARCHIVE_FILE = gql`
  mutation archiveFile($update: ArchiveFilePayload!) {
    archiveFile(update: $update) {
      status
      description
    }
  }
`;

// done
// updated
export const ADD_UPDATE_ATTACHMENT = gql`
  mutation addUpdateAttachment($data: NewFilesPayload!) {
    addUpdateAttachment(data: $data) {
      id
    }
  }
`;
