const buildTitle = (title) => <title>{title}</title>;

const buildMeta = (name, content) => <meta name={name} content={content} />;

const buildMetaItemProp = (itemProp, content) => <meta itemProp={itemProp} content={content} />;

const buildMetaProperty = (property, content) => <meta property={property} content={content} />;

const buildLink = (rel, href) => <link rel={rel} href={href} />;

export { buildTitle, buildMeta, buildMetaItemProp, buildMetaProperty, buildLink };
