import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
// done
// done
export const GET_LIST_OF_USER = gql`
  query listsByOwnerId($ownerId: ID!, $page: Int, $limit: PaginationLimit) {
    listsByOwnerId(ownerId: $ownerId, page: $page, limit: $limit) {
      id
      name
      projects {
        id
        name
        assetLink
        views
        totalLikes
        owner {
          id
          avatarLink
          firstName
          lastName
        }
      }
    }
  }
`;
