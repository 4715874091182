import { Avatar, Box, Button, Divider, Grid, Typography, makeStyles } from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import { arrayOf, bool, number, object } from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { cookieUserId } from 'helpers/auth';
import { convertToSlug, getDisplayName } from 'helpers/common';

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(1, 0),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      padding: 0,
    },
  },
  nbMembers: {
    color: theme.palette.secondary.main,
  },
  avatar: {
    marginRight: theme.spacing(2.25),
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  button: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(0.5, 4),
  },
  divider: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
    },
  },
  member: {
    padding: theme.spacing(0.5, 0),
    '&:hover': {
      textDecoration: 'underline',
    },
    [theme.breakpoints.down('sm')]: {
      '& *': {
        fontSize: 14,
      },
    },
  },
  text: {
    fontWeight: theme.typography.fontWeightBold,
    color: '#575757',
  },
  owner: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
  },
  btnFluid: {
    color: '#575757',
    borderColor: '#575757',
    borderRadius: '6px',
    borderWidth: '1px',
    width: '100%',
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(1, 3),
    textTransform: 'none',
    '&:hover': {
      background: 'transparent',
      '& *': {
        fontWeight: 'bold',
      },
    },
  },
  placeholder: {
    display: 'inline-block',
  },
  certifiedIcon: {
    color: '#fff',
    backgroundColor: '#FAC405',
    borderRadius: '50%',
    height: '16px',
    width: '16px',
    verticalAlign: 'middle',
    marginLeft: '5px',
  },
}));

const NUMBER_DISPLAY = 6;

const BriefTeam = ({ members: propMembers, ownerId, errorProject, isNewProject, desktop }) => {
  const classes = useStyles();
  const [team, setTeam] = useState(propMembers);

  const { t } = useTranslation();

  const handleSetTeam = (isCollapsed = false) => {
    if (isCollapsed) {
      setTeam(propMembers);
    } else {
      setTeam(_.take(propMembers, NUMBER_DISPLAY));
    }
  };

  useEffect(() => {
    handleSetTeam();
  }, [propMembers]);

  const isCollapsed = propMembers !== null && team.length === NUMBER_DISPLAY;

  const renderMemberName = (_member) => {
    let memberName = getDisplayName(_member);

    if (_member.id === cookieUserId) {
      memberName = 'You';
    }
    return (
      <Typography variant="h6" style={{ color: '#575757' }}>
        {memberName}
        {_member.id === ownerId && <span className={classes.owner}> ({t('common.owner')})</span>}
        {_member.certified && <CheckIcon className={classes.certifiedIcon} />}
      </Typography>
    );
  };

  const renderTeam = (_isNewProject, _team) => {
    if (_isNewProject) {
      return <Box className={classes.text}>{t('defaultInformation.noTeams')}</Box>;
    }

    return (
      <>
        {_.map(_team, (m) => (
          <Grid
            key={m.id}
            container
            alignItems="center"
            className={classes.member}
            component={Link}
            to={`/users/${m.id}/${convertToSlug(`${m.firstName} ${m.lastName}`)}/profile`}
          >
            <Grid item>
              <Avatar src={m.avatarLink} alt={getDisplayName(m)} className={classes.avatar} />
            </Grid>
            <Grid item>{renderMemberName(m)}</Grid>
          </Grid>
        ))}
        {propMembers.length > NUMBER_DISPLAY && (
          <Box textAlign="center" mt={2}>
            <Button
              size="small"
              variant="outlined"
              className={classes.btnFluid}
              onClick={() => handleSetTeam(isCollapsed)}
            >
              {isCollapsed
                ? `${t('common.showAll')} ${propMembers.length} ${t('common.members')}`
                : t('common.collapse')}
            </Button>
          </Box>
        )}
      </>
    );
  };

  return (
    <Grid item xs={12} className={classes.grid}>
      <Typography variant={desktop ? 'h5' : 'h6'} color="textSecondary" gutterBottom>
        {t('common.team')} {!isNewProject && <span className={classes.nbMembers}>({propMembers.length})</span>}
      </Typography>
      <Divider className={classes.divider} />
      {renderTeam(isNewProject, team)}
      {errorProject && (
        <Alert variant="outlined" severity="error">
          {errorProject.message}
        </Alert>
      )}
    </Grid>
  );
};

BriefTeam.propTypes = {
  members: arrayOf(object),
  ownerId: number,
  errorProject: object,
  isNewProject: bool.isRequired,
  desktop: bool.isRequired,
};

BriefTeam.defaultProps = {
  members: null,
  ownerId: undefined,
  errorProject: undefined,
};

export default BriefTeam;
