import _ from 'lodash';
import { useSnackbar as useNotiSnackbar } from 'notistack';

const useSnackbar = () => {
  const { enqueueSnackbar } = useNotiSnackbar();

  const openSnackbarSuccess = (content) => enqueueSnackbar(content, { variant: 'success' });
  const openSnackbarError = (content) => enqueueSnackbar(content, { variant: 'error' });
  const openSnackbarInfo = (content) => enqueueSnackbar(content, { variant: 'info' });
  const openSnackbarWarning = (content) => enqueueSnackbar(content, { variant: 'warning' });

  const openSnackbarMultiple = (items, variant) => {
    _.forOwn(items, (value) => {
      enqueueSnackbar(value.message, { variant });
    });
  };

  const enqueueSnackbarMultipleErrors = (contents) => openSnackbarMultiple(contents, 'error');

  return {
    openSnackbarSuccess,
    openSnackbarError,
    openSnackbarInfo,
    openSnackbarWarning,
    enqueueSnackbarMultipleErrors,
  };
};

export default useSnackbar;
