import { AppBar, Grid, Tabs, makeStyles } from '@material-ui/core';
import { arrayOf, string, number, object, bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CocoonStats } from 'components';

import ProfileTab from '../profile-tab/ProfileTab';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: '#F5F5F5',
    boxShadow: 'none',
    zIndex: 1,
  },
  tabs: {
    height: '80px',
    justifyContent: 'flex-start',
  },
  tab: {
    minWidth: 80,
    margin: theme.spacing(0, 1.5),
  },
  indicator: {
    backgroundColor: '#FAC405',
  },
}));

function tabProps(index) {
  return {
    id: `profile-tab-${index}`,
    'aria-controls': `profile-tabpanel-${index}`,
  };
}

const DesktopNavigation = ({ tabs, activeTab, handleTabSwitch, isGuest, profileType, profile }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <AppBar position="static" className={classes.appBar}>
      <Grid container>
        <Grid item xs={3} />
        <Grid item xs={5}>
          <Tabs
            classes={{ indicator: classes.indicator }}
            value={activeTab}
            aria-label="profile tabs"
            centered
            className={classes.tabs}
          >
            {tabs.map((navigation, index) => {
              if (index === 3) {
                return null;
              }
              return (
                <ProfileTab
                  key={navigation.id}
                  handleTabSwitch={() => handleTabSwitch(navigation.key, index)}
                  active={activeTab === index}
                  title={navigation.title}
                  tabProps={tabProps(navigation.id)}
                />
              );
            })}
            {!isGuest && (
              <ProfileTab
                handleTabSwitch={() => handleTabSwitch('ARCHIVED_PROJECTS', 3)}
                active={activeTab === 3}
                title={t('common.archived')}
                tabProps={tabProps(3)}
              />
            )}
            {/* TODO: uncomment later */}
            {/* <ProfileTab
              handleTabSwitch={() => handleTabSwitch(BOARD, 3)}
              active={activeTab === 3}
              title="Board"
              tabProps={tabProps(3)}
            /> */}
          </Tabs>
        </Grid>
        <Grid item xs={4}>
          <CocoonStats profileType={profileType} desktop {...profile} />
        </Grid>
      </Grid>
    </AppBar>
  );
};

DesktopNavigation.propTypes = {
  tabs: arrayOf(object).isRequired,
  activeTab: number.isRequired,
  handleTabSwitch: func.isRequired,
  isGuest: bool.isRequired,
  profileType: string.isRequired,
  profile: object.isRequired,
};

export default DesktopNavigation;
