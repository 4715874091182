import { Typography, makeStyles } from '@material-ui/core';
import { Description as DescriptionIcon } from '@material-ui/icons';
import { arrayOf, object } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { DETAILS_TYPE, DIALOG } from 'helpers/constants';
import { handleOpenDialogAC3 } from 'reducers/testAC3/cache';

import EmptyField from '../empty-field/EmptyField';

const useStyles = makeStyles((theme) => ({
  contestsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  contestText: {
    ...theme.typography.body1,
    color: '#575757',
    fontWeight: '100',
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
  },
  contestName: {
    ...theme.typography.h1,
    fontWeight: '700',
    color: '#000000',
    lineHeight: '24px',
    fontSize: '16px',
    letterSpacing: '0.5px',
    marginBottom: '3px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
  detailsWrapper: {
    display: 'flex',
    borderRadius: 0,
    right: '5px',
    alignItems: 'center',
    cursor: 'pointer',
  },
  details: {
    ...theme.typography.h4,
    color: '#4791CE',
    fontSize: 14,
  },
}));

const Contests = ({ contests }) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const openContestsDialog = () => {
    const options = {
      contests,
      type: DETAILS_TYPE.CONTESTS,
    };
    handleOpenDialogAC3(DIALOG.VIEW_EXPERIENCE_CONTEST_DETAILS, options);
  };

  return (
    <>
      {!contests || contests.length === 0 ? (
        <EmptyField field="contests" />
      ) : (
        <>
          {contests.map((contest, index) => (
            <div key={index}>
              <div className={classes.contestsWrapper}>
                <Typography className={classes.contestName} variant="body1">
                  {contest.name}
                </Typography>
              </div>
              <Typography className={classes.contestText} variant="body1" paragraph>
                {contest.reward} • {new Date(contest.year).getFullYear()}
              </Typography>
            </div>
          ))}
          <div role="button" onClick={() => openContestsDialog()} className={classes.detailsWrapper}>
            <DescriptionIcon style={{ fill: '#4791CE', marginRight: 5, fontSize: 15 }} />
            <Typography color="primary" className={classes.details}>
              {t('button.viewDetail')}
            </Typography>
          </div>
        </>
      )}
    </>
  );
};

Contests.defaultProps = {
  contests: [],
};

Contests.propTypes = {
  contests: arrayOf(object),
};

export default Contests;
