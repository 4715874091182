import { Typography, Paper, Tabs, Tab, Grid, Box, Button, Collapse, makeStyles, withStyles } from '@material-ui/core';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import { number, func, object, bool } from 'prop-types';
import { useState } from 'react';

import { CRUD_STATUS } from '../../helpers/constants';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  mainFeaturedPost: {
    position: 'relative',
    backgroundColor: '#F5F5F5',
    color: '#989898',
    padding: theme.spacing(0, 9),
    marginBottom: theme.spacing(3),
  },
  labelProgress: {
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
  },
  labelStats: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
  },
  dataProgress: {
    color: theme.palette.success.dark,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
  },
  dataStats: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.black,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
  },
  gridProgress: {
    paddingRight: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
  },
  gridStats: {
    paddingLeft: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  collapse: {
    position: 'absolute',
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.6)',
    marginTop: theme.spacing(-3.5),
    zIndex: 3,
    '& > button': {
      color: '#fff',
      '& > span': {
        justifyContent: 'left',
        fontWeight: 400,
      },
    },
    '& > div': {
      backgroundColor: '#fff',
      border: '1px solid #989898',
    },
  },
}));

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#989898',
    marginRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
    minWidth: '60px',
    minHeight: '80px',
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const a11yProps = (index) => ({
  id: `project-tab-${index}`,
  'aria-controls': `project-tabpanel-${index}`,
});

const HeaderInfo = ({ tab, handleChangeTab, project, projectStats, crudStatus, desktop, isView }) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  const { t } = useTranslation();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const progressData = [
    { label: t('project.completionLabel'), data: project.completion },
    { label: t('project.currentPhaseLabel'), data: project.currentPhase },
  ];

  const statsData = [
    { label: t('common.views'), data: '--' || project.views },
    { label: t('common.likes'), data: projectStats ? projectStats.totalLikes : '--' },
    { label: t('common.saved'), data: project.saved },
    {
      label: t('common.comments'),
      data: projectStats ? projectStats.totalComments : '--',
    },
  ];
  const renderData = (data) => {
    if (data === undefined || data === null) {
      return '--';
    }
    return data;
  };

  const isDisabled = crudStatus === CRUD_STATUS.EDIT || crudStatus === CRUD_STATUS.NEW;

  const renderProgress = (
    <Grid item container xs={desktop ? 3 : 5} className={classes.gridProgress}>
      {_.map(progressData, (p, i) => (
        <Grid key={`${p.data}-${i}`} item xs={desktop ? 6 : 12} container>
          <Grid
            item
            xs={desktop ? 12 : 8}
            component={Typography}
            variant="h6"
            color="textSecondary"
            className={classes.labelProgress}
          >
            {p.label}
          </Grid>
          <Grid
            item
            xs={desktop ? 12 : 4}
            component={Typography}
            variant={desktop ? 'h4' : 'h6'}
            className={classes.dataProgress}
          >
            {renderData(p.data)}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );

  const renderStats = (
    <Grid item container xs className={classes.gridStats}>
      {_.map(statsData, (p, i) => (
        <Grid key={`${p.data}-${i}`} item xs>
          <Typography variant="caption" color="textSecondary" className={classes.labelStats}>
            {p.label}
          </Typography>
          <Typography variant="h6" className={classes.dataStats}>
            {renderData(p.data)}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );

  return desktop ? (
    <Paper square className={classes.mainFeaturedPost}>
      <Grid container alignItems="center">
        <Grid item xs={5}>
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="secondary"
            aria-label="project tabs"
          >
            <StyledTab label={t('common.brief')} {...a11yProps(0)} />
            <StyledTab label={t('common.logsUpper')} disabled={isDisabled} {...a11yProps(1)} />
            {/* <StyledTab label="Gigs" disabled={isDisabled} {...a11yProps(2)} /> */}
            <StyledTab label={t('common.team')} disabled={isDisabled} {...a11yProps(2)} />
            <StyledTab label={t('common.tutorial')} disabled={isDisabled} {...a11yProps(3)} />
            <StyledTab label={t('common.forum')} disabled={isDisabled} {...a11yProps(4)} />
          </Tabs>
        </Grid>
        {renderProgress}
        {renderStats}
      </Grid>
    </Paper>
  ) : (
    <>
      {isView && (
        <Box className={classes.collapse}>
          <Button
            onClick={handleExpandClick}
            size="small"
            fullWidth
            startIcon={expanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          >
            {expanded ? t('button.hide') : t('button.show')} {t('project.progressStats')}
          </Button>
        </Box>
      )}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box component={Grid} item xs={12} p={desktop ? 0 : 1} pb={0} container alignItems="center">
          {renderProgress}
          {renderStats}
        </Box>
      </Collapse>
    </>
  );
};

HeaderInfo.propTypes = {
  tab: number.isRequired,
  crudStatus: number.isRequired,
  handleChangeTab: func.isRequired,
  project: object.isRequired,
  projectStats: object,
  desktop: bool.isRequired,
  isView: bool.isRequired,
};

export default HeaderInfo;
