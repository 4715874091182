import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: '0.5em 0 1em 0',
    backgroundColor: '#989898',
    border: 'solid 0.5px #e6e6e6',
    [theme.breakpoints.down('sm')]: {
      margin: '0.25em 0 0.5em 0',
    },
  },
  editWarning: {
    ...theme.typography.body1,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  editInfoWrapper: {
    marginTop: '15px',
  },
  editInfoTitle: {
    marginTop: '2%',
    ...theme.typography.h1,
    color: '#575757',
    fontSize: '16px',
    fontWeight: '700',
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.h6,
      fontSize: 13,
    },
  },
  editInfoTextField: {
    background: '#ffffff',
    '& .MuiOutlinedInput-root': {
      borderRadius: '6px',
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
    '& input': {
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.1)',
      borderRadius: '6px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
      '& input': {
        padding: theme.spacing(1, 1.5),
      },
      '& p': {
        margin: 0,
      },
    },
  },
  socialMediaWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px',
  },
  socialMediaTitle: {
    ...theme.typography.body1,
    fontSize: '20px',
    color: '#575757',
  },
}));

export default useStyles;
