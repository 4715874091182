import { gql } from '@apollo/client';

// done
// updated
export const ADD_UPDATE = gql`
  mutation createUpdate($update: NewProjectUpdateInput!) {
    createUpdate(update: $update) {
      id
    }
  }
`;

// done
// updated
export const EDIT_UPDATE = gql`
  mutation editUpdate($update: EditProjectUpdateInput!) {
    editUpdate(update: $update) {
      id
    }
  }
`;

// done
// updated
export const DELETE_UPDATE = gql`
  mutation deleteUpdate($update: DeleteProjectUpdateInput!) {
    deleteUpdate(update: $update) {
      description
      status
    }
  }
`;

// done
// updated
export const PUBLISH_UPDATE = gql`
  mutation publishUpdate($update: PublishProjectUpdateInput!) {
    publishUpdate(update: $update) {
      id
      isPublished
    }
  }
`;
