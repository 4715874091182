import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

// INITIAL STATE
const initState = {
  open: false,
  images: [],
  index: 0,
};

// ACTION CREATORS
export const { openLightbox, closeLightbox, changeIndex } = createActions({
  OPEN_LIGHTBOX: (images, index) => ({ images, index }),
  CLOSE_LIGHTBOX: undefined,
  CHANGE_INDEX: (index) => ({ index }),
});

// REDUCERS
export default handleActions(
  {
    [openLightbox](state, { payload: { images, index } }) {
      return { ...state, open: true, images, index };
    },
    [closeLightbox](state) {
      return { ...state, open: false };
    },
    [changeIndex](state, { payload: { index } }) {
      return { ...state, index };
    },
  },
  initState,
);

// SELECTORS
const selectorLightbox = (state) => state.lightbox;

export const getLightboxStore = createSelector([selectorLightbox], (lightbox) => lightbox);
