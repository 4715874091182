import { useLazyQuery } from '@apollo/client';
import _ from 'lodash';
import { object } from 'prop-types';
import { useState, useEffect } from 'react';

import AllProjects from './AllProjects';

const LIMIT = 5;

const AllProjectsContainer = ({ query, ...props }) => {
  const [page, setPage] = useState(1);
  const [isPaging, setIsPaging] = useState(false);
  const [projects, setProjects] = useState(null);
  const [canFetchMore, setCanFetchMore] = useState(true);

  const variables = { isArchived: false, page, limit: 'FIVE' };

  const [getProjects, { data, loading, error }] = useLazyQuery(query, {
    variables,
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (data.projects.length < LIMIT) {
        setCanFetchMore(false);
      }
      setProjects((projects) => {
        if (projects === null) {
          return [...data.projects];
        }
        setIsPaging(false);
        return [...projects, ...data.projects];
      });
    },
  });

  useEffect(() => {
    getProjects();
  }, [page]);

  const handleFetchMore = () => {
    if (canFetchMore && !isPaging) {
      setIsPaging(true);
      setPage((page) => {
        if (page === variables.page) {
          return page + 1;
        } else return page;
      });
    }
  };

  const debounceHandleFetchMore = _.debounce(handleFetchMore, 300);

  return (
    <AllProjects
      projects={projects}
      loading={loading}
      error={error}
      canFetchMore={canFetchMore}
      handleFetchMore={debounceHandleFetchMore}
      {...props}
    />
  );
};

AllProjectsContainer.propTypes = {
  query: object.isRequired,
};

export default AllProjectsContainer;
