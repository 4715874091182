import { gql } from '@apollo/client';

// done
// updated
export const GET_PROJECT_CATEGORIES = gql`
  query projectCategories($page: Int, $limit: PaginationLimit) {
    projectCategories(page: $page, limit: $limit) {
      id
      name
      displayColor
    }
  }
`;
