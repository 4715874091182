/* eslint-disable max-len */
const FlagIconPublished = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.1325 4.31522C8.85662 4.31522 7.80288 3.49999 6.19876 3.49999C5.6046 3.49999 5.07236 3.60274 4.57864 3.78155C4.64707 3.60564 4.67619 3.41729 4.66402 3.22931C4.62183 2.56284 4.06681 2.02826 3.389 2.00112C2.62726 1.9706 2 2.56943 2 3.31249C2 3.75841 2.22612 4.15214 2.57143 4.38933V13.4375C2.57143 13.7482 2.82726 14 3.14286 14H3.52381C3.8394 14 4.09524 13.7482 4.09524 13.4375V11.225C4.76931 10.9422 5.6091 10.7065 6.81988 10.7065C8.09579 10.7065 9.1495 11.5217 10.7536 11.5217C11.9005 11.5217 12.8171 11.1398 13.6704 10.5641C13.8771 10.4247 14 10.1931 14 9.94636V4.2487C14 3.70043 13.4222 3.33769 12.917 3.56864C12.0995 3.94242 11.0967 4.31522 10.1325 4.31522Z"
      fill="white"
    />
  </svg>
);

export default FlagIconPublished;
