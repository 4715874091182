import { Box, Divider, Grid, Button, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { number, string, bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Header, Body } from './components';

const useStyles = makeStyles((theme) => ({
  btnEdit: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
  },
  btnDelete: {
    backgroundColor: 'red',
    color: 'white',
  },
  divider: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  buttonsContainer: {
    marginLeft: theme.spacing(-1),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0),
      marginTop: theme.spacing(1),
      textAlign: 'right',
    },
  },
  button: {
    borderColor: theme.palette.text.secondary,
    padding: theme.spacing(0.25, 1.5),
    marginLeft: theme.spacing(1),
    textTransform: 'none',
    borderRadius: '6px',
    borderWidth: '1px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5, 1),
      fontSize: 12,
    },
  },
}));

const StepSection = ({
  ownerId,
  name,
  content,
  isEditMode,
  isNewSection,
  changeEditMode,
  updateContent,
  updateTitle,
  handleSubmit,
  handleDelete,
  desktop,
}) => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <Box mb={3} key={name}>
      <Header
        ownerId={ownerId}
        sectionName={name}
        isEditMode={isEditMode}
        changeEditMode={changeEditMode}
        updateTitle={updateTitle}
      />
      <Divider className={classes.divider} />
      <Body content={content} isEditMode={isEditMode} updateContent={updateContent} desktop={desktop} />
      {isEditMode && (
        <Grid className={classes.buttonsContainer} item>
          {!isNewSection && (
            <Button className={`${classes.button} ${classes.btnDelete}`} variant="contained" onClick={handleDelete}>
              {t('button.delete')}
            </Button>
          )}
          <Button className={classes.button} variant="contained" onClick={changeEditMode}>
            {t('button.cancel')}
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            disabled={_.isEmpty(name)}
            onClick={handleSubmit}
          >
            {t('button.save')}
          </Button>
        </Grid>
      )}
    </Box>
  );
};

StepSection.propTypes = {
  changeEditMode: func,
  content: string,
  desktop: bool,
  handleDelete: func,
  handleSubmit: func,
  isEditMode: bool,
  isNewSection: bool,
  name: string,
  ownerId: number,
  updateContent: func,
  updateTitle: func,
};

export default StepSection;
