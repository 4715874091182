import { Grid } from '@material-ui/core';

import { PROJECT_TYPE } from 'helpers/constants';

import LayoutDefault from './components/default/LayoutDefault';
import LayoutFeatured from './components/featured/LayoutFeatured';
import layoutPropTypes from './layoutPropTypes';

const { FEAT_TYPE, SUBS_TYPE, DISC_TYPE, WANT_TYPE, LOGS_TYPE } = PROJECT_TYPE;

const ProjectsLayout = ({ type, ...props }) => (
  <Grid container spacing={2}>
    {type === FEAT_TYPE && <LayoutFeatured type={type} {...props} />}
    {type === DISC_TYPE && <LayoutDefault type={type} isBig {...props} />}
    {[SUBS_TYPE, WANT_TYPE, LOGS_TYPE].includes(type) && <LayoutDefault type={type} {...props} />}
  </Grid>
);

ProjectsLayout.propTypes = layoutPropTypes;

export default ProjectsLayout;
