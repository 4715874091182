import { object, string } from 'yup';

export const contactDefaultValues = {
  fullName: '',
  email: '',
  text: '',
};

export const contactSchemaValidation = (t) =>
  object({
    fullName: string().label(t('common:contactYup.fullName')).required(),
    email: string().label(t('common:contactYup.emailLabel')).email(t('common:contactYup.emailError')).required(),
    text: string()
      .label(t('common:contactYup.labelText'))
      .required()
      .min(10, t('common:contactYup.textMin'))
      .max(1000, t('common:contactYup.textMax')),
  });
