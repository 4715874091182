import { Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { object, node, string, bool } from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    height: '100%',
  },
  rootMobileLandscape: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  media: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > *': {
      maxHeight: '100%',
    },
  },
  mediaMobile: {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  mediaMobileLandscape: {},
  mediaBackground: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    height: 'calc(100% - 66px)',
    textAlign: 'center',
  },
  mediaBackgroundMobile: {
    height: 'calc(100% - 241px)',
  },
  mediaBackgroundMobileLandscape: {
    height: '100%',
    flex: '1 1',
    alignSelf: 'stretch',
  },
  text: {
    textAlign: 'center',
    maxWidth: '80%',
    margin: '0 auto',
    paddingTop: 15,
  },
  textMobile: {
    paddingTop: 30,
    '& $title': {
      marginBottom: 8,
    },
  },
  textMobileLandscape: {
    minWidth: 300,
    maxWidth: 'calc(50% - 48px)',
    padding: '24px 24px 128px',
    flex: '0 1',
    alignSelf: 'center',
    textAlign: 'left',
    margin: 0,
  },
  title: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    marginBottom: 12,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: theme.palette.common.white,
  },
  subtitle: {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '18px',
    margin: 0,
    color: theme.palette.common.white,
  },
}));

const Slide = ({ media, mediaBackgroundStyle, subtitle, title, mobile, landscape, ...other }) => {
  const classes = useStyles();
  const mobileLandscape = mobile && landscape;

  return (
    <div
      className={clsx(classes.root, {
        [classes.rootMobile]: mobile,
        [classes.rootMobileLandscape]: mobileLandscape,
      })}
      {...other}
    >
      <div
        className={clsx(classes.mediaBackground, {
          [classes.mediaBackgroundMobile]: mobile,
          [classes.mediaBackgroundMobileLandscape]: mobileLandscape,
        })}
        style={mediaBackgroundStyle}
      >
        <div
          className={clsx(classes.media, {
            [classes.mediaMobile]: mobile,
            [classes.mediaMobileLandscape]: mobileLandscape,
          })}
        >
          {media}
        </div>
      </div>
      {(title || subtitle) && (
        <div
          className={clsx(classes.text, {
            [classes.textMobile]: mobile,
            [classes.textMobileLandscape]: mobileLandscape,
          })}
        >
          {title && <Typography className={classes.title}>{title}</Typography>}
          {subtitle && <Typography className={classes.subtitle}>{subtitle}</Typography>}
        </div>
      )}
    </div>
  );
};

Slide.propTypes = {
  type: string,
  media: node.isRequired,
  mediaBackgroundStyle: object,
  subtitle: string,
  title: string,
  mobile: bool,
  landscape: bool,
};

Slide.defaultProps = {
  type: 'image',
  mediaBackgroundStyle: {},
  subtitle: undefined,
  title: undefined,
  mobile: false,
  landscape: false,
};

export default Slide;
