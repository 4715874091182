import _ from 'lodash';
import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

// INITIAL STATE
const initState = {
  currentTopic: null,
  topics: [],
};

// ACTION CREATORS
export const {
  setTopics,
  addTopic,
  addTopics,
  selectTopic,
  setTopicComments,
  addComment,
  addComments,
  addReply,
  addReplies,
  updateTotalComments,
} = createActions({
  SET_TOPICS: (topics) => ({ topics }),
  ADD_TOPIC: (topic) => ({ topic }),
  ADD_TOPICS: (topics) => ({ topics }),
  SELECT_TOPIC: (topic) => ({ topic }),
  SET_TOPIC_COMMENTS: (comments) => ({ comments }),
  ADD_COMMENT: (comment) => ({ comment }),
  ADD_COMMENTS: (comments) => ({ comments }),
  ADD_REPLY: (commentId, reply) => ({ commentId, reply }),
  ADD_REPLIES: (commentId, replies) => ({ commentId, replies }),
  UPDATE_TOTAL_COMMENTS: (topicId) => ({ topicId }),
});

// REDUCERS
export default handleActions(
  {
    [setTopics](state, { payload: { topics } }) {
      return { ...state, topics };
    },
    [addTopic](state, { payload: { topic } }) {
      return { ...state, topics: [topic, ...state.topics] };
    },
    [addTopics](state, { payload: { topics } }) {
      return { ...state, topics: [...state.topics, ...topics] };
    },
    [selectTopic](state, { payload: { topic } }) {
      return { ...state, currentTopic: topic };
    },
    [setTopicComments](state, { payload: { comments } }) {
      return {
        ...state,
        currentTopic: { ...state.currentTopic, comments: comments },
      };
    },
    [addComment](state, { payload: { comment } }) {
      return {
        ...state,
        currentTopic: {
          ...state.currentTopic,
          comments: [comment, ...state.currentTopic.comments],
        },
      };
    },
    [addComments](state, { payload: { comments } }) {
      return {
        ...state,
        currentTopic: {
          ...state.currentTopic,
          comments: [...state.currentTopic.comments, ...comments],
        },
      };
    },
    [addReply](state, { payload: { commentId, reply } }) {
      var tmpComments = [...state.currentTopic.comments];
      const currentCommentIndex = _.findIndex(tmpComments, ({ id }) => +id === +commentId);
      var currentComment = { ...tmpComments[currentCommentIndex] };

      currentComment.replies = [reply, ...currentComment.replies];

      tmpComments[currentCommentIndex] = currentComment;

      return {
        ...state,
        currentTopic: { ...state.currentTopic, comments: tmpComments },
      };
    },
    [addReplies](state, { payload: { commentId, replies } }) {
      var tmpComments = [...state.currentTopic.comments];
      const currentCommentIndex = _.findIndex(tmpComments, ({ id }) => +id === +commentId);
      var currentComment = { ...tmpComments[currentCommentIndex] };

      currentComment.replies = _.unionWith([...currentComment.replies, ...replies], (a, b) => +a.id === +b.id);

      tmpComments[currentCommentIndex] = currentComment;

      return {
        ...state,
        currentTopic: { ...state.currentTopic, comments: tmpComments },
      };
    },
    [updateTotalComments](state, { payload: { topicId } }) {
      var tmpTopics = [...state.topics];
      const selectedTopicIndex = _.findIndex(tmpTopics, { id: +topicId });
      var selectedTopic = { ...tmpTopics[selectedTopicIndex] };

      selectedTopic.totalComments++;

      tmpTopics[selectedTopicIndex] = selectedTopic;

      return { ...state, topics: tmpTopics };
    },
  },
  initState,
);

// SELECTORS
const selectorTopics = (state) => state.topics;

export const getTopics = createSelector([selectorTopics], (topics) => topics.topics);
export const getCurrentTopic = createSelector([selectorTopics], (topics) => topics.currentTopic);
