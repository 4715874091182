import { Grid, Typography, IconButton, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import {
  BookmarkBorder as BookmarkBorderIcon,
  Clear as ClearIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import { arrayOf, object, bool } from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CocoonProjectWithOverlay } from 'components';
import { DIALOG } from 'helpers/constants';
import { handleOpenDialogAC3 } from 'reducers/testAC3/cache';

import ListProjectPopover from './list-project-popover/ListProjectPopover';
import useStyles from './listsWithProjectsStyle';

const ListsWithProjects = ({ desktop, listsWithProjects }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [currentIndex, setCurrentIndex] = useState(-1);

  const handleMouseOver = (index) => {
    setCurrentIndex(index);
  };

  const handleOpenRemoveDialog = (e, project, list) => {
    e.preventDefault();
    const options = {
      project,
      list,
    };
    handleOpenDialogAC3(DIALOG.REMOVE_PROJECT_FROM_LIST, options);
  };

  if (!listsWithProjects || listsWithProjects.length === 0) {
    return (
      <div className={classes.centered}>
        <Typography className={classes.emptyProjects}>{t('defaultInformation.noList')}</Typography>
      </div>
    );
  }

  return (
    <div className={classes.accordionWrapper}>
      {listsWithProjects.map((list) => (
        <Accordion className={classes.accordion} square key={list.id}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <div className={classes.accordionSummaryContent}>
              <div className={classes.listTitle}>
                <BookmarkBorderIcon className={classes.saveIcon} />
                <Typography className={classes.heading}>
                  {list.name}
                  {` (${list.projects && list.projects.length})`}
                </Typography>
              </div>

              <ListProjectPopover list={list} />
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {list.projects && list.projects.length === 0 ? (
              <div className={classes.centered}>
                <Typography className={classes.noProject}>{t('defaultInformation.noProjectShow')}</Typography>
              </div>
            ) : (
              <Grid container spacing={1}>
                {list.projects &&
                  list.projects.map((project, index) => (
                    <Grid
                      key={project.id}
                      onFocus={() => handleMouseOver(index)}
                      onMouseOver={() => handleMouseOver(index)}
                      item
                      xs={desktop ? 4 : 6}
                      className={classes.project}
                    >
                      <IconButton
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                        onClick={(e) => handleOpenRemoveDialog(e, project, list)}
                        className={classes.removeIconWrapper}
                      >
                        <ClearIcon style={{ fill: '#ffffff' }} />
                      </IconButton>
                      <CocoonProjectWithOverlay
                        project={project}
                        isFromList
                        isHovered={currentIndex === index}
                        desktop={desktop}
                      />
                    </Grid>
                  ))}
              </Grid>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

ListsWithProjects.propTypes = {
  listsWithProjects: arrayOf(object).isRequired,
  desktop: bool.isRequired,
};

export default ListsWithProjects;
