import { gql } from '@apollo/client';

const FRAGMENTS = gql`
  fragment mainFields on ProjectUpdate {
    id
    name
    publishedDate
    description
    isMilestone
    createdAt
    isPublished
  }
`;

// done
// updated
export const GET_UPDATES_BY_PROJECT_ID = gql`
  query updatesByProjectId(
    $projectId: ID!
    $isPublished: Boolean
    $page: Int!
    $limit: PaginationLimit!
    $tagName: String
  ) {
    logs: updatesByProjectId(
      projectId: $projectId
      isPublished: $isPublished
      page: $page
      limit: $limit
      tagName: $tagName
    ) {
      ...mainFields
    }
  }
  ${FRAGMENTS}
`;

// done
// updated
export const GET_UPDATE_ATTACHMENT_PRESIGNED_URL = gql`
  query updateAttachmentPresignedUrl($contentType: String!, $projectId: ID!, $updateId: ID!, $fileName: String!) {
    updateAttachmentPresignedUrl(
      contentType: $contentType
      projectId: $projectId
      updateId: $updateId
      fileName: $fileName
    ) {
      url
      key
    }
  }
`;
