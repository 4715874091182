import { Grid, Typography, makeStyles } from '@material-ui/core';
import { arrayOf, object } from 'prop-types';

import { CocoonAvatar } from 'components';

const useStyles = makeStyles(() => ({
  memberItemWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
}));

const Members = ({ members }) => {
  const fakeSrc = 'https://res.cloudinary.com/cookiemaster/image/upload/v1618818200/Ellipse_2.png';
  const classes = useStyles();

  return (
    <>
      {members.map((member) => (
        <Grid key={member.id} container direction="row" style={{ marginBottom: 10 }}>
          <Grid className={classes.memberItemWrapper} item xs={2}>
            <CocoonAvatar src={fakeSrc} alt={member.email} />
          </Grid>
          <Grid className={classes.memberItemWrapper} item xs={10}>
            <Typography variant="h6">{member.email}</Typography>
          </Grid>
        </Grid>
      ))}
    </>
  );
};

Members.propTypes = {
  members: arrayOf(object),
};

Members.defaultProps = {
  members: [],
};

export default Members;
