import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  overlayContainer: {
    backgroundColor: 'rgba(0,0,0,0.3)',
    borderRadius: '6px',
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
  },
  regularStatsRow: {
    position: 'absolute',
    top: 20,
    left: 10,
    zIndex: 10,
  },
  smallStatsRow: {
    position: 'absolute',
    top: 20,
    left: 10,
    zIndex: 10,
  },
  projectStatsColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginLeft: '10px',
    marginRight: '10px',
  },
  regularStatsIcon: {
    fill: '#ffffff',
    fontSize: '1.8em',
  },
  smallStatsIcon: {
    fill: '#ffffff',
    fontSize: '1.5em',
  },
  projectStats: {
    top: '5%',
    color: '#ffffff',
    fontSize: '1.2em',
    fontWeight: 'bold',
  },
  projectPopoverColumn: {
    position: 'absolute',
    top: '5px',
    right: '30px',
  },
  regularTitleRow: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    padding: 20,
    zIndex: 10,
  },
  smallTitleRow: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    padding: 15,
    zIndex: 10,
  },
  regularTitle: {
    ...theme.typography.h4,
    fontWeight: 900,
    color: '#ffffff',
    marginBottom: '3%',
    fontSize: '24px',
    lineHeight: '24px',
  },
  smallTitle: {
    ...theme.typography.h5,
    fontWeight: 'bold',
    color: '#ffffff',
    marginBottom: '3%',
  },
  authorRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(1, 0, 0),
  },
  regularAvatar: {
    height: 36,
    width: 36,
  },
  smallAvatar: {
    height: 26,
    width: 26,
  },
  author: {
    ...theme.typography.body1,
    color: '#ffffff',
    fontWeight: 'bold',
    marginLeft: 5,
  },
}));

export default useStyles;
