import { makeStyles, withStyles, Grid, Typography, Tabs, Tab } from '@material-ui/core';
import { number, func } from 'prop-types';

import TaskSvgActive from '../../helpers/icons/TaskSvgActive.js';
import TaskSvgDeactive from '../../helpers/icons/TaskSvgDeactive.js';
import TimelineSvgActive from '../../helpers/icons/TimelineSvgActive.js';
import TimelineSvgDeactive from '../../helpers/icons/TimelineSvgDeactive.js';
import TreeSvgActive from '../../helpers/icons/TreeSvgActive.js';
import TreeSvgDeactive from '../../helpers/icons/TreeSvgDeactive.js';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  selectionPanel: {
    // margin: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: '100%',
    marginLeft: 0,
  },
  margin: {
    // margin: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  labelStyle: {
    ...theme.typography.h5,
    letterSpacing: '0.1px',
    color: '#333333',
  },
}));

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: '#fff',
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);
const StyledTab = withStyles((theme) => ({
  root: {
    ...theme.typography.h6,
    textTransform: 'none',
    color: '#575757',
    display: 'flex',
    justifyContent: 'flex-start',
    width: '323px',
    maxWidth: 'none',
    opacity: 1,
    '&[aria-selected="true"]': {
      opacity: 1,
      backgroundColor: '#F5F5F5',
      width: '323px',
    },
  },
  wrapper: {
    display: 'inline-flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '323px',
    flexDirection: 'row',
    '& > *:first-child': {
      marginTop: '6px',
      marginRight: theme.spacing(1),
      // width:'100%',
    },
  },
  labelIcon: {
    minHeight: 0,
    padding: 0,
    paddingLeft: theme.spacing(2),
  },
}))((props) => <Tab disableRipple {...props} />);

const a11yProps = (index) => ({
  id: `project-tab-${index}`,
  'aria-controls': `project-tabpanel-${index}`,
});

const DashBoard = ({ dashboardTab, handleChangeDashboardTab }) => {
  const classes = useStyles();

  const firstArr = [];

  const { t } = useTranslation('panel');

  const DrawerLabel = [
    {
      label: t('dashboardDrawer.label'),
      subLabel: t('dashboardDrawer.subLabel'),
      first: t('dashboardDrawer.first'),
      second: t('dashboardDrawer.second'),
      third: t('dashboardDrawer.third'),
    },
    {
      label: t('groupDrawer.label'),
      subLabel: t('groupDrawer.subLabel'),
      first: t('groupDrawer.first'),
      second: t('groupDrawer.second'),
      third: t('groupDrawer.third'),
    },
    {
      label: t('memberDrawer.label'),
      subLabel: t('memberDrawer.subLabel'),
    },
  ];

  Object.keys(DrawerLabel[0]).forEach(function (key) {
    if (key !== 'label' && key !== 'subLabel') firstArr.push(DrawerLabel[0][key]);
  });

  return (
    <div style={{ width: 323 }}>
      <Grid className={classes.margin}>
        <Typography variant="h5" className={classes.labelStyle} gutterBottom>
          {DrawerLabel[0].label}
        </Typography>
      </Grid>
      <Grid className={classes.selectionPanel}>
        <StyledTabs
          orientation="vertical"
          value={dashboardTab}
          onChange={handleChangeDashboardTab}
          indicatorColor="secondary"
          aria-label="dashboard tabs"
        >
          {firstArr.map((element, index) =>
            index === 0 ? (
              <StyledTab
                key={index}
                icon={
                  dashboardTab === 0 ? (
                    <TreeSvgActive style={{ marginBottom: 0 }} />
                  ) : (
                    <TreeSvgDeactive style={{ marginBottom: 0 }} />
                  )
                }
                label={element}
                {...a11yProps(index)}
              />
            ) : index === 1 ? (
              <StyledTab
                key={index}
                icon={
                  dashboardTab === 1 ? (
                    <TaskSvgActive style={{ marginBottom: 0 }} />
                  ) : (
                    <TaskSvgDeactive style={{ marginBottom: 0 }} />
                  )
                }
                label={element}
                {...a11yProps(index)}
              />
            ) : (
              <StyledTab
                key={index}
                icon={
                  dashboardTab === 2 ? (
                    <TimelineSvgActive style={{ marginBottom: 0 }} />
                  ) : (
                    <TimelineSvgDeactive style={{ marginBottom: 0 }} />
                  )
                }
                label={element}
                {...a11yProps(index)}
              />
            ),
          )}
        </StyledTabs>
      </Grid>
    </div>
  );
};

DashBoard.propTypes = {
  dashboardTab: number.isRequired,
  handleChangeDashboardTab: func.isRequired,
};

export default DashBoard;
