import { Box, CircularProgress, Grid } from '@material-ui/core';
import _ from 'lodash';
import { arrayOf, object, func, number, bool } from 'prop-types';
import { createRef } from 'react';
import PullToRefresh from 'react-simple-pull-to-refresh';

import { withError, withLoading, withEmpty } from 'hocs';

import withNewProject from '../../../../hocs/withNewProject';

import LogDetails from './components/details/LogDetails';

const LogsRightPanel = (props) => {
  const { items, logIdsSelected, handleFetchMore, canFetchMore, desktop } = props;

  const refs = items.reduce((acc, value) => {
    acc[+value.id] = createRef();
    return acc;
  }, {});

  const handleClickLog = (id) => {
    window.scrollTo({
      top: refs[+id].current.offsetTop - 80,
      behavior: 'smooth',
    });
  };

  const handleOnFetchMore = () => {
    return new Promise((res) => {
      res(handleFetchMore());
    });
  };

  return (
    <Grid item xs={12}>
      <PullToRefresh canFetchMore={canFetchMore} onFetchMore={handleOnFetchMore} fetchMoreThreshold={0}>
        {_.map(items, (log) => (
          <LogDetails
            key={+log.id}
            log={log}
            logRef={refs[+log.id]}
            logIdsSelected={logIdsSelected}
            handleClickLog={handleClickLog}
            desktop={desktop}
          />
        ))}
      </PullToRefresh>
      {canFetchMore && (
        <Box height={100} width="100%" display="flex" alignItems="center" justifyContent="center">
          <CircularProgress color="inherit" />
        </Box>
      )}
    </Grid>
  );
};

LogsRightPanel.propTypes = {
  items: arrayOf(object).isRequired,
  logIdsSelected: arrayOf(number),
  handleFetchMore: func.isRequired,
  canFetchMore: bool,
  desktop: bool,
};

LogsRightPanel.defaultProps = {
  logIdsSelected: [],
};

export default _.flowRight(withNewProject, withError, withLoading, withEmpty)(LogsRightPanel);
