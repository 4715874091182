import { Box, Grid } from '@material-ui/core';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import layoutPropTypes from '../../layoutPropTypes';
import ForumCard from '../forum-card';

const LayoutDefault = ({ type, logs, ...props }) => {
  const { t } = useTranslation('common');
  return (
    <>
      {_.isEmpty(logs) ? (
        <Box component={Grid} item xs={12} fontWeight="fontWeightBold">
          {t('defaultInformation.noComments')}
        </Box>
      ) : (
        logs.map((l) => <ForumCard type={type} key={l.id} log={l} {...props} />)
      )}
    </>
  );
};

export default LayoutDefault;

LayoutDefault.propTypes = layoutPropTypes;
