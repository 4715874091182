import { IconButton, Avatar, Grid, Typography, CardContent, Box, CardHeader } from '@material-ui/core';
import { bool, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Truncate } from 'components';
import { convertToSlug } from 'helpers/common';

import LogHomeAttachments from '../attachments/LogHomeAttachments';

import useStyles from './logCardLayoutStyles';

const LogCardLayout = ({ log: { id, description, attachments, name, project }, isBig, desktop }) => {
  const classes = useStyles(isBig)();
  const { t } = useTranslation();
  const updatedBy = `${project.owner.firstName} ${project.owner.lastName}`;
  const slugLogName = `${convertToSlug(name)}-${id}`;

  return (
    <>
      <Grid item xs={12}>
        <CardHeader
          avatar={
            <IconButton aria-label="settings" size="small">
              <Box
                component={Link}
                to={`/users/${project.owner.id}/${convertToSlug(
                  `${project.owner.firstName} ${project.owner.lastName}`,
                )}/profile`}
              >
                <Avatar alt="avatar" className={classes.avatar} src={project.owner.avatarLink} />
              </Box>
            </IconButton>
          }
          title={
            <>
              <Box
                component={Link}
                to={`/users/${project.owner.id}/${convertToSlug(
                  `${project.owner.firstName} ${project.owner.lastName}`,
                )}/profile`}
              >
                <Typography component="span" variant="h6" className={classes.name}>
                  {updatedBy}
                </Typography>
              </Box>
              <span className={classes.nameUpdated}>{t('common.updated')}</span>
            </>
          }
          className={classes.cardHeaderTitle}
        />
      </Grid>
      <CardContent component={Grid} item xs={12} className={classes.content}>
        <Typography variant={desktop ? 'h5' : 'h6'}>
          <Box
            display="flex"
            component={Link}
            to={`/projects/${project.id}/${convertToSlug(name)}#tab=logs&name=${slugLogName}`}
            className={classes.title}
          >
            <Truncate lines={2} width={desktop ? 0 : 300}>
              {name}
            </Truncate>
          </Box>
        </Typography>
        <Typography variant="body1" color="textSecondary" className={classes.descriptionPadding}>
          <Truncate lines={3}>{description}</Truncate>
        </Typography>
        <LogHomeAttachments attachments={attachments} desktop={desktop} />
      </CardContent>
    </>
  );
};

LogCardLayout.propTypes = {
  project: object,
  isBig: bool,
  log: object.isRequired,
  desktop: bool.isRequired,
};

LogCardLayout.defaultProps = {
  isBig: false,
};

export default LogCardLayout;
