import { Drawer, IconButton, Grid, Container, Typography, Box, Tooltip, makeStyles } from '@material-ui/core';
import { number, func, bool, object } from 'prop-types';

import Dashboard from './Dashboard';
import Vector from './icon/Vector';
import Members from './Members';

const drawerWidth = 323;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    height: '100%',
    left: 200,
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerPaper: {
    width: `${drawerWidth}px`,
    left: '75px',
    backgroundColor: '#ffffff',
    boxShadow: 'inset 0px 2px 6px rgba(0,0,0,0.1), 0px 0px 2px rgba(0, 0, 0, 0.1)',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    // padding: theme.spacing(0, 1),
    justify: 'flex-end',
    // height:'1em'
  },
  contentDrawerPanel: {
    // paddingTop: 20,
  },
  containerPanel: {
    padding: '1em',
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  iconButton: {
    padding: 0,
  },
  gridIconButton: {
    marginTop: theme.spacing(3.5),
    paddingLeft: theme.spacing(2),
  },
  labelStyle: {
    ...theme.typography.h5,
    letterSpacing: '0.1px',
    color: '#333333',
  },
  margin: {
    // margin: theme.spacing(1),
    // marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },
}));

const truncate = (str, length, ending) => {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = '...';
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  }
  return str;
};

const DrawerPanel = ({ dashboardTab, handleChangeDashboardTab, project, openDrawer, handleDrawerClose, profile }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Drawer
        variant="persistent"
        anchor="left"
        open={openDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Container className={classes.containerPanel}>
          <div className={classes.drawerHeader}>
            <Grid container direction="row" style={{ height: 48, width: '100%', display: 'flex' }}>
              <Grid item xs={9} className={classes.margin}>
                {/* <SearchPanel /> */}
                <Box
                  component={Tooltip}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  title={project.name}
                >
                  <Typography variant="h5" className={classes.labelStyle}>
                    {truncate(project.name, 20)}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={2}
                // style={{ width: '20%' }}
                justify="center"
                alignItems="center"
                container
                direction="column"
                // className={classes.gridIconButton}
              >
                <IconButton onClick={handleDrawerClose} className={classes.iconButton}>
                  {/* {theme.direction === 'ltr' ? <CloseIcon /> : <MenuIcon />} */}
                  <Vector />
                </IconButton>
              </Grid>
            </Grid>
          </div>
          <div className={classes.contentDrawerPanel}>
            <Grid container direction="column" alignItems="flex-start" justify="flex-start">
              <Dashboard dashboardTab={dashboardTab} handleChangeDashboardTab={handleChangeDashboardTab} />
              {/* <Group /> */}
              <Members project={project} profile={profile} />
            </Grid>
          </div>
        </Container>
      </Drawer>
    </div>
  );
};

DrawerPanel.propTypes = {
  dashboardTab: number.isRequired,
  handleChangeDashboardTab: func.isRequired,
  handleDrawerClose: func.isRequired,
  openDrawer: bool.isRequired,
  profile: object,
  project: object.isRequired,
};

export default DrawerPanel;
