import { Box, Typography, Divider } from '@material-ui/core';
import { string } from 'prop-types';

const Title = ({ title }) => (
  <Box mb={3}>
    <Typography variant="h2" color="primary">
      {title}
    </Typography>
    <Divider />
  </Box>
);

Title.propTypes = {
  title: string,
};

export default Title;
