import { Grid, makeStyles, useMediaQuery } from '@material-ui/core';
import { arrayOf, string, func, bool, object } from 'prop-types';

import { CocoonHelmet } from 'components';
import { PROFILE_TYPES } from 'helpers/constants';
import { META_DATA } from 'helpers/metaData';

import ProfileDescription from '../components/profile-description';
import ProfileHeader from '../components/profile-header';
import ProfileNavigation from '../components/profile-navigation/ProfileNavigation';
import ProfileProjects from '../components/profile-projects';

const useStyles = makeStyles((theme) => ({
  partnerProjects: {
    padding: theme.spacing(3, 6),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 0, 1.5),
    },
  },
}));

// awefully similar to user profile, refactor soon
const PartnerProfile = ({
  profile,
  // refetchProfile,
  tabs,
  mobileTabs,
  currentTab,
  setCurrentTab,
  descriptionHidden,
  setDescriptionHidden,
}) => {
  const classes = useStyles();

  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const { title, description, image, url } = META_DATA.CONTEST_PARTNER_PROFILE;

  return (
    <>
      <CocoonHelmet title={title} description={description} image={image} url={url} />
      <Grid container>
        <Grid item xs={12}>
          <ProfileHeader
            desktop={desktop}
            profile={profile}
            profileType={PROFILE_TYPES.PARTNER_PROFILE}
            refetchProfile={() => {}}
          />
        </Grid>
        <Grid item xs={12}>
          <ProfileNavigation
            desktop={desktop}
            tabs={tabs}
            mobileTabs={mobileTabs}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            profile={profile}
            profileType={PROFILE_TYPES.PARTNER_PROFILE}
            descriptionHidden={descriptionHidden}
            setDescriptionHidden={setDescriptionHidden}
            isGuest={true}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.partnerProjects}>
        {desktop && (
          <Grid item xs={4} hidden={descriptionHidden}>
            <ProfileDescription desktop={desktop} profile={profile} profileType={PROFILE_TYPES.PARTNER_PROFILE} />
          </Grid>
        )}

        <Grid item xs={descriptionHidden || !desktop ? 12 : 8}>
          <ProfileProjects
            desktop={desktop}
            currentTab={currentTab}
            profile={profile}
            profileType={PROFILE_TYPES.PARTNER_PROFILE}
            profileHidden={descriptionHidden}
            setProfileHidden={setDescriptionHidden}
          />
        </Grid>
      </Grid>
    </>
  );
};

PartnerProfile.propTypes = {
  profile: object.isRequired,
  // refetchProfile: func.isRequired,
  tabs: arrayOf(object).isRequired,
  mobileTabs: arrayOf(object).isRequired,
  currentTab: string.isRequired,
  setCurrentTab: func.isRequired,
  descriptionHidden: bool.isRequired,
  setDescriptionHidden: func.isRequired,
};

export default PartnerProfile;
