import { Box, Typography, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { bool, string } from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles((theme) => ({
  header: {
    '& h5': {
      fontWeight: 700,
    },
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid #989898`,
  },
  link: {
    '& a': { color: '#989898' },
    '& :hover': {
      color: theme.palette.primary.main,
    },
  },
}));

const ForumTitle = ({ desktop, label, type }) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const getLinkAllProjects = `/projects?type=${_.toLower(type)}`;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="flex-end"
      mb={desktop ? 2 : 1}
      className={classes.header}
    >
      <Typography variant="h5" color="textSecondary">
        {label}
      </Typography>
      <Typography variant="caption" className={classes.link}>
        <Link to={getLinkAllProjects}>{t('button.seeMore')}</Link>
      </Typography>
    </Box>
  );
};

ForumTitle.propTypes = {
  label: string.isRequired,
  type: string,
  desktop: bool.isRequired,
};

export default ForumTitle;
