import axios from 'axios';
import _ from 'lodash';

import log from 'helpers/log';

const validateStatusHelper = (status, isErrorValidated) =>
  (status >= 200 && status < 300) || (isErrorValidated && status >= 400 && status < 600);

const { CancelToken } = axios;
const sources = [];

const getClient = (baseUrl) => {
  const options = {
    baseURL: baseUrl && `${process.env.REST_ENDPOINT || 'http://localhost:5000'}/${baseUrl}`,
    withCredentials: true,
  };

  // TODO: Authorized API call
  // if (getCookie(COOKIES.names.userInfo)) {
  //   options.headers = {
  //     Authorization: `Bearer `,
  //   };
  // }

  const client = axios.create(options);

  client.interceptors.request.use(
    (config) => config,
    (error) => {
      // TODO: Setup Sentry capture exception here
      return Promise.reject(error);
    },
  );

  client.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status >= 500) {
        // TODO: Setup Sentry capture exception here
      }
      return Promise.reject(error);
    },
  );

  return client;
};

const baseApi = () => {
  let baseUrl = '';

  const handleError = (error) => {
    if (axios.isCancel(error)) {
      log.error('Request canceled', error.message);
    }
    throw error;
  };

  const base = (func) => (url, params, data) => {
    const apiSource = CancelToken.source();

    sources.push(apiSource);

    const extra = {
      ...params,
      cancelToken: apiSource.token,
      validateStatus: (status) => validateStatusHelper(status, params.isErrorValidated),
    };

    return getClient(baseUrl)
      [func](..._.compact([url, data, extra]))
      .catch(handleError);
  };

  return {
    setBaseUrl: (url) => {
      baseUrl = url;
    },
    get: (url, params, headers = {}) => base('get')(url, { params, headers }),
    delete: (url, params = {}) => base('delete')(url, params),
    head: (url, params = {}) => base('head')(url, params),
    options: (url, params = {}) => base('options')(url, params),
    post: (url, data = {}, params = {}) => base('post')(url, params, data),
    put: (url, data = {}, params = {}) => base('put')(url, params, data),
    patch: (url, data = {}, params = {}) => base('patch')(url, params, data),
    cancelRequests: () => {
      _.forEach(sources, (source) => {
        source.cancel('Cancellation');
      });
    },
  };
};

export default baseApi();
