import { useQuery } from '@apollo/client';
import { Box, CircularProgress } from '@material-ui/core';
import _ from 'lodash';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { GET_PROJECT } from 'apollo/queries/project-query';
import { GET_USER } from 'apollo/queries/user-query';
import { backToAnchor, CocoonHelmet } from 'components';
import { cookieUserId } from 'helpers/auth';
import { getImg } from 'helpers/common';

import { PROJECT_DEFAULT } from './helpers/constants';
import Panel from './Panel';

const PanelContainer = () => {
  const { id } = useParams();

  const [dashboardTab, setDashboardTab] = useState(0);
  const [navigationTab, setNavigationTab] = useState(0);
  const [phaseTab, setPhaseTab] = useState(2);
  const [project, setProject] = useState(PROJECT_DEFAULT);
  const [isReload, setIsReload] = useState(false);
  const [isMember, setIsMember] = useState(false);
  const [profile, setProfile] = useState(null);
  const {
    data,
    loading,
    error,
    refetch: refetchPanel,
  } = useQuery(GET_PROJECT, {
    variables: { id },
    skip: !id,
  });
  const { data: userProfile } = useQuery(GET_USER, {
    variables: { id: cookieUserId },
  });

  useEffect(() => {
    if (userProfile) {
      setProfile(userProfile);
    }
  }, [userProfile]);
  useEffect(() => {
    backToAnchor(document);
  });

  useEffect(() => {
    if (data) {
      setProject(data.projectById);

      const { members, ownerId } = data.projectById;

      if (members) {
        const temp = _.find(members, ({ id }) => +id === +cookieUserId);
        setIsMember(!!temp || +cookieUserId === +ownerId);
      }
    }
  }, [data]);

  const [openDrawer, setOpenDrawer] = useState(false);

  const reloadComponent = (value) => {
    setIsReload(value);
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleToggleDrawer = () => {
    if (openDrawer) handleDrawerClose();
    else handleDrawerOpen();
  };

  const handleChangeDashboardTab = (event, newTab) => {
    setDashboardTab(newTab);
  };

  const handleChangeNavigationTab = (event, newTab) => {
    setNavigationTab(newTab);
    if (newTab === 0) {
      handleToggleDrawer();
    }
  };

  const handleChangePhaseTab = (event, newTab) => {
    setPhaseTab(newTab);
  };

  if (loading) {
    return (
      <Box height="380px" width="100%" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress color="inherit" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box height="380px" width="100%" display="flex" alignItems="center" justifyContent="center">
        {error.message}
      </Box>
    );
  }

  return (
    <>
      <CocoonHelmet
        title={`${project.name} - Panel`}
        description={project.description}
        image={getImg(project.assetLink)}
      />
      <Panel
        openDrawer={openDrawer}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
        handleToggleDrawer={handleToggleDrawer}
        error={error}
        project={project}
        navigationTab={navigationTab}
        dashboardTab={dashboardTab}
        phaseTab={phaseTab}
        handleChangeDashboardTab={handleChangeDashboardTab}
        handleChangeNavigationTab={handleChangeNavigationTab}
        handleChangePhaseTab={handleChangePhaseTab}
        isMember={isMember}
        refetchPanel={refetchPanel}
        isReload={isReload}
        reloadComponent={reloadComponent}
        profile={profile}
      />
    </>
  );
};

export default PanelContainer;
