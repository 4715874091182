import { format, parseISO } from 'date-fns';

const L = 'dd/MM/yyyy';
const ll = 'MMM dd, yyyy';
const lll = 'MMM dd, yyyy | HH:mm';

const formatString = (dateString, arg) => {
  let dateToFormat = dateString === Object(dateString) ? dateString : parseISO(dateString);
  return format(dateToFormat, arg);
};

export const formatDate_L = (date) => formatString(date, L);
export const formatDate_ll = (date) => formatString(date, ll);

export const getDiffNow = (date, short = false) => {
  if (!date) {
    return 'N/A';
  }

  const diffSeconds = Math.floor((new Date() - new Date(date)) / 1000);

  if (diffSeconds < 30) {
    return 'just now';
  }

  if (diffSeconds < 60) {
    return short ? `${diffSeconds}s` : `${diffSeconds} seconds ago`;
  }

  const diffMinutes = Math.floor(diffSeconds / 60);

  if (diffMinutes === 1) {
    return short ? `${diffMinutes}m` : `${diffMinutes} minute ago`;
  }

  if (diffMinutes < 60) {
    return short ? `${diffMinutes}m` : `${diffMinutes} minutes ago`;
  }

  const diffHours = Math.floor(diffMinutes / 60);

  if (diffHours === 1) {
    return short ? `${diffHours}h` : `${diffHours} hour ago`;
  }

  if (diffHours < 24) {
    return short ? `${diffHours}h` : `${diffHours} hours ago`;
  }

  return short ? formatString(date, ll) : formatString(date, lll);
};

export const getDateFromISO = (date, options = {}) => {
  const { showSeconds = true } = options;

  const fdate = date ? new Date(date) : new Date();

  const dd = String(fdate.getDate()).padStart(2, '0');
  const mm = String(fdate.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = fdate.getFullYear();
  const ampm = fdate.getHours() >= 12 ? 'PM' : 'AM';
  const hours = fdate.getHours() % 12 ?? 12;
  const seconds = showSeconds ? `:${fdate.getSeconds()}` : '';

  const time = `${hours}:${fdate.getMinutes() < 10 ? '0' : ''}${fdate.getMinutes()}${seconds} ${ampm}`;
  const dateFinal = formatString(`${yyyy}-${mm}-${dd}`, ll);

  return `${dateFinal}, ${time}`;
};
