import { useMediaQuery } from '@material-ui/core';
import { bool, func, number, object } from 'prop-types';
import { useState, useEffect } from 'react';

import { DELETE_STEP_SECTION } from 'apollo/mutations/tutorial-mutation';
import { DIALOG } from 'helpers/constants';
import { handleOpenDialogAC3 } from 'reducers/testAC3/cache';

import StepSection from './StepSection';

const StepSectionContainer = ({
  section,
  ownerId,
  isNewSection = false,
  handleAddSection,
  handleEditSection,
  handleDeleteSection,
  toggleAddSection,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  useEffect(() => {
    setIsEditMode(isNewSection);
    setTitle(isNewSection ? '' : section.title);
    setContent(isNewSection ? '' : section.content);
  }, []);

  const updateTitle = (name) => {
    setTitle(name);
  };

  const updateContent = (data) => {
    setContent(data);
  };

  const changeEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const handleSubmit = async () => {
    if (handleAddSection) {
      handleAddSection(title, content);
      toggleAddSection();
    } else {
      // edit mode
      handleEditSection({ id: section.id, title, content });
    }

    changeEditMode();
  };

  const openRemoveSectionDialog = () => {
    const optionsDelete = {
      variables: { section: { id: section.id } },
      mutation: DELETE_STEP_SECTION,
      handleSectionDisplay: () => handleDeleteSection(+section.id),
    };

    handleOpenDialogAC3(DIALOG.DELETE_SECTION_TUTORIAL, optionsDelete);
  };

  return (
    <StepSection
      desktop={desktop}
      ownerId={ownerId}
      isEditMode={isEditMode}
      isNewSection={isNewSection}
      name={title}
      content={content}
      changeEditMode={changeEditMode}
      updateContent={updateContent}
      updateTitle={updateTitle}
      handleSubmit={handleSubmit}
      handleDelete={openRemoveSectionDialog}
    />
  );
};

StepSectionContainer.propTypes = {
  handleAddSection: func,
  handleDeleteSection: func,
  handleEditSection: func,
  isNewSection: bool,
  ownerId: number,
  section: object,
  toggleAddSection: func.isRequired,
};

StepSectionContainer.defaultProps = {
  handleAddSection: undefined,
  isNewSection: undefined,
};

export default StepSectionContainer;
