import { Box, Container, FormControl, Select, MenuItem as MatMenuItem, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { arrayOf, object, number, func } from 'prop-types';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    display: 'flex',
    margin: theme.spacing(0, 0, 1.5),
    '& .MuiSelect-select': {
      padding: theme.spacing(1),
    },
  },
}));

const MobileNavigation = ({ mobileTabs, activeTab, handleTabSwitch }) => {
  const classes = useStyles();
  return (
    <Box component={Container} mb={1.5}>
      <FormControl className={classes.formControl}>
        <Select
          value={activeTab}
          onChange={(key) => {
            const navigationId = key.target.value;
            handleTabSwitch(_.find(mobileTabs, ({ id }) => +id === +navigationId).key, navigationId);
          }}
          autoWidth
          variant="outlined"
          inputProps={{
            name: 'navigation',
            id: 'navigation-select',
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
        >
          {mobileTabs.map(({ id, title }) => (
            <MatMenuItem style={{ color: '#000' }} key={id} value={+id}>
              {title}
            </MatMenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

MobileNavigation.propTypes = {
  mobileTabs: arrayOf(object).isRequired,
  activeTab: number.isRequired,
  handleTabSwitch: func.isRequired,
};

export default MobileNavigation;
