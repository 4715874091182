import { Box } from '@material-ui/core';
import { string, func, bool } from 'prop-types';
import { useHistory } from 'react-router-dom';

import { CocoonEditor } from 'components';
import { CK_EDITOR_IMAGE_UPLOAD_URL, CK_EDITOR_UPLOAD_TYPES } from 'helpers/constants';

const BodyContent = ({ content, handleChangeData, desktop }) => {
  const history = useHistory();
  const projectId = +history.location.pathname.split('/')[2];

  return (
    <Box pt={0.75} width={desktop ? 'auto' : 353} maxWidth="100%">
      <CocoonEditor
        data={content}
        handleChangeData={handleChangeData}
        uploadUrl={CK_EDITOR_IMAGE_UPLOAD_URL}
        type={CK_EDITOR_UPLOAD_TYPES.TUTORIAL_SECTION}
        projectId={projectId}
      />
    </Box>
  );
};

BodyContent.propTypes = {
  content: string,
  desktop: bool,
  handleChangeData: func,
};

export default BodyContent;
