import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
// done
// updated
export const GET_NOTIFICATIONS = gql`
  query userNotifications($page: Int, $limit: PaginationLimit) {
    userNotifications(page: $page, limit: $limit) {
      id
      action
      fromUserId
      fromUserFullName
      fromUserAvatarLink
      projectId
      projectName
      logName
      eventType
      eventId
      isSeen
      createdAt
      projectTopicId
    }
  }
`;
