import {
  Select,
  Box,
  CircularProgress,
  FormControl,
  MenuItem as MatMenuItem,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { bool, number, func, object, arrayOf } from 'prop-types';

import FeaturedHeaderButtons from './components/featured-header/components/buttons/FeaturedHeaderButtons';
import FeaturedHeader from './components/featured-header/FeaturedHeader';
import HeaderInfo from './components/header-info/HeaderInfo';
import TabPanels from './components/tab-panels/TabPanels';
import { CRUD_STATUS } from './helpers/constants';

const useStyles = makeStyles((theme) => ({
  menu: {
    margin: theme.spacing(2, 0),
    '& .menu-item-wrapper.active': {
      border: 'none',
    },
    '& .scroll-menu-arrow': {
      padding: theme.spacing(3),
      cursor: 'pointer',
    },
  },
  formControl: {
    minWidth: 120,
    display: 'flex',
    margin: theme.spacing(0, 1.5, 2),
    '& .MuiSelect-select': {
      padding: theme.spacing(1),
    },
  },
}));

const Project = ({
  register,
  control,
  project,
  loadingProject,
  errorProject,
  projectTags,
  projectStats,
  crudStatus,
  tab,
  loadingButton,
  uploadedFile,
  handleChangeTab,
  handleChangeProject,
  handleChangeProjectTags,
  handleUploadFile,
  handleSubmit,
  handleChangeCrudStatus,
  handleCancelEditMode,
  disabledSave,
  refetchProject,
}) => {
  const classes = useStyles();
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const isView = crudStatus === CRUD_STATUS.VIEW || crudStatus === CRUD_STATUS.VIEW_UPDATED;

  const tabs = ['Brief', 'Logs', 'Team', 'Tutorial', 'Forum'];

  return (
    <>
      {loadingProject ? (
        <Box height="77vh" width="100%" display="flex" alignItems="center" justifyContent="center">
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <>
          <FeaturedHeader
            isView={isView}
            register={register}
            control={control}
            loadingButton={loadingButton}
            crudStatus={crudStatus}
            uploadedFile={uploadedFile}
            project={project}
            projectTags={projectTags}
            handleChangeTab={handleChangeTab}
            handleSubmit={handleSubmit}
            handleChangeProject={handleChangeProject}
            handleChangeProjectTags={handleChangeProjectTags}
            handleUploadFile={handleUploadFile}
            handleChangeCrudStatus={handleChangeCrudStatus}
            handleCancelEditMode={handleCancelEditMode}
            disabledSave={disabledSave}
            refetchProject={refetchProject}
            desktop={desktop}
          />
          <HeaderInfo
            tab={tab}
            handleChangeTab={handleChangeTab}
            project={project}
            projectStats={projectStats}
            crudStatus={crudStatus}
            desktop={desktop}
            isView={isView}
          />
          {!desktop && (
            <>
              <FeaturedHeaderButtons
                isView={isView}
                control={control}
                project={project}
                loadingButton={loadingButton}
                uploadedFile={uploadedFile}
                disabledSave={disabledSave}
                crudStatus={crudStatus}
                handleChangeCrudStatus={handleChangeCrudStatus}
                handleUploadFile={handleUploadFile}
                handleCancelEditMode={handleCancelEditMode}
                refetchProject={refetchProject}
                desktop={desktop}
              />
              {isView && (
                <FormControl className={classes.formControl}>
                  <Select
                    value={tab}
                    onChange={(e) => handleChangeTab(e, e.target.value)}
                    autoWidth
                    variant="outlined"
                    inputProps={{
                      name: 'tab',
                      id: 'tab-select',
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {tabs.map((value, index) => (
                      <MatMenuItem style={{ color: '#000' }} key={value} value={index}>
                        {value}
                      </MatMenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </>
          )}
          <TabPanels
            control={control}
            crudStatus={crudStatus}
            tab={tab}
            project={project}
            handleChangeProject={handleChangeProject}
            errorProject={errorProject}
            desktop={desktop}
            isView={isView}
          />
        </>
      )}
      {errorProject && (
        <Alert variant="outlined" severity="error">
          {errorProject.message}
        </Alert>
      )}
    </>
  );
};

Project.propTypes = {
  project: object.isRequired,
  loadingProject: bool,
  errorProject: object,
  projectStats: object,
  loadingButton: bool.isRequired,
  crudStatus: number.isRequired,
  uploadedFile: object,
  projectTags: arrayOf(object).isRequired,
  tab: number.isRequired,
  handleChangeTab: func.isRequired,
  handleChangeProject: func.isRequired,
  handleChangeProjectTags: func.isRequired,
  handleUploadFile: func.isRequired,
  handleSubmit: func.isRequired,
  handleChangeCrudStatus: func.isRequired,
  handleCancelEditMode: func.isRequired,
  disabledSave: bool,
  refetchProject: func.isRequired,
};

Project.defaultProps = {
  uploadedFile: null,
  loadingProject: false,
  errorProject: undefined,
  disabledSave: undefined,
  projectStats: undefined,
};

export default Project;
