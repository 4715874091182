import { useApolloClient } from '@apollo/client';
import {
  Button,
  ClickAwayListener,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  makeStyles,
} from '@material-ui/core';
import {
  BookmarkBorder as BookmarkBorderIcon,
  MoreHoriz as MoreHorizIcon,
  PowerSettingsNew as PowerSettingsNewIcon,
} from '@material-ui/icons';
import { number, string } from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { GET_LIST_OF_USER } from 'apollo/queries/list-query';
import { SameUserNull } from 'components/auth/UserAuthorization';
import { cookieUserId } from 'helpers/auth';
import { DIALOG } from 'helpers/constants';
// import { openDialog } from 'reducers/dialogReducer';
import { handleOpenDialogAC3 } from 'reducers/testAC3/cache';
const useStyles = makeStyles((theme) => ({
  list: {
    '& button': {
      display: 'flex',
      alignItems: 'end',
      color: '#333',
      width: '100%',
      borderRadius: 0,
      '& > span': {
        fontSize: 14,

        '& > div:first-child': {
          minWidth: 32,
        },
      },
      '&:focus, &:hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        fontWeight: theme.typography.fontWeightBold,
        '& > div, svg': {
          color: theme.palette.common.white,
        },
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0.5, 1, 0),
        minHeight: 28,
      },
    },
  },
  button: {
    border: 'none',
    '&:hover': {
      border: 'none',
      borderRadius: 0,
      '& *': {
        fontWeight: 'bold',
      },
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 42,
    },
  },
}));

const ProjectMoreButton = ({ id, ownerId, name }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const userId = +ownerId || cookieUserId;
  const client = useApolloClient();

  const handleToggle = (e) => {
    setAnchorEl(e.currentTarget);
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    // if (anchorEl) {
    //   setAnchorEl(null);
    // }
    setOpen(false);
  };

  const handleListKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      setOpen(false);
    }
  };

  const handleAddToLists = async (e) => {
    e.preventDefault();
    const listQuery = await client.query({
      query: GET_LIST_OF_USER,
      variables: { ownerId: userId },
    });
    const {
      data: { listsByOwnerId },
    } = listQuery;
    const options = {
      list: listsByOwnerId,
      projectId: +id,
      ownerId: +userId,
    };
    handleOpenDialogAC3(DIALOG.LIST_PROJECT, options);
    handleClose(e);
  };

  const handleDeactivate = (e) => {
    e.preventDefault();
    const options = { project: { id: +id, name, ownerId: +ownerId } };
    handleOpenDialogAC3(DIALOG.DEACTIVATE, options);
    handleClose(e);
  };

  // TODO: uncomment later
  // const handleSetPrivacy = e => {
  //   e.preventDefault();
  //   const options = { project: { id, ownerId } };
  //   handleOpenDialog(DIALOG.PRIVACY, options);
  //   handleOpenDialogAC3(DIALOG.PRIVACY, options);
  //   handleClose(e);
  // };

  return (
    <>
      <Button
        aria-controls={open ? 'edit-card-grow' : undefined}
        aria-haspopup="true"
        color="primary"
        size="small"
        variant="outlined"
        onClick={handleToggle}
        className={classes.button}
      >
        <MoreHorizIcon />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
        style={{ zIndex: 3 }}
      >
        {() => (
          <Paper elevation={3} square>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="edit-card-grow" onKeyDown={handleListKeyDown} className={classes.list}>
                <MenuItem component={Button} onClick={handleAddToLists}>
                  <ListItemIcon>
                    <BookmarkBorderIcon fontSize="small" />
                  </ListItemIcon>
                  Add To Lists
                </MenuItem>
                {/* TODO: uncomment later */}
                {/* {SameUserNull(ownerId)(
                  <MenuItem component={Button} onClick={handleSetPrivacy}>
                    <ListItemIcon>
                      <LockOpenIcon fontSize="small" />
                    </ListItemIcon>
                    Set privacy
                  </MenuItem>,
                )} */}
                {SameUserNull(ownerId)(
                  <MenuItem component={Button} onClick={handleDeactivate}>
                    <ListItemIcon>
                      <PowerSettingsNewIcon fontSize="small" />
                    </ListItemIcon>
                    Deactivate
                  </MenuItem>,
                )}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        )}
      </Popper>
    </>
  );
};

ProjectMoreButton.propTypes = {
  id: number.isRequired,
  ownerId: number.isRequired,
  name: string.isRequired,
};

export default ProjectMoreButton;
