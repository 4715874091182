const MilestoneIcon = () => (
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="17" cy="17" r="16" fill="#4791CE" stroke="white" strokeWidth="2" />
    <path
      d="M19.1325 13.3152C17.8566 13.3152 16.8029 12.5 15.1988
      12.5C14.6046 12.5 14.0724 12.6027 13.5786 12.7815C13.6471
      12.6056 13.6762 12.4173 13.664 12.2293C13.6218 11.5628
      13.0668 11.0283 12.389 11.0011C11.6273 10.9706 11 11.5694
      11 12.3125C11 12.7584 11.2261 13.1521 11.5714 13.3893V22.4375C11.5714
      22.7482 11.8273 23 12.1429 23H12.5238C12.8394 23 13.0952 22.7482
      13.0952 22.4375V20.225C13.7693 19.9422 14.6091 19.7065 15.8199
      19.7065C17.0958 19.7065 18.1495 20.5217 19.7536 20.5217C20.9005
      20.5217 21.8171 20.1398 22.6704 19.5641C22.8771 19.4247 23
      19.1931 23 18.9464V13.2487C23 12.7004 22.4222 12.3377 21.917
      12.5686C21.0995 12.9424 20.0967 13.3152 19.1325 13.3152V13.3152Z"
      fill="white"
    />
  </svg>
);

export default MilestoneIcon;
