/* eslint-disable max-len */
export const CRUD_STATUS = {
  VIEW: 0,
  NEW: 1,
  EDIT: 2,
};

export const PROJECT_DEFAULT = {
  name: '',
  description: '',
  assetLink: '',
  owner: {},
  categories: [],
};

export const LOG_DEFAULT = {
  name: '',
  publishedDate: '',
  description: '',
  isMilestone: false,
};

export const NEW_LOG_DESCRIPTION = {
  data: '<h2 style="margin-left:0px;">Summary</h2><p style="margin-left:0px;">(Briefly describe what you have done/accomplished within the scope of this log)</p><h2 style="margin-left:0px;">Introduction</h2><p style="margin-left:0px;">(What is the main theme of this log ?)</p><h2 style="margin-left:0px;">Accomplishments</h2><p style="margin-left:0px;">(Describe in detail what and how you have done things)</p><h2 style="margin-left:0px;">Goals</h2><p style="margin-left:0px;">(What are the expected goals within the scope of this log?</p><h2 style="margin-left:0px;">Roadblocks</h2><p style="margin-left:0px;">(What are the hurdles you have stumbled upon ?)</p><h2 style="margin-left:0px;">Note</h2><p style="margin-left:0px;">(What are the notices of this log ?)</p><h2 style="margin-left:0px;">Next step</h2><p style="margin-left:0px;">(What will you do next ?)</p><h2 style="margin-left:0px;">Attachment</h2><p style="margin-left:0px;">(What is included in this log ?)</p>',
};

export const DRAWER_LABEL = [
  {
    label: 'Dashboard',
    subLabel: '',
    first: 'Tree Preview',
    second: 'Task Manager',
    third: 'Timeline Manager',
  },
  {
    label: 'Groups',
    subLabel: 'Create',
    first: 'General',
    second: 'Engineer Team',
    third: 'Design Department',
  },
  {
    label: 'Members',
    subLabel: 'Invite',
  },
];

export const UPDATE_TAGS_DEFAULT = [
  {
    id: 0,
    name: '',
    _typename: '',
  },
];

export const COLOR_ARRAY = [
  '#FF6633',
  '#FFB399',
  '#FF33FF',
  '#FFFF99',
  '#00B3E6',
  '#E6B333',
  '#3366E6',
  '#999966',
  '#99FF99',
  '#B34D4D',
  '#80B300',
  '#809900',
  '#E6B3B3',
  '#6680B3',
  '#66991A',
  '#FF99E6',
  '#CCFF1A',
  '#FF1A66',
  '#E6331A',
  '#33FFCC',
  '#66994D',
  '#B366CC',
  '#4D8000',
];
export const user1 =
  'https://s3-alpha-sig.figma.com/img/e778/391f/94dad86705c7dcd41d2210968b87a737?Expires=1586736000&Signature=Pohm4xAgK2q29b7Nx5oLQ6Mc332B~p5UQGhTY-uH13UEukJXjC0jJsUZU~kdYQf8RQ0eIvOHx3KiXXM3uYXPejAi5Dpw6DVfRdEC2HcsS7iuTIvPj5fAiPtuYS1KAZM75xFjSq6uQxmvDQcIdholdDDSpVnbV2qQMhOtvMov1Wej~FPFGv0Dr85K8s6qiW8j2tDfqrOddwhV23~dvGFfUi6q2Ic8gfhgg0SYKbaWWVKtYFtgXv145UkyUMh7NaMoKzfmTK7i0s19AcugDJO~jIBzXbXUxeNTI0lkPxCwOir6cj0RWnH9ATsiRdernYWwnUaDbYPfk-Hj2fGyvlbWbA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA';
export const user2 =
  'https://s3-alpha-sig.figma.com/img/33f2/b80a/017166021d5b449df8ce443ec4f44ef4?Expires=1586736000&Signature=hOzoeyRZVp66qlihJald1CTcd7CNSUhwexEcv~i70WyOdBIGmKaI8OjRUFGeEg3vs1KvrQxeErkQMfmFSkrdKHNXjBnmDqYcJKoBDgU~6sbi4eNuQvb9QTIrUlM5yA8RtEQ9uOZkTaevPG14Za0Xpkudh-DOKUUR8jq76uUczafH7UQp0NPxmxg4JDHxoKEoKO44ZR3tN03VktOWP0Vd4YQ1Unk6BgPSSzQy1pkbnuuOScC8whDZ0i6L53V4S5pKtt04r4JM3uGVffJibdfAn1Rzi15Rw8B86b354pEJ2qi9YIkhbtAFWiX3l9cXZMdaW2OQYn01dMzQU74xfnjMgA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA';
export const user3 =
  'https://s3-alpha-sig.figma.com/img/81ec/899f/9ad693c43a22c5e21fc4a3c6e78c46c3?Expires=1586736000&Signature=eHNpcu~fViknQQdOkHaqnFY1BAA86NVPjAcv18zAULBHAIqaCIys9tJmBv47llgaDiZVkIoJJVssrS4-MKD5BbNzm6mQAxCkuIv5RWBgjoE1EWSgZbZLg9dQJMZc20ACOBGTjzYyLp1kTvcFqLs3eoZ3lbh4qKrflwAMkho0VAp-b~vRFcMd0~tZggNoFEPqi8-UKsXiPS-zY-IlVlaTB6emNFwbAmABSQ3OimZBoItKQCEQnC-HpphgIvZnxk0lOADkP5yEj5TXput3ZZ33g5BkmD04DJA4eOEbIW00wql2VVPmoldWkaHMQ6Lcur8DL2jrtsjVj6G96BzOrWec0w__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA';
export const user4 =
  'https://s3-alpha-sig.figma.com/img/b508/dc43/25a546144c884a34ed1b78cf7918decc?Expires=1586736000&Signature=P3NoyCX6N3~E1kd177NY5dX1GM1tHtlYKt3V6~K5KPTUSo3yXdBI6XiaXnPYq-qVnHUDrgCL9X15wZfb1sQRdn1aqbbTQc7A7HWoCPHJum6Xz1oHldFFbx2I0DbiZNJziNvY5R0FYDcWvq8zFkX8I-GgXZxWAxxVsQCC9rKHkt1cKzDi-5Xrmttc3U6J8O1-213dG46Y8xMS9jnPULSwlNZsu3erzDgsNdE82-VSITO7fVgP~Gy96m5lmMqR7zvYl8amNLrXtQBe8Al~HZLqR1s-Zu9c61agC7Pr8KNQqM7wmm77LNBUlgYFkTrymLCHlZLKuiztT8VlCjm7RtEX1w__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA';
export const user5 =
  'https://s3-alpha-sig.figma.com/img/0a3d/1ec8/08406e3ab5456113f0b0ed2ebec0e299?Expires=1586736000&Signature=flPxkkmMyzcuW6D160BNJ4bb6np4gwpyv387qwhajTPNj7syvOabtM83tF5jEo02InJJm0I0CjyfnGOw9dlZhhHIvkDB6OCPF2xrbPQydd3FYsXn8T83v82pEA4Qlx9cA9L2qG-1MAdd7LhQSISV1NdDyeejmRoyS2aW5iShWIoBmZ7tTuisjwB4gMQz6iKn~N1FdDciQsKa85Z8gkyZEV46P8LPZac9hjMM0FqwDbQteN-2-0Ewd2aYdWL6zgg9dp8b7WbxK7BvTZ0ypFssk0rdL5f3nRWhNyUUpo1yyARRGYmi4L~LHr6Uad43v7TCTDrNGmdoyXeMdTGc1slt7Q__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA';
export const user6 =
  'https://s3-alpha-sig.figma.com/img/6d62/95b7/de18b49b9a7040577f71cdc99efdd03a?Expires=1586736000&Signature=HP3snjaRTK47pTsPSE0kji8mrytZBshpiWibacPMu5CLiLIUEubEO1aGSO0HD~RJbwNNdTrRyKF4Sii89V7CbtFE7xoiOLA-lXm2Axe98Bui2PpN4FWWxAT4JCeiMvxFxOA6JqjFZ2Vwg2LDyQSRRcA09nfO6hI1Ab44P6PcBPmeJZ1TGceDT7wdq8Lh10M6-GTeCT9UYNYUnUa-ST-exGGHg-VH-nJqCuuAybIYo4pSZgNiBHEeUrv21U4aYA2SFarfMxdD~wUMrW4Tg3rPP1jQGvwfgpGePqZqm9hFrSkSQ7rjraj8P1PQymeDeyNcwkNjVo2g7lQgx0Nk4p9ZFg__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA';
export const user7 =
  'https://s3-alpha-sig.figma.com/img/73b3/e83a/70cb66ce4439a7ef80e037305b02e797?Expires=1586736000&Signature=KLnPjMVGubmxzGT9HbgLKgGyj~vUYNaG8V7yitriWXWpD1UY9xNh8Enw9OITlWRreCFNoXjbggG6E2WdleyFLK7oTO29zqJSgByPgGaWzr6CULblocDUhsK1BAZreDt94uu0HiU-73Sp7Bgju0v~kqfkiWePyjwzZG-vhqr~CKXRVIDtrtvEblvfWqQSO3PgKzzTyA0X2a5NUgycB6-7-u5ylK-TFg2fDtCW62KJylNZtQp2ZjPpGdy~xbotHQo1AUb~Dm50ZqWE941X-ojpYIdD98gm23yKbQYsk~ppvTVT7Eh2hxPUk54w6jAr7PP9j8sSrwNa0eYJHXaT~0ldMA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA';
export const tayRobot =
  'https://s3-alpha-sig.figma.com/img/ff59/082b/d0b9eb3a2b28198401038865df98c3ca?Expires=1586736000&Signature=bYo4L4ak8EerqPayz4asogioEiApWkdB1L80HF2t0-EQ5gAPzCJLE0fLh-2iJkQGYWNsW5f4mt0hH5ApGDiH53dPIh2ko62YdVnHXux3nzBeTm~Mr0Znu4l8mPB2H00pXKRo1qXdC2M2NR2dnDAwH~rOxduSvbiuptJRgXHGeEghjeyf6Syb3v9byFA~p-Hf5mh2gR81POMxBfHH7zwdP0b239mrPV-sE-h63j1BLS-gLxIpiP6-3ro0551x08CZul2wcnIRqPZVkOJ6mSzfIobQPZJxDe-ihIY4CXRMChhLOWQ52DrDNLKAnw6XBZiMAeSs148lX~TKGHK3fxztnA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA';

export const DrawerLabel = [
  {
    label: 'Dashboard',
    subLabel: '',
    first: 'Tree Preview',
    second: 'Task Manager',
    third: 'Timeline Manager',
  },
  {
    label: 'Groups',
    subLabel: 'Create',
    first: 'General',
    second: 'Engineer Team',
    third: 'Design Department',
  },
  {
    label: 'Members',
    subLabel: 'Manage',
    members: [
      {
        avatar: user1,
        name: 'You',
      },
      {
        avatar: user2,
        name: 'Derren Quentin',
      },
      {
        avatar: user3,
        name: 'Britannia Sheridan',
      },
      {
        avatar: user4,
        name: 'Reg Anson',
      },
      {
        avatar: user5,
        name: 'herbert Buddy',
      },
      {
        avatar: user6,
        name: 'Eskine Channing',
      },
      {
        avatar: user7,
        name: 'Bekki Todd',
      },
    ],
  },
];
export const progressData = [{ label: 'Current Phase', data: '20%' }];
export const taskReport = [
  { label: 'All Task', data: '127' },
  { label: 'Wailist', data: '25' },
  { label: 'Pending', data: '13' },
  { label: 'Closed', data: '86' },
  { label: 'Overdue', data: '3' },
];
export const members = [
  { avatar: user1, name: 'You' },
  { avatar: user2, name: 'Derren Quentin' },
  { avatar: user3, name: 'Britannia Sheridan' },
  { avatar: user4, name: 'Reg Anson' },
  { avatar: user5, name: 'herbert Buddy' },
  { avatar: user6, name: 'Eskine Channing' },
  { avatar: user7, name: 'Bekki Todd' },
];
