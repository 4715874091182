/* eslint-disable max-len */
const NotFoundPageBackgroundSVG = () => (
  <svg
    style={{ position: 'fixed', overflow: 'hidden' }}
    width="1286"
    height="965"
    viewBox="0 0 1286 965"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.1"
      d="M356.212 946.83C605.615 946.83 778.934 466.206 988.68 680.548C1198.43 894.891 1376.74 711.639 1233.09 559.112C1089.44 406.585 897.32 147.253 809.779 353.182C722.238 559.112 290.409 261.739 419.077 98.2391C547.744 -65.261 943.441 -8.20059 882.045 162.615C820.648 333.431 251.926 330.87 251.926 162.615C251.926 -5.64018 716.069 141.034 522.187 452.672C328.304 764.31 337.117 85.4371 233.713 312.582C130.309 539.726 -117.038 347.33 67.4444 581.058C251.926 814.787 380.888 531.679 251.926 531.679C122.965 531.679 328.598 993.649 450.803 798.327C573.008 603.005 361.499 514.488 406.151 609.954C450.803 705.421 49.2312 1043.39 285.709 946.83C522.187 850.267 795.679 531.679 585.346 531.679C375.013 531.679 858.25 205.045 923.465 393.051C988.68 581.058 818.005 262.105 783.047 559.112C748.089 856.119 714.894 905.864 660.549 732.488C606.203 559.112 591.221 1153.49 973.698 828.32C1356.18 503.149 908.777 517.414 882.045 757.726"
      stroke="#242525"
      strokeWidth="2"
    />
  </svg>
);

export default NotFoundPageBackgroundSVG;
