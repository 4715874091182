import { useMediaQuery, Box, Container } from '@material-ui/core';
import { number, func, string, object } from 'prop-types';

import ProjectsHeader from './components/header/ProjectsHeader';
import TABS from './helpers/constants';
import AllProjects from './screens/all';
import UsersProjects from './screens/users';

const { PROJECT_PUBLIC, PROJECT_PRIVATE, JOIN_REQUEST } = TABS;

const Projects = ({ label, queries, tab, projectType, handleChangeTab }) => {
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  return (
    <Container fixed component={Box} mb={desktop ? 6 : 0} mt={desktop ? 6 : 2}>
      <ProjectsHeader tab={tab} handleChangeTab={handleChangeTab} label={label} desktop={desktop} />
      {tab !== undefined ? (
        <>
          <UsersProjects index={PROJECT_PUBLIC} tab={tab} query={queries[PROJECT_PUBLIC]} desktop={desktop} />
          <UsersProjects index={PROJECT_PRIVATE} tab={tab} query={queries[PROJECT_PRIVATE]} desktop={desktop} />
          <UsersProjects index={JOIN_REQUEST} tab={tab} query={queries[JOIN_REQUEST]} desktop={desktop} />
        </>
      ) : (
        <AllProjects query={queries[projectType]} desktop={desktop} />
      )}
    </Container>
  );
};

Projects.propTypes = {
  tab: number,
  label: string.isRequired,
  handleChangeTab: func,
  queries: object,
  projectType: string,
};

Projects.defaultProps = {
  queries: undefined,
  tab: undefined,
  handleChangeTab: undefined,
  projectType: undefined,
};

export default Projects;
