import { Paper, makeStyles } from '@material-ui/core';
import { number, object, func } from 'prop-types';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  dots: {
    position: 'relative',
    padding: '20px 0 28px',
  },
  dotOuter: {
    width: 8,
    height: 8,
    padding: 4,
    float: 'left',
    position: 'absolute',
  },
  dot: {
    width: 8,
    height: 8,
    background: theme.palette.common.white,
    transition: 'all 400ms cubic-bezier(0.4, 0.0, 0.2, 1)',
    borderRadius: 4,
  },
}));

const Dots = ({ count, index, style, onDotClick, ...other }) => {
  const classes = useStyles();
  const [previousIndex, setPreviousIndex] = useState(index || 0);

  useEffect(() => {
    setPreviousIndex(index);
    let timeout = setTimeout(() => {
      timeout = null;
      setPreviousIndex(index);
    }, 400);

    return () => {
      if (timeout !== null) {
        clearTimeout(timeout);
      }
    };
  }, [index]);

  const handleDotClick = (i, event) => {
    if (onDotClick != null) {
      onDotClick(i, event);
    }
  };

  return (
    <div style={{ ...style, width: count * 16 }} {...other}>
      <div className={classes.dots}>
        {[...Array(count).keys()].map((i) => (
          <div
            role="button"
            key={i}
            className={classes.dotOuter}
            style={{
              left: i * 16,
              cursor: onDotClick != null ? 'pointer' : 'inherit',
            }}
            onClick={(event) => handleDotClick(i, event)}
          >
            <Paper
              elevation={0}
              className={classes.dot}
              style={{
                opacity: i >= Math.min(previousIndex, index) && i <= Math.max(previousIndex, index) ? 0 : 0.5,
              }}
            />
          </div>
        ))}
        <Paper
          elevation={0}
          className={classes.dot}
          style={{
            position: 'absolute',
            marginTop: 4,
            left: Math.min(previousIndex, index) * 16 + 4,
            width: Math.abs(previousIndex - index) * 16 + 8,
          }}
        />
      </div>
    </div>
  );
};

Dots.propTypes = {
  style: object,
  count: number.isRequired,
  index: number.isRequired,
  onDotClick: func,
};

Dots.defaultProps = {
  onDotClick: undefined,
  style: {},
};

export default Dots;
