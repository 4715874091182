import { TextField, CircularProgress, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { object, string, func, bool, arrayOf } from 'prop-types';

const useStyles = makeStyles((theme) => ({
  autoCompleteTextField: {
    background: '#ffffff',
    borderRadius: '6px',
    borderColor: 'white',
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.1)',
  },
  option: {
    color: theme.palette.text.secondary,
  },
}));

const AutocompleteBase = ({ name, options, fieldValue, handleOnChange, loading, desktop, disabled }) => {
  const classes = useStyles();

  return (
    <Autocomplete
      id={`search-${name}`}
      classes={{
        option: classes.option,
      }}
      autoComplete
      options={options || []}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option) => option.id}
      value={fieldValue}
      onChange={(event, value) => {
        handleOnChange(value, name);
      }}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          placeholder={`Search your ${name}`}
          className={classes.autoCompleteTextField}
          size={desktop ? 'medium' : 'small'}
          fullWidth
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            autoComplete: 'new-password',
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

AutocompleteBase.propTypes = {
  name: string.isRequired,
  options: arrayOf(object),
  fieldValue: object,
  handleOnChange: func.isRequired,
  loading: bool,
  disabled: bool,
  desktop: bool.isRequired,
};

AutocompleteBase.defaultProps = {
  options: undefined,
  fieldValue: null,
  loading: false,
  disabled: false,
};

export default AutocompleteBase;
