import { useMutation } from '@apollo/client';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, Box, Grid } from '@material-ui/core';
import { BookmarkBorder as BookmarkBorderIcon } from '@material-ui/icons';
import { bool, func, shape, string } from 'prop-types';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { SAVE_CONTEST_PROJECTS } from 'apollo/mutations/contest-mutation';
import { cookieUserId } from 'helpers/auth';

import PersonalProjectsGallery from './components/PersonalProjectsGallery';
import style from './SubmitProjectsToContestStyle';

const SubmitProjectsToContest = ({
  open,
  options,
  handleCloseDialog,
  handleOpenBackdrop,
  handleCloseBackdrop,
  openSnackbarSuccess,
  openSnackbarError,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { id, projects } = options;
  const existingProjectIds = projects.length !== 0 ? projects.map(({ id }) => id) : [];
  const [selectedProjectIds, setSelectedProjectIds] = useState([]);
  const [submitProjectsToList] = useMutation(SAVE_CONTEST_PROJECTS);

  const handleCheckProject = (selectedProjectId) => {
    if (!selectedProjectIds.includes(selectedProjectId)) {
      setSelectedProjectIds([...selectedProjectIds, selectedProjectId]);
    } else {
      const filteredProjects = selectedProjectIds.filter((item) => item !== selectedProjectId);
      setSelectedProjectIds(filteredProjects);
    }
  };

  const handleSubmitProjectsToContest = async () => {
    if (!cookieUserId) {
      openSnackbarError('Could not submit contest, please contact Admin');
      return;
    }

    try {
      handleCloseDialog();
      handleOpenBackdrop();
      const submitProjectsMutation = await submitProjectsToList({
        variables: {
          contest: {
            projectIds: selectedProjectIds,
            contestId: id,
          },
        },
      });
      const {
        data: { saveContestProjects },
      } = submitProjectsMutation;
      if (saveContestProjects) {
        openSnackbarSuccess('Project submitted!');
        handleCloseDialog();
        handleCloseBackdrop();
        history.push({
          pathname: location.pathname,
          state: { isRefetchContestProjects: true },
        });
      }
    } catch (error) {
      handleCloseBackdrop();
      openSnackbarError(error.message);
    }
  };

  const classes = style();
  return open ? (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="list-project-dialog-title"
      aria-describedby="list-project-description"
      className={classes.dialog}
      maxWidth="md"
    >
      <DialogTitle disableTypography id="deactive-dialog-title">
        <Grid container alignItems="center" alignContent="space-between">
          <Grid item xs>
            <Box display="flex" alignItems="center" className={classes.header}>
              <BookmarkBorderIcon color="primary" className={classes.icon} />
              <Typography color="primary" variant="h6">
                Submit your project
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <DialogActions>
              <Button variant="outlined" className={classes.button} onClick={handleCloseDialog}>
                Cancel
              </Button>
              <Button
                autoFocus
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleSubmitProjectsToContest}
                disabled={selectedProjectIds.length === 0}
              >
                Submit
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <PersonalProjectsGallery
          handleCheckProject={handleCheckProject}
          selectedProjectIds={selectedProjectIds}
          existingProjectIds={existingProjectIds}
        />
      </DialogContent>
    </Dialog>
  ) : null;
};

SubmitProjectsToContest.propTypes = {
  open: bool.isRequired,
  options: shape({
    contestId: string,
  }).isRequired,
  handleCloseDialog: func.isRequired,
  handleOpenBackdrop: func.isRequired,
  handleCloseBackdrop: func.isRequired,
  openSnackbarSuccess: func.isRequired,
  openSnackbarError: func.isRequired,
};

export default SubmitProjectsToContest;
