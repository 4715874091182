import { gql } from '@apollo/client';

// done
// updated
export const ADD_TOPIC = gql`
  mutation createProjectTopic($topic: NewProjectTopic!) {
    createProjectTopic(topic: $topic) {
      id
      name
      isArchived
      createdAt
    }
  }
`;

// done
// updated
export const ADD_COMMENT = gql`
  mutation addProjectComment($comment: NewProjectComment!) {
    addProjectComment(comment: $comment) {
      id
      content
      isArchived
      createdAt
    }
  }
`;

// done
// updated
export const LIKE_COMMENT = gql`
  mutation toggleProjectCommentLike($like: ToggleProjectCommentLike!) {
    toggleProjectCommentLike(like: $like) {
      description
      status
    }
  }
`;

// done
// updated
export const ARCHIVE_COMMENT = gql`
  mutation archiveProjectComment($comment: ArchiveComment!) {
    archiveProjectComment(comment: $comment) {
      description
      status
    }
  }
`;
