import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 0,
  },
  title: {
    color: '#333333',
    fontWeight: 700,
    paddingTop: theme.spacing(0.5),
    '&:hover': {
      color: theme.palette.primary.dark,
    },
    '&:active, &:focus': {
      color: theme.palette.text.primary,
    },
  },
  name: {
    fontSize: 15,
    lineHeight: '20px',
    color: '#575757',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
    '&:active, &:focus': {
      color: theme.palette.text.primary,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
  },
  nameUpdated: {
    fontSize: 15,
    lineHeight: '20px',
    color: '#989898',
    paddingLeft: '4px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
  },
  cardHeaderTitle: {
    padding: 0,
    '& > .MuiCardHeader-avatar': {
      marginRight: 8,
      [theme.breakpoints.down('sm')]: {
        marginRight: 4,
      },
    },
    '& > .MuiCardHeader-action': {
      marginTop: 0,
      marginRight: 0,
    },
    '& > .MuiCardHeader-content': {
      '& > .MuiTypography-h6': {
        fontWeight: '0',
        color: '#000',
      },
    },
  },
  descriptionPadding: {
    padding: theme.spacing(1.5, 0, 2),
    color: '#575757',
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
      paddingBottom: 12,
    },
  },
  interactPadding: {
    display: 'inline-block',
    marginRight: '5px',
  },
  commentText: {
    wordBreak: 'break-word',
    fontSize: '15px',
    color: '#575757',
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
      paddingBottom: 12,
    },
  },
  avatar: {
    height: theme.spacing(4.5),
    width: theme.spacing(4.5),
  },
}));

export default useStyles;
