import { makeStyles } from '@material-ui/core';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Markdown } from 'components';

import EmptyField from '../empty-field/EmptyField';

const useStyles = makeStyles((theme) => ({
  bioText: {
    fontSize: 16,
    '& *': {
      color: theme.palette.common.black,
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
  },
}));

const Bio = ({ bio }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  if (bio) {
    return <Markdown className={classes.bioText}>{bio}</Markdown>;
  }
  return <EmptyField field={t('common.bioLower')} />;
};

Bio.defaultProps = {
  bio: '',
};

Bio.propTypes = {
  bio: string,
};

export default Bio;
