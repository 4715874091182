import { gql } from '@apollo/client';

// done
// updated
export const GET_TUTORIAL_STEPS = gql`
  query stepsByProjectId($projectId: ID!, $nameTerm: String, $page: Int, $limit: PaginationLimit) {
    stepsByProjectId(projectId: $projectId, nameTerm: $nameTerm, page: $page, limit: $limit) {
      id
      stepNumber
      name
      projectId
      createdAt
      updatedAt
    }
  }
`;

// done
// updated
export const GET_STEP_SECTIONS = gql`
  query sectionsByProjectAndStep($projectId: ID!, $projectTutorialStepId: ID!, $page: Int, $limit: PaginationLimit) {
    sectionsByProjectAndStep(
      projectId: $projectId
      projectTutorialStepId: $projectTutorialStepId
      page: $page
      limit: $limit
    ) {
      id
      title
      content
      projectTutorialStepId
      projectId
      createdAt
      updatedAt
    }
  }
`;
