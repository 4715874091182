import { Avatar, Grid, Typography, Box, makeStyles } from '@material-ui/core';
import { bool, func, object, string, number, arrayOf, oneOfType } from 'prop-types';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { getValue, KEYS } from 'helpers/localStorage';
import { getQueryStringParams } from 'helpers/url';

import InputNewReply from '../input-new-reply';

import CommentBody from './components/body/CommentBody';
import CommentExtra from './components/extra/CommentExtra';
import CommentHeader from './components/header/CommentHeader';

import Reply from './index';

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: theme.spacing(2.8, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 0),
    },
  },
  gridContent: {
    paddingTop: theme.spacing(1),
    wordBreak: 'break-all',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  commentBox: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5, 1, 1),
      border: '1px solid #575757',
      width: '100%',
      borderRadius: 6,
      backgroundColor: 'rgba(0,0,0,0.03)',
    },
  },
  repliesContainer: {
    marginTop: theme.spacing(2.8),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
  avatar: {
    marginRight: theme.spacing(2.5),
    width: theme.spacing(7),
    height: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0.75),
      width: theme.spacing(4.5),
      height: theme.spacing(4.5),
    },
  },
  viewMore: {
    color: '#575757',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

const ForumComment = ({
  id,
  user,
  createdAt,
  content,
  isLikedByCurrentUser,
  isDeleted,
  replies,
  totalLikes,
  submitReply,
  addReplyVisible,
  removeComment,
  toggleAddReplyVisible,
  showPreviousRepliesVisible,
  loadMoreReplies,
  desktop,
}) => {
  const classes = useStyles();
  const { firstName, lastName, avatarLink } = user;

  const { hash } = useLocation();
  const resultsRef = useRef();

  const { t } = useTranslation();

  useEffect(() => {
    if (getQueryStringParams('comment', hash, '#') === `${id}`) {
      window.scrollTo({
        top: resultsRef.current.offsetTop - 80,
        behavior: 'smooth',
      });
    }
  }, [hash]);

  return (
    <Grid
      ref={resultsRef}
      key={+id}
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      className={classes.grid}
      spacing={desktop ? 1 : undefined}
      wrap="nowrap"
    >
      <Grid item>
        <Avatar src={avatarLink || getValue(KEYS.avatarLink)} className={classes.avatar} />
      </Grid>
      <Grid container className={classes.gridContent} spacing={desktop ? 2 : undefined}>
        <Box className={classes.commentBox}>
          <CommentHeader name={`${firstName} ${lastName}`} dateCreated={createdAt} />
        </Box>
        <CommentBody content={content} isDeleted={isDeleted} />
        {!isDeleted && (
          <CommentExtra
            projectId={+id}
            userId={+user.id}
            isLiked={isLikedByCurrentUser}
            replies={replies}
            likeCount={totalLikes}
            toggleAddReplyVisible={toggleAddReplyVisible}
            removeComment={removeComment}
            replyCount={replies ? replies.length : 0}
          />
        )}
        {replies && addReplyVisible && (
          <InputNewReply commentId={+id} submitReply={submitReply} toggleAddReplyVisible={toggleAddReplyVisible} />
        )}
        {replies && (
          <div className={classes.repliesContainer}>
            {replies.map((r) => (
              <Reply key={+r.id} comment={r} classes={classes} />
            ))}
            {replies.length > 0 && showPreviousRepliesVisible && (
              <Typography variant="h6" className={classes.viewMore} onClick={loadMoreReplies}>
                {t('common.viewPreviousComment')}
              </Typography>
            )}
          </div>
        )}
      </Grid>
    </Grid>
  );
};

ForumComment.propTypes = {
  addReplyVisible: bool,
  content: string,
  createdAt: string,
  desktop: bool,
  id: oneOfType([number, string]),
  isDeleted: bool,
  isLikedByCurrentUser: bool,
  loadMoreReplies: func,
  removeComment: func,
  replies: arrayOf(object),
  showPreviousRepliesVisible: bool,
  submitReply: func,
  toggleAddReplyVisible: func,
  totalLikes: number,
  user: object,
};

export default ForumComment;
