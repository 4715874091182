import { Typography, makeStyles } from '@material-ui/core';
import { string } from 'prop-types';

const useStyles = makeStyles((theme) => ({
  profileTitles: {
    ...theme.typography.body1,
    fontSize: '1.3rem',
    fontWeight: 900,
    color: '#989898',
    margin: theme.spacing(1, 0),

    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
}));

const ProfileCategory = ({ categoryName }) => {
  const classes = useStyles();

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Typography className={classes.profileTitles} variant="h6">
        {categoryName}
      </Typography>
    </div>
  );
};

ProfileCategory.propTypes = {
  categoryName: string.isRequired,
};

export default ProfileCategory;
