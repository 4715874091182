import { useApolloClient } from '@apollo/client';
import { Tooltip, makeStyles, Button } from '@material-ui/core';
import { BookmarkBorder as BookmarkBorderIcon, ChatBubbleOutline as ChatBubbleOutlineIcon } from '@material-ui/icons';
import { bool, func, number, oneOfType, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { GET_LIST_OF_USER } from 'apollo/queries/list-query';
import { cookieUserId } from 'helpers/auth';
import { convertToSlug } from 'helpers/common';
import { DIALOG } from 'helpers/constants';
import { useSnackbar } from 'hooks';
import { openDialog } from 'reducers/dialogReducer';
import { handleOpenDialogAC3 } from 'reducers/testAC3/cache';

import Follow from '../../../../follow/Follow';
import Like from '../../../../like/Like';

const useStyles = makeStyles((theme) => ({
  buttonIcon: {
    height: '100%',
    '&:not(.disabled)': {
      color: theme.palette.common.white,
      [theme.breakpoints.down('sm')]: {
        color: '#575757',
      },
    },
    borderColor: theme.palette.common.white,
    marginRight: theme.spacing(2),
    padding: theme.spacing(0.8),
    textTransform: 'none',
    borderRadius: '6px',
    borderWidth: '1px',
    '&:hover': {
      background: 'transparent',
      borderWidth: '2px',
      '& *': {
        fontWeight: 'bold',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& svg': {
        fontSize: 18,
      },
      color: '#575757',
      borderColor: '#575757',
      padding: theme.spacing(0.5, 1),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
    },
  },
}));

const ButtonsViewerView = ({ id, name, isBookmarkedByUser, isLikedByUser, refetchProject }) => {
  const classes = useStyles();

  const { openSnackbarWarning } = useSnackbar();

  const client = useApolloClient();

  const { t } = useTranslation(['common', 'dialog']);

  const handleAddToLists = async (e) => {
    e.preventDefault();
    if (cookieUserId) {
      const listQuery = await client.query({
        query: GET_LIST_OF_USER,
        variables: { ownerId: cookieUserId },
      });
      const {
        data: { listsByOwnerId },
      } = listQuery;
      const options = {
        list: listsByOwnerId,
        projectId: id,
        ownerId: cookieUserId,
      };
      handleOpenDialogAC3(DIALOG.LIST_PROJECT, options);
    } else {
      openSnackbarWarning(t('dialog:openSnackbarWarning.reminderLoginFeature'));
    }
  };

  return (
    <>
      {!!cookieUserId && (
        <>
          <Like isLiked={isLikedByUser || false} id={id} refetchProject={refetchProject} />
          <Tooltip title={t('common:button.forum')}>
            <Button
              size="small"
              variant="outlined"
              className={classes.buttonIcon}
              href={`/projects/${id}/${convertToSlug(name)}#tab=forum`}
            >
              <ChatBubbleOutlineIcon />
            </Button>
          </Tooltip>
          <Tooltip title={t('common:button.addList')}>
            <Button size="small" variant="outlined" className={classes.buttonIcon} onClick={handleAddToLists}>
              <BookmarkBorderIcon />
            </Button>
          </Tooltip>
          <Follow isFollowed={isBookmarkedByUser || false} id={+id} />
        </>
      )}
      {/* <Button
        component="span"
        size="small"
        variant="outlined"
        className={classes.button}
      >
        <Typography variant="button">Message</Typography>
      </Button> */}
    </>
  );
};

ButtonsViewerView.propTypes = {
  id: oneOfType([number, string]).isRequired,
  name: string.isRequired,
  isBookmarkedByUser: bool.isRequired,
  isLikedByUser: bool,
  refetchProject: func.isRequired,
};

ButtonsViewerView.defaultProps = {
  // isLikedByUser can be null so can't set boolean type for proptypes
  isLikedByUser: false,
};

export default ButtonsViewerView;
