import { Box, Button, CircularProgress, Divider, Grid, Tooltip, Typography, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { number, arrayOf, bool, func, object, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import PullToRefresh from 'react-simple-pull-to-refresh';

import { cookieUserId } from 'helpers/auth';

import ForumComment from '../comment';
import InputNewComment from '../input-new-comment';

const useStyles = makeStyles((theme) => ({
  container: {},
  btnAddComment: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  divider: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
    },
  },
}));

const ForumDetails = ({
  title,
  projectId,
  topicId,
  comments,
  submitComment,
  submitReply,
  addCommentVisible,
  toggleAddCommentInput,
  loadMoreReplies,
  desktop,
  handleFetchMore,
  canFetchMore,
}) => {
  const classes = useStyles();

  const handleOnFetchMore = () => {
    return new Promise((res) => {
      res(handleFetchMore());
    });
  };

  const { t } = useTranslation();

  return (
    <Grid item xs={12} className={classes.container}>
      <Grid container justify="space-between" alignItems="center">
        <Typography variant="h6" color="textSecondary" gutterBottom>
          #{title}
        </Typography>
        {!!cookieUserId && (
          <Tooltip title={t('sign.signInReminder')}>
            <Button onClick={toggleAddCommentInput} color="primary" className={classes.btnAddComment}>
              {t('button.addComment')}
            </Button>
          </Tooltip>
        )}
      </Grid>
      <Divider className={classes.divider} />
      {addCommentVisible && (
        <InputNewComment submitComment={submitComment} handleCancel={toggleAddCommentInput} desktop={desktop} />
      )}
      <PullToRefresh canFetchMore={canFetchMore} onFetchMore={handleOnFetchMore} fetchMoreThreshold={0}>
        {_.map(comments, (c) => (
          <ForumComment
            key={+c.id}
            comment={c}
            projectId={projectId}
            topicId={+topicId}
            submitReply={submitReply}
            loadMoreReplies={loadMoreReplies}
            desktop={desktop}
          />
        ))}
      </PullToRefresh>
      {canFetchMore && (
        <Box height={100} width="100%" display="flex" alignItems="center" justifyContent="center">
          <CircularProgress color="inherit" />
        </Box>
      )}
    </Grid>
  );
};

ForumDetails.propTypes = {
  title: string,
  comments: arrayOf(object),
  addCommentVisible: bool.isRequired,
  toggleAddCommentInput: func.isRequired,
  submitReply: func,
  submitComment: func.isRequired,
  projectId: number,
  topicId: number,
  loadMoreReplies: func,
  handleFetchMore: func,
  desktop: bool.isRequired,
  canFetchMore: bool.isRequired,
};

export default ForumDetails;
