import { Grid, Fab } from '@material-ui/core';
import { number, arrayOf, object, string, bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CocoonProjectWithOverlay } from 'components';
import { USER_TABS, PARTNER_TABS } from 'helpers/constants';

import ProfileDescription from '../profile-description';

import ListsWithProjects from './lists-with-projects/ListsWithProjects';
import useStyles from './profileProjectsStyle';

const { LISTS_WITH_PROJECTS } = USER_TABS;

const ProfileProjects = ({
  displayProjects,
  displayLists,
  profile,
  profileHidden,
  displayUserInfo,
  currentTab,
  desktop,
  profileType,
  handleMouseOver,
  handleMouseOut,
  expandProjects,
  currentIndex,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { all: allProjects, sliced: slicedProjects } = displayProjects;

  const renderProjects = profileHidden ? allProjects : slicedProjects;

  if (currentTab === LISTS_WITH_PROJECTS) {
    return <ListsWithProjects desktop={desktop} listsWithProjects={displayLists} />;
  }

  const isFromContest = currentTab === PARTNER_TABS.PARTNER_CONTESTS;

  if (!desktop && displayUserInfo) {
    return <ProfileDescription desktop={desktop} profile={profile} profileType={profileType} />;
  }

  return (
    <Grid container>
      {renderProjects.map((project, index) => (
        <Grid
          className={classes.projectWrapper}
          key={project.id}
          onFocus={() => handleMouseOver(index)}
          onMouseOver={() => handleMouseOver(index)}
          onMouseLeave={() => handleMouseOut()}
          item
          xs={!desktop ? 6 : profileHidden ? 3 : 4}
        >
          <CocoonProjectWithOverlay
            project={project}
            isHovered={currentIndex === index}
            isFromContest={isFromContest}
            desktop={desktop}
          />
        </Grid>
      ))}
      {allProjects.length >= 6 && (
        <Fab
          onClick={() => expandProjects(currentTab)}
          variant="extended"
          className={profileHidden ? classes.minimize : classes.displayAll}
        >
          {profileHidden ? t('profile.minimize') : t('profile.viewAllProjects')}
        </Fab>
      )}
    </Grid>
  );
};

ProfileProjects.defaultProps = {
  error: {},
};

ProfileProjects.propTypes = {
  displayProjects: object.isRequired,
  displayLists: arrayOf(object),
  profile: object.isRequired,
  profileHidden: bool.isRequired,
  displayUserInfo: bool.isRequired,
  currentTab: string.isRequired,
  desktop: bool.isRequired,
  profileType: string.isRequired,
  handleMouseOver: func.isRequired,
  handleMouseOut: func.isRequired,
  expandProjects: func.isRequired,
  currentIndex: number.isRequired,
};

export default ProfileProjects;
