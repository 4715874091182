import { Box, Container, Chip, Button, Collapse, Typography, makeStyles } from '@material-ui/core';
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import { number, object, func, bool, arrayOf } from 'prop-types';
import { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import { convertToSlug } from 'helpers/common';
import { getDiffNow } from 'helpers/date';

import { CRUD_STATUS } from '../../helpers/constants';

import FeaturedHeaderButtons from './components/buttons/FeaturedHeaderButtons';
import FeaturedHeaderContent from './components/content/FeaturedHeaderContent';
import ImageFeatured from './components/image-featured/ImageFeatured';

const MIN_HEIGHT = 490;
const MIN_HEIGHT_MOBILE = 350;

const useStyles = (expanded, isView) =>
  makeStyles((theme) => ({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      zIndex: 1,
      width: '100%',
      color: theme.palette.common.white,
      [theme.breakpoints.down('sm')]: {
        backgroundColor: 'rgba(0,0,0,.6)',
        height: isView ? 'calc(100% - 61px)' : '100%',
      },
    },
    sponsors: {
      backgroundColor: 'rgba(0, 0, 0, 0.72)',
      padding: theme.spacing(0.5, 1),
      borderRadius: 6,
      '& > a': {
        fontWeight: 700,
        color: theme.palette.common.white,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 13,
        padding: theme.spacing(0.5, 0),
        backgroundColor: 'transparent',
        color: '#ddd',
        '& > div': {
          height: 20,
        },
        whiteSpace: !expanded && 'nowrap',
        textOverflow: !expanded && 'ellipsis',
        overflow: !expanded && 'hidden',
      },
    },
    chip: {
      marginLeft: theme.spacing(0.5),
      marginBottom: expanded && theme.spacing(1),
    },
    collapse: {
      marginBottom: theme.spacing(3.5),
      position: 'absolute',
      zIndex: 3,
      width: '100%',
      bottom: 0,

      backgroundColor: 'rgba(0,0,0,0.6)',
      '& > h5': {
        fontWeight: 700,
        padding: theme.spacing(1, 1, 0.5, 1),
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        color: theme.palette.common.white,
      },
      '& > button': {
        color: theme.palette.common.white,
        borderRadius: 0,
        '& > span': {
          justifyContent: 'left',
          fontWeight: 400,
        },
      },
    },
    owner: {
      padding: theme.spacing(0, 0.5, 0.5, 1),
      display: 'inline-block',
      color: '#ddd',
      [theme.breakpoints.down('sm')]: {
        fontWeight: 700,
        fontSize: 13,
      },
    },
    time: {
      color: '#ddd',
      [theme.breakpoints.down('sm')]: {
        fontSize: 13,
      },
    },
  }));

const FeaturedHeader = ({
  register,
  control,
  isView,
  loadingButton,
  uploadedFile,
  crudStatus,
  project,
  projectTags,
  handleSubmit,
  handleChangeProject,
  handleChangeProjectTags,
  handleUploadFile,
  handleChangeCrudStatus,
  handleCancelEditMode,
  handleChangeTab,
  disabledSave,
  refetchProject,
  desktop,
}) => {
  const contentWrapperRef = useRef();
  const { assetLink } = project;
  // const [wrapperHeight, setWrapperHeight] = useState();
  const [height, setHeight] = useState(desktop ? MIN_HEIGHT : MIN_HEIGHT_MOBILE);
  const history = useHistory();

  const [expanded, setExpanded] = useState(false);

  const classes = useStyles(expanded, isView)();

  const { t } = useTranslation();

  const handleExpandClick = () => {
    if (expanded) {
      setExpanded(false);
      setHeight(MIN_HEIGHT_MOBILE);
    } else {
      setExpanded(true);
      setHeight(MIN_HEIGHT);
    }
  };

  useEffect(() => {
    if (!desktop) {
      if (crudStatus === CRUD_STATUS.EDIT) {
        setExpanded(true);
        setHeight(MIN_HEIGHT_MOBILE);
      } else {
        setExpanded(false);
        setHeight(MIN_HEIGHT_MOBILE);
      }
    } else {
      setHeight(desktop ? MIN_HEIGHT : MIN_HEIGHT_MOBILE);
    }
  }, [desktop, crudStatus]);

  // useEffect(() => {
  //   if (contentWrapperRef.current && !wrapperHeight) {
  //     setWrapperHeight(contentWrapperRef.current.clientHeight);
  //   }
  //   if (
  //     wrapperHeight &&
  //     contentWrapperRef.current.clientHeight > wrapperHeight
  //   ) {
  //     const gap = contentWrapperRef.current.clientHeight - wrapperHeight;
  //     setHeight((oldHeight) => oldHeight + gap);
  //     setWrapperHeight(contentWrapperRef.current.clientHeight);
  //   }
  // }, [contentWrapperRef.current && contentWrapperRef.current.clientHeight]);

  const renderSponsors =
    project.sponsors && !_.isEmpty(project.sponsors) ? (
      <Box className={classes.sponsors}>
        <b>{t('project.sponsoredBy')} </b>
        {project.sponsors.map(({ id, firstName, lastName }) => (
          <Chip
            key={id}
            onClick={() => history.push(`/users/${id}/${convertToSlug(`${firstName} ${lastName}`)}/profile`)}
            size="small"
            className={classes.chip}
            label={` ${firstName} ${lastName}`}
          />
        ))}
      </Box>
    ) : (
      <Box />
    );

  const featuredContent = (
    <Box className={classes.wrapper} ref={contentWrapperRef}>
      <Container maxWidth={desktop ? 'md' : 'lg'} component={Box} my={desktop ? 3 : 0} mt={desktop ? 0 : 6}>
        <FeaturedHeaderContent
          register={register}
          control={control}
          isView={isView}
          project={project}
          projectTags={projectTags}
          handleChangeTab={handleChangeTab}
          handleChangeProject={handleChangeProject}
          handleChangeProjectTags={handleChangeProjectTags}
          renderSponsors={renderSponsors}
          desktop={desktop}
        />
      </Container>
      {desktop && (
        <Container
          maxWidth="lg"
          component={Box}
          mb={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          {renderSponsors}
          <FeaturedHeaderButtons
            control={control}
            isView={isView}
            project={project}
            loadingButton={loadingButton}
            uploadedFile={uploadedFile}
            disabledSave={disabledSave}
            crudStatus={crudStatus}
            handleSubmit={handleSubmit}
            handleChangeCrudStatus={handleChangeCrudStatus}
            handleUploadFile={handleUploadFile}
            handleCancelEditMode={handleCancelEditMode}
            refetchProject={refetchProject}
            desktop={desktop}
          />
        </Container>
      )}
    </Box>
  );

  const { name, owner, updatedAt } = project;

  return (
    <Box position="relative" minHeight={height}>
      {!desktop && crudStatus === CRUD_STATUS.NEW ? (
        featuredContent
      ) : (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {featuredContent}
        </Collapse>
      )}
      {desktop
        ? featuredContent
        : crudStatus !== CRUD_STATUS.NEW && (
            <Box className={classes.collapse}>
              {!expanded && (
                <>
                  <Typography variant="h5">{name}</Typography>
                  <Box
                    component={Link}
                    to={`/users/${owner.id}/${convertToSlug(`${owner.firstName} ${owner.lastName}`)}/profile`}
                  >
                    <Typography variant="caption" color="textSecondary" className={classes.owner}>
                      {owner.firstName} {owner.lastName} -
                    </Typography>
                  </Box>
                  <Typography variant="caption" color="textSecondary" className={classes.time}>
                    {getDiffNow(updatedAt)}
                  </Typography>
                  <Box pl={1} pb={desktop ? 0 : 1}>
                    {renderSponsors}
                  </Box>
                </>
              )}
              {isView && (
                <Button
                  onClick={handleExpandClick}
                  size="small"
                  fullWidth
                  startIcon={expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowRightIcon />}
                >
                  {expanded ? t('button.hide') : t('button.show')} {t('project.fullDescription')}
                </Button>
              )}
            </Box>
          )}
      <ImageFeatured
        src={assetLink}
        previewImg={uploadedFile}
        isFeatured={project.isFeatured}
        height={height}
        desktop={desktop}
      />
    </Box>
  );
};

FeaturedHeader.propTypes = {
  isView: bool.isRequired,
  loadingButton: bool.isRequired,
  crudStatus: number.isRequired,
  uploadedFile: object,
  project: object.isRequired,
  projectTags: arrayOf(object).isRequired,
  handleChangeProject: func.isRequired,
  handleUploadFile: func.isRequired,
  handleChangeCrudStatus: func.isRequired,
  handleCancelEditMode: func.isRequired,
  handleChangeProjectTags: func.isRequired,
  handleChangeTab: func.isRequired,
  disabledSave: bool,
  refetchProject: func.isRequired,
  desktop: bool.isRequired,
};

FeaturedHeader.defaultProps = {
  uploadedFile: null,
  disabledSave: undefined,
};

export default FeaturedHeader;
