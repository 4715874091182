import { Box, Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { bool, func, number, object } from 'prop-types';
import { useTranslation } from 'react-i18next';

import CreateProjectTutorial from '../../components/section/CreateProjectTutorial';
import TabPanel from '../../components/tab-panel/TabPanel';

import TutorialDetails from './components/details';
import TutorialsList from './components/list';

const useStyles = makeStyles((theme) => ({
  fullHeight: {
    height: '100%',
  },
  leftPanel: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 1.5, 2),
      padding: theme.spacing(1.5, 1),
      border: '1px solid #575757',
      borderRadius: 6,
    },
  },
  rightPanel: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 1.5, 2),
    },
  },
}));

const TutorialTabPanel = ({ desktop, tab, project: { id, ownerId }, stepActive, handleChangeStep }) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const renderTutorials = () => (
    <>
      {stepActive === -1 ? (
        <Box fontWeight="fontWeightBold">{t('defaultInformation.noTutorials')}</Box>
      ) : (
        <TutorialDetails stepActive={stepActive} projectId={id} ownerId={ownerId} desktop={desktop} />
      )}
    </>
  );

  const isNewProject = +id === undefined;

  return (
    <TabPanel tab={tab} index={3}>
      <Grid item xs={desktop ? 5 : 12} className={clsx(classes.fullHeight, classes.leftPanel)}>
        <TutorialsList
          desktop={desktop}
          projectId={id}
          stepActive={stepActive}
          isNewProject={isNewProject}
          handleChangeStep={handleChangeStep}
          ownerId={ownerId}
        />
      </Grid>
      <Grid item xs={desktop ? 7 : 12} className={clsx(classes.fullHeight, classes.rightPanel)}>
        {isNewProject ? <CreateProjectTutorial desktop={desktop} /> : renderTutorials()}
      </Grid>
    </TabPanel>
  );
};

TutorialTabPanel.propTypes = {
  desktop: bool.isRequired,
  stepActive: number,
  tab: number.isRequired,
  project: object.isRequired,
  handleChangeStep: func.isRequired,
};

export default TutorialTabPanel;
