import { Divider, Grid, makeStyles } from '@material-ui/core';
import { number, arrayOf, object, bool, func, string, oneOfType } from 'prop-types';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { cookieUserId } from 'helpers/auth';
import { getQueryStringParams } from 'helpers/url';

import ButtonLike from './components/button-like';
import ButtonRemove from './components/button-remove/ButtonRemove';
import ButtonReply from './components/button-reply/ButtonReply';

const useStyles = makeStyles((theme) => ({
  commentExtraContainer: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(-1),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
}));

const CommentExtra = ({
  userId,
  projectId,
  replies,
  toggleAddReplyVisible,
  removeComment,
  isLiked,
  likeCount,
  replyCount,
}) => {
  const classes = useStyles();
  const { hash } = useLocation();
  const resultsRef = useRef();

  useEffect(() => {
    if (getQueryStringParams('reply', hash, '#') === `${projectId}`) {
      window.scrollTo({
        top: resultsRef.current.offsetTop - 200,
        behavior: 'smooth',
      });
      toggleAddReplyVisible();
    }
  }, [hash]);

  return (
    <Grid ref={resultsRef} className={classes.commentExtraContainer} container>
      <ButtonLike isLiked={isLiked} likeCount={likeCount} projectId={projectId} />
      {replies && (
        <>
          <Grid item>
            <Divider orientation="vertical" />
          </Grid>
          <ButtonReply replyCount={replyCount} handleClick={toggleAddReplyVisible} />
        </>
      )}
      {+cookieUserId === +userId && (
        <>
          <Grid item>
            <Divider orientation="vertical" />
          </Grid>
          <ButtonRemove handleClick={removeComment} />
        </>
      )}
    </Grid>
  );
};

CommentExtra.propTypes = {
  isLiked: bool,
  likeCount: number,
  projectId: oneOfType([number, string]),
  removeComment: func,
  replies: arrayOf(object),
  replyCount: number,
  toggleAddReplyVisible: func,
  userId: number,
};

export default CommentExtra;
