import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

// INITIAL STATE
const initState = {
  isEditing: false,
};

// ACTION CREATORS
export const { isEdit, isRead } = createActions({
  IS_EDIT: undefined,
  IS_READ: undefined,
});

// REDUCERS
export default handleActions(
  {
    [isEdit](state) {
      return { ...state, isEditing: true };
    },
    [isRead](state) {
      return { ...state, isEditing: false };
    },
  },
  initState,
);

// SELECTORS
const selectorMode = (state) => state.panel;

export const getModeStore = createSelector([selectorMode], (mode) => mode);
export const getModeIsEditing = createSelector([selectorMode], (mode) => mode.isEditing);
