import { Typography, Box, Grid, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { string, number, object, arrayOf, bool } from 'prop-types';
import { Link } from 'react-router-dom';

import { ProjectMoreButton } from 'components';
import { cookieUserId } from 'helpers/auth';
import { convertToSlug } from 'helpers/common';
import { getDiffNow } from 'helpers/date';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    wordBreak: 'break-word',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
    '&:active, &:focus': {
      color: theme.palette.text.primary,
    },
  },
  para: {
    wordBreak: 'break-word',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#575757',
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
  },
  category: {
    fontWeight: 'bold',
    width: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
      lineHeight: '1rem',
    },
  },
  owner: {
    display: 'block',
    fontWeight: 700,
    color: '#575757',
    width: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '& > div': {
      display: 'inline-block',
    },
    '&:hover': {
      color: theme.palette.primary.dark,
    },
    '&:active, &:focus': {
      color: theme.palette.text.primary,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(1.5),
    },
  },
  time: {
    color: '#989898',
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
      display: 'flex',
      alignItems: 'center',
    },
  },
  divider: {
    height: '1rem',
    marginRight: theme.spacing(2),
  },
  more: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    display: 'block',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
}));

const ProjectCardInfo = ({ id, owner, ownerId, name, description, updatedAt, tags, desktop }) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12}>
        <Grid
          component={Typography}
          variant={desktop ? 'h4' : 'h5'}
          gutterBottom
          container
          alignItems="center"
          justify="space-between"
        >
          <Grid
            item
            xs
            display="block"
            component={Link}
            to={`/projects/${id}/${convertToSlug(name)}`}
            className={classes.title}
          >
            {name}
          </Grid>
          {cookieUserId && !desktop && (
            <Grid item xs={1} style={{ marginRight: 8 }}>
              <ProjectMoreButton id={+id} ownerId={ownerId} name={name} />
            </Grid>
          )}
        </Grid>
        {!_.isEmpty(tags) && (
          <Box display="flex" alignItems="center" mb={desktop ? 2 : 1}>
            <Typography variant="h6" color="secondary" className={classes.category}>
              {tags.map((tag) => tag.tagName).join(', ')}
            </Typography>
          </Box>
        )}
        <Typography className={classes.para} variant="body1" color="textSecondary">
          {description}
        </Typography>
      </Grid>
      <Grid item xs={12} container alignItems="flex-end" justify="space-between">
        <Grid item>
          <Box
            component={Link}
            to={`/users/${owner.id}/${convertToSlug(`${owner.firstName} ${owner.lastName}`)}/profile`}
          >
            <Typography variant="caption" color="textSecondary" className={classes.owner}>
              {owner.firstName} {owner.lastName}
            </Typography>
          </Box>
          <Typography variant="caption" color="textSecondary" className={classes.time}>
            {getDiffNow(updatedAt)}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

ProjectCardInfo.propTypes = {
  name: string.isRequired,
  id: number.isRequired,
  ownerId: number,
  description: string.isRequired,
  updatedAt: string.isRequired,
  tags: arrayOf(object).isRequired,
  desktop: bool.isRequired,
  owner: object.isRequired,
};

ProjectCardInfo.defaultProps = {
  ownerId: undefined,
};

export default ProjectCardInfo;
