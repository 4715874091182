import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: '0.5em 0 1em 0',
    backgroundColor: '#989898',
    border: 'solid 0.5px #e6e6e6',
    [theme.breakpoints.down('sm')]: {
      margin: '0.25em 0 0.5em 0',
    },
  },
  editInfoWrapper: {
    marginTop: '15px',
  },
  editInfoTitle: {
    ...theme.typography.h1,
    color: '#575757',
    fontSize: '16px',
    fontWeight: '700',
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.h6,
      fontSize: 13,
    },
  },
}));

export default useStyles;
