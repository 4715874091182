import { gql } from '@apollo/client';

const FRAGMENT = gql`
  fragment countryFragment on Country {
    id
    iso
    name
  }
`;

// done
// updated
export const GET_COUNTRIES = gql`
  query countries($page: Int, $limit: PaginationLimit) {
    countries(page: $page, limit: $limit) {
      ...countryFragment
    }
  }
  ${FRAGMENT}
`;
