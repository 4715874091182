import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  makeStyles,
} from '@material-ui/core';
import { LockOpen as LockOpenIcon } from '@material-ui/icons';
import { bool, object, func } from 'prop-types';
import { useState } from 'react';

import log from 'helpers/log';

import PermissionSettings from './components/PermissionSettings';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '678px',
    },
  },
  header: {
    color: '#414141',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  button: {
    borderColor: theme.palette.text.secondary,
    padding: theme.spacing(0.25, 1.5),
    marginLeft: theme.spacing(1),
    textTransform: 'none',
    borderRadius: '6px',
    borderWidth: '1px',
  },
  formControl: {
    '& legend': {
      ...theme.typography.caption2,
      marginBottom: theme.spacing(1),

      '& > span.MuiFormLabel-asterisk': {
        color: '#C53C2A',
      },
    },
    '& label > span:last-child': {
      ...theme.typography.button,
      fontSize: '14px',
      fontWeight: 'normal',
    },
    '& legend, & legend.Mui-focused, & label > span:last-child': {
      color: '#414141',
    },
    '& label > span.Mui-checked': {
      color: '#C34674',
    },
  },
  radioGroup: {
    flexDirection: 'row',
  },
  text: {
    fontWeight: theme.typography.fontWeightBold,
    color: '#575757',
  },
}));

const PRIVACY = {
  PUBLIC: 'public',
  PRIVATE: 'private',
  PERMISSION: 'permission',
};

const PrivacyDialog = ({ open, options, handleCloseDialog }) => {
  const classes = useStyles();
  const { t } = useTranslation(['dialog', 'common']);
  const [privacy, setPrivacy] = useState(PRIVACY.PUBLIC);

  const handleChangePrivacy = ({ target: { value } }) => {
    setPrivacy(value);
  };

  const handleSetPrivacyProject = (e) => {
    e.preventDefault();
    log.info(options);
  };

  return open ? (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="privacy-dialog-title"
      aria-describedby="privacy-dialog-description"
      className={classes.dialog}
      maxWidth="md"
    >
      <DialogTitle disableTypography id="privacy-dialog-title">
        <Grid container alignItems="center" alignContent="space-between">
          <Grid item xs>
            <Typography color="textSecondary" variant="h5">
              <Box display="flex" alignItems="center" className={classes.header}>
                <LockOpenIcon color="primary" className={classes.icon} />
                {t('dialog:privacy.setPrivacy')}
              </Box>
            </Typography>
          </Grid>
          <Grid item xs>
            <DialogActions>
              <Button variant="outlined" className={classes.button} onClick={handleCloseDialog}>
                {t('common:button.cancel')}
              </Button>
              <Button
                autoFocus
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleSetPrivacyProject}
              >
                {t('common:button.save')}
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Box my={2}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">{t('dialog:privacy.privacyLevel')}</FormLabel>
            <RadioGroup
              aria-label="privacy"
              name="privacy-group"
              value={privacy}
              onChange={handleChangePrivacy}
              className={classes.radioGroup}
            >
              <FormControlLabel
                value={PRIVACY.PRIVATE}
                control={<Radio size="small" />}
                label={t('common:common.onlyMe')}
              />
              <FormControlLabel
                value={PRIVACY.PERMISSION}
                control={<Radio size="small" />}
                label={t('dialog:privacy.permissionRequired')}
              />
              <FormControlLabel
                value={PRIVACY.PUBLIC}
                control={<Radio size="small" />}
                label={t('common:common.public')}
              />
            </RadioGroup>
          </FormControl>
          {privacy === PRIVACY.PERMISSION && <PermissionSettings />}
        </Box>
      </DialogContent>
    </Dialog>
  ) : null;
};

PrivacyDialog.propTypes = {
  open: bool.isRequired,
  options: object.isRequired,
  handleCloseDialog: func.isRequired,
};

export default PrivacyDialog;
