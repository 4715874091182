import { Avatar, Badge, Box, Link, Typography, makeStyles, withStyles } from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import { AvatarGroup } from '@material-ui/lab';
import _ from 'lodash';
import { bool, element, func, object } from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Tags } from 'components';
import { getDisplayName } from 'helpers/common';
import { getDiffNow } from 'helpers/date';

const useStyles = makeStyles((theme) => ({
  headerLink: {
    color: theme.palette.common.white,
    fontWeight: 700,
  },
  divider: {
    height: '1rem',
    backgroundColor: '#F5F5F5',
    margin: theme.spacing(2),
  },
  time: {
    minWidth: 200,
    color: '#F5F5F5',
    '& > div': {
      display: 'inline-block',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(1),
    },
  },
  description: {
    color: theme.palette.common.white,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
      textAlign: 'left',
      wordBreak: 'break-word',
      padding: theme.spacing(1),
      backgroundColor: 'rgba(0,0,0,.72)',
      borderRadius: 6,
      margin: theme.spacing(0, -1),
    },
  },
  avatars: {
    margin: theme.spacing(0, 2, 0, 3),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 2, 0, 1.5),
    },
  },
  link: {
    color: '#E6E6E6',
    textDecoration: 'underline',
  },
  created: {
    color: '#E6E6E6',
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
  },
  avatarIcon: {
    borderRadius: '50%',
  },
  certifiedIcon: {
    color: '#fff',
    backgroundColor: '#FAC405',
    borderRadius: '50%',
    height: '0.7em',
    width: '0.7em',
  },
}));

const SmallAvatarStyles = withStyles({
  root: {
    maxWidth: 36,
    maxHeight: 36,
  },
})((props) => <Avatar {...props} />);

const SmallBadgeStyles = withStyles({
  root: {
    maxWidth: 40,
    maxHeight: 40,
  },
})((props) => <Badge {...props} />);

const ContentView = ({
  control,
  project: { name: projectName, description: projectDescription, tags, updatedAt, members },
  handleChangeTab,
  renderSponsors,
  desktop,
}) => {
  const classes = useStyles();

  const name = useWatch({
    control,
    name: 'name',
    defaultValue: projectName || '',
  });

  const description = useWatch({
    control,
    name: 'description',
    defaultValue: projectDescription || '',
  });

  const { t } = useTranslation();

  const renderTime = (
    <Box display="flex" alignItems="center" justifyContent="center" mb={desktop ? 2 : 1}>
      <Typography variant="caption" className={classes.time}>
        <b>{getDiffNow(updatedAt)}</b>
      </Typography>
    </Box>
  );

  return (
    <>
      <Typography variant={desktop ? 'h2' : 'h5'} color="textSecondary" className={classes.headerLink} gutterBottom>
        {name}
      </Typography>
      {!_.isEmpty(tags) && (
        <Box display="flex" alignItems="center" justifyContent="center" mb={desktop ? 2 : 0}>
          <Tags tags={tags} />
        </Box>
      )}
      {renderTime}
      <Typography variant="body1" color="textSecondary" className={classes.description} gutterBottom>
        {description}
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="center" my={desktop ? 2 : 1}>
        <Typography variant="h6" className={classes.created}>
          {t('project.createdBy')}
        </Typography>
        <AvatarGroup className={classes.avatars}>
          {_.map(members, (m) => {
            return (
              <SmallBadgeStyles
                key={m.id}
                badgeContent={m.certified ? <CheckIcon className={classes.certifiedIcon} /> : null}
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                className={classes.avatarIcon}
              >
                <SmallAvatarStyles alt={getDisplayName(m)} src={m.avatarLink} />
              </SmallBadgeStyles>
            );
          })}
        </AvatarGroup>
        <Link
          href="#"
          onClick={(e) => {
            handleChangeTab(e, 2);
          }}
        >
          <Typography variant="caption" color="textSecondary" className={classes.link}>
            {t('button.seeAllMember')}
          </Typography>
        </Link>
      </Box>
      {!desktop && renderSponsors}
    </>
  );
};

ContentView.propTypes = {
  project: object.isRequired,
  handleChangeTab: func.isRequired,
  desktop: bool.isRequired,
  renderSponsors: element,
};

export default ContentView;
