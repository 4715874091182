import { InputBase, InputAdornment, IconButton, makeStyles } from '@material-ui/core';
import { Cancel as CancelIcon } from '@material-ui/icons';
import _ from 'lodash';
import { arrayOf, object, func, string, number, bool } from 'prop-types';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { isEmpty } from 'helpers/common';

import { useTranslation } from 'react-i18next';

const useStyles = (isLightView) =>
  makeStyles(({ palette, spacing, breakpoints }) => ({
    addTag: {
      color: isLightView ? '#545454' : palette.common.white,
      padding: spacing(0, 1),
      margin: spacing(0.5),
      textTransform: 'none',
      borderRadius: '6px',
      borderWidth: '1px',
      width: '6.5em',
      '& *': {
        fontWeight: 'bold',
      },
      '&.Mui-focused': {
        color: palette.common.white,
        backgroundColor: '#989898',
      },
      '&:hover': {
        cursor: 'pointer',
        color: palette.common.white,
        backgroundColor: '#989898',
        borderWidth: '2px',
      },
      [breakpoints.down('sm')]: {
        '& > *': {
          fontSize: 14,
        },
      },
    },
    clear: {
      padding: 0,
      '& svg': {
        width: '22px',
        height: '22px',
        color: palette.common.white,
      },
    },
  }));

const InputNewTag = ({ tagPlaceholder, label, tags, setTags, openSnackbarError, tagSizeLimit, isLightView }) => {
  const classes = useStyles(isLightView)();
  const { register, handleSubmit, watch, setValue, errors } = useForm();
  const tagValue = watch('tag', '');

  const { t } = useTranslation(['common', 'dialog']);

  const handleClearInput = () => {
    setValue('tag', '');
  };

  const handleMouseDownInput = (e) => {
    e.preventDefault();
  };

  const handleOnSubmit = ({ tag }, e) => {
    e.preventDefault();
    if (!isEmpty(tagValue)) {
      if (tagValue.length > tagSizeLimit) {
        openSnackbarError(`${t('dialog:openSnackbarError.textLength')} ${tagSizeLimit}`);
        return;
      }

      const newTags = [...tags, { id: null, [label]: tag }];

      setTags(newTags);
      handleClearInput();
    }
  };

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)} noValidate autoComplete="off">
      <InputBase
        name="tag"
        type="text"
        placeholder={tagPlaceholder}
        className={classes.addTag}
        value={tagValue}
        inputRef={register}
        onBlur={handleSubmit(handleOnSubmit)}
        endAdornment={
          !_.isEmpty(tagValue) && (
            <InputAdornment position="end">
              <IconButton
                aria-label="clear input"
                onClick={handleClearInput}
                onMouseDown={handleMouseDownInput}
                className={classes.clear}
              >
                <CancelIcon />
              </IconButton>
            </InputAdornment>
          )
        }
      />
    </form>
  );
};

InputNewTag.propTypes = {
  tags: arrayOf(object).isRequired,
  tagPlaceholder: string.isRequired,
  label: string.isRequired,
  setTags: func,
  openSnackbarError: func.isRequired,
  tagSizeLimit: number.isRequired,
  isLightView: bool.isRequired,
};

InputNewTag.defaultProps = {
  setTags: undefined,
};

export default InputNewTag;
