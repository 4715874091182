import { CardContent, Typography, Grid, Card, Divider, makeStyles } from '@material-ui/core';
import { func, object, arrayOf, number } from 'prop-types';

import { Tags } from 'components';

import { CRUD_STATUS } from '../../../../helpers/constants';

import { useTranslation } from 'react-i18next';
const useStyles = makeStyles((theme) => ({
  fullSize: {
    width: '100%',
    height: '100%',
    paddingTop: theme.spacing(1),
  },
  publishedTitle: {
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.1px',
    fontWeight: theme.typography.fontWeightBold,
    color: '#59A76A',
    paddingRight: '5px',
  },
  cardStyle: {
    backgroundColor: '#ffffff',
  },
  title: {
    ...theme.typography.h6,
    fontWeight: theme.typography.fontWeightBold,
    color: '#575757',
    letterSpacing: '0.1px',
  },
  divider: {
    width: '100%',
    backgroundColor: '#989898',
    marginTop: theme.spacing(1),
    border: '1px solid #989898',
  },
  addTagButton: {
    marginTop: theme.spacing(1),
  },
}));

const TagField = ({ tags, handleChangeUpdateTags, crudStatus }) => {
  const classes = useStyles();

  const { t } = useTranslation(['panel', 'common']);
  return (
    <div className={classes.fullSize}>
      <Card className={classes.cardStyle}>
        <CardContent>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} container direction="row">
              <Typography className={classes.title} variant="subtitle1">
                {t('panel:tags')}
              </Typography>
            </Grid>
            <Divider variant="middle" className={classes.divider} />
            <Grid
              item
              xs={12}
              className={classes.addTagButton}
              container
              direction="row"
              alignItems="center"
              justify="flex-start"
            >
              {crudStatus === CRUD_STATUS.EDIT ? (
                <Tags isEditMode tags={tags} label="name" setTags={handleChangeUpdateTags} isChips isLightView />
              ) : (
                <Tags tags={tags} isChips label="name" isLightView />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

TagField.propTypes = {
  tags: arrayOf(object.isRequired),
  handleChangeUpdateTags: func.isRequired,
  crudStatus: number.isRequired,
};

export default TagField;
