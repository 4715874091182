import { Grid } from '@material-ui/core';
import _ from 'lodash';
import { object, bool } from 'prop-types';
import { useRouteMatch } from 'react-router-dom';

import mimeTypes from 'helpers/mimeTypes';

import TabSideMenu from '../../../../components/side-menu/TabSideMenu';

import BriefFiles from './component/BriefFiles';
import BriefGallery from './component/BriefGallery';
import BriefTeam from './component/BriefTeam';

const BriefLeftPanel = ({
  project: { attachments, members, ownerId },
  loadingAttachments,
  errorAttachments,
  desktop,
  ...projectProps
}) => {
  const { png, jpg, gif, bmp, ico, tif, svg } = mimeTypes;

  const files = attachments ? [...attachments] : null;
  const images = files ? _.remove(files, (file) => [png, jpg, gif, bmp, ico, tif, svg].includes(file.assetType)) : null;

  const match = useRouteMatch();

  const { id } = match.params;
  const isNewProject = id === undefined;

  const attachmentsProps = {
    loadingAttachments,
    errorAttachments,
    isNewProject,
    desktop,
  };

  return (
    <Grid container spacing={4}>
      <TabSideMenu
        desktop={desktop}
        components={
          <>
            <BriefGallery images={images} desktop={desktop} {...attachmentsProps} />
            <BriefFiles files={files} desktop={desktop} {...attachmentsProps} />
            <BriefTeam
              isNewProject={isNewProject}
              members={members}
              ownerId={ownerId}
              desktop={desktop}
              {...projectProps}
            />
          </>
        }
      />
    </Grid>
  );
};

BriefLeftPanel.propTypes = {
  project: object.isRequired,
  loadingAttachments: bool,
  desktop: bool.isRequired,
  errorAttachments: object,
};

BriefLeftPanel.defaultProps = {
  loadingAttachments: false,
  errorAttachments: undefined,
};

export default BriefLeftPanel;
