import { Paper, Grid, makeStyles } from '@material-ui/core';

import TaskContent from './TaskContent.js';

const useStyles = makeStyles((theme) => ({
  fontWeight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  paperStyle: {
    backgroundColor: '#EBEBEB',
    height: 'auto',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: '6px',
  },
  buttonStyle: {
    display: 'flex',
    paddingRight: 0,
    paddingTop: 0,
    justifyContent: 'flex-end',
  },
  h5Custom: {
    fontStyle: 'normal',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '20px',
    // lineHeight:'24px',
    letterSpacing: '0.1px',
    color: '#575757',
  },
  h6Custom: {
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.1px',
  },
  publishButton: {
    paddingLeft: 5,
    paddingRight: 5,
    textDecoration: 'underline',
    fontWeight: theme.typography.fontWeightBold,
    color: '#989898',
  },
  fullSize: {
    width: '100%',
    height: '100%',
  },
}));

const Task = () => {
  const classes = useStyles();

  return (
    <div className={classes.fullSize}>
      <Grid item xs={12}>
        <Paper className={classes.paperStyle}>
          <Grid container direction="column" justify="flex-start" alignItems="center">
            <TaskContent />
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
};

Task.propTypes = {};

export default Task;
