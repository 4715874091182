import _ from 'lodash';

export default (projectCurrent, projectSubmit, projectTags) => {
  const { name, description, assetLink, projectOverview, ownerId, tags } = projectCurrent;
  const projectToCompare = {
    name,
    description,
    assetLink,
    projectOverview,
    id: projectCurrent.id,
    ownerId,
  };

  return (
    _.isEqual(projectToCompare, projectSubmit) &&
    tags.length === projectTags.length &&
    _.isEmpty(_.differenceWith(tags, projectTags, _.isEqual))
  );
};
