// eslint-disable-next-line import/prefer-default-export
export const isValidUrl = (str) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
};

export const getQueryStringParams = (params, url, queryHash = '?') => {
  // first decode URL to get readable data
  const href = decodeURIComponent(url || window.location.href);
  // regular expression to get value
  const regEx = new RegExp(`[${queryHash}&]${params}=([^&#]*)`, 'i');
  const value = regEx.exec(href);
  // return the value if exist
  return value ? value[1] : null;
};
