import React from 'react';
function withProjects(ProjectsRenderer, data) {
  return class extends React.Component {
    componentDidMount() {
      console.log('mounted, query: ', data);
      console.log(this.props);
    }
    render() {
      return <ProjectsRenderer {...this.props} />;
    }
  };
}

export default withProjects;
