import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

// INITIAL STATE
const initState = {
  files: [],
  tags: [],
};

// ACTION CREATORS
export const { updateProjectAttachments, updateProjectUpdateTags, removeProjectAttachment, resetProject } =
  createActions({
    UPDATE_PROJECT_ATTACHMENTS: (files) => ({ files }),
    UPDATE_PROJECT_UPDATE_TAGS: (tags) => ({ tags }),
    REMOVE_PROJECT_ATTACHMENT: (index) => ({ index }),
    RESET_PROJECT: undefined,
  });

// REDUCERS
export default handleActions(
  {
    [updateProjectAttachments](state, { payload: { files } }) {
      return { ...state, files: [...state.files, files] };
    },
    [updateProjectUpdateTags](state, { payload: { tags } }) {
      return { ...state, tags };
    },
    [removeProjectAttachment](state, { payload: { index } }) {
      const { files } = state;
      return { ...state, files: files.splice(index, 1) };
    },
    [resetProject]() {
      return initState;
    },
  },
  initState,
);

// SELECTORS
const selectorProject = (state) => state.project;

export const getProjectState = createSelector([selectorProject], (project) => project);
