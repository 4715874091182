import _ from 'lodash';

import { Button } from '@material-ui/core';

const CLICK_DELAY = 500;

const DebounceButton = ({ children, onClick, content, ...props }) => (
  <Button {...props} onClick={_.debounce(onClick, CLICK_DELAY)}>
    {children}
    {content}
  </Button>
);

export default DebounceButton;
