import { Box, Container, CircularProgress, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import { string, bool, object, arrayOf } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { hex2rgba } from 'helpers/common';
import { PROJECT_TYPE } from 'helpers/constants';

import { ProjectsHeader } from '@projectcocoon/web-component';
import ProjectsLayout from './components/layout/ProjectsLayout';

const { SUBS_TYPE, WANT_TYPE } = PROJECT_TYPE;
const isSub = (type) => [SUBS_TYPE, WANT_TYPE].includes(type);

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    paddingLeft: 0,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 0),
      marginTop: 0,
    },
  },
  divider: {
    margin: theme.spacing(4, 0, 2),
    backgroundColor: '#ababab',
  },
  boxFeatured: {
    marginBottom: theme.spacing(4),
  },
  header: {
    '& h5': {
      lineHeight: '35px',
    },
    backgroundColor: hex2rgba(theme.palette.primary.main, 0.05),
    padding: theme.spacing(0.25, 1),
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 6,
  },
}));

const HomeProjects = ({ type, loading, error, projects, label, ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const renderProjectsCatFeat = () => {
    const getFilteredProjects = (isFeatured) =>
      _.chain(projects).filter({ isFeatured }).orderBy(['updatedAt'], ['desc']).value();

    const projectsFeatured = getFilteredProjects(true);
    const projectsNonFeatured = getFilteredProjects(false);

    return <ProjectsLayout type={type} projects={[...projectsFeatured, ...projectsNonFeatured]} {...props} />;
  };

  return (
    <Container fixed className={classes.container}>
      {loading ? (
        <Box height="100px" width="100%" display="flex" alignItems="center" justifyContent="center">
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <>
          <ProjectsHeader
            isBig={!isSub(type)}
            allProjectsLink={`/projects?type=${_.toLower(type)}`}
            btnSeeMoreLabel={t('button.seeMore')}
            shouldDisplayBtnSeeMore={true}
          >
            {label}
          </ProjectsHeader>
          {error ? (
            <Alert variant="outlined" severity="error">
              {error.message}
            </Alert>
          ) : (
            renderProjectsCatFeat()
          )}
        </>
      )}
    </Container>
  );
};

HomeProjects.propTypes = {
  projects: arrayOf(object).isRequired,
  type: string.isRequired,
  loading: bool.isRequired,
  error: object,
  divider: bool,
};

HomeProjects.defaultProps = {
  divider: false,
  error: undefined,
};

export default HomeProjects;
