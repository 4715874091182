export default {
  heroHome: {
    boxDescription: 'Show, manage, and document your work.',
    spanDescription: 'Meanwhile, socialize and connect with other creators',
  },
  about: {
    industrialPartners: {
      skyPCB: {
        name: 'skyPCB',
        saleOff:
          '- <span className="saleoff">10% off</span> for your first design, free-of-charge setup fee from your second design\n- 24h PCB manufacturing',
        description: '',
      },
      makerStore: {
        name: 'MakerStore',
        saleOff: '- <span className="saleoff">10% off</span>',
        description: '',
      },
      HTPro: {
        name: 'HTPro',
        saleOff: '- <span className="saleoff">5% off</span>',
        description: '- Electronic components and modules',
      },
      _3DMeo: {
        name: '3DMeo',
        saleOff: '- <span className="saleoff">10% off</span>',
        description: '- 3D printing and laser cutting',
      },
      elec2PCB: {
        name: 'Elec2PCB',
        saleOff: '- <span className="saleoff">5% off</span>',
        description: '- SMT service and PCB manufacturing',
      },
      fabLabSaigon: {
        name: 'Fab Lab Saigon',
        saleOff: '- <span className="saleoff">10% off</span>',
        description: '- Services offered at Fab Lab Saigon',
      },
    },
    educationalPartners: {
      elec2PCB: {
        name: 'Elec2PCB',
        saleOff: '- <span className="saleoff">25% off</span>',
        description: ['- PCB design from basic to professional', '- Programming STM32'],
      },
      devIoT: {
        name: 'DevIoT',
        description: '- ESP32/8266 and IoT',
      },
    },
    aboutUs: {
      showCase: {
        title: 'Showcase your projects',
        description: ['Build your audience from the beginning through series of logs and updates.'],
      },
      buildProjet: {
        title: 'Build your profiles',
        description: [
          'Create your interactive portfolio and measure your community impact by simply sharing your first projects on Project Cocoon.',
        ],
      },
      collaborative: {
        title: 'Collaborate with members',
        sentences: ['Keep track tasks and progress of your projects through our dedicated PMS tools.'],
      },
      communityFeedback: {
        title: 'Get community feedback',
        sentences: [
          'Get feedback on your projects from our network of mentors and Project Cocoon community from the early days.',
        ],
      },
      industrialPartnersNetwork: {
        title: 'Find what you need at a much lower cost from our network of industrial partners',
      },
    },
  },
  hero: {
    banner: {
      boxDescription: 'Show, manage, and document your work.',
      spanDescription: 'Meanwhile, socialize and connect with other creators',
    },
  },
  logHome: {
    logCard: {
      attachments: {
        download: 'Download',
      },
      layout: {
        updated: 'updated',
      },
    },
    logTitle: {
      seeMore: 'See more',
    },
  },
  notification: {
    desktop: {
      newUpdate: 'New updates for Project Cocoon. Check it out now!',
      newFeature: 'New features from September, 2020 has been released.',
      content: 'View it now. Content goes here by the content team!',
    },
    mobile: {
      newUpdate: 'New updates for Project Cocoon!',
      newFeature: 'New features from September, 2020 has been released.',
    },
  },
  project: {
    layoutDefault: {
      no: 'No ',
      logs: 'logs',
      projects: 'projects',
      available: ' available',
    },
    home: {
      projectFeatured: {
        label: "What's good?",
      },
      projectDiscover: {
        label: 'Discover more great ideas',
      },
      latestProjectUpdate: {
        label: 'Weekly breakthroughs',
      },
      latestCommentForum: {
        label: 'Forum',
      },
      newLogs: {
        label: 'New logs',
      },
    },
  },
  profile: {
    panel: {
      profileDescription: {
        bio: 'bio',
        emptyField: {
          no: 'No',
          toShow: 'to show',
        },
        profileDescription: {
          description: 'Description',
          bio: 'Bio',
          fieldsOfWork: 'Fields Of Work',
          emptyField: 'fields of work',
          experience: 'Experience',
          contests: 'Contests',
        },
      },
      profileProject: {
        listsWithProject: {
          deleteList: 'Delete list',
        },
        projectPopover: {
          addToLists: 'Add to lists',
          addToList: 'Add to list',
          reactivate: 'Reactivate',
          deactivate: 'Deactivate',
        },
        profileProject: {
          minimize: 'Minimize',
          viewAllProjects: 'View All Projects',
        },
      },
    },
    profileNavigation: {
      info: 'Info',
      projects: 'Projects',
      following: 'Following',
      lists: 'Lists',
      archived: 'Archived',
      // profileNavigation:  [
      //     {
      //         id: -1,
      //         key: USER_INFO,
      //         title: 'Info',
      //     },
      //     {
      //         id: 0,
      //         key: PERSONAL_PROJECTS,
      //         title: 'Projects',
      //     },
      //     {
      //         id: 1,
      //         key: FOLLOWED_PROJECTS,
      //         title: 'Following',
      //     },
      //     {
      //         id: 2,
      //         key: LISTS_WITH_PROJECTS,
      //         title: 'Lists',
      //     },
      //     {
      //         id: 4,
      //         key: ARCHIVED_PROJECTS,
      //         title: 'Archived',
      //     },
      // ]
    },
  },
  project: {
    components: {
      // buttons: {
      //     buttonsOwnerCreateEdit: {
      //         cancel: "Cancel",
      //         changeCover: "Change cover",
      //         addCover: "Add cover"
      //     },
      //     buttonsOwnerView: {
      //         addTo: "Add to",
      //         projectPanel: "Project panel",
      //         edit: "Edit"
      //     },
      //     buttonsViewerView: {
      //         forum: "Go to Forum",
      //         addList: "Add to Lists"
      //     }
      // },
      content: {
        contentEditCreate: {
          addTitle: 'Add title',
        },
        contentView: {
          createBy: 'Created by',
        },
        imagedFeatured: {
          sponsoredBy: 'Sponsored by',
          fullDescription: 'full description',
        },
      },
    },
    headerInfo: {
      progressData: {
        completionLabel: 'Completion',
        currentPhaseLabel: 'Current phase',
      },
      progressStats: 'progress & stats',
    },
    project: {
      components: {
        // buttons: {
        //     buttonsOwnerCreateEdit: {
        //         cancel: "Cancel",
        //         changeCover: "Change cover",
        //         addCover: "Add cover"
        //     },
        //     buttonsOwnerView: {
        //         addTo: "Add to",
        //         projectPanel: "Project panel",
        //         edit: "Edit"
        //     },
        //     buttonsViewerView: {
        //         forum: "Go to Forum",
        //         addList: "Add to Lists"
        //     }
        // },
        content: {
          contentEditCreate: {
            addTitle: 'Add title',
          },
          contentView: {
            createdBy: 'Created by',
          },
          imagedFeatured: {
            sponsoredBy: 'Sponsored by',
            fullDescription: 'full description',
          },
        },
      },
      sideMenu: {
        // hard to add
        searchByInput: {
          no: 'No',
          found: 's found',
          searchBy: 'search by ',
        },
      },
    },
    screens: {
      brief: {
        components: {
          left: {
            briefFiles: {
              filesComponents: 'Files & Components',
            },
          },
          right: {
            contentDefault: 'Summarize your goals, process, challenges, and outcome of this project.',
          },
        },
      },
      forum: {
        components: {
          loginReminder: 'Please log in to use this feature',
          comments: {
            body: {
              deletedComment: 'This comment has been deleted',
            },
            header: {
              commentHeader: {
                wrote: 'wrote',
              },
            },
            forumComment: {
              viewPreviousComment: 'View previous comments',
            },
          },
          details: {},
          inputNewComment: {
            enterKeyCode: 'Enter',
            leaveComment: 'Leave a new comment...',
          },
          topic: {
            commentsTitle: 'Comments',
            commentsBody: 'comments',
          },
          topics: {
            moreTopic: 'More topic',
            addTopic: 'Add topic',
          },
        },
      },
    },
  },
  projects: {
    noJoin: 'No join requests available',
    noProjects: 'No projects available',
    projectsHeader: {
      publishedLabel: 'Published',
    },
    projectCard: {
      stats: {
        completion: 'Completion',
        currentPhase: 'Current phase',
      },
    },
    myProjects: 'My Projects',
    allProjects: 'All Projects',
  },
};
