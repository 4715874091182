import { gql } from '@apollo/client';

// done
export const UPDATE_USER = gql`
  mutation updateUser($user: UpdateUserInput!) {
    updateUser(user: $user) {
      description
      status
    }
  }
`;

// done
export const UPDATE_USER_AVATAR = gql`
  mutation updateAvatar($user: UpdateUserAvatarInput!) {
    updateAvatar(user: $user) {
      description
      status
    }
  }
`;

// done
export const UPDATE_USER_BACKGROUND_PICTURE = gql`
  mutation updateBackgroundPicture($user: UpdateBackgroundPictureInput!) {
    updateBackgroundPicture(user: $user) {
      description
      status
    }
  }
`;

// done
export const LIKE_UNLIKE_PROJECT = gql`
  mutation toggleProjectLike($project: ToggleProjectLikeInput!) {
    toggleProjectLike(project: $project) {
      description
      status
    }
  }
`;
