import { Box, Grid, makeStyles } from '@material-ui/core';
import _ from 'lodash';

import { PROJECT_TYPE } from 'helpers/constants';

import layoutPropTypes from '../../layoutPropTypes';
import ProjectCard from '../project-card';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles((theme) => ({
  cardFeatType: {
    '& > *:not(:last-child)': {
      [theme.breakpoints.down('sm')]: { marginBottom: theme.spacing(2) },
    },
  },
}));

const LayoutDefault = ({ projects, type, ...props }) => {
  const classes = useStyles();
  const { desktop } = props;
  const { t } = useTranslation();
  return (
    <>
      {_.isEmpty(projects) ? (
        <Box component={Grid} item xs={12} fontWeight="fontWeightBold">
          {t('project.layoutDefault.no')}{' '}
          {type === PROJECT_TYPE.LOGS_TYPE ? t('common.logsLower') : t('common.projects')} {t('common.available')}
        </Box>
      ) : desktop ? (
        <Grid item xs={12} container alignItems="flex-start" className={classes.cardFeatType} spacing={2}>
          {projects.map((p) => (
            <ProjectCard type={type} key={p.id} project={p} {...props} />
          ))}
        </Grid>
      ) : (
        <Grid item xs={12} container alignItems="flex-start" className={classes.cardFeatType}>
          {projects.map((p) => (
            <ProjectCard type={type} key={p.id} project={p} {...props} />
          ))}
        </Grid>
      )}
    </>
  );
};

export default LayoutDefault;

LayoutDefault.propTypes = layoutPropTypes;
