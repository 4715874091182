import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { BookmarkBorder as BookmarkBorderIcon } from '@material-ui/icons';
import { bool, object, func } from 'prop-types';
import { useState, useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useYupValidationResolver } from 'hooks';

import { addTopicSchemaValidationFunction } from './helper/addTopicYup';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '678px',
    },
  },
  header: {
    color: '#414141',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  button: {
    borderColor: theme.palette.text.secondary,
    padding: theme.spacing(0.25, 1.5),
    marginLeft: theme.spacing(1.5),
    textTransform: 'none',
    borderRadius: '6px',
    borderWidth: '1px',
    [theme.breakpoints.down('sm')]: {
      padding: '7px 8px 4px',
      fontSize: 12,
    },
  },
  text: {
    color: '#575757',
  },
  editInfoTextField: {
    background: '#ffffff',
    '& .MuiOutlinedInput-root': {
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.1)',
      borderRadius: '6px',
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      '& input': {
        padding: theme.spacing(1, 1.5),
      },
      '& p': {
        margin: 0,
      },
    },
  },
}));

const AddTopicDialog = ({
  open,
  options,
  desktop,
  openSnackbarSuccess,
  handleCloseDialog,
  enqueueSnackbarMultipleErrors,
}) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const validationSchema = useMemo(() => addTopicSchemaValidationFunction(t), []);

  const { register, handleSubmit, errors } = useForm({
    resolver: useYupValidationResolver(validationSchema),
  });

  const handleCreate = ({ topicName }, e) => {
    e.preventDefault();

    options.handlePositiveButton(topicName);
    handleCloseDialog();
  };

  useEffect(() => {
    enqueueSnackbarMultipleErrors(errors);
  }, [errors]);

  const renderDialogActions = (
    <DialogActions>
      <Button variant="outlined" className={classes.button} onClick={handleCloseDialog}>
        Cancel
      </Button>
      <Button type="submit" autoFocus variant="contained" color="primary" className={classes.button}>
        Create
      </Button>
    </DialogActions>
  );

  return open ? (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="add-topic-dialog-title"
      aria-describedby="add-topic-dialog-description"
      className={classes.dialog}
      maxWidth="md"
    >
      <form onSubmit={handleSubmit(handleCreate)}>
        <DialogTitle disableTypography id="add-topic-dialog-title">
          <Grid container alignItems="center" alignContent="space-between">
            <Grid item xs>
              <Typography color="textSecondary" variant={desktop ? 'h5' : 'h6'}>
                <Box display="flex" alignItems="center" className={classes.header}>
                  <BookmarkBorderIcon color="primary" className={classes.icon} />
                  Create New Topic
                </Box>
              </Typography>
            </Grid>
            {desktop && (
              <Grid item xs>
                {renderDialogActions}
              </Grid>
            )}
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            className={classes.editInfoTextField}
            placeholder="Enter Topic name"
            fullWidth
            variant="outlined"
            inputRef={register}
            name="topicName"
          />
        </DialogContent>
        {!desktop && renderDialogActions}
      </form>
    </Dialog>
  ) : null;
};

AddTopicDialog.propTypes = {
  open: bool.isRequired,
  options: object.isRequired,
  desktop: bool.isRequired,
  openSnackbarSuccess: func.isRequired,
  handleCloseDialog: func.isRequired,
};

export default AddTopicDialog;
