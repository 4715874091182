import { Avatar, Chip, CircularProgress, TextField, Typography, makeStyles } from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { arrayOf, bool, func, object, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    margin: theme.spacing(1),
    marginBottom: 0,
  },
  certifiedIcon: {
    color: '#fff',
    backgroundColor: '#FAC405',
    borderRadius: '50%',
    height: '16px',
    width: '16px',
    verticalAlign: 'middle',
    marginLeft: '3px',
  },
}));

const AddMembers = ({
  searchMember,
  onSearchMemberChange,
  setOpenSearch,
  openSearch,
  loading,
  optionsMembers,
  onChangeChosenMembers,
  chosenMembers,
}) => {
  const classes = useStyles();

  const { t } = useTranslation('dialog');

  return (
    <>
      <div className={classes.addMemberWrapper}>
        <Typography className={classes.addMemberTitle}>{t('addMember.addMember')}</Typography>
        <Autocomplete
          multiple
          id="tags-outlined"
          limitTags={4}
          freeSolo
          options={optionsMembers}
          loading={loading}
          filterSelectedOptions
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              return (
                <Chip
                  key={index}
                  avatar={<Avatar alt={option.username} src={option.avatarLink} className={classes.root} />}
                  label={option.email}
                  size="medium"
                  {...getTagProps({ index })}
                />
              );
            })
          }
          value={chosenMembers}
          renderOption={(option) => (
            <>
              <Avatar alt={option.username} src={option.avatarLink} className={classes.small} />
              {option.username} ({option.email}){option.certified && <CheckIcon className={classes.certifiedIcon} />}
            </>
          )}
          onChange={(event, value) => onChangeChosenMembers(value)}
          getOptionSelected={(optionsMembers, value) => optionsMembers.email === value.email}
          getOptionLabel={(optionsMembers) => optionsMembers.email}
          open={openSearch}
          onOpen={() => {
            setOpenSearch(true);
          }}
          onClose={() => {
            setOpenSearch(false);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={t('addMember.placeHolderEmail')}
              value={searchMember || ''}
              onChange={onSearchMemberChange}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </div>
    </>
  );
};

AddMembers.propTypes = {
  searchMember: string,
  onSearchMemberChange: func,
  onChangeChosenMembers: func,
  chosenMembers: arrayOf(object),
  setOpenSearch: func,
  openSearch: bool,
  loading: bool,
  optionsMembers: arrayOf(object),
};

export default AddMembers;
