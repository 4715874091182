import { Grid, Box, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import clsx from 'clsx';
import _ from 'lodash';
import { bool, number, object } from 'prop-types';
import { useEffect, useState } from 'react';

import CreateProjectTutorial from '../../components/section/CreateProjectTutorial';
import TabSideMenu from '../../components/side-menu/TabSideMenu';
import TabPanel from '../../components/tab-panel/TabPanel';

import TeamsDetails from './components/details/TeamsDetails';
import TeamsList from './components/list/TeamsList';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  fullHeight: {
    height: '100%',
  },
  leftPanel: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 1.5, 2),
      padding: theme.spacing(2, 1),
      border: '1px solid #575757',
      borderRadius: 6,
    },
  },
  rightPanel: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 1.5, 2),
    },
  },
}));

const TeamTabPanel = ({ tab, project: { id, ownerId, members }, errorProject, desktop }) => {
  const classes = useStyles();

  const [teamActive, setTeamActive] = useState(0);
  const [teams, setTeams] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (members) {
      const teamsDefault = [
        {
          id: 0,
          name: t('team.nameTeamDefault'),
          nbMembers: members && members.length,
          teams: [
            {
              id: 1,
              name: t('team.nameTeamDefault'),
              members,
            },
          ],
        },
      ];
      setTeams(teamsDefault);
    }
  }, [members]);

  const handleChangeTeam = (event, newTeamId) => {
    setTeamActive(newTeamId);
  };

  const renderTeams = () =>
    _.isEmpty(teams) ? (
      <Box fontWeight="fontWeightBold">{t('defaultInformation.noTeams')}</Box>
    ) : (
      teams && <TeamsDetails teams={teams} teamActive={teamActive} desktop={desktop} />
    );

  const isNewProject = id === undefined;

  return (
    <TabPanel tab={tab} index={2}>
      <>
        {desktop && (
          <Grid item xs={desktop ? 5 : 12} className={clsx(classes.fullHeight, classes.leftPanel)}>
            <TabSideMenu
              searchName="team"
              options={[]}
              desktop={desktop}
              components={
                desktop ? (
                  <TeamsList
                    teams={teams}
                    teamActive={teamActive}
                    handleChangeTeam={handleChangeTeam}
                    isNewProject={isNewProject}
                    ownerId={ownerId}
                  />
                ) : null
              }
            />
          </Grid>
        )}
        <Grid item xs={desktop ? 7 : 12} className={clsx(classes.fullHeight, classes.rightPanel)}>
          {isNewProject ? <CreateProjectTutorial desktop={desktop} /> : renderTeams()}
        </Grid>
      </>
      {errorProject && (
        <Alert variant="outlined" severity="error">
          {errorProject.message}
        </Alert>
      )}
    </TabPanel>
  );
};

TeamTabPanel.propTypes = {
  tab: number.isRequired,
  project: object.isRequired,
  errorProject: object,
  desktop: bool.isRequired,
};

TeamTabPanel.defaultProps = {
  errorProject: undefined,
};

export default TeamTabPanel;
