import { number, func, object } from 'prop-types';

import HeaderPanel from './HeaderPanel';

const HeaderCondition = ({
  navigationTab,
  dashboardTab,
  phaseTab,
  project,
  handleChangeDashboardTab,
  handleChangePhaseTab,
  handleStatusNewLog,
}) => {
  return (
    <HeaderPanel
      project={project}
      navigationTab={navigationTab}
      dashboardTab={dashboardTab}
      phaseTab={phaseTab}
      handleChangePhaseTab={handleChangePhaseTab}
      handleChangeDashboardTab={handleChangeDashboardTab}
      handleStatusNewLog={handleStatusNewLog}
    />
  );
};

HeaderCondition.propTypes = {
  project: object.isRequired,
  navigationTab: number.isRequired,
  dashboardTab: number.isRequired,
  phaseTab: number.isRequired,
  handleChangeDashboardTab: func.isRequired,
  handleChangePhaseTab: func.isRequired,
  handleStatusNewLog: func.isRequired,
};

export default HeaderCondition;
