export default {
  palette: {
    background: {
      default: '#fff',
    },
    primary: {
      main: '#384885',
      dark: '#24305e',
    },
    secondary: {
      main: '#edae49',
      dark: '#D9A900',
    },
    success: {
      main: '#59a76a',
    },
    error: {
      main: '#f26c6c',
    },
    divider: 'rgba(51, 51, 51, 0.3)',
  },
};
