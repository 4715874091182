import { Box, Container, Divider, CircularProgress, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { bool, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import LogsLayout from './components/layout/LogsLayout';
import LogTitle from './components/title/LogTitle';
import { ProjectsHeader } from '@projectcocoon/web-component';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(3),
    paddingRight: '12px',
  },
  divider: {
    margin: theme.spacing(4, 0, 2),
    backgroundColor: '#ababab',
  },
}));

const Logs = ({ divider, loading, error, label, type, ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container fixed className={classes.container}>
      {loading ? (
        <Box height="100px" width="100%" display="flex" alignItems="center" justifyContent="center">
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <>
          <ProjectsHeader
            isBig={false}
            allProjectsLink={`/projects?type=${_.toLower(type)}`}
            btnSeeMoreLabel={t('button.seeMore')}
            shouldDisplayBtnSeeMore={true}
          >
            {label}
          </ProjectsHeader>
          {error ? (
            <Alert variant="outlined" severity="error">
              {error.message}
            </Alert>
          ) : (
            <>
              <LogsLayout {...props} />
              {divider && <Divider className={classes.divider} />}
            </>
          )}
        </>
      )}
    </Container>
  );
};

Logs.propTypes = {
  loading: bool.isRequired,
  error: object,
  divider: bool,
};

Logs.defaultProps = {
  divider: false,
  error: undefined,
};

export default Logs;
