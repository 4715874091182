import { useLazyQuery } from '@apollo/client';
import { ThemeProvider } from '@material-ui/core';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import defaultUser from '@images/default-user.png';
import { GET_USER } from 'apollo/queries/user-query';
import { theme } from 'config';
import { cookieUserId } from 'helpers/auth';
import { getCurrentUser, updateCurrentUser } from 'reducers/authReducer';

import AppLayout from './AppLayout';
import CocoonSnackbar from './components/snackbar/CocoonSnackbar';

const AppLayoutContainer = (props) => {
  const currentUser = useSelector(getCurrentUser);

  const dispatch = useDispatch();
  const handleUpdateCurrentUser = (user) => dispatch(updateCurrentUser(user));

  const [getUser, { data: dataUser }] = useLazyQuery(GET_USER, {
    variables: { id: cookieUserId },
    onCompleted: () => {
      if (dataUser?.user) handleUpdateCurrentUser(dataUser.user);
    },
  });

  useEffect(() => {
    if (cookieUserId && currentUser.id === undefined && currentUser.avatarLink === defaultUser) {
      getUser();
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CocoonSnackbar>
        <AppLayout {...props} />
      </CocoonSnackbar>
    </ThemeProvider>
  );
};

export default AppLayoutContainer;
