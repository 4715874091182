import { useQuery } from '@apollo/client';
import {
  VisibilityOutlined as VisibilityOutlinedIcon,
  FavoriteBorderOutlined as FavoriteBorderOutlinedIcon,
  ChatBubbleOutlineRounded as ChatBubbleOutlineRoundedIcon,
  SendOutlined as SendOutlinedIcon,
  BookmarkBorderOutlined as BookmarkBorderOutlinedIcon,
} from '@material-ui/icons';
import { number, bool, object } from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GET_PROJECT_OVERALL_STATS } from 'apollo/queries/project-query';

import ProjectCardStats from './ProjectCardStats';

const ProjectCardStatsContainer = ({
  id,
  stats: { completion, currentPhase, views, shares, bookmarks },
  desktop,
  ...props
}) => {
  const [statsData, setStatsData] = useState([]);

  const { data: dataStats, loading: statsLoading } = useQuery(GET_PROJECT_OVERALL_STATS, {
    variables: { projectId: id },
    skip: !id,
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (!statsLoading) {
      const { projectOverallStats } = dataStats;
      const { totalComments } = projectOverallStats;

      setStatsData([
        { icon: VisibilityOutlinedIcon, data: '--' || views },
        {
          icon: FavoriteBorderOutlinedIcon,
          data: projectOverallStats.totalLikes || 0,
        },
        { icon: ChatBubbleOutlineRoundedIcon, data: totalComments || 0 },
        { icon: SendOutlinedIcon, data: '--' || shares },
        { icon: BookmarkBorderOutlinedIcon, data: '--' || bookmarks },
      ]);
    }
  }, [statsLoading]);

  const progressData = [
    { label: t('project.completionLabel'), data: '--' || completion },
    { label: t('project.currentPhaseLabel'), data: 1 || currentPhase },
  ];

  return <ProjectCardStats id={+id} desktop={desktop} progressData={progressData} statsData={statsData} {...props} />;
};

ProjectCardStatsContainer.propTypes = {
  desktop: bool,
  id: number,
  stats: object,
};

export default ProjectCardStatsContainer;
