import { clearValue, KEYS } from 'helpers/localStorage';

class ReduxAuth {
  constructor() {
    this.store = null;
    this.persistor = null;
  }

  init = (store, persistor) => {
    this.store = store;
    this.persistor = persistor;
  };

  logout = () => {
    this.store.dispatch({ type: 'RESET' });
    sessionStorage.removeItem('persist:root');
    clearValue(KEYS.avatarLink);
    const purgePromise = this.persistor.purge();
    return Promise.all([purgePromise]);
  };
}

export default new ReduxAuth();
