import { useQuery, useLazyQuery } from '@apollo/client';
import _ from 'lodash';
import { bool, number, object } from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { GET_UPDATES_BY_PROJECT_ID } from 'apollo/queries/update-query';
import { GET_UPDATE_TAGS_BY_PROJECT_ID } from 'apollo/queries/update-tag-query';

import TabPanel from '../../components/tab-panel/TabPanel';

import LogsTabPanel from './LogsTabPanel';

const LIMIT = 5;

const LogsTabPanelContainer = ({ desktop, tab, project: { id } }) => {
  const [searchValue, setSearchValue] = useState(null);
  const [canFetchMore, setCanFetchMore] = useState(true);
  const [page, setPage] = useState(1);
  const [isPaging, setIsPaging] = useState(false);
  const [logs, setLogs] = useState(null);

  const { t } = useTranslation();

  const {
    data: dataUpdateTagsByProject,
    loading: loadingUpdateTagsByProject,
    error: errorUpdateTagsByProject,
  } = useQuery(GET_UPDATE_TAGS_BY_PROJECT_ID, {
    variables: { projectId: id },
    skip: id === undefined,
  });

  const variables = {
    projectId: id,
    isPublished: true,
    tagName: searchValue,
    page,
    limit: 'FIVE',
  };

  const handleChangeSearchValue = (value) => {
    if (tab === 1) {
      setSearchValue(value);
    } else {
      setSearchValue('');
    }
  };

  const handleFetchMore = () => {
    if (canFetchMore && !isPaging) {
      setIsPaging(true);
      setPage((page) => {
        if (page === variables.page) {
          return page + 1;
        } else return page;
      });
    }
  };

  const [getUpdates, { loading, error }] = useLazyQuery(GET_UPDATES_BY_PROJECT_ID, {
    variables,
    fetchPolicy: 'network-only',
    onCompleted: (_data) => {
      if (_data.logs.length < LIMIT) {
        setCanFetchMore(false);
      }
      setLogs((logs) => {
        if (logs !== null) {
          setIsPaging(false);
        }
        return [..._data.logs];
      });
    },
  });

  useEffect(() => {
    if (id) {
      getUpdates();
    }
  }, [id, page]);

  const debounceHandleFetchMore = _.debounce(handleFetchMore, 300);

  const logsProps = {
    items: logs,
    loading,
    error,
    handleFetchMore: debounceHandleFetchMore,
  };

  const logsLeftProps = {
    ...logsProps,
    stopQuery: !canFetchMore,
    handleChangeSearchValue,
    searchValue,
    options: dataUpdateTagsByProject && dataUpdateTagsByProject.tagsByProject,
    loadingUpdateTagsByProject,
    errorUpdateTagsByProject,
  };

  const logsRightProps = {
    ...logsProps,
    canFetchMore,
    emptyText: t('defaultInformation.noLogs'),
  };

  return (
    <TabPanel tab={tab} index={1}>
      <LogsTabPanel logsLeftProps={logsLeftProps} logsRightProps={logsRightProps} desktop={desktop} />
    </TabPanel>
  );
};

LogsTabPanelContainer.propTypes = {
  desktop: bool.isRequired,
  tab: number.isRequired,
  project: object.isRequired,
};

export default LogsTabPanelContainer;
