import { Fab } from '@material-ui/core';
import { KeyboardArrowUp as KeyboardArrowUpIcon } from '@material-ui/icons';

import BackTop from './components/BackTop';
import BackToTopAnchor from './components/BackToTopAnchor';
import backToAnchor from './helpers/backToAnchor';

const CocoonBackTop = () => (
  <BackTop>
    <Fab color="primary" size="small" aria-label="scroll back to top">
      <KeyboardArrowUpIcon />
    </Fab>
  </BackTop>
);

export default CocoonBackTop;
export { BackToTopAnchor, backToAnchor };
