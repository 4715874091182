import { useMutation } from '@apollo/client';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { LockOpen as LockOpenIcon } from '@material-ui/icons';
import { bool, object, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '678px',
    },
  },
  header: {
    color: '#414141',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  button: {
    borderColor: theme.palette.text.secondary,
    padding: theme.spacing(0.25, 1.5),
    marginLeft: theme.spacing(1.5),
    textTransform: 'none',
    borderRadius: '6px',
    borderWidth: '1px',
  },
  text: {
    color: '#575757',
  },
}));

const DeleteDialog = ({ open, options, openSnackbarSuccess, openSnackbarError, handleCloseDialog }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const { itemName, variables, mutation, resultObjectName } = options;
  const [mutationPromise] = useMutation(mutation);

  const { t } = useTranslation(['common', 'dialog']);

  const handleDeleteItem = async (e) => {
    e.preventDefault();

    try {
      const { data } = await mutationPromise({ variables });

      if (data) {
        handleCloseDialog();
        openSnackbarSuccess(
          `${t('dialog:openSnackbarSuccess.theLog')} ${data[resultObjectName].name} ${t(
            'dialog:openSnackbarSuccess.isDeleted',
          )}`,
        );
        history.push({ pathname: location.pathname, state: { isRefetch: true } });
      }
    } catch (error) {
      openSnackbarError(error.message);
    }
  };

  return open ? (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
      className={classes.dialog}
      maxWidth="md"
    >
      <DialogTitle disableTypography id="delete-dialog-title">
        <Grid container alignItems="center" alignContent="space-between">
          <Grid item xs>
            <Typography color="textSecondary" variant="h5">
              <Box display="flex" alignItems="center" className={classes.header}>
                <LockOpenIcon color="primary" className={classes.icon} />
                {t('dialog:deleteDialog.deleteThis')} {itemName}
              </Box>
            </Typography>
          </Grid>
          <Grid item xs>
            <DialogActions>
              <Button variant="outlined" className={classes.button} onClick={handleCloseDialog}>
                {t('common:button.back')}
              </Button>
              <Button
                autoFocus
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleDeleteItem}
              >
                {t('common:button.confirmAndDelete')}
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Box component={Typography} my={2} variant="body1" className={classes.text}>
          {t('dialog:deleteDialog.this')} {itemName} {t('dialog:deleteDialog.dialogContent')}
        </Box>
        <Box component={Typography} my={2} variant="body1" className={classes.text}>
          {t('dialog:deleteDialog.reminder')} {itemName}?
        </Box>
      </DialogContent>
    </Dialog>
  ) : null;
};

DeleteDialog.propTypes = {
  open: bool.isRequired,
  options: object.isRequired,
  openSnackbarError: func.isRequired,
  openSnackbarSuccess: func.isRequired,
  handleCloseDialog: func.isRequired,
};

export default DeleteDialog;
