import { useMutation } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  DialogTitle,
} from '@material-ui/core';
import { bool, object, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { REMOVE_PROJECTS_FROM_LIST } from 'apollo/mutations/list-mutation';

const RemoveProjectFromListDialog = ({
  open,
  options,
  openSnackbarSuccess,
  openSnackbarError,
  handleCloseDialog,
  handleCloseBackdrop,
  handleOpenBackdrop,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { project, list } = options;
  const [removeProjectMutation] = useMutation(REMOVE_PROJECTS_FROM_LIST);

  const { t } = useTranslation(['common', 'dialog']);

  const handleRemoveProject = async () => {
    try {
      handleOpenBackdrop();
      const { data } = await removeProjectMutation({
        variables: {
          projectLists: {
            listId: list.id,
            projectIds: [project.id],
          },
        },
      });
      if (data) {
        history.push({
          pathname: location.pathname,
          state: { isRefetchLists: true },
        });
        handleCloseBackdrop();
        openSnackbarSuccess(`${t('dialog:openSnackbarSuccess.projectRemoved')}`);
        handleCloseDialog();
      }
    } catch (errorRemoveProject) {
      openSnackbarError(errorRemoveProject.message);
    }
  };

  return open ? (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="deactive-dialog-title"
      aria-describedby="deactive-dialog-description"
    >
      <DialogTitle disableTypography id="deactive-dialog-title">
        <Typography color="error" variant="h6">
          {t('dialog:openSnackbarSuccess.projectRemoved')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="deactive-dialog-description">
          {t('dialog:removeProjectFromList.reminder')} <b>{project.name}</b>{' '}
          {t('dialog:removeProjectFromList.projectFrom')} {list.name}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Typography color="error" variant="h6">
          <Button onClick={handleCloseDialog}>{t('common:button.cancel')}</Button>
          <Button onClick={handleRemoveProject} color="inherit" autoFocus>
            {t('common:button.remove')}
          </Button>
        </Typography>
      </DialogActions>
    </Dialog>
  ) : null;
};

RemoveProjectFromListDialog.propTypes = {
  open: bool.isRequired,
  options: object.isRequired,
  openSnackbarError: func.isRequired,
  openSnackbarSuccess: func.isRequired,
  handleCloseDialog: func.isRequired,
  handleCloseBackdrop: func.isRequired,
  handleOpenBackdrop: func.isRequired,
};

export default RemoveProjectFromListDialog;
