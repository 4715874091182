import { useState } from 'react';

import LogCard from './LogCard';

const LogCardContainer = ({ ...props }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const toggleTooltipVisible = () => setTooltipVisible(!tooltipVisible);

  return (
    <LogCard tooltipVisible={tooltipVisible} toggleTooltipVisible={toggleTooltipVisible} {...props} />
    // <h1>hi</h1>
  );
};

export default LogCardContainer;
