import { bool, func, object, string } from 'prop-types';

import { UPDATE_USER_BACKGROUND_PICTURE } from 'apollo/mutations/user-mutation';
import { GET_BACKGROUND_PICTURE_PRESIGNED_URL } from 'apollo/queries/user-query';
import { DIALOG, PROFILE_TYPES } from 'helpers/constants';
import { getFileLink } from 'helpers/file';
import { handleOpenDialogAC3 } from 'reducers/testAC3/cache';

import ProfileHeader from './ProfileHeader';

const ProfileHeaderContainer = ({ desktop, refetchProfile, profileType, profile }) => {
  const isUserProfile = profileType === PROFILE_TYPES.USER_PROFILE;

  const handleProfileCoverChange = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      const options = {
        profileId: +profile.id,
        getVariable: (uploadImage) => {
          return new Promise((resolve) => {
            resolve({
              user: {
                backgroundPictureUrl: getFileLink(uploadImage, profile.backgroundPictureUrl),
              },
            });
          });
        },
        presignedUrl: 'backgroundPicturePresignedUrl',
        mutation: UPDATE_USER_BACKGROUND_PICTURE,
        query: GET_BACKGROUND_PICTURE_PRESIGNED_URL,
        file,
        refetchProfile: () => refetchProfile(),
        aspect: 16 / 9,
        success: 'Background picture updated successfully!',
      };
      handleOpenDialogAC3(DIALOG.CROP_IMAGE, options);
    }
  };

  return (
    <ProfileHeader
      desktop={desktop}
      profileType={profileType}
      profile={profile}
      isUserProfile={isUserProfile}
      refetchProfile={refetchProfile}
      handleProfileCoverChange={handleProfileCoverChange}
    />
  );
};

ProfileHeaderContainer.propTypes = {
  profile: object.isRequired,
  desktop: bool.isRequired,
  refetchProfile: func.isRequired,
  profileType: string.isRequired,
};

export default ProfileHeaderContainer;
