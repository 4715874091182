import { useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Grid, Link, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import logoH from '@images/logo/logo-h.svg';
import { LOGIN } from 'apollo/mutations/auth-mutation';

const AuthCallback = () => {
  const [login] = useMutation(LOGIN);
  const [errorMsg, setErrorMsg] = useState();
  const { isLoading, getIdTokenClaims } = useAuth0();

  const { t } = useTranslation();
  let text = t('authCallBack.duringLogin');

  useEffect(async () => {
    if (!isLoading) {
      const { __raw: idToken } = await getIdTokenClaims();
      try {
        await login({ variables: { idToken } });
        text = t('authCallBack.afterLogin');
        window.location.href = '/';
      } catch (error) {
        setErrorMsg(error.message);
      }
    }
  }, [isLoading]);

  return (
    <>
      <Grid container justifyContent="center">
        <Box width={200}>
          <img src={logoH} alt="logo" />
        </Box>
      </Grid>
      {errorMsg ? (
        <>
          <Typography component="div" align="center">
            <h1>Login unsuccessfully</h1>
            <div>{errorMsg}</div>
          </Typography>
          <Grid container justifyContent="center">
            <Typography component="div" align="center">
              <Link component={RouterLink} variant="body1" to="/">
                Back to home page and try Login/Signup again.
              </Link>
            </Typography>
          </Grid>
        </>
      ) : (
        <Typography component="div" align="center">
          <h1>{t('authCallBack.pleaseWait')}</h1>
          <div>{text}</div>
        </Typography>
      )}
    </>
  );
};

export default AuthCallback;
