import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import { getLightBoxImagesFromFiles, getImageList, getOtherFiles } from 'helpers/common';

// INITIAL_STATE
const initState = {
  currentUpdate: null,
  currentLightBoxImage: [],
  currentLightBoxTitle: [],
  imageFiles: [],
  otherFiles: [],
};

// ACTION CREATORS
export const {
  setCurrentUpdate,
  setCurrentUpdateAttachments,
  removeCurrentUpdate,
  removeUpdateImageAttachment,
  removeUpdateOtherAttachment,
  resetUpdate,
} = createActions({
  SET_CURRENT_UPDATE: (currentUpdate) => ({ currentUpdate }),
  SET_CURRENT_UPDATE_ATTACHMENTS: (attachments) => ({ attachments }),
  REMOVE_CURRENT_UPDATE: undefined,
  REMOVE_UPDATE_IMAGE_ATTACHMENT: (index) => ({ index }),
  REMOVE_UPDATE_OTHER_ATTACHMENT: (index) => ({ index }),
  RESET_UPDATE: undefined,
});

// REDUCERS
export default handleActions(
  {
    [setCurrentUpdate](state, { payload: { currentUpdate } }) {
      return { ...state, currentUpdate };
    },
    [setCurrentUpdateAttachments](state, { payload: { attachments } }) {
      return {
        ...state,
        currentLightBoxImage: getLightBoxImagesFromFiles(attachments),
        imageFiles: getImageList(attachments),
        otherFiles: getOtherFiles(attachments),
      };
    },
    [removeCurrentUpdate](state) {
      return { ...state, currentUpdate: null };
    },
    [removeUpdateImageAttachment](state, { payload: { index } }) {
      const { currentLightBoxImage, currentLightBoxTitle, imageFiles } = state;
      return {
        ...state,
        currentLightBoxImage: currentLightBoxImage.splice(index, 1),
        currentLightBoxTitle: currentLightBoxTitle.slice(index, 1),
        imageFiles: imageFiles.splice(index, 1),
      };
    },
    [removeUpdateOtherAttachment](state, { payload: { index } }) {
      const { otherFiles } = state;
      return { ...state, otherFiles: otherFiles.splice(index, 1) };
    },
    [resetUpdate]() {
      return initState;
    },
  },
  initState,
);

// SELECTORS
const selectorUpdate = (state) => state.update;

export const getUpdateState = createSelector([selectorUpdate], (update) => update);
