import { CssBaseline, withStyles } from '@material-ui/core';
import { arrayOf, object } from 'prop-types';
import { Route, Switch } from 'react-router-dom';

const GlobalCss = withStyles({
  '@global': {
    'body, html': {
      backgroundColor: 'white',
      padding: '10px',
      height: '100%',
    },
    '#cocoon-app': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
})(() => null);

const NoThemeApp = ({ routes }) => (
  <>
    <CssBaseline />
    <GlobalCss />
    <Switch>
      {routes.map(({ path, exact, component }, key) => (
        <Route key={key} exact={exact} path={path} component={component} />
      ))}
    </Switch>
  </>
);

NoThemeApp.propTypes = {
  routes: arrayOf(object).isRequired,
};

export default NoThemeApp;
