import { gql } from '@apollo/client';

// done
// updated
export const USERS_BY_USERNAME_EMAIL = gql`
  query usersByUsernameEmail($searchTerm: String!, $page: Int, $limit: PaginationLimit) {
    usersByUsernameEmail(searchTerm: $searchTerm, page: $page, limit: $limit) {
      id
      username
      email
      avatarLink
      certified
    }
  }
`;

// done
// updated
export const GET_PROJECT_MEMBER_INVITATIONS = gql`
  query projectMemberInvitations($projectId: ID!, $page: Int, $limit: PaginationLimit) {
    projectMemberInvitations(projectId: $projectId, page: $page, limit: $limit) {
      id
      status
      user {
        id
        username
        email
        avatarLink
        certified
      }
    }
  }
`;
