import Cookie from 'js-cookie';

export const getCookie = (name) => Cookie.get(name);

export const setCookie = (name, value, options = {}) => {
  Cookie.set(name, value, options);
};

export const deleteCookie = (name) => {
  Cookie.remove(name);
};

export const getJSONCookie = (name) => {
  try {
    return JSON.parse(Cookie.get(name));
  } catch (error) {
    return {};
  }
};
