import { useMutation } from '@apollo/client';
import {
  Box,
  Typography,
  ClickAwayListener,
  FormControlLabel,
  Switch,
  Grid,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { Close as CloseIcon, Add as AddIcon } from '@material-ui/icons';
import { arrayOf, object, func, number, bool } from 'prop-types';
import { useState } from 'react';

import { TOGGLE_SPONSORS_DISPLAY, REMOVE_CONTEST_SPONSORS } from 'apollo/mutations/contest-mutation';
import { CocoonAvatar } from 'components';
import { useSnackbar } from 'hooks';

import SelectSponsors from './SelectSponsors';

const useStyles = makeStyles(() => ({
  sponsorWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    columnGap: 10,
  },
  avatar: {
    height: 32,
    width: 32,
    borderRadius: 16,
  },
}));

function Icon({ isAddingSponsors }) {
  if (isAddingSponsors) return <CloseIcon />;
  return <AddIcon />;
}

Icon.propTypes = {
  isAddingSponsors: bool.isRequired,
};

function Sponsors({ contestId, sponsors, refetch }) {
  const classes = useStyles();
  const [toggleSponsorDisplay] = useMutation(TOGGLE_SPONSORS_DISPLAY);
  const [removeContestSponsor] = useMutation(REMOVE_CONTEST_SPONSORS);
  const [isAddingSponsors, setIsAddingSponsors] = useState(false);

  const { openSnackbarSuccess, openSnackbarError } = useSnackbar();

  async function handleToggleSponsor(values) {
    try {
      const { data } = await toggleSponsorDisplay({
        variables: {
          contest: {
            ids: [values],
          },
        },
      });
      if (data) {
        openSnackbarSuccess('Sponsor updated!');
        refetch();
      }
    } catch (err) {
      openSnackbarError(err.message);
    }
  }

  async function handleRemoveSponsor(values) {
    try {
      const { data } = await removeContestSponsor({
        variables: {
          contest: {
            contestId,
            sponsorsIds: [values],
          },
        },
      });
      if (data) {
        openSnackbarSuccess('Sponsor updated!');
        refetch();
      }
    } catch (err) {
      openSnackbarError(err.message);
    }
  }

  function handleToggleAddSponsors() {
    if (isAddingSponsors) return setIsAddingSponsors(false);
    return setIsAddingSponsors(true);
  }

  function handleRefetch() {
    refetch();
    setIsAddingSponsors(false);
  }

  const hasSponsors = sponsors.length !== 0;
  const text = !hasSponsors ? 'No sponsors' : `${sponsors.length} sponsors`;

  return (
    <ClickAwayListener onClickAway={() => setIsAddingSponsors(false)}>
      <Grid container direction="column" alignItems="flex-end">
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography style={{ marginRight: 12 }} variant="body1">
            {text}
          </Typography>
          <IconButton onClick={handleToggleAddSponsors} variant="text" color="primary">
            <Icon isAddingSponsors={isAddingSponsors} />
          </IconButton>
        </Box>
        {isAddingSponsors && <SelectSponsors sponsors={sponsors} contestId={contestId} refetch={handleRefetch} />}
        {hasSponsors && (
          <Grid container>
            {sponsors.map(({ id, isHidden, sponsor }) => (
              <Grid className={classes.sponsorWrapper} key={sponsor.id} item xs={12}>
                <CocoonAvatar className={classes.avatar} src={sponsor.avatarLink} alt="Sponsor avatar" />
                <Typography style={{ marginRight: 12 }} variant="body1">
                  {sponsor.partnerName}
                </Typography>
                <FormControlLabel
                  checked={!isHidden ? true : false}
                  control={<Switch color="primary" onChange={() => handleToggleSponsor(+id)} />}
                  label={!isHidden ? 'Active' : 'Hidden'}
                  labelPlacement="start"
                />
                <IconButton onClick={() => handleRemoveSponsor(+sponsor.id)} disableRipple variant="contained">
                  <CloseIcon />
                </IconButton>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </ClickAwayListener>
  );
}

Sponsors.propTypes = {
  contestId: number.isRequired,
  sponsors: arrayOf(object),
  refetch: func.isRequired,
};

export default Sponsors;
