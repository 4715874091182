import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

import resources from '@locales';

export const defaultLocale = localStorage.getItem('cocoon-lang') || 'en';

export const locales = [
  { key: 'en', value: 'en', text: 'English' },
  { key: 'vi', value: 'vi', text: 'Tiếng Việt' },
];

i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next)
  .init({
    resources,

    // all namespaces needed to fetch -> separate file to load
    ns: ['common', 'glossary', 'validation', 'dashboard', 'error', 'panel', 'public'],

    // default namespace
    defaultNS: 'common',

    // Dynamically fetching user language
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },

    lng: defaultLocale,

    // use en if detected language is not available
    fallbackLng: defaultLocale,

    // send not translated keys to endpoint
    saveMissing: true,

    // debug mode for development
    debug: false,

    // react already safes from xss
    interpolation: { escapeValue: false },

    // react i18next special options (optional)
    // override if needed - omit if ok with defaults
    // react: {
    //   bindI18n: 'languageChanged',
    //   bindI18nStore: '',
    //   transEmptyNodeValue: '',
    //   transSupportBasicHtmlNodes: true,
    //   transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    //   useSuspense: false,
    // },
  });

export default i18n;
