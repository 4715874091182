import {
  CardContent,
  makeStyles,
  Typography,
  Grid,
  Link,
  Card,
  Divider,
  GridList,
  GridListTile,
  IconButton,
  Box,
} from '@material-ui/core';
import { Add as AddIcon, DescriptionOutlined as DescriptionOutlinedIcon, Close as CloseIcon } from '@material-ui/icons';
import _ from 'lodash';
import { number, func, object, arrayOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Image } from 'components';
import { acceptedBasicFiles } from 'helpers/constants';
import { openLightbox } from 'reducers/lightboxReducer';

import { CRUD_STATUS } from '../../../../helpers/constants';

const useStyles = makeStyles((theme) => ({
  fullSize: {
    width: '100%',
    height: '100%',
    paddingTop: theme.spacing(2),
  },
  cardStyle: {
    backgroundColor: '#ffffff',
  },
  title: {
    ...theme.typography.h6,
    fontWeight: theme.typography.fontWeightBold,
    color: '#575757',
  },
  divider: {
    width: '100%',
    backgroundColor: '#989898',
    marginTop: theme.spacing(1),
    border: '1px solid #989898',
  },
  addTagButton: {
    marginTop: theme.spacing(1),
  },
  buttonStyle: {
    display: 'flex',
    paddingRight: 0,
    paddingTop: 0,
    justifyContent: 'flex-end',
  },
  attachmentLink: {
    ...theme.typography.h6,
    fontWeight: theme.typography.fontWeightBold,
    color: '#4791CE',
    letterSpacing: '0.1px',
    display: 'flex',
    alignItems: 'center',
  },
  attachmentDownloadGrid: {
    paddingTop: theme.spacing(2),
  },
  inputUpload: {
    display: 'none',
  },
  sizeImage: {
    // height: '187px',
    // width: '134px'
  },
  titleBar: {
    background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' + 'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    alignItems: 'flex-start',
  },
  icon: {
    color: 'white',
    padding: theme.spacing(1),
  },
  iconBlack: {
    color: 'white',
    backgroundColor: 'black',
    padding: 0,
  },
  spacingTop: {
    paddingTop: theme.spacing(2),
  },
}));

const AttachmentField = ({
  // isPublishMilestone,
  // publishMilestone,
  attachments,
  // uploadedFile,
  handleUploadFile,
  crudStatus,
  deleteAttachment,
  onClickDelete,
}) => {
  const classes = useStyles();

  const { t } = useTranslation(['panel', 'common']);
  const dispatch = useDispatch();
  const handleOpenLightbox = (images, index) => dispatch(openLightbox(images, index));

  // const  = [...attachments];
  // const files = _.remove(images, ['assetType', 'application/octet-stream']);

  const images = attachments.filter((attachment) => {
    return attachment.assetType.includes('image') === true;
  });

  const files = attachments.filter((attachment) => {
    return attachment.assetType.includes('image') === false;
  });

  // const [expanded, setExpanded] = useState(true);

  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };

  const handleImageZoom = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    handleOpenLightbox(images, index);
  };

  const handleDownload = (e) => {
    e.stopPropagation();
  };
  const renderAttachments = () => {
    const renderFiles = _.map(files, (file, index) => (
      <Grid
        xs={12}
        item
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.spacingTop}
        key={index}
      >
        <Grid xs={10} item>
          <Typography key={file.id} variant="h6" className={classes.linkAttachment} gutterBottom>
            <Box
              component={Link}
              display="flex"
              href={file.assetLink}
              title={file.name}
              download={file.name}
              aria-label="download"
              onClick={handleDownload}
            >
              <Box flexGrow={1} display="flex" alignItems="center">
                <Box component={DescriptionOutlinedIcon} mr={1} />
                {file.name}
              </Box>
              {/* <Box color="black">Download</Box> */}
            </Box>
          </Typography>
        </Grid>
        {(crudStatus === CRUD_STATUS.EDIT || crudStatus === CRUD_STATUS.NEW) && (
          <Grid xs={2} item container direction="row" justify="flex-end" alignItems="flex-end">
            <IconButton aria-label="cancel1" className={classes.iconBlack} onClick={deleteAttachment.bind(this, file)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>
        )}
      </Grid>
    ));

    const renderImages = (
      <GridList spacing={10} cols={3}>
        {_.map(images, (img, index) => (
          <GridListTile key={index} cols={1}>
            {crudStatus === CRUD_STATUS.EDIT || crudStatus === CRUD_STATUS.NEW ? (
              <Image
                id={index}
                src={img.assetLink}
                alt={img.name}
                hasDelete
                onClickDelete={onClickDelete}
                isHoverable
                position="unset"
              />
            ) : (
              <Image
                src={img.assetLink}
                alt={img.name}
                isHoverable
                hasZoom
                hasDownload
                onZoomCLick={(e) => handleImageZoom(e, index)}
                onClick={(e) => handleImageZoom(e, index)}
                position="unset"
              />
            )}

            {/* {(crudStatus === CRUD_STATUS.EDIT ||
              crudStatus === CRUD_STATUS.NEW) && (
              <GridListTileBar
                // title={tile.title}
                titlePosition="top"
                actionIcon={
                  <IconButton
                    aria-label={`cancel`}
                    className={classes.icon}
                    onClick={deleteAttachment.bind(this, img)}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                }
                actionPosition="right"
                className={classes.titleBar}
              />
            )} */}
          </GridListTile>
        ))}
      </GridList>
    );

    return (
      <>
        {renderFiles}
        {renderImages}
      </>
    );
  };

  return (
    <div className={classes.fullSize}>
      <Card className={classes.cardStyle}>
        <CardContent>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} container direction="row">
              <Grid item xs={9}>
                <Typography className={classes.title}>{t('common:common.attachments')}</Typography>
              </Grid>
              {(crudStatus === CRUD_STATUS.EDIT || crudStatus === CRUD_STATUS.NEW) && (
                <Grid item xs={3} container justify="flex-end" alignItems="center">
                  <label htmlFor="button-cover">
                    <input
                      accept={acceptedBasicFiles}
                      className={classes.inputUpload}
                      id="button-cover"
                      multiple
                      type="file"
                      onChange={handleUploadFile}
                    />
                    <IconButton component="span" size="small" className={classes.buttonStyle}>
                      <AddIcon />
                    </IconButton>
                  </label>
                </Grid>
              )}
            </Grid>
            <Divider variant="middle" className={classes.divider} />
            <Grid item xs={12} className={classes.addTagButton}>
              {_.isEmpty(attachments) ? <b>{t('common:defaultInformation.noAttachment')}</b> : renderAttachments()}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

AttachmentField.propTypes = {
  // isPublishMilestone: bool,
  // publishMilestone: func.isRequired,
  attachments: arrayOf(object).isRequired,
  handleUploadFile: func.isRequired,
  crudStatus: number.isRequired,
  deleteAttachment: func.isRequired,
  onClickDelete: func.isRequired,
};

AttachmentField.defaultProps = {
  isPublishMilestone: undefined,
};

export default AttachmentField;
