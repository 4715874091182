import { Card, Grid, makeStyles } from '@material-ui/core';
import { bool, func } from 'prop-types';

import ProjectCardLayout from './components/layout/ProjectCardLayout';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexGrow: 1,
    minHeight: 129,
    boxShadow: 'none',
    borderRadius: 0,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
}));

const ProjectCard = (props) => {
  const classes = useStyles();

  return (
    <Grid component={Card} item xs={12} container className={classes.card}>
      <ProjectCardLayout classes={classes} {...props} />
    </Grid>
  );
};

ProjectCard.propTypes = {
  tooltipVisible: bool.isRequired,
  toggleTooltipVisible: func.isRequired,
};

export default ProjectCard;
