import { useLazyQuery, useMutation } from '@apollo/client';
import _ from 'lodash';
import { bool, number, string, oneOfType } from 'prop-types';
import { useState, useEffect } from 'react';

import { ADD_STEP_SECTION, EDIT_STEP_SECTION } from 'apollo/mutations/tutorial-mutation';
import { GET_STEP_SECTIONS } from 'apollo/queries/tutorial-query';
import { CircularCenterLoader } from 'components';

import TutorialDetails from './TutorialDetails';

const LIMIT = 10;

const TutorialDetailsContainer = ({ stepActive, projectId, ownerId, desktop }) => {
  const [canFetchMore, setCanFetchMore] = useState(true);
  const [page, setPage] = useState(1);
  const [isPaging, setIsPaging] = useState(false);
  const [sections, setSections] = useState(null);
  const [addSectionVisible, setAddSectionVisible] = useState(false);

  const variables = {
    projectId: projectId,
    projectTutorialStepId: stepActive,
    page,
    limit: 'TEN',
  };

  const [getSections, { data, loading, error }] = useLazyQuery(GET_STEP_SECTIONS, {
    variables,
    fetchPolicy: 'network-only',
    onCompleted: () => {
      const { sectionsByProjectAndStep } = data;
      const sortedSteps = _.sortBy(sectionsByProjectAndStep, 'id');

      if (sortedSteps.length < LIMIT) {
        setCanFetchMore(false);
      }
      setSections((sect) => {
        if (sect === null) {
          return [...sortedSteps];
        }
        setIsPaging(false);
        return [...sect, ...sortedSteps];
      });
    },
  });

  useEffect(() => {
    if (stepActive) {
      setSections(null);
      setPage(1);
      setCanFetchMore(true);
    }
  }, [stepActive]);

  useEffect(() => {
    getSections();
  }, [page]);

  const handleFetchMore = () => {
    if (canFetchMore && !isPaging) {
      setIsPaging(true);
      setPage((page) => {
        if (page === variables.page) {
          return page + 1;
        } else return page;
      });
    }
  };

  const [addNewSection] = useMutation(ADD_STEP_SECTION);
  const [editSection] = useMutation(EDIT_STEP_SECTION);

  const handleAddSection = async (name, content) => {
    const { data: dataSectionCreated } = await addNewSection({
      variables: {
        section: {
          projectId,
          projectTutorialStepId: stepActive,
          title: name,
          content,
        },
      },
    });

    if (dataSectionCreated) {
      const { addProjectTutorialSection } = dataSectionCreated;
      setSections((sect) => [...sect, addProjectTutorialSection]);
    }
  };

  const handleEditSection = async (newSection) => {
    const { data: dataSectionEdited } = await editSection({
      variables: { section: newSection },
    });

    if (dataSectionEdited) {
      //
    }
  };

  const handleDeleteSection = (deletedSectionId) => {
    setSections((sect) => {
      const newSections = [...sect];
      _.remove(newSections, ({ id }) => +id === +deletedSectionId);
      return newSections;
    });
  };

  const toggleAddSection = () => {
    setAddSectionVisible(!addSectionVisible);
  };
  const debounceHandleFetchMore = _.debounce(handleFetchMore, 300);

  return (loading && sections && sections.length === 0) || !sections ? (
    <CircularCenterLoader />
  ) : (
    <TutorialDetails
      canFetchMore={canFetchMore}
      handleFetchMore={debounceHandleFetchMore}
      loading={loading}
      error={error}
      sections={sections}
      ownerId={ownerId}
      addSectionVisible={addSectionVisible}
      toggleAddSection={toggleAddSection}
      handleAddSection={handleAddSection}
      handleEditSection={handleEditSection}
      handleDeleteSection={handleDeleteSection}
      desktop={desktop}
    />
  );
};

TutorialDetailsContainer.propTypes = {
  desktop: bool.isRequired,
  ownerId: number,
  projectId: oneOfType([number, string]),
  stepActive: number,
};

export default TutorialDetailsContainer;
