import _ from 'lodash';

export default (projectSubmit, uploadedFile) => {
  const isValid = (value) => _.isEmpty(value) && value !== null;
  return {
    name: isValid(projectSubmit.name) ? projectSubmit.name.trim() : '',
    description: isValid(projectSubmit.description) ? projectSubmit.description.trim() : '',
    assetLink: uploadedFile || projectSubmit.assetLink || null,
    projectOverview: isValid(projectSubmit.projectOverview) ? projectSubmit.projectOverview.trim() : '',
    id: projectSubmit.id,
    ownerId: projectSubmit.ownerId,
  };
};
