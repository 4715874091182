import { useQuery } from '@apollo/client';
import { Grid, Typography, IconButton, Badge, Button, Popover, makeStyles } from '@material-ui/core';
import { MailOutline as MailOutlineIcon, NotificationsNone as NotificationsNoneIcon } from '@material-ui/icons';
import _ from 'lodash';
import { bool, func } from 'prop-types';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { GET_NOTIFICATIONS } from 'apollo/queries/notification-query';
import { GET_USER_INVITATIONS } from 'apollo/queries/user-invitation-query';

import Invitations from '../invitations/Invitations';
import Notifications from '../notifications/Notifications';

import UserButtonAvatar from './components/avatar/UserButtonAvatar';
import UserButtonNew from './components/new/UserButtonNew';

import { CocoonIcon } from '@projectcocoon/web-component';

const useStyles = makeStyles((theme) => ({
  link: {
    '& > a': {
      color: '#333',
      '&:hover': {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
    },
  },
  button: {
    textTransform: 'none',
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(0.25, 1.5),
    [theme.breakpoints.down('sm')]: {
      '& h6': {
        fontSize: 14,
      },
      padding: theme.spacing(0.25, 1.5),
    },
  },
  iconBtn: {
    [theme.breakpoints.down('sm')]: {
      '& svg': {
        fontSize: '1.4rem',
      },
    },
  },
}));

const UserButtons = (props) => {
  const { desktop, setOpenMenuBar } = props;
  const classes = useStyles();
  const history = useHistory();

  const [invitations, setInvitations] = useState([]);
  const [anchorElInvitations, setAnchorElInvitations] = useState(null);
  const openInvitations = Boolean(anchorElInvitations);
  const idInvitations = openInvitations ? 'invitations-popover' : undefined;

  /* Just load all user invitations */
  const { refetch: refetchInvitations } = useQuery(GET_USER_INVITATIONS, {
    variables: { page: 1 },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setInvitations(data.currentUserInvitations.filter((invitation) => _.toLower(invitation.status) === 'pending'));
    },
  });

  const [notifications, setNotifications] = useState(null);
  const [anchorElNotifications, setAnchorElNotifications] = useState(null);
  const openNotifications = Boolean(anchorElNotifications);
  const idNotifications = openNotifications ? 'notifications-popover' : undefined;

  const { refetch: refetchNotifications } = useQuery(GET_NOTIFICATIONS, {
    variables: { page: 1, limit: 'TEN' },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const { userNotifications } = data;
      setNotifications([...userNotifications]);
    },
  });

  const handleClickInvitations = (e) => {
    e.stopPropagation();
    setAnchorElInvitations(e.currentTarget);
  };

  const handleCloseInvitations = () => {
    setAnchorElInvitations(null);
  };

  const handleClickNotifications = (e) => {
    e.stopPropagation();
    setAnchorElNotifications(e.currentTarget);
  };

  const handleCloseNotifications = () => {
    setAnchorElNotifications(null);
  };

  return (
    <>
      <Grid item>
        <UserButtonNew setOpenMenuBar={setOpenMenuBar} />
      </Grid>
      <Grid item>
        <Button
          component={Grid}
          item
          className={classes.button}
          color="primary"
          variant="outlined"
          onClick={() => {
            setOpenMenuBar(false);
            history.push('/users/me/projects');
          }}
        >
          <Typography component="h6" variant="button" color="inherit">
            My projects
          </Typography>
        </Button>
      </Grid>
      <Grid item>
        <IconButton
          aria-describedby={idInvitations}
          variant="contained"
          onClick={handleClickInvitations}
          color="primary"
          className={classes.iconBtn}
        >
          <Badge color="primary" badgeContent={invitations.length}>
            <CocoonIcon name={'mail_outline'} />
            {/* <MailOutlineIcon style={{ fill: '#4791CE' }} /> */}
          </Badge>
        </IconButton>
        <Popover
          id={idInvitations}
          open={openInvitations}
          anchorEl={anchorElInvitations}
          onClose={handleCloseInvitations}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Invitations
            invitations={invitations}
            refetchInvitations={refetchInvitations}
            closeInvitations={handleCloseInvitations}
            desktop={desktop}
          />
        </Popover>
        {/* <IconButton>
            <Badge color="primary">
              <SvgIcon component={MessageSvgIcon} />
            </Badge>
          </IconButton> */}
        <IconButton
          aria-describedby={idNotifications}
          variant="contained"
          onClick={handleClickNotifications}
          color="primary"
          className={classes.iconBtn}
        >
          <Badge color="primary" badgeContent={_.filter(notifications, { isSeen: false }).length}>
            <CocoonIcon name={'notifications_none'} />
            {/* <NotificationsNoneIcon style={{ fill: '#4791CE' }} /> */}
          </Badge>
        </IconButton>
        <Popover
          id={idNotifications}
          open={openNotifications}
          anchorEl={anchorElNotifications}
          onClose={handleCloseNotifications}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Notifications
            notifications={notifications}
            refetchNotifications={refetchNotifications}
            closeNotifications={handleCloseNotifications}
            desktop={desktop}
          />
        </Popover>
        <UserButtonAvatar {...props} />
      </Grid>
    </>
  );
};

UserButtons.propTypes = {
  desktop: bool.isRequired,
  setOpenMenuBar: func.isRequired,
};

export default UserButtons;
