import About from 'screens/dashboard/about';
import Contact from 'screens/dashboard/contact';
import PartnerContest from 'screens/dashboard/contest';
import EditPartnerContest from 'screens/dashboard/contest/components/edit';
import CookiePractices from 'screens/dashboard/cookie-practices';
import Home from 'screens/dashboard/home/Home';
import PartnerProfile from 'screens/dashboard/profile/partner';
import UserProfile from 'screens/dashboard/profile/user';
import Project from 'screens/dashboard/project';
import Projects from 'screens/dashboard/projects';
import NotFoundError from 'screens/error/NotFoundError';
import Panel from 'screens/panel';
import AuthCallback from 'screens/public/sign/auth-callback';

export const routesPublic = [
  {
    path: '/',
    exact: true,
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    exact: true,
    name: 'About',
    component: About,
  },
  {
    path: '/how-we-use-cookies',
    exact: true,
    name: 'How we use cookies',
    component: CookiePractices,
  },
  {
    path: '/contact',
    exact: true,
    name: 'Contact',
    component: Contact,
  },
];

export const noThemeRoutes = [
  {
    path: '/auth/callback',
    exact: false,
    component: AuthCallback,
  },
];

const routes = [
  ...routesPublic,
  {
    path: '/category/:id/:name',
    exact: true,
    component: Home,
  },
  {
    path: '/projects/:id/:name',
    exact: true,
    component: Project,
  },
  {
    path: '/projects/:id/:name/panel',
    exact: true,
    component: Panel,
  },
  {
    path: '/projects/new',
    exact: true,
    component: Project,
  },
  {
    path: '/projects',
    exact: true,
    component: Projects,
  },
  {
    path: '/users/:id/:name/profile',
    exact: true,
    component: UserProfile,
  },
  {
    path: '/users/me/projects',
    exact: true,
    component: Projects,
  },
  {
    path: '/not-found',
    component: NotFoundError,
  },
  {
    path: '/partners/:id/:name/profile',
    exact: true,
    component: PartnerProfile,
  },
  {
    path: '/contests/:id/:name',
    exact: true,
    component: PartnerContest,
  },
  {
    path: '/contests/:id/:name/edit',
    exact: true,
    component: EditPartnerContest,
  },
];

export default routes;
