import { useQuery } from '@apollo/client';
import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import { number, object, func, bool } from 'prop-types';
import { useState, useEffect } from 'react';

import { GET_PROJECT_ATTACHMENTS } from 'apollo/queries/file-query';

import TabPanel from '../../components/tab-panel/TabPanel';

import BriefLeftPanel from './components/left/BriefLeftPanel';
import BriefRightPanel from './components/right/BriefRightPanel';

const useStyles = makeStyles((theme) => ({
  fullHeight: {
    height: '100%',
  },
  leftPanel: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 1.5, 2),
      padding: theme.spacing(2, 1),
      border: '1px solid #575757',
      borderRadius: 6,
    },
  },
  rightPanel: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 1.5, 2),
    },
  },
}));

const BriefTabPanel = ({
  watch,
  tab,
  control,
  project,
  crudStatus,
  handleChangeProject,
  desktop,
  isView,
  ...projectProps
}) => {
  const classes = useStyles();
  const { id } = project;
  const [attachments, setAttachments] = useState(null);

  const {
    data,
    loading: loadingAttachments,
    error: errorAttachments,
  } = useQuery(GET_PROJECT_ATTACHMENTS, {
    variables: { projectId: id, isPublished: true },
    skip: id === undefined || !_.isEmpty(attachments),
  });

  useEffect(() => {
    if (data) {
      setAttachments(data.attachments);
    }
  }, [data]);

  return (
    <TabPanel tab={tab} index={0}>
      {desktop ? (
        <>
          <Grid item xs={5} className={classes.fullHeight}>
            <BriefLeftPanel
              project={{
                ...project,
                attachments,
              }}
              loadingAttachments={loadingAttachments}
              errorAttachments={errorAttachments}
              desktop={desktop}
              {...projectProps}
            />
          </Grid>
          <Grid item xs={7} className={classes.fullHeight}>
            <BriefRightPanel
              project={project}
              control={control}
              watch={watch}
              crudStatus={crudStatus}
              handleChangeProject={handleChangeProject}
              desktop={desktop}
              isView={isView}
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} className={clsx(classes.fullHeight, classes.rightPanel)}>
            <BriefRightPanel
              project={project}
              control={control}
              watch={watch}
              crudStatus={crudStatus}
              handleChangeProject={handleChangeProject}
              desktop={desktop}
              isView={isView}
            />
          </Grid>
          {isView && (
            <Grid item xs={12} className={clsx(classes.fullHeight, classes.leftPanel)}>
              <BriefLeftPanel
                project={{
                  ...project,
                  attachments,
                }}
                loadingAttachments={loadingAttachments}
                errorAttachments={errorAttachments}
                desktop={desktop}
                {...projectProps}
              />
            </Grid>
          )}
        </>
      )}
    </TabPanel>
  );
};

BriefTabPanel.propTypes = {
  tab: number.isRequired,
  project: object.isRequired,
  crudStatus: number.isRequired,
  handleChangeProject: func.isRequired,
  desktop: bool.isRequired,
  isView: bool.isRequired,
};

export default BriefTabPanel;
