import { Box, InputBase, Chip, makeStyles } from '@material-ui/core';
import { object, func, arrayOf, bool } from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Tags } from 'components';

const useStyles = makeStyles((theme) => ({
  inputTitle: {
    ...theme.typography.h2,
    color: theme.palette.common.white,
    '& > textarea': {
      textAlign: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.h5,
      color: theme.palette.common.white,
      fontWeight: 700,
    },
  },
  error: {
    ...theme.typography.caption2,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
  },
  inputDescription: {
    ...theme.typography.body1,
    color: theme.palette.common.white,
    '& > textarea': {
      '&::-webkit-input-placeholder': {
        fontStyle: 'italic',
      },
      '&:-moz-placeholder': {
        fontStyle: 'italic',
      },
      '&::-moz-placeholder': {
        fontStyle: 'italic',
      },
      '&:-ms-input-placeholder': {
        fontStyle: 'italic',
      },
      textAlign: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      '& > textarea': {
        fontSize: 13,
        textAlign: 'left',
        wordBreak: 'break-word',
        padding: theme.spacing(1),
        backgroundColor: 'rgba(0,0,0,.72)',
        borderRadius: 6,
        margin: theme.spacing(0, -1),
      },
    },
  },
}));

const ContentEditCreate = ({ project, register, control, projectTags, handleChangeProjectTags, desktop }) => {
  const classes = useStyles();
  const name = useWatch({
    control,
    name: 'name',
    defaultValue: project.name || '',
  });

  const description = useWatch({
    control,
    name: 'description',
    defaultValue: project.description || '',
  });

  const { t } = useTranslation(['common', 'validation']);

  return (
    <>
      <InputBase
        className={classes.inputTitle}
        inputProps={{ 'aria-label': 'description' }}
        placeholder={t('common:project.addTitle')}
        multiline
        rowsMax="2"
        fullWidth
        name="name"
        inputRef={register}
        autoComplete="new-password"
      />
      {name.trim().length > 50 && (
        <Chip size="small" label={t('validation:project.limitCharacterProjectName')} className={classes.error} />
      )}
      <Box display="flex" alignItems="center" justifyContent="center" flexWrap="wrap" mb={2}>
        <Tags isEditMode tags={projectTags} setTags={handleChangeProjectTags} />
      </Box>
      <InputBase
        className={classes.inputDescription}
        inputProps={{ 'aria-label': 'description' }}
        inputRef={register}
        placeholder={t('validation:project.descriptionPlaceHolder')}
        multiline
        rowsMax={desktop ? '4' : '5'}
        fullWidth
        name="description"
      />
      {description.trim().length > 200 && (
        <Chip size="small" label={t('validation:project.limitCharacterProjectDes')} className={classes.error} />
      )}
    </>
  );
};

ContentEditCreate.propTypes = {
  project: object,
  handleChangeProject: func.isRequired,
  projectTags: arrayOf(object).isRequired,
  handleChangeProjectTags: func.isRequired,
  desktop: bool.isRequired,
};

export default ContentEditCreate;
