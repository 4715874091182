import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';

import { fileApi } from 'api';
import { ADD_PROJECT, EDIT_PROJECT } from 'apollo/mutations/project-mutation';
import {
  GET_PROJECT,
  GET_PROJECT_PICTURE_PRESIGNED_URL,
  GET_PROJECT_OVERALL_STATS,
} from 'apollo/queries/project-query';
import { backToAnchor, CocoonHelmet } from 'components';
import { cookieUserId } from 'helpers/auth';
import { convertToSlug, getImg } from 'helpers/common';
import { DIALOG } from 'helpers/constants';
import { getFileLink } from 'helpers/file';
import { META_DATA } from 'helpers/metaData';
import { getQueryStringParams } from 'helpers/url';
import { useSnackbar } from 'hooks';
import { closeLightbox, getLightboxStore } from 'reducers/lightboxReducer';
import { handleOpenDialogAC3 } from 'reducers/testAC3/cache';

import { CRUD_STATUS, PROJECT_DEFAULT } from './helpers/constants';
import getProjectSubmit from './helpers/getProjectSubmit';
import isSame from './helpers/isSame';
import Project from './Project';

const PROJECT_SECTION = [
  'brief',
  'logs',
  // 'gigs',
  'team',
  'tutorial',
  'forum',
];

const ProjectContainer = () => {
  const { id } = useParams();
  const history = useHistory();

  const { title, description, image, url } = META_DATA.CONTEST_PARTNER_PROFILE;

  const dispatch = useDispatch();
  const lightbox = useSelector(getLightboxStore);

  const { t } = useTranslation(['common', 'dialog']);

  const { openSnackbarSuccess, openSnackbarError, openSnackbarWarning } = useSnackbar();
  const handleCloseLightbox = () => dispatch(closeLightbox());

  const { hash, pathname } = useLocation();

  const hashMapper = (_hash) => {
    if (_.isEmpty(_hash)) {
      return 0;
    }
    return PROJECT_SECTION.indexOf(getQueryStringParams('tab', _hash, '#'));
  };

  const client = useApolloClient();
  const [tab, setTab] = useState(0);
  const [project, setProject] = useState(PROJECT_DEFAULT);
  const [projectTags, setProjectTags] = useState(project.tags);
  const [loadingButton, setLoadingButton] = useState(false);

  const [uploadedFile, setUploadedFile] = useState(null);
  const [crudStatus, setCrudStatus] = useState(CRUD_STATUS.VIEW);

  useEffect(() => {
    setTab(hashMapper(hash));
  }, [hash]);

  useEffect(() => {
    if (crudStatus === CRUD_STATUS.EDIT) {
      setTab(0);
    }
  }, [crudStatus]);

  const {
    data: dataProject,
    loading: loadingProject,
    error: errorProject,
    refetch: refetchProject,
  } = useQuery(GET_PROJECT, {
    variables: { id },
    skip: !id,
  });

  const { control, register, handleSubmit, reset } = useForm({
    defaultValues: {
      name: '',
      description: '',
      projectOverview: '',
    },
  });

  const { data: dataStats } = useQuery(GET_PROJECT_OVERALL_STATS, {
    variables: { projectId: id },
    skip: !id,
  });

  const handleCancelEditMode = () => {
    setCrudStatus(CRUD_STATUS.VIEW);
    setUploadedFile(null);
  };

  const handleSetInitialProject = () => {
    const { projectById } = dataProject;
    const { name, projectOverview, description, members, owner, tags } = projectById;
    let newMembers = members;
    if (!_.find(members, ({ id }) => +id === +owner.id)) {
      newMembers = [owner, ...projectById.members];
    }

    setProjectTags(tags);
    setProject({
      ...projectById,
      members: newMembers,
    });

    reset({
      name: name,
      projectOverview: projectOverview || '',
      description: description,
    });
  };

  useEffect(() => {
    backToAnchor(document);
    // if (crudStatus !== CRUD_STATUS.VIEW_UPDATED) {
    //   if (snackbarOpen) {
    //     handleCloseSnackbar();
    //   }
    // }
    if (lightbox.open) {
      handleCloseLightbox();
    }
    if (dataProject) {
      handleSetInitialProject();
    }
    if (id === undefined) {
      setCrudStatus(CRUD_STATUS.NEW);
    }
  }, [dataProject]);

  const [addProject] = useMutation(ADD_PROJECT);
  const [editProject] = useMutation(EDIT_PROJECT);

  const handleChangeTab = (e, newTab) => {
    e.preventDefault();
    setTab(newTab);
    const tabPrefix = '#tab=';

    window.location.hash = `${tabPrefix}${PROJECT_SECTION[newTab]}`;
  };

  const handleChangeProject = (e, editorInputName, editorInputValue) => {
    const name = e ? e.target.name : editorInputName;
    const value = e ? e.target.value : editorInputValue;
    setProject({ ...project, [name]: value });
  };

  const handleChangeProjectTags = (newTags) => {
    setProjectTags(newTags);
  };

  const handleUploadFile = (e) => {
    e.preventDefault();
    const options = {
      setImage: (cropImage) => {
        setUploadedFile(cropImage);
      },
      file: e.target.files[0],
      aspect: 16 / 9,
    };
    handleOpenDialogAC3(DIALOG.CROP_IMAGE, options);
  };

  const handleChangeCrudStatus = (mode) => {
    setCrudStatus(mode);
  };

  const isProjectValidated = (projectSubmitted) => {
    if (_.isEmpty(projectSubmitted.name.trim())) {
      openSnackbarWarning(t('dialog:openSnackbarWarning.projectNameEmpty'));
      setLoadingButton(false);
      return false;
    }

    if (projectSubmitted.name.trim().length > 50) {
      openSnackbarError(t('dialog:openSnackbarError.limitCharacterProjectName'));
      setLoadingButton(false);
      return false;
    }

    if (_.isEmpty(projectSubmitted.description.trim())) {
      openSnackbarWarning(t('dialog:openSnackbarWarning.projectDescriptionEmpty'));
      setLoadingButton(false);
      return false;
    }

    if (projectSubmitted.description.trim().length > 200) {
      openSnackbarError(t('dialog:openSnackbarError.limitCharacterProject'));
      setLoadingButton(false);
      return false;
    }

    if (_.isEmpty(projectSubmitted.projectOverview.trim())) {
      openSnackbarWarning(t('dialog:openSnackbarWarning.projectOverview'));
      setLoadingButton(false);
      return false;
    }

    return true;
  };

  const onSubmit = async (data, e) => {
    const { name, description, projectOverview } = data;

    e.preventDefault();

    setLoadingButton(true);

    if (isProjectValidated(data)) {
      // Upload project's picture.
      let awsUploadedFile = uploadedFile;

      if (uploadedFile) {
        try {
          const variables = {
            fileName: awsUploadedFile.name,
            contentType: awsUploadedFile.type,
          };

          const { data: projectPicture } = await client.query({
            query: GET_PROJECT_PICTURE_PRESIGNED_URL,
            variables,
          });

          if (projectPicture) {
            const { projectPicturePresignedUrl } = projectPicture;
            awsUploadedFile = await fileApi.uploadFile(projectPicturePresignedUrl, awsUploadedFile);
          }
        } catch (error) {
          openSnackbarError(error.message);
          setLoadingButton(false);
          return;
        }
      }

      let projectToSubmit = {
        name: name.trim(),
        assetLink: getFileLink(awsUploadedFile, project.assetLink),
        description: description.trim(),
        tags: projectTags.map(({id, tagName}) => ({id, tagName})),
        projectOverview: projectOverview.trim(),
      };

      if (crudStatus === CRUD_STATUS.EDIT) {
        projectToSubmit = {
          ...projectToSubmit,
          id,
        };
      }

      try {
        if (crudStatus === CRUD_STATUS.NEW) {
          const { data: dataProjectCreated } = await addProject({
            variables: { project: projectToSubmit },
          });

          if (dataProjectCreated) {
            setLoadingButton(false);
            openSnackbarSuccess(
              `${t('dialog:openSnackbarSuccess.theProject')} ${t('dialog:openSnackbarSuccess.isCreated')}`,
            );
            const { id: newId, name: newName } = dataProjectCreated.createProject;
            history.push(`/projects/${newId}/${convertToSlug(newName)}`);
          }
        } else {
          const { data: dataProjectEdited } = await editProject({
            variables: { project: projectToSubmit },
          });

          if (dataProjectEdited) {
            setLoadingButton(false);
            setCrudStatus(CRUD_STATUS.VIEW_UPDATED);
            openSnackbarSuccess(
              `${t('dialog:openSnackbarSuccess.theProject')} ${dataProjectEdited.editProject.name} ${t(
                'dialog:openSnackbarSuccess.isUpdated',
              )}`,
            );
            refetchProject();
          }
        }
      } catch (errorSubmitProject) {
        setLoadingButton(false);
        openSnackbarError(errorSubmitProject.message);
      }
    }
  };

  const handleTagsToKeyword = (projectTagsArray) => {
    let tagsMap = projectTagsArray.map((element) => {
      return element.tagName;
    });
    return tagsMap;
  };

  if (pathname !== '/projects/new' && !loadingProject && !dataProject) {
    return <Redirect to="/not-found" />;
  }

  return (
    <>
      {crudStatus === CRUD_STATUS.NEW ? (
        <CocoonHelmet title={title} description={description} image={image} url={url} />
      ) : (
        project && (
          <CocoonHelmet
            title={project.name}
            description={project.description}
            image={getImg(project.assetLink)}
            projectTags={handleTagsToKeyword(projectTags)}
          />
        )
      )}
      <Project
        register={register}
        control={control}
        loadingProject={loadingProject}
        loadingButton={loadingButton}
        errorProject={errorProject}
        crudStatus={crudStatus}
        project={project}
        projectTags={projectTags}
        projectStats={dataStats && dataStats.projectOverallStats}
        tab={tab}
        uploadedFile={uploadedFile}
        handleChangeTab={handleChangeTab}
        handleSubmit={handleSubmit(onSubmit)}
        handleChangeProject={handleChangeProject}
        handleChangeProjectTags={handleChangeProjectTags}
        handleUploadFile={handleUploadFile}
        handleChangeCrudStatus={handleChangeCrudStatus}
        handleCancelEditMode={handleCancelEditMode}
        refetchProject={refetchProject}
        disabledSave={
          project &&
          dataProject &&
          isSame(dataProject.projectById, getProjectSubmit(project, uploadedFile), projectTags)
        }
      />
    </>
  );
};

export default ProjectContainer;
