import { useApolloClient } from '@apollo/client';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  makeStyles,
  Slider,
} from '@material-ui/core';
import { bool, object, func } from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import Cropper from 'react-easy-crop';
import { useTranslation } from 'react-i18next';

import { fileApi } from 'api';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '678px',
    },
  },
  cropContainer: {
    height: 600,
    width: 600,
  },
  controlContainer: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    width: '100%',
    transform: 'translateX(-50%)',
    height: 80,
    display: 'flex',
    alignItems: 'center',
    background: 'white',
  },
  controlContent: {
    paddingLeft: '22px',
    paddingRight: '22px',
  },
  button: {
    borderColor: theme.palette.text.secondary,
    padding: theme.spacing(0.25, 1.5),
    marginLeft: theme.spacing(1.5),
    textTransform: 'none',
    borderRadius: '6px',
    borderWidth: '1px',
    [theme.breakpoints.down('sm')]: {
      padding: '7px 8px 4px',
      fontSize: 12,
    },
  },
}));

const CropperDialog = ({
  open,
  options,
  openSnackbarSuccess,
  openSnackbarError,
  handleCloseDialog,
  handleCloseBackdrop,
  handleOpenBackdrop,
}) => {
  const classes = useStyles();
  const client = useApolloClient();

  const { t } = useTranslation(['common', 'dialog']);

  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  useEffect(() => {
    async function getImageUrl() {
      try {
        const imageDataUrl = await readFile(options.file);

        setImageSrc(imageDataUrl);
      } catch (e) {
        handleCloseDialog();
      }
    }
    getImageUrl();
  }, [options.file]);

  const convertImage = () => {
    return new Promise((resolve) => {
      const image = new Image();

      image.src = imageSrc;

      image.crossOrigin = 'Anonymous';
      const canvas = document.createElement('canvas');
      canvas.width = croppedAreaPixels.width;
      canvas.height = croppedAreaPixels.height;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(
        image,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
        0,
        0,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
      );
      const dataURL = canvas.toDataURL(options.file.type);
      resolve(dataURL);
    });
  };

  const setImageFile = async () => {
    const convertedImage = await convertImage();
    if (convertedImage.length > 5 * 1000 * 1000) {
      openSnackbarError(t('dialog:openSnackbarError.imageDeleted'));
      return;
    }
    const uploadImageFile = dataURLtoFile(convertedImage, options.file.name);

    options.setImage(uploadImageFile);
    handleCloseDialog();
  };

  const handleAddImage = async () => {
    if (!options.query || !options.mutation) {
      handleCloseDialog();
      return;
    }
    const convertedImage = await convertImage();

    if (convertedImage.length > 5 * 1000 * 1000) {
      openSnackbarError(t('dialog:openSnackbarError.imageDeleted'));
      return;
    }
    let uploadImageFile = dataURLtoFile(convertedImage, options.file.name);

    try {
      handleOpenBackdrop();
      const variables = {
        userId: +options.profileId,
        fileName: uploadImageFile.name,
        contentType: uploadImageFile.type,
      };

      const contestVariables = {
        contestId: +options.contestId,
        fileName: uploadImageFile.name,
        contentType: uploadImageFile.type,
      };

      const { data: imageData } = await client.query({
        query: options.query,
        variables: options.isFromContest ? contestVariables : variables,
      });

      if (imageData) {
        uploadImageFile = await fileApi.uploadFile(imageData[options.presignedUrl], uploadImageFile);
      }
    } catch (errorAws) {
      openSnackbarError(errorAws.message);
      return;
    }
    const variables = await options.getVariable(uploadImageFile);

    try {
      const { data: dataUserAvatarUpdated } = await client.mutate({
        mutation: options.mutation,
        variables,
      });

      if (dataUserAvatarUpdated) {
        options.refetchProfile();
        handleCloseBackdrop();
        openSnackbarSuccess(options.success);
        handleCloseDialog();
      }
    } catch (errorSubmitAvatar) {
      openSnackbarError(errorSubmitAvatar.message);
    }
  };

  return (
    <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth="md">
      <DialogTitle disableTypography id="alert-dialog-title">
        <div className={classes.cropContainer}>
          {imageSrc ? (
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              cropShape={options.round ? 'round' : ''}
              aspect={options.aspect}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          ) : (
            <CircularProgress color="inherit" size={30} />
          )}
        </div>
        <Grid container className={classes.controlContainer}>
          <Grid item xs={12} sm={6} className={classes.controlContent}>
            <Slider
              value={zoom}
              min={1}
              max={5}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e, zoom) => setZoom(zoom)}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.controlContent}>
            <DialogActions>
              <Button variant="outlined" className={classes.button} onClick={handleCloseDialog}>
                {t('common:button.back')}
              </Button>
              <Button
                autoFocus
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={options.setImage ? setImageFile : handleAddImage}
              >
                {t('common:button.addImage')}
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </DialogTitle>
    </Dialog>
  );
};

CropperDialog.propTypes = {
  open: bool.isRequired,
  options: object.isRequired,
  openSnackbarError: func.isRequired,
  openSnackbarSuccess: func.isRequired,
  handleCloseDialog: func.isRequired,
  handleCloseBackdrop: func.isRequired,
  handleOpenBackdrop: func.isRequired,
};

export default CropperDialog;
