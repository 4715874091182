import { useMutation } from '@apollo/client';
import { Tooltip, makeStyles, Button } from '@material-ui/core';
import { FavoriteBorder as FavoriteBorderIcon } from '@material-ui/icons';
import { bool, number, func, string, oneOfType } from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { LIKE_UNLIKE_PROJECT } from 'apollo/mutations/user-mutation';
import { useSnackbar } from 'hooks';

const useStyles = makeStyles((theme) => ({
  buttonLike: {
    height: '100%',
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    marginRight: theme.spacing(2),
    padding: theme.spacing(1, 2),
    textTransform: 'none',
    borderRadius: '6px',
    borderWidth: '1px',
    '& span': {
      color: theme.palette.common.white,
      [theme.breakpoints.down('sm')]: {
        color: '#575757',
        fontSize: 13,
      },
    },
    '&:hover': {
      borderWidth: '2px',
      '& *': {
        fontWeight: 'bold',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& svg': {
        fontSize: 18,
      },
      color: '#575757',
      borderColor: '#575757',
      padding: theme.spacing(0.5, 1),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
    },
  },
  buttonLiked: {
    height: '100%',
    color: '#575757',
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    marginRight: theme.spacing(2),
    padding: theme.spacing(0.5, 2),
    textTransform: 'none',
    borderRadius: '6px',
    borderWidth: '1px',
    '& span': {
      color: '#575757',
      [theme.breakpoints.down('sm')]: {
        color: theme.palette.common.white,
        fontSize: 13,
      },
    },
    '&:hover': {
      color: '#575757',
      backgroundColor: theme.palette.common.white,
      borderWidth: '2px',
      '& *': {
        fontWeight: 'bold',
      },
      [theme.breakpoints.down('sm')]: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        borderWidth: '1px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& svg': {
        fontSize: 18,
      },
      borderColor: '#575757',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(0.5, 1),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
    },
  },
}));

const Like = ({ id, isLiked, refetchProject }) => {
  const classes = useStyles();

  const { openSnackbarSuccess, openSnackbarError } = useSnackbar();

  const [liked, setLiked] = useState(isLiked);

  const [likeUnlikeProjectMutation] = useMutation(LIKE_UNLIKE_PROJECT);

  const { t } = useTranslation(['common', 'dialog']);

  useEffect(() => {
    setLiked(isLiked);
  }, [isLiked]);

  const toggleLike = async () => {
    try {
      const likeUnlikeProject = await likeUnlikeProjectMutation({
        variables: { project: { id } },
      });
      const {
        data: {
          toggleProjectLike: { status },
        },
      } = likeUnlikeProject;
      if (status === 'SUCCESS') {
        if (!liked) {
          setLiked(true);
          openSnackbarSuccess(t('dialog:openSnackbarSuccess.likeProject'));
        } else {
          setLiked(false);
          openSnackbarSuccess(t('dialog:openSnackbarSuccess.unlikeProject'));
        }
        // refetchProfile right after snackbar open will make snackbar disappear immediately, user can't see the message of snackbar
        setTimeout(() => {
          refetchProject();
        }, 500);
      }
    } catch (errorLikeUnlikeProject) {
      openSnackbarError(errorLikeUnlikeProject.message);
    }
  };

  return (
    <Tooltip title={t('common:common.like')}>
      <Button
        size="small"
        variant="outlined"
        className={liked ? classes.buttonLiked : classes.buttonLike}
        onClick={toggleLike}
      >
        <FavoriteBorderIcon />
      </Button>
    </Tooltip>
  );
};

Like.propTypes = {
  isLiked: bool.isRequired,
  id: oneOfType([number, string]).isRequired,
  refetchProject: func.isRequired,
};

export default Like;
