import DroneRangerWoff from '@fonts/DroneRanger.woff';
import DroneRangerWoff2 from '@fonts/DroneRanger.woff2';
import BrandonTextBoldWoff from '@fonts/SvnBrandonTextBold.woff';
import BrandonTextBoldWoff2 from '@fonts/SvnBrandonTextBold.woff2';
import BrandonTextRegularWoff from '@fonts/SvnBrandonTextRegular.woff';
import BrandonTextRegularWoff2 from '@fonts/SvnBrandonTextRegular.woff2';

export const droneRanger = {
  fontFamily: 'Drone Ranger',
  fontStyle: 'bold',
  fontWeight: 700,
  src: `url(${DroneRangerWoff2}) format('woff2'),
  url(${DroneRangerWoff}) format('woff')`,
};

export const brandonText = {
  fontFamily: 'Brandon Text',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `url(${BrandonTextRegularWoff2}) format('woff2'),
  url(${BrandonTextRegularWoff}) format('woff')`,
};

export const brandonTextBold = {
  fontFamily: 'Brandon Text',
  fontStyle: 'bold',
  fontWeight: 700,
  src: `url(${BrandonTextBoldWoff2}) format('woff2'),
  url(${BrandonTextBoldWoff}) format('woff')`,
};
