import { Typography, Divider, Box, Container, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { routesPublic } from 'helpers/routes';

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(3, 2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 1, 2),
    },
    '& > *:first-child': {
      '& a': {
        color: '#333',
      },
      '& > * + *': {
        marginLeft: theme.spacing(3),
      },
      '& > *:hover': {
        fontWeight: 'bold',
        transition: 'all 0.3s',
      },
    },
  },
}));

const AppFooter = () => {
  const classes = useStyles();
  const { t } = useTranslation('glossary');

  return (
    <Container maxWidth="xl">
      <Divider variant="middle" />
      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom>
          {_.map(routesPublic, ({ path, name }) => (
            <Link key={path} to={path}>
              {name}
            </Link>
          ))}
        </Typography>
        <Box mt={1}>
          <Typography variant="h6" align="center" color="textSecondary">
            {t('copyright')}
          </Typography>
        </Box>
      </footer>
    </Container>
  );
};

export default AppFooter;
