import { useQuery } from '@apollo/client';
import { Grid, Box, CircularProgress, Typography, TextField, Checkbox } from '@material-ui/core';
import { func, string, arrayOf } from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GET_PROJECTS_BY_USER_ID } from 'apollo/queries/project-query';
import { CocoonProjectWithOverlay } from 'components';
import { cookieUserId } from 'helpers/auth';

import useStyles from './PersonalProjectsGalleryStyle';

const PersonalProjectsGallery = ({ handleCheckProject, selectedProjectIds, existingProjectIds }) => {
  const classes = useStyles();
  // TODO: should paginate this
  const { data, loading } = useQuery(GET_PROJECTS_BY_USER_ID, {
    variables: {
      limit: 'FIVE_O',
      userId: cookieUserId,
      isArchived: false,
      page: 1,
    },
  });
  const { t } = useTranslation('dialog');
  const [galleryProjects, setGalleryProjects] = useState(null);
  const [mouseOver, setMouseOver] = useState({
    state: false,
    currentIndex: null,
  });

  useEffect(() => {
    if (data) {
      const filtered = [];
      data.projects.forEach((project) => {
        if (!existingProjectIds.includes(project.id)) {
          filtered.push(project);
        }
      });

      setGalleryProjects([...filtered]);
    }
  }, [data]);

  const onProjectSearch = (name) => {
    const filteredProjects = [];
    data.projects.forEach((project) => {
      if (project.name.toLowerCase().includes(name.toLowerCase())) {
        filteredProjects.push(project);
      }
    });
    setGalleryProjects(filteredProjects);
  };

  const handleMouseOver = (index) => {
    setMouseOver({ state: true, currentIndex: index });
  };

  if (loading)
    return (
      <Box height="300px" width="100%" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress color="inherit" />
      </Box>
    );
  return (
    galleryProjects && (
      <>
        <TextField
          className={classes.textField}
          fullWidth
          variant="outlined"
          placeholder={t('listProjects.searchProject')}
          onChange={(e) => onProjectSearch(e.target.value)}
          disabled={!data.projects || data.projects.length === 0}
        />
        <Typography className={classes.text}>{t('listProjects.selectMore')}</Typography>
        <Grid container spacing={2} className={classes.projectsWrapper}>
          {galleryProjects.map((project, index) => (
            <Grid
              key={project.id}
              onFocus={() => handleMouseOver(index)}
              onMouseOver={() => handleMouseOver(index)}
              item
              xs={4}
              className={classes.projectItem}
            >
              <Checkbox
                className={classes.projectsGalleryCheckBox}
                inputProps={{ 'aria-label': 'primary checkbox' }}
                onChange={() => handleCheckProject(project.id)}
                checked={selectedProjectIds.includes(project.id)}
              />
              <Box borderRadius={6} className={classes.img}>
                <CocoonProjectWithOverlay
                  project={project}
                  isFromList
                  desktop={false}
                  isHovered={mouseOver.state && mouseOver.currentIndex === index}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </>
    )
  );
};

PersonalProjectsGallery.propTypes = {
  handleCheckProject: func,
  selectedProjectIds: arrayOf(string),
  existingProjectIds: arrayOf(string),
};

export default PersonalProjectsGallery;
