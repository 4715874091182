import { useMutation } from '@apollo/client';
import { arrayOf, object, number, bool } from 'prop-types';
import { useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import {
  CREATE_USER_EXPERIENCE,
  DELETE_USER_EXPERIENCE,
  UPDATE_USER_EXPERIENCE,
} from 'apollo/mutations/user-experience-mutation';
import { useSnackbar, useYupValidationResolver } from 'hooks';

import Experiences from './display-experiences/Experiences';
import ExperienceForm from './experience-form/ExperienceForm';
import { experienceSchemaValidation } from './helpers/experienceYup';

const today = new Date();

const ExperiencesWrapper = ({ desktop, id, experiences }) => {
  const [createExperience] = useMutation(CREATE_USER_EXPERIENCE);
  const [deleteExperience] = useMutation(DELETE_USER_EXPERIENCE);
  const [updateExperience] = useMutation(UPDATE_USER_EXPERIENCE);
  const history = useHistory();
  const location = useLocation();

  const { openSnackbarSuccess, openSnackbarError } = useSnackbar();

  const [actionMode, setActionMode] = useState({
    editing: false,
    adding: false,
  });

  const defaultExperience = {
    userId: +id,
    role: '',
    company: '',
    startDate: today,
    endDate: today,
    duration: '',
    description: '',
  };

  const { t } = useTranslation(['common', 'dialog']);

  const validationSchema = useMemo(() => experienceSchemaValidation(t), []);

  const { watch, handleSubmit, register, errors, control, clearErrors, reset } = useForm({
    resolver: useYupValidationResolver(validationSchema),
    defaultValues: defaultExperience,
  });

  const openForm = (mode, editObject) => {
    if (mode === 'add') {
      setActionMode({
        adding: true,
        editing: false,
      });
    } else {
      setActionMode({
        editing: true,
        adding: false,
      });
      const tmpEditObject = { ...editObject };
      tmpEditObject.startDate = new Date(editObject.startDate);
      tmpEditObject.endDate = new Date(editObject.endDate);

      reset(tmpEditObject);
    }
  };

  const closeForm = () => {
    setActionMode({
      editing: false,
      adding: false,
      experience: defaultExperience,
    });

    clearErrors();
    reset(defaultExperience);
  };

  const getMonthAndYearToString = (startDate, endDate) => {
    return `${startDate.getMonth() + 1}/${startDate.getFullYear()} - ${
      endDate.getMonth() + 1
    }/${endDate.getFullYear()}`;
  };

  const handleAddEditExperience = async (experience) => {
    if (actionMode.adding) {
      try {
        experience.duration = getMonthAndYearToString(experience.startDate, experience.endDate);
        delete experience.id;
        const { data } = await createExperience({
          variables: { experience },
        });
        if (data) {
          openSnackbarSuccess(t('dialog:openSnackbarSuccess.experienceAdded'));
          closeForm();
          history.push({
            pathname: location.pathname,
            state: { isRefetchProfile: true },
          });
        }
      } catch (errorAddExperience) {
        openSnackbarError(`${t('dialog:openSnackbarError.addExperience')} ${errorAddExperience.message}`);
      }
    } else {
      try {
        experience.duration = getMonthAndYearToString(experience.startDate, experience.endDate);
        const { data } = await updateExperience({
          variables: { experience },
        });
        if (data) {
          openSnackbarSuccess(t('dialog:openSnackbarSuccess.experienceUpdated'));
          closeForm();
          history.push({
            pathname: location.pathname,
            state: { isRefetchProfile: true },
          });
        }
      } catch (errorUpdateExperience) {
        openSnackbarError(`${t('dialog:openSnackbarError.updateExperience')} ${errorUpdateExperience.message}`);
      }
    }
  };

  const handleDeleteExperience = async (experience) => {
    const experienceId = parseInt(experience.id);

    try {
      const { data: deleteUserExperience } = await deleteExperience({
        variables: { experience: { id: experienceId } },
      });
      if (deleteUserExperience) {
        openSnackbarSuccess(t('dialog:openSnackbarSuccess.experienceDeleted'));
        closeForm();
        history.push({
          pathname: location.pathname,
          state: { isRefetchProfile: true },
        });
      }
    } catch (errorDeleteExperience) {
      openSnackbarError(`${t('dialog:openSnackbarError.deleteExperience')} ${errorDeleteExperience.message}`);
    }
  };
  return (
    <>
      {experiences && experiences.length !== 0 && (
        <Experiences experiences={experiences} openForm={openForm} desktop={desktop} />
      )}
      <ExperienceForm
        watch={watch}
        register={register}
        control={control}
        editing={actionMode.editing}
        adding={actionMode.adding}
        errors={errors}
        openForm={openForm}
        closeForm={closeForm}
        handleAddEditExperience={handleSubmit(handleAddEditExperience)}
        handleDeleteExperience={handleSubmit(handleDeleteExperience)}
        desktop={desktop}
      />
    </>
  );
};

ExperiencesWrapper.propTypes = {
  id: number.isRequired,
  experiences: arrayOf(object).isRequired,
  desktop: bool.isRequired,
};

export default ExperiencesWrapper;
