import { Paper, Grid, makeStyles } from '@material-ui/core';
import { bool, number, func, object, arrayOf } from 'prop-types';

import MilestonePublish from './milestone/MilestonePublish.js';

const useStyles = makeStyles((theme) => ({
  fontWeight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  paperStyle: {
    backgroundColor: '#EBEBEB',
    height: 'auto',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: '6px',
  },
  buttonStyle: {
    display: 'flex',
    paddingRight: 0,
    paddingTop: 0,
    justifyContent: 'flex-end',
  },
  h5Custom: {
    fontStyle: 'normal',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '20px',
    // lineHeight:'24px',
    letterSpacing: '0.1px',
    color: '#575757',
  },
  h6Custom: {
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.1px',
  },
  publishButton: {
    paddingLeft: 5,
    paddingRight: 5,
    textDecoration: 'underline',
    fontWeight: theme.typography.fontWeightBold,
    color: '#989898',
  },
}));

const MainContent = ({
  isMilestone,
  handleCancelEditMode,
  numberLogChosen,
  project,
  newLog,
  handleStatusNewLog,
  isNewLog,
  handleChangeNewLog,
  refetchPanel,
  isReload,
  reloadComponent,
  updateLogs,
  deleteNewLog,
  crudStatus,
  setCrudStatus,
  isShowAutoSave,
  setIsShowAutoSave,
  desktop,
}) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Paper className={classes.paperStyle}>
        <Grid container direction="row" justify="center" alignItems="center">
          {isMilestone && (
            <MilestonePublish
              numberLogChosen={numberLogChosen}
              updateLogs={updateLogs}
              project={project}
              newLog={newLog}
              handleStatusNewLog={handleStatusNewLog}
              isNewLog={isNewLog}
              handleChangeNewLog={handleChangeNewLog}
              refetchPanel={refetchPanel}
              isReload={isReload}
              reloadComponent={reloadComponent}
              deleteNewLog={deleteNewLog}
              handleCancelEditMode={handleCancelEditMode}
              crudStatus={crudStatus}
              setCrudStatus={setCrudStatus}
              isShowAutoSave={isShowAutoSave}
              setIsShowAutoSave={setIsShowAutoSave}
              desktop={desktop}
            />
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

MainContent.propTypes = {
  project: object.isRequired,
  isMilestone: bool.isRequired,
  numberLogChosen: number.isRequired,
  newLog: object.isRequired,
  handleStatusNewLog: func.isRequired,
  isNewLog: bool.isRequired,
  handleChangeNewLog: func.isRequired,
  refetchPanel: func.isRequired,
  handleCancelEditMode: func.isRequired,
  isReload: bool,
  reloadComponent: func,
  updateLogs: arrayOf(object),
  deleteNewLog: func,
  crudStatus: number,
  setCrudStatus: func,
  isShowAutoSave: bool,
  setIsShowAutoSave: func,
  desktop: bool,
};

export default MainContent;
