import { Box } from '@material-ui/core';
import _ from 'lodash';

const withEmpty = (Component) => {
  const wrapped = (props) => {
    const { items, emptyText } = props;
    return _.isEmpty(items) ? <Box fontWeight="fontWeightBold">{emptyText}</Box> : <Component {...props} />;
  };

  return wrapped;
};

export default withEmpty;
