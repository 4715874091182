import { Avatar, Badge, Box, Button, Grid, Tooltip, Typography, makeStyles, withStyles } from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import _ from 'lodash';
import { func, number, object } from 'prop-types';

import { getDisplayName } from 'helpers/common';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  grid: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0.75),
    textTransform: 'none',
    textAlign: 'left',
    borderRadius: '6px',
    '&:hover': {
      backgroundColor: '#F5F5F5',
    },
  },
  link: {
    '&': { color: theme.palette.primary.main },
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  yellow: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
  },
  avatars: {
    padding: theme.spacing(1, 0),
    float: 'left',
    '& > div': {
      float: 'left',
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  },
  nbMembers: {
    ...theme.typography.h6,
    color: '#575757',
  },
  certifiedIcon: {
    color: '#fff',
    backgroundColor: '#FAC405',
    borderRadius: '50%',
    height: '0.5em',
    width: '0.5em',
  },
}));

const SmallAvatarStyles = withStyles({
  root: {
    maxWidth: 32,
    maxHeight: 32,
  },
})((props) => <Avatar {...props} />);

const btnActive = (active) => ({
  backgroundColor: active ? '#F5F5F5' : 'transparent',
});

const NUMBER_DISPLAY = 3;

const TeamsListItem = ({ team, teamActive, handleChangeTeam }) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const renderTeamDisplayed = (teamDisplayed) => {
    const members = _.flatMap(teamDisplayed.teams, (t) => t.members);

    if (members.length > 4) {
      const nbMembersRest = members.length - 3;
      return (
        <>
          {_.map(_.take(members, NUMBER_DISPLAY), (m) => {
            return (
              <Badge
                key={getDisplayName(m)}
                badgeContent={m.certified && <CheckIcon className={classes.certifiedIcon} />}
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                className={classes.avatarIcon}
              >
                <SmallAvatarStyles alt={getDisplayName(m)} src={m.avatarLink} />
              </Badge>
            );
          })}
          <Tooltip title={t('team.othersTitle')}>
            <Badge>
              <SmallAvatarStyles className={classes.yellow}>+{nbMembersRest}</SmallAvatarStyles>
            </Badge>
          </Tooltip>
        </>
      );
    }

    return _.map(members, (m) => (
      <Badge
        key={getDisplayName(m)}
        badgeContent={m.certified && <CheckIcon className={classes.certifiedIcon} />}
        overlap="circle"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        className={classes.avatarIcon}
      >
        <SmallAvatarStyles alt={getDisplayName(m)} src={m.avatarLink} />
      </Badge>
    ));
  };

  return (
    <Grid
      className={classes.grid}
      container
      component={Button}
      onClick={(e) => handleChangeTeam(e, team.id)}
      style={btnActive(teamActive === team.id)}
    >
      <Grid item container xs={3} direction="row" justify="center" alignItems="center">
        <Box display="block" width={80} height={80} className={classes.avatars}>
          {renderTeamDisplayed(team)}
        </Box>
      </Grid>
      <Grid item xs>
        <Box mb={2}>
          <Box my={1}>
            <Typography variant="h5" className={classes.link}>
              {team.name}
            </Typography>
          </Box>
          <Box component={Grid} item xs>
            <Box component={Typography} variant="caption" color="text.secondary">
              {t('team.members')}
            </Box>
            <Box component="span" ml={1} className={classes.nbMembers}>
              {team.nbMembers} {team.nbMembers > 1 ? t('team.people') : t('team.person')}
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

TeamsListItem.propTypes = {
  team: object,
  teamActive: number.isRequired,
  handleChangeTeam: func.isRequired,
};

TeamsListItem.defaultProps = {
  team: null,
};

export default TeamsListItem;
