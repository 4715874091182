import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { BusinessCenter as BusinessCenterIcon, Stars as StarsIcon } from '@material-ui/icons';
import { object, bool, func } from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Markdown from 'components/markdown/Markdown';
import { DETAILS_TYPE } from 'helpers/constants';

const useStyles = makeStyles((theme) => ({
  header: {
    color: '#414141',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  button: {
    borderColor: theme.palette.text.secondary,
    padding: theme.spacing(0.25, 1.5),
    marginLeft: theme.spacing(1),
    textTransform: 'none',
    borderRadius: '6px',
    borderWidth: '1px',
    [theme.breakpoints.down('sm')]: {
      padding: '7px 8px 4px',
      fontSize: 12,
    },
  },
  itemWrapper: {
    minWidth: 720,
    [theme.breakpoints.down('sm')]: {
      minWidth: 260,
    },
  },
  itemTitle: {
    ...theme.typography.h5,
    color: '#000',
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.h6,
    },
  },
  itemSubTitle: {
    ...theme.typography.h6,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
  },
  itemDescription: {
    ...theme.typography.body1,
    color: '#575757',
    fontWeight: '500',
    [theme.breakpoints.down('sm')]: {
      '& p, span': {
        fontSize: 12,
      },
    },
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
    height: 0.1,
    backgroundColor: '#ccc',
    border: 'none',
  },
}));

const ExperienceContestDetailsDialog = ({ options, open, desktop, handleCloseDialog }) => {
  const classes = useStyles();

  const { type } = options;
  const [content, setContent] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    if (type === DETAILS_TYPE.EXPERIENCES) {
      const { experiences } = options;
      setContent([...experiences]);
    } else {
      const { contests } = options;
      setContent([...contests]);
    }
  }, [options]);

  const renderExperienceDuration = (startDate, endDate) => {
    if (type === DETAILS_TYPE.EXPERIENCES) {
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);
      return `${startDateObj.toLocaleString('default', {
        month: 'long',
      })}, ${startDateObj.getFullYear()} - ${endDateObj.toLocaleString('default', {
        month: 'long',
      })}, ${endDateObj.getFullYear()}`;
    }
    return new Date(startDate).getFullYear();
  };

  const renderDialogActions = (
    <DialogActions>
      <Button onClick={() => handleCloseDialog()} variant="outlined" className={classes.button}>
        {t('button.back')}
      </Button>
    </DialogActions>
  );

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="list-project-dialog-title"
      aria-describedby="list-project-description"
      className={classes.dialog}
      maxWidth="md"
    >
      <DialogTitle disableTypography id="list-project-title">
        <Grid container alignItems="center" alignContent="space-between">
          <Grid item xs>
            <Typography color="textSecondary" variant={desktop ? 'h5' : 'h6'}>
              <Box display="flex" alignItems="center" className={classes.header}>
                {type === DETAILS_TYPE.EXPERIENCES ? (
                  <BusinessCenterIcon color="primary" className={classes.icon} />
                ) : (
                  <StarsIcon color="primary" className={classes.icon} />
                )}
                {type === DETAILS_TYPE.EXPERIENCES ? t('common.experiences') : t('common.contests')}
              </Box>
            </Typography>
          </Grid>
          {desktop && (
            <Grid item xs>
              {renderDialogActions}
            </Grid>
          )}
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        {content.map((item, index) => (
          <div className={classes.itemWrapper} key={+item.id}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: desktop ? 20 : 16,
              }}
            >
              <div>
                <Typography className={classes.itemTitle}>
                  {type === DETAILS_TYPE.EXPERIENCES ? item.role : item.name}
                </Typography>
                <Typography className={classes.itemSubTitle}>
                  {type === DETAILS_TYPE.EXPERIENCES ? item.company : item.reward}
                </Typography>
              </div>
              <div>
                <Typography className={classes.itemSubTitle}>
                  {type === DETAILS_TYPE.EXPERIENCES
                    ? renderExperienceDuration(item.startDate, item.endDate)
                    : renderExperienceDuration(item.year)}
                </Typography>
              </div>
            </div>
            <div>
              <Box className={classes.itemDescription}>
                <Markdown>{item.description}</Markdown>
              </Box>
            </div>
            {index !== content.length - 1 && <hr className={classes.divider} />}
          </div>
        ))}
      </DialogContent>
      {!desktop && renderDialogActions}
    </Dialog>
  );
};

ExperienceContestDetailsDialog.propTypes = {
  options: object.isRequired,
  open: bool.isRequired,
  desktop: bool.isRequired,
  handleCloseDialog: func.isRequired,
};

export default ExperienceContestDetailsDialog;
