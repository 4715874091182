import { makeStyles } from '@material-ui/core';

const HEIGHT_DESKTOP = 239;
const HEIGHT_MOBILE = 170;

const useStyles = (isBig) =>
  makeStyles((theme) => ({
    cover: {
      maxWidth: '100%',
      width: '100%',
      backgroundColor: '#eee',
      minHeight: isBig ? HEIGHT_DESKTOP : HEIGHT_MOBILE,
      borderRadius: 6,
      [theme.breakpoints.down('sm')]: { minHeight: HEIGHT_MOBILE },
    },
    media: {
      height: HEIGHT_MOBILE,
      borderRadius: 6,
    },
    mediaDefault: {
      height: HEIGHT_MOBILE,
      width: '100%',
      borderRadius: 6,
      backgroundSize: 90,
    },
    mediaBig: {
      height: HEIGHT_DESKTOP,
      borderRadius: 6,
    },
    mediaBigDefault: {
      height: HEIGHT_DESKTOP,
      borderRadius: 6,
      backgroundSize: 200,
    },
    content: {
      padding: 0,
    },
    category: {
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: 13,
        lineHeight: '1rem',
      },
    },
    divider: {
      height: '1rem',
      margin: theme.spacing(2),
    },
    time: {
      color: '#989898',
      minWidth: 115,
      '& > div': {
        display: 'inline-block',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 13,
        display: 'flex',
        alignItems: 'center',
      },
    },
    more: {
      color: theme.palette.secondary.main,
      fontWeight: 'bold',
      display: 'block',
      marginTop: theme.spacing(2),
    },
    cardHeaderTitle: {
      padding: 0,
      '& > .MuiCardHeader-avatar': {
        marginRight: 8,
        [theme.breakpoints.down('sm')]: {
          marginRight: 4,
        },
      },
      '& > .MuiCardHeader-action': {
        marginTop: 0,
        marginRight: 0,
      },
      '& > .MuiCardHeader-content': {
        '& > .MuiTypography-h6': {
          fontWeight: '0',
          color: '#000',
        },
      },
    },
    name: {
      fontSize: 15,
      lineHeight: '20px',
      color: '#575757',
      '&:hover': {
        color: theme.palette.primary.dark,
      },
      '&:active, &:focus': {
        color: theme.palette.text.primary,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 13,
      },
    },
    nameUpdated: {
      fontSize: 15,
      lineHeight: '20px',
      color: '#989898',
      paddingLeft: '4px',
      [theme.breakpoints.down('sm')]: {
        fontSize: 13,
      },
    },
    title: {
      color: '#333333',
      fontWeight: 700,
      paddingTop: theme.spacing(0.5),
      '&:hover': {
        color: theme.palette.primary.dark,
      },
      '&:active, &:focus': {
        color: theme.palette.text.primary,
      },
    },
    attachmentPadding: {
      padding: theme.spacing(1, 0, 0),
    },
    descriptionPadding: {
      padding: theme.spacing(1.5, 0, 2),
      color: '#575757',
      [theme.breakpoints.down('sm')]: {
        fontSize: 13,
        color: '#777',
        paddingBottom: 12,
      },
    },
    avatar: {
      height: theme.spacing(4.5),
      width: theme.spacing(4.5),
    },
  }));

export default useStyles;
