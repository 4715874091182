import { useMutation } from '@apollo/client';
import { Box, ClickAwayListener, IconButton } from '@material-ui/core';
import { Close as CloseIcon, Edit as EditIcon, Save as SaveIcon } from '@material-ui/icons';
import { bool, arrayOf, shape, number, func } from 'prop-types';
import { useState } from 'react';

import { UPDATE_CONTEST_TAGS } from 'apollo/mutations/contest-mutation';
import { Tags as CocoonTags } from 'components';
import { useSnackbar } from 'hooks';

// const useStyles = makeStyles(() => ({}));

function Icon({ isAdding, hasNewTags }) {
  if (isAdding) {
    if (hasNewTags) {
      return <SaveIcon />;
    }

    return <CloseIcon />;
  }
  return <EditIcon />;
}

Icon.propTypes = {
  isAdding: bool.isRequired,
  hasNewTags: bool.isRequired,
};

function Tags({ contestId, tags, refetch }) {
  // const classes = useStyles();
  const [isAddNewTags, setIsAddNewTags] = useState(false);
  const [newTags, setNewTags] = useState(() => [...tags]);
  const [hasNewTags, setHasNewTags] = useState(false);
  const [updateTags] = useMutation(UPDATE_CONTEST_TAGS);

  const { openSnackbarSuccess, openSnackbarError } = useSnackbar();

  function handleAddNewTag(tag) {
    if (newTags.length > 5) return;
    setHasNewTags(true);
    setNewTags([...tag]);
  }

  async function handleUpdateTags() {
    try {
      const { data } = await updateTags({
        variables: {
          contest: {
            contestId,
            tags: newTags.map(({ tagName }) => tagName),
          },
        },
      });
      if (data) {
        refetch();
        openSnackbarSuccess('Tags updated!');
        setHasNewTags(false);
        setIsAddNewTags(false);
      }
    } catch (err) {
      openSnackbarError('Could not update tags');
      throw new Error('Could not update tags', err.message);
    }
  }

  function handleToggleAddTags() {
    if (isAddNewTags) {
      if (hasNewTags) {
        return handleUpdateTags();
      }
      return setIsAddNewTags(false);
    }

    return setIsAddNewTags(true);
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (!hasNewTags) {
          setIsAddNewTags(false);
        }
      }}
    >
      <Box display="flex" flexDirection="row" alignItems="center">
        <CocoonTags isLightView tags={newTags} isEditMode={isAddNewTags} setTags={handleAddNewTag} />
        <IconButton onClick={handleToggleAddTags} variant="text" color="primary">
          <Icon isAdding={isAddNewTags} hasNewTags={hasNewTags} />
        </IconButton>
      </Box>
    </ClickAwayListener>
  );
}

Tags.propTypes = {
  contestId: number.isRequired,
  tags: arrayOf(shape({})).isRequired,
  refetch: func.isRequired,
};

export default Tags;
