import { useLazyQuery, useMutation } from '@apollo/client';
import { TextField, CircularProgress, Box, Chip, Button, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { arrayOf, shape, func, number } from 'prop-types';
import { useState, useEffect } from 'react';

import { SAVE_CONTEST_SPONSORS } from 'apollo/mutations/contest-mutation';
import { GET_PARTNERS } from 'apollo/queries/user-query';
import { cookieUserId } from 'helpers/auth';
import { useDebounce, useSnackbar } from 'hooks';

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '90%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    columnGap: 8,
    marginTop: 10,
  },
  chip: {
    marginTop: 8,
  },
}));

export default function SelectSponsors({ sponsors, contestId, refetch }) {
  const classes = useStyles();

  const [getPartnersCb, { data: partnersData, loading: loadingPartners }] = useLazyQuery(GET_PARTNERS, {
    fetchPolicy: 'network-only',
  });
  const [searchPartner, setSearchPartner] = useState('');
  const debouncedSearchPartner = useDebounce(searchPartner, 500);
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [selectedPartners, setSelectedPartners] = useState([]);
  const [saveContestSponsors] = useMutation(SAVE_CONTEST_SPONSORS);

  const { openSnackbarSuccess, openSnackbarError } = useSnackbar();

  function handleDeleteTag(tag) {
    const { id } = tag;
    const copy = selectedPartners.filter((t) => t.id !== id);
    setSelectedPartners([...copy]);
  }

  async function handleSaveSponsor() {
    if (selectedPartners.length === 0 || !contestId) return;

    try {
      const { data } = await saveContestSponsors({
        variables: {
          contest: {
            contestId,
            sponsorsIds: selectedPartners,
          },
        },
      });
      if (data) {
        refetch();
        openSnackbarSuccess('Contest updated!');
      }
    } catch (err) {
      openSnackbarError('Something went wrong');
      throw new Error('Error save contest sponsors', err.message);
    }
  }

  useEffect(() => {
    if (debouncedSearchPartner) {
      getPartnersCb({
        variables: {
          name: debouncedSearchPartner,
          page: 1,
          limit: 'FIVE_O',
        },
      });
    }
  }, [debouncedSearchPartner]);

  useEffect(() => {
    // TODO: do this in BE
    if (partnersData) {
      const userId = +cookieUserId;
      const sponsorsIds = sponsors.map(({ sponsor }) => +sponsor.id).concat([userId]);
      const filtered = partnersData.partners.filter(({ id }) => !sponsorsIds.includes(+id));
      setPartnerOptions(filtered);
    }
  }, [partnersData]);

  return (
    <Box className={classes.wrapper}>
      <Autocomplete
        multiple
        className={classes.autocomplete}
        value={selectedPartners}
        id="asynchronous-partners"
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={(event, value) => setSelectedPartners([...value])}
        getOptionLabel={({ email }) => email}
        options={partnerOptions || []}
        loading={loadingPartners}
        renderTags={() => undefined}
        renderInput={(params) => (
          <TextField
            {...params}
            className={classes.textField}
            placeholder="Search for a sponsor"
            variant="outlined"
            onChange={(e) => setSearchPartner(e.target.value)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loadingPartners ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      {selectedPartners.length > 0 && (
        <Box>
          <Box marginBottom="16px" className={classes.row}>
            {selectedPartners.map((tag) => (
              <Chip className={classes.chip} key={tag.id} label={tag.email} onDelete={() => handleDeleteTag(tag)} />
            ))}
          </Box>
          <Button onClick={handleSaveSponsor} variant="contained" color="primary">
            Save
          </Button>
        </Box>
      )}
    </Box>
  );
}

SelectSponsors.propTypes = {
  sponsors: arrayOf(shape({})).isRequired,
  contestId: number.isRequired,
  refetch: func.isRequired,
};
