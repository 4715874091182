import { gql } from '@apollo/client';

// done
// updated
export const ADD_PROJECT = gql`
  mutation createProject($project: NewProjectInput!) {
    createProject(project: $project) {
      id
      name
    }
  }
`;

// done
// updated
export const EDIT_PROJECT = gql`
  mutation editProject($project: UpdatedProjectInput!) {
    editProject(project: $project) {
      id
      name
    }
  }
`;

// done
// updated
export const DEACTIVATE_PROJECT = gql`
  mutation deactivateProject($project: ArchiveProjectInput!) {
    deactivateProject(project: $project) {
      id
      name
    }
  }
`;

// done
// updated
export const REACTIVATE_PROJECT = gql`
  mutation reactivateProject($project: ArchiveProjectInput!) {
    reactivateProject(project: $project) {
      id
      name
    }
  }
`;
