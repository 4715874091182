import { useMediaQuery, Container, Box, Typography, makeStyles } from '@material-ui/core';
import { arrayOf, object } from 'prop-types';
import { useEffect } from 'react';

import logoH from '@images/logo/projectcocoon-logo.svg';
import { backToAnchor, CocoonHelmet } from 'components';

import AboutParagraph from './components/AboutParagraph';
import AboutPartners from './components/AboutPartners';
import AboutTitle from './components/AboutTitle';
// import BannerHover from './banner/BannerHover';

import { META_DATA } from 'helpers/metaData';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(4),
  },
}));

const intro =
  'Project Cocoon is a one-stop solution for your projects, a community of project makers helps bring your ideas to reality.';

const metadataDescription =
  'Project Cocoon is a community of project makers helps bring your ideas to reality. In here, we offer showcase your projects, build your profiles.';

const metadataTitle = 'About us - Project Cocoon place to showcase your projects and build your profiles';

const About = ({ text, industrialPartners, educationalPartners }) => {
  const classes = useStyles();
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const { title, description, image, url } = META_DATA.ABOUT;
  useEffect(() => {
    backToAnchor(document);
  }, []);

  return (
    <>
      <Container fixed className={classes.container}>
        <CocoonHelmet title={title} description={description} image={image} url={url} />
        <Box width={300} mt={6} mb={3} mx="auto">
          <img src={logoH} alt="Project Cocoon a community of project makers helps bring your ideas to reality" />
        </Box>
      </Container>
      {/* <BannerHover /> */}
      <Container fixed className={classes.container}>
        <Box mb={3}>
          <AboutTitle title="About us" desktop={desktop} />
          <Typography variant={desktop ? 'body1' : 'body2'} gutterBottom component="p" color="textPrimary">
            {intro}
          </Typography>
        </Box>
        {text.map((p) => (
          <AboutParagraph key={p.title} p={p} desktop={desktop} />
        ))}
        <AboutPartners partners={industrialPartners} desktop={desktop} />
        <AboutParagraph
          p={{
            title: 'Build knowledge through our network of educational and training partners',
          }}
          desktop={desktop}
        />
        <AboutPartners partners={educationalPartners} desktop={desktop} />
      </Container>
    </>
  );
};

About.propTypes = {
  text: arrayOf(object).isRequired,
  industrialPartners: arrayOf(object).isRequired,
  educationalPartners: arrayOf(object).isRequired,
};

export default About;
