import awsApi from '../aws';
import baseApi from '../baseApi';

const fileApi = () => {
  baseApi.setBaseUrl('api/file-upload');
  return {
    getPreSignedUrl: (params) => baseApi.get('signed-url', params),
    uploadFile: async (data, file) => {
      const { url, key } = data;
      await awsApi.upload(url, file, file.type);
      return key;
    },
  };
};

export default fileApi();
