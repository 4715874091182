import { makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { number, object, arrayOf, bool } from 'prop-types';

import TeamsListItem from './TeamsListItem';

const useStyles = makeStyles((theme) => ({
  button: {
    color: '#575757',
    width: '100%',
    borderColor: '#575757',
    borderRadius: '6px',
    borderWidth: '1px',
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(0.25, 1.5),
    textTransform: 'none',
    '&:hover': {
      background: 'transparent',
      '& *': {
        fontWeight: 'bold',
      },
    },
  },
}));

export default function TeamsList({ teams, ...props }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {!_.isEmpty(teams) && _.map(teams, (team) => <TeamsListItem key={team.id} team={team} {...props} />)}
    </div>
  );
}

TeamsList.propTypes = {
  teams: arrayOf(object),
  isNewProject: bool.isRequired,
  ownerId: number,
};

TeamsList.defaultProps = {
  teams: null,
  ownerId: undefined,
};
