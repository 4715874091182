import { Tab, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  tab: {
    minWidth: '100px',
  },
  tabTitlePrimary: {
    ...theme.typography.h6,
    textTransform: 'none',
    position: 'absolute',
    top: '50%',
    color: theme.palette.secondary.main,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 900,
    letterSpacing: '0.1px',
  },
  tabTitleSecondary: {
    ...theme.typography.h6,
    textTransform: 'none',
    position: 'absolute',
    top: '50%',
    color: '#989898',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 900,
    letterSpacing: '0.1px',
  },
}));

const ProfileTab = ({ handleTabSwitch, active, tabProps, title }) => {
  const classes = useStyles();

  return (
    <Tab
      className={classes.tab}
      onClick={() => handleTabSwitch()}
      label={<span className={active ? classes.tabTitlePrimary : classes.tabTitleSecondary}>{title}</span>}
      {...tabProps}
    />
  );
};

ProfileTab.propTypes = {
  handleTabSwitch: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  tabProps: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default ProfileTab;
