import { Grid, makeStyles } from '@material-ui/core';
import { bool, string } from 'prop-types';

import { Markdown } from 'components';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  commentBodyContainer: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  commentText: {
    wordBreak: 'break-word',
    '& *': {
      color: theme.palette.common.black,
    },
  },
}));

const CommentBody = ({ content, isDeleted }) => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <Grid className={classes.commentBodyContainer} container>
      {isDeleted ? <p>{t('common.deletedComment')}</p> : <Markdown className={classes.commentText}>{content}</Markdown>}
    </Grid>
  );
};

CommentBody.propTypes = {
  content: string.isRequired,
  isDeleted: bool,
};

export default CommentBody;
