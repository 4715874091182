import { Divider, Grid, makeStyles } from '@material-ui/core';
import { bool, number, oneOfType, string } from 'prop-types';

import ButtonLike from './components/button-like';
import ButtonReply from './components/button-reply/ButtonReply';

const useStyles = makeStyles((theme) => ({
  commentExtraContainer: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(-1),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
}));

const CommentExtra = ({ topicLink, projectId, replyCount, isLiked, likeCount }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.commentExtraContainer} container>
      <ButtonLike isLiked={isLiked} likeCount={likeCount} projectId={+projectId} />
      {replyCount !== null && (
        <>
          <Grid item>
            <Divider orientation="vertical" />
          </Grid>
          <ButtonReply topicLink={topicLink} replyCount={replyCount} />
        </>
      )}
    </Grid>
  );
};

CommentExtra.propTypes = {
  isLiked: bool,
  likeCount: number.isRequired,
  projectId: oneOfType([number, string]).isRequired,
  replyCount: number,
  topicLink: string.isRequired,
};

CommentExtra.defaultProps = {
  isLiked: null,
  replyCount: null,
};

export default CommentExtra;
