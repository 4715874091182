import { Grid, Box, Typography, makeStyles } from '@material-ui/core';
import { string, object, number, oneOfType, bool } from 'prop-types';

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  data: {
    color: theme.palette.success.dark,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  iconData: {
    '& h6': {
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
    },
  },
}));

const ProjectCardProgressData = ({ label, icon: Icon, data, desktop }) => {
  const classes = useStyles();

  return (
    <>
      {label && (
        <>
          <Grid
            item
            xs={desktop ? 12 : 8}
            component={Typography}
            variant="h6"
            color="textSecondary"
            className={classes.label}
          >
            {label}
          </Grid>
          <Grid
            item
            xs={desktop ? 12 : 4}
            component={Typography}
            variant={desktop ? 'h4' : 'h6'}
            className={classes.data}
          >
            {data}
          </Grid>
        </>
      )}
      {Icon && (
        <Box textAlign="center" className={classes.iconData}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Icon color="primary" fontSize={desktop ? 'default' : 'small'} />
          </Box>
          <Typography variant="h6">{data}</Typography>
        </Box>
      )}
    </>
  );
};

ProjectCardProgressData.propTypes = {
  label: string,
  data: oneOfType([string, number]).isRequired,
  icon: object,
  desktop: bool.isRequired,
};

ProjectCardProgressData.defaultProps = {
  label: undefined,
  icon: undefined,
};

export default ProjectCardProgressData;
