import { useQuery } from '@apollo/client';
import { Grid, CircularProgress, makeStyles } from '@material-ui/core';
import { bool, number, func, object } from 'prop-types';
import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { GET_UPDATES_BY_PROJECT_ID } from 'apollo/queries/update-query';

// import ProgressBarCondition from '../progress-bar/ProgressBarCondition';
import { CRUD_STATUS, LOG_DEFAULT, NEW_LOG_DESCRIPTION } from '../../helpers/constants';
import { addLogsStatus } from '../../helpers/function';
import FilterCondition from '../filter/FilterCondition';
import HeaderCondition from '../header-panel/HeaderCondition';

import ContentCondition from './ContentCondition';

import { useDispatch, useSelector } from 'react-redux';

import { isEdit, isRead, getModeIsEditing } from 'reducers/panelReducer';

const useStyles = (desktop) =>
  makeStyles((theme) => ({
    fullHeight: {
      height: '100%',
    },
    fullWidth: {
      width: '100%',
    },
    filterPadding: {
      width: '100%',
      paddingRight: desktop ? theme.spacing(4) : 0,
      // paddingTop: theme.spacing(4),
      // height: 80
    },
    contentPadding: {
      width: '100%',
      paddingTop: theme.spacing(3),
    },
  }));

const MainContent = ({
  navigationTab,
  dashboardTab,
  phaseTab,
  project,
  // uploadedFile,
  // openDialogDeactivate,
  // handleChangeNavigationTab,
  handleChangeDashboardTab,
  handleChangePhaseTab,
  refetchPanel,
  isReload,
  reloadComponent,
  desktop,
}) => {
  const classes = useStyles(desktop)();
  const dispatch = useDispatch();
  // let chosenMilestone = 0;

  const [crudStatus, setCrudStatus] = useState(CRUD_STATUS.VIEW);
  const [isMilestone, setIsMilestone] = useState(true);
  const [isPhase, setIsPhase] = useState(false);
  const [numberLogChosen, setNumberLogChosen] = useState(0);
  const [newLog, setNewLog] = useState(LOG_DEFAULT);
  const [isNewLog, setIsNewLog] = useState(false);
  const [updateLogs, setUpdateLogs] = useState(null);
  const [isShowAutoSave, setIsShowAutoSave] = useState(false);

  const { state } = useLocation();
  const history = useHistory();

  const isModeEditing = useSelector(getModeIsEditing);
  const handleChangeEditMode = () => dispatch(isEdit());

  const {
    loading: logsLoading,
    data: dataLogs,
    error: logsError,
    refetch: refetchUpdates,
  } = useQuery(GET_UPDATES_BY_PROJECT_ID, {
    variables: { projectId: project.id, page: 1, limit: 'TEN' },
    skip: !project.id,
  });

  useEffect(() => {
    if (isReload) {
      refetchUpdates();
      reloadComponent();
    }
  }, [isReload]);

  useEffect(() => {
    if (state && state.isRefetch) {
      refetchUpdates();
      history.push({ state: { isRefetch: false } });
    }
  }, [state]);

  useEffect(() => {
    if (dataLogs && !isNewLog) {
      // const tempUpdateLogs = arrangeUpdateLogs(dataLogs.logs);
      const tempUpdateLogs = dataLogs.logs;

      if (tempUpdateLogs.length > 0) {
        setUpdateLogs(addLogsStatus(tempUpdateLogs));
      } else {
        const tempNewLog = [];
        const newLog = {
          id: 0,
          name: '',
          description: NEW_LOG_DESCRIPTION.data,
          isMilestone: true,
          createdAt: new Date(),
          isNew: true,
        };
        tempNewLog.push(newLog);
        setUpdateLogs(tempNewLog);
      }
    }
  }, [dataLogs, isNewLog]);
  useEffect(() => {
    if (isNewLog && crudStatus === CRUD_STATUS.EDIT) {
      setIsShowAutoSave(true);
      return;
    }
    if (isNewLog) {
      const newLogToAdd = {
        id: 0,
        name: '',
        description: NEW_LOG_DESCRIPTION.data,
        isMilestone: true,
        createdAt: new Date(),
        isNew: true,
      };
      setUpdateLogs((oldDate) => [newLogToAdd, ...oldDate]);
    }
  }, [isNewLog, isShowAutoSave]);

  const deleteNewLog = () => {
    const newLogDeleted = updateLogs.filter((e) => e.id !== updateLogs[0].id);
    setUpdateLogs(newLogDeleted);
    setIsNewLog(false);
  };

  const handleCancelEditMode = () => {
    setCrudStatus(CRUD_STATUS.VIEW);
    setIsNewLog(false);
  };

  useEffect(() => {
    if (project) {
      handleCancelEditMode();
    }
  }, [project]);

  const changeMilestoneContent = () => {
    setIsMilestone(true);
    setIsPhase(false);
  };

  const changePhaseContent = () => {
    setIsMilestone(false);
    setIsPhase(true);
  };

  const handleChangeChosenUpdate = (id) => {
    // chosenMilestone = id;
    setNumberLogChosen(id);
  };

  const handleStatusNewLog = (value) => {
    setIsNewLog(value);
  };

  const handleChangeNewLog = (target, value) => {
    const tempLog = newLog;
    tempLog[target] = value;
    setNewLog(tempLog);
  };

  const handleDeleteUpdate = () => {};

  if (logsLoading) return <CircularProgress />;
  if (logsError) return `Error: ${logsError}`;

  return (
    <>
      {desktop && (
        <Grid item project={project} className={classes.fullWidth}>
          <HeaderCondition
            project={project}
            navigationTab={navigationTab}
            dashboardTab={dashboardTab}
            phaseTab={phaseTab}
            handleChangePhaseTab={handleChangePhaseTab}
            handleChangeDashboardTab={handleChangeDashboardTab}
            handleStatusNewLog={handleStatusNewLog}
          />
        </Grid>
      )}
      {/* <Grid item className={classes.fullWidth}>
        <ProgressBarCondition
          project={project}
          navigationTab={navigationTab}
          dashboardTab={dashboardTab}
        />
      </Grid> */}
      <Grid item className={classes.filterPadding}>
        <FilterCondition
          project={project}
          handleChangeChosenUpdate={handleChangeChosenUpdate}
          dashboardTab={dashboardTab}
          handleStatusNewLog={handleStatusNewLog}
          handleChangeNewLog={handleChangeNewLog}
          isNewLog={isNewLog}
          handleDeleteUpdate={handleDeleteUpdate}
          isReload={isReload}
          reloadComponent={reloadComponent}
          updateLogs={updateLogs}
          deleteNewLog={deleteNewLog}
          setIsShowAutoSave={setIsShowAutoSave}
        />
      </Grid>
      <Grid item className={classes.contentPadding}>
        <ContentCondition
          dashboardTab={dashboardTab}
          project={project}
          isMilestone={isMilestone}
          isPhase={isPhase}
          changeMilestoneContent={changeMilestoneContent}
          changePhaseContent={changePhaseContent}
          numberLogChosen={numberLogChosen}
          handleStatusNewLog={handleStatusNewLog}
          newLog={newLog}
          isNewLog={isNewLog}
          handleChangeNewLog={handleChangeNewLog}
          refetchPanel={refetchPanel}
          isReload={isReload}
          reloadComponent={reloadComponent}
          updateLogs={updateLogs}
          deleteNewLog={deleteNewLog}
          handleCancelEditMode={handleCancelEditMode}
          crudStatus={crudStatus}
          setCrudStatus={setCrudStatus}
          isShowAutoSave={isShowAutoSave}
          setIsShowAutoSave={setIsShowAutoSave}
          desktop={desktop}
        />
      </Grid>
    </>
  );
};

MainContent.propTypes = {
  project: object.isRequired,
  dashboardTab: number.isRequired,
  navigationTab: number.isRequired,
  phaseTab: number.isRequired,
  handleChangeDashboardTab: func.isRequired,
  handleChangePhaseTab: func.isRequired,
  refetchPanel: func.isRequired,
  isReload: bool,
  reloadComponent: func,
  desktop: bool,
};

MainContent.defaultProps = {
  uploadedFile: null,
  loading: false,
  error: undefined,
};

export default MainContent;
