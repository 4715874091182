import { useQuery } from '@apollo/react-hooks';
import { IconButton, Box, Grid, Typography, makeStyles } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import { string } from 'prop-types';
import { useState } from 'react';
import { Redirect, useParams, useHistory } from 'react-router-dom';

import { GET_CONTEST_BY_ID } from 'apollo/queries/contest-query';
import { CircularCenterLoader } from 'components';
import { cookieUserId } from 'helpers/auth';
import { convertToSlug } from 'helpers/common';

import VerticalTabMenu from './components/left/VerticalTabMenu';
import BasicInfo from './components/right/basic-info/BasicInfo';
import RulesPrizesEditor from './components/right/rules-prizes-editor/RulesPrizesEditor';

const MENU_ITEMS = ['Basic Information', 'Rules & Description', 'Rewards', 'Eligibility'];

const styles = makeStyles(() => ({
  wrapper: {
    padding: '60px 24px',
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  columnGap: {
    columnGap: 10,
  },
  launch: {
    marginTop: 16,
  },
  title: {
    margin: '40px 0px',
    fontWeight: 700,
  },
  hr: {
    width: 400,
    marginBottom: 40,
    border: '1px solid #F1F1F1',
  },
}));

function MenuComponent(props) {
  let Component;

  switch (props.activeTab) {
    case MENU_ITEMS[0]:
      Component = BasicInfo;
      break;
    case MENU_ITEMS[1]:
      Component = RulesPrizesEditor;
      break;
    default:
      // not good lol -> check default case
      Component = null;
  }

  return <Component {...props} />;
}

MenuComponent.propTypes = {
  activeTab: string.isRequired,
};

function EditPartnerContest() {
  const { id } = useParams();
  const history = useHistory();

  const { data, loading, refetch } = useQuery(GET_CONTEST_BY_ID, {
    variables: {
      id,
    },
  });

  const [activeTab, setActiveTab] = useState(MENU_ITEMS[0]);
  const classes = styles();

  function goToContest() {
    const link = `/contests/${id}/${convertToSlug(data.contestById.name)}`;
    history.push(link);
  }

  if (loading) {
    return <CircularCenterLoader />;
  }

  if (!cookieUserId || +cookieUserId !== +data.contestById.creator.id) {
    return <Redirect to="/not-found" />;
  }

  return (
    <Grid container className={classes.wrapper}>
      <Grid className={classes.centered} item xs={12}>
        <Box
          className={classes.columnGap}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h3" align="center" className={classes.title}>
            Contest{' '}
          </Typography>
          <IconButton className={classes.launch} onClick={goToContest} variant="text" color="primary">
            <LaunchIcon />
          </IconButton>
        </Box>
        <hr className={classes.hr} />
      </Grid>
      <Grid item xs={4}>
        <VerticalTabMenu menuItems={MENU_ITEMS} activeTab={activeTab} setActiveTab={setActiveTab} />
      </Grid>
      <Grid item xs={6}>
        <MenuComponent activeTab={activeTab} contest={data.contestById} refetch={refetch} />
      </Grid>
    </Grid>
  );
}

export default EditPartnerContest;
