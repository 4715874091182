import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
// done
// updated
export const CREATE_LIST = gql`
  mutation createList($list: NewUserList!) {
    createList(list: $list) {
      id
      name
    }
  }
`;

// done
// updated
export const DELETE_LIST = gql`
  mutation deleteList($list: RemoveList!) {
    deleteList(list: $list) {
      status
      description
    }
  }
`;

// done
// updated
export const ADD_PROJECTS_TO_LIST = gql`
  mutation addProjectsToList($projectLists: NewProjectsInList!) {
    addProjectsToList(projectLists: $projectLists) {
      id
      listId
    }
  }
`;

// done
// updated
export const REMOVE_PROJECTS_FROM_LIST = gql`
  mutation removeProjectsFromList($projectLists: NewProjectsInList!) {
    removeProjectsFromList(projectLists: $projectLists) {
      status
      description
    }
  }
`;

// done
// updated
export const ADD_PROJECT_TO_LISTS = gql`
  mutation addProjectToLists($projectLists: NewProjectInLists!) {
    addProjectToLists(projectLists: $projectLists) {
      id
    }
  }
`;
