import { Box } from '@material-ui/core';
import { object, func, bool, arrayOf, element } from 'prop-types';

import ContentEditCreate from './components/ContentEditCreate';
import ContentView from './components/ContentView';

const FeaturedHeaderContent = ({
  register,
  control,
  isView,
  project,
  projectTags,
  handleChangeTab,
  handleChangeProject,
  handleChangeProjectTags,
  renderSponsors,
  desktop,
}) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent={desktop ? 'center' : 'flex-start'}
    alignItems={desktop ? 'center' : 'flex-start'}
    minHeight={desktop ? 380 : 'inherit'}
    textAlign="center"
    pt={desktop ? 0 : 2}
  >
    {isView ? (
      <ContentView
        control={control}
        project={project}
        handleChangeTab={handleChangeTab}
        desktop={desktop}
        renderSponsors={renderSponsors}
      />
    ) : (
      <ContentEditCreate
        project={project}
        control={control}
        register={register}
        handleChangeProject={handleChangeProject}
        projectTags={projectTags}
        handleChangeProjectTags={handleChangeProjectTags}
        desktop={desktop}
      />
    )}
  </Box>
);

FeaturedHeaderContent.propTypes = {
  isView: bool.isRequired,
  desktop: bool.isRequired,
  project: object.isRequired,
  projectTags: arrayOf(object).isRequired,
  handleChangeTab: func.isRequired,
  handleChangeProject: func.isRequired,
  handleChangeProjectTags: func.isRequired,
  renderSponsors: element,
};

export default FeaturedHeaderContent;
