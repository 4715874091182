export default {
  text: {
    black: '#414042',
    grey: '#989898',
    lightContrast: '#161616',
    lightDefault: '#333333',
    lightLow: '#414141',
    lightSubtle: '#575757',
    lightNonEssential: '#989898',
  },
};
