import { Avatar, makeStyles } from '@material-ui/core';
import { arrayOf, object, number } from 'prop-types';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  avatar: {
    marginRight: '5px',
    width: '30px',
    height: '30px',
  },
  remainingCircle: {
    width: '30px',
    height: '30px',
    backgroundColor: '#EDAE49',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function CocoonAvatarGroup({ avatars, max }) {
  const classes = useStyles();
  const slicedAvatars = avatars.slice(0, max);
  const remaining = avatars.length - slicedAvatars.length;

  return (
    <div className={classes.wrapper}>
      {slicedAvatars.map((applicant) => (
        <Avatar key={applicant.id} src={applicant.avatarLink} className={classes.avatar} />
      ))}
      {remaining !== 0 && <div className={classes.remainingCircle}>+{remaining}</div>}
    </div>
  );
}

CocoonAvatarGroup.propTypes = {
  avatars: arrayOf(object).isRequired,
  max: number.isRequired,
};
