import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  flex: {
    display: 'flex',
  },
  currentTabTitle: {
    fontWeight: 700,
  },
  coverText: {
    marginTop: 35,
    color: ' #616161',
    marginBottom: 12,
  },
  editableFieldText: {
    fontWeight: 700,
  },
  editableFieldRow: {
    minHeight: 50,
    display: 'flex',
    justifyContent: 'center',
  },
  rightEditableColumn: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default useStyles;
