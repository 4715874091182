import { useMutation } from '@apollo/client';
import { Button, Typography, makeStyles } from '@material-ui/core';
import { bool, number } from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ADD_PROJECT_BOOKMARK, DELETE_PROJECT_BOOKMARK } from 'apollo/mutations/bookmark-mutation';
import { cookieUserId } from 'helpers/auth';
import { useSnackbar } from 'hooks';

const useStyles = makeStyles((theme) => ({
  buttonFollow: {
    height: '100%',
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    marginRight: theme.spacing(2),
    padding: theme.spacing(1, 2),
    textTransform: 'none',
    borderRadius: '6px',
    borderWidth: '1px',
    '& span': {
      color: theme.palette.common.white,
      [theme.breakpoints.down('sm')]: {
        color: '#575757',
        fontSize: 13,
      },
    },
    '&:hover': {
      borderWidth: '2px',
      '& *': {
        fontWeight: 'bold',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& svg': {
        fontSize: 18,
      },
      color: '#575757',
      borderColor: '#575757',
      padding: theme.spacing(0.5, 1),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      minWidth: 98,
    },
  },
  buttonFollowed: {
    height: '100%',
    color: '#575757',
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    marginRight: theme.spacing(2),
    padding: theme.spacing(1, 2),
    textTransform: 'none',
    borderRadius: '6px',
    borderWidth: '1px',
    '& span': {
      color: '#575757',
      [theme.breakpoints.down('sm')]: {
        color: theme.palette.common.white,
        fontSize: 13,
      },
    },
    '&:hover': {
      color: '#575757',
      backgroundColor: theme.palette.common.white,
      borderWidth: '2px',
      '& *': {
        fontWeight: 'bold',
      },
      [theme.breakpoints.down('sm')]: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        borderWidth: '1px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& svg': {
        fontSize: 18,
      },
      borderColor: '#575757',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(0.5, 1),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      minWidth: 98,
    },
  },
}));

const Follow = ({ id, isFollowed }) => {
  const classes = useStyles();

  const { openSnackbarSuccess, openSnackbarWarning } = useSnackbar();

  const [followed, setFollowed] = useState(isFollowed);

  const [addProjectBookmark] = useMutation(ADD_PROJECT_BOOKMARK);
  const [deleteProjectBookmark] = useMutation(DELETE_PROJECT_BOOKMARK);

  const { t } = useTranslation(['common', 'dialog']);

  useEffect(() => {
    setFollowed(isFollowed);
  }, [isFollowed]);

  const toggleFollow = async () => {
    if (cookieUserId) {
      const bookmarkVariables = { bookmark: { projectId: id } };

      if (followed) {
        const { data } = await deleteProjectBookmark({
          variables: bookmarkVariables,
        });

        if (data?.deleteProjectBookmark?.status === 'SUCCESS') {
          setFollowed(false);
          openSnackbarSuccess(t('dialog:openSnackbarSuccess.unfollowedProject'));
        }
      } else {
        const { data } = await addProjectBookmark({
          variables: bookmarkVariables,
        });

        if (data) {
          setFollowed(true);
          openSnackbarSuccess(t('dialog:openSnackbarSuccess.followedProject'));
        }
      }
    } else {
      openSnackbarWarning(t('dialog:openSnackbarWarning.reminderLoginFeature'));
    }
  };

  return (
    <Button
      size="small"
      variant="outlined"
      className={followed ? classes.buttonFollowed : classes.buttonFollow}
      onClick={toggleFollow}
    >
      <Typography variant="button" color="inherit">
        {followed ? t('common:button.followed') : t('common:button.follow')}
      </Typography>
    </Button>
  );
};

Follow.propTypes = {
  isFollowed: bool.isRequired,
  id: number.isRequired,
};

export default Follow;
