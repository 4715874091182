import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
// done
// updated
export const GET_ANNOUNCEMENT_ACTIVE = gql`
  query activeAnnouncement {
    activeAnnouncement {
      text
    }
  }
`;
