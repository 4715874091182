import { useQuery } from '@apollo/client';
import {
  CircularProgress,
  Box,
  Chip,
  Grid,
  Typography,
  Divider,
  Link,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import { object, arrayOf, number, func, bool } from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import { GET_UPDATE_ATTACHMENTS } from 'apollo/queries/file-query';
import { GET_UPDATE_TAGS } from 'apollo/queries/update-tag-query';
import { CocoonEditor } from 'components';
import { formatDate_ll } from 'helpers';
import { convertToSlug } from 'helpers/common';
import { getQueryStringParams } from 'helpers/url';

import TabPanelAttachments from '../../../../../../components/attachments/TabPanelAttachments';

import ButtonCopyLink from './ButtonCopyLink';

const Tag = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(1, 0.5),
    marginRight: theme.spacing(0.5),
    borderRadius: '6px',
  },
}))((props) => <Chip color="secondary" size="small" {...props} />);

const useStyles = makeStyles((theme) => ({
  divider: {
    marginBottom: theme.spacing(1),
  },
  block: {
    margin: theme.spacing(2, 0),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1, 0),
      '& > div > span': {
        fontSize: 13,
      },
    },
  },
  link: {
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& button': {
      padding: 0,
    },
  },
}));

const LogDetails = ({ log, logRef, logIdsSelected, handleClickLog, desktop }) => {
  const classes = useStyles();
  const { hash } = useLocation();
  const history = useHistory();

  const {
    loading: loadingTags,
    data: dataTags,
    error: errorTags,
  } = useQuery(GET_UPDATE_TAGS, {
    variables: { updateId: log.id },
  });

  const { t } = useTranslation();

  const {
    loading: loadingAttachments,
    data: dataAttachments,
    error: errorAttachments,
  } = useQuery(GET_UPDATE_ATTACHMENTS, { variables: { updateId: log.id } });

  const renderLoading = (height = 20) => (
    <Box height={height} width="100%" display="flex" alignItems="center" justifyContent="center">
      <CircularProgress fontSize="small" color="inherit" />
    </Box>
  );

  const renderError = (error) =>
    error && (
      <Alert variant="outlined" severity="error">
        {error.message}
      </Alert>
    );

  const isSelectMode = !_.isEmpty(logIdsSelected);
  const isSelected = logIdsSelected.includes(+log.id);
  const display = !isSelectMode || (isSelectMode && isSelected) ? 'block' : 'none';

  const handleClickLogName = (e) => {
    if (e) e.preventDefault();

    const slugLogName = `${convertToSlug(log.name)}-${+log.id}`;

    if (getQueryStringParams('name', hash, '#') !== slugLogName) {
      history.push({
        pathname: history.location.pathname,
        hash: `#tab=logs&name=${slugLogName}`,
        state: { clicked: true },
      });
    }

    handleClickLog(+log.id);
  };

  useEffect(() => {
    if (getQueryStringParams('name', hash, '#') === `${convertToSlug(log.name)}-${+log.id}`) {
      handleClickLog(+log.id);
    }
  }, [hash]);

  return (
    <Box border={1} borderColor="text.secondary" px={desktop ? 2 : 1} mb={2} display={display} ref={logRef}>
      <div className={classes.block}>
        <Box mb={1} component={Typography} variant="h6" className={classes.link}>
          <Link href="#" onClick={handleClickLogName}>
            {log.name}
          </Link>
          <ButtonCopyLink handleClickLogName={handleClickLogName} />
        </Box>
        <Box>
          {loadingTags
            ? renderLoading()
            : dataTags && _.map(dataTags.tagsByUpdate, (tag) => <Tag key={tag.id} label={tag.name} />)}
          {renderError(errorTags)}
        </Box>
      </div>
      <Divider className={classes.divider} />
      <Grid container direction="row" justify="space-between" alignItems="center" className={classes.block}>
        <Box component={Grid} item xs={desktop ? 4 : 12}>
          <Box component="span" color="text.secondary">
            {t('log.publishedDate')}
          </Box>
          <Box component="span" fontWeight="fontWeightBold" ml={1}>
            {formatDate_ll(log.publishedDate)}
          </Box>
        </Box>
        <Box component={Grid} item xs={desktop ? 4 : 6} textAlign={desktop ? 'center' : 'left'}>
          <Box component="span" color="text.secondary">
            {t('log.completedTasks')}
          </Box>
          <Box component="span" fontWeight="fontWeightBold" ml={1}>
            {log.tasksCompleted || 0}
          </Box>
        </Box>
        <Box component={Grid} item xs={desktop ? 4 : 6} textAlign={desktop ? 'right' : 'left'}>
          <Box component="span" color="text.secondary">
            {t('log.overallContribution')}
          </Box>
          <Box component="span" fontWeight="fontWeightBold" ml={1} color="success.dark">
            {log.contributions || 0}%
          </Box>
        </Box>
      </Grid>
      <Divider className={classes.divider} />
      <div className={classes.block}>
        <Typography variant="caption" color="textSecondary" gutterBottom>
          {t('log.updateDetails')}
        </Typography>
        <Box mt={2}>
          <CocoonEditor data={log.description} />
        </Box>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.block}>
        {loadingAttachments
          ? renderLoading()
          : dataAttachments && (
              <TabPanelAttachments desktop={desktop} attachments={dataAttachments.attachmentsByUpdateId} />
            )}
        {renderError(errorAttachments)}
      </div>
    </Box>
  );
};

LogDetails.propTypes = {
  log: object.isRequired,
  logIdsSelected: arrayOf(number),
  logRef: object.isRequired,
  handleClickLog: func.isRequired,
  desktop: bool.isRequired,
};

LogDetails.defaultProps = {
  logIdsSelected: [],
};

export default LogDetails;
