import { func } from 'prop-types';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { getValue, KEYS } from 'helpers/localStorage';
import { getCurrentUser } from 'reducers/authReducer';

import InputNewComment from './InputNewComment';

const InputNewCommentContainer = ({ submitComment, handleCancel }) => {
  const currentUser = useSelector(getCurrentUser);

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = ({ comment }) => {
    submitComment(currentUser, comment.replace(/(\n)/gm, '\n\n'));
  };

  return (
    <InputNewComment
      register={register}
      handleSubmit={handleSubmit(onSubmit)}
      handleCancel={handleCancel}
      avatar={currentUser.avatarLink || getValue(KEYS.avatarLink)}
    />
  );
};

InputNewCommentContainer.propTypes = {
  handleCancel: func.isRequired,
  submitComment: func.isRequired,
};

export default InputNewCommentContainer;
