import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  projectsWrapper: {
    minHeight: 670,
  },
  projectItem: {
    position: 'relative',
    height: 215,
    width: '100%',
  },
  textField: {
    background: '#ffffff',
    '& .MuiOutlinedInput-root': {
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.1)',
      borderRadius: '6px',
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
  },
  text: {
    ...theme.typography.body1,
    display: 'block',
    marginTop: 15,
    marginBottom: 15,
    color: '#000',
  },
  projectsGalleryCheckBox: {
    position: 'absolute',
    zIndex: 1301,
    top: 10,
    right: 5,
    color: '#ffffff',
  },
  img: {
    '& > div': {
      backgroundColor: 'rgba(0,0,0,0.1)',
      borderRadius: 6,
    },
  },
}));

export default useStyles;
