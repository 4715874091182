import { Typography } from '@material-ui/core';
import { Info as InfoIcon } from '@material-ui/icons';
import { bool, func, object } from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import BasicInfo from './basic-info/BasicInfo';
import useStyles from './editMoreStyle';
import JobAndBio from './job-and-bio/JobAndBio';
import SocialMedias from './social-medias/SocialMedias';
import WorksExperiencesContests from './works-experiences-contests/WorksExperiencesContests';

const EditMore = ({ watch, setValue, control, register, editProfile, setEditProfile, editMore, desktop }) => {
  const classes = useStyles();
  const { t } = useTranslation('dialog');

  useEffect(() => {
    let tags = [];
    if (editProfile.fieldsOfWork) {
      tags = editProfile.fieldsOfWork.map((str, index) => {
        if (typeof str === 'object') {
          return {
            id: +index,
            fieldName: str?.fieldName,
          };
        }
        return {
          id: +index,
          fieldName: str,
        };
      });
    }
    setEditProfile((prevState) => ({
      ...prevState,
      fieldsOfWork: tags,
    }));
  }, []);

  const handleSetFieldsOfWork = (newFields) => {
    setEditProfile((prevState) => ({
      ...prevState,
      fieldsOfWork: newFields,
    }));
  };

  return (
    <>
      {editMore && (
        <BasicInfo
          control={control}
          register={register}
          setValue={setValue}
          watch={watch}
          editProfile={editProfile}
          desktop={desktop}
        />
      )}
      <JobAndBio register={register} />
      {editMore && (
        <>
          <SocialMedias register={register} desktop={desktop} />
          <WorksExperiencesContests
            register={register}
            editProfile={editProfile}
            handleSetFieldsOfWork={handleSetFieldsOfWork}
            desktop={desktop}
          />
        </>
      )}
    </>
  );
};

EditMore.propTypes = {
  editProfile: object.isRequired,
  setEditProfile: func.isRequired,
  editMore: bool.isRequired,
  desktop: bool.isRequired,
};

export default EditMore;
