import { useLazyQuery } from '@apollo/client';
import _ from 'lodash';
import { object, number } from 'prop-types';
import { useState, useEffect } from 'react';

import { cookieUserId } from 'helpers/auth';

import UsersProjects from './UsersProjects';

const LIMIT = 5;

const UsersProjectsContainer = ({ query, ...props }) => {
  const [page, setPage] = useState(1);
  const [isPaging, setIsPaging] = useState(false);
  const [projects, setProjects] = useState(null);
  const [canFetchMore, setCanFetchMore] = useState(true);

  const variables = {
    userId: cookieUserId,
    page,
    isArchived: false,
    limit: 'FIVE',
  };

  const [getProjects, { data, loading, error }] = useLazyQuery(query, {
    variables,
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (data.projects.length < LIMIT) {
        setCanFetchMore(false);
      }
      setProjects((projects) => {
        if (projects === null) {
          return [...data.projects];
        }
        setIsPaging(false);
        return [...projects, ...data.projects];
      });
    },
  });

  useEffect(() => {
    getProjects();
  }, [page]);

  const handleFetchMore = () => {
    if (canFetchMore && !isPaging) {
      setIsPaging(true);
      setPage((page) => {
        if (page === variables.page) {
          return page + 1;
        } else return page;
      });
    }
  };

  const debounceHandleFetchMore = _.debounce(handleFetchMore, 300);

  return (
    <UsersProjects
      projects={projects}
      loading={loading}
      error={error}
      canFetchMore={canFetchMore}
      handleFetchMore={debounceHandleFetchMore}
      {...props}
    />
  );
};

UsersProjectsContainer.propTypes = {
  query: object,
  index: number.isRequired,
  tab: number.isRequired,
};

UsersProjectsContainer.defaultProps = {
  query: undefined,
};

const UsersProjectsContainerGuard = (props) => {
  const { query } = props;

  if (!query) return <UsersProjects projects={[]} loading={false} error={undefined} {...props} />;

  return <UsersProjectsContainer {...props} />;
};

UsersProjectsContainerGuard.propTypes = {
  query: object,
};

UsersProjectsContainerGuard.defaultProps = {
  query: undefined,
};

export default UsersProjectsContainerGuard;
