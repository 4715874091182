import { gql } from '@apollo/client';

const FRAGMENTS = gql`
  fragment fileFragment on ProjectFile {
    id
    name
    assetLink
    assetType
  }
`;

// done
// updated
export const GET_UPDATE_ATTACHMENTS = gql`
  query attachmentsByUpdateId($updateId: ID!, $page: Int, $limit: PaginationLimit) {
    attachmentsByUpdateId(updateId: $updateId, page: $page, limit: $limit) {
      ...fileFragment
    }
  }
  ${FRAGMENTS}
`;

// done
// updated
export const GET_PROJECT_ATTACHMENTS = gql`
  query attachmentsByProjectId($projectId: ID!, $isPublished: Boolean, $page: Int, $limit: PaginationLimit) {
    attachments: attachmentsByProjectId(projectId: $projectId, isPublished: $isPublished, page: $page, limit: $limit) {
      ...fileFragment
    }
  }
  ${FRAGMENTS}
`;
