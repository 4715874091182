import { useQuery } from '@apollo/client';
import { CssBaseline } from '@material-ui/core';
import { CURRENT_USER_INFO } from 'apollo/queries/user-query';
import { arrayOf, object } from 'prop-types';
import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import {
  AppLayout,
  BackToTopAnchor,
  CocoonBackTop,
  CocoonBackdrop,
  CocoonDialog,
  CocoonHelmet,
  CocoonLightbox,
} from 'components';

const App = ({ routes }) => {
  const { data, loading } = useQuery(CURRENT_USER_INFO);

  useEffect(() => {
    if (!loading && process.env.TENANT !== 'dev') {
      if (data.currentUserInfo.email) {
        heap.identify(`${process.env.TENANT}-${data.currentUserInfo.email}`);
      }
      heap.addUserProperties({ tenant: process.env.TENANT });
    }
  }, [loading]);

  return (
    !loading && (
      <AppLayout>
        <CocoonHelmet />
        <CssBaseline />
        <BackToTopAnchor />
        <Switch>
          {routes.map(({ path, exact, component }, key) => (
            <Route key={key} exact={exact} path={path} component={component} />
          ))}
        </Switch>
        <CocoonBackTop />
        <CocoonBackdrop />
        <CocoonDialog />
        <CocoonLightbox />
      </AppLayout>
    )
  );
};

App.propTypes = {
  routes: arrayOf(object).isRequired,
};

export default App;
