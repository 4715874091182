import _ from 'lodash';
import { string } from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';

import {
  GET_PROJECTS,
  GET_PROJECTS_FEATURED,
  GET_PROJECTS_DISCOVER,
  GET_PROJECTS_BY_USER_ID,
} from 'apollo/queries/project-query';
import { CocoonHelmet, backToAnchor } from 'components';
import { PROJECT_TYPE } from 'helpers/constants';
import { META_DATA } from 'helpers/metaData';

import TABS from './helpers/constants';
import Projects from './Projects';

const { ALL, FEAT_TYPE, DISC_TYPE } = PROJECT_TYPE;

const QUERIES_USERS = {
  [TABS.PROJECT_PUBLIC]: GET_PROJECTS_BY_USER_ID,
  [TABS.PROJECT_PRIVATE]: undefined,
  [TABS.JOIN_REQUEST]: undefined,
};

const QUERIES_ALL = {
  [ALL]: GET_PROJECTS,
  [FEAT_TYPE]: GET_PROJECTS_FEATURED,
  [DISC_TYPE]: GET_PROJECTS_DISCOVER,
};

const ProjectsContainerGuard = () => {
  const location = useLocation();

  if (location.pathname === '/users/me/projects') return <ProjectsContainer />;

  const projectType = new URLSearchParams(location.search).get('type');

  const isValidProjectType = ['featured', 'discover', 'all'].includes(projectType);

  if (projectType === null || !isValidProjectType) {
    return <Redirect to="/projects?type=all" />;
  }

  return <ProjectsContainer projectType={projectType} />;
};

const ProjectsContainer = ({ projectType }) => {
  // Users projects
  const [tab, setTab] = useState(0);
  const { t } = useTranslation();
  let handleChangeTab = (event, newTab) => {
    setTab(newTab);
  };

  const { title, description, image, url } = META_DATA.PROJECTS;

  let queries = QUERIES_USERS;
  let label = t('projects.myProjects');

  // All projects
  if (projectType) {
    queries = QUERIES_ALL;
    label =
      projectType === 'all'
        ? t('projects.myProjects')
        : `${t('projects.myProjects')} ${_.startCase(_.toLower(projectType))}`;

    handleChangeTab = undefined;
  }

  useEffect(() => {
    backToAnchor(document);
  }, []);

  return (
    <>
      <CocoonHelmet title={title} description={description} image={image} url={url} />
      <Projects
        label={label}
        queries={queries}
        tab={handleChangeTab ? tab : undefined}
        projectType={_.toUpper(projectType)}
        handleChangeTab={handleChangeTab}
      />
    </>
  );
};

ProjectsContainer.propTypes = {
  projectType: string,
};

ProjectsContainer.defaultProps = {
  projectType: null,
};

export default ProjectsContainerGuard;
