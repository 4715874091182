import { useQuery } from '@apollo/client';
import { Box, Container, Grid, useMediaQuery, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import { object } from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { GET_LATEST_COMMENTS_OF_FORUM } from 'apollo/queries/forum-query';
import { GET_LATEST_UPDATES_OF_PROJECTS, GET_UPDATES_BY_CATEGORY_ID } from 'apollo/queries/log-query';
import { GET_PROJECT_CATEGORIES } from 'apollo/queries/project-category-query';
import { GET_PROJECTS_BY_CATEGORY, GET_PROJECTS_DISCOVER, GET_PROJECTS_FEATURED } from 'apollo/queries/project-query';
import { CircularCenterLoader, backToAnchor } from 'components';
import { convertToSlug } from 'helpers/common';
import { PROJECT_TYPE } from 'helpers/constants';
import { META_DATA } from 'helpers/metaData';
import { closeBackdrop, getBackdropOpen } from 'reducers/backdropReducer';

import Categories from './components/categories/Categories';
import Hero from './components/hero/Hero';
import ContestsCarousel from './components/contests-carousel/ContestsCarousel';
import HomeProjects from './components/projects';
const { FEAT_TYPE, DISC_TYPE, WANT_TYPE, LOGS_TYPE, FORUM_TYPE } = PROJECT_TYPE;

const useStyles = makeStyles((theme) => ({
  want: {
    height: '100%',
  },
  container: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  marginTop: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  paddingY: {
    padding: theme.spacing(2, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 0),
    },
  },
  paddingYRight: {
    paddingTop: 27,
    paddingBottom: 16,
    backgroundColor: 'rgba(208, 201, 201, 0.12)',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      padding: theme.spacing(2, 0, 1),
    },
  },
}));

const Home = ({ history }) => {
  const classes = useStyles();
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const { title, description, url } = META_DATA.HOME;

  const { id: categoryId } = useParams();

  const backdropOpen = useSelector(getBackdropOpen);

  const dispatch = useDispatch();
  const handleCloseBackdrop = () => dispatch(closeBackdrop());
  const { t } = useTranslation();

  useEffect(() => {
    backToAnchor(document);
    if (backdropOpen) {
      handleCloseBackdrop();
    }
  }, []);

  const [selectedCategory, setSelectedCategory] = useState(categoryId ? +categoryId : -1);

  const { data, loading } = useQuery(GET_PROJECT_CATEGORIES, {
    variables: { page: 1 },
    onCompleted: () => {
      if (categoryId && !_.find(data.projectCategories, ({ id }) => +id === +categoryId)) {
        history.push('/not-found');
      }
    },
  });

  if (loading) return <CircularCenterLoader />;

  const handleSelectCategory = (key) => {
    if (key.target) {
      setSelectedCategory(+key.target.value);
      if (+key.target.value !== -1) {
        const categoriesById = _.find(data.projectCategories, ({ id }) => +id === +key.target.value);
        history.push(
          categoriesById ? `/category/${key.target.value}/${convertToSlug(categoriesById.name)}` : `/not-found`,
        );
      } else {
        history.push('/');
      }
    } else {
      setSelectedCategory(key);
      if (+key !== -1) {
        const categoriesById = _.find(data.projectCategories, ({ id }) => +id === +key);
        history.push(categoriesById ? `/category/${key}/${convertToSlug(categoriesById.name)}` : `/not-found`);
      } else {
        history.push('/');
      }
    }
  };

  const categoryChosen = _.find(data.projectCategories, ({ id }) => +id === +selectedCategory);

  return (
    <>
      {/* <CocoonHelmet title={title} description={description} image={image} url={url} /> */}
      <MetaTags>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name="description" content={description} />
        <meta name="og:description" content={description} />
        <meta property="og:image" content={url} />
      </MetaTags>
      <Hero categoryChosen={categoryChosen} />
      {/* {!cookieUserId && desktop && <BannerBots />} */}

      <Box component={Container} mb={desktop ? 6 : 2}>
        <ContestsCarousel />
        <Categories
          categories={data.projectCategories}
          selectedCategory={selectedCategory}
          handleSelectCategory={handleSelectCategory}
          desktop={desktop}
        />
        {/* <HomeNotification desktop={desktop} /> */}
        {!categoryChosen ? (
          <Grid container>
            <Grid item xs={desktop ? 8 : 12} container>
              <Grid item xs={12} className={classes.paddingY}>
                <HomeProjects
                  query={GET_PROJECTS_FEATURED}
                  variables={{ page: 1, limit: 'EIGHT' }}
                  label={t('home.projectFeatured')}
                  type={FEAT_TYPE}
                  desktop={desktop}
                />
              </Grid>
              <Grid item xs={12} className={clsx(classes.marginTop, classes.paddingY)}>
                <HomeProjects
                  query={GET_PROJECTS_DISCOVER}
                  variables={{ page: 1, limit: 'SIX', isArchived: false }}
                  label={t('home.projectDiscover')}
                  type={DISC_TYPE}
                  desktop={desktop}
                />
              </Grid>
            </Grid>
            <Grid item xs={desktop ? 4 : 12}>
              <Grid item xs={12} className={classes.paddingYRight}>
                <HomeProjects
                  query={GET_LATEST_UPDATES_OF_PROJECTS}
                  variables={{ page: 1, limit: 'THREE' }}
                  label={t('home.latestProjectUpdate')}
                  type={WANT_TYPE}
                  desktop={desktop}
                />
              </Grid>
              <Grid item xs={12} className={clsx(classes.marginTop, classes.paddingYRight)}>
                <HomeProjects
                  query={GET_LATEST_COMMENTS_OF_FORUM}
                  variables={{ page: 1, limit: 'SEVEN' }}
                  label={t('home.latestCommentForum')}
                  type={FORUM_TYPE}
                  desktop={desktop}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={desktop ? 8 : 12} container>
              <Grid item xs={12} className={classes.paddingY}>
                <HomeProjects
                  query={GET_PROJECTS_BY_CATEGORY}
                  variables={{
                    projectCategoryId: selectedCategory,
                    page: 1,
                    isArchived: false,
                    limit: 'SIX',
                  }}
                  label={categoryChosen.name}
                  desktop={desktop}
                  type={DISC_TYPE}
                />
              </Grid>
            </Grid>
            <Grid item xs={desktop ? 4 : 12}>
              <Grid item xs={12} className={classes.paddingYRight}>
                <HomeProjects
                  label={t('home.newLogs')}
                  query={GET_UPDATES_BY_CATEGORY_ID}
                  variables={{
                    projectCategoryId: selectedCategory,
                  }}
                  type={LOGS_TYPE}
                  desktop={desktop}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

Home.propTypes = {
  history: object.isRequired,
};

export default Home;
