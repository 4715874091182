import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache, HttpLink, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { Suspense, useEffect, useState } from 'react';
import { render, hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Auth0Provider } from '@auth0/auth0-react';

import 'config/common/i18n';
import 'config/common/sentry';
import configureStore from 'config/redux/store';
import log from 'helpers/log';
import routes, { noThemeRoutes } from 'helpers/routes';
import { dialogsVar } from 'reducers/testAC3/cache';

import App from './app';
import NoThemeApp from './no-theme-app';

const store = configureStore();

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(() => ({ fetchOptions: { credentials: 'include' } }));
  return forward(operation);
});

const client = new ApolloClient({
  link: from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          log.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
        );
      if (networkError) log.error(`[Network error]: ${networkError}`);
    }),
    authMiddleware,
    new HttpLink({ uri: process.env.GRAPHQL_ENDPOINT || 'http://localhost:5000/bff/backend/graphql' }),
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          dialogData: {
            read() {
              return dialogsVar();
            },
          },
        },
      },
    },
  }),
});

const CocoonApp = () => {
  return (
    <Suspense fallback="Loading...">
      <Provider store={store.store}>
        <PersistGate loading={null} persistor={store.persistor}>
          <ApolloProvider client={client}>
            <Auth0Provider
              domain={process.env.AUTH0_DOMAIN}
              clientId={process.env.AUTH0_CLIENT_ID}
              authorizationParams={{
                redirect_uri: process.env.AUTH0_REDIRECT_URI,
              }}
            >
              <Router basename="/" forceRefresh={false}>
                <Route exact path={routes.map((route) => route.path)}>
                  <App routes={routes} />
                </Route>
                <Route path={noThemeRoutes.map((route) => route.path)}>
                  <NoThemeApp routes={noThemeRoutes} />
                </Route>
              </Router>
            </Auth0Provider>
          </ApolloProvider>
        </PersistGate>
      </Provider>
    </Suspense>
  );
};

const rootElement = document.getElementById('cocoon-app');

if (rootElement.hasChildNodes()) {
  hydrate(<CocoonApp />, rootElement);
} else {
  render(<CocoonApp />, rootElement);
}

// ReactDOM.render(
// ,
//   document.getElementById('cocoon-app'),
// );
