import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  verticalList: {
    flexDirection: 'column',
    padding: '0px 50px',
  },
  item: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  itemText: {
    fontWeight: 700,
  },
  selectedItem: {
    borderRadius: 12,
  },
}));

export default useStyles;
