import { gql } from '@apollo/client';

// done
// updated
// eslint-disable-next-line import/prefer-default-export
export const GET_USER = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      username
      email
      firstName
      lastName
      gender
      dob
      description
      avatarLink
      backgroundPictureUrl
      primaryJob
      location
      profileViewsCount
      fullBio
      fieldsOfWork
      certified
      roleId
      experiences {
        id
        role
        company
        duration
        description
        startDate
        endDate
      }
      contests {
        id
        name
        reward
        year
        description
      }
      linkedin
      github
      facebook
      twitter
      country {
        id
        iso
        name
      }
      state {
        id
        iso
        name
      }
    }
  }
`;

// done
// updated
export const GET_BACKGROUND_PICTURE_PRESIGNED_URL = gql`
  query backgroundPicturePresignedUrl($fileName: String!, $contentType: String!) {
    backgroundPicturePresignedUrl(fileName: $fileName, contentType: $contentType) {
      url
      key
    }
  }
`;

// done
// updated
export const GET_AVATAR_PRESIGNED_URL = gql`
  query avatarPresignedUrl($fileName: String!, $contentType: String!) {
    avatarPresignedUrl(fileName: $fileName, contentType: $contentType) {
      url
      key
    }
  }
`;

// done
// updated
export const GET_PARTNERS = gql`
  query partners($name: String, $page: Int, $limit: PaginationLimit) {
    partners(name: $name, page: $page, limit: $limit) {
      id
      partnerName
      email
    }
  }
`;

// done
// updated
export const GET_USERS_BY_USERNAME_EMAIL = gql`
  query usersByUsernameEmail($page: Int, $limit: PaginationLimit, $searchTerm: String) {
    usersByUsernameEmail(page: $page, limit: $limit, searchTerm: $searchTerm) {
      id
      email
    }
  }
`;

export const CURRENT_USER_INFO = gql`
  query currentUserInfo {
    currentUserInfo {
      email
    }
  }
`;
