import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      minWidth: 720,
      [theme.breakpoints.down('sm')]: {
        minWidth: 340,
        maxHeight: 540,
      },
    },
  },
  header: {
    color: '#414141',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  button: {
    borderColor: theme.palette.text.secondary,
    padding: theme.spacing(0.25, 1.5),
    marginLeft: theme.spacing(1),
    textTransform: 'none',
    borderRadius: '6px',
    borderWidth: '1px',
  },
}));

export default useStyles;
