import logoH from '@images/logo/logo-h.svg';

export const getDisplayName = (user) => {
  const { firstName, lastName, username } = user;
  let name = '';
  if (firstName === null && lastName === null) {
    name = username;
  } else {
    name += firstName !== null ? firstName : '';
    name += lastName !== null ? ` ${lastName}` : '';
  }
  return name;
};

export const hex2rgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

export const convertToSlug = (str) =>
  str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-');

export const getLightBoxImagesFromFiles = (files) => {
  const images = files.filter((item) => item.assetType.indexOf('image') === 0);

  const lightboxImages = [];

  images.map((image) => lightboxImages.push({ src: image.assetLink, caption: image.name }));

  return lightboxImages;
};

export const getImageList = (files) => {
  const images = files.filter((item) => item.assetType.indexOf('image') === 0);

  const imageList = [];

  images.map((image) => imageList.push(image));

  return imageList;
};

export const getOtherFiles = (files) => {
  const otherFiles = files.filter((item) => item.assetType.indexOf('image') < 0);

  const newFiles = [];

  otherFiles.map((file) => newFiles.push(file));

  return newFiles;
};

export const getImg = (img) => {
  return img || logoH;
};

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  Number.isNaN(value) ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0);
