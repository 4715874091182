import { useMutation } from '@apollo/client';
import { Typography, Grid } from '@material-ui/core';
import { string, shape, bool, func } from 'prop-types';
import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

import { UPDATE_BASIC_CONTEST_INFO, UPDATE_CONTEST_BACKGROUND_PICTURE } from 'apollo/mutations/contest-mutation';
import { GET_CONTEST_BACKGROUND_PICTURE_PRESIGNED_URL } from 'apollo/queries/contest-query';
import { CocoonHelmet } from 'components';
import { DIALOG } from 'helpers/constants';
import { getFileLink } from 'helpers/file';
import { META_DATA } from 'helpers/metaData';
import { useSnackbar } from 'hooks';
import { handleOpenDialogAC3 } from 'reducers/testAC3/cache';

import useStyle from './basicInfoStyle';
import ImageDrop from './components/image-drop/ImageDrop';
import Members from './components/members/Members';
import Sponsors from './components/sponsors/Sponsors';
import Switch from './components/switch/Switch';
import Tags from './components/tags/Tags';
import TextInput from './components/text-input/TextInput';

const NOT_APPLICABLE = 'N/A';

function BasicInfo({ contest, activeTab, refetch }) {
  const [isUpdating, setIsUpdating] = useState(false);
  const classes = useStyle();

  const { title, description: pageDescription, image, url } = META_DATA.EDIT_CONTEST_PARTNER;
  const { id, name, tags, description, creator, isArchived, isFeatured, sponsors, members, fullAssetLink } = contest;

  const EDITABLE_FIELDS = [
    {
      title: 'Contest Name',
      name: 'name',
      Component: TextInput,
      otherProps: {},
    },
    {
      title: 'Contest Description',
      name: 'description',
      Component: TextInput,
      otherProps: {},
    },
    {
      title: 'Tags',
      name: 'displaySponsor',
      Component: Tags,
      isCustom: true,
      otherProps: {
        tags,
      },
    },
    // please read implementation of Switch
    {
      title: 'Contest Status',
      name: 'isArchived',
      Component: Switch,
      otherProps: {
        activeText: 'Archived',
        inactiveText: 'Active',
        isInverted: true,
      },
    },
    {
      title: 'Sponsors',
      name: 'sponsors',
      Component: Sponsors,
      isCustom: true,
      otherProps: {
        sponsors,
      },
    },
    {
      title: 'Members',
      name: 'members',
      Component: Members,
      isCustom: true,
      otherProps: {
        members,
      },
    },
  ];

  const defaultValues = {
    name: name || NOT_APPLICABLE,
    createdBy: creator?.email || NOT_APPLICABLE,
    description: description || NOT_APPLICABLE,
    isArchived: isArchived || false,
    isFeatured: isFeatured || false,
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm({
    defaultValues,
  });

  const { openSnackbarSuccess, openSnackbarError } = useSnackbar();

  const [updateContest] = useMutation(UPDATE_BASIC_CONTEST_INFO);

  const handleUpdateContest = async (values) => {
    setIsUpdating(true);

    const input = {
      id: contest.id,
      description: values.description,
      isArchived: values.isArchived,
    };

    if (values.name !== contest.name) input.name = values.name;

    try {
      const { data } = await updateContest({
        variables: { contest: input },
      });
      if (data.updateContestBasicInfo.description === 'Contest name already exists') {
        return setError('name', {
          type: 'manual',
          message: 'Contest name is already taken',
        });
      }
      if (data) {
        openSnackbarSuccess('Contest updated!');
        setIsUpdating(false);
        return refetch();
      }
    } catch (err) {
      openSnackbarError('Something went wrong');
      setIsUpdating(false);
      throw new Error('Could not save contest', err.message);
    }
  };

  const handleProfileCoverChange = async (files) => {
    const file = files[0];

    if (file) {
      const options = {
        isFromContest: true,
        contestId: id,
        getVariable: (uploadImage) => {
          return new Promise((resolve) => {
            resolve({
              contest: {
                id,
                backgroundPictureUrl: getFileLink(uploadImage, fullAssetLink),
              },
            });
          });
        },
        presignedUrl: 'contestBackgroundPicturePresignedUrl',
        mutation: UPDATE_CONTEST_BACKGROUND_PICTURE,
        query: GET_CONTEST_BACKGROUND_PICTURE_PRESIGNED_URL,
        file,
        refetchProfile: () => refetch(),
        aspect: 16 / 9,
        success: 'Background picture updated successfully!',
      };
      handleOpenDialogAC3(DIALOG.CROP_IMAGE, options);
    }
  };

  const onReset = (fieldName) => {
    const clone = { ...defaultValues, [fieldName]: contest[fieldName] || NOT_APPLICABLE };
    reset({ ...clone });
  };

  return (
    <>
      <CocoonHelmet title={title} description={pageDescription} image={image} url={url} />
      <Grid container direction="column">
        <Typography className={classes.currentTabTitle} variant="h5">
          {activeTab}
        </Typography>
        <Typography className={classes.coverText} variant="h6">
          COVER
        </Typography>
        <Grid item xs={12}>
          <ImageDrop onChange={handleProfileCoverChange} />
        </Grid>
        <Typography className={classes.coverText} variant="h6">
          GENERAL
        </Typography>
        {EDITABLE_FIELDS.map(({ title, name, Component, otherProps, isCustom }) => (
          <Grid key={title} className={classes.editableFieldRow} item xs={12}>
            <Grid container>
              <Grid item xs={3} className={classes.flex}>
                <Typography variant="h6">{title}</Typography>
              </Grid>
              <Grid item xs={9} classes={{ item: classes.rightEditableColumn }}>
                {!isCustom ? (
                  <Controller
                    name={name}
                    control={control}
                    render={(field) => {
                      // pass ref down will raise error for Material-UI, use inputRef instead
                      const { ref, ...otherFieldProps } = field;
                      // TODO: make a "selectProps" function for Component type

                      return (
                        <Component
                          {...otherFieldProps}
                          {...otherProps}
                          inputRef={ref}
                          disabled={isUpdating}
                          error={errors[name]}
                          save={() => handleSubmit(handleUpdateContest)()}
                          resetField={() => onReset(name)}
                        />
                      );
                    }}
                  />
                ) : (
                  <Component contestId={id} refetch={refetch} {...otherProps} />
                )}
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

BasicInfo.propTypes = {
  contest: shape({
    name: string,
    description: string,
    isArchived: bool.isRequired,
  }),
  activeTab: string.isRequired,
  refetch: func.isRequired,
};

export default BasicInfo;
