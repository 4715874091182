import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
// done
// updated
export const ACCEPT_PROJECT_INVITATION = gql`
  mutation acceptInvitation($invitation: AcceptInvitationInput!) {
    acceptInvitation(invitation: $invitation) {
      status
      description
    }
  }
`;

// done
// updated
export const REJECT_PROJECT_INVITATION = gql`
  mutation rejectInvitation($invitation: RejectInvitationInput!) {
    rejectInvitation(invitation: $invitation) {
      status
      description
    }
  }
`;
