export const arrangeLogs = (data) => {
  let logs = data.logs;
  if (logs.length > 1) {
    logs.sort(compare);
    data.logs = logs;
    return data;
  } else return data;
};

export const arrangeUpdateLogs = (logs) => {
  if (logs.length > 1) {
    logs.sort(compare);
  }
  return logs;
};

export const addLogsStatus = (logs) => {
  let newLogs = [...logs];

  if (logs.length > 0) {
    newLogs = newLogs.map((log) => {
      if (!('isNew' in log)) {
        return { ...log, id: +log.id, isNew: false };
      }
      return { ...log, id: +log.id };
    });
  }

  return newLogs;
};

function compare(a, b) {
  const aNumber = a.id;
  const bNumber = b.id;

  let comparison = 0;
  if (aNumber > bNumber) {
    comparison = -1;
  } else if (bNumber > aNumber) {
    comparison = 1;
  }
  return comparison;
}
