import { Box, CircularProgress, Container, Divider, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { bool, object } from 'prop-types';

import ForumLayout from './components/layout/ForumLayout';
import ForumTitle from './components/title/ForumTitle';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(3),
    paddingRight: '12px',
  },
  divider: {
    margin: theme.spacing(4, 0, 2),
    backgroundColor: '#ababab',
  },
}));

const Forum = ({ divider, loading, error, ...props }) => {
  const classes = useStyles();
  return (
    <Container fixed className={classes.container}>
      {loading ? (
        <Box height="100px" width="100%" display="flex" alignItems="center" justifyContent="center">
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <>
          <ForumTitle {...props} />
          {error ? (
            <Alert variant="outlined" severity="error">
              {error.message}
            </Alert>
          ) : (
            <>
              <ForumLayout {...props} />
              {divider && <Divider className={classes.divider} />}
            </>
          )}
        </>
      )}
    </Container>
  );
};

Forum.propTypes = {
  loading: bool.isRequired,
  error: object,
  divider: bool,
};

Forum.defaultProps = {
  divider: false,
  error: undefined,
};

export default Forum;
