import { Box, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { number, object, func, bool } from 'prop-types';
import { useWatch } from 'react-hook-form';

import { ProjectAuthAlt } from 'components/auth/UserAuthorization';
import { cookieUserId } from 'helpers/auth';

import { CRUD_STATUS } from '../../../../helpers/constants';

import ButtonsOwnerCreateEdit from './components/ButtonsOwnerCreateEdit';
import ButtonsOwnerView from './components/ButtonsOwnerView';
import ButtonsViewerView from './components/ButtonsViewerView';

const useStyles = makeStyles((theme) => ({
  buttons: {
    height: 36,
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(2, 1.5),
      height: 64,
    },
  },
}));

const FeaturedHeaderButtons = ({
  control,
  isView,
  loadingButton,
  uploadedFile,
  disabledSave,
  project,
  crudStatus,
  handleSubmit,
  handleChangeCrudStatus,
  handleUploadFile,
  handleCancelEditMode,
  refetchProject,
  desktop,
}) => {
  const name = useWatch({
    control,
    name: 'name',
    defaultValue: project.name || '',
  });
  const { assetLink, ownerId, members, id, isBookmarkedByUser, isLikedByUser } = project;
  const projectMember = _.find(members, ({ id }) => +id === +cookieUserId);
  const classes = useStyles();

  return (
    <Box className={classes.buttons}>
      {isView ? (
        !_.isEmpty(members) && (
          <>
            {ProjectAuthAlt(ownerId, projectMember)(
              <ButtonsOwnerView
                id={id}
                name={name}
                ownerId={ownerId}
                handleChangeEditMode={() => handleChangeCrudStatus(CRUD_STATUS.EDIT)}
                desktop={desktop}
              />,
              <ButtonsViewerView
                id={id}
                name={name}
                isLikedByUser={isLikedByUser}
                isBookmarkedByUser={isBookmarkedByUser || false}
                ownerId={ownerId}
                refetchProject={refetchProject}
                desktop={desktop}
              />,
            )}
          </>
        )
      ) : (
        <ButtonsOwnerCreateEdit
          isEditMode={crudStatus === CRUD_STATUS.EDIT}
          name={name}
          loadingButton={loadingButton}
          assetLink={assetLink}
          uploadedFile={uploadedFile}
          handleSubmit={handleSubmit}
          handleCancelEditMode={handleCancelEditMode}
          handleUploadFile={handleUploadFile}
          disabledSave={disabledSave}
          desktop={desktop}
        />
      )}
    </Box>
  );
};

FeaturedHeaderButtons.propTypes = {
  isView: bool.isRequired,
  desktop: bool.isRequired,
  loadingButton: bool.isRequired,
  crudStatus: number.isRequired,
  uploadedFile: object,
  project: object.isRequired,
  handleUploadFile: func.isRequired,
  handleChangeCrudStatus: func.isRequired,
  handleCancelEditMode: func.isRequired,
  disabledSave: bool,
  refetchProject: func.isRequired,
};

FeaturedHeaderButtons.defaultProps = {
  uploadedFile: null,
  disabledSave: undefined,
};

export default FeaturedHeaderButtons;
