import { Box } from '@material-ui/core';
import { number, func, bool } from 'prop-types';

import BriefTabPanel from './screens/brief/BriefTabPanel';
import ForumTabPanel from './screens/forum';
import LogsTabPanel from './screens/logs/logsTabPanelContainer';
// import GigsTabPanel from './screens/gigs/GigsTabPanel';
import TeamTabPanel from './screens/team/TeamTabPanel';
import TutorialTabPanel from './screens/tutorial';

const TabPanels = ({ tab, handleChangeProject, ...props }) => {
  const { desktop } = props;

  return (
    <Box my={desktop ? 4 : 0} mx={desktop ? 9 : 0}>
      <BriefTabPanel tab={tab} handleChangeProject={handleChangeProject} {...props} />
      <LogsTabPanel tab={tab} desktop={desktop} {...props} />
      {/* <GigsTabPanel tab={tab} {...props} /> */}
      <TeamTabPanel tab={tab} {...props} />
      <TutorialTabPanel tab={tab} {...props} />
      <ForumTabPanel tab={tab} {...props} />
    </Box>
  );
};

TabPanels.propTypes = {
  desktop: bool.isRequired,
  tab: number.isRequired,
  handleChangeProject: func.isRequired,
};

export default TabPanels;
