export default {
  content: {
    firstLine: 'Oops! You caught us.',
    secondLine: 'This feature is currently being developed.',
    thirdLine: 'Come back next time! We will notify you with our lastest updates',
  },
  dashboardDrawer: {
    label: 'Dashboard',
    subLabel: '',
    first: 'Tree Preview',
    second: 'Task Manager',
    third: 'Timeline Manager',
  },
  groupDrawer: {
    label: 'Groups',
    subLabel: 'Create',
    first: 'General',
    second: 'Engineer Team',
    third: 'Design Department',
  },
  memberDrawer: {
    label: 'Members',
    subLabel: 'Manage',
  },
  filter: {
    addTask: 'Add task',
    sortBy: 'Sort by   ',
    filter: 'Filter',
    tags: 'Tags',
    status: 'Status',
    visibility: 'Visibility',
    phaseOne: 'PHASE 1',
    addPlus: 'Add +',
  },
  noLogs: 'No logs available',
  addLog: 'Add log',
  dashboard: 'Dashboard',
  members: 'Members',
  memberList: 'Member List',
  manageMember: 'Manage Member',
  assignedMembers: 'Assigned Members',
  treePreview: 'Tree Preview',
  taskManager: 'Task manager',
  timelineManager: 'Timeline Manager',
  tags: 'Tags',
  tagLabel: 'name',
  published: 'Published.',
  unpublished: 'Unpublished.',
  click: 'Click',
  here: 'here',
  publishMilestone: 'to publish this milestone',
  updateDetail: {
    noContent: 'No Content',
    updateDetails: 'Update Details',
  },
  allCompletedTask: 'All Completed Task',
  noCompletedTask: 'No completed tasks, yet.',
  taskContentDescription: {
    first: 'Manage your task process by using',
    second: 'Task Manager',
    third: '. Track your overall deadlines for All assigned tasks through',
    fourth: 'Timeline Manager',
  },
};
