import { useRef, useEffect } from 'react';

const useUnload = (func) => {
  const cb = useRef();

  cb.current = func;

  useEffect(() => {
    const onUnload = () => cb.current();

    window.addEventListener('beforeunload', onUnload);

    return () => window.removeEventListener('beforeunload', onUnload);
  }, [cb]);
};

export default useUnload;
