import { combineReducers } from 'redux';

import adminProjectReducer from './adminProjectReducer';
import authReducer from './authReducer';
import backdropReducer from './backdropReducer';
import dialogReducer from './dialogReducer';
import forumReducer from './forumReducer';
import lightboxReducer from './lightboxReducer';
import projectReducer from './projectReducer';
import projectsReducer from './projectsReducer';
import updateReducer from './updateReducer';
import panelReducer from './panelReducer';

export default combineReducers({
  update: updateReducer,
  adminProject: adminProjectReducer,
  project: projectReducer,
  dialog: dialogReducer,
  backdrop: backdropReducer,
  projects: projectsReducer,
  auth: authReducer,
  lightbox: lightboxReducer,
  topics: forumReducer,
  panel: panelReducer,
});
