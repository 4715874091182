import { Grid, Typography, makeStyles } from '@material-ui/core';
import { string } from 'prop-types';

import { getDateFromISO } from 'helpers';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles((theme) => ({
  commentContent: {
    '& > *': {
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& > *': {
        marginBottom: theme.spacing(0),
      },
      '& > p': {
        fontSize: 12,
      },
    },
  },
  username: {
    color: '#575757',
    marginRight: '6px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
  timeCreate: {
    marginRight: '6px',
    lineHeight: '24px',
  },
}));

const CommentHeader = ({ name, dateCreated }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container className={classes.commentContent}>
      <Typography display="inline" variant="h6" className={classes.username}>
        {name}
      </Typography>
      <Typography display="inline" variant="body1" color="textSecondary">
        <span className={classes.timeCreate}>{t('common.wrote')}</span>
        {getDateFromISO(dateCreated).replace(',', ' @ ')}
      </Typography>
    </Grid>
  );
};

CommentHeader.propTypes = {
  dateCreated: string,
  name: string,
};

export default CommentHeader;
