import { Avatar, Grid, Typography, Tab, Tabs, Badge, withStyles } from '@material-ui/core';
import { Check as CheckIcon, StarBorder as StarBorderIcon } from '@material-ui/icons';
import { arrayOf, func, object, string } from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getDisplayName } from 'helpers/common';

import useStyles from '../styles/MembersStyle';
const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: '#fff',
  },
  root: {
    width: '100%',
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    ...theme.typography.h6,
    textTransform: 'none',
    color: '#575757',
    display: 'flex',
    justifyContent: 'flex-start',
    width: '323px',
    maxWidth: 'none',
    opacity: 1,
    '&[aria-selected="true"]': {
      opacity: 1,
      backgroundColor: '#F5F5F5',
      width: '323px',
    },
  },
  wrapper: {
    display: 'inline-flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '323px',
    flexDirection: 'row',
    '& > *:first-child': {
      marginTop: '6px',
      marginRight: theme.spacing(1),
      // width:'100%',
    },
  },
  labelIcon: {
    minHeight: 0,
    padding: 0,
  },
  fullWidth: {
    width: '100%',
  },
}))((props) => <Tab disableRipple {...props} />);

const a11yProps = (index) => ({
  id: `project-tab-${index}`,
  'aria-controls': `project-tabpanel-${index}`,
});

const Members = ({ project }) => {
  const classes = useStyles();
  const [members, setMembers] = useState([]);
  const [profile, setProfile] = useState(null);
  // const [isOwner, setIsOwner] = useState(false);
  const [tab, setTab] = useState(0);
  const { t } = useTranslation('dialog');
  const handleChangeTab = (event, newTab) => {
    setTab(newTab);
  };

  useEffect(() => {
    if (project.profile) {
      setProfile(project.profile);
    }
  }, [project.profile]);

  useEffect(() => {
    if (profile) {
      const ownerInfo = {
        id: +profile.user.id,
        name: getDisplayName(profile.user),
        avatarLink: profile.user.avatarLink,
        certified: profile.user.certified,
      };
      const found = members.some((info) => info.id === ownerInfo.id);

      if (!found) {
        setMembers((members) => [...members, ownerInfo]);
      }
    }
  }, [profile]);

  useEffect(() => {
    if (project && members.length !== 0) {
      const ownerId = +project.owner.id;
      const userId = +profile.user.id;
      if (ownerId === userId) {
        // setIsOwner(true);
      } else {
        const ownerInfo = {
          id: +ownerId,
          name: getDisplayName(project.owner),
          avatarLink: project.owner.avatarLink,
          certified: project.owner.certified,
        };
        const found = members.some((info) => info.id === ownerInfo.id);
        if (!found) {
          setMembers((members) => [...members, ownerInfo]);
        }
      }
      project.members.forEach(function (member) {
        const memberInfo = {
          id: +member.id,
          name: getDisplayName(member),
          avatarLink: member.avatarLink,
          certified: member.certified,
        };
        const found = members.some((info) => info.id === memberInfo.id);
        if (!found) {
          setMembers((members) => [...members, memberInfo]);
        }
      });
    }
  }, [project, members]);

  return (
    <>
      <div className={classes.memberWrapper}>
        <Typography className={classes.memberTitle}>{t('addMember.members')}</Typography>
        <Grid container direction="column" justify="center" alignItems="center" className={classes.membersSpace}>
          <StyledTabs
            orientation="vertical"
            value={tab}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            aria-label="members mobile tabs"
            className={classes.fullWidth}
          >
            {members.map((element, index) => {
              if (element.id === project.owner.id) {
                return (
                  <StyledTab
                    key={index}
                    icon={
                      <Badge
                        overlap="circle"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        badgeContent={<StarBorderIcon className={classes.ownerStarStyle} />}
                      >
                        <Avatar alt="avatar" src={element.avatarLink} style={{ marginBottom: 0 }} />
                      </Badge>
                    }
                    label={
                      <>
                        {element.name}
                        {element.certified && <CheckIcon className={classes.certifiedIcon} />}
                      </>
                    }
                    {...a11yProps(index)}
                  />
                );
              }
              return (
                <StyledTab
                  key={index}
                  icon={<Avatar alt="avatar" src={element.avatarLink} style={{ marginBottom: 0 }} />}
                  label={
                    <>
                      {element.name}
                      {element.certified && <CheckIcon className={classes.certifiedIcon} />}
                    </>
                  }
                  {...a11yProps(index)}
                />
              );
            })}
          </StyledTabs>
        </Grid>
      </div>
    </>
  );
};

Members.propTypes = {
  searchMember: string,
  onSearchMemberChange: func,
  project: object,
  membersInvitation: arrayOf(object),
  onClickRemove: func,
};
export default Members;
