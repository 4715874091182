/* eslint-disable max-len */
const Vector = () => (
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.13042e-07 16.3125L7.3763e-08 1.6875C3.30397e-08 0.755859 0.767857 -3.35641e-08 1.71429 -7.49338e-08L14.2857 -6.24448e-07C15.2321 -6.65818e-07 16 0.755859 16 1.6875L16 16.3125C16 17.2441 15.2321 18 14.2857 18L1.71429 18C0.767858 18 7.53765e-07 17.2441 7.13042e-07 16.3125ZM5.71429 15.75L5.71429 2.25L2.71429 2.25C2.47857 2.25 2.28571 2.43984 2.28571 2.67187L2.28571 15.3281C2.28571 15.5602 2.47857 15.75 2.71429 15.75L5.71429 15.75Z"
      fill="#989898"
    />
  </svg>
);

export default Vector;
