import { bool, oneOfType, node, arrayOf } from 'prop-types';
import { cloneElement } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay, virtualize, bindKeyboard } from 'react-swipeable-views-utils';

import modulo from '../helpers/modulo';

const VirtualizeSwipeViews = bindKeyboard(virtualize(SwipeableViews));
const VirtualizeAutoPlaySwipeViews = autoPlay(VirtualizeSwipeViews);

const carouselSlideRenderer =
  (children) =>
  ({ index, key }) =>
    cloneElement(children[modulo(index, children.length)], { key });

export default function SwipeViews({ children, autoplay, ...other }) {
  const slideRenderer = carouselSlideRenderer(children);
  return autoplay ? (
    <VirtualizeAutoPlaySwipeViews {...other} slideRenderer={slideRenderer} />
  ) : (
    <VirtualizeSwipeViews {...other} slideRenderer={slideRenderer} />
  );
}

SwipeViews.propTypes = {
  autoplay: bool.isRequired,
  children: oneOfType([arrayOf(node), node]).isRequired,
};
