import { Avatar, Grid, TextField, Button, makeStyles } from '@material-ui/core';
import { string, func, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { DebounceButton } from 'components';

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: theme.spacing(3, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 0),
      marginTop: theme.spacing(1),
      width: '100%',
    },
  },
  avatar: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: theme.spacing(7),
    height: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0.75),
      marginTop: theme.spacing(1),
      width: theme.spacing(4.5),
      height: theme.spacing(4.5),
    },
  },
  buttonsContainer: {
    marginLeft: theme.spacing(-1),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0),
      marginTop: theme.spacing(1),
      textAlign: 'right',
    },
  },
  button: {
    borderColor: theme.palette.text.secondary,
    padding: theme.spacing(0.25, 1.5),
    marginLeft: theme.spacing(1),
    textTransform: 'none',
    borderRadius: '6px',
    borderWidth: '1px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5, 1),
      fontSize: 12,
    },
  },
  newCommentInput: {
    margin: '2% 0 3% 0',
    '& .MuiInputBase-root': {
      borderRadius: '6px',
    },
    '& input': {
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#ffffff',
      borderRadius: '6px',
    },
    '& p': {
      paddingTop: '6px',
      marginLeft: '3px',
    },
    [theme.breakpoints.down('sm')]: {
      '& div': {
        fontSize: 13,
      },
      '& .MuiInputBase-root': {
        padding: theme.spacing(1.5),
      },
    },
  },
  newCommentInputWrapper: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingBottom: 0,
    },
  },
}));

const ENTER_KEY_CODE = 'Enter';

const InputNewComment = ({ register, avatar, handleSubmit, handleCancel, desktop }) => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      className={classes.grid}
      spacing={desktop ? 1 : 0}
      wrap="nowrap"
    >
      <Grid item>
        <Avatar src={avatar} className={classes.avatar} />
      </Grid>
      <Grid item container spacing={desktop ? 2 : 0} direction="column">
        <Grid item className={classes.newCommentInputWrapper}>
          <TextField
            name="comment"
            inputRef={register}
            className={classes.newCommentInput}
            placeholder={t('common.leaveComment')}
            onKeyUp={(e) => {
              if (e.ctrlKey && e.key === ENTER_KEY_CODE) {
                handleSubmit();
                e.preventDefault();
              }
            }}
            multiline
            fullWidth
            rows={4}
            variant="outlined"
          />
        </Grid>
        <Grid className={classes.buttonsContainer} item>
          <Button className={classes.button} variant="contained" onClick={handleCancel}>
            {t('button.cancel')}
          </Button>
          <DebounceButton className={classes.button} variant="contained" color="primary" onClick={handleSubmit}>
            {t('button.post')}
          </DebounceButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

InputNewComment.propTypes = {
  avatar: string,
  handleSubmit: func.isRequired,
  handleCancel: func.isRequired,
  desktop: bool,
};

InputNewComment.defaultProps = {
  comment: '',
};

export default InputNewComment;
