import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

// INITIAL STATE
const initState = {
  open: false,
  type: undefined,
  options: {},
};

// ACTION CREATORS
export const { openDialog, closeDialog } = createActions({
  OPEN_DIALOG: (type, options) => ({ type, options }),
  CLOSE_DIALOG: undefined,
});

// REDUCERS
export default handleActions(
  {
    [openDialog](state, { payload: { type, options } }) {
      return { ...state, open: true, type, options };
    },
    [closeDialog]() {
      return { ...initState };
    },
  },
  initState,
);

// SELECTORS
const selectorDialog = (state) => state.dialog;

export const getDialogStore = createSelector([selectorDialog], (dialog) => dialog);
