import { Grid, useMediaQuery } from '@material-ui/core';

import LayoutDefault from './components/default/LayoutDefault';
import layoutPropTypes from './layoutPropTypes';

const LogsLayout = (props) => {
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  return (
    <Grid container spacing={desktop ? 2 : 0}>
      <LayoutDefault desktop={desktop} {...props} />
    </Grid>
  );
};

LogsLayout.propTypes = layoutPropTypes;

export default LogsLayout;
