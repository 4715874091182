import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

// INITIAL STATE
const initState = {
  projects: [],
};

// ACTION CREATORS
export const { setProjects, addProject } = createActions({
  SET_PROJECTS: (projects) => ({ projects }),
  ADD_PROJECT: (project) => ({ project }),
});

// REDUCERS
export default handleActions(
  {
    [setProjects](state, { payload: { projects } }) {
      return { ...state, projects };
    },
    [addProject](state, { payload: { project } }) {
      return { ...state, projects: [...state.projects, project] };
    },
  },
  initState,
);

// SELECTORS
const selectorProjects = (state) => state.projects;

export const getProjects = createSelector([selectorProjects], (projects) => projects.projects);
