import { Box, AppBar, Grid, Typography, Avatar } from '@material-ui/core';
import { bool, string, arrayOf, object } from 'prop-types';

import { CocoonAvatarGroup, CocoonStats } from 'components';
import { PROFILE_TYPES } from 'helpers/constants';

import useStyles from './desktopContestInfoBarStyle';

const DesktopContestInfoBar = ({
  desktop,
  partnerAvatar,
  partnerName,
  contestApplicants,
  contestSponsors,
  contestStats,
}) => {
  const classes = useStyles();
  return (
    <AppBar position="static" className={classes.appBar}>
      <Grid container>
        <Grid item xs={3} className={classes.sectionWrapper}>
          <div className={classes.ownerInfoWrapper}>
            <Typography variant="h6" className={classes.ownerText}>
              Created by
            </Typography>
            <div className={classes.contestOwnerWrapper}>
              <Avatar alt="contest-owner-avatar" src={partnerAvatar} className={classes.ownerAvatar} />
              <Typography variant="h6">{partnerName}</Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={6} className={classes.sectionWrapper}>
          <Grid container>
            <Grid item xs={5} className={classes.ownerInfoWrapper}>
              <Typography variant="h6" className={classes.ownerText}>
                Applicants
              </Typography>
              <CocoonAvatarGroup avatars={contestApplicants} max={4} />
            </Grid>
            <Grid item xs={5}>
              <Box className={classes.ownerInfoWrapper}>
                <Typography variant="h6" className={classes.ownerText}>
                  Sponsors
                </Typography>
                <CocoonAvatarGroup avatars={contestSponsors} max={4} />
              </Box>
            </Grid>
            <Grid item xs={2} />
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <CocoonStats profileType={PROFILE_TYPES.CONTEST_PROFILE} desktop={desktop} {...contestStats} />
        </Grid>
      </Grid>
    </AppBar>
  );
};

DesktopContestInfoBar.propTypes = {
  desktop: bool.isRequired,
  partnerAvatar: string,
  partnerName: string,
  contestApplicants: arrayOf(object).isRequired,
  contestSponsors: arrayOf(object).isRequired,
  contestStats: object.isRequired,
};

DesktopContestInfoBar.defaultProps = {
  partnerName: '',
  partnerAvatar: undefined,
};

export default DesktopContestInfoBar;
