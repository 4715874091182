import { Box, Container, Select, MenuItem as MatMenuItem, makeStyles, FormControl } from '@material-ui/core';
import { arrayOf, object, string, func } from 'prop-types';

const useStyles = makeStyles((theme) => ({
  selectWrapper: {
    marginTop: 24,
  },
  formControl: {
    minWidth: 120,
    display: 'flex',
    margin: theme.spacing(0, 0, 1.5),
    '& .MuiSelect-select': {
      padding: theme.spacing(1),
    },
  },
}));

const MobileContestInfoSelect = ({ tabs, currentTab, handleSwitchTab }) => {
  const classes = useStyles();
  return (
    <Box component={Container} className={classes.selectWrapper}>
      <FormControl className={classes.formControl}>
        <Select
          value={currentTab}
          onChange={(e) => {
            handleSwitchTab(e.target.value);
          }}
          autoWidth
          variant="outlined"
          inputProps={{
            name: 'navigation',
            id: 'navigation-select',
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
        >
          {tabs.map((tab) => (
            <MatMenuItem style={{ color: '#000' }} key={tab.key} value={tab.key}>
              {tab.key}
            </MatMenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

MobileContestInfoSelect.propTypes = {
  tabs: arrayOf(object).isRequired,
  currentTab: string.isRequired,
  handleSwitchTab: func.isRequired,
};

export default MobileContestInfoSelect;
