import { Avatar, IconButton, makeStyles } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { convertToSlug } from 'helpers/common';
import { getValue, KEYS } from 'helpers/localStorage';
import { getCurrentUser } from 'reducers/authReducer';

const useStyles = makeStyles((theme) => ({
  list: {
    '& a, & li': {
      display: 'flex',
      alignItems: 'center',
      color: '#333',
      '& > div': {
        minWidth: '32px',
      },
      '&:focus, &:hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        fontWeight: theme.typography.fontWeightBold,
        textDecoration: 'none',
        '& > div': {
          color: theme.palette.common.white,
        },
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 13,
        minHeight: theme.spacing(3),
      },
    },
  },
  avatar: {
    padding: theme.spacing(1, 2),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(-2),
    },
  },
}));

const UserButtonAvatar = () => {
  const classes = useStyles();

  const currentUser = useSelector(getCurrentUser);

  const [open] = useState(false);

  const anchorRef = useRef(null);

  // const handleToggle = (e) => {
  //   e.preventDefault();
  //   history.push('/');
  //   handleClose(e);
  //   setOpenMenuBar(false);
  // };

  // const handleClose = (event) => {
  //   if (anchorRef.current && anchorRef.current.contains(event.target)) {
  //     return;
  //   }

  //   setOpen(false);
  //   setOpenMenuBar(false);
  // };

  // const handleListKeyDown = (e) => {
  //   if (e.key === 'Tab') {
  //     e.preventDefault();
  //     setOpen(false);
  //   }
  // };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <IconButton
        ref={anchorRef}
        aria-controls={open ? 'avatar-list-grow' : undefined}
        aria-haspopup="true"
        color="primary"
        className={classes.avatar}
      >
        <Link
          to={`/users/${currentUser.id}/${convertToSlug(`${currentUser.firstName} ${currentUser.lastName}`)}/profile`}
        >
          <Avatar src={currentUser.avatarLink || getValue(KEYS.avatarLink)} alt="User Avatar" />
        </Link>
      </IconButton>
    </>
  );
};

// UserButtonAvatar.propTypes = {
//   setOpenMenuBar: func.isRequired,
// };

export default UserButtonAvatar;
