import { Box, Button, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { arrayOf, object, func, bool, number, string } from 'prop-types';
import { useRouteMatch } from 'react-router-dom';

import { withLoading } from 'hocs';

import TabSideMenu from '../../../../components/side-menu/TabSideMenu';

import LogsList from './components/list/LogsList';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  btnFluid: {
    color: '#575757',
    borderColor: '#575757',
    borderRadius: '6px',
    borderWidth: '1px',
    width: '100%',
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(1, 3),
    textTransform: 'none',
    '&:hover': {
      background: 'transparent',
      '& *': {
        fontWeight: 'bold',
      },
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 2),
      fontSize: 13,
    },
  },
}));

const LogsLeftPanel = ({
  items,
  stopQuery,
  handleFetchMore,
  searchValue,
  handleChangeSearchValue,
  logIdsSelected,
  handleToggleLogIdSelected,
  resetLogIdsSelected,
  options,
  desktop,
}) => {
  const classes = useStyles();
  const match = useRouteMatch();

  const { id } = match.params;

  const { t } = useTranslation();

  return (
    <TabSideMenu
      searchName="tag"
      searchValue={searchValue}
      handleChangeSearchValue={handleChangeSearchValue}
      options={options}
      desktop={desktop}
      components={
        desktop ? (
          <>
            {!_.isEmpty(logIdsSelected) && (
              <Button size="small" variant="outlined" className={classes.btnFluid} onClick={resetLogIdsSelected}>
                {t('log.removeLogs')}
              </Button>
            )}
            <LogsList
              logs={items}
              reset={_.isEmpty(logIdsSelected)}
              logIdsSelected={logIdsSelected}
              handleToggleLogIdSelected={handleToggleLogIdSelected}
            />
            {id !== undefined && !stopQuery && (
              <Box textAlign="center" mt={2}>
                <Button size="small" variant="outlined" className={classes.btnFluid} onClick={handleFetchMore}>
                  {t('log.loadMore')}
                </Button>
              </Box>
            )}
          </>
        ) : null
      }
    />
  );
};

LogsLeftPanel.propTypes = {
  items: arrayOf(object),
  options: arrayOf(object),
  logIdsSelected: arrayOf(number),
  stopQuery: bool,
  handleFetchMore: func.isRequired,
  searchValue: string,
  handleChangeSearchValue: func.isRequired,
  handleToggleLogIdSelected: func.isRequired,
  resetLogIdsSelected: func.isRequired,
  desktop: bool.isRequired,
};

LogsLeftPanel.defaultProps = {
  items: null,
  logIdsSelected: [],
  stopQuery: undefined,
  options: undefined,
  searchValue: null,
};

export default withLoading(LogsLeftPanel);
