import {
  ClickAwayListener,
  Link,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  makeStyles,
  Box,
  Button,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { Flag as FlagIcon } from '@material-ui/icons';
import { func, number } from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TaskSvg from '../../helpers/icons/TaskSvgActive';
import TimelineSvg from '../../helpers/icons/TimelineSvgActive';
import TreeSvg from '../../helpers/icons/TreeSvgActive';
const useStyles = makeStyles((theme) => ({
  headerTab: {
    position: 'relative',
    width: '100%',
    // backgroundColor: '#ffffff',
    color: '#989898',
    top: 0,
    right: 0,
    height: '80px',
    backgroundColor: '#fff',
    boxShadow: '0px 1px 6px -5px rgba(0, 0, 0, 0.1),inset 2px 0 6px -5px #333',
    // border: '2px solid black',
  },
  root: {
    flexGrow: 1,
    height: '100%',
  },
  taskManager: {
    paddingLeft: theme.spacing(2),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.text.lightSubtle,
  },
  addPhase: {
    paddingLeft: theme.spacing(5),
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
  taskManagerFont: {
    ...theme.typography.h5,
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.1px',
    paddingLeft: theme.spacing(2),
  },
  addPhaseFont: {
    ...theme.typography.h6,
    fontWeight: theme.typography.fontWeightBold,
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.1px',
    color: '#fff',
  },
  phaseFont: {
    ...theme.typography.h6,
    fontWeight: theme.typography.fontWeightBold,
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.1px',
  },
  phaseFontSelected: {
    ...theme.typography.h6,
    fontWeight: theme.typography.fontWeightBold,
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.1px',
    color: '#FAC405',
  },
  percentSuccess: {
    color: theme.palette.success.dark,
  },
  percentWarning: {
    color: theme.palette.secondary.main,
  },
  fullHeight: {
    height: '100%',
  },
  leftPadding: {
    paddingLeft: theme.spacing(5),
    width: '29%',
  },
  button: {
    textTransform: 'none',
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(0.25, 1.5),
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      '& h6': {
        fontSize: 14,
      },
    },
  },
  list: {
    '& a': {
      display: 'flex',
      alignItems: 'end',
      color: '#333',
      '& > div': {
        minWidth: '32px',
      },
      '&:focus, &:hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        fontWeight: theme.typography.fontWeightBold,
        textDecoration: 'none',
        '& > div': {
          color: theme.palette.common.white,
        },
      },
    },
  },
}));

const HeaderPanel = ({ dashboardTab, handleStatusNewLog }) => {
  const classes = useStyles();
  // POPPER
  const [openPopper, setOpenPopper] = useState(false);
  const anchorRef = useRef(null);

  const { t } = useTranslation('panel');

  const handleToggleMenuAdd = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenPopper((prevOpen) => !prevOpen);
  };

  const handleCloseMenu = (event) => {
    if (anchorRef && anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenPopper(false);
  };

  const handleListKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      setOpenPopper(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpenPopper = useRef(openPopper);

  useEffect(() => {
    if (prevOpenPopper.current === true && openPopper === false) {
      anchorRef.current.focus();
    }
    prevOpenPopper.current = openPopper;
  }, [openPopper]);

  const onClickNewLog = (e) => {
    e.preventDefault();
    handleStatusNewLog(true);
    handleCloseMenu(e);
  };

  return (
    <Box className={classes.headerTab}>
      <Grid container direction="row" justify="center" alignItems="center" className={classes.fullHeight}>
        <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.fullHeight}>
          <Grid
            xs={11}
            item
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            className={classes.leftPadding}
          >
            {dashboardTab === 0 ? (
              <>
                <TreeSvg />
                <Typography className={classes.taskManagerFont}>{t('treePreview')}</Typography>
              </>
            ) : dashboardTab === 1 ? (
              <>
                <TaskSvg />
                <Typography className={classes.taskManagerFont}>{t('taskManager')}</Typography>
              </>
            ) : (
              <>
                <TimelineSvg />
                <Typography className={classes.taskManagerFont}>{t('timelineManager')}</Typography>
              </>
            )}
          </Grid>
          {/* <StyledTabs
            value={phaseTab}
            onChange={handleChangePhaseTab}
            textColor="secondary"
            aria-label="phase tabs"
          >
            {phaseTab === 0 ? (
              <StyledTab
                icon={
                  <Typography className={classes.phaseFontSelected}>
                    Phase 1{' '}
                    <span className={classes.percentWarning}> (100%)</span>
                  </Typography>
                }
                {...a11yProps(0)}
              />
            ) : (
              <StyledTab
                icon={
                  <Typography className={classes.phaseFont}>
                    Phase 1{' '}
                    <span className={classes.percentSuccess}> (100%)</span>
                  </Typography>
                }
                {...a11yProps(0)}
              />
            )}
            {phaseTab === 1 ? (
              <StyledTab
                icon={
                  <Typography className={classes.phaseFontSelected}>
                    Phase 2{' '}
                    <span className={classes.percentWarning}> (96%)</span>
                  </Typography>
                }
                {...a11yProps(1)}
              />
            ) : (
              <StyledTab
                icon={
                  <Typography className={classes.phaseFont}>
                    Phase 2{' '}
                    <span className={classes.percentSuccess}> (96%)</span>
                  </Typography>
                }
                {...a11yProps(1)}
              />
            )}
            {phaseTab === 2 ? (
              <StyledTab
                icon={
                  <Typography className={classes.phaseFontSelected}>
                    Phase 3{' '}
                    <span className={classes.percentWarning}> (20%)</span>
                  </Typography>
                }
                {...a11yProps(2)}
              />
            ) : (
              <StyledTab
                icon={
                  <Typography className={classes.phaseFont}>
                    Phase 3{' '}
                    <span className={classes.percentSuccess}> (20%)</span>
                  </Typography>
                }
                {...a11yProps(2)}
              />
            )}
          </StyledTabs> */}
          {dashboardTab === 0 && (
            <div>
              <Divider orientation="vertical" light flexitem="true" />
              <Grid xs item container direction="row" justify="center" alignItems="center">
                <Button
                  className={classes.buttonPadding}
                  onClick={handleToggleMenuAdd}
                  ref={anchorRef}
                  aria-controls={open ? 'timeline-add-grow' : undefined}
                  aria-haspopup="true"
                  size="small"
                  color="primary"
                  variant="outlined"
                >
                  <Typography component="h6" variant="button" color="inherit">
                    {t('filter.addPlus')}
                  </Typography>
                </Button>
              </Grid>
              <Popper
                open={openPopper}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                className={classes.popper}
                placement="bottom-start"
              >
                {() => (
                  <Paper>
                    <ClickAwayListener onClickAway={handleCloseMenu}>
                      <MenuList id="timeline-tag-add" onKeyDown={handleListKeyDown} className={classes.list}>
                        <MenuItem component={Link} onClick={onClickNewLog}>
                          <ListItemIcon>
                            <FlagIcon />
                          </ListItemIcon>
                          {t('addLog')}
                        </MenuItem>
                        {/* <MenuItem component={Link} onClick={onClickNewPhase}>
                          <ListItemIcon>
                            <PhaseIcon />
                          </ListItemIcon>
                          Add phase
                        </MenuItem> */}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                )}
              </Popper>
            </div>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

HeaderPanel.propTypes = {
  dashboardTab: number.isRequired,
  handleStatusNewLog: func.isRequired,
};

export default HeaderPanel;
