import { useMutation } from '@apollo/client';
import { Typography, Avatar, Grid, Box, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import _ from 'lodash';
import { object, arrayOf, func, bool } from 'prop-types';
import { useHistory } from 'react-router-dom';

import { MARK_NOTIFICATION_SEEN } from 'apollo/mutations/notification-mutation';
import { convertToSlug } from 'helpers/common';
import { getDiffNow } from 'helpers/date';
import { useSnackbar } from 'hooks';

import useStyles from './notificationsStyle';

const Notifications = ({ notifications, closeNotifications, desktop }) => {
  const classes = useStyles();
  const history = useHistory();

  const { openSnackbarError } = useSnackbar();

  const [markNotificationSeen] = useMutation(MARK_NOTIFICATION_SEEN);

  const handleMarkNotificationSeen = async (event, id) => {
    try {
      await markNotificationSeen({
        variables: { notification: { id } },
      });
    } catch (error) {
      openSnackbarError(error.message);
    }
  };

  const ACION = {
    LIKE_PROJECT: ' like your project ',
    LIKE_PROJECT_COMMENT: ' liked your comment in ',
    FOLLOW_PROJECT: ' followed your project ',
    COMMENT_TOPIC: ' commented in ',
    REPLY_COMMENT: ' replied to your comment in ',
    PUBLISH_UPDATE: ' updated a log in ',
    NEW_COMMENT: 'commented in ',
  };

  const ACTION_TAB = {
    COMMENT_TOPIC: '#tab=forum',
    REPLY_COMMENT: '#tab=forum',
    NEW_COMMENT: '#tab=forum',
    LIKE_PROJECT_COMMENT: '#tab=forum',
    PUBLISH_UPDATE: '#tab=logs',
  };

  const getActionTabUrl = (action, eventId, topicId, logName) => {
    return ACTION_TAB[action]
      ? logName
        ? `${ACTION_TAB[action]}&name=${convertToSlug(logName)}-${eventId}`
        : `${ACTION_TAB[action]}&topic=${topicId}&comment=${eventId}`
      : '';
  };

  const renderText = (item) => (
    <Grid container>
      <Grid item xs={desktop ? 9 : 10} container alignItems="center">
        <Typography className={classes.normalText}>
          {!item.fromUserFullName ? (
            ACION[item.action] === ACION.NEW_COMMENT ? (
              // Notification for user following the project when new comment was added
              <>
                Project <span className={classes.projectName}>{item.projectName}</span> has new comment. Check it out
                now.
              </>
            ) : (
              <>
                Project <span className={classes.projectName}>{item.projectName}</span> has updated a log. Check it out
                now.
              </>
            )
          ) : (
            <>
              <span className={classes.owner}>{item.fromUserFullName}</span>
              {ACION[item.action]}
              <span className={classes.projectName}>{item.projectName}</span>
            </>
          )}
        </Typography>
      </Grid>
      <Grid item xs={desktop ? 3 : 2} container alignItems="center" justify="flex-end">
        <Typography className={classes.time}>{getDiffNow(item.createdAt, true)}</Typography>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
        className={classes.header}
        borderBottom={1}
      >
        <Typography variant="h6" color="primary">
          Notifications
        </Typography>
      </Box>
      <List className={classes.list}>
        {notifications === null ? (
          <Box
            height={desktop ? 50 : 30}
            width={desktop ? 300 : 220}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography className={classes.emptyNotifications}>You do not have any notifications yet</Typography>
          </Box>
        ) : (
          notifications.map((item) => (
            <ListItem
              className={classes.listItem}
              key={+item.id}
              button
              onClick={(e) => {
                closeNotifications();
                handleMarkNotificationSeen(e, +item.id);
                history.push(
                  `/projects/${item.projectId}/${convertToSlug(item.projectName)}/${getActionTabUrl(
                    item.action,
                    item.eventId,
                    item.projectTopicId,
                    item.logName,
                  )}`,
                );
              }}
              style={{ backgroundColor: item.isSeen ? undefined : '#f5f5f5' }}
            >
              <ListItemAvatar>
                <Avatar alt={item.fromUserFullName} src={item.fromUserAvatarLink} />
              </ListItemAvatar>
              <ListItemText id={item.fromUserId} primary={renderText(item)} />
            </ListItem>
          ))
        )}
      </List>
    </>
  );
};

Notifications.propTypes = {
  notifications: arrayOf(object),
  closeNotifications: func,
  desktop: bool.isRequired,
};

Notifications.defaultProps = {
  closeNotifications: undefined,
};

export default Notifications;
