import { IconButton, makeStyles } from '@material-ui/core';
import {
  LinkedIn as LinkedInIcon,
  GitHub as GitHubIcon,
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
} from '@material-ui/icons';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { isValidUrl } from 'helpers/url';
import { useSnackbar } from 'hooks';

const useStyles = makeStyles({
  socialLinksWrapper: {
    display: 'block',
    marginLeft: -16,
  },
  socialLinks: {
    marginRight: '3%',
  },
});

const SocialLinks = ({ github, linkedin, facebook, twitter }) => {
  const classes = useStyles();

  const { openSnackbarError } = useSnackbar();
  const { t } = useTranslation(['common', 'dialog']);
  const onClickLink = (link) => {
    if (isValidUrl(link)) {
      if (!link.startsWith('http')) {
        link = `https://${link}`;
      }
      window.open(link, '_blank');
    } else {
      openSnackbarError(t('dialog:openSnackbarError.notUrl'));
    }
  };

  return (
    <div className={classes.socialLinksWrapper}>
      {linkedin && (
        <IconButton onClick={() => onClickLink(linkedin)}>
          <LinkedInIcon className={classes.socialLinks} fontSize="large" />
        </IconButton>
      )}
      {github && (
        <IconButton onClick={() => onClickLink(github)}>
          <GitHubIcon className={classes.socialLinks} fontSize="large" />
        </IconButton>
      )}
      {facebook && (
        <IconButton onClick={() => onClickLink(facebook)}>
          <FacebookIcon className={classes.socialLinks} fontSize="large" />
        </IconButton>
      )}
      {twitter && (
        <IconButton onClick={() => onClickLink(twitter)}>
          <TwitterIcon className={classes.socialLinks} fontSize="large" />
        </IconButton>
      )}
    </div>
  );
};

SocialLinks.defaultProps = {
  github: '',
  linkedin: '',
  facebook: '',
  twitter: '',
};

SocialLinks.propTypes = {
  github: string,
  linkedin: string,
  facebook: string,
  twitter: string,
};

export default SocialLinks;
