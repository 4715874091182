import { bool, func, string } from 'prop-types';

import BodyContent from './BodyContent';

const StepBodyContainer = ({ content, isEditMode, updateContent, desktop }) => {
  const bodyParam = isEditMode ? { handleChangeData: updateContent } : {};
  return <BodyContent content={content} desktop={desktop} {...bodyParam} />;
};

StepBodyContainer.propTypes = {
  content: string,
  desktop: bool,
  isEditMode: bool,
  updateContent: func,
};

export default StepBodyContainer;
