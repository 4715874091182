import { useMutation, useLazyQuery } from '@apollo/client';
import { Box, CircularProgress } from '@material-ui/core';
import _ from 'lodash';
import { number, bool, string, oneOfType } from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ADD_COMMENT } from 'apollo/mutations/forum-mutation';
import { GET_TOPIC_COMMENTS } from 'apollo/queries/forum-query';
import { useSnackbar } from 'hooks';
import {
  getCurrentTopic,
  setTopicComments,
  addComment as addCommentReducer,
  addReply,
  updateTotalComments,
} from 'reducers/forumReducer';

import ForumDetails from './ForumDetails';

const LIMIT_ENUM = 'TEN';
const LIMIT = 10;

const ForumDetailsContainer = ({ projectId, topicActive, desktop }) => {
  const currentTopic = useSelector(getCurrentTopic);

  const dispatch = useDispatch();
  const handleSetTopicComments = (comments) => dispatch(setTopicComments(comments));
  const handleAddNewComment = (comment) => dispatch(addCommentReducer(comment));
  const handleAddReply = (commentId, reply) => dispatch(addReply(+commentId, reply));
  const handleUpdateTotalComments = (topicId) => dispatch(updateTotalComments(+topicId));

  const [canFetchMore, setCanFetchMore] = useState(true);
  const [page, setPage] = useState(1);
  const [isPaging, setIsPaging] = useState(false);
  // const [comments, setComments] = useState(null);
  const [addCommentVisible, setAddCommentVisible] = useState(false);

  const { openSnackbarError } = useSnackbar();

  const variables = {
    page,
    projectId,
    limit: LIMIT_ENUM,
    projectTopicId: topicActive,
  };

  const [getComments, { data, loading }] = useLazyQuery(GET_TOPIC_COMMENTS, {
    variables,
    fetchPolicy: 'network-only',
    onCompleted: () => {
      const { projectTopicCommentsReplies } = data;
      if (projectTopicCommentsReplies.length < LIMIT) {
        setCanFetchMore(false);
      }
      let newComments = [...currentTopic.comments, ...projectTopicCommentsReplies];
      handleSetTopicComments(newComments);
      setIsPaging(false);
    },
  });

  useEffect(() => {
    if (topicActive) {
      handleSetTopicComments([]);
      setPage(1);
      setCanFetchMore(true);
    }
  }, [topicActive]);

  useEffect(() => {
    getComments();
  }, [page]);

  const handleFetchMore = () => {
    if (canFetchMore && !isPaging) {
      setIsPaging(true);
      setPage((page) => {
        if (page === variables.page) {
          return page + 1;
        } else return page;
      });
    }
  };

  const debounceHandleFetchMore = _.debounce(handleFetchMore, 300);

  const [addComment] = useMutation(ADD_COMMENT);

  const toggleAddCommentInput = () => setAddCommentVisible((addCommentVisible) => !addCommentVisible);

  const submitReply = async (commentId, currentUser, reply) => {
    try {
      const { data: addProjectComment } = await addComment({
        variables: {
          comment: {
            content: reply,
            projectId: projectId,
            projectTopicId: topicActive,
            parentId: commentId,
          },
        },
      });

      if (addProjectComment) {
        const { id, content, createdAt } = addProjectComment.addProjectComment;

        const newReply = {
          id: +id,
          content,
          totalLikes: 0,
          isLikedByCurrentUser: false,
          createdAt,
          user: currentUser,
        };

        handleAddReply(commentId, newReply);
        handleUpdateTotalComments(+currentTopic.id);
      }
    } catch (errorSubmitComment) {
      openSnackbarError(errorSubmitComment.message);
    }
  };

  const submitComment = async (currentUser, comment) => {
    try {
      const { data: addProjectComment } = await addComment({
        variables: {
          comment: {
            content: comment,
            projectId: projectId,
            projectTopicId: currentTopic.id,
          },
        },
      });

      if (addProjectComment) {
        const { id, content, createdAt } = addProjectComment.addProjectComment;

        const newComment = {
          id: +id,
          content,
          totalLikes: 0,
          isLikedByCurrentUser: false,
          replies: [],
          createdAt,
          userName: name,
          user: currentUser,
        };

        handleAddNewComment(newComment);
        toggleAddCommentInput();
        handleUpdateTotalComments(+currentTopic.id);
      }
    } catch (errorSubmitComment) {
      openSnackbarError(errorSubmitComment.message);
    }
  };

  return loading && currentTopic && currentTopic.comments && currentTopic.comments.length === 0 ? (
    <Box height={100} width="100%" display="flex" alignItems="center" justifyContent="center">
      <CircularProgress color="inherit" />
    </Box>
  ) : (
    <ForumDetails
      loading={loading}
      title={currentTopic ? currentTopic.name : ''}
      comments={currentTopic ? currentTopic.comments : []}
      projectId={+projectId}
      topicId={currentTopic ? +currentTopic.id : null}
      addCommentVisible={addCommentVisible}
      submitComment={submitComment}
      submitReply={submitReply}
      toggleAddCommentInput={toggleAddCommentInput}
      desktop={desktop}
      handleFetchMore={debounceHandleFetchMore}
      canFetchMore={canFetchMore}
    />
  );
};

ForumDetailsContainer.propTypes = {
  projectId: oneOfType([number, string]).isRequired,
  topicActive: oneOfType([number, string]).isRequired,
  desktop: bool.isRequired,
};

export default ForumDetailsContainer;
