import { Box, Typography, Divider, makeStyles } from '@material-ui/core';
import { object, bool } from 'prop-types';

import { hex2rgba } from 'helpers/common';

const useStyles = makeStyles((theme) => ({
  paragraph: {
    boxShadow: `inset 0 0 0 2000px ${hex2rgba(theme.palette.primary.main, 0.05)}`,
    borderRadius: theme.spacing(0, 1, 1, 0),
    margin: theme.spacing(3, 0),
    padding: theme.spacing(1, 0, 1, 2),
    borderLeft: `solid 5px ${theme.palette.primary.main}`,
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1.5, 0),
      borderWidth: 3,
    },
  },
}));

const AboutParagraph = ({ p: { title, sentences, divider }, desktop }) => {
  const classes = useStyles();

  return (
    <Box className={classes.paragraph}>
      <Typography variant={desktop ? 'h5' : 'h6'} gutterBottom color="primary">
        {title}
      </Typography>
      {sentences &&
        sentences.map((s, i) => (
          <Typography key={i} variant={desktop ? 'body1' : 'body2'} gutterBottom component="p" color="textPrimary">
            {s}
          </Typography>
        ))}
      {divider && (
        <Box my={3}>
          <Divider />
        </Box>
      )}
    </Box>
  );
};

AboutParagraph.propTypes = {
  p: object.isRequired,
  desktop: bool.isRequired,
};

export default AboutParagraph;
