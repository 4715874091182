import { gql } from '@apollo/client';

// TODO: use "categories" query when used.
const FRAGMENT_PROJECT = gql`
  fragment mainDetails on Project {
    id
    name
    description
    assetLink
    ownerId
    createdAt
    updatedAt
    isFeatured
    isArchived
    views
    projectOverview
    tags {
      id
      tagName
    }
    categories {
      id
      name
      displayColor
    }
    sponsors {
      id
      firstName
      lastName
    }
    owner {
      id
      username
      email
      firstName
      lastName
      avatarLink
      certified
    }
    isBookmarkedByUser
    isLikedByUser
    totalLikes
  }
`;

// done
// updated
export const GET_PROJECTS = gql`
  query projects($name: String, $page: Int!, $limit: PaginationLimit!, $isArchived: Boolean) {
    projects(name: $name, page: $page, limit: $limit, isArchived: $isArchived) {
      ...mainDetails
    }
  }
  ${FRAGMENT_PROJECT}
`;

export const GLOBAL_PROJECT_SEARCH = gql`
  query projects($name: String, $page: Int!, $limit: PaginationLimit!, $isArchived: Boolean) {
    projects(name: $name, page: $page, limit: $limit, isArchived: $isArchived) {
      id
      name
    }
  }
`;

// done
// updated
export const GET_PROJECTS_DISCOVER = GET_PROJECTS;

// done
// updated
export const GET_PROJECTS_SUBSCRIPTIONS = gql`
  query projectBookmarksByUserId($userId: ID!, $page: Int, $limit: PaginationLimit) {
    projects: projectBookmarksByUserId(userId: $userId, page: $page, limit: $limit) {
      ...mainDetails
    }
  }
  ${FRAGMENT_PROJECT}
`;

// done, change to featuredProjects
// updated
export const GET_PROJECTS_FEATURED = gql`
  query featuredProjects {
    projects: featuredProjects {
      ...mainDetails
    }
  }
  ${FRAGMENT_PROJECT}
`;

// done
// updated
export const GET_PROJECT = gql`
  query projectById($id: ID!) {
    projectById(id: $id) {
      ...mainDetails
      members {
        id
        username
        email
        firstName
        lastName
        avatarLink
        certified
      }
    }
  }
  ${FRAGMENT_PROJECT}
`;

// done
// updated
export const GET_PROJECTS_BY_USER_ID = gql`
  query projectsByUserId($userId: ID!, $isArchived: Boolean!, $page: Int!, $limit: PaginationLimit!) {
    projects: projectsByUserId(userId: $userId, isArchived: $isArchived, page: $page, limit: $limit) {
      ...mainDetails
    }
  }
  ${FRAGMENT_PROJECT}
`;

// done
// updated
export const GET_PROJECT_PICTURE_PRESIGNED_URL = gql`
  query projectPicturePresignedUrl($contentType: String!, $fileName: String!) {
    projectPicturePresignedUrl(fileName: $fileName, contentType: $contentType) {
      url
      key
    }
  }
`;

// done
// updated
export const GET_PROJECTS_BY_CATEGORY = gql`
  query projectsByCategory($projectCategoryId: ID!, $page: Int!, $limit: PaginationLimit!) {
    projects: projectsByCategory(projectCategoryId: $projectCategoryId, page: $page, limit: $limit) {
      ...mainDetails
    }
  }
  ${FRAGMENT_PROJECT}
`;

// done
// updated
export const GET_PROJECT_OVERALL_STATS = gql`
  query projectOverallStats($projectId: ID!) {
    projectOverallStats(projectId: $projectId) {
      totalLikes
      totalComments
    }
  }
`;
