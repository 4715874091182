import { useLazyQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import { object, string, bool, func } from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { GET_LIST_OF_USER } from 'apollo/queries/list-query';
import { GET_PROJECTS_BY_USER_ID } from 'apollo/queries/project-query';
import { cookieUserId } from 'helpers/auth';
import { USER_TABS, PARTNER_TABS, PROFILE_TYPES } from 'helpers/constants';
import { useFetchProjectsByTab } from 'hooks';

import ProfileProjects from './ProfileProjects';
import useStyles from './profileProjectsStyle';

const { USER_INFO, LISTS_WITH_PROJECTS } = USER_TABS;
const { PARTNER_CONTESTS } = PARTNER_TABS;

const ProfileProjectsWrapper = ({ desktop, currentTab, profile, profileType, profileHidden, setProfileHidden }) => {
  const classes = useStyles();
  const { state } = useLocation();
  const { t } = useTranslation();
  const { id } = useParams();
  const userId = id || cookieUserId;
  const [displayProjects, setDisplayProjects] = useState({ sliced: [], all: [] });
  const [displayLists, setDisplayLists] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [displayUserInfo, setDisplayUserInfo] = useState(false);
  const [data, loading, error] = useFetchProjectsByTab(currentTab);

  const [refetchLists] = useLazyQuery(GET_LIST_OF_USER, {
    variables: { page: 1, limit: 'FIVE_O', ownerId: userId },
    fetchPolicy: 'network-only',
  });

  const [refetchProjects] = useLazyQuery(GET_PROJECTS_BY_USER_ID, {
    variables: {
      page: 1,
      limit: 'FIVE_O',
      userId,
      isArchived: false,
    },
    fetchPolicy: 'network-only',
  });

  const [refetchArchivedProjects] = useLazyQuery(GET_PROJECTS_BY_USER_ID, {
    variables: {
      page: 1,
      limit: 'FIVE_O',
      userId,
      isArchived: true,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!desktop && currentTab === USER_INFO && profileType === PROFILE_TYPES.USER_PROFILE) {
      setDisplayUserInfo(true);
    }
  }, [desktop]);

  useEffect(() => {
    if (state) {
      if (state.isRefetchLists) {
        refetchLists();
      }
      if (state.isRefetchProjects) {
        refetchProjects();
        refetchArchivedProjects();
      }
    }
  }, [state]);

  useEffect(() => {
    if (data && data.length !== 0) {
      const projects = [...data[Object.keys(data)[0]]];
      if (currentTab === LISTS_WITH_PROJECTS) {
        setDisplayLists([...projects]);
      } else {
        setDisplayProjects((prevState) => ({
          ...prevState,
          sliced: [...projects.slice(0, 6)],
          all: [...projects],
        }));
      }
    } else {
      setDisplayProjects({ sliced: [], all: [] });
    }
  }, [data]);

  const handleMouseOver = (index) => {
    setCurrentIndex(index);
  };

  const handleMouseOut = () => {
    setCurrentIndex(-1);
  };

  const expandProjects = () => {
    if (!profileHidden) {
      setProfileHidden(true);
    } else {
      setProfileHidden(false);
    }
  };

  const renderWithoutData = (message) => (
    <div className={classes.centered}>
      <Typography className={classes.emptyProjects}>{message}</Typography>
    </div>
  );

  if (loading) {
    return renderWithoutData('Loading...');
  }

  if (error) {
    return renderWithoutData('Error!');
  }

  if (!loading && data) {
    const projects = [...data[Object.keys(data)[0]]];
    if (projects.length === 0) {
      if (currentTab === LISTS_WITH_PROJECTS) {
        return renderWithoutData('No list to show');
      }
      if (currentTab === PARTNER_CONTESTS) {
        return renderWithoutData('No contests to show');
      }

      return renderWithoutData(t('defaultInformation.noProjectShow'));
    }
  }

  return (
    <ProfileProjects
      displayProjects={displayProjects}
      displayLists={displayLists}
      profile={profile}
      profileHidden={profileHidden}
      displayUserInfo={displayUserInfo}
      currentTab={currentTab}
      desktop={desktop}
      profileType={profileType}
      handleMouseOver={handleMouseOver}
      handleMouseOut={handleMouseOut}
      expandProjects={expandProjects}
      currentIndex={currentIndex}
    />
  );
};

ProfileProjectsWrapper.propTypes = {
  desktop: bool.isRequired,
  currentTab: string.isRequired,
  profile: object.isRequired,
  profileType: string.isRequired,
  profileHidden: bool.isRequired,
  setProfileHidden: func.isRequired,
};

export default ProfileProjectsWrapper;
