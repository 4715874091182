import { Add as AddIcon } from '@material-ui/icons';
import _ from 'lodash';

import mimeTypes from './mimeTypes';

export const isImage = (fileType) => {
  const { png, jpeg, gif } = fileType;
  return _.some([png, jpeg, gif], (m) => m === fileType);
};

export const getFileIcon = (type) => {
  let FileIcon = AddIcon;

  switch (type) {
    case mimeTypes.png:
    case mimeTypes.jpeg:
    case mimeTypes.gif:
      FileIcon = AddIcon;
      break;
    case mimeTypes.pdf:
      FileIcon = AddIcon;
      break;
    case mimeTypes.csv:
      FileIcon = AddIcon;
      break;
    case mimeTypes.word:
    case mimeTypes.oldWord:
      FileIcon = AddIcon;
      break;
    case mimeTypes.excel:
    case mimeTypes.oldExcel:
      FileIcon = AddIcon;
      break;
    case mimeTypes.zip:
    case mimeTypes.rar:
      FileIcon = AddIcon;
      break;
    default:
      break;
  }

  return FileIcon;
};

export const getFileNameOnly = (fileName) => {
  const lastDotPosition = fileName.lastIndexOf('.');
  return lastDotPosition === -1 ? fileName : fileName.substr(0, lastDotPosition);
};

export const download = (filename, url) => {
  const extensions = filename.split('.').pop();
  const pom = document.createElement('a');
  pom.setAttribute('href', `data:${mimeTypes[extensions]},${encodeURIComponent(url)}`);
  pom.setAttribute('download', filename);
  if (document.createEvent) {
    const event = document.createEvent('MouseEvents');
    event.initEvent('click', true, true);
    pom.dispatchEvent(event);
  } else {
    pom.click();
  }
};

export const getFileLink = (awsUploadedFile, link) => {
  const tenantReg = new RegExp(`^${process.env.TENANT}/`);

  if (awsUploadedFile) {
    return awsUploadedFile;
  }

  if (link) {
    return link
      .trim()
      .replace(/([^\\/]*\/){3}/, '')
      .replace(tenantReg, '');
  }

  return null;
};
