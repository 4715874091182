const color = '#333';

export default {
  typography: {
    fontFamily: ['Brandon Text', 'Drone Ranger', 'Montserrat', 'sans-serif'].join(','),
    h2: {
      fontFamily: 'Drone Ranger',
      fontWeight: 700,
      fontSize: '48px',
      letterSpacing: '-1px',
      color,
    },
    h4: {
      color,
      fontFamily: 'Brandon Text',
      fontWeight: 700,
      fontSize: '28px',
      lineHeight: 1.5,
    },
    h5: {
      fontFamily: 'Brandon Text',
      fontWeight: 700,
      fontSize: '20px',
      letterSpacing: '0.1px',
      color,
    },
    h6: {
      fontFamily: 'Brandon Text',
      fontWeight: 700,
      fontSize: '16px',
      letterSpacing: '0.1px',
      color,
    },
    button: {
      fontFamily: 'Drone Ranger',
      fontWeight: 700,
      fontSize: '16px',
      textTransform: 'none',
      color,
      marginTop: 3,
    },
    body1: {
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: '15px',
      color,
    },
    caption: {
      fontFamily: 'Brandon Text',
      fontWeight: 400,
      fontSize: '15px',
      color,
    },
  },
};
