import { useApolloClient, useQuery, useMutation } from '@apollo/client';
import { CircularProgress, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { number, func, object, arrayOf, bool } from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';

import awsS3 from 'api/aws';
import { ADD_UPDATE_ATTACHMENT, ARCHIVE_FILE } from 'apollo/mutations/file-mutation';
import { ADD_UPDATE, EDIT_UPDATE, PUBLISH_UPDATE } from 'apollo/mutations/update-mutation';
import { ADD_TAG, REMOVE_UPDATE_TAGS } from 'apollo/mutations/update-tag-mutation';
import { GET_UPDATE_ATTACHMENTS } from 'apollo/queries/file-query';
import { GET_UPDATE_ATTACHMENT_PRESIGNED_URL } from 'apollo/queries/update-query';
import { GET_UPDATE_TAGS } from 'apollo/queries/update-tag-query';
import { getDateFromISO } from 'helpers';
import { DIALOG } from 'helpers/constants';
import { useSnackbar } from 'hooks';
import { handleOpenDialogAC3 } from 'reducers/testAC3/cache';

import { CRUD_STATUS, UPDATE_TAGS_DEFAULT, LOG_DEFAULT } from '../../../helpers/constants';

import AttachmentField from './attachment/AttachmentField';
import TagField from './tags/TagField.js';
import SubTitleWithAction from './title/SubTitleWithAction.js';
import Title from './title/Title';
import UpdateDetail from './updateDetail/UpdateDetail';

import { useDispatch, useSelector } from 'react-redux';

import { isEdit, isRead, getModeIsEditing } from 'reducers/panelReducer';

const useStyles = makeStyles((theme) => ({
  fontWeight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  buttonStyle: {
    display: 'flex',
    paddingRight: 0,
    paddingTop: 0,
    justifyContent: 'flex-end',
  },
  h5Custom: {
    fontStyle: 'normal',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '20px',
    // lineHeight:'24px',
    letterSpacing: '0.1px',
    color: '#575757',
  },
  fullSize: {
    width: '100%',
    height: '100%',
  },
}));

const MilestonePublish = ({
  numberLogChosen,
  updateLogs,
  project,
  newLog,
  handleStatusNewLog,
  handleChangeNewLog,
  handleCancelEditMode,
  refetchPanel,
  reloadComponent,
  deleteNewLog,
  // isNewLog,
  crudStatus,
  setCrudStatus,
  isShowAutoSave,
  setIsShowAutoSave,
  desktop,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isModeEditing = useSelector(getModeIsEditing);
  const handleChangeEditMode = () => dispatch(isEdit());
  const handleChangeViewMode = () => dispatch(isRead());
  const { openSnackbarSuccess, openSnackbarError, openSnackbarWarning } = useSnackbar();
  const [log, setLog] = useState(LOG_DEFAULT);
  const [logName, setLogName] = useState(log.name);
  const [updateTags, setUpdateTags] = useState(UPDATE_TAGS_DEFAULT);
  const [attachments, setAttachments] = useState([]);
  const [logDetail, setLogDetail] = useState(log.description);
  const [crudStatusInternal, setCrudStatusInternal] = useState(null);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [deletedAttachment, setDeletedAttachment] = useState([]);
  const [loadingLogInfo, setLoadingLogInfo] = useState(false);

  const client = useApolloClient();
  const [addUpdate] = useMutation(ADD_UPDATE);
  const [editUpdate] = useMutation(EDIT_UPDATE);
  const [addUpdateTag] = useMutation(ADD_TAG);
  const [removeUpdateTags] = useMutation(REMOVE_UPDATE_TAGS);
  const [addUpdateAttachment] = useMutation(ADD_UPDATE_ATTACHMENT);
  const [deleteFile] = useMutation(ARCHIVE_FILE);
  const [editModeTag, setEditModeTag] = useState(false);
  const [isLogNew, setIsLogNew] = useState(false);
  const [isPublishMilestone, setIsPublishMilestone] = useState('');

  const { t } = useTranslation(['common', 'dialog']);

  const beforeUnloadHandler = (e) => {
    e.preventDefault();
    e.returnValue = '';
  };

  const handleChangeCrudStatusInternal = (mode) => {
    setCrudStatusInternal(mode);
  };

  useEffect(() => {
    if (crudStatusInternal === null) {
      handleChangeCrudStatusInternal(crudStatus);
    }
    if (crudStatus !== crudStatusInternal) {
      setCrudStatus(crudStatusInternal);
    }
  }, [crudStatusInternal, crudStatus]);

  useEffect(() => {
    if (isShowAutoSave) {
      if (isModeEditing) {
        handleOpenConfirmationSaveDialog();
      }
    }
  }, [isShowAutoSave]);

  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    if (didMount) {
      if (isModeEditing) {
        window.addEventListener('beforeunload', beforeUnloadHandler, true);
      }
      return () => window.removeEventListener('beforeunload', beforeUnloadHandler, true);
    } else setDidMount(true);
  });

  useEffect(() => {
    if (numberLogChosen === 0 && updateLogs[numberLogChosen].isNew && updateLogs.length > 1) {
      if (isModeEditing) {
        handleOpenConfirmationSaveDialog();
      } else {
        setLog(updateLogs[numberLogChosen]);
      }
    } else if (updateLogs[numberLogChosen]) {
      setLog(updateLogs[numberLogChosen]);
    }
  }, [updateLogs, numberLogChosen]);

  const handleOpenConfirmationSaveDialog = () => {
    handleChangeViewMode();
    const options = {
      saveEditLog: () => SaveEditLog(),
      // handleConfirmNavigationClick: () => handleConfirmNavigationClick(),
    };
    handleOpenDialogAC3(DIALOG.USER_CONFIRMATION, options);
  };

  useEffect(() => {
    if (isModeEditing) {
      handleOpenConfirmationSaveDialog();
    }
  }, [numberLogChosen]);

  const {
    loading: loadingTags,
    data: dataTags,
    error: errorTags,
    refetch: refetchTags,
  } = useQuery(GET_UPDATE_TAGS, {
    variables: { updateId: updateLogs[numberLogChosen].id },
  });
  const {
    loading: loadingAttachments,
    data: dataAttachments,
    error: errorAttachments,
    refetch: refetchAttachments,
  } = useQuery(GET_UPDATE_ATTACHMENTS, {
    variables: { updateId: updateLogs[numberLogChosen].id },
  });

  useEffect(() => {
    if (log.isNew) {
      setAttachments([]);
      setUploadedFile([]);
      setLogName(log.name);
      setLogDetail(log.description);
      handleChangeCrudStatusInternal(CRUD_STATUS.EDIT);
      setIsLogNew(true);
    } else {
      handleChangeCrudStatusInternal(CRUD_STATUS.VIEW);
      setIsLogNew(false);
    }
  }, [log]);

  useEffect(() => {
    if (dataTags) {
      setUpdateTags(dataTags.tagsByUpdate);
    }
    if (dataAttachments && !log.isNew) {
      if (attachments.length > 0) {
        setAttachments([]);
      }
      setAttachments(dataAttachments.attachmentsByUpdateId);
    }
    if (log.name !== logName) {
      setLogDetail(log.description);
      setLogName(log.name);
    }

    if (log) {
      setIsPublishMilestone(log.isPublished);
    }
  }, [dataTags, dataAttachments, log]);

  const clearAttachment = () => {
    setAttachments([]);
    setUploadedFile([]);
    setDeletedAttachment([]);
  };

  const reloadState = () => {
    setUploadedFile([]);
    setDeletedAttachment([]);
    clearAttachment();
    refetchTags();
    refetchAttachments();
    refetchPanel();
  };

  const handleCancelEditModeInner = () => {
    handleChangeCrudStatusInternal(CRUD_STATUS.VIEW);
    setLogName(log.name);
    setLogDetail(log.description);
    setUpdateTags(dataTags.tagsByUpdate);
    setAttachments(dataAttachments.attachmentsByUpdateId);
    setEditModeTag(false);
    handleStatusNewLog(false);
  };

  const handleCancelSaveNewLog = () => {
    handleChangeCrudStatusInternal(CRUD_STATUS.VIEW);
    setIsLogNew(false);
    clearAttachment();
    deleteNewLog();
  };

  const publishMilestone = () => {
    const { id } = log;
    const options = {
      itemName: logName,
      variables: {
        update: {
          id,
        },
      },
      mutation: PUBLISH_UPDATE,
      resultObjectName: 'publishUpdate',
    };
    handleOpenDialogAC3(DIALOG.UPDATE_PUBLISH, options);
  };

  if (loadingTags || loadingAttachments) return <CircularProgress />;
  if (errorTags) return `Error: ${errorTags}`;
  if (errorAttachments) return `Error: ${errorAttachments}`;

  const handleChangeLogName = (event) => {
    const { value } = event.target;
    if (!isModeEditing && value !== logName) {
      handleChangeEditMode();
    }
    if (crudStatusInternal === CRUD_STATUS.NEW) {
      handleChangeNewLog('name', value);
    } else if (crudStatusInternal === CRUD_STATUS.EDIT) {
      setLogName(value);
    }
  };
  const handleChangeUpdateTags = (newTags) => {
    if (!isModeEditing) {
      handleChangeEditMode();
    }
    setUpdateTags(newTags);
  };

  const handleChangeLogDetail = (data) => {
    if (!isModeEditing && data !== logDetail) {
      handleChangeEditMode();
    }
    setLogDetail(data);
  };

  async function handleUploadFile(e) {
    e.preventDefault();
    const files = Object.values(e.target.files);
    const tempFiles = [...attachments];

    files.map((file) => {
      tempFiles.push({
        assetLink: URL.createObjectURL(file),
        name: file.name,
        assetType: file.type,
        _typename: 'File',
      });
    });
    setAttachments(tempFiles);
    const arrayFileUploaded = [];
    files.map((file) => {
      arrayFileUploaded.push(file);
    });
    if (!isModeEditing) {
      handleChangeEditMode();
    }
    setUploadedFile([...uploadedFile, arrayFileUploaded]);
  }

  function deleteAttachment(att) {
    const index = attachments.indexOf(att);
    if (index > -1) {
      const temps = attachments.slice(0, index).concat(attachments.slice(index + 1, attachments.length));
      setAttachments(temps);
    }
    setDeletedAttachment([...deletedAttachment, att]);
  }
  const membersId = [];
  project.members.forEach((member) => {
    membersId.push(member.id);
  });

  const onClickDelete = (e) => {
    const attDeleted = attachments.filter((att) => att.assetLink === e);
    deleteAttachment(attDeleted[0]);
  };
  async function saveAttachmentForLog(updateId) {
    const fileUploaded = [];
    async function asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    }

    asyncForEach(uploadedFile, async (fileList) => {
      asyncForEach(fileList, async (file) => {
        await fileUploaded.push(file);
      });
    });

    async function SerialLoopFlow(jobs) {
      const finalResult = [];
      for (const file of jobs) {
        const variables = {
          contentType: file.type,
          fileName: file.name,
          updateId: updateId,
          projectId: project.id,
        };

        const { data: updateAttachment } = await client.query({
          query: GET_UPDATE_ATTACHMENT_PRESIGNED_URL,
          variables,
        });

        if (updateAttachment) {
          const { name, size, type } = file;
          await awsS3.upload(updateAttachment.updateAttachmentPresignedUrl.url, file, type);
          finalResult.push({
            name,
            size,
            assetLink: updateAttachment.updateAttachmentPresignedUrl.key,
            assetType: type,
          });
        }
      }
      return finalResult;
    }
    const fileStringResponse = await SerialLoopFlow(fileUploaded);
    const updateAttachment = async () => {
      const { data: dataUpdateAttachmentsAdded } = await addUpdateAttachment({
        variables: {
          data: {
            files: fileStringResponse,
            updateId,
          },
        },
      });
      return dataUpdateAttachmentsAdded;
    };
    const responseImage = updateAttachment();
    return responseImage;
  }

  async function deleteAttachmentOfLog() {
    // const response = await deleteFile
    async function SerialLoopFlow(jobs) {
      const finalResult = [];
      for (const file of jobs) {
        const { data: dataDeleteAttachment } = await deleteFile({
          variables: {
            update: {
              id: file.id,
              projectId: project.id,
            },
          },
        });
        finalResult.push(dataDeleteAttachment);
      }
      return finalResult;
    }
    const fileStringResponse = await SerialLoopFlow(deletedAttachment);
    return fileStringResponse;
  }

  function validationService(logSubmit) {
    const { name, description } = logSubmit;

    if (name === '' || name === undefined || _.isEmpty(name.trim())) {
      openSnackbarWarning(t('dialog:openSnackbarWarning.logNameEmpty'));
      setLoadingLogInfo(false);
      return true;
    }

    if (name.trim().length > 75) {
      openSnackbarError(t('dialog:openSnackbarError.limitCharacterLog'));
      setLoadingLogInfo(false);
      return true;
    }

    if (description && _.isEmpty(description.trim())) {
      openSnackbarWarning(t('dialog:openSnackbarWarning.logDescriptionEmpty'));
      setLoadingLogInfo(false);
      return true;
    }

    return false;
  }

  async function addTagsData(newTags, updateId) {
    if (newTags.length !== 0) {
      const { data: dataUpdateTagsAdded } = await addUpdateTag({
        variables: {
          tag: {
            updateId: updateId || log.id,
            tags: newTags.map(({ id, name }) => ({
              id,
              name,
            })),
          },
        },
      });
      return dataUpdateTagsAdded;
    }
    return false;
  }

  async function deleteTagsData(deleteTags) {
    if (deleteTags.length === 0) {
      return false;
    }

    const {
      data: { status },
    } = await removeUpdateTags({
      variables: {
        tag: {
          ids: deleteTags.map(({ id }) => id),
        },
      },
    });

    return status === 'SUCCESS';
  }

  function isTagChange() {
    let isChange = false;
    if (dataTags !== undefined && dataTags.tagsByUpdate.length === updateTags.length) {
      dataTags.tagsByUpdate.map((tag, indexTag) => {
        updateTags.map((updateTag, index) => {
          if (indexTag === index && tag.name !== updateTag.name) {
            isChange = true;
          }
        });
      });
    }
    return isChange;
  }

  const convertLogSubmit = () => ({
    ...log,
    description: logDetail === null ? '' : logDetail,
    projectId: project.id,
    name: logName,
  });

  async function saveWhenInEditMode(logSubmit) {
    // Update project tags
    const newTags = updateTags;
    const deleteTags = dataTags.tagsByUpdate;

    if (deletedAttachment.length > 0) await deleteAttachmentOfLog();
    if (uploadedFile.length > 0) await saveAttachmentForLog(log.id);
    await editUpdate({
      variables: {
        update: {
          id: logSubmit.id,
          name: logSubmit.name,
          description: logSubmit.description,
          isMilestone: logSubmit.isMilestone,
          publishedDate: logSubmit.publishedDate,
          members: logSubmit.members,
        },
      },
    });
    await deleteTagsData(deleteTags);
    await addTagsData(newTags);

    setLoadingLogInfo(false);
    handleChangeCrudStatusInternal(CRUD_STATUS.VIEW);
    openSnackbarSuccess(
      `${t('dialog:openSnackbarSuccess.theLog')} ${logSubmit.name} ${t('dialog:openSnackbarSuccess.isUpdated')}`,
    );
    setIsShowAutoSave(false);
    handleCancelEditMode();
    reloadState();
    reloadComponent(true);
  }

  async function saveNewLog(logSubmit) {
    const { data: dataAddNew } = await addUpdate({
      variables: {
        update: {
          projectId: project.id,
          name: logSubmit.name,
          description: logSubmit.description,
        },
      },
    });

    if (dataAddNew) {
      const newTags = updateTags;
      const deleteTags = dataTags.tagsByUpdate;
      const updateId = dataAddNew.createUpdate.id;

      await saveAttachmentForLog(updateId);
      await deleteTagsData(deleteTags);
      await addTagsData(newTags, updateId);

      setLoadingLogInfo(false);
      handleChangeCrudStatusInternal(CRUD_STATUS.VIEW);
      setIsLogNew(false);
      openSnackbarSuccess(
        `${t('dialog:openSnackbarSuccess.theLog')} ${logSubmit.name} ${t('dialog:openSnackbarSuccess.isCreated')}`,
      );
      setIsShowAutoSave(false);
      handleCancelEditMode();
      reloadState();
      reloadComponent(true);
    }
  }

  async function SaveEditLog(e) {
    if (e) e.preventDefault();

    handleChangeViewMode();

    setLoadingLogInfo(true);

    const logSubmit = convertLogSubmit();

    if (logSubmit === {}) {
      setLoadingLogInfo(false);
      handleChangeCrudStatusInternal(CRUD_STATUS.VIEW);
      setIsLogNew(false);
      openSnackbarSuccess(
        `${t('dialog:openSnackbarSuccess.theLog')} ${logSubmit.name} ${t('dialog:openSnackbarSuccess.isUpdated')}`,
      );
      handleCancelEditMode();
      setIsShowAutoSave(false);
      reloadState();
      reloadComponent(true);
      return;
    }

    if (validationService(logSubmit)) {
      return;
    }

    validationService(logSubmit);
    handleChangeViewMode();
    try {
      if (crudStatusInternal === CRUD_STATUS.EDIT && !isLogNew) {
        await saveWhenInEditMode(logSubmit);
      } else if (isLogNew) {
        await saveNewLog(logSubmit);
      }
    } catch (errorSubmitDetail) {
      openSnackbarError(errorSubmitDetail.message);
    }
  }

  const handleBlockedNavigation = () => {
    if (isModeEditing) {
      handleOpenConfirmationSaveDialog();
      return false;
    }
    return true;
  };

  return loadingLogInfo ? (
    <CircularProgress />
  ) : (
    <div className={classes.fullSize}>
      <Prompt when={isModeEditing} message={handleBlockedNavigation} />
      <Title
        logName={logName}
        newLog={newLog}
        handleChangeLogName={handleChangeLogName}
        crudStatus={crudStatusInternal}
        handleChangeCrudStatus={handleChangeCrudStatusInternal}
        handleCancelEditMode={handleCancelEditModeInner}
        SaveEditLog={SaveEditLog}
        handleChangeNewLog={handleChangeNewLog}
        isLogNew={isLogNew}
        deleteNewLog={deleteNewLog}
        handleCancelSaveNewLog={handleCancelSaveNewLog}
        desktop={desktop}
      />
      <SubTitleWithAction
        isPublishMilestone={isPublishMilestone}
        publishMilestone={publishMilestone}
        crudStatus={crudStatusInternal}
        log={log}
      />
      <TagField tags={updateTags} crudStatus={crudStatusInternal} handleChangeUpdateTags={handleChangeUpdateTags} />
      <UpdateDetail
        logDetail={logDetail}
        isPublishMilestone={isPublishMilestone}
        publishMilestone={publishMilestone}
        crudStatus={crudStatusInternal}
        handleCancelEditMode={handleCancelEditModeInner}
        handleChangeLogDetail={handleChangeLogDetail}
      />
      <AttachmentField
        attachments={attachments}
        isPublishMilestone={isPublishMilestone}
        publishMilestone={publishMilestone}
        uploadedFile={uploadedFile}
        crudStatus={crudStatusInternal}
        handleUploadFile={handleUploadFile}
        deleteAttachment={deleteAttachment}
        onClickDelete={onClickDelete}
      />
    </div>
  );
};

MilestonePublish.propTypes = {
  numberLogChosen: number.isRequired,
  updateLogs: arrayOf(object),
  project: object.isRequired,
  newLog: object.isRequired,
  handleStatusNewLog: func.isRequired,
  handleCancelEditMode: func.isRequired,
  handleChangeNewLog: func.isRequired,
  refetchPanel: func.isRequired,
  reloadComponent: func.isRequired,
  deleteNewLog: func.isRequired,
  // isNewLog: bool,
  crudStatus: number,
  setCrudStatus: func,
  isShowAutoSave: bool,
  setIsShowAutoSave: func,
  desktop: bool,
};

MilestonePublish.defaultProps = {};

export default MilestonePublish;
