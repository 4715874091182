import { gql } from '@apollo/client';

// done
export const GET_PARTNER_BY_ID = gql`
  query partnerById($id: ID!) {
    partnerById(id: $id) {
      id
      partnerName
      email
      description
      fullBio
      linkedin
      github
      facebook
      twitter
      avatarLink
      fieldsOfWork
      backgroundPictureUrl
      tags {
        id
        tagName
      }
      members {
        id
        email
        roleId
      }
    }
  }
`;
