import { useLazyQuery } from '@apollo/client';
import { bool, func, number, object, string, oneOfType } from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { ARCHIVE_COMMENT } from 'apollo/mutations/forum-mutation';
import { MORE_COMMENT_REPLIES } from 'apollo/queries/forum-query';
import { DIALOG } from 'helpers/constants';
import { addReplies } from 'reducers/forumReducer';
import { handleOpenDialogAC3 } from 'reducers/testAC3/cache';

import ForumComment from './ForumComment';

const LIMIT = 5;

const ForumCommentContainer = ({ projectId, topicId, comment, submitReply, desktop }) => {
  const dispatch = useDispatch();
  const handleAddReplies = (commentId, replies) => dispatch(addReplies(commentId, replies));

  const [addReplyVisible, setAddReplyVisible] = useState(false);
  const [showPreviousRepliesVisible, setShowPreviousRepliesVisible] = useState(true);
  const [isDeleted, setIsDeleted] = useState(false);

  const [moreCommentReplies, { data }] = useLazyQuery(MORE_COMMENT_REPLIES);

  useEffect(() => {
    if (data) {
      handleHideShowPreviousReplies(data.moreCommentReplies);
      handleAddReplies(+comment.id, data.moreCommentReplies);
    }
  }, [data]);

  useEffect(() => {
    setIsDeleted(comment.isArchived);
  }, []);

  const toggleAddReplyVisible = () => setAddReplyVisible(!addReplyVisible);

  const openRemoveCommentDialog = (e) => {
    e.preventDefault();
    const options = {
      variables: { comment: { id: comment.id } },
      mutation: ARCHIVE_COMMENT,
      handleCommentDisplay: () => setIsDeleted(true),
    };
    handleOpenDialogAC3(DIALOG.ARCHIVE_COMMENT, options);
  };

  const handleHideShowPreviousReplies = (replies) => {
    if (replies.length < LIMIT || replies.length === 0) {
      setShowPreviousRepliesVisible(false);
    }
  };

  const loadMoreReplies = () => {
    moreCommentReplies({
      variables: {
        projectId: projectId,
        projectTopicId: topicId,
        projectCommentId: comment.id,
        page: 1,
        limit: 'FIVE',
      },
    });
  };

  return (
    <ForumComment
      {...comment}
      submitReply={submitReply}
      isDeleted={isDeleted}
      removeComment={openRemoveCommentDialog}
      addReplyVisible={addReplyVisible}
      showPreviousRepliesVisible={showPreviousRepliesVisible}
      toggleAddReplyVisible={toggleAddReplyVisible}
      loadMoreReplies={loadMoreReplies}
      desktop={desktop}
    />
  );
};

ForumCommentContainer.propTypes = {
  comment: object,
  desktop: bool,
  projectId: oneOfType([number, string]),
  submitReply: func,
  topicId: number,
};

ForumCommentContainer.defaultProps = {
  projectId: undefined,
};

export default ForumCommentContainer;
