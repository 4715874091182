import { Chip, Typography, makeStyles } from '@material-ui/core';
import { arrayOf, object, func, bool, string, number } from 'prop-types';

import { isEmpty } from 'helpers/common';
import { useSnackbar } from 'hooks';

import InputNewTag from './components/InputNewTag';
import InputTag from './components/InputTag';

const useStyles = makeStyles((theme) => ({
  tag: {
    ...theme.typography.h6,
    color: theme.palette.common.white,
    borderRadius: '6px',
    borderWidth: '1px',
    '& > span': {
      padding: theme.spacing(0, 1.25),
    },
    '& > svg': {
      color: theme.palette.common.white,
    },
    '&:not(:last-child)': {
      margin: theme.spacing(0.25, 1, 0.25, 0),
    },
    [theme.breakpoints.down('sm')]: {
      '& > span': {
        fontSize: 14,
        '&:not(:last-child)': {
          margin: theme.spacing(0, 0.25, 0.25, 0),
        },
      },
    },
  },
  tagText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
}));

const Tags = ({
  isEditMode,
  isChips,
  isLightView,
  tags,
  label,
  setTags,
  tagPlaceholder,
  tagSizeLimit,
  nbTagsLimit,
}) => {
  const classes = useStyles();
  const { openSnackbarError } = useSnackbar();

  const renderView = (tagsView, _isChips) =>
    !isEmpty(tagsView) &&
    (_isChips ? (
      tagsView.map((tag, index) => {
        return <Chip key={index} label={tag ? tag[label] : ''} className={classes.tag} color="secondary" />;
      })
    ) : (
      <Typography variant="h6" color="secondary" className={classes.tagText}>
        {tagsView.map((tag) => (tag ? tag[label] : '')).join(', ')}
      </Typography>
    ));

  return (
    <>
      {isEditMode
        ? tags.map((tag, index) => (
            <InputTag
              key={index}
              index={index}
              label={label}
              value={tag ? tag[label] : ''}
              tags={tags}
              setTags={setTags}
              openSnackbarError={openSnackbarError}
              tagSizeLimit={tagSizeLimit}
            />
          ))
        : renderView(tags, isChips)}
      {isEditMode && tags.length < nbTagsLimit && (
        <InputNewTag
          tags={tags}
          label={label}
          setTags={setTags}
          openSnackbarError={openSnackbarError}
          tagPlaceholder={tagPlaceholder}
          tagSizeLimit={tagSizeLimit}
          isLightView={isLightView}
        />
      )}
    </>
  );
};

Tags.propTypes = {
  isEditMode: bool,
  isChips: bool,
  isLightView: bool,
  tags: arrayOf(object),
  label: string,
  setTags: func,
  tagPlaceholder: string,
  tagSizeLimit: number,
  nbTagsLimit: number,
};

Tags.defaultProps = {
  isEditMode: false,
  isChips: false,
  isLightView: false,
  setTags: undefined,
  label: 'tagName',
  tagPlaceholder: '+ Add tags',
  tagSizeLimit: 20,
  tags: [],
  nbTagsLimit: 10,
};

export default Tags;
