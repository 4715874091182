import { Avatar, Badge, Grid, Tab, Tabs, Typography, makeStyles, withStyles } from '@material-ui/core';
import { Check as CheckIcon, StarBorder as StarBorderIcon } from '@material-ui/icons';
import { object } from 'prop-types';
import { useEffect, useState } from 'react';

import { getDisplayName } from 'helpers/common';
import { DIALOG } from 'helpers/constants';
import { handleOpenDialogAC3 } from 'reducers/testAC3/cache';

import { DrawerLabel } from '../../helpers/constants';

const useStyles = makeStyles((theme) => ({
  selectionPanel: {
    // margin: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: '100%',
    marginLeft: 0,
  },
  margin: {
    // margin: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  buttonCustom: {
    fontStyle: 'normal',
    fontWeitght: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.1px',
    color: '#989898',
    cursor: 'pointer',
  },
  buttonBox: {
    display: 'flex',
    justify: 'flex-end',
    justifyContent: 'flex-end',
    paddingRight: '2em',
  },
  labelStyle: {
    ...theme.typography.h5,
    letterSpacing: '0.1px',
    color: '#333333',
  },
  ownerStarStyle: {
    color: '#fff',
    backgroundColor: '#FAC405',
    borderRadius: '20px',
    width: '0.7em',
    height: '0.7em',
  },
  certifiedIcon: {
    color: '#fff',
    backgroundColor: '#FAC405',
    borderRadius: '50%',
    height: '16px',
    width: '16px',
    marginLeft: '5px',
  },
}));
const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: '#fff',
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);
const StyledTab = withStyles((theme) => ({
  root: {
    ...theme.typography.h6,
    textTransform: 'none',
    color: '#575757',
    display: 'flex',
    justifyContent: 'flex-start',
    width: '323px',
    maxWidth: 'none',
    opacity: 1,
    '&[aria-selected="true"]': {
      opacity: 1,
      backgroundColor: '#F5F5F5',
      width: '323px',
    },
  },
  wrapper: {
    display: 'inline-flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '323px',
    flexDirection: 'row',
    '& > *:first-child': {
      marginTop: '6px',
      marginRight: theme.spacing(1),
      // width:'100%',
    },
  },
  labelIcon: {
    minHeight: 0,
    padding: 0,
    paddingLeft: theme.spacing(2),
  },
}))((props) => <Tab disableRipple {...props} />);

const a11yProps = (index) => ({
  id: `project-tab-${index}`,
  'aria-controls': `project-tabpanel-${index}`,
});

const Members = ({ project, profile }) => {
  const classes = useStyles();

  const [tab, setTab] = useState(0);
  const [membersInfo, setMembersInfo] = useState([]);
  const [isOwner, setIsOwner] = useState(false);

  useEffect(() => {
    if (profile) {
      const ownerInfo = {
        id: profile.user.id,
        name: getDisplayName(profile.user),
        avatarLink: profile.user.avatarLink,
        certified: profile.user.certified,
      };
      const found = membersInfo.some((info) => info.id === ownerInfo.id);
      if (!found) {
        setMembersInfo((membersInfo) => [...membersInfo, ownerInfo]);
      }
    }
  }, [profile]);

  useEffect(() => {}, [isOwner]);

  useEffect(() => {
    if (project && profile && membersInfo.length !== 0) {
      const ownerId = +project.owner.id;
      const userId = +profile.user.id;
      if (ownerId === userId) {
        setIsOwner(true);
      } else {
        const ownerInfo = {
          id: ownerId,
          name: getDisplayName(project.owner),
          avatarLink: project.owner.avatarLink,
          certified: project.owner.certified,
        };
        const found = membersInfo.some((info) => info.id === ownerInfo.id);
        if (!found) {
          setMembersInfo((membersInfo) => [...membersInfo, ownerInfo]);
        }
      }
      project.members.forEach(function (member) {
        const memberInfo = {
          id: member.id,
          name: getDisplayName(member),
          avatarLink: member.avatarLink,
          certified: member.certified,
        };

        const found = membersInfo.some((info) => info.id === memberInfo.id);
        if (!found) {
          setMembersInfo((membersInfo) => [...membersInfo, memberInfo]);
        }
      });
    }
  }, [project, profile, membersInfo]);

  const handleChangeTab = (event, newTab) => {
    setTab(newTab);
  };

  const firstArr = [];

  Object.keys(DrawerLabel[0]).forEach(function (key) {
    if (key !== 'label' && key !== 'subLabel') firstArr.push(DrawerLabel[0][key]);
  });

  const openInviteMemberDialog = (e) => {
    if (e) {
      e.preventDefault();
    }
    const options = { project };
    handleOpenDialogAC3(DIALOG.INVITE_PROJECT_MEMBER, options);
  };

  return (
    // membersInfo.length > 0 && (
    <div style={{ width: 323 }}>
      {isOwner ? (
        <Grid className={classes.margin} container direction="row" justify="center" alignItems="center">
          <Grid item xs={4}>
            <Typography variant="h5" className={classes.labelStyle}>
              {DrawerLabel[2].label}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            {/* {isOwner & (

            )} */}
            <a className={classes.buttonBox}>
              <span onClick={(e) => openInviteMemberDialog(e)} className={classes.buttonCustom}>
                {DrawerLabel[2].subLabel}
              </span>
            </a>
          </Grid>
        </Grid>
      ) : (
        <Grid className={classes.margin}>
          <Typography variant="h5" className={classes.labelStyle} gutterBottom>
            {DrawerLabel[2].label}
          </Typography>
        </Grid>
      )}
      <Grid className={classes.selectionPanel}>
        <StyledTabs
          orientation="vertical"
          value={tab}
          onChange={handleChangeTab}
          indicatorColor="secondary"
          aria-label="dashboard tabs"
        >
          {membersInfo.map((element, index) => {
            if (element.id === project.owner.id)
              return (
                <StyledTab
                  key={index}
                  icon={
                    <Badge
                      overlap="circle"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      badgeContent={<StarBorderIcon className={classes.ownerStarStyle} />}
                    >
                      <Avatar alt="avatar" src={element.avatarLink} style={{ marginBottom: 0 }} />
                    </Badge>
                  }
                  label={
                    <>
                      {element.name}
                      {element.certified && <CheckIcon className={classes.certifiedIcon} />}
                    </>
                  }
                  {...a11yProps(index)}
                />
              );
            return (
              <StyledTab
                key={index}
                icon={<Avatar alt="avatar" src={element.avatarLink} style={{ marginBottom: 0 }} />}
                label={
                  <>
                    {element.name}
                    {element.certified && <CheckIcon className={classes.certifiedIcon} />}
                  </>
                }
                {...a11yProps(index)}
              />
            );
          })}
        </StyledTabs>
      </Grid>
    </div>
    // )
  );
};

Members.propTypes = {
  project: object,
  profile: object,
};

export default Members;
