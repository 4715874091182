import { useLazyQuery, useMutation } from '@apollo/client';
import _ from 'lodash';
import { bool, number, object } from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ADD_TOPIC } from 'apollo/mutations/forum-mutation';
import { GET_TOPICS } from 'apollo/queries/forum-query';
import { useSnackbar } from 'hooks';
import { addTopic as addTopicReducer, addTopics, getTopics, selectTopic, setTopics } from 'reducers/forumReducer';

import ForumTabPanel from './ForumTabPanel';

const LIMIT = 5;

const ForumTabPanelContainer = ({ tab, project: { id, ownerId }, desktop }) => {
  const topics = useSelector(getTopics);

  const dispatch = useDispatch();
  const handleSetTopics = (topics) => dispatch(setTopics(topics));
  const addTopicToForum = (topic) => dispatch(addTopicReducer(topic));
  const handleAddTopics = (topics) => dispatch(addTopics(topics));
  const handleSelectTopic = (topic) => dispatch(selectTopic(topic));

  const { openSnackbarError, openSnackbarSuccess } = useSnackbar();

  const [topicActive, setTopicActive] = useState(-1);
  const [page, setPage] = useState(1);
  const [showMoreTopics, setShowMoreTopics] = useState(true);
  const [getAllTopics, { data: dataTopics }] = useLazyQuery(GET_TOPICS, {
    variables: { projectId: id, page },
    onCompleted: () => {
      const { topicsByProject } = dataTopics;

      if (topics.length === 0) {
        handleSetTopics(topicsByProject);
      } else {
        handleAddTopics(topicsByProject);
      }

      if (topicsByProject.length < LIMIT || topicsByProject.length === 0) {
        setShowMoreTopics(false);
      }

      if (topicsByProject.length > 0 && topicActive === -1) {
        handleSelectTopic(topicsByProject[0]);
        setTopicActive(+topicsByProject[0].id);
      }
    },
  });

  const [addTopic] = useMutation(ADD_TOPIC);

  useEffect(() => {
    handleSetTopics([]);

    if (id) {
      getAllTopics();
    }
  }, [id]);

  const handleChangeTopic = (newTopicId) => {
    if (+newTopicId !== topicActive) {
      setTopicActive(+newTopicId);
      handleSelectTopic(_.find(topics, ({ id }) => +id === +newTopicId));
    }
  };

  const handleAddTopic = async (topicName) => {
    try {
      const { data: dataTopicCreated } = await addTopic({
        variables: {
          topic: {
            name: topicName,
            projectId: id,
          },
        },
      });

      if (dataTopicCreated) {
        openSnackbarSuccess(`Topic created successfully!`);

        const newTopic = {
          id: +dataTopicCreated.createProjectTopic.id,
          name: dataTopicCreated.createProjectTopic.name,
          totalComments: 0,
          comments: [],
        };

        await addTopicToForum(newTopic);
        setTopicActive(+newTopic.id);
        await handleSelectTopic(newTopic);
      }
    } catch (errorAddTopic) {
      openSnackbarError(errorAddTopic.message);
    }
  };

  const handleLoadMoreTopics = () => {
    setPage((page) => {
      const newPage = page + 1;

      getAllTopics({
        variables: {
          projectId: +id,
          page: newPage,
        },
      });

      return newPage;
    });
  };

  return (
    <ForumTabPanel
      tab={tab}
      id={id}
      ownerId={ownerId}
      topics={topics}
      topicActive={topicActive}
      showMoreTopics={showMoreTopics}
      handleChangeTopic={handleChangeTopic}
      handleLoadMoreTopics={handleLoadMoreTopics}
      handleAddTopic={handleAddTopic}
      isNewProject={id === undefined}
      desktop={desktop}
    />
  );
};

ForumTabPanelContainer.propTypes = {
  tab: number.isRequired,
  project: object.isRequired,
  desktop: bool.isRequired,
};

export default ForumTabPanelContainer;
