import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

// INITIAL STATE
const initState = { adminProject: {} };

// ACTION CREATORS
export const { getAdminProject } = createActions({
  GET_ADMIN_PROJECT: (adminProject) => ({ adminProject }),
});

// REDUCERS
export default handleActions(
  {
    [getAdminProject](state, { payload: { adminProject } }) {
      return adminProject;
    },
  },
  initState,
);

// SELECTORS
const selectorAdminProject = (state) => state.adminProject;

export const getAdminProjectState = createSelector([selectorAdminProject], (adminProject) => adminProject);
