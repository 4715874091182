import { Link, Grid, Typography, Box, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { useWatch } from 'react-hook-form';

import { CocoonEditor } from 'components';

const useStyles = makeStyles((theme) => ({
  grid: {
    marginBottom: theme.spacing(3),
  },
  boxBorderBottom: {
    borderBottom: '1px solid rgba(51, 51, 51, 0.3)',
  },
}));

const ProjectOverviewContent = ({ project, control, emptyText, title }) => {
  const classes = useStyles();

  const projectOverview = useWatch({
    control,
    name: 'projectOverview',
    defaultValue: project.projectOverview || '',
  });

  return (
    <Grid item xs={12} className={classes.grid}>
      {title && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          mb={1}
          className={classes.boxBorderBottom}
        >
          <Typography variant="h6" color="textSecondary" gutterBottom>
            {title}
          </Typography>
        </Box>
      )}
      {_.isEmpty(projectOverview) ? (
        <Box fontWeight="fontWeightBold">{emptyText}</Box>
      ) : (
        <Box pt={0.75}>
          <CocoonEditor data={projectOverview} />
        </Box>
      )}
    </Grid>
  );
};

export default ProjectOverviewContent;
