import common from './common';
import glossary from './glossary';
import validation from './validation';
import dashboard from './dashboard.js';
import panel from './panel';
// import public from './public';
import error from './error';
import dialog from './dialog';
export default {
  common,
  glossary,
  validation,
  dashboard,
  panel,
  error,
  dialog,
};
