import { Grid, Card, makeStyles } from '@material-ui/core';
import { string, object } from 'prop-types';

import LogCardLayout from './components/layout/LogCardLayout';

const useStyles = makeStyles({
  card: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    minHeight: 120,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    borderRadius: 0,
  },
});

const LogCard = ({ log, type, ...props }) => {
  const classes = useStyles();

  return (
    <Grid component={Card} item xs={12} container className={classes.card}>
      {log && <LogCardLayout type={type} log={log} {...props} />}
    </Grid>
  );
};

LogCard.propTypes = {
  project: object,
  log: object,
  type: string,
};

LogCard.defaultProps = {
  project: undefined,
};

export default LogCard;
