import { useQuery } from '@apollo/client';
import { Box, CircularProgress } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, Redirect, useParams, useHistory } from 'react-router-dom';

import { GET_USER } from 'apollo/queries/user-query';
import { cookieUserId } from 'helpers/auth';
import { USER_TABS } from 'helpers/constants';
import { updateCurrentUser } from 'reducers/authReducer';

import UserProfile from './UserProfile';

const { PERSONAL_PROJECTS, FOLLOWED_PROJECTS, LISTS_WITH_PROJECTS, ARCHIVED_PROJECTS, USER_INFO } = USER_TABS;

const UserProfileWrapper = () => {
  const dispatch = useDispatch();
  const handleUpdateCurrentUser = (user) => dispatch(updateCurrentUser(user));
  const { t } = useTranslation();

  const history = useHistory();
  const { state } = useLocation();
  const { id } = useParams();

  const userId = +id || +cookieUserId;

  const isArchivedQueryCallback = !id || +cookieUserId === +id;

  const {
    data: profile,
    loading: loadingProfile,
    // error: errorProfile,
    refetch: refetchProfile,
  } = useQuery(GET_USER, {
    variables: { id: userId },
  });

  if (state && state.isRefetchProfile) {
    refetchProfile();
    history.push({
      pathname: location.pathname,
      state: { isRefetchProfile: false },
    });
  }

  const [profileHidden, setProfileHidden] = useState(false);
  const [currentTab, setCurrentTab] = useState(PERSONAL_PROJECTS);

  const TABS = [
    {
      id: 0,
      key: PERSONAL_PROJECTS,
      title: t('common.projectsUpper'),
    },
    {
      id: 1,
      key: FOLLOWED_PROJECTS,
      title: t('common.followingUpper'),
    },
    {
      id: 2,
      key: LISTS_WITH_PROJECTS,
      title: t('common.lists'),
    },
    {
      id: 3,
      key: ARCHIVED_PROJECTS,
      title: t('common.archived'),
    },
  ];

  const MOBILE_TABS = [
    {
      id: 0,
      key: USER_INFO,
      title: t('common.info'),
    },
    {
      id: 1,
      key: PERSONAL_PROJECTS,
      title: t('common.projectsUpper'),
    },
    {
      id: 2,
      key: FOLLOWED_PROJECTS,
      title: t('common.followingUpper'),
    },
    {
      id: 3,
      key: LISTS_WITH_PROJECTS,
      title: t('common.lists'),
    },
    {
      id: 4,
      key: ARCHIVED_PROJECTS,
      title: t('common.archived'),
    },
  ];

  useEffect(() => {
    if (profile && +cookieUserId === +profile.user.id) {
      handleUpdateCurrentUser(profile.user);
    }
  }, [profile]);

  if (loadingProfile)
    return (
      <Box height="300px" width="100%" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress color="inherit" />
      </Box>
    );

  if (!loadingProfile && !profile) {
    return <Redirect to="/not-found" />;
  }

  return (
    <UserProfile
      tabs={TABS}
      mobileTabs={MOBILE_TABS}
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
      profile={profile.user}
      refetchProfile={refetchProfile}
      profileHidden={profileHidden}
      setProfileHidden={setProfileHidden}
      isArchivedQueryCallback={isArchivedQueryCallback}
    />
  );
};

export default UserProfileWrapper;
