import { Grid, Box, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { bool, number, func, object, arrayOf } from 'prop-types';
import { useTranslation } from 'react-i18next';

import DataContent from './DataContent';
import Task from './task/Task';

const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%%',
    top: 0,
    right: 0,
    height: '100%',
    backgroundColor: '#E5E5E5',
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  fullHeight: {
    height: '100%',
  },
  fontWeight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  leftPadding: {
    // paddingLeft: theme.spacing(5),
    height: '100%',
    paddingBottom: theme.spacing(2),
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
  },
}));

const TreeContentContainer = ({
  project,
  isMilestone,
  isPhase,
  changeMilestoneContent,
  handleCancelEditMode,
  changePhaseContent,
  numberLogChosen,
  newLog,
  handleStatusNewLog,
  isNewLog,
  handleChangeNewLog,
  refetchPanel,
  isReload,
  reloadComponent,
  updateLogs,
  deleteNewLog,
  crudStatus,
  setCrudStatus,
  isShowAutoSave,
  setIsShowAutoSave,
  desktop,
}) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const renderContent = () =>
    _.isEmpty(updateLogs) ? (
      <Box fontWeight="fontWeightBold">{t('defaultInformation.noLogs')}</Box>
    ) : (
      <>
        <DataContent
          project={project}
          isMilestone={isMilestone}
          isPhase={isPhase}
          changeMilestoneContent={changeMilestoneContent}
          changePhaseContent={changePhaseContent}
          numberLogChosen={numberLogChosen}
          newLog={newLog}
          handleStatusNewLog={handleStatusNewLog}
          isNewLog={isNewLog}
          handleChangeNewLog={handleChangeNewLog}
          refetchPanel={refetchPanel}
          isReload={isReload}
          reloadComponent={reloadComponent}
          updateLogs={updateLogs}
          deleteNewLog={deleteNewLog}
          handleCancelEditMode={handleCancelEditMode}
          crudStatus={crudStatus}
          setCrudStatus={setCrudStatus}
          isShowAutoSave={isShowAutoSave}
          setIsShowAutoSave={setIsShowAutoSave}
          desktop={desktop}
        />
      </>
    );

  const renderTask = () =>
    _.isEmpty(updateLogs) ? (
      <Box fontWeight="fontWeightBold">{t('defaultInformation.noLogs')}</Box>
    ) : (
      <>
        <Task
          project={project}
          isMilestone={isMilestone}
          isPhase={isPhase}
          changeMilestoneContent={changeMilestoneContent}
          changePhaseContent={changePhaseContent}
          numberLogChosen={numberLogChosen}
          newLog={newLog}
          handleStatusNewLog={handleStatusNewLog}
          isNewLog={isNewLog}
          handleChangeNewLog={handleChangeNewLog}
          refetchPanel={refetchPanel}
          isReload={isReload}
          reloadComponent={reloadComponent}
          updateLogs={updateLogs}
          deleteNewLog={deleteNewLog}
          handleCancelEditMode={handleCancelEditMode}
        />
      </>
    );
  return (
    <Box className={classes.content}>
      <Grid container direction="row" justify="center" alignItems="flex-start" className={classes.leftPadding}>
        {/* <Grid xs={1} item /> */}
        <Grid
          item
          xs={desktop ? 8 : 12}
          className={classes.fullHeight}
          style={desktop ? { paddingRight: '10px' } : { paddingRight: '0px' }}
          container
          direction="row"
        >
          {renderContent()}
        </Grid>
        <Grid
          item
          xs={desktop ? 4 : 12}
          className={classes.fullHeight}
          style={desktop ? { paddingLeft: '10px' } : { paddingLeft: '0px', paddingTop: '10px' }}
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
        >
          {renderTask()}
        </Grid>
        {/* <Grid xs={1} item/> */}
      </Grid>
    </Box>
  );
};

TreeContentContainer.propTypes = {
  project: object.isRequired,
  isMilestone: bool.isRequired,
  isPhase: bool.isRequired,
  changeMilestoneContent: func.isRequired,
  changePhaseContent: func.isRequired,
  handleCancelEditMode: func.isRequired,
  numberLogChosen: number.isRequired,
  newLog: object.isRequired,
  handleStatusNewLog: func.isRequired,
  isNewLog: bool.isRequired,
  handleChangeNewLog: func.isRequired,
  refetchPanel: func.isRequired,
  isReload: bool,
  reloadComponent: func.isRequired,
  updateLogs: arrayOf(object),
  deleteNewLog: func.isRequired,
  crudStatus: number,
  setCrudStatus: func,
  isShowAutoSave: bool,
  setIsShowAutoSave: func,
  desktop: bool,
};

TreeContentContainer.defaultProps = {
  isReload: undefined,
};

export default TreeContentContainer;
