import { Grid } from '@material-ui/core';
import { number, object, func } from 'prop-types';

import UserButtonAvatar from './components/avatar/UserButtonAvatar';
import DashboardButton from './components/dashboard/DashboardButton';
import MemberButton from './components/member/MemberButton';

const UserButtons = (props) => {
  const { setOpenMenuBar, dashboardTab, handleChangeDashboardTab, project } = props;

  return (
    <>
      <Grid xs={4} item>
        <DashboardButton
          setOpenMenuBar={setOpenMenuBar}
          dashboardTab={dashboardTab}
          handleChangeDashboardTab={handleChangeDashboardTab}
        />
      </Grid>
      <Grid xs={4} item>
        <MemberButton project={project} setOpenMenuBar={setOpenMenuBar} />
      </Grid>
      <Grid xs={4} item container alignItems="flex-end" justify="flex-end">
        <Grid item>
          <UserButtonAvatar {...props} />
        </Grid>
      </Grid>
    </>
  );
};

UserButtons.propTypes = {
  dashboardTab: number.isRequired,
  handleChangeDashboardTab: func.isRequired,
  project: object.isRequired,
  setOpenMenuBar: func.isRequired,
};

export default UserButtons;
