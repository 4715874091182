import { TextField } from '@material-ui/core';
import _ from 'lodash';
import { object } from 'prop-types';

const FormikTextField = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <div>
    <TextField
      error={_.get(touched, field.name) && _.get(errors, field.name) && true}
      helperText={_.get(touched, field.name) && _.get(errors, field.name)}
      {...field}
      {...props}
    />
  </div>
);

FormikTextField.propTypes = {
  field: object.isRequired,
  form: object.isRequired,
};

FormikTextField.defaultProps = {};

export default FormikTextField;
