export default {
  addStepDialog: {
    stepNumber: 'Step number',
    helperText: 'Step number should greater than zero',
    name: 'Name',
  },
  addTopicDialog: {
    createNewTopic: 'Create New Topic',
    enterTopicName: 'Enter Topic name',
  },
  archiveCommentDialog: {
    deleteComment: 'Deleting this comment',
    reminder: 'Are you sure delete this comment?',
  },
  deactivateDialog: {
    reminder: 'Are you sure you want to',
    thisProject: 'this project?',
  },
  deleteDialog: {
    deleteThis: 'Deleting this',
    this: 'This',
    dialogContent:
      'will be permanently deleted from Tree Preview and Project Cover. Note that you can always unpublish a log by changing its publishing status.',
    reminder: 'o you wish to continue deleting this',
  },
  deleteBoardDialog: {
    deleteThisBoard: 'Deleting this board',
    reminder: 'Are you sure delete the board?',
  },
  deleteListDialog: {
    delete: 'Delete successfully',
    reminder: 'Are you sure you want to delete',
  },
  deleteProjectMemberDialog: {
    removeMember: 'Remove member or invitation from this project',
    reminder: 'Are you sure you want to remove',
    from: 'from this project ?',
  },
  deleteSectionTutorial: {
    deleteSection: 'Deleting this section',
    reminder: 'Are you sure delete this section?',
  },
  removeProjectFromList: {
    reminder: 'Are you sure you want to remove',
    projectFrom: 'project from',
  },
  updatePublishDialog: {
    publishLog: 'Publishing this log',
    reminderFirst: 'Are you sure you want to publish this log,',
    reminderSecond: '?',
  },
  openSnackbarSuccess: {
    step: 'Step',
    updated: 'updated',
    created: 'created',
    deleteStep: 'This step is deleted successfully!',
    successfully: 'successfully!',
    theLog: 'The log',
    theProject: 'The project',
    isUpdated: ' is updated successfully!',
    isDeleted: 'is deleted successfully!',
    isCreated: 'is created successfully!',
    isPublised: ' is published successfully!',
    invitationAccepted: 'Invitation accepted!',
    invitationRejected: 'Invitation rejected!',
    topicCreated: 'Topic created successfully!',
    commentDeleted: 'This comment is deleted successfully!',
    isReactivate: 'is reactivated successfully!',
    isDeactivate: 'is deactivated successfully!',
    isDeleted: 'is deleted successfully!',
    deleteListDialog: 'deleted successfully!',
    deletedboard: 'This board is deleted successfully!',
    deleteMember: 'Delete member successfully!',
    deleteSection: 'This section is deleted successfully!',
    profileUpdated: 'Your profile is updated successfully!',
    contestAdded: 'Contest added successfully!',
    experienceUpdated: 'Experience updated successfully!',
    contestDeleted: 'Contest deleted.',
    experienceAdded: 'Experience added successfully!',
    memberInvited: 'Invite members successfully!',
    projectAdded: 'Project added to list(s) successfully!',
    projectAddedToLists: 'Project(s) added to ',
    projectRemoved: 'Project removed from list',
    contactSent: 'Successfully sent!',
    unfollowedProject: 'This project is unfollowed!',
    followedProject: 'This project is followed!',
    likeProject: 'Project Liked!',
    unlikeProject: 'Project unliked',
    experienceDeleted: 'Experience deleted.',
  },
  openSnackbarError: {
    imageDeleted: 'Image is bigger than 5MB',
    linkedin: 'LinkedIn link is not a valid URL',
    github: 'GitHub link is not a valid URL',
    facebook: 'Facebook link is not a valid URL',
    twitter: 'Twitter link is not a valid URL',
    addExperience: 'Failed to add experience:',
    updateExperience: 'Failed to update experience: ',
    deleteExperience: 'Failed to delete experience: ',
    contestDeleted: 'Failed to delete contest: ',
    textLength: 'The text length cannot exceed the limit ',
    somethingWrong: 'Something went wrong',
    notUrl: 'This is not a URL. Please contact owner.',
    limitCharacterProject: 'The project description should not exceed 200 characters!',
    limitCharacterLog: 'The log name should not exceed 75 characters!',
    limitCharacterProjectName: 'The project name should not exceed 50 characters!',
  },
  openSnackbarWarning: {
    projectNameEmpty: 'The project name is empty!',
    projectDescriptionEmpty: 'The project description is empty!',
    projectOverview: 'The project overview is empty!',
    reminderLoginFeature: 'Please login to use this feature',
    reminderLoginDownload: 'Please login to download',
    finishWork: 'You need to finish your work at current log first! (Editting)',
    logNameEmpty: 'The log name is empty!',
    logDescriptionEmpty: 'The log description is empty!',
  },
};
