import { gql } from '@apollo/client';

// done
// updated
export const CREATE_USER_CONTEST = gql`
  mutation createUserContest($contest: NewUserContestInput!) {
    createUserContest(contest: $contest) {
      id
    }
  }
`;

// done
// updated
export const UPDATE_USER_CONTEST = gql`
  mutation updateUserContest($contest: UpdateUserContestInput!) {
    updateUserContest(contest: $contest) {
      status
      description
    }
  }
`;

// done
// updated
export const DELETE_USER_CONTEST = gql`
  mutation deleteUserContest($contest: DeleteUserContestInput!) {
    deleteUserContest(contest: $contest) {
      status
      description
    }
  }
`;
