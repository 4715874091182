import { makeStyles, Link, IconButton, CircularProgress, Checkbox } from '@material-ui/core';
import { common, grey } from '@material-ui/core/colors';
import {
  GetAppOutlined as GetAppOutlinedIcon,
  ZoomOutMapOutlined as ZoomOutMapOutlinedIcon,
  BrokenImage as BrokenImageIcon,
  Cancel as CancelIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import { number, string, bool, node, func, object, oneOfType } from 'prop-types';
import { useEffect, useState, useRef } from 'react';

const useStyles = (styles) =>
  makeStyles((theme) => ({
    root: {
      ...styles.root,
    },
    image: { ...styles.image },
    hoverable: {
      '&:hover $overlay': {
        opacity: 1,
      },
    },
    overlay: {
      ...styles.overlay,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      opacity: 0,
      position: 'absolute',
      zIndex: 10,
      backgroundColor: 'rgba(0,0,0,0.3)',
      '& button, & a': {
        border: '2px solid #fff',
        color: theme.palette.common.white,
        cursor: 'default',
        margin: theme.spacing(0, 0.5),

        '&:hover': {
          color: theme.palette.secondary.main,
          borderColor: theme.palette.secondary.main,
        },
      },
    },
    notSrc: {
      ...styles.notSrc,
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      pointerEvents: 'none',
    },
  }));

export default function Image({
  src,
  animationDuration,
  aspectRatio,
  color,
  disableError,
  disableSpinner,
  disableTransition,
  errorIcon,
  imageStyle,
  style,
  loading,
  onClick,
  alt,
  height,
  width,
  hasZoom,
  onZoomCLick,
  hasDownload,
  onDownloadClick,
  isHoverable,
  hasDelete,
  onClickDelete,
  checked,
  position,
  ...image
}) {
  const ref = useRef();
  const [innerSrc, setInnerSrc] = useState(src);
  const [imageError, setImageError] = useState(false);
  // const [imageLoaded, setImageLoaded] = useState(false);
  const [dimensions, setDimensions] = useState({
    height: undefined,
    width: undefined,
  });

  // useIntersectionObserver({
  //   target: ref,
  //   onIntersect: ([{ isIntersecting }], observerElement) => {
  //     if (isIntersecting) {
  //       setImageLoaded(true);
  //       observerElement.unobserve(ref.current);
  //     }
  //   },
  // });

  useEffect(() => {
    setImageError(false);
    // setImageLoaded(false);
    if (innerSrc === null) {
      setInnerSrc(src);
    }
  }, [innerSrc]);

  useEffect(() => {
    setInnerSrc(null);
  }, [src]);

  const getStyles = () => {
    const imageTransition = !disableTransition && {
      // opacity: imageLoaded ? 1 : 0,
      // filterBrightness: imageLoaded ? 100 : 0,
      // filterSaturate: imageLoaded ? 100 : 20,
      transition: `
        filterBrightness ${animationDuration * 0.75}ms cubic-bezier(0.4, 0.0, 0.2, 1),
        filterSaturate ${animationDuration}ms cubic-bezier(0.4, 0.0, 0.2, 1),
        opacity ${animationDuration / 2}ms cubic-bezier(0.4, 0.0, 0.2, 1)`,
    };

    const styles = {
      root: {
        backgroundColor: color,
        cursor: onClick !== 'none' && 'pointer',
        paddingTop: aspectRatio && `calc(1 / ${aspectRatio} * 100%)`,
        position,
        overflow: 'hidden',
        ...style,
      },
      image: {
        width: dimensions.width && dimensions.width,
        height: dimensions.height && dimensions.height,
        position: 'absolute',
        top: '50%',
        left: '50%',
        // width: 'auto',
        // height: '100%',
        transform: 'translate(-50%,-50%)',
        ...imageTransition,
        ...imageStyle,
      },
      overlay: {
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
      },
      notSrc: {
        height: '100%',
        width: '100%',
      },
      height,
      width,
    };

    return styles;
  };

  const handleLoadImage = ({ target: img }) => {
    // setImageLoaded(true);
    const { offsetHeight, offsetWidth } = img;

    let innerWidth = offsetWidth;
    let innerHeight = offsetHeight;

    if (aspectRatio === 1 && (!height || !width)) {
      innerWidth = offsetWidth >= offsetHeight ? 'auto' : '100%';
      innerHeight = offsetHeight > offsetWidth ? 'auto' : '100%';
    }

    if (!aspectRatio) {
      innerWidth = '100%';
      innerHeight = undefined;
      if (height) {
        innerHeight = height;
      }
      if (width) {
        innerWidth = width;
      }
    }

    setDimensions({ height: innerHeight, width: innerWidth });
  };

  const handleImageError = () => {
    if (innerSrc) {
      setImageError(true);
    }
  };

  const handleDownload = (e) => {
    if (e) e.stopPropagation();
    if (onDownloadClick) {
      onDownloadClick();
    }
  };

  const handleDelete = () => {
    onClickDelete(src);
  };

  const classes = useStyles(getStyles())();
  return (
    <div ref={ref} role="button" onClick={onClick} className={clsx(classes.root, { [classes.hoverable]: isHoverable })}>
      {innerSrc && (
        <>
          <img
            {...image}
            src={innerSrc}
            alt={alt}
            className={classes.image}
            onLoad={handleLoadImage}
            onError={handleImageError}
          />
          {checked && <Checkbox checked={checked} />}
          <div className={classes.overlay}>
            {hasZoom && (
              <IconButton aria-label="zoom" onClick={onZoomCLick}>
                <ZoomOutMapOutlinedIcon fontSize="small" />
              </IconButton>
            )}
            {hasDownload && (
              <IconButton
                href={innerSrc}
                target="_blank"
                download={alt}
                component={Link}
                title={alt}
                aria-label="download"
                onClick={handleDownload}
              >
                <GetAppOutlinedIcon fontSize="small" />
              </IconButton>
            )}
            {hasDelete && (
              <IconButton title={alt} aria-label="delete" onClick={handleDelete}>
                <CancelIcon fontSize="small" />
              </IconButton>
            )}
          </div>
        </>
      )}
      <div className={classes.notSrc}>
        {!disableSpinner &&
          // && !imageLoaded
          !imageError &&
          loading}
        {!disableError && imageError && errorIcon}
      </div>
    </div>
  );
}

Image.propTypes = {
  checked: bool,
  animationDuration: number,
  aspectRatio: number,
  color: string,
  disableError: bool,
  disableSpinner: bool,
  disableTransition: bool,
  errorIcon: node,
  loading: node,
  onClick: func,
  src: string,
  alt: string.isRequired,
  height: oneOfType([number, string]),
  width: oneOfType([number, string]),
  /** Override the inline-styles of the image. */
  imageStyle: object,
  /** Override the inline-styles of the root element. */
  style: object,
  hasZoom: bool,
  hasDownload: bool,
  onZoomCLick: func,
  onDownloadClick: func,
  isHoverable: bool,
  hasDelete: bool,
  onClickDelete: func,
  position: string,
};

Image.defaultProps = {
  src: null,
  checked: undefined,
  animationDuration: 500,
  aspectRatio: 1,
  color: common.white,
  disableError: false,
  disableSpinner: true,
  disableTransition: false,
  errorIcon: <BrokenImageIcon style={{ width: 48, height: 48, color: grey[300] }} />,
  loading: <CircularProgress size={48} />,
  onClick: undefined,
  style: {},
  imageStyle: {},
  height: undefined,
  width: undefined,
  hasZoom: false,
  hasDownload: false,
  hasDelete: false,
  onZoomCLick: undefined,
  onDownloadClick: undefined,
  isHoverable: false,
  onClickDelete: undefined,
  position: 'relative',
};
