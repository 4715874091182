import { makeVar } from '@apollo/client';
import { useQuery, gql } from '@apollo/client';
export const dialogsInitialValue = {
  isDialogOpen: false,
  typeDialog: undefined,
  optionsDialog: {},
};

export const dialogsVar = makeVar(dialogsInitialValue);

const GET_CART_ITEMS = gql`
  query GetDialogData {
    dialogData @client
  }
`;
export function handleOpenDialogAC3(type, options) {
  const data = {
    isDialogOpen: true,
    typeDialog: type,
    optionsDialog: options,
  };
  dialogsVar(data);
}
export function closeDialogAC3() {
  dialogsVar(dialogsInitialValue);
}
