import { Box, Typography, Divider } from '@material-ui/core';
import { string, bool } from 'prop-types';

const AboutTitle = ({ title, desktop }) => {
  return (
    <Box mb={desktop ? 3 : 2}>
      <Typography variant={desktop ? 'h1' : 'h4'} color="primary">
        {title}
      </Typography>
      <Divider />
    </Box>
  );
};

AboutTitle.propTypes = {
  title: string.isRequired,
  desktop: bool.isRequired,
};

export default AboutTitle;
