import { useScrollTrigger, Zoom, makeStyles } from '@material-ui/core';
import { element } from 'prop-types';

import backToAnchor from '../helpers/backToAnchor';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const BackTop = (props) => {
  const { children } = props;
  const classes = useStyles();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    backToAnchor(event.target.ownerDocument || document);
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
};

BackTop.propTypes = {
  children: element.isRequired,
};

export default BackTop;
