import { Card, CardMedia, CardContent, Typography, Box, makeStyles } from '@material-ui/core';
import { string, arrayOf, bool } from 'prop-types';

import { Markdown } from 'components';

const useStyles = makeStyles((theme) => ({
  card: {
    textAlign: 'center',
    height: '99%',
  },
  media: {
    height: 239,
    backgroundSize: 'contain',
    [theme.breakpoints.down('sm')]: {
      height: 128,
    },
  },
  description: {
    '& ul': {
      paddingInlineStart: '16px',
    },
    '& span': {
      color: theme.palette.text.primary,
      [theme.breakpoints.down('sm')]: {
        ...theme.typography.body2,
      },
      '&.saleoff': {
        ...theme.typography.h6,
        padding: theme.spacing(0.2, 1),
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 6,
        color: theme.palette.common.white,
        [theme.breakpoints.down('sm')]: {
          fontSize: 14,
        },
      },
    },
  },
  off: {
    color: 'black',
  },
}));

const AboutBlurb = ({ src, title, subtitle, desktop }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardMedia className={classes.media} image={src} title={title} />
      <CardContent>
        <Typography gutterBottom variant={desktop ? 'h5' : 'h6'} color="primary">
          {title}
        </Typography>
        <Box textAlign="left" className={classes.description}>
          {subtitle && subtitle.map((s, i) => <Markdown key={i}>{s}</Markdown>)}
        </Box>
      </CardContent>
    </Card>
  );
};

AboutBlurb.propTypes = {
  src: string.isRequired,
  title: string.isRequired,
  subtitle: arrayOf(string).isRequired,
  desktop: bool.isRequired,
};

export default AboutBlurb;
