import { Avatar, Divider, Grid, Typography, makeStyles } from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import _ from 'lodash';
import { arrayOf, bool, number, object } from 'prop-types';
import { Link } from 'react-router-dom';

import { convertToSlug, getDisplayName } from 'helpers/common';

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: theme.spacing(1, 0),
  },
  gridItem: {
    padding: theme.spacing(1, 0),
    '&:hover': {
      textDecoration: 'underline',
    },
    [theme.breakpoints.down('sm')]: {
      '& *': {
        fontSize: 14,
      },
    },
  },
  certifiedIcon: {
    color: '#fff',
    backgroundColor: '#FAC405',
    borderRadius: '50%',
    height: '16px',
    width: '16px',
    verticalAlign: 'middle',
  },
  nbMembers: {
    color: theme.palette.secondary.main,
  },
  avatar: {
    marginRight: theme.spacing(2.25),
    width: theme.spacing(4),
    height: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1.5),
    },
  },
  divider: {
    marginBottom: theme.spacing(1.5),
    backgroundColor: '#ababab',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
    },
  },
  button: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(0.5, 4),
  },
  placeholder: {
    display: 'inline-block',
  },
}));

const TeamsDetails = ({ teams, teamActive, desktop }) => {
  const classes = useStyles();

  const renderTeamSize = (members) =>
    _.isEmpty(members) ? (
      <Skeleton component="span" variant="text" width={40} className={classes.placeholder} />
    ) : (
      members.length
    );

  return (
    <Grid container spacing={4}>
      {_.map(teams[teamActive].teams, (t) => (
        <Grid key={t.id} item xs={desktop ? 6 : 12} className={classes.grid}>
          <Typography variant={desktop ? 'h5' : 'h6'} color="textSecondary" gutterBottom>
            {t.name} <span className={classes.nbMembers}>({renderTeamSize(t.members)})</span>
          </Typography>
          <Divider className={classes.divider} />
          {_.map(t.members, (m) => (
            <Grid
              component={Link}
              key={getDisplayName(m)}
              container
              alignItems="center"
              className={classes.gridItem}
              to={`/users/${m.id}/${convertToSlug(`${m.firstName} ${m.lastName}`)}/profile`}
            >
              <Grid item>
                <Avatar src={m.avatarLink} alt={getDisplayName(m)} className={classes.avatar} />
              </Grid>
              <Grid item>
                <Typography variant="h6" style={{ color: '#575757' }}>
                  {getDisplayName(m)} {m.certified && <CheckIcon className={classes.certifiedIcon} />}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      ))}
    </Grid>
  );
};

TeamsDetails.propTypes = {
  teams: arrayOf(object).isRequired,
  teamActive: number.isRequired,
  desktop: bool.isRequired,
};

export default TeamsDetails;
