import { bool, func, object } from 'prop-types';

import Members from './Members';

const MemberList = ({ project, onClickRemove, desktop }) => (
  <Members project={project.project} onClickRemove={onClickRemove} desktop={desktop} />
);

MemberList.propTypes = {
  project: object,
  onClickRemove: func,
  desktop: bool,
};

export default MemberList;
