import awsApi from '../aws';
import fileApi from '../file';

export default {
  uploadUpdateAttachment: async (urlInfo, file) => {
    const { name, size, type } = file;
    const { data } = await fileApi.getPreSignedUrl(urlInfo);
    await awsApi.upload(data.url, file, type);

    return {
      name,
      size,
      assetLink: data.key,
      assetType: type,
      updateId: +urlInfo.updateId,
    };
  },
};
