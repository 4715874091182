const TimelineSvgActive = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_dd)">
      <rect x="4" y="3" width="32" height="32" rx="4" fill="#384885" />
    </g>
    <rect
      x="8.80029"
      y="14.2002"
      width="5.33333"
      height="13.8667"
      rx="1"
      transform="rotate(-90 8.80029 14.2002)"
      fill="white"
    />
    <rect
      x="13.0669"
      y="21.6666"
      width="5.33333"
      height="13.8667"
      rx="1"
      transform="rotate(-90 13.0669 21.6666)"
      fill="white"
    />
    <rect
      x="16.2671"
      y="29.1332"
      width="5.33333"
      height="13.8667"
      rx="1"
      transform="rotate(-90 16.2671 29.1332)"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_dd"
        x="0"
        y="0"
        width="40"
        height="40"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default TimelineSvgActive;
