import { bool, func, object, string } from 'prop-types';

import { UPDATE_USER_AVATAR } from 'apollo/mutations/user-mutation';
import { GET_AVATAR_PRESIGNED_URL } from 'apollo/queries/user-query';
import { DIALOG } from 'helpers/constants';
import { getFileLink } from 'helpers/file';
import { handleOpenDialogAC3 } from 'reducers/testAC3/cache';

import ProfileHeaderAvatar from './ProfileHeaderAvatar';

const ProfileHeaderAvatarContainer = ({ desktop, profile, avatar, refetchProfile }) => {
  const handleProfilePictureChange = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      const options = {
        profileId: +profile.id,
        getVariable: (uploadImage) => {
          return new Promise((resolve) => {
            resolve({
              user: {
                avatarLink: getFileLink(uploadImage, profile.avatarLink),
              },
            });
          });
        },
        presignedUrl: 'avatarPresignedUrl',
        mutation: UPDATE_USER_AVATAR,
        query: GET_AVATAR_PRESIGNED_URL,
        file,
        refetchProfile: () => refetchProfile(),
        round: true,
        aspect: 1,
        success: 'Profile picture updated successfully!',
      };
      handleOpenDialogAC3(DIALOG.CROP_IMAGE, options);
    }
  };

  return (
    <ProfileHeaderAvatar
      desktop={desktop}
      avatar={avatar}
      onProfilePictureChange={handleProfilePictureChange}
      userId={+profile.id}
    />
  );
};

ProfileHeaderAvatarContainer.propTypes = {
  profile: object.isRequired,
  avatar: string.isRequired,
  refetchProfile: func.isRequired,
  desktop: bool.isRequired,
};

export default ProfileHeaderAvatarContainer;
