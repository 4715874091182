import { bool } from 'prop-types';
import { useRouteMatch } from 'react-router-dom';

import CreateProjectTutorial from '../components/section/CreateProjectTutorial';

const withNewProject = (Component) => {
  const Wrapped = (props) => {
    const match = useRouteMatch();
    const { id } = match.params;

    return id === undefined ? <CreateProjectTutorial desktop={props.desktop} /> : <Component {...props} />;
  };

  Wrapped.propTypes = {
    desktop: bool.isRequired,
  };

  return Wrapped;
};

export default withNewProject;
