export const KEYS = {
  avatarLink: 'avatarLink',
};

export const getValue = (key) => localStorage.getItem(key);

export const setValue = (key, value) => {
  localStorage.setItem(key, value);
};

export const clearValue = (key) => {
  localStorage.removeItem(key);
};
