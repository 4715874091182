import { Stepper, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { arrayOf, object, number } from 'prop-types';

import LogsListItem from './LogsListItem';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  stepper: {
    padding: theme.spacing(2, 0),
  },
}));

const LogsList = ({ logs, logIdsSelected, ...props }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {!_.isEmpty(logs) && (
        <Stepper orientation="vertical" className={classes.stepper}>
          {_.map(logs, (log) => (
            <LogsListItem key={+log.id} log={log} isActive={logIdsSelected.includes(+log.id)} {...props} />
          ))}
        </Stepper>
      )}
    </div>
  );
};

LogsList.propTypes = {
  logs: arrayOf(object),
  logIdsSelected: arrayOf(number),
};

LogsList.defaultProps = {
  logs: [],
  logIdsSelected: [],
};

export default LogsList;
