import { Alert } from '@material-ui/lab';

const withError = (Component) => {
  const wrapped = ({ error, ...props }) =>
    error ? (
      <Alert variant="outlined" severity="error">
        {error.message}
      </Alert>
    ) : (
      <Component {...props} />
    );

  return wrapped;
};

export default withError;
