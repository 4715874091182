import { Typography, Container, Grid, useMediaQuery, useTheme, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { object } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Truncate } from 'components';
import { cookieUserId } from 'helpers/auth';
import { hex2rgba } from 'helpers/common';

import { LandingBanner } from '@projectcocoon/web-component';
import Banner from './Banner';
import bg from './bg.png';

const FADE = 0.7;

const useStyles = makeStyles((theme) => ({
  want: {
    backgroundColor: 'rgba(208, 201, 201, 0.12)',
  },
  container: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  bannerContent: {},
  categoryContent: {
    backgroundColor: theme.palette.background.paper,
    backgroundSize: 'cover',
    backgroundImage: `url(${bg})`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: 250,
    [theme.breakpoints.down('sm')]: {
      height: 100,
    },
  },
  button: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    marginRight: theme.spacing(2),
    padding: theme.spacing(0.25, 1.5),
    textTransform: 'none',
    borderRadius: '6px',
    borderWidth: '1px',
    '&:hover': {
      '& *': {
        fontWeight: 'bold',
      },
    },
  },
  btnActions: {
    marginBottom: theme.spacing(2),
    '& > div:nth-child(2)': {
      textAlign: 'right',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
      padding: 0,
    },
  },
  spanText: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    padding: theme.spacing(0, 1),
    borderRadius: 6,
  },
}));

const Hero = ({ categoryChosen }) => {
  const classes = useStyles();
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const theme = useTheme();
  const yellow = hex2rgba(theme.palette.secondary.main, FADE);
  const blue = hex2rgba(theme.palette.primary.main, FADE);
  const green = hex2rgba(theme.palette.success.main, FADE);
  const red = hex2rgba(theme.palette.error.main, FADE);

  const { t } = useTranslation();

  return (
    <div
      className={clsx({
        [classes.bannerContent]: !categoryChosen,
        [classes.categoryContent]: categoryChosen,
      })}
      style={
        categoryChosen && {
          boxShadow: `inset 0 0 0 2000px ${hex2rgba(categoryChosen.displayColor, 0.5)}`,
        }
      }
    >
      <Container maxWidth={false}>
        {!categoryChosen ? (
          <LandingBanner
            title={t('home.allAboutProject')}
            description={t('home.heroBoxDescription')}
            subDescription={t('home.heroSpanDescription')}
            botsDisplay={false}
            botsColor={{
              bot1BackgroundColor: yellow,
              bot2BackgroundColor: blue,
              bot3BackgroundColor: green,
              bot4BackgroundColor: red,
              bot5BackgroundColor: yellow,
              bot6BackgroundColor: green,
              bot7BackgroundColor: blue,
              bot8BackgroundColor: red,
              bot9BackgroundColor: yellow,
              bot10BackgroundColor: blue,
              bot11BackgroundColor: green,
              bot12BackgroundColor: red,
            }}
          />
        ) : (
          <Container
            component={Grid}
            container
            direction="row"
            justify="space-between"
            alignItems="flex-end"
            className={classes.btnActions}
          >
            <Grid item xs>
              <Typography variant={desktop ? 'h2' : 'h4'} align="left" style={{ color: '#fff' }}>
                <Truncate className={classes.spanText} width={desktop ? 0 : 300}>
                  {categoryChosen.name}
                </Truncate>
              </Typography>
            </Grid>
          </Container>
        )}
      </Container>
    </div>
  );
};

Hero.propTypes = { categoryChosen: object };

export default Hero;
