import { Button, Grid, Typography, Tooltip, makeStyles } from '@material-ui/core';
import { number, func } from 'prop-types';

import { cookieUserId } from 'helpers/auth';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles((theme) => ({
  btnInteract: {
    textTransform: 'none',
    padding: theme.spacing(0, 1),
    '&:hover': {
      background: 'transparent',
      '& *': {
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.secondary.main,
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& *': {
        fontSize: 12,
      },
    },
  },
}));

const ButtonReply = ({ replyCount, handleClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid item>
      <Tooltip title={cookieUserId ? '' : t('sign.signInReminder')}>
        <Button className={classes.btnInteract} onClick={handleClick}>
          <Typography variant="body1" color="textSecondary">
            <span style={{ marginRight: '6px' }}>{replyCount}</span>
            {replyCount > 1 ? t('common.replies') : t('common.reply')}
          </Typography>
        </Button>
      </Tooltip>
    </Grid>
  );
};

ButtonReply.propTypes = {
  handleClick: func,
  replyCount: number,
};

export default ButtonReply;
