import { Avatar, Grid, IconButton, Typography } from '@material-ui/core';
import { Check as CheckIcon, Close as CloseIcon } from '@material-ui/icons';
import _ from 'lodash';
import { arrayOf, bool, func, object } from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useStyles from '../styles/MembersStyle';

const Members = ({ membersInvitation, onClickRemove, desktop }) => {
  const classes = useStyles();

  const [members, setMembers] = useState([]);

  const { t } = useTranslation('dialog');

  useEffect(() => {
    if (membersInvitation) {
      if (_.isEmpty(membersInvitation)) {
        setMembers([]);
      } else {
        membersInvitation.map((member) => {
          if (!members.filter((e) => +e.user.id === +member.user.id).length > 0) {
            setMembers((members) => [...members, member]);
          }
        });
      }
    }
  }, [membersInvitation]);

  const mobileMember = (member, info, index) => {
    return (
      <Grid
        item
        xs={12}
        container
        direction="row"
        justify="center"
        alignItems="center"
        key={index}
        className={classes.memberSpace}
      >
        <Grid xs={2} item>
          <Avatar alt={info.username} src={info.avatarLink} style={{ marginBottom: 0 }} className={classes.small} />
        </Grid>
        <Grid xs={7} container direction="column" justify="flex-start" alignItems="flex-start" item>
          <Grid xs={12} item container direction="row" justify="center" alignItems="center">
            <Grid item xs={8}>
              <Typography className={classes.memberTitle}>
                {info.username}
                {info.certified && <CheckIcon className={classes.certifiedIcon} />}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              {_.toLower(member.status) === 'pending' ? (
                <Typography className={classes.statusStyle}>Sent</Typography>
              ) : (
                _.toLower(member.status) !== 'approved' && (
                  <Typography className={classes.statusStyle} color="error">
                    Declined
                  </Typography>
                )
              )}
            </Grid>
          </Grid>
          <Grid xs={12} item>
            <Typography>{info.email}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={3} container direction="row" justify="flex-end" alignItems="center">
          <IconButton
            onClick={(e) => onClickRemove(e, { ...info, status: member.status })}
            color="primary"
            aria-label="delete"
            size="small"
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  const desktopMember = (member, info, index) => {
    return (
      <Grid
        item
        xs={12}
        container
        direction="row"
        justify="center"
        alignItems="center"
        key={index}
        className={classes.memberSpace}
      >
        <Grid xs={1} item>
          <Avatar alt={info.username} src={info.avatarLink} style={{ marginBottom: 0 }} className={classes.small} />
        </Grid>
        <Grid xs={4} item>
          <Typography className={classes.memberTitle}>
            {info.username} {info.certified && <CheckIcon className={classes.certifiedIcon} />}
          </Typography>
        </Grid>
        <Grid xs={5} item>
          <Typography>{info.email}</Typography>
        </Grid>
        <Grid xs item justify="flex-end" alignItems="flex-end" container spacing={2}>
          <Grid item xs={6} container direction="row" justify="center" alignItems="center">
            {_.toLower(member.status) === 'pending' ? (
              <Typography>{t('addMember.sent')}</Typography>
            ) : (
              _.toLower(member.status) !== 'approved' && <Typography color="error">{t('addMember.members')}</Typography>
            )}
          </Grid>
          <Grid item xs={6} container direction="row" justify="flex-end" alignItems="center">
            <IconButton
              onClick={(e) => onClickRemove(e, { ...info, status: member.status })}
              color="primary"
              aria-label="delete"
              size="small"
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <div className={classes.memberWrapper}>
        <Typography className={classes.memberTitle}>Members</Typography>
        <Grid container direction="column" justify="center" alignItems="center" className={classes.membersSpace}>
          {members.map((member, index) => {
            const info = member.user;
            return desktop ? desktopMember(member, info, index) : mobileMember(member, info, index);
          })}
        </Grid>
      </div>
    </>
  );
};

Members.propTypes = {
  membersInvitation: arrayOf(object),
  onClickRemove: func,
  desktop: bool,
};

export default Members;
