import { Grid, Box } from '@material-ui/core';
import _ from 'lodash';
import { bool, object, string } from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Tags } from 'components';
import { PROFILE_TYPES } from 'helpers/constants';

import Bio from './bio/Bio';
import Contests from './contests/Contests';
import EmptyField from './empty-field/EmptyField';
import Experiences from './experience/Experiences';
import Members from './members/Members';
import ProfileCategory from './profile-category/ProfileCategory';
import SocialLinks from './social-links/SocialLinks';

const ProfileDescription = ({ desktop, profile, profileType }) => {
  const isUserProfile = profileType === PROFILE_TYPES.USER_PROFILE;
  const [fieldsOfWork, setFieldsOfWork] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    if (profile.fieldsOfWork) {
      setFieldsOfWork(
        profile.fieldsOfWork.map((str, index) => ({
          id: index,
          fieldName: str?.fieldName || str,
        })),
      );
    }
  }, []);

  return (
    <Grid
      item
      xs={12}
      style={{
        minHeight: '400px',
        display: 'flex',
        flexDirection: 'column',
        padding: desktop ? '0 24px 0 0' : '0 16px',
      }}
    >
      {!desktop && (
        <Box flex={1} mb={1.5}>
          <ProfileCategory categoryName={isUserProfile ? t('common.description') : 'Company Description'} />
          <hr style={{ marginBottom: 10 }} />
          <Bio bio={profile.description} />
        </Box>
      )}
      <Box flex={1} mb={1.5}>
        <ProfileCategory categoryName={isUserProfile ? t('common.bioUpper') : 'Company Details'} />
        <hr style={{ marginBottom: 10 }} />
        <Bio bio={profile.fullBio} />
        {desktop && <Box mb={1} />}
        <SocialLinks
          github={profile.github}
          linkedin={profile.linkedin}
          facebook={profile.facebook}
          twitter={profile.twitter}
        />
      </Box>
      <Box flex={1} mb={1.5}>
        <ProfileCategory categoryName={t('common.fieldsOfWork')} />
        <hr style={{ marginBottom: 10 }} />
        {_.isEmpty(fieldsOfWork) ? (
          <EmptyField field={t('common.emptyField')} />
        ) : (
          <Tags isChips isLightView label="fieldName" tags={fieldsOfWork} />
        )}
      </Box>
      {isUserProfile ? (
        <>
          <Box flex={1} mb={1.5}>
            <ProfileCategory categoryName={t('common.experience')} />
            <hr style={{ marginBottom: 10 }} />
            <Experiences experiences={profile.experiences} />
          </Box>
          <Box flex={1} mb={1.5}>
            <ProfileCategory categoryName={t('common.contests')} />
            <hr style={{ marginBottom: 10 }} />
            <Contests contests={profile.contests} />
          </Box>
        </>
      ) : (
        <Box flex={1} mb={1.5}>
          <ProfileCategory categoryName="Members" />
          <hr style={{ marginBottom: 10 }} />
          <Members members={profile.members} />
        </Box>
      )}
    </Grid>
  );
};

ProfileDescription.propTypes = {
  desktop: bool.isRequired,
  profile: object.isRequired,
  profileType: string.isRequired,
};

export default ProfileDescription;
