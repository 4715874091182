import { func, object, number, bool, arrayOf } from 'prop-types';

import Timeline from './components/timeline/Timeline';

const FilterCondition = ({
  dashboardTab,
  project,
  handleChangeChosenUpdate,
  handleStatusNewLog,
  isNewLog,
  handleDeleteUpdate,
  isReload,
  reloadComponent,
  updateLogs,
  deleteNewLog,
  isPublished,
  setIsShowAutoSave,
}) => {
  return dashboardTab === 0 ? (
    <Timeline
      project={project}
      handleChangeChosenUpdate={handleChangeChosenUpdate}
      handleStatusNewLog={handleStatusNewLog}
      isNewLog={isNewLog}
      handleDeleteUpdate={handleDeleteUpdate}
      isReload={isReload}
      reloadComponent={reloadComponent}
      updateLogs={updateLogs}
      deleteNewLog={deleteNewLog}
      isPublished={isPublished}
      setIsShowAutoSave={setIsShowAutoSave}
    />
  ) : (
    <div />
    // <Filter />
  );
};

FilterCondition.propTypes = {
  dashboardTab: number.isRequired,
  project: object.isRequired,
  handleChangeChosenUpdate: func.isRequired,
  handleStatusNewLog: func.isRequired,
  isNewLog: bool.isRequired,
  handleDeleteUpdate: func.isRequired,
  isReload: bool,
  reloadComponent: func,
  updateLogs: arrayOf(object),
  deleteNewLog: func,
  isPublished: bool,
  setIsShowAutoSave: func,
};

export default FilterCondition;
