import { useMutation } from '@apollo/client';
import { Button, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Box } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import _ from 'lodash';
import { object, bool, func } from 'prop-types';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { UPDATE_USER } from 'apollo/mutations/user-mutation';
import { cookieUserId } from 'helpers/auth';
import { isValidUrl } from 'helpers/url';

import EditMore from './components/edit-more/EditMore';
import ProfileInfo from './components/profile-info/ProfileInfo';
import useStyles from './editProfileDialogStyle';

const EditProfileDialog = ({ open, options, desktop, openSnackbarSuccess, openSnackbarError, handleCloseDialog }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();

  const userId = +id || cookieUserId;
  const { profile } = options;

  const [editProfile, setEditProfile] = useState({});
  const [editMore, setEditMore] = useState(false);
  const { watch, control, setValue, register, handleSubmit } = useForm({
    defaultValues: profile,
  });

  const [updateUser] = useMutation(UPDATE_USER);

  const { t } = useTranslation(['common', 'dialog']);

  useEffect(() => {
    setEditProfile(profile);
  }, [options]);

  const handleGoBack = () => {
    if (editMore) {
      setEditMore(false);
    } else {
      handleCloseDialog();
    }
  };

  const handleUpdateProfile = async ({
    firstName,
    lastName,
    gender,
    dob,
    country,
    state,
    fullBio,
    description,
    primaryJob,
    linkedin,
    facebook,
    twitter,
    github,
  }) => {
    const tempFields = [];

    if (!_.isEmpty(linkedin) && !isValidUrl(linkedin)) {
      openSnackbarError(t('dialog:openSnackbarError.linkedin'));
      return;
    }
    if (!_.isEmpty(github) && !isValidUrl(github)) {
      openSnackbarError(t('dialog:openSnackbarError.github'));
      return;
    }
    if (!_.isEmpty(facebook) && !isValidUrl(facebook)) {
      openSnackbarError(t('dialog:openSnackbarError.facebook'));
      return;
    }
    if (!_.isEmpty(twitter) && !isValidUrl(twitter)) {
      openSnackbarError(t('dialog:openSnackbarError.twitter'));
      return;
    }

    editProfile.fieldsOfWork.forEach((field) => {
      tempFields.push(field?.fieldName);
    });

    const editMoreVariables = editMore
      ? {
          firstName: firstName,
          lastName: lastName,
          gender: gender || 'male',
          dob: dob,
          countryId: country && +country.id,
          stateId: state && +state.id,
          linkedin: linkedin,
          github: github,
          facebook: facebook,
          twitter: twitter,
        }
      : {
          ...editProfile,
          countryId: editProfile.country && editProfile.country.id,
          stateId: editProfile.state && editProfile.state.id,
        };

    const { data: dataEditUser } = await updateUser({
      variables: {
        user: {
          id: userId,
          firstName: editMoreVariables.firstName,
          lastName: editMoreVariables.lastName,
          gender: editMoreVariables.gender?.toLowerCase(),
          dob: editMoreVariables.dob,
          countryId: editMoreVariables.countryId,
          stateId: editMoreVariables.stateId,
          description: description,
          primaryJob: primaryJob,
          fullBio: fullBio,
          linkedin: editMoreVariables.linkedin,
          github: editMoreVariables.github,
          facebook: editMoreVariables.facebook,
          twitter: editMoreVariables.twitter,
          fieldsOfWork: tempFields,
        },
      },
    });

    if (dataEditUser.updateUser) {
      handleCloseDialog();
      openSnackbarSuccess(t('dialog:openSnackbarSuccess.profileUpdated'));
      history.push({
        pathname: location.pathname,
        state: { isRefetchProfile: true },
      });
    }
  };

  const renderDialogActions = (
    <DialogActions>
      <Button onClick={() => handleGoBack()} variant="outlined" className={classes.button}>
        {t('common:button.back')}
      </Button>
      <Button autoFocus color="primary" variant="contained" type="submit" className={classes.button}>
        {t('common:button.save')}
      </Button>
    </DialogActions>
  );

  return open ? (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="edit-profile-dialog-title"
      aria-describedby="edit-profile-description"
      className={classes.dialog}
      maxWidth="md"
    >
      <form onSubmit={handleSubmit(handleUpdateProfile)}>
        <DialogTitle>
          <Grid container alignItems="center" alignContent="space-between">
            <Grid item xs>
              <Typography color="secondary" variant={desktop ? 'h5' : 'h6'}>
                <Box display="flex" alignItems="center" className={classes.header}>
                  <EditIcon className={classes.icon} />
                  {t('common:common.overview')}
                </Box>
              </Typography>
            </Grid>
            {desktop && (
              <Grid item xs>
                {renderDialogActions}
              </Grid>
            )}
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          {!editMore && <ProfileInfo desktop={desktop} editProfile={editProfile} setEditMore={setEditMore} />}
          <EditMore
            control={control}
            register={register}
            watch={watch}
            editProfile={editProfile}
            setEditProfile={setEditProfile}
            editMore={editMore}
            setValue={setValue}
            desktop={desktop}
          />
        </DialogContent>
        {!desktop && renderDialogActions}
      </form>
    </Dialog>
  ) : null;
};

EditProfileDialog.propTypes = {
  open: bool.isRequired,
  desktop: bool.isRequired,
  options: object.isRequired,
  openSnackbarError: func.isRequired,
  openSnackbarSuccess: func.isRequired,
  handleCloseDialog: func.isRequired,
};

export default EditProfileDialog;
