/* eslint-disable max-len */
const NavigationSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.9754 20.8177C18.0113 20.8165 19.0044 20.4045 19.7369 19.672C20.4694 18.9395 20.8815 17.946 20.8826 16.9101V12.9595H12.7266V20.8177L16.9754 20.8177Z"
      fill="#FAC405"
    />
    <path
      d="M20.8826 7.55685C20.8814 6.52101 20.4693 5.52796 19.7368 4.79554C19.0043 4.06313 18.0109 3.65116 16.975 3.65002H12.7266L12.7266 11.5082H20.8826V7.55685Z"
      fill="#FAC405"
    />
    <path
      d="M7.02443 3.65002C5.98865 3.65125 4.99523 4.06326 4.26283 4.79567C3.53042 5.52807 3.11842 6.52149 3.11719 7.55727V16.9105C3.11833 17.9463 3.53029 18.9394 4.2627 19.6719C4.99512 20.4044 5.98859 20.8164 7.02443 20.8177H11.2732V3.65002H7.02443Z"
      fill="#FAC405"
    />
  </svg>
);

export default NavigationSvg;
