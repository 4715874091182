import { Box, CircularProgress } from '@material-ui/core';
import { number, string } from 'prop-types';

const CircularCenterLoader = ({ height, label, color }) => (
  <Box height={height} width="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
    <CircularProgress color={color} />
    {label && (
      <Box mt={2} fontWeight={700}>
        {label}
      </Box>
    )}
  </Box>
);

CircularCenterLoader.propTypes = {
  height: number,
  color: string,
  label: string,
};

CircularCenterLoader.defaultProps = {
  height: 100,
  color: 'primary',
  label: undefined,
};

export default CircularCenterLoader;
