import { useMutation } from '@apollo/client';
import { bool, object, func } from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { ADD_PROJECT_TO_LISTS, ADD_PROJECTS_TO_LIST, CREATE_LIST } from 'apollo/mutations/list-mutation';

import ListProjectDialog from './ListProjectDialog';

const ListProjectDialogWrapper = ({
  open,
  options,
  openSnackbarSuccess,
  openSnackbarError,
  handleCloseDialog,
  handleCloseBackdrop,
  handleOpenBackdrop,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { list, projectId } = options;

  const [listWithoutProject, setListWithoutProject] = useState([]);
  const [selectedListIds, setSelectedListIds] = useState([]);
  const [selectedProjectIds, setSelectedProjectIds] = useState([]);
  const [listName, setListName] = useState('');
  const [newListId, setNewListId] = useState(null);
  const [step, setStep] = useState(0);

  const [createNewList] = useMutation(CREATE_LIST);
  const [addProjectsToList] = useMutation(ADD_PROJECTS_TO_LIST);
  const [addProjectToLists] = useMutation(ADD_PROJECT_TO_LISTS);

  const { t } = useTranslation(['common', 'dialog']);

  useEffect(() => {
    const a = [];

    list.forEach((x) => {
      if (x.projects.every((project) => project.id !== projectId)) {
        a.push(x);
      }
    });
    setListWithoutProject(a);
  }, [list]);

  const handleCheckList = (selectedListId) => {
    if (!selectedListIds.includes(selectedListId)) {
      setSelectedListIds([...selectedListIds, selectedListId]);
    } else {
      const filteredList = selectedListIds.filter((item) => item !== selectedListId);
      setSelectedListIds(filteredList);
    }
  };

  const handleCheckProject = (selectedProjectId) => {
    if (!selectedProjectIds.includes(selectedProjectId)) {
      setSelectedProjectIds([...selectedProjectIds, selectedProjectId]);
    } else {
      const filteredProjects = selectedProjectIds.filter((item) => item !== selectedProjectId);
      setSelectedProjectIds(filteredProjects);
    }
  };

  const disableSave = () => {
    if (step === 0 && selectedListIds.length === 0) {
      return true;
    }
    if (step === 1 && listName === '') {
      return true;
    }
    return step === 2 && selectedProjectIds.length === 0;
  };

  const handleContinueOrSave = async (add) => {
    if (step === 0) {
      if (add) {
        setStep(step + 1);
        setSelectedListIds([]);
      } else {
        try {
          handleOpenBackdrop();
          const addProjectToListsMutation = await addProjectToLists({
            variables: {
              projectLists: {
                projectId,
                listIds: selectedListIds,
              },
            },
          });
          const {
            data: { addProjectToLists: projectAddedToLists },
          } = addProjectToListsMutation;
          if (projectAddedToLists) {
            openSnackbarSuccess(`${t('dialog:openSnackbarSuccess.projectAdded')}`);
            handleCloseDialog();
            handleCloseBackdrop();
            history.push({
              pathname: location.pathname,
              state: { isRefetchLists: true },
            });
          }
        } catch (e) {
          openSnackbarError(e.message);
        }
      }
    } else if (step === 1) {
      setSelectedProjectIds([...selectedProjectIds, projectId]);
      try {
        handleOpenBackdrop();
        const createNewListMutation = await createNewList({
          variables: { list: { name: listName } },
        });
        const {
          data: { createList },
        } = createNewListMutation;
        if (createList) {
          handleCloseBackdrop();
          openSnackbarSuccess(`${createList.name} ${t('dialog:openSnackbarSuccess.isCreated')}`);
          history.push({
            pathname: location.pathname,
            state: { isRefetchLists: true },
          });
          setNewListId(createList.id);
          setStep(step + 1);
          setSelectedProjectIds([...selectedProjectIds, projectId]);
        }
      } catch (errorCreateList) {
        openSnackbarError(errorCreateList.message);
      }
    } else {
      try {
        handleOpenBackdrop();
        const addProjectsToListMutation = await addProjectsToList({
          variables: {
            projectLists: {
              listId: newListId,
              projectIds: selectedProjectIds,
            },
          },
        });
        const {
          data: { addProjectsToList: projectsAddToList },
        } = addProjectsToListMutation;
        if (projectsAddToList) {
          openSnackbarSuccess(
            `${t('dialog:openSnackbarSuccess.projectAddedToLists')} ${listName} ${t(
              'dialog:openSnackbarSuccess.successfully',
            )}`,
          );
          handleCloseDialog();
          handleCloseBackdrop();
          history.push({
            pathname: location.pathname,
            state: { isRefetchLists: true },
          });
        }
      } catch (e) {
        handleCloseBackdrop();
        openSnackbarError(e);
      }
    }
  };

  const handleBack = () => {
    if (step === 0 || step === 2) {
      handleCloseDialog();
    } else if (step === 1) {
      setListName('');
      setStep(step - 1);
    }
  };

  return open ? (
    <ListProjectDialog
      step={step}
      listWithoutProject={listWithoutProject}
      handleCheckList={handleCheckList}
      selectedListIds={selectedListIds}
      handleCheckProject={handleCheckProject}
      selectedProjectIds={selectedProjectIds}
      listName={listName}
      setListName={setListName}
      open={open}
      handleCloseDialog={handleCloseDialog}
      handleBack={handleBack}
      handleContinueOrSave={handleContinueOrSave}
      disableSave={disableSave}
    />
  ) : null;
};

ListProjectDialogWrapper.propTypes = {
  open: bool.isRequired,
  options: object.isRequired,
  openSnackbarError: func.isRequired,
  openSnackbarSuccess: func.isRequired,
  handleCloseDialog: func.isRequired,
  handleCloseBackdrop: func.isRequired,
  handleOpenBackdrop: func.isRequired,
};

export default ListProjectDialogWrapper;
