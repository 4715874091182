import { useMutation } from '@apollo/client';
import { Box, Typography, IconButton, makeStyles } from '@material-ui/core';
import { Edit as EditIcon, Add as AddIcon, Save as SaveIcon } from '@material-ui/icons';
import { shape, string, bool, func } from 'prop-types';
import { useState } from 'react';

import { UPDATE_BASIC_CONTEST_INFO } from 'apollo/mutations/contest-mutation';
import { CocoonEditor } from 'components';
import { CK_EDITOR_CONTEST_IMAGE_UPLOAD_URL, CK_EDITOR_UPLOAD_TYPES } from 'helpers/constants';
import { useSnackbar } from 'hooks';

const useStyles = makeStyles(() => ({
  titleContainer: {
    columnGap: 10,
  },
  btn: {
    padding: 5,
  },
}));

function Icon({ isEditing, isEmpty }) {
  if (!isEditing) {
    if (isEmpty) {
      return <AddIcon />;
    }
    return <EditIcon />;
  }
  return <SaveIcon />;
}

Icon.propTypes = {
  isEditing: bool.isRequired,
  isEmpty: bool.isRequired,
};

function RulesPrizesEditor({ activeTab, contest, refetch }) {
  const [contestOverview, setContestOverview] = useState(contest.contestOverview || '');
  const [isEditing, setIsEditing] = useState(false);
  const [updateContest] = useMutation(UPDATE_BASIC_CONTEST_INFO);
  const { openSnackbarSuccess, openSnackbarError } = useSnackbar();

  const classes = useStyles();

  function handleContestOverviewChange(val) {
    setContestOverview(val);
  }

  async function handleBtnClick() {
    if (!isEditing) {
      setIsEditing(true);
    } else {
      try {
        const updatedContest = {
          id: contest.id,
          contestOverview,
        };
        const { data } = await updateContest({
          variables: { contest: updatedContest },
        });
        if (data) {
          openSnackbarSuccess('Contest updated!');
          setIsEditing(false);
          return refetch();
        }
      } catch (err) {
        openSnackbarError('Something went wrong with saving contest');
        setIsEditing(false);
        throw new Error('Could not save contest', err.message);
      }
    }
  }

  return (
    <>
      <Box className={classes.titleContainer} display="flex" flexDirection="row" alignItems="center">
        <Typography variant="h5">{activeTab}</Typography>
        <IconButton onClick={handleBtnClick} variant="text" color="primary">
          <Icon isEditing={isEditing} isEmpty={contestOverview.length === 0} />
        </IconButton>
      </Box>
      <Box pt={0.75}>
        <CocoonEditor
          isFromContest
          data={contestOverview}
          handleChangeData={isEditing ? handleContestOverviewChange : undefined}
          uploadUrl={CK_EDITOR_CONTEST_IMAGE_UPLOAD_URL}
          type={CK_EDITOR_UPLOAD_TYPES.CONTEST_OVERVIEW}
          contestId={contest.id}
        />
      </Box>
    </>
  );
}

RulesPrizesEditor.propTypes = {
  activeTab: string.isRequired,
  contest: shape({}).isRequired,
  refetch: func.isRequired,
};

export default RulesPrizesEditor;
