import { Typography, Grid, Paper, TextField } from '@material-ui/core';
import {
  LinkedIn as LinkedInIcon,
  GitHub as GitHubIcon,
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
} from '@material-ui/icons';
import { bool, func, object } from 'prop-types';

import useStyles from './socialMediaStyle';

import { useTranslation } from 'react-i18next';

const SocialMedias = ({ desktop, register }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.editInfoWrapper}>
      <Typography className={classes.editInfoTitle}>{t('common.socialMedia')}</Typography>
      <hr className={classes.divider} />
      <Grid container spacing={desktop ? 3 : 1}>
        <Grid item xs={desktop ? 4 : 12}>
          <Paper className={classes.socialMediaWrapper} elevation={0}>
            <LinkedInIcon fontSize={desktop ? 'large' : 'small'} style={{ marginRight: desktop ? '0.5em' : 4 }} />
            <Typography className={classes.socialMediaTitle}>LinkedIn</Typography>
          </Paper>
        </Grid>
        <Grid item xs={desktop ? 8 : 12}>
          <TextField
            className={classes.editInfoTextField}
            fullWidth
            variant="outlined"
            name="linkedin"
            placeholder={t('common.linkedIn')}
            inputRef={register}
          />
        </Grid>
      </Grid>
      <Grid container spacing={desktop ? 3 : 1}>
        <Grid item xs={desktop ? 4 : 12}>
          <Paper className={classes.socialMediaWrapper} elevation={0}>
            <GitHubIcon fontSize={desktop ? 'large' : 'small'} style={{ marginRight: desktop ? '0.5em' : 4 }} />
            <Typography className={classes.socialMediaTitle}>Github</Typography>
          </Paper>
        </Grid>
        <Grid item xs={desktop ? 8 : 12}>
          <TextField
            className={classes.editInfoTextField}
            fullWidth
            variant="outlined"
            name="github"
            placeholder={t('common.gitHub')}
            inputRef={register}
          />
        </Grid>
      </Grid>
      <Grid container spacing={desktop ? 3 : 1}>
        <Grid item xs={desktop ? 4 : 12}>
          <Paper className={classes.socialMediaWrapper} elevation={0}>
            <FacebookIcon fontSize={desktop ? 'large' : 'small'} style={{ marginRight: desktop ? '0.5em' : 4 }} />
            <Typography className={classes.socialMediaTitle}>Facebook</Typography>
          </Paper>
        </Grid>
        <Grid item xs={desktop ? 8 : 12}>
          <TextField
            className={classes.editInfoTextField}
            fullWidth
            variant="outlined"
            name="facebook"
            placeholder={t('common.facebook')}
            inputRef={register}
          />
        </Grid>
      </Grid>
      <Grid container spacing={desktop ? 3 : 1}>
        <Grid item xs={desktop ? 4 : 12}>
          <Paper className={classes.socialMediaWrapper} elevation={0}>
            <TwitterIcon fontSize={desktop ? 'large' : 'small'} style={{ marginRight: desktop ? '0.5em' : 4 }} />
            <Typography className={classes.socialMediaTitle}>Twitter</Typography>
          </Paper>
        </Grid>
        <Grid item xs={desktop ? 8 : 12}>
          <TextField
            className={classes.editInfoTextField}
            fullWidth
            variant="outlined"
            name="twitter"
            placeholder={t('common.twitter')}
            inputRef={register}
          />
        </Grid>
      </Grid>
    </div>
  );
};

SocialMedias.propTypes = {
  desktop: bool.isRequired,
};

export default SocialMedias;
