import { Fab, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

function FloatingGoToButton({ label, className, color, icon, onClick }) {
  const classes = useStyles();

  return (
    <Fab onClick={onClick} aria-label={label} className={clsx([classes.fab, className])} color={color}>
      {icon}
    </Fab>
  );
}

FloatingGoToButton.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func,
};

FloatingGoToButton.defaultProps = {
  label: '',
  className: '',
  color: '#FFF',
  icon: undefined,
  onClick: () => {},
};

export default FloatingGoToButton;
