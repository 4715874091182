import { Box, Button, Grid, Typography } from '@material-ui/core';
import {
  Check as CheckIcon,
  Edit as EditIcon,
  LocationOn as LocationOnIcon,
  PhotoLibrary as PhotoLibraryIcon,
} from '@material-ui/icons';
import { string, bool, func, object } from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import defaultUserAvatar from '@images/default-user.png';
import { Tags, CocoonStats } from 'components';
import { SameUserAlt } from 'components/auth/UserAuthorization';
import { getDisplayName } from 'helpers/common';
import { acceptedImageFiles, DIALOG, PROFILE_TYPES } from 'helpers/constants';
import { handleOpenDialogAC3 } from 'reducers/testAC3/cache';

import ProfileHeaderAvatar from './components/avatar';
import profileHeaderStyle from './profileHeaderStyle';

const MIN_HEIGHT = 490;
const MIN_HEIGHT_MOBILE = 350;
// TODO: Separate Partner and User logic
const ProfileHeader = ({ desktop, profileType, profile, isUserProfile, handleProfileCoverChange, refetchProfile }) => {
  const classes = profileHeaderStyle();
  const avatar = profile.avatarLink || defaultUserAvatar;
  const history = useHistory();
  const { state } = useLocation();

  const profileBackground = profile.backgroundPictureUrl || '';

  const { t } = useTranslation();

  const renderLocation = () => {
    if (!profile.country) {
      return t('defaultInformation.noLocation');
    }
    return profile.state ? `${profile.state.name}, ${profile.country.name}` : profile.country.name;
  };

  const openEditProfileDialog = (e) => {
    if (e) {
      e.preventDefault();
    }
    const options = { profile };
    handleOpenDialogAC3(DIALOG.EDIT_PROFILE, options);
  };

  useEffect(() => {
    // this is to update profile form when experience or contest is modified
    // other than calling refetch again
    // only works for experiences/contests
    if (state && state.isRefetchProfile) {
      openEditProfileDialog();
      history.push({ state: { isRefetchProfile: false } });
    }
  }, [profile.experiences, profile.contests]);

  return (
    <>
      <Grid
        item
        className={classes.backgroundWrapper}
        style={{
          justifyContent: desktop ? 'center' : 'initial',
          backgroundImage: `url(${profileBackground})`,
          backgroundSize: 'cover',
          backgroundColor: '#000',
          minHeight: desktop ? MIN_HEIGHT : MIN_HEIGHT_MOBILE,
        }}
      >
        {desktop ? (
          <>
            <ProfileHeaderAvatar desktop={desktop} avatar={avatar} profile={profile} refetchProfile={refetchProfile} />
            <Box className={classes.contentWrapper}>
              <Typography variant="h2" className={classes.landingName}>
                {isUserProfile ? getDisplayName(profile) : profile.name}
                {profile.certified && <CheckIcon className={classes.certifiedIcon} />}
                {profileType === PROFILE_TYPES.PARTNER_PROFILE && profile.partnerName}
              </Typography>
              {isUserProfile ? (
                <Typography variant="h6" className={classes.primaryJob}>
                  {profile.primaryJob ? profile.primaryJob : t('defaultInformation.noPrimaryJob')}
                </Typography>
              ) : (
                <Grid container justify="center" alignItems="center" className={classes.fieldsOfWorkWrapper}>
                  <Tags
                    desktop={desktop}
                    isChips
                    isLightView
                    label={profileType === PROFILE_TYPES.PARTNER_PROFILE ? 'tagName' : 'fieldName'}
                    tags={profileType === PROFILE_TYPES.PARTNER_PROFILE ? profile.tags : profile.fieldsOfWork}
                  />
                </Grid>
              )}
              <Typography variant="body1" className={classes.description}>
                {profile.description ? profile.description : t('defaultInformation.noProfileDescription')}
              </Typography>
              <div
                style={{
                  display: 'flex',
                  marginTop: '5%',
                  alignItems: 'center',
                }}
              >
                <LocationOnIcon style={{ color: '#fff' }} />
                <Typography variant="body1" className={classes.location}>
                  {renderLocation()}
                </Typography>
              </div>
              <Box className={classes.actionButtons}>
                {SameUserAlt(profile.id)(
                  <>
                    <label htmlFor="button-cover-1">
                      <input
                        accept={acceptedImageFiles}
                        className={classes.fileInput}
                        id="button-cover-1"
                        multiple
                        type="file"
                        onChange={handleProfileCoverChange}
                      />
                      <Button
                        component="span"
                        variant="outlined"
                        className={classes.button}
                        startIcon={<PhotoLibraryIcon />}
                      >
                        <Typography variant="button" color="inherit">
                          {t('button.changeCover')}
                        </Typography>
                      </Button>
                    </label>
                    <Button onClick={(e) => openEditProfileDialog(e)} variant="outlined" className={classes.button}>
                      {t('button.editProfile')}
                    </Button>
                  </>,
                  <>
                    {/* <Button variant="outlined" className={classes.button}>
                Follow
              </Button>
              <Button variant="outlined" className={classes.button}>
                Message
              </Button> */}
                  </>,
                )}
              </Box>
            </Box>
          </>
        ) : (
          <Box className={classes.userInfo}>
            <Grid container>
              <Grid item xs={12}>
                <ProfileHeaderAvatar
                  desktop={desktop}
                  avatar={avatar}
                  profile={profile}
                  refetchProfile={refetchProfile}
                />
              </Grid>
              <Grid item xs={12} container direction="column" justify="center">
                <Typography variant="h5" className={classes.landingName}>
                  {isUserProfile ? getDisplayName(profile) : profile.name}
                </Typography>
                {isUserProfile && (
                  <Typography variant="caption" className={classes.primaryJob}>
                    {profile.primaryJob ? profile.primaryJob : t('defaultInformation.noPrimaryJob')}
                  </Typography>
                )}
                <Typography variant="caption" className={classes.location}>
                  {renderLocation()}
                </Typography>
              </Grid>
            </Grid>
            <CocoonStats profileType={profileType} desktop={desktop} {...profile} />
          </Box>
        )}
        <div className={classes.overlay} />
      </Grid>
      {!desktop && (
        <Box className={classes.actionButtonsMobile}>
          {SameUserAlt(profile.id)(
            <>
              <label htmlFor="button-cover-1">
                <input
                  accept={acceptedImageFiles}
                  className={classes.fileInput}
                  id="button-cover-1"
                  multiple
                  type="file"
                  onChange={handleProfileCoverChange}
                />
                <Button component="span" variant="outlined" className={classes.button} startIcon={<PhotoLibraryIcon />}>
                  <Typography variant="button">{t('button.changeCover')}</Typography>
                </Button>
              </label>
              <Button
                onClick={(e) => openEditProfileDialog(e)}
                variant="outlined"
                className={classes.button}
                startIcon={<EditIcon />}
              >
                {t('button.editProfile')}
              </Button>
            </>,
          )}
        </Box>
      )}
    </>
  );
};

ProfileHeader.propTypes = {
  profileType: string.isRequired,
  profile: object.isRequired,
  isUserProfile: bool.isRequired,
  refetchProfile: func.isRequired,
  handleProfileCoverChange: func.isRequired,
  desktop: bool.isRequired,
};

export default ProfileHeader;
