import { Link, Step, StepLabel, StepContent, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { object, func, bool } from 'prop-types';
import { useState, useEffect } from 'react';

import { formatDate_ll } from 'helpers';

import MilestoneIcon from './MilestoneIcon';

const useStyles = makeStyles((theme) => ({
  milestoneLabel: {
    fontSize: '16px',
  },
  milestoneContent: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(1),
    marginTop: -theme.spacing(1),
  },
  stepLink: {
    cursor: 'pointer',
  },
  active: {
    color: theme.palette.secondary.main,
  },
  activeIcon: {
    '& circle': {
      fill: theme.palette.secondary.main,
    },
  },
}));

const LogsListItem = ({ log, reset, isActive, handleToggleLogIdSelected }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState(isActive);

  const onStepClick = (e, id) => {
    e.preventDefault();
    handleToggleLogIdSelected(id);
    setSelected((prevState) => !prevState);
  };

  useEffect(() => {
    if (reset) {
      setSelected(false);
    }
  }, [reset]);

  return (
    <Link onClick={(e) => onStepClick(e, +log.id)} component={Step} key={+log.id} active className={classes.stepLink}>
      <StepLabel
        StepIconComponent={MilestoneIcon}
        className={clsx({
          [classes.activeIcon]: selected,
        })}
      >
        <Typography
          variant="caption"
          color="textSecondary"
          className={clsx(classes.milestoneLabel, {
            [classes.active]: selected,
          })}
        >
          {formatDate_ll(log.publishedDate)}
        </Typography>
      </StepLabel>
      <StepContent icon="" orientation="vertical">
        <Typography
          variant="h6"
          className={clsx(classes.milestoneContent, {
            [classes.active]: selected,
          })}
        >
          {log.name}
        </Typography>
      </StepContent>
    </Link>
  );
};

LogsListItem.propTypes = {
  log: object.isRequired,
  reset: bool.isRequired,
  isActive: bool.isRequired,
  handleToggleLogIdSelected: func.isRequired,
};

export default LogsListItem;
