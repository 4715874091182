import { Box, makeStyles } from '@material-ui/core';
import CKEditor from '@projectcocoon/ckeditor';
import clsx from 'clsx';
import { string, func, number, oneOfType, bool } from 'prop-types';

import UploadAdapter from './UploadAdapter';

const useStyles = makeStyles({
  view: {
    '& .ck.ck-editor__top.ck-reset_all': {
      display: 'none',
    },
    '& .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused)': {
      border: 'none',
    },
    '& .ck.ck-editor__editable_inline': {
      padding: 0,
    },
  },
});

const CocoonEditor = ({ isFromContest, data, handleChangeData, uploadUrl, type, projectId, contestId }) => {
  const classes = useStyles();

  const cocoonUploadUrl = `${uploadUrl}?type=${type}&projectId=${projectId}`;
  const cocoonContestUploadUrl = `${uploadUrl}?type=${type}&contestId=${contestId}`;
  const ckEditorUploadUrl = isFromContest ? cocoonContestUploadUrl : cocoonUploadUrl;

  return (
    <Box
      className={clsx({
        [classes.view]: !handleChangeData,
      })}
    >
      <CKEditor
        data={data}
        disabled={!handleChangeData}
        onInit={(editor) => {
          editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return new UploadAdapter(loader, ckEditorUploadUrl);
          };
        }}
        onChange={(_event, editor) => {
          if (handleChangeData) {
            handleChangeData(editor.getData());
          }
        }}
        uploadUrl={ckEditorUploadUrl}
        type={type}
        projectId={projectId}
        contestId={contestId}
      />
    </Box>
  );
};

CocoonEditor.propTypes = {
  isFromContest: bool,
  data: string.isRequired,
  handleChangeData: func,
  uploadUrl: string,
  type: string,
  projectId: oneOfType([number, string]),
  contestId: oneOfType([number, string]),
};

CocoonEditor.defaultProps = {
  isFromContest: false,
  handleChangeData: undefined,
  uploadUrl: '',
  type: '',
  projectId: undefined,
  contestId: undefined,
};

export default CocoonEditor;
