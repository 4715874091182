import { Modal, Fab, Fade, Backdrop, Paper, Button, withStyles } from '@material-ui/core';
import { duration } from '@material-ui/core/styles/transitions';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Clear as ClearIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import { bool, object, string, number, func, oneOfType, arrayOf, node } from 'prop-types';
import { Component, Children, cloneElement } from 'react';

import modulo from '../helpers/modulo';

import Dots from './Dots';
import SwipeViews from './SwipeViews';

const styles = {
  root: {
    '& > *:focus': {
      outline: 'none',
    },
  },
  content: {
    height: 'calc(100% - 96px)',
    margin: '-16px auto 0',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  contentMobile: {
    width: '100%',
    height: '100%',
    maxWidth: 'initial',
    maxHeight: 'initial',
    margin: 0,
    top: 0,
    transform: 'none',

    '& > $carouselWrapper': {
      borderRadius: 0,
    },
  },
  arrow: {
    width: 48,
    height: 48,
    position: 'absolute',
    top: 'calc((100% - 96px) / 2 + 24px)',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#545454',
    },
  },
  arrowLeft: {
    left: 8,
    marginRight: 4,
  },
  arrowRight: {
    right: 8,
    marginLeft: 4,
  },
  clear: {
    width: 48,
    height: 48,
    position: 'absolute',
    top: 8,
    right: 8,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#545454',
    },
  },
  download: {
    width: 48,
    height: 48,
    position: 'absolute',
    top: 8,
    right: 56,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#545454',
    },
  },
  icon: {
    color: '#fff',
  },
  carouselWrapper: {
    overflow: 'hidden',
    transform: 'scale(1.0)',
    background: 'transparent',
    height: '100%',
  },
  dots: {
    paddingTop: 28,
    margin: '0 auto',
  },
  dotsMobile: {
    paddingTop: 0,
  },
  dotsMobileLandscape: {
    paddingTop: 20,
  },
  footer: {
    marginTop: -72,
    width: '100%',
    position: 'relative',
    textAlign: 'center',
  },
  footerMobile: {
    marginTop: -92,
  },
  footerMobileLandscape: {
    marginTop: -3,
    transform: 'translateY(-50vh)',
    display: 'inline-block',
    width: 'auto',
  },
  slide: {
    width: '100%',
    height: '100%',
  },
  slideMobile: {
    width: '100%',
    height: '100%',
  },
  carousel: {
    height: '100%',
  },
  carouselContainer: {
    height: '100%',
  },
  closed: {},
};

class Carousel extends Component {
  state = {
    slideIndex: this.props.index,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.index !== prevState.slideIndex) {
      return { slideIndex: nextProps.index };
    }
    return null;
  }

  onChange = (slideIndex) => {
    const { onChange, children } = this.props;
    if (onChange) {
      onChange(modulo(slideIndex, children.length));
    }
  };

  handleContentClick = (e) => e.stopPropagation() || e.preventDefault();

  handleChange = (slideIndex) => {
    this.setState(
      {
        slideIndex,
      },
      this.onChange(slideIndex),
    );
  };

  handleDownload = (e) => {
    e.stopPropagation();
  };

  increaseIndex = () => {
    this.setState(
      (prevState) => ({
        slideIndex: prevState.slideIndex + 1,
      }),
      this.onChange(this.state.slideIndex + 1),
    );
  };

  decreaseIndex = () => {
    this.setState(
      (prevState) => ({
        slideIndex: prevState.slideIndex - 1,
      }),
      this.onChange(this.state.slideIndex - 1),
    );
  };

  render() {
    const {
      autoplay,
      ButtonProps,
      children,
      classes,
      containerStyle,
      hideArrows,
      interval,
      label,
      landscape: landscapeProp,
      mobile,
      ModalProps,
      open,
      onClose,
      onStart,
    } = this.props;
    const landscape = mobile && landscapeProp;
    const transitionDuration = {
      enter: duration.enteringScreen,
      exit: duration.leavingScreen,
    };
    const hasMultipleChildren = children.length !== null && children.length > 1;

    const carousel = (
      <SwipeViews
        autoplay={open && autoplay && hasMultipleChildren}
        className={classes.carousel}
        containerStyle={{ height: '100%', ...containerStyle }}
        index={this.state.slideIndex}
        interval={interval}
        onChangeIndex={this.handleChange}
        slideClassName={classes.slide}
      >
        {Children.map(children, (c) => cloneElement(c, { mobile, landscape }))}
      </SwipeViews>
    );

    const BackdropProps = ModalProps ? { transitionDuration, ...ModalProps.BackdropProps } : { transitionDuration };

    return (
      <Modal
        className={clsx(classes.root, {
          [classes.rootMobile]: mobile,
        })}
        open={open}
        onClose={onClose}
        onBackdropClick={onClose}
        BackdropComponent={Backdrop}
        BackdropProps={BackdropProps}
        {...ModalProps}
      >
        <Fade appear in={open} timeout={transitionDuration}>
          <div
            role="button"
            className={clsx(classes.content, {
              [classes.contentMobile]: mobile,
            })}
            onClick={this.handleContentClick}
          >
            <Paper elevation={mobile ? 0 : 1} className={classes.carouselWrapper}>
              {carousel}
            </Paper>
            <div
              style={
                landscape
                  ? {
                      minWidth: 300,
                      maxWidth: 'calc(50% - 48px)',
                      padding: 24,
                      float: 'right',
                    }
                  : null
              }
            >
              <div
                className={clsx(classes.footer, {
                  [classes.footerMobile]: mobile,
                  [classes.footerMobileLandscape]: landscape,
                })}
              >
                {label && (
                  <Button variant="contained" onClick={onStart} {...ButtonProps}>
                    {label}
                  </Button>
                )}
                {hasMultipleChildren && (
                  <Dots
                    count={children.length}
                    index={modulo(this.state.slideIndex, children.length)}
                    className={clsx(classes.dots, {
                      [classes.dotsMobile]: mobile,
                      [classes.dotsMobileLandscape]: landscape,
                    })}
                    onDotClick={this.handleChange}
                  />
                )}
              </div>
            </div>
            {!mobile && !hideArrows && (
              <div>
                <Fab className={classes.clear} onClick={() => onClose()}>
                  <ClearIcon fontSize="large" className={classes.icon} />
                </Fab>
                {hasMultipleChildren && (
                  <>
                    <Fab className={clsx(classes.arrow, classes.arrowLeft)} onClick={() => this.decreaseIndex()}>
                      <ChevronLeftIcon fontSize="large" className={classes.icon} />
                    </Fab>
                    <Fab className={clsx(classes.arrow, classes.arrowRight)} onClick={() => this.increaseIndex()}>
                      <ChevronRightIcon fontSize="large" className={classes.icon} />
                    </Fab>
                  </>
                )}
              </div>
            )}
          </div>
        </Fade>
      </Modal>
    );
  }
}

Carousel.defaultProps = {
  autoplay: false,
  interval: 3000,
  mobile: false,
  open: false,
  hideArrows: false,
  ButtonProps: {},
  containerStyle: {},
  label: undefined,
  landscape: false,
  ModalProps: {},
  onChange: undefined,
  onClose: undefined,
  onStart: undefined,
  index: 0,
};

Carousel.propTypes = {
  /** If `false`, the auto play behavior is disabled. */
  autoplay: bool,
  /** Properties applied to the [Button](https://material-ui.com/api/button/) element. */
  ButtonProps: object,
  /** Object for customizing the CSS classes. */
  classes: object.isRequired,
  /** Override the inline-styles of the carousel container. */
  containerStyle: object,
  /** Delay between auto play transitions (in ms). */
  interval: number,
  /** Button text. If not supplied, the button will be hidden. */
  label: string,
  /** If `true`, slide will adjust content for wide mobile screens. */
  landscape: bool,
  /** If `true`, the screen width and height is filled. */
  mobile: bool,
  /** Properties applied to the [Modal](https://material-ui.com/api/modal/) element. */
  ModalProps: object,
  /** Fired when the index changed. Returns current index. */
  onChange: func,
  /** Fired when the gray background of the popup is pressed when it is open. */
  onClose: func,
  /** Fired when the user clicks the getting started button. */
  onStart: func,
  /** Controls whether the AutoRotatingCarousel is opened or not. */
  open: bool,
  /** If `true`, the left and right arrows are hidden in the desktop version. */
  hideArrows: bool,
  children: oneOfType([arrayOf(node), node]).isRequired,
  index: number,
};

export default withStyles(styles)(Carousel);
