import { Box, makeStyles } from '@material-ui/core';
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from '@material-ui/icons';
import { arrayOf, bool, object } from 'prop-types';
import { useState } from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';

import AboutBlurb from './AboutBlurb';

const useStyles = makeStyles((theme) => ({
  menu: {
    '& .menu-item-wrapper.active': {
      border: 'none',
    },
    '& .scroll-menu-arrow': {
      padding: theme.spacing(1.5),
      color: theme.palette.primary.main,
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0.25),
      },
    },
    '& .menu-wrapper--inner': {
      display: 'flex',
      alignItems: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      height: 410,
    },
  },
  menuItem: {
    width: 300,
    maxHeight: 500,
    height: 410,
    margin: theme.spacing(0, 1.5),
    [theme.breakpoints.down('sm')]: {
      width: 260,
      maxHeight: 400,
      height: 300,
    },
  },
}));

const Menu = (list, selected, desktop) => {
  const classes = useStyles();

  return list.map(({ imgPath, name, title }) => (
    <Box key={name} className={classes.menuItem}>
      <AboutBlurb src={imgPath} title={name} subtitle={title} desktop={desktop} />
    </Box>
  ));
};

const ArrowLeft = (
  <div className="arrow-prev">
    <ChevronLeftIcon />
  </div>
);
const ArrowRight = (
  <div className="arrow-prev">
    <ChevronRightIcon />
  </div>
);

const AboutPartners = ({ partners, desktop }) => {
  const classes = useStyles();

  const [selected, setSelected] = useState(partners[0].name);

  const menu = Menu(partners, selected, desktop);

  const handleSelect = (key) => {
    setSelected(key);
  };

  return (
    <ScrollMenu
      alignCenter={false}
      menuClass={classes.menu}
      arrowLeft={ArrowLeft}
      arrowRight={ArrowRight}
      data={menu}
      selected={selected}
      onSelect={handleSelect}
      useButtonRole={false}
    />
  );
};

AboutPartners.propTypes = {
  partners: arrayOf(object).isRequired,
  desktop: bool.isRequired,
};

export default AboutPartners;
