import { useQuery } from '@apollo/client';
import { Avatar, Grid, Typography } from '@material-ui/core';
import {
  BookmarkBorder as BookmarkBorderIcon,
  ChatBubbleOutline as ChatBubbleOutlineIcon,
  FavoriteBorder as FavoriteBorderIcon,
  Visibility as VisibilityIcon,
} from '@material-ui/icons';
import { string, object, bool } from 'prop-types';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import DefaultProfileAvatar from '@images/default-user.png';
import { GET_PROJECT_OVERALL_STATS } from 'apollo/queries/project-query';
import { getDisplayName } from 'helpers/common';

import ProjectPopover from '../popover/ProjectPopopver';

import projectOverlayStyle from './projectOverlayStyle';

const ProjectOverlay = ({ desktop, project, currentTab, isFromList = false, isFromContest = false, link }) => {
  const classes = projectOverlayStyle();
  const [statsData, setStatsData] = useState({
    totalLikes: 0,
    totalComments: 0,
  });

  const { data: dataStats, loading: statsLoading } = useQuery(GET_PROJECT_OVERALL_STATS, {
    variables: { projectId: project.id },
    skip: !project.id,
  });

  useEffect(() => {
    if (!statsLoading) {
      setStatsData({ ...dataStats.projectOverallStats });
    }
  }, [statsLoading]);

  const buildGridIcon = (Icon, data) => (
    <Grid item sm={1} className={classes.projectStatsColumn}>
      <Icon className={isFromList || !desktop ? classes.smallStatsIcon : classes.regularStatsIcon} />
      <Typography variant="body2" className={classes.projectStats}>
        {data ?? '--'}
      </Typography>
    </Grid>
  );

  return (
    <div className={classes.overlayContainer}>
      {!statsLoading && (
        <>
          <Grid container className={isFromList ? classes.smallStatsRow : classes.regularStatsRow}>
            {buildGridIcon(VisibilityIcon, project.viewsCount)}
            {buildGridIcon(ChatBubbleOutlineIcon, statsData.totalComments)}
            {buildGridIcon(BookmarkBorderIcon, project.savedCount)}
            {buildGridIcon(FavoriteBorderIcon, statsData.totalLikes)}
            <Grid item sm={8} className={classes.projectPopoverColumn}>
              {/* disable contest actions for now */}
              {(!isFromList || isFromContest) && (
                <ProjectPopover desktop={desktop} project={project} currentTab={currentTab} />
              )}
            </Grid>
          </Grid>
          <div className={isFromList || !desktop ? classes.smallTitleRow : classes.regularTitleRow}>
            <Typography
              component={isFromList ? Typography : Link}
              to={link}
              variant="h4"
              className={isFromList || !desktop ? classes.smallTitle : classes.regularTitle}
            >
              {project.name}
            </Typography>
            {/* TODO: Link to profile later */}
            <div className={classes.authorRow}>
              <Avatar
                alt={project.author}
                src={project.owner ? project.owner.avatarLink : DefaultProfileAvatar}
                className={isFromList || !desktop ? classes.smallAvatar : classes.regularAvatar}
              />
              <Typography variant="h5" className={classes.author}>
                {isFromContest ? project.creator.partnerName : getDisplayName(project.owner)}
              </Typography>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

ProjectOverlay.propTypes = {
  desktop: bool.isRequired,
  project: object.isRequired,
  isFromList: bool,
  isFromContest: bool,
  currentTab: string,
  link: string,
};

ProjectOverlay.defaultProps = {
  isFromList: false,
  isFromContest: false,
  currentTab: '',
};

export default ProjectOverlay;
