import { useApolloClient } from '@apollo/client';
import { TextField, CircularProgress } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import _ from 'lodash';
import { bool } from 'prop-types';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { GLOBAL_PROJECT_SEARCH } from 'apollo/queries/project-query';
import { convertToSlug } from 'helpers/common';

import useStyles from './searchStyles';

let delayTimer;

const Search = ({ desktop }) => {
  const classes = useStyles();
  const history = useHistory();

  const client = useApolloClient();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const [optValue, setOptValue] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const getProjects = async (projectName) => {
    const { data: dataProjects } = await client.query({
      query: GLOBAL_PROJECT_SEARCH,
      variables: { name: _.toLower(projectName), page: 1, limit: 'FIVE' },
    });

    if (dataProjects) {
      setOptions(dataProjects.projects.map(({ id, name }) => ({ id, name })));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const doSearch = (text) => {
    setLoading(true);

    clearTimeout(delayTimer);
    delayTimer = setTimeout(() => {
      getProjects(text);
    }, 300);
  };

  return (
    <div className={classes.search}>
      {desktop && (
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
      )}
      <Autocomplete
        id="search-projects"
        classes={{
          focused: desktop ? classes.focused : undefined,
          option: classes.option,
          loading: classes.loading,
        }}
        open={open}
        onOpen={() => {
          if (!open && !_.isEmpty(inputValue)) {
            setOpen(true);
          }
        }}
        onClose={() => {
          if (open) {
            setOpen(false);
          }
        }}
        autoComplete
        options={options}
        getOptionLabel={(option) => option.name}
        noOptionsText="No result. Try searching by keyword."
        loading={loading}
        loadingText="Loading projects..."
        onChange={(event, value) => {
          if (value.id === -1) {
            history.push(`/projects?type=all`);
            setInputValue('');
            setOptValue(null);
          } else {
            history.push(`/projects/${value.id}/${convertToSlug(value.name)}`);
          }
        }}
        value={optValue}
        inputValue={inputValue}
        onInputChange={_.debounce((event, newInputValue) => {
          doSearch(newInputValue);
        }, 300)}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search project by title"
            className={classes.input}
            onChange={(e, value) => setInputValue(e.target.value)}
            InputProps={{
              ...params.InputProps,
              autoComplete: 'no',
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            autoComplete="no"
          />
        )}
      />
    </div>
  );
};

Search.propTypes = {
  desktop: bool.isRequired,
};

export default Search;
