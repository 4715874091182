import { gql } from '@apollo/client';

// done
// updated
export const GET_UPDATE_TAGS = gql`
  query tagsByUpdate($updateId: ID!, $page: Int, $limit: PaginationLimit) {
    tagsByUpdate(updateId: $updateId, page: $page, limit: $limit) {
      id
      name
    }
  }
`;

// done
// updated
export const GET_UPDATE_TAGS_BY_PROJECT_ID = gql`
  query tagsByProject($projectId: ID!, $isPublished: Boolean, $page: Int, $limit: PaginationLimit) {
    tagsByProject(projectId: $projectId, isPublished: $isPublished, page: $page, limit: $limit) {
      name
    }
  }
`;
