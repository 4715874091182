import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  GridList,
  GridListTile,
  Avatar,
  makeStyles,
} from '@material-ui/core';
import { bool, object, func } from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Image from 'components/image/Image';
import { getDisplayName } from 'helpers/common';
import { useUnload } from 'hooks';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      maxWidth: 'none',
    },
  },
  gridList: {
    width: 600,

    '& .MuiGridListTile-tile > div': {
      backgroundColor: 'rgba(0,0,0,0.1)',
    },

    [theme.breakpoints.down('sm')]: {
      width: 260,
    },
  },
}));

const ListDialog = ({ open, options, desktop, handleCloseDialog, handleOpenLightbox }) => {
  const classes = useStyles();

  const [isDownload, setIsDownload] = useState(false);
  const { title, list, handleOnClickItem, isGallery } = options;
  const { t } = useTranslation();
  // Prevent user reload when dialog is opened
  useUnload(!isDownload && handleCloseDialog);

  const handleOnDownloadClick = () => {
    setIsDownload(true);
  };

  const handleImageZoom = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    handleOpenLightbox(list, index);
  };

  const renderGallery = () =>
    list && (
      <GridList cellHeight={desktop ? 196 : 126} className={classes.gridList} cols={desktop ? 3 : 2}>
        {list.map((item, index) => (
          <GridListTile key={+item.id} cols={1}>
            <Image
              src={item.assetLink}
              alt={item.name}
              onClick={(e) => handleImageZoom(e, index)}
              aspectRatio={1}
              hasDownload
              hasZoom
              isHoverable
              onZoomCLick={(e) => handleImageZoom(e, index)}
              onDownloadClick={handleOnDownloadClick}
            />
          </GridListTile>
        ))}
      </GridList>
    );

  const renderList = () =>
    list && (
      <List dense>
        {list.map((item, index) => (
          <ListItem key={index} button onClick={handleOnClickItem}>
            <ListItemAvatar>
              <Avatar alt={getDisplayName(item)} src={item.avatarLink} />
            </ListItemAvatar>
            <ListItemText id={item.username} primary={getDisplayName(item)} />
          </ListItem>
        ))}
      </List>
    );

  return open ? (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      className={classes.dialog}
      aria-labelledby="list-dialog-title"
      aria-describedby="list-dialog-description"
    >
      <DialogTitle disableTypography id="list-dialog-title">
        <Typography color="primary" variant="h6">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>{isGallery ? renderGallery() : renderList()}</DialogContent>
      <DialogActions>
        <Typography color="error" variant="h6">
          <Button onClick={handleCloseDialog}>{t('button.cancel')}</Button>
        </Typography>
      </DialogActions>
    </Dialog>
  ) : null;
};

ListDialog.propTypes = {
  open: bool.isRequired,
  desktop: bool.isRequired,
  options: object.isRequired,
  handleCloseDialog: func.isRequired,
  handleOpenLightbox: func.isRequired,
};

export default ListDialog;
