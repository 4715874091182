import { gql } from '@apollo/client';

// done
// updated
export const SAVE_CONTEST_PROJECTS = gql`
  mutation saveContestProjects($contest: ContestProjectInput!) {
    saveContestProjects(contest: $contest) {
      status
      description
    }
  }
`;

// done
// updated
export const UPDATE_BASIC_CONTEST_INFO = gql`
  mutation updateContestBasicInfo($contest: UpdateContestBasicInfoInput!) {
    updateContestBasicInfo(contest: $contest) {
      status
      description
    }
  }
`;

// done
// updated
export const UPDATE_CONTEST_BACKGROUND_PICTURE = gql`
  mutation updateContestBackgroundPicture($contest: UpdateContestBackgroundPicInput!) {
    updateContestBackgroundPicture(contest: $contest) {
      status
      description
    }
  }
`;

// done
// updated
export const SAVE_CONTEST_SPONSORS = gql`
  mutation saveContestSponsors($contest: ContestSponsorInput!) {
    saveContestSponsors(contest: $contest) {
      status
      description
    }
  }
`;

// done
// updated
export const TOGGLE_SPONSORS_DISPLAY = gql`
  mutation toggleSponsorsDisplay($contest: ToggleContestSponsorInput!) {
    toggleSponsorsDisplay(contest: $contest) {
      status
      description
    }
  }
`;

// done
// updated
export const REMOVE_CONTEST_SPONSORS = gql`
  mutation removeContestSponsors($contest: ContestSponsorInput!) {
    removeContestSponsors(contest: $contest) {
      status
      description
    }
  }
`;

// done
// updated
export const SAVE_CONTEST_MEMBERS = gql`
  mutation saveContestMembers($contest: ContestMemberInput!) {
    saveContestMembers(contest: $contest) {
      status
      description
    }
  }
`;

// done
// updated
export const REMOVE_CONTEST_MEMBERS = gql`
  mutation removeContestMembers($contest: ContestMemberInput!) {
    removeContestMembers(contest: $contest) {
      status
      description
    }
  }
`;

// done
// updated
export const UPDATE_CONTEST_TAGS = gql`
  mutation updateContestTags($contest: ContestTagInput!) {
    updateContestTags(contest: $contest) {
      status
      description
    }
  }
`;
