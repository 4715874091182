import { bool } from 'prop-types';

const Line = ({ isLast }) => (
  <svg height="2" width="200">
    <line x1="0" y1="0" x2={isLast ? '100' : '200'} y2="0" style={{ stroke: '#989898', strokeWidth: 2 }} />
  </svg>
);

Line.propTypes = {
  isLast: bool,
};

Line.defaultProps = {
  isLast: false,
};

export default Line;
