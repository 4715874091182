import { useMutation } from '@apollo/client';
import { useEffect, useMemo } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CONTACT_EMAIL } from 'apollo/mutations/contact-mutation';
import { useSnackbar, useYupValidationResolver } from 'hooks';

import Contact from './Contact';
import { contactDefaultValues, contactSchemaValidation } from './helpers/contactYup';

const ContactContainer = () => {
  const { openSnackbarSuccess, openSnackbarError, enqueueSnackbarMultipleErrors } = useSnackbar();
  const { t } = useTranslation(['common', 'dialog']);
  const validationSchema = useMemo(() => contactSchemaValidation(t), []);
  const { register, handleSubmit, errors } = useForm({
    resolver: useYupValidationResolver(validationSchema),
    defaultValues: contactDefaultValues,
  });

  const [contactEmail] = useMutation(CONTACT_EMAIL);
  const text = {
    title: t('common:contactFormInformation.title'),
    share: t('common:contactFormInformation.share'),
    contact: t('common:contactFormInformation.contact'),
    email: t('common:contactFormInformation.contactEmail'),
  };

  const onSubmit = async ({ fullName, email, text }) => {
    try {
      const { data } = await contactEmail({
        variables: { message: { fullName, email, text } },
      });

      if (data) {
        openSnackbarSuccess(t('dialog:openSnackbarSuccess.contactSent'));
      }
    } catch (err) {
      openSnackbarError(t('dialog:openSnackbarError.somethingWrong'));
    }
  };

  useEffect(() => {
    enqueueSnackbarMultipleErrors(errors);
  }, [errors]);

  return <Contact text={text} handleSubmit={handleSubmit(onSubmit)} register={register} errors={errors} />;
};

export default ContactContainer;
