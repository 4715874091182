import { useQuery } from '@apollo/client';
import { Box, CircularProgress } from '@material-ui/core';
import { Redirect, useParams, useLocation, useHistory } from 'react-router-dom';

import { GET_CONTEST_BY_ID } from 'apollo/queries/contest-query';
import { CocoonHelmet } from 'components';
import { META_DATA } from 'helpers/metaData';

import PartnerContest from './PartnerContest';

const PartnerContestWrapper = () => {
  const { title, description, image, url } = META_DATA.CONTEST_PARTNER;
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { state } = useLocation();

  const {
    data: contestData,
    loading,
    error,
    refetch,
  } = useQuery(GET_CONTEST_BY_ID, {
    variables: { id },
  });

  if (state && state.isRefetchContestProjects) {
    refetch();
    history.push({
      pathname: location.pathname,
      state: { isRefetchContestProjects: false },
    });
  }

  if (loading) {
    return (
      <Box height="300px" width="100%" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress color="inherit" />
      </Box>
    );
  }

  if ((!loading && !contestData) || error) {
    return <Redirect to="/not-found" />;
  }

  return (
    <>
      <CocoonHelmet title={title} description={description} image={image} url={url} />
      <PartnerContest contest={contestData.contestById} />
    </>
  );
};

export default PartnerContestWrapper;
