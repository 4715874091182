import { Container, makeStyles } from '@material-ui/core';
import { useEffect } from 'react';

import { CocoonHelmet, Title, backToAnchor } from 'components';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    '& *': {
      color: theme.palette.text.secondary,
    },
  },
}));

const NotFoundErrorPage = () => {
  const classes = useStyles();

  const { t } = useTranslation();
  useEffect(() => {
    backToAnchor(document);
  }, []);

  return (
    <Container fixed className={classes.container}>
      <CocoonHelmet title={t('error.404')} />
      <Title level={3} title={t('error.notFoundResponse')} />
    </Container>
  );
};

export default NotFoundErrorPage;
