import { Avatar, Box, CardContent, CardHeader, Grid, IconButton, Typography } from '@material-ui/core';
import { bool, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Truncate } from 'components';
import { convertToSlug } from 'helpers/common';

import CommentExtra from '../extra/CommentExtra';

import useStyles from './ForumCardLayoutStyles';

const ForumCardLayout = ({
  log: { id, content, topic, project, totalLikes, isLikedByCurrentUser, totalReplies, user },
  desktop,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <Grid item xs={12}>
        <CardHeader
          avatar={
            <IconButton aria-label="settings" size="small">
              <Box
                component={Link}
                to={`/users/${user.id}/${convertToSlug(`${user.firstName} ${user.lastName}`)}/profile`}
              >
                <Avatar alt="avatar" className={classes.avatar} src={user.avatarLink} />
              </Box>
            </IconButton>
          }
          title={
            <>
              <Box
                component={Link}
                to={`/users/${user.id}/${convertToSlug(`${user.firstName} ${user.lastName}`)}/profile`}
              >
                <Typography component="span" variant="h6" className={classes.name}>
                  {user.firstName} {user.lastName}
                </Typography>
              </Box>
              <span className={classes.nameUpdated}>{totalReplies ? t('home.commentedOn') : t('home.repliedIn')}</span>
            </>
          }
          className={classes.cardHeaderTitle}
        />
      </Grid>
      <CardContent component={Grid} item xs={12} className={classes.content}>
        <Typography variant={desktop ? 'h5' : 'h6'}>
          <Box
            display="block"
            component={Link}
            to={`/projects/${project.id}/${convertToSlug(project.name)}#tab=forum&topic=${topic.id}&comment=${id}`}
            className={classes.title}
          >
            <Truncate lines={2} width={desktop ? 0 : 300}>
              {project.name}
            </Truncate>
          </Box>
        </Typography>
        <Typography variant="body1" color="textSecondary" className={classes.descriptionPadding}>
          <Truncate className={classes.commentText} lines={7}>
            {content}
          </Truncate>
        </Typography>
        <CommentExtra
          projectId={id}
          isLiked={isLikedByCurrentUser}
          replyCount={totalReplies}
          likeCount={totalLikes}
          topicLink={`/projects/${project.id}/${convertToSlug(project.name)}#tab=forum&topic=${topic.id}&reply=${id}`}
        />
      </CardContent>
    </>
  );
};

ForumCardLayout.propTypes = {
  log: object.isRequired,
  desktop: bool.isRequired,
};

export default ForumCardLayout;
