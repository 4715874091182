import { useMutation, useQuery } from '@apollo/client';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import _ from 'lodash';
import { bool, object, func } from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import {
  INVITE_PROJECT_MEMBER,
  REMOVE_PROJECT_MEMBER,
  CANCEL_PROJECT_MEMBER,
} from 'apollo/mutations/invite-member-mutation';
import { GET_PROJECT_MEMBER_INVITATIONS, USERS_BY_USERNAME_EMAIL } from 'apollo/queries/invite-member-query';

import DeleteProjectMemberDialog from '../delete-project-member/DeleteProjectMemberDialog';

import InviteContent from './components/InviteContent';
import useStyles from './InviteProjectMembersDialogStyle';

const InviteProjectMembersDialog = ({
  open,
  options,
  desktop,
  openSnackbarSuccess,
  openSnackbarWarning,
  handleCloseDialog,
}) => {
  const classes = useStyles();

  const match = useRouteMatch();
  const { id } = match.params;
  const projectId = +id;
  const [inviteProjectMembers] = useMutation(INVITE_PROJECT_MEMBER);

  const { t } = useTranslation(['common', 'dialog']);

  const [project, setProject] = useState({ id: 0 });
  useEffect(() => {
    setProject(options);
  }, [options]);

  const handleGoBack = () => {
    handleCloseDialog();
  };

  const [searchMember, setSearchMember] = useState('');
  const [openSearch, setOpenSearch] = useState(false);
  const [optionsMembers, setOptionsMembers] = useState([]);
  const [membersInvitation, setMembersInvitation] = useState([]);
  const [chosenMembers, setChosenMembers] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);

  const [optionsDelete, setOptionsDelete] = useState({
    userName: '',
    variables: { invitation: { projectId: '0', userId: '0' } },
    mutation: REMOVE_PROJECT_MEMBER,
    resultObjectName: 'removeProjectMember',
    clearAndRefetch: '',
  });

  const {
    loading,
    data: dataSearchMembers,
    refetch: refetchUpdates,
  } = useQuery(USERS_BY_USERNAME_EMAIL, {
    variables: { searchTerm: searchMember },
  });

  const { data: dataInvitedMembers, refetch: refetchInvitedMembers } = useQuery(GET_PROJECT_MEMBER_INVITATIONS, {
    variables: { projectId },
  });

  useEffect(() => {
    if (dataInvitedMembers) {
      setMembersInvitation(dataInvitedMembers.projectMemberInvitations);
    }
  }, [dataInvitedMembers]);

  const onSearchMemberChange = (e) => {
    if (e) {
      e.preventDefault();
    }
    setSearchMember(e.target.value);
  };

  function validateEmail(email) {
    // eslint-disable-next-line max-len
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const getSearchMembers = () => {
    refetchUpdates();
  };

  const getDebouncedSearchMembers = _.debounce(getSearchMembers, 700);

  useEffect(() => {
    if (openSearch) {
      getDebouncedSearchMembers();
    }
  }, [openSearch]);

  useEffect(() => {
    getDebouncedSearchMembers(searchMember);
  }, [searchMember]);

  useEffect(() => {
    if (dataSearchMembers) {
      setOptionsMembers(dataSearchMembers.usersByUsernameEmail);
    }
  }, [dataSearchMembers]);

  const onChangeChosenMembers = (value) => {
    setChosenMembers(value);
    setSearchMember('');
  };

  const onClickInvite = async (e) => {
    if (e) {
      e.preventDefault();
    }

    if (_.isEmpty(chosenMembers)) {
      openSnackbarWarning('Please choose member(s)');
    } else {
      const { data } = await inviteProjectMembers({
        variables: {
          invitations: {
            projectId,
            userIds: chosenMembers.map(({ id }) => id),
          },
        },
      });

      if (data) {
        openSnackbarSuccess(`${t('dialog:openSnackbarSuccess.memberInvited')}`);
        setChosenMembers([]);
        setSearchMember('');
        setMembersInvitation([]);
        setOptionsMembers([]);
        refetchInvitedMembers();
      }
    }
  };

  const onClickRemove = (e, info) => {
    if (e) {
      e.preventDefault();
    }
    const options = {
      userName: info.username,
      variables: { invitation: { projectId, userId: info.id } },
      mutation: _.toLower(info.status) === 'pending' ? CANCEL_PROJECT_MEMBER : REMOVE_PROJECT_MEMBER,
      resultObjectName: _.toLower(info.status) === 'pending' ? 'cancelProjectMember' : 'removeProjectMember',
    };
    setOptionsDelete(options);
    setOpenDelete(true);
  };

  const clearAndRefetch = () => {
    setMembersInvitation([]);
    refetchInvitedMembers();
  };

  const closeDeleteDialog = () => {
    setOpenDelete(false);
  };

  return open ? (
    <div>
      <DeleteProjectMemberDialog
        open={openDelete}
        closeDeleteDialog={closeDeleteDialog}
        clearAndRefetch={clearAndRefetch}
        options={optionsDelete}
      />
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="edit-profile-dialog-title"
        aria-describedby="edit-profile-description"
        className={classes.dialog}
        maxWidth="md"
      >
        <DialogTitle>
          <Grid container alignItems="center" alignContent="space-between">
            <Grid item xs>
              <Typography color="secondary" variant="h5">
                <Box display="flex" alignItems="center" className={classes.header}>
                  <EditIcon className={classes.icon} />
                  {t('common:button.invite')}
                </Box>
              </Typography>
            </Grid>
            <Grid item xs>
              <DialogActions>
                <Button onClick={() => handleGoBack()} variant="outlined" className={classes.button}>
                  {t('common:button.back')}
                </Button>
                <Button
                  autoFocus
                  color="primary"
                  variant="contained"
                  onClick={() => onClickInvite()}
                  className={classes.button}
                >
                  {t('common:button.invite')}
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <InviteContent
            searchMember={searchMember}
            onSearchMemberChange={onSearchMemberChange}
            project={project}
            setOpenSearch={setOpenSearch}
            openSearch={openSearch}
            loading={loading}
            optionsMembers={optionsMembers}
            membersInvitation={membersInvitation}
            onChangeChosenMembers={onChangeChosenMembers}
            chosenMembers={chosenMembers}
            onClickRemove={onClickRemove}
            desktop={desktop}
          />
        </DialogContent>
      </Dialog>
    </div>
  ) : null;
};

InviteProjectMembersDialog.propTypes = {
  open: bool.isRequired,
  desktop: bool.isRequired,
  options: object.isRequired,
  openSnackbarSuccess: func.isRequired,
  handleCloseDialog: func.isRequired,
};

export default InviteProjectMembersDialog;
