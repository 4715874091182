import { Box, Divider, Grid, Typography, Link, Button, CircularProgress, makeStyles } from '@material-ui/core';
import { DescriptionOutlined as DescriptionOutlinedIcon } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import { arrayOf, object, bool } from 'prop-types';
import { useState, useEffect } from 'react';

import { Truncate } from 'components';
import { cookieUserId } from 'helpers/auth';
import { useSnackbar } from 'hooks';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(1, 0),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      padding: 0,
    },
  },
  linkAttachment: {
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(0.5, 0),
    '& svg': {
      marginRight: theme.spacing(1),
    },
    cursor: 'pointer',
  },
  divider: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
    },
  },
  text: {
    fontWeight: theme.typography.fontWeightBold,
    color: '#575757',
  },
  btnFluid: {
    color: '#575757',
    borderColor: '#575757',
    borderRadius: '6px',
    borderWidth: '1px',
    width: '100%',
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(1, 3),
    textTransform: 'none',
    '&:hover': {
      background: 'transparent',
      '& *': {
        fontWeight: 'bold',
      },
    },
  },
}));

const NUMBER_DISPLAY = 6;

const BriefFiles = ({ files: propFiles, loadingAttachments, errorAttachments, isNewProject, desktop }) => {
  const classes = useStyles();

  const { openSnackbarWarning } = useSnackbar();

  const [files, setFiles] = useState(propFiles);

  const { t } = useTranslation(['common', 'dialog']);

  const handleSetFiles = (isCollapsed = false) => {
    if (isCollapsed) {
      setFiles(propFiles);
    } else {
      setFiles(_.take(propFiles, NUMBER_DISPLAY));
    }
  };

  useEffect(() => {
    handleSetFiles();
  }, [propFiles]);

  const isCollapsed = propFiles !== null && files.length === NUMBER_DISPLAY;

  const handleDownload = (e) => {
    e.stopPropagation();

    if (!cookieUserId) {
      openSnackbarWarning(t('dialog:openSnackbarWarning.reminderLoginDownload'));
    }
  };

  const renderFiles = () =>
    _.isEmpty(files) && !loadingAttachments ? (
      <Box className={classes.text}>{t('common:defaultInformation.noFiles')}</Box>
    ) : (
      <>
        {_.map(files, (file, index) => (
          <Typography
            key={index}
            variant="h6"
            className={classes.linkAttachment}
            gutterBottom
            component={Link}
            onClick={handleDownload}
            href={cookieUserId && file.assetLink}
            download={cookieUserId && file.name}
          >
            <Box display="flex" alignItems="center">
              <Box flexGrow={1} display="flex" alignItems="center" style={{ wordBreak: 'break-all' }}>
                <DescriptionOutlinedIcon fontSize="small" />
                <Truncate lines={2}>{file.name}</Truncate>
              </Box>
              <Box className={classes.text}>{t('common:button.download')}</Box>
            </Box>
          </Typography>
        ))}
        {propFiles.length > NUMBER_DISPLAY && (
          <Box textAlign="center" mt={2}>
            <Button
              size="small"
              variant="outlined"
              className={classes.btnFluid}
              onClick={() => handleSetFiles(isCollapsed)}
            >
              {isCollapsed
                ? `${t('common:common.showAll')} ${propFiles.length} ${t('common:common.files')}`
                : t('common:common.collapse')}
            </Button>
          </Box>
        )}
      </>
    );

  return (
    <Grid item xs={12} className={classes.grid}>
      <Typography variant={desktop ? 'h5' : 'h6'} color="textSecondary" gutterBottom>
        {t('common:project.filesComponents')}
      </Typography>
      <Divider className={classes.divider} />
      {!isNewProject && propFiles === null ? (
        <Box height="100px" width="100%" display="flex" alignItems="center" justifyContent="center">
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        renderFiles()
      )}
      {errorAttachments && (
        <Alert variant="outlined" severity="error">
          {errorAttachments.message}
        </Alert>
      )}
    </Grid>
  );
};

BriefFiles.propTypes = {
  files: arrayOf(object),
  loadingAttachments: bool,
  errorAttachments: object,
  isNewProject: bool.isRequired,
  desktop: bool.isRequired,
};

BriefFiles.defaultProps = {
  files: null,
  loadingAttachments: false,
  errorAttachments: undefined,
};

export default BriefFiles;
