import { Grid, Box, makeStyles, Typography } from '@material-ui/core';
import { string, arrayOf, object } from 'prop-types';

import { CocoonAvatar } from 'components';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0px 16px',
  },
  itemWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 10,
  },
  title: {
    ...theme.typography.body1,
    fontSize: '1.3rem',
    fontWeight: 900,
    color: '#989898',
    margin: theme.spacing(1, 0),

    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
}));

const ContestInfo = ({ title, items }) => {
  const classes = useStyles();
  return (
    <Box flex={1} mb={1.5}>
      <Typography variant="h6" className={classes.title}>
        {title}
      </Typography>
      <hr style={{ marginBottom: 10 }} />
      {items.map((item) => (
        <Grid container className={classes.itemWrapper} key={+item.id}>
          <Grid item xs={2}>
            <CocoonAvatar src={item.avatarLink} alt={item.name} />
          </Grid>
          <Grid item xs={10}>
            <Typography>{item.name}</Typography>
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};

ContestInfo.propTypes = {
  title: string.isRequired,
  items: arrayOf(object).isRequired,
};

const MobileContestInfo = ({ applicants, sponsors }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <ContestInfo title="Applicants" items={applicants} />
      <ContestInfo title="Sponsors" items={sponsors} />
    </Box>
  );
};

MobileContestInfo.propTypes = {
  applicants: arrayOf(object).isRequired,
  sponsors: arrayOf(object).isRequired,
};

export default MobileContestInfo;
