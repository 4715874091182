import { Collapse, Box, Button, Typography, GridList, GridListTile, Link, makeStyles } from '@material-ui/core';
import { DescriptionOutlined as DescriptionOutlinedIcon } from '@material-ui/icons';
import _ from 'lodash';
import { arrayOf, object } from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Image } from 'components';
import mimeTypes from 'helpers/mimeTypes';
import { openLightbox } from 'reducers/lightboxReducer';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  button: {
    padding: 0,
    '& h6': {
      fontSize: '15px',
    },
  },
  gridList: {
    '& .MuiGridListTile-tile > div': {
      backgroundColor: 'rgba(0,0,0,0.1)',
      borderRadius: 6,
    },
  },
});

const TabPanelAttachments = ({ attachments }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const handleOpenLightbox = (images, index) => dispatch(openLightbox(images, index));

  const { png, jpg, gif, bmp, ico, tif, svg } = mimeTypes;

  const files = attachments ? [...attachments] : null;
  const images = files ? _.remove(files, (file) => [png, jpg, gif, bmp, ico, tif, svg].includes(file.assetType)) : null;

  const [expanded, setExpanded] = useState(true);

  const { t } = useTranslation();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleImageZoom = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    handleOpenLightbox(images, index);
  };

  const handleDownload = (e) => {
    e.stopPropagation();
  };

  const renderAttachments = () => {
    const renderFiles = _.map(files, (file) => (
      <Typography key={file.id} variant="h6" className={classes.linkAttachment} gutterBottom>
        <Box
          component={Link}
          display="flex"
          href={file.assetLink}
          title={file.name}
          download={file.name}
          aria-label="download"
          onClick={handleDownload}
        >
          <Box flexGrow={1} display="flex" alignItems="center" style={{ wordBreak: 'break-all' }}>
            <Box component={DescriptionOutlinedIcon} mr={1} />
            {file.name}
          </Box>
          <Box color="black">{t('button.download')}</Box>
        </Box>
      </Typography>
    ));

    const renderImages = (
      <GridList cellHeight="auto" className={classes.gridList} cols={3}>
        {_.map(images, (img, index) => (
          <GridListTile key={img.id} cols={1}>
            <Image
              src={img.assetLink}
              alt={img.name}
              isHoverable
              hasZoom
              hasDownload
              onZoomCLick={(e) => handleImageZoom(e, index)}
              onClick={(e) => handleImageZoom(e, index)}
              onDownloadClick={handleDownload}
              aspectRatio={1}
            />
          </GridListTile>
        ))}
      </GridList>
    );

    return (
      <>
        {renderFiles}
        {renderImages}
      </>
    );
  };

  return (
    <>
      <Box mb={1} display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="caption" color="textSecondary" gutterBottom>
          {t('common.attachments')}
        </Typography>
        <Button onClick={handleExpandClick} color="primary" className={classes.button} disableRipple>
          <Typography variant="h6" color="primary">
            {t('button.showHide')}
          </Typography>
        </Button>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {_.isEmpty(attachments) ? <b>{t('defaultInformation.noAttachment')}</b> : renderAttachments()}
      </Collapse>
    </>
  );
};

TabPanelAttachments.propTypes = {
  attachments: arrayOf(object).isRequired,
};

export default TabPanelAttachments;
