import { Box } from '@material-ui/core';
import { bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CocoonEditor } from 'components';

const CreateProjectTutorial = ({ desktop, control }) => {
  const { t } = useTranslation();

  const tutorial = t('project.tutorial');

  const tutorialMobile = t('project.tutorialMobile');

  return (
    <Box pt={0.75}>
      <CocoonEditor data={desktop ? tutorial : tutorialMobile} />
    </Box>
  );
};

CreateProjectTutorial.propTypes = {
  desktop: bool.isRequired,
};

export default CreateProjectTutorial;
