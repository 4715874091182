import { Grid, makeStyles, Typography } from '@material-ui/core';
import { object, bool, string, arrayOf } from 'prop-types';
import { useState } from 'react';

import { CocoonProjectWithOverlay } from 'components';
import { CONTEST_TABS } from 'helpers/constants';

import MobileContestInfo from './components/mobile-info/MobileContestInfo';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '24px 12px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 12px',
    },
  },
  projectWrapper: {
    padding: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    borderRadius: '6px',
  },
  empty: {
    padding: '24px 24px',
    ...theme.typography.h4,
  },
}));

const ContestProjects = ({ currentTab, projects, desktop }) => {
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(-1);

  const handleMouseOver = (index) => {
    setCurrentIndex(index);
  };

  const handleMouseOut = () => {
    setCurrentIndex(-1);
  };

  if (!projects || projects.length === 0) {
    return (
      <Grid className={classes.container} container>
        <Typography className={classes.empty}> This contest does not have any projects yet.</Typography>
      </Grid>
    );
  }

  if (!desktop && currentTab === CONTEST_TABS.APPLICANTS_SPONSORS) {
    return <MobileContestInfo applicants={projects.members} sponsors={projects.members} />;
  }

  return (
    <Grid container className={classes.container}>
      {projects.map((project, index) => (
        <Grid
          className={classes.projectWrapper}
          key={project.id}
          onFocus={() => handleMouseOver(index)}
          onMouseOver={() => handleMouseOver(index)}
          onMouseLeave={() => handleMouseOut()}
          item
          xs={!desktop ? 6 : 3}
        >
          <CocoonProjectWithOverlay project={project} isHovered={currentIndex === index} desktop={desktop} />
        </Grid>
      ))}
    </Grid>
  );
};

ContestProjects.propTypes = {
  currentTab: string.isRequired,
  desktop: bool.isRequired,
  projects: arrayOf(object).isRequired,
};

export default ContestProjects;
