import * as Sentry from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations';

import { COOKIES } from 'helpers/constants';
import { getJSONCookie } from 'helpers/cookies';

const { id, email, username } = getJSONCookie(COOKIES.names.userInfo);

const addUserContext = (_id, _email) => {
  if (!process.env.ALLOW_SENTRY) return;

  Sentry.configureScope((scope) => {
    scope.setUser({ id: +_id, email: _email, username });
  });
};

if (process.env.ALLOW_SENTRY) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    release: process.env.CURRENT_RELEASE_VERSION,
    integrations: [new CaptureConsole({ levels: ['warn', 'error'] })],
  });
}

if (id) addUserContext(id, email, username);
