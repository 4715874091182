import { Typography, Grid, Card, CardContent, Divider, Box, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { number, func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { CocoonEditor } from 'components';
import { CK_EDITOR_IMAGE_UPLOAD_URL, CK_EDITOR_UPLOAD_TYPES } from 'helpers/constants';

import { CRUD_STATUS } from '../../../../helpers/constants';

const useStyles = makeStyles((theme) => ({
  fullSize: {
    width: '100%',
    height: '100%',
    paddingTop: theme.spacing(2),
  },
  detailStyle: {
    ...theme.typography.body1,
    color: '#575757',
  },
  cardStyle: {
    backgroundColor: '#ffffff',
  },
  title: {
    ...theme.typography.h6,
    fontWeight: theme.typography.fontWeightBold,
    color: '#575757',
  },
  divider: {
    width: '100%',
    backgroundColor: '#989898',
    marginTop: theme.spacing(1),
    border: '1px solid #989898',
  },
  logFieldStyle: {
    marginTop: theme.spacing(1),
  },
  buttonStyle: {
    display: 'flex',
    paddingRight: 0,
    paddingTop: 0,
    justifyContent: 'flex-end',
  },
  inputDescription: {
    ...theme.typography.body1,
    color: theme.palette.common.black,
    width: '100%',
    '& > *:first-child': {
      '& >  * + *': {
        '&::-webkit-input-placeholder': {
          fontStyle: 'italic',
        },
        '&:-moz-placeholder': {
          fontStyle: 'italic',
        },
        '&::-moz-placeholder': {
          fontStyle: 'italic',
        },
        '&:-ms-input-placeholder': {
          fontStyle: 'italic',
        },
        textAlign: 'center',
      },
    },
    '&:focus, &:hover': {
      borderColor: '#fff',
      color: '#fff',
    },
  },
  buttonSave: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  buttonCancel: {
    backgroundColor: 'white',
    color: '#575757',
  },
}));

const UpdateDetail = ({ logDetail, crudStatus, handleChangeLogDetail }) => {
  const classes = useStyles();

  const history = useHistory();
  const projectId = +history.location.pathname.split('/')[2];

  const { t } = useTranslation('panel');
  const renderContent = () =>
    _.isEmpty(logDetail) ? (
      <Box fontWeight="fontWeightBold">{t('updateDetail.noContent')}</Box>
    ) : (
      <Box pt={0.75}>
        <CocoonEditor data={logDetail} />
      </Box>
    );

  return (
    <div className={classes.fullSize}>
      <Card className={classes.cardStyle}>
        <CardContent>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} container direction="row">
              <Grid item xs={9}>
                <Typography className={classes.title} variant="subtitle1">
                  {t('updateDetail.updateDetails')}
                </Typography>
              </Grid>
            </Grid>
            <Divider variant="middle" className={classes.divider} />
            <Grid item xs={12} className={classes.logFieldStyle}>
              {crudStatus === CRUD_STATUS.VIEW ? (
                renderContent()
              ) : (
                <CocoonEditor
                  data={logDetail}
                  handleChangeData={handleChangeLogDetail}
                  uploadUrl={CK_EDITOR_IMAGE_UPLOAD_URL}
                  type={CK_EDITOR_UPLOAD_TYPES.PROJECT_LOG}
                  projectId={projectId}
                />
                // <TextField
                //   multiline
                //   value={logDetail}
                //   onChange={handleChangeLogDetail}
                //   placeholder="Input your update description"
                //   variant="outlined"
                //   className={classes.inputDescription}
                // />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

UpdateDetail.propTypes = {
  logDetail: string.isRequired,
  crudStatus: number.isRequired,
  handleChangeLogDetail: func.isRequired,
};

export default UpdateDetail;
