import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyProjects: {
    ...theme.typography.h4,
  },
  accordionWrapper: {
    padding: 10,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  accordion: {
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
  },
  accordionSummaryContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  listTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  saveIcon: {
    marginRight: theme.spacing(2),
    color: '#989898',
    fontSize: 35,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  heading: {
    ...theme.typography.h3,
    color: '#989898',
    fontWeight: 700,
    fontSize: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
  noProject: {
    ...theme.typography.h6,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
  project: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
  },
  removeIconWrapper: {
    position: 'absolute',
    top: 10,
    right: 5,
    zIndex: 1300,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  img: {
    '& > div': {
      backgroundColor: 'rgba(0,0,0,0.1)',
      borderRadius: 6,
    },
  },
}));

export default useStyles;
