import DateFnsUtils from '@date-io/date-fns';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { object, func, bool } from 'prop-types';
import { Controller } from 'react-hook-form';

import useStyles from '../../formStyle';

import { useTranslation } from 'react-i18next';

const ExperienceForm = ({
  watch,
  register,
  control,
  editing,
  adding,
  errors,
  handleAddEditExperience,
  handleDeleteExperience,
  closeForm,
  openForm,
  desktop,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['common', 'dialog']);
  return (
    <>
      {!editing && !adding && (
        <Button onClick={() => openForm('add')} variant="contained" color="primary">
          <Typography className={classes.saveExperienceButton}>{t('dialog:experience.addExperience')}</Typography>
        </Button>
      )}
      {(adding || editing) && (
        <>
          <input name="id" ref={register} type="hidden" />
          <div className={classes.editInfoWrapper}>
            <Typography className={classes.editInfoTitle}>{t('dialog:experience.yourRole')}</Typography>
            <hr className={classes.divider} />
            <TextField
              autoFocus
              className={classes.editInfoTextField}
              fullWidth
              variant="outlined"
              inputRef={register}
              name="role"
              helperText={errors.role?.message}
              error={!!errors.role?.message}
            />
          </div>
          <div className={classes.editInfoWrapper}>
            <Grid container spacing={desktop ? 2 : 1}>
              <Grid item xs={desktop ? 4 : 12}>
                <Typography className={classes.editInfoTitle}>{t('dialog:experience.company')}</Typography>
                <hr className={classes.divider} />
                <TextField
                  className={classes.editInfoTextField}
                  fullWidth
                  variant="outlined"
                  inputRef={register}
                  name="company"
                  helperText={errors.company?.message}
                  error={!!errors.company?.message}
                />
              </Grid>
              <Grid item xs={desktop ? 4 : 12}>
                <Typography className={classes.editInfoTitle}>{t('dialog:experience.startDate')}</Typography>
                <hr className={classes.divider} />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Controller
                    as={<KeyboardDatePicker />}
                    className={classes.editInfoTextField}
                    variant="inline"
                    inputVariant="outlined"
                    fullWidth
                    InputAdornmentProps={{ position: 'end' }}
                    disableFuture
                    format="MM/yyyy"
                    views={['month', 'year']}
                    name="startDate"
                    control={control}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={desktop ? 4 : 12}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography className={classes.editInfoTitle}>{t('dialog:experience.endDate')}</Typography>
                  <Typography className={classes.editInfoTitle}>{t('dialog:experience.stillWorking')}</Typography>
                </div>
                <hr className={classes.divider} />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Controller
                    as={<KeyboardDatePicker />}
                    control={control}
                    className={classes.editInfoTextField}
                    variant="inline"
                    inputVariant="outlined"
                    fullWidth
                    InputAdornmentProps={{ position: 'end' }}
                    disableFuture
                    format="MM/yyyy"
                    views={['month', 'year']}
                    name="endDate"
                    minDate={watch('startDate')}
                    minDateMessage={t('dialog:experience.minDateMessage')}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </div>

          <div className={classes.editInfoWrapper}>
            <Typography className={classes.editInfoTitle}>{t('dialog:experience.jobDetails')}</Typography>
            <hr className={classes.divider} />
            <TextField
              className={classes.editInfoTextField}
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              name="description"
              inputRef={register}
            />
          </div>

          <div className={classes.editInfoWrapper}>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Typography className={classes.saveExperience} onClick={handleAddEditExperience}>
                {!editing ? t('dialog:experience.addExperience') : t('common:button.saveEdit')}
              </Typography>
              <Typography onClick={closeForm} className={classes.cancelExperience}>
                {t('common:button.cancelAndCollapse')}
              </Typography>
              {!adding && (
                <Typography className={classes.deleteExperience} onClick={handleDeleteExperience}>
                  {t('dialog:experience.deleteExperience')}
                </Typography>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

ExperienceForm.propTypes = {
  editing: bool.isRequired,
  adding: bool.isRequired,
  desktop: bool.isRequired,
  errors: object.isRequired,
  handleAddEditExperience: func.isRequired,
  handleDeleteExperience: func.isRequired,
  openForm: func.isRequired,
  closeForm: func.isRequired,
};

export default ExperienceForm;
