import { string } from 'prop-types';
import { Helmet } from 'react-helmet';

import favicon from '@dist/favicon.svg';
import logoH from '@images/logo/logo-h.svg';

import { buildLink, buildMeta, buildTitle } from './helpers/buildTags';

const titleDefault = 'Project Cocoon place to showcase your projects and build your profiles';
const descriptionDescription =
  'Project Cocoon is a community of project makers helps bring your ideas to reality. In here, we offer showcase your projects, build your profiles.';
const cocoonIcon = 'https://cocoon-icon.s3.us-west-2.amazonaws.com/cocoon.png';
const width = '630';
const height = '630';
const locale = 'en_US';
const CocoonHelmet = ({ title, description = title, image = logoH, url, stylesheet, canonical, projectTags }) => (
  <Helmet>
    {/* <!-- HTML Meta Tags --> */}
    <meta charSet="utf-8" />
    {/* <link rel="icon" href="favicon.svg" />
    <link
      rel="stylesheet"
      type="text/css"
      href="https://fonts.googleapis.com/css?family=Montserrat|Open+Sans:400,700&display=swap"
    />
    <link rel="stylesheet" type="text/css" href="https://fonts.googleapis.com/icon?family=Material+Icons" /> */}
    {buildLink('icon', favicon)}
    {title ? buildTitle(title) : titleDefault}
    {description ? buildMeta('description', description + ' ' + projectTags) : descriptionDescription}
    {buildMeta('google-site-verification', 'HieSPs-CzxWDXKKI2xKDQ2QYqJUuGqVbzOob_95nArI')}
    {buildMeta('google-site-canonical', 'https://stage.projectcocoon.org')}
    {buildMeta('viewport', 'minimum-scale=1, initial-scale=1, width=device-width')}
    {buildMeta(
      'viewport',
      'width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no, shrink-to-fit=no',
    )}
    {stylesheet && buildLink('stylesheet', stylesheet)}
    {canonical && buildLink('canonical', canonical)}
    {buildLink('robots', 'index,follow')}
    {/* <!-- Google / Search Engine Tags --> */}
    {buildMeta('name', title)}
    {buildMeta('description', description)}
    {buildMeta('image', image)}
    {/* <!-- Facebook Meta Tags --> */}
    {buildMeta('og:url', url || window.location.href)}
    {buildMeta('og:type', 'website')}
    {buildMeta('og:title', title)}
    {buildMeta('og:description', description)}
    {buildMeta('og:image', image)}
    {buildMeta('og:locale', locale)}
    {buildMeta('og:image:alt', title)}
    {buildMeta('og:image:width', width)}
    {buildMeta('og:image:height', height)}
    {buildMeta('fb:app_id', '210195964320041')}
    {/* <!-- Twitter Meta Tags --> */}
    {buildMeta('twitter:card', 'summary_large_image')}
    {buildMeta('twitter:title', title)}
    {buildMeta('twitter:description', description)}
    {buildMeta('twitter:image', image)}x
  </Helmet>
);

CocoonHelmet.propTypes = {
  title: string,
  description: string,
  stylesheet: string,
  viewport: string,
  image: string,
  url: string,
};

CocoonHelmet.defaultProps = {
  title: undefined,
  description: undefined,
  stylesheet: undefined,
  viewport: undefined,
  image: logoH,
  url: window.location.href,
};

export default CocoonHelmet;
