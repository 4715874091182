import { makeStyles } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  searchBar: {
    height: '80px',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.04),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'white',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  focused: {
    '& > div': {
      width: 400,
    },
  },
  input: {
    padding: theme.spacing(0.5, 1, 0.5, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    '& > div::before': {
      borderBottom: 'none',
      transition: 'none',
    },
    '& div:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      width: 200,
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 2),
    },
  },
  option: {
    color: theme.palette.text.secondary,
  },
  loading: {
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
