import {
  Box,
  FormControl,
  FormLabel,
  OutlinedInput,
  List,
  IconButton,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Cancel as CancelIcon } from '@material-ui/icons';
import _ from 'lodash';
import { useState } from 'react';

import { getDisplayName } from 'helpers/common';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& legend': {
      ...theme.typography.caption2,
      marginBottom: theme.spacing(1),

      '& > span.MuiFormLabel-asterisk': {
        color: '#C53C2A',
      },
    },
    '& label > span:last-child': {
      ...theme.typography.button,
      fontSize: '14px',
      fontWeight: 'normal',
    },
    '& legend, & legend.Mui-focused, & label > span:last-child': {
      color: '#414141',
    },
    '& label > span.Mui-checked': {
      color: '#C34674',
    },
  },
  listTitle: {
    ...theme.typography.caption2,
    color: '#414141',
  },
  text: {
    fontWeight: theme.typography.fontWeightBold,
    color: '#575757',
  },
}));

const PermissionSettings = () => {
  const classes = useStyles();
  const { t } = useTranslation('dialog');
  const [userString, setUserString] = useState('');

  const handleChangeUserString = ({ target: { value } }) => {
    setUserString(value);
  };

  const users = [];

  return (
    <>
      <Box component={FormControl} mt={2} className={classes.formControl} fullWidth required>
        <FormLabel component="legend">{t('listProjects.addUser')}</FormLabel>
        <OutlinedInput
          id="user-input"
          value={userString}
          onChange={handleChangeUserString}
          placeholder={t('listProjects.enterUserNameOrEmail')}
        />
      </Box>
      <Box mt={2}>
        <Typography className={classes.listTitle}>{t('listProjects.curatorsList')}</Typography>
        <div>
          <List dense>
            {_.isEmpty() ? (
              <Box className={classes.text}>{t('listProjects.noUser')}</Box>
            ) : (
              _.map(users, (user) => {
                const fullname = getDisplayName(user);
                return (
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar src={user.avatarLink} alt={user.fullname} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography variant="h6" color="textSecondary">
                          {fullname}
                        </Typography>
                      }
                    />
                    <ListItemText
                      primary={
                        <Typography variant="caption" color="textSecondary" className="listItemName">
                          {user.email}
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete">
                        <CancelIcon color="primary" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })
            )}
          </List>
        </div>
      </Box>
    </>
  );
};

PermissionSettings.propTypes = {};

export default PermissionSettings;
