import { Grid, IconButton, Typography, makeStyles } from '@material-ui/core';
import { Edit as EditIcon, Menu as MenuIcon } from '@material-ui/icons';
import { func, arrayOf, object, bool } from 'prop-types';

const useStyles = makeStyles((theme) => ({
  editInfoWrapper: {
    marginBottom: '20px',
  },
  experienceRowWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '68px',
    borderRadius: '6px',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.1)',
    borderColor: 'white',
    background: '#ffffff',
    marginTop: '2%',
  },
  experienceWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingLeft: '20px',
  },
  experienceButton: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: 'solid 0.5px #e6e6e6',
  },
  experienceRole: {
    ...theme.typography.h1,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '900',
    color: '#575757',
  },
  experienceDuration: {
    ...theme.typography.h1,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#575757',
  },
  editExperienceIconWrapper: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const Experiences = ({ experiences, openForm, desktop }) => {
  const classes = useStyles();
  return (
    <div className={classes.editInfoWrapper}>
      <Grid container>
        {experiences.map((experience, index) => (
          <div key={index} className={classes.experienceRowWrapper}>
            <Grid className={classes.experienceButton} item xs={desktop ? 1 : 2}>
              <IconButton>
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid className={classes.experienceWrapper} item xs={desktop ? 11 : 10}>
              <Typography className={classes.experienceRole}>{experience.role}</Typography>
              <Typography className={classes.experienceDuration}>
                {experience.company} • {experience.duration}{' '}
              </Typography>
            </Grid>
            <Grid className={classes.editExperienceIconWrapper}>
              <IconButton onClick={() => openForm('edit', experience)}>
                <EditIcon />
              </IconButton>
            </Grid>
          </div>
        ))}
      </Grid>
    </div>
  );
};

Experiences.defaultProps = {
  experiences: [],
};

Experiences.propTypes = {
  experiences: arrayOf(object),
  openForm: func.isRequired,
  desktop: bool.isRequired,
};

export default Experiences;
