import { Box, Grid, CardMedia, CardContent, makeStyles } from '@material-ui/core';
import { bool, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { getImg, convertToSlug, hex2rgba } from 'helpers/common';

import ProjectCardInfo from '../info/ProjectCardInfo';
import ProjectCardStats from '../stats';

const HEIGHT_DESKTOP = 214;
const HEIGHT_MOBILE = 200;

const useStyles = makeStyles((theme) => ({
  cover: {
    maxWidth: '100%',
    width: '100%',
    backgroundColor: '#eee',
    minHeight: HEIGHT_DESKTOP,
    // height: HEIGHT_DESKTOP,
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      minHeight: HEIGHT_MOBILE,
      height: HEIGHT_MOBILE,
    },
  },
  ribbon: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: theme.spacing(0.5, 2.5),
    textAlign: 'center',
    transform: 'translateY(-100%) rotate(90deg) translateX(70.71067811865476%) rotate(-45deg)',
    transformOrigin: 'bottom right',

    /* Demo styles */
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: '50%',
    letterSpacing: 1,
    color: theme.palette.common.white,
    boxShadow: `inset 0 0 0 2000px ${hex2rgba('#1161a3', 0.7)}`,
  },
  coverStats: {
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,.5)',
      '& h6, svg': {
        color: '#ddd',
      },
    },
  },
  content: {
    padding: theme.spacing(0, 3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 0),
    },
  },
  media: {
    height: '100%',
    [theme.breakpoints.down('sm')]: { height: HEIGHT_MOBILE },
  },
  mediaDefault: {
    height: HEIGHT_DESKTOP,
    backgroundSize: 200,
    [theme.breakpoints.down('sm')]: { height: HEIGHT_MOBILE },
  },
  collapse: {
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.6)',
    marginTop: theme.spacing(-3.5),
    zIndex: 3,
    '& > button': {
      color: '#fff',
      '& > span': {
        justifyContent: 'left',
        fontWeight: 400,
      },
    },
    '& > div': {
      backgroundColor: '#fff',
      border: '1px solid #989898',
    },
  },
}));

const ProjectCardLayout = ({
  project: {
    id,
    owner,
    ownerId,
    isBookmarkedByUser,
    name,
    description,
    assetLink,
    updatedAt,
    tags,
    totalLikes,
    isFeatured,
    ...stats
  },
  desktop,
}) => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <>
      <Grid
        item
        xs={desktop ? 3 : 12}
        className={classes.cover}
        component={Link}
        to={`/projects/${id}/${convertToSlug(name)}`}
      >
        <CardMedia
          image={getImg(assetLink)}
          alt={assetLink}
          title={name}
          className={assetLink ? classes.media : classes.mediaDefault}
        />
        {isFeatured && (
          <div className={classes.ribbon}>
            <span>{t('common.featured')}</span>
          </div>
        )}
        {!desktop && (
          <Box
            component={Grid}
            item
            xs={12}
            p={desktop ? 0 : 0.5}
            container
            alignItems="center"
            className={classes.coverStats}
          >
            <ProjectCardStats
              id={+id}
              isBookmarkedByUser={isBookmarkedByUser}
              name={name}
              ownerId={ownerId}
              totalLikes={totalLikes}
              stats={stats}
              desktop={desktop}
            />
          </Box>
        )}
      </Grid>
      {desktop ? (
        <>
          <CardContent component={Grid} item xs={6} container className={classes.content}>
            <ProjectCardInfo
              name={name}
              owner={owner}
              id={+id}
              description={description || ''}
              updatedAt={updatedAt}
              tags={tags}
              isBookmarkedByUser={isBookmarkedByUser}
              desktop={desktop}
            />
          </CardContent>
          <Grid item xs={3}>
            <ProjectCardStats
              id={+id}
              isBookmarkedByUser={isBookmarkedByUser}
              name={name}
              ownerId={ownerId}
              totalLikes={totalLikes}
              stats={stats}
              desktop={desktop}
            />
          </Grid>
        </>
      ) : (
        <CardContent component={Grid} item xs={12} className={classes.content}>
          <ProjectCardInfo
            name={name}
            owner={owner}
            ownerId={ownerId}
            id={+id}
            description={description || ''}
            updatedAt={updatedAt}
            tags={tags}
            isBookmarkedByUser={isBookmarkedByUser}
            desktop={desktop}
          />
        </CardContent>
      )}
    </>
  );
};

ProjectCardLayout.propTypes = {
  project: object.isRequired,
  desktop: bool.isRequired,
};

export default ProjectCardLayout;
