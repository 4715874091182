// TODO: refactor this for contest stats
import { Grid, makeStyles } from '@material-ui/core';
import {
  VisibilityOutlined as VisibilityOutlinedIcon,
  FavoriteBorderOutlined as FavoriteBorderOutlinedIcon,
  SupervisedUserCircle as CollaboratorsIcon,
  Group as FollowingIcon,
  RssFeed as FollowersIcon,
  EmojiFlags as ProjectsIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import { number, bool, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { PROFILE_TYPES } from 'helpers/constants';

import StatsItem from './components/item/StatsItem';

const useStyles = makeStyles((theme) => ({
  interactions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  interactionsMobile: {
    zIndex: 1,
    padding: theme.spacing(1.5, 1.5, 1),
  },
  interactionsTitles: {
    color: '#414141',
    fontSize: '15px',
    display: 'block',
  },
  interactionsCount: {
    color: '#414141',
    fontSize: '20px',
    fontWeight: 'bold',
  },
}));

// CocoonStats is being called twice for Contest
export default function CocoonStats({
  desktop,
  profileType,
  // contest
  viewsCount,
  // contestSponsorsCount,
  contestFollowersCount,
  contestCuratorsCount,
  contestProjectsCount,
  // profile(partner/user)
  profileViewsCount,
  likesCount,
  followers,
  following,
}) {
  const classes = useStyles();

  const { t } = useTranslation();

  // use nullish coalescing ?? instead of || because ?? treats null and undefined as falsy, || treats 0 as falsy
  // hence 0 || '--' will return '--' because 0 is falsy and '--' is truthy (non-empty string)
  // in the future, when stats data is an object, rewrite this function
  const statsData = () => {
    if (profileType === PROFILE_TYPES.PARTNER_PROFILE) {
      return [
        {
          icon: CollaboratorsIcon,
          label: 'Curators',
          data: contestCuratorsCount ?? '--',
        },
        {
          icon: FollowersIcon,
          label: 'Followers',
          data: followers ?? '--',
        },
        {
          icon: VisibilityOutlinedIcon,
          label: 'Views',
          data: profileViewsCount ?? '--',
        },
      ];
    } else if (profileType === PROFILE_TYPES.CONTEST_PROFILE) {
      return [
        {
          icon: ProjectsIcon,
          label: 'projects',
          data: contestProjectsCount ?? '--',
        },
        {
          icon: CollaboratorsIcon,
          label: 'curators',
          data: contestCuratorsCount ?? '--',
        },
        {
          icon: VisibilityOutlinedIcon,
          label: 'views',
          data: viewsCount ?? '--',
        },
        {
          icon: FollowersIcon,
          label: 'followers',
          data: contestFollowersCount ?? '--',
        },
      ];
    }

    return [
      {
        icon: VisibilityOutlinedIcon,
        label: t('common.views'),
        data: '--' || profileViewsCount,
      },
      { icon: FavoriteBorderOutlinedIcon, label: t('common.likes'), data: likesCount ?? 0 },
      { icon: FollowersIcon, label: t('common.followers'), data: followers ?? '--' },
      { icon: FollowingIcon, label: t('common.following'), data: following ?? '--' },
    ];
  };

  return desktop ? (
    <Grid container className={classes.interactions}>
      {statsData().map((stat) => (
        <Grid item xs={PROFILE_TYPES.PARTNER_PROFILE ? 3 : 4} key={stat.label}>
          <span className={classes.interactionsTitles}>{stat.label}</span>
          <span className={classes.interactionsCount}> {stat.data}</span>
        </Grid>
      ))}
    </Grid>
  ) : (
    <Grid container className={classes.interactionsMobile}>
      {_.map(statsData(), (p, i) => (
        <Grid key={`${p.data}-${i}`} item xs={6}>
          <StatsItem icon={p.icon} data={p.data} label={p.label} />
        </Grid>
      ))}
    </Grid>
  );
}

CocoonStats.propTypes = {
  desktop: bool.isRequired,
  profileType: string.isRequired,
  // contestSponsorsCount,
  viewsCount: number,
  contestFollowersCount: number,
  contestCuratorsCount: number,
  contestProjectsCount: number,
  profileViewsCount: number,
  likesCount: number,
  followers: number,
  following: number,
};
