import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 500,
  },
  emptyProjects: {
    ...theme.typography.h4,
  },
  projectWrapper: {
    padding: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  displayAll: {
    width: '100%',
    margin: '8px 0',
    borderRadius: '6px',
    backgroundColor: 'transparent',
    border: '1px solid #575757',
    boxShadow: 'none',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  minimize: {
    width: '100%',
    margin: '0 30% 0 30%',
    borderRadius: '6px',
    backgroundColor: 'transparent',
    border: '1px solid #575757',
    boxShadow: 'none',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export default useStyles;
