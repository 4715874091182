import { Grid } from '@material-ui/core';
import { number, node } from 'prop-types';

const TabPanel = ({ tab, index, children, ...other }) => (
  <Grid
    role="tabpanel"
    hidden={tab !== index}
    id={`project-tabpanel-${index}`}
    aria-labelledby={`project-tab-${index}`}
    container
    {...other}
  >
    {tab === index && children}
  </Grid>
);

TabPanel.propTypes = {
  tab: number.isRequired,
  index: number.isRequired,
  children: node.isRequired,
};

export default TabPanel;
