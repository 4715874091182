import { Button, Box, Tooltip, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { arrayOf, object, bool, func } from 'prop-types';

import { cookieUserId } from 'helpers/auth';

import ForumTopic from '../topic';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  yellow: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
  },
  avatars: {
    float: 'left',
    '& > div': {
      float: 'left',
    },
  },
  button: {
    color: '#575757',
    width: '100%',
    borderColor: '#575757',
    borderRadius: '6px',
    borderWidth: '1px',
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(0.25, 1.5),
    textTransform: 'none',
    '&:hover': {
      background: 'transparent',
      '& *': {
        fontWeight: 'bold',
      },
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      padding: theme.spacing(0.5, 1),
    },
  },
}));

export default function TopicList({
  topics,
  btnAddTopicVisible,
  showMoreTopics,
  handleAddTopic,
  handleLoadMoreTopics,
  ...props
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      {_.isEmpty(topics) ? (
        <Box mt={2} fontWeight="fontWeightBold">
          {t('defaultInformation.noTopics')}
        </Box>
      ) : (
        _.map(topics, (topic) => <ForumTopic key={topic.id} topic={topic} {...props} />)
      )}

      {showMoreTopics && (
        <Box textAlign="center" mt={2}>
          <Button variant="outlined" className={classes.button} onClick={handleLoadMoreTopics}>
            {t('button.moreTopic')}
          </Button>
        </Box>
      )}

      {btnAddTopicVisible && (
        <Box textAlign="center" mt={2}>
          <Tooltip title={cookieUserId ? '' : t('sign.signInReminder')}>
            <Button variant="outlined" className={classes.button} onClick={handleAddTopic}>
              {t('button.addTopic')}
            </Button>
          </Tooltip>
        </Box>
      )}
    </div>
  );
}

TopicList.propTypes = {
  topics: arrayOf(object).isRequired,
  btnAddTopicVisible: bool.isRequired,
  showMoreTopics: bool.isRequired,
  handleAddTopic: func.isRequired,
  handleLoadMoreTopics: func.isRequired,
};

TopicList.defaultProps = {
  btnAddTopicVisible: false,
};
