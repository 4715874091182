import { gql } from '@apollo/client';

// done
// updated
export const ADD_TAG = gql`
  mutation createTagsForUpdate($tag: NewProjectUpdateTagsInput!) {
    createTagsForUpdate(tag: $tag) {
      id
      name
    }
  }
`;

// done
// updated
export const REMOVE_UPDATE_TAGS = gql`
  mutation removeUpdateTags($tag: DeleteUpdateTagsInput!) {
    removeUpdateTags(tag: $tag) {
      status
      description
    }
  }
`;
