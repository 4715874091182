import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: '0.5em 0 1em 0',
    backgroundColor: '#989898',
    border: 'solid 0.5px #e6e6e6',
    [theme.breakpoints.down('sm')]: {
      margin: '0.25em 0 0.5em 0',
    },
  },
  editInfoWrapper: {
    marginTop: '5%',
  },
  editInfoTitle: {
    ...theme.typography.h1,
    color: '#575757',
    fontSize: '16px',
    fontWeight: '700',
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.h6,
      fontSize: 13,
    },
  },
  editInfoTextField: {
    background: '#ffffff',
    '& .MuiOutlinedInput-root': {
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.1)',
      borderRadius: '6px',
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
    '& p': {
      marginLeft: 0,
      marginTop: 10,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      marginBottom: 12,
      '& input': {
        padding: theme.spacing(1, 1.5),
        fontSize: 12,
      },
      '& textarea': {
        padding: 0,
        fontSize: 12,
      },
      '& p': {
        margin: 0,
      },
    },
  },
  button: {
    borderColor: theme.palette.text.secondary,
    padding: theme.spacing(0.25, 1.5),
    marginLeft: theme.spacing(1.5),
    textTransform: 'none',
    borderRadius: '6px',
    borderWidth: '1px',
    [theme.breakpoints.down('sm')]: {
      padding: '7px 8px 4px',
      fontSize: 12,
    },
  },
  saveExperienceButton: {
    ...theme.typography.button,
    color: '#ffffff',
    fontSize: '13px',
    fontWeight: '700',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  saveExperience: {
    ...theme.typography.button,
    fontSize: '16px',
    fontWeight: '700',
    marginRight: '1em',
    color: '#4791ce',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      marginRight: theme.spacing(1.5),
    },
  },
  cancelExperience: {
    ...theme.typography.button,
    color: '#575757',
    fontSize: '16px',
    fontWeight: '700',
    marginRight: '1em',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      marginRight: theme.spacing(1.5),
    },
  },
  deleteExperience: {
    ...theme.typography.button,
    color: '#C53C2A',
    fontSize: '16px',
    fontWeight: '700',
    marginRight: '1em',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      marginRight: 0,
    },
  },
  experienceRowWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '68px',
    borderRadius: '6px',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.1)',
    borderColor: 'white',
    background: '#ffffff',
    marginTop: '2%',
  },
  experienceWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingLeft: '20px',
  },
  experienceRole: {
    ...theme.typography.h1,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '900',
    color: '#575757',
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.h6,
      fontSize: 13,
    },
  },
  experienceDuration: {
    ...theme.typography.h1,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#575757',
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.h6,
      fontSize: 13,
    },
  },
  experienceButton: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: '1px solid #989898',
  },
}));

export default useStyles;
