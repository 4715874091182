import { useState } from 'react';

import ProjectCard from './ProjectCard';

const ProjectContainer = ({ ...props }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const toggleTooltipVisible = () => setTooltipVisible(!tooltipVisible);

  return <ProjectCard tooltipVisible={tooltipVisible} toggleTooltipVisible={toggleTooltipVisible} {...props} />;
};

export default ProjectContainer;
