import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { LockOpen as LockOpenIcon } from '@material-ui/icons';
import { bool, object, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '678px',
    },
  },
  header: {
    color: '#414141',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  button: {
    borderColor: theme.palette.text.secondary,
    padding: theme.spacing(0.25, 1.5),
    marginLeft: theme.spacing(1.5),
    textTransform: 'none',
    borderRadius: '6px',
    borderWidth: '1px',
  },
  text: {
    color: '#575757',
  },
}));

const UserConfirmationDialog = ({ open, options, handleCloseDialog }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { saveEditLog } = options;

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    saveEditLog();
    handleCloseDialog();
  };

  return open ? (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
      className={classes.dialog}
      maxWidth="md"
    >
      <DialogTitle disableTypography id="delete-dialog-title">
        <Grid container alignItems="center" alignContent="space-between">
          <Grid item xs>
            <Typography color="textSecondary" variant="h5">
              <Box display="flex" alignItems="center" className={classes.header}>
                <LockOpenIcon color="primary" className={classes.icon} />
                {t('common.unsavedChange')}
              </Box>
            </Typography>
          </Grid>
          <Grid item xs>
            <DialogActions>
              <Button variant="outlined" className={classes.button} onClick={handleCloseDialog}>
                {t('button.cancel')}
              </Button>
              <Button
                autoFocus
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleSaveChanges}
              >
                {t('button.save')}
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Box component={Typography} my={2} variant="body1" className={classes.text}>
          {t('common.unsavedChangeReminder')}
        </Box>
      </DialogContent>
    </Dialog>
  ) : null;
};

UserConfirmationDialog.propTypes = {
  open: bool.isRequired,
  options: object.isRequired,
  handleCloseDialog: func.isRequired,
};

export default UserConfirmationDialog;
