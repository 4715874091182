import { useApolloClient } from '@apollo/client';
import { Typography, Button, Box, makeStyles } from '@material-ui/core';
import {
  BookmarkBorder as BookmarkBorderIcon,
  ExpandMore as ExpandMoreIcon,
  AccountTree as AccountTreeIcon,
  Edit as EditIcon,
} from '@material-ui/icons';
import { func, number, oneOfType, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { GET_LIST_OF_USER } from 'apollo/queries/list-query';
import { SameUserNull } from 'components/auth/UserAuthorization';
import { cookieUserId } from 'helpers/auth';
import { convertToSlug } from 'helpers/common';
import { DIALOG } from 'helpers/constants';
import { handleOpenDialogAC3 } from 'reducers/testAC3/cache';

const useStyles = makeStyles((theme) => ({
  buttonEdit: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0.25, 1.5),
    textTransform: 'none',
    borderRadius: '6px',
    borderWidth: '1px',
    '&:hover': {
      '& *': {
        fontWeight: 'bold',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& *': { fontSize: 13 },
      color: '#575757',
      borderColor: '#575757',
      padding: theme.spacing(0.5, 1),
      marginLeft: theme.spacing(0),
      minWidth: 100,
    },
  },
}));

const ButtonsOwnerView = ({ id, name, ownerId, handleChangeEditMode }) => {
  const classes = useStyles();
  const userId = +ownerId || cookieUserId;
  const client = useApolloClient();

  const { t } = useTranslation();

  const goToPanel = (e) => {
    e.preventDefault();
    const win = window.open(`/projects/${id}/${convertToSlug(name)}/panel`, '_blank');
    win.focus();
  };

  const handleAddToLists = async (e) => {
    e.preventDefault();
    const listQuery = await client.query({
      query: GET_LIST_OF_USER,
      variables: { ownerId: cookieUserId },
    });
    const {
      data: { listsByOwnerId },
    } = listQuery;
    const options = {
      list: listsByOwnerId,
      projectId: id,
      ownerId: userId,
    };
    handleOpenDialogAC3(DIALOG.LIST_PROJECT, options);
  };

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        className={classes.buttonEdit}
        onClick={handleAddToLists}
        startIcon={<BookmarkBorderIcon />}
      >
        <Typography variant="button" color="inherit">
          <Box display="flex" alignItems="center">
            {t('button.addTo')} <ExpandMoreIcon fontSize="small" />
          </Box>
        </Typography>
      </Button>
      <Button
        size="small"
        variant="outlined"
        onClick={goToPanel}
        className={classes.buttonEdit}
        startIcon={<AccountTreeIcon />}
      >
        <Typography variant="button" color="inherit">
          {t('button.projectPanel')}
        </Typography>
      </Button>
      {SameUserNull(ownerId)(
        <Button
          size="small"
          variant="outlined"
          className={classes.buttonEdit}
          onClick={handleChangeEditMode}
          startIcon={<EditIcon />}
        >
          <Typography variant="button" color="inherit">
            {t('button.edit')}
          </Typography>
        </Button>,
      )}
    </>
  );
};

ButtonsOwnerView.propTypes = {
  ownerId: number,
  name: string,
  handleChangeEditMode: func,
  id: oneOfType([number, string]),
};

ButtonsOwnerView.defaultProps = {
  ownerId: undefined,
  id: undefined,
  name: undefined,
};

export default ButtonsOwnerView;
