import { number, object, bool } from 'prop-types';
import { useState } from 'react';

import TutorialTabPanel from './TutorialTabPanel';

const TutorialTabPanelContainer = ({ tab, project, desktop }) => {
  const [stepActive, setStepActive] = useState(-1);

  const handleChangeStep = (e, newStep) => {
    if (e) {
      e.preventDefault();
    }
    setStepActive(newStep);
  };

  return (
    <TutorialTabPanel
      desktop={desktop}
      tab={tab}
      project={project}
      stepActive={stepActive}
      handleChangeStep={handleChangeStep}
    />
  );
};

TutorialTabPanelContainer.propTypes = {
  desktop: bool.isRequired,
  project: object,
  tab: number,
};

export default TutorialTabPanelContainer;
