import { useLazyQuery, useMutation } from '@apollo/client';
import { TextField, CircularProgress, Box, Chip, Button, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { arrayOf, shape, func, number } from 'prop-types';
import { useState, useEffect } from 'react';

import { SAVE_CONTEST_MEMBERS } from 'apollo/mutations/contest-mutation';
import { GET_USERS_BY_USERNAME_EMAIL } from 'apollo/queries/user-query';
import { cookieUserId } from 'helpers/auth';
import { useDebounce, useSnackbar } from 'hooks';

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '90%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    columnGap: 8,
    marginTop: 10,
  },
  chip: {
    marginTop: 8,
  },
}));

export default function SelectMembers({ members, contestId, refetch }) {
  const classes = useStyles();

  const [getUsersCb, { data: usersData, loading }] = useLazyQuery(GET_USERS_BY_USERNAME_EMAIL, {
    fetchPolicy: 'network-only',
  });
  const [searchMember, setSearchMember] = useState('');
  const debouncedSearchMember = useDebounce(searchMember, 500);
  const [memberOptions, setMemberOptions] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [saveContestMembers] = useMutation(SAVE_CONTEST_MEMBERS);

  const { openSnackbarSuccess, openSnackbarError } = useSnackbar();

  function handleDeleteTag(tag) {
    const { id } = tag;
    const copy = selectedMembers.filter((t) => t.id !== id);
    setSelectedMembers([...copy]);
  }

  async function handleSaveMember() {
    if (selectedMembers.length === 0 || !contestId) return;

    try {
      const { data } = await saveContestMembers({
        variables: {
          contest: {
            membersIds: selectedMembers,
            contestId,
          },
        },
      });
      if (data) {
        refetch();
        openSnackbarSuccess('Contest updated!');
      }
    } catch (err) {
      openSnackbarError('Something went wrong');
      throw new Error('Error save contest sponsors', err.message);
    }
  }

  useEffect(() => {
    if (debouncedSearchMember) {
      getUsersCb({
        variables: {
          searchTerm: debouncedSearchMember,
          page: 1,
          limit: 'FIVE_O',
        },
      });
    }
  }, [debouncedSearchMember]);

  useEffect(() => {
    // TODO: do this in BE
    if (usersData) {
      const userId = +cookieUserId;
      const membersIds = members.map(({ id }) => +id).concat([userId]);
      const filtered = usersData.usersByUsernameEmail.filter(({ id }) => !membersIds.includes(+id));

      setMemberOptions(filtered);
    }
  }, [usersData]);

  return (
    <Box className={classes.wrapper}>
      <Autocomplete
        multiple
        className={classes.autocomplete}
        value={selectedMembers}
        id="asynchronous-members"
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={(event, value) => setSelectedMembers([...value])}
        getOptionLabel={({ email }) => email}
        options={memberOptions || []}
        loading={loading}
        renderTags={() => undefined}
        renderInput={(params) => (
          <TextField
            {...params}
            className={classes.textField}
            placeholder="Search for a member"
            variant="outlined"
            onChange={(e) => setSearchMember(e.target.value)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      {selectedMembers.length > 0 && (
        <Box>
          <Box marginBottom="16px" className={classes.row}>
            {selectedMembers.map((tag) => (
              <Chip className={classes.chip} key={tag.id} label={tag.email} onDelete={() => handleDeleteTag(tag)} />
            ))}
          </Box>
          <Button onClick={handleSaveMember} variant="contained" color="primary">
            Save
          </Button>
        </Box>
      )}
    </Box>
  );
}

SelectMembers.propTypes = {
  members: arrayOf(shape({})).isRequired,
  contestId: number.isRequired,
  refetch: func.isRequired,
};
