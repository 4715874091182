import { Button, makeStyles } from '@material-ui/core';
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from '@material-ui/icons';
import clsx from 'clsx';
import { bool, string } from 'prop-types';

const useStylesDisplayColor = (displayColor) =>
  makeStyles((theme) => ({
    menuItem: {
      ...theme.typography.h6,
      color: theme.palette.common.white,
      margin: theme.spacing(1),
      boxShadow: 'none',
      backgroundColor: displayColor || '#989898',

      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    menuItemSelected: {
      backgroundColor: theme.palette.secondary.main,
    },
  }));

// One item component
// selected prop will be passed
const CatMenuItem = ({ text, displayColor, selected }) => {
  const classes = useStylesDisplayColor(displayColor)();
  return (
    <Button
      variant="contained"
      color="default"
      className={clsx(classes.menuItem, {
        [classes.menuItemSelected]: selected,
      })}
    >
      {text}
    </Button>
  );
};

CatMenuItem.propTypes = {
  displayColor: string,
  selected: bool,
  text: string,
};

const CatMenu = (list, selected, setSelectedCategory) =>
  list.map(({ id, name, displayColor }) => (
    <CatMenuItem
      key={id}
      text={name}
      selected={!!selected}
      displayColor={displayColor}
      setSelectedCategory={setSelectedCategory}
    />
  ));

const CatArrowLeft = (
  <div className="arrow-prev">
    <ChevronLeftIcon />
  </div>
);

const CatArrowRight = (
  <div className="arrow-prev">
    <ChevronRightIcon />
  </div>
);

export { CatMenu, CatArrowLeft, CatArrowRight };
