import { useMutation } from '@apollo/client';
import { Dialog, DialogContent, DialogContentText, DialogActions, Typography, Button } from '@material-ui/core';
import { bool, object, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { DELETE_LIST } from 'apollo/mutations/list-mutation';

const DeleteListDialog = ({
  open,
  options,
  openSnackbarSuccess,
  openSnackbarError,
  handleCloseDialog,
  handleCloseBackdrop,
  handleOpenBackdrop,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { list } = options;

  const [deleteListMutation] = useMutation(DELETE_LIST);

  const { t } = useTranslation(['common', 'dialog']);

  const handleDeactivateProject = async (e) => {
    e.preventDefault();

    try {
      handleOpenBackdrop();
      const { data } = await deleteListMutation({
        variables: { list: { id: list.id } },
      });
      if (data) {
        openSnackbarSuccess(`${list.name} ${t('dialog:deleteListDialog.delete')}`);
        handleCloseDialog();
        handleCloseBackdrop();
        history.push({
          pathname: location.pathname,
          state: { isRefetch: true },
        });
      }
    } catch (errorDeleteList) {
      openSnackbarError(errorDeleteList.message);
    }
  };

  return open ? (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="deactive-dialog-title"
      aria-describedby="deactive-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="deactive-dialog-description">
          {t('dialog:deleteListDialog.reminder')} <b>{list.name}</b>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Typography color="error" variant="h6">
          <Button onClick={handleCloseDialog}>{t('common:button.cancel')}</Button>
          <Button onClick={handleDeactivateProject} color="inherit" autoFocus>
            {t('common:button.delete')}
          </Button>
        </Typography>
      </DialogActions>
    </Dialog>
  ) : null;
};

DeleteListDialog.propTypes = {
  open: bool.isRequired,
  options: object.isRequired,
  openSnackbarError: func.isRequired,
  openSnackbarSuccess: func.isRequired,
  handleCloseDialog: func.isRequired,
  handleCloseBackdrop: func.isRequired,
  handleOpenBackdrop: func.isRequired,
};

export default DeleteListDialog;
