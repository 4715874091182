import { Typography, makeStyles } from '@material-ui/core';
import { Description as DescriptionIcon } from '@material-ui/icons';
import { arrayOf, object } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { DETAILS_TYPE, DIALOG } from 'helpers/constants';
import { handleOpenDialogAC3 } from 'reducers/testAC3/cache';

import EmptyField from '../empty-field/EmptyField';

const useStyles = makeStyles((theme) => ({
  experiencesWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  experienceText: {
    ...theme.typography.h1,
    color: '#575757',
    fontWeight: '100',
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
  },
  experienceRole: {
    ...theme.typography.h1,
    fontWeight: '700',
    color: '#000000',
    lineHeight: '24px',
    fontSize: '16px',
    letterSpacing: '0.5px',
    marginBottom: '3px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
  detailsWrapper: {
    display: 'flex',
    borderRadius: 0,
    right: '5px',
    alignItems: 'center',
    cursor: 'pointer',
  },
  details: {
    ...theme.typography.h4,
    color: '#4791CE',
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
  },
}));

const Experiences = ({ experiences }) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const openExperiencesDialog = () => {
    const options = {
      experiences,
      type: DETAILS_TYPE.EXPERIENCES,
    };
    handleOpenDialogAC3(DIALOG.VIEW_EXPERIENCE_CONTEST_DETAILS, options);
  };
  return (
    <>
      {!experiences || experiences.length === 0 ? (
        <EmptyField field="experiences" />
      ) : (
        <>
          {experiences.map((exp, index) => (
            <div key={index}>
              <div className={classes.experiencesWrapper}>
                <Typography className={classes.experienceRole}>{exp.role}</Typography>
              </div>
              <Typography className={classes.experienceText} variant="body1" paragraph>
                {exp.company} • {exp.duration}
              </Typography>
            </div>
          ))}
          <div role="button" onClick={() => openExperiencesDialog()} className={classes.detailsWrapper}>
            <DescriptionIcon style={{ fill: '#4791CE', marginRight: 5, fontSize: 15 }} />
            <Typography color="primary" className={classes.details}>
              {t('button.viewDetail')}
            </Typography>
          </div>
        </>
      )}
    </>
  );
};

Experiences.defaultProps = {
  experiences: [],
};

Experiences.propTypes = {
  experiences: arrayOf(object),
};

export default Experiences;
