import baseApi from '../baseApi';

export default {
  upload: (url, file, contentType) =>
    baseApi.put(url, file, {
      headers: {
        'Content-Type': contentType
      },
    }),
};
