import { Typography, Paper, TextField, makeStyles } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import { Search as SearchIcon } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import _ from 'lodash';
import { string, func, arrayOf, object } from 'prop-types';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    width: '100%',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  searchIcon: {
    width: theme.spacing(6),
    color: '#989898',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(4.5),
    },
  },
  input: {
    padding: theme.spacing(1, 1, 1, 6),
    transition: theme.transitions.create('width'),
    width: '100%',
    '& > div::before': {
      borderBottom: 'none',
      transition: 'none',
    },
    '& div:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.25, 0.75, 0.25, 4.5),
      '& div': {
        fontSize: 14,
      },
    },
  },
  option: {
    color: theme.palette.text.secondary,
  },
}));

export default function SearchByInput({ name, searchValue, handleChangeSearchValue, options }) {
  const classes = useStyles();
  const [value, setValue] = useState(searchValue);

  const convertString = (valueToConvert) => {
    const searchObj = valueToConvert === null ? '' : valueToConvert.name;
    return _.isString(valueToConvert) ? valueToConvert : searchObj;
  };

  const handleChange = (event, newValue, reason) => {
    if (reason === 'clear') {
      handleChangeSearchValue('');
    } else if (reason === 'select-option') {
      handleChangeSearchValue(convertString(newValue));
    }
    setValue(newValue);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleChangeSearchValue) {
      handleChangeSearchValue(convertString(value));
    }
  };

  return (
    <Paper component="form" onSubmit={handleSubmit} className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <Typography variant="h6">
        <Autocomplete
          id={`search-${name}s`}
          classes={{
            option: classes.option,
          }}
          options={options || []}
          getOptionLabel={(option) => option.name}
          // filterOptions={filterOptions}
          noOptionsText={`No ${name}s found`}
          onChange={handleChange}
          value={value}
          autoFocus
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={`search by ${name}`}
              className={classes.input}
              InputProps={{
                ...params.InputProps,
                autoComplete: 'no',
                'aria-label': `search ${name}`,
              }}
              autoComplete="no"
            />
          )}
        />
      </Typography>
    </Paper>
  );
}

SearchByInput.propTypes = {
  name: string.isRequired,
  searchValue: string,
  handleChangeSearchValue: func,
  options: arrayOf(object),
};

SearchByInput.defaultProps = {
  searchValue: null,
  handleChangeSearchValue: undefined,
  options: undefined,
};
