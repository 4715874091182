import { FormControl, MenuItem as MatMenuItem, Select, makeStyles } from '@material-ui/core';
import { bool, func, number, arrayOf, object } from 'prop-types';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { useTranslation } from 'react-i18next';
import { CatArrowLeft, CatArrowRight, CatMenu } from './components/CategoriesMenu';

const useStyles = makeStyles((theme) => ({
  menu: {
    margin: theme.spacing(2, 0),
    '& .menu-item-wrapper.active': {
      border: 'none',
    },
    '& .scroll-menu-arrow': {
      padding: theme.spacing(3),
      cursor: 'pointer',
    },
  },
  formControl: {
    minWidth: 120,
    display: 'flex',
    marginTop: theme.spacing(1.5),
    '& .MuiSelect-select': {
      padding: theme.spacing(1),
    },
  },
}));

const Categories = ({ categories, selectedCategory, handleSelectCategory, desktop }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const categoriesWithDefault = [
    { id: t('home.categoriesWithDefaultId'), name: t('home.categoriesWithDefaultName') },
    ...categories,
  ];

  const menu = CatMenu(categoriesWithDefault, selectedCategory);

  return desktop ? (
    <ScrollMenu
      alignCenter={false}
      menuClass={classes.menu}
      data={menu}
      arrowLeft={CatArrowLeft}
      arrowRight={CatArrowRight}
      selected={selectedCategory}
      onSelect={handleSelectCategory}
    />
  ) : (
    <FormControl className={classes.formControl}>
      <Select
        value={selectedCategory}
        onChange={handleSelectCategory}
        autoWidth
        variant="outlined"
        inputProps={{
          name: 'category',
          id: 'category-select',
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {categoriesWithDefault.map(({ id, name }) => (
          <MatMenuItem style={{ color: '#000' }} key={id} value={+id}>
            {name}
          </MatMenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

Categories.propTypes = {
  desktop: bool.isRequired,
  handleSelectCategory: func.isRequired,
  selectedCategory: number.isRequired,
  categories: arrayOf(object).isRequired,
};

export default Categories;
