import { Avatar, Badge, IconButton, makeStyles } from '@material-ui/core';
import { PhotoCamera as PhotoCameraIcon } from '@material-ui/icons';
import { bool, func, number, string } from 'prop-types';

import { SameUserAlt } from 'components/auth/UserAuthorization';
import { acceptedImageFiles } from 'helpers/constants';

const styles = makeStyles((theme) => ({
  profileImageWrapper: {
    position: 'absolute',
    bottom: '-19%',
    left: '7%',
    zIndex: 1,
  },
  profileAvatar: {
    width: '200px',
    height: '200px',
    borderRadius: '50%',
  },
  fileInput: {
    display: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  iconButton: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.85)',
    },
  },
}));

const mobileStyles = makeStyles((theme) => ({
  profileImageWrapper: {
    zIndex: 1,
    // paddingLeft: theme.spacing(2),
    // position: 'absolute',
    // top: -68,
    height: 50,
    textAlign: 'center',
    '& > span, > div': {
      position: 'absolute',
      top: -100,
      left: 'calc(50% - 75px)',
    },
  },
  profileAvatar: {
    width: '150px',
    height: '150px',
    borderRadius: '50%',
  },
  fileInput: {
    display: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  iconButton: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.85)',
    },
    '& svg': {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
    },
  },
}));

const ProfileHeaderAvatar = ({ desktop, avatar, onProfilePictureChange, userId }) => {
  const classes = desktop ? styles() : mobileStyles();

  return (
    <div className={classes.profileImageWrapper}>
      {SameUserAlt(userId)(
        <Badge
          overlap="circle"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={
            <label htmlFor="button-avatar" style={{ cursor: 'pointer' }}>
              <input
                accept={acceptedImageFiles}
                className={classes.fileInput}
                id="button-avatar"
                type="file"
                onChange={onProfilePictureChange}
                onClick={(event) => {
                  event.target.value = null;
                }}
              />
              <IconButton aria-label="upload picture" component="span" className={classes.iconButton}>
                <PhotoCameraIcon size={desktop ? 'large' : 'small'} />
              </IconButton>
            </label>
          }
        >
          <Avatar className={classes.profileAvatar} src={avatar} alt="avatar" />
        </Badge>,
        <Avatar className={classes.profileAvatar} src={avatar} alt="avatar" />,
      )}
    </div>
  );
};

ProfileHeaderAvatar.propTypes = {
  avatar: string.isRequired,
  onProfilePictureChange: func.isRequired,
  userId: number.isRequired,
  desktop: bool.isRequired,
};

export default ProfileHeaderAvatar;
