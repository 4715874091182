import {
  Button,
  ClickAwayListener,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { func, number } from 'prop-types';
import { useEffect, useRef, useState } from 'react';
// import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TaskSvgActive from '../../../../../helpers/icons/TaskSvgActive.js';
import TaskSvgDeactive from '../../../../../helpers/icons/TaskSvgDeactive.js';
import TimelineSvgActive from '../../../../../helpers/icons/TimelineSvgActive.js';
import TimelineSvgDeactive from '../../../../../helpers/icons/TimelineSvgDeactive';
import TreeSvgActive from '../../../../../helpers/icons/TreeSvgActive.js';
import TreeSvgDeactive from '../../../../../helpers/icons/TreeSvgDeactive.js';

const useStyles = makeStyles((theme) => ({
  list: {
    '& a': {
      display: 'flex',
      alignItems: 'center',
      color: '#333',
      '& > div': {
        minWidth: '32px',
      },
      '&:focus, &:hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        fontWeight: theme.typography.fontWeightBold,
        textDecoration: 'none',
        '& > div': {
          color: theme.palette.common.white,
        },
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 13,
        minHeight: theme.spacing(3),
      },
    },
  },
  button: {
    textTransform: 'none',
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(0.25, 1.5),
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      '& h6': {
        fontSize: 14,
      },
    },
  },
}));

const DashboardButton = ({
  // setOpenMenuBar,
  dashboardTab,
  handleChangeDashboardTab,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  // const history = useHistory();

  const { t } = useTranslation('panel');
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  // const onClickNewProject = (e) => {
  //   e.preventDefault();
  //   history.push('/projects/new');
  //   handleClose(e);
  //   setOpenMenuBar(false);
  // };

  // TODO: uncomment later
  // const onClickNewGroup = e => {
  //   e.preventDefault();
  //   handleClose(e);
  // };

  return (
    <>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'new-list-grow' : undefined}
        aria-haspopup="true"
        color="primary"
        size="small"
        variant="outlined"
        onClick={handleToggle}
        className={classes.button}
      >
        <Typography component="h6" variant="button" color="inherit">
          {t('dashboard')}
        </Typography>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
        style={{ zIndex: 3 }}
      >
        {() => (
          <Paper elevation={3} square>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="new-list-grow" onKeyDown={handleListKeyDown} className={classes.list}>
                <MenuItem
                  style={{ backgroundColor: dashboardTab === 0 && '#F5F5F5' }}
                  onClick={(e) => handleChangeDashboardTab(e, 0)}
                >
                  <ListItemIcon>
                    {/* <PostAddIcon fontSize="small" /> */}
                    {dashboardTab === 0 ? (
                      <TreeSvgActive style={{ marginBottom: 0 }} />
                    ) : (
                      <TreeSvgDeactive style={{ marginBottom: 0 }} />
                    )}
                  </ListItemIcon>
                  {t('treePreview')}
                </MenuItem>
                <MenuItem
                  style={{ backgroundColor: dashboardTab === 1 && '#F5F5F5' }}
                  onClick={(e) => handleChangeDashboardTab(e, 1)}
                >
                  <ListItemIcon>
                    {/* <PostAddIcon fontSize="small" /> */}
                    {dashboardTab === 1 ? (
                      <TaskSvgActive style={{ marginBottom: 0 }} />
                    ) : (
                      <TaskSvgDeactive style={{ marginBottom: 0 }} />
                    )}
                  </ListItemIcon>
                  {t('taskManager')}
                </MenuItem>
                <MenuItem
                  style={{ backgroundColor: dashboardTab === 2 && '#F5F5F5' }}
                  onClick={(e) => handleChangeDashboardTab(e, 2)}
                >
                  <ListItemIcon>
                    {/* <PostAddIcon fontSize="small" /> */}
                    {/* <TimelineSvg style={{ marginBottom: 0 }} /> */}
                    {dashboardTab === 2 ? (
                      <TimelineSvgActive style={{ marginBottom: 0 }} />
                    ) : (
                      <TimelineSvgDeactive style={{ marginBottom: 0 }} />
                    )}
                  </ListItemIcon>
                  {t('timelineManager')}
                </MenuItem>
                {/* TO DO: uncomment later */}
                {/* <MenuItem component={Link} onClick={onClickNewGroup}>
                  <ListItemIcon>
                    <GroupAddIcon fontSize="small" />
                  </ListItemIcon>
                  New group
                </MenuItem> */}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        )}
      </Popper>
    </>
  );
};

DashboardButton.propTypes = {
  dashboardTab: number,
  handleChangeDashboardTab: func,
  // setOpenMenuBar: func,
};

export default DashboardButton;
