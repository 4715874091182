import { useMediaQuery, Container, Box, Typography, makeStyles } from '@material-ui/core';
import { useEffect } from 'react';

import logoH from '@images/logo/logo-h.svg';
import { backToAnchor, CocoonHelmet } from 'components';

import AboutTitle from '../about/components/AboutTitle';

import { META_DATA } from 'helpers/metaData';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(4),
  },
}));

const CookiePractices = () => {
  const classes = useStyles();
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const { title, description, image, url } = META_DATA.COOKIE_PRACTIVES;

  useEffect(() => {
    backToAnchor(document);
  }, []);

  return (
    <Container fixed className={classes.container}>
      <CocoonHelmet title={title} description={description} image={image} url={url} />
      <Box width={300} mt={6} mb={3} mx="auto">
        <img src={logoH} alt="logo" />
      </Box>
      <Box mb={3}>
        <AboutTitle title="How we use cookies" desktop={desktop} />
        <Typography variant={desktop ? 'body1' : 'body2'} gutterBottom component="p" color="textPrimary">
          Project Cocoon would like to use cookies for the following purposes:
        </Typography>
        <Typography variant={desktop ? 'body1' : 'body2'} gutterBottom component="ul" color="textPrimary">
          <li>Keep track of who has logged in to the website.</li>
          <li>Ensure you are still logged in even after closing tab or browser.</li>
          <li>Give you access to your authorized pages and features such as your user profile and project panel</li>
        </Typography>
        <Typography variant={desktop ? 'body1' : 'body2'} gutterBottom component="p" color="textPrimary">
          Why do we need you to allow <b>3rd party cookies</b>?
        </Typography>
        <Typography variant={desktop ? 'body1' : 'body2'} gutterBottom component="ul" color="textPrimary">
          <li>
            We use <a href="https://auth0.com/">Auth0</a> as our reliable and secure authentication service. Auth0 needs
            3rd party cookies persmission from your browser for a successful authentication process
          </li>
        </Typography>
        <Typography variant={desktop ? 'body1' : 'body2'} gutterBottom component="p" color="textPrimary">
          Important notes on our practices and policies:
        </Typography>
        <Typography variant={desktop ? 'body1' : 'body2'} gutterBottom component="ul" color="textPrimary">
          <li>Cookies are securely transferred between your browser and our server using HTTPs network protocol</li>
          <li>
            Cookies will be expired after 6 months since the successful login time. After cookies are expired, you will
            need to login again to continue accessing authorized pages and features
          </li>
          <li>
            Cookies contains some of your personal data that you shared with us during sign up/sign in process to help
            with authorization purposes. Your personal data is securely stored in our internal database.
          </li>
        </Typography>
      </Box>
    </Container>
  );
};

export default CookiePractices;
