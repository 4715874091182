import { useMutation } from '@apollo/client';
import { arrayOf, object, number, bool } from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { CREATE_USER_CONTEST, DELETE_USER_CONTEST, UPDATE_USER_CONTEST } from 'apollo/mutations/user-contest-mutation';
import { useSnackbar } from 'hooks';

import ContestForm from './contest-form/ContestForm';
import Contests from './display-contests/Contests';

const today = new Date();

const ContestsWrapper = ({ id, contests, desktop }) => {
  const [createContest] = useMutation(CREATE_USER_CONTEST);
  const [deleteContest] = useMutation(DELETE_USER_CONTEST);
  const [updateContest] = useMutation(UPDATE_USER_CONTEST);
  const history = useHistory();
  const location = useLocation();

  const { openSnackbarSuccess, openSnackbarError } = useSnackbar();

  const { t } = useTranslation(['common', 'dialog', 'validation']);

  const [actionMode, setActionMode] = useState({
    editing: false,
    adding: false,
  });
  const [errors, setErrors] = useState({});
  const defaultContest = {
    name: '',
    year: today,
    description: '',
    reward: '',
  };
  const [contest, setContest] = useState(defaultContest);

  const handleTextFieldChange = (e, field) => {
    if (field === 'year') {
      setContest((prevState) => ({
        ...prevState,
        year: e,
      }));
    } else {
      const { name, value } = e.target;
      setContest((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const openForm = (mode, editObject) => {
    if (mode === 'add') {
      setActionMode({
        adding: true,
        editing: false,
      });
      setContest(defaultContest);
    } else {
      setActionMode({
        editing: true,
        adding: false,
      });
      setContest((prevState) => {
        return { ...prevState, ...editObject };
      });
    }
  };

  const closeForm = () => {
    setActionMode({ editing: false, adding: false, contest: defaultContest });
    setErrors({});
  };

  const validateForm = (data) => {
    const formErrors = {};
    if (!data.name || data.name.trim() === '') {
      formErrors.name = t('validatioin:dialog.contestName');
    }
    if (Object.keys(formErrors).length !== 0) {
      setErrors(formErrors);
      return false;
    }
    return true;
  };

  const handleAddEditContest = async () => {
    if (validateForm(contest)) {
      if (actionMode.adding) {
        try {
          const { data } = await createContest({
            variables: { contest },
          });
          if (data) {
            openSnackbarSuccess(t('dialog:openSnackbarSuccess.contestAdded'));
            setContest(defaultContest);
            closeForm();
            history.push({
              pathname: location.pathname,
              state: { isRefetchProfile: true },
            });
          }
        } catch (errorAddContest) {
          openSnackbarError(`${t('dialog:openSnackbarError.addExperience')} ${errorAddContest.message}`);
        }
      } else {
        try {
          delete contest.__typename;
          const { data } = await updateContest({
            variables: { contest },
          });
          if (data) {
            openSnackbarSuccess(t('dialog:openSnackbarSuccess.experienceUpdated'));
            setContest(defaultContest);
            closeForm();
            history.push({
              pathname: location.pathname,
              state: { isRefetchProfile: true },
            });
          }
        } catch (errorUpdateContest) {
          openSnackbarError(`${t('dialog:openSnackbarError.updateExperience')} ${errorUpdateContest.message}`);
        }
      }
    }
  };

  const handleDeleteContest = async (experienceId) => {
    try {
      const { data } = await deleteContest({
        variables: { contest: { id: experienceId } },
      });
      if (data) {
        openSnackbarSuccess(t('dialog:openSnackbarSuccess.contestDeleted'));
        closeForm();
        history.push({
          pathname: location.pathname,
          state: { isRefetchProfile: true },
        });
      }
    } catch (errorDeleteContest) {
      openSnackbarError(`${t('dialog:openSnackbarError.contestDeleted')} ${errorDeleteContest.message}`);
    }
  };
  return (
    <>
      {contests && contests.length !== 0 && <Contests desktop={desktop} contests={contests} openForm={openForm} />}
      <ContestForm
        desktop={desktop}
        contest={contest}
        editing={actionMode.editing}
        adding={actionMode.adding}
        errors={errors}
        openForm={openForm}
        closeForm={closeForm}
        handleAddEditContest={handleAddEditContest}
        handleDeleteContest={handleDeleteContest}
        handleTextFieldChange={handleTextFieldChange}
      />
    </>
  );
};

ContestsWrapper.propTypes = {
  id: number.isRequired,
  contests: arrayOf(object).isRequired,
  desktop: bool.isRequired,
};

export default ContestsWrapper;
