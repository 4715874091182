export default {
  common: {
    or: 'Or',
    and: 'And',
    yes: 'Yes',
    no: 'No',
    untitled: 'Untitled',
    featured: 'Featured',
    views: 'views',
    likes: 'likes',
    followers: 'followers',
    following: 'following',
    saved: 'saved',
    comments: 'comments',
    commentTitle: 'Comments:',
    updated: 'updated',
    brief: 'Brief',
    logsUpper: 'Logs',
    logsLower: 'logs',
    team: 'Team',
    tutorial: 'Tutorial',
    forum: 'Forum',
    like: 'Like',
    attachments: 'Attachments',
    showAll: 'Show all',
    collapse: 'Collapse',
    files: 'files',
    gallery: 'Gallery',
    members: 'members',
    owner: 'Owner',
    phases: 'Phases',
    allTasks: 'All tasks',
    waitList: 'Waitlist',
    pending: 'Pending',
    closed: 'Closed',
    overdue: 'Overdue',
    completion: 'Completion',
    projectOverview: 'Project Overview',
    deletedComment: 'This comment has been deleted',
    wrote: 'wrote',
    viewPreviousComment: 'View previous comments',
    reply: 'Reply',
    replies: 'Replies',
    leaveComment: 'Leave a new comment...',
    enterReply: 'Enter a reply...',
    projects: 'projects',
    projectsUpper: 'Projects',
    followingUpper: 'Following',
    available: ' available',
    description: 'Description',
    bioUpper: 'Bio',
    bioLower: 'bio',
    fieldsOfWork: 'Fields Of Work',
    emptyField: 'fields of work',
    experience: 'Experience',
    experiences: 'Experiences',
    tags: 'Tags',
    contests: 'Contests',
    toShow: 'to show',
    deleteList: 'Delete list',
    info: 'Info',
    project: 'Project',
    lists: 'Lists',
    archived: 'Archived',
    addToLists: 'Add to lists',
    addToList: 'Add to list',
    reactivate: 'Reactivate',
    deactivate: 'Deactivate',
    aboutUs: 'About us',
    contactUs: 'Contact us',
    published: 'Published',
    newProject: 'New Project',
    overview: 'Overview',
    firstName: 'First Name',
    lastName: 'Last Name',
    gender: 'Gender',
    dob: 'Date of Birth',
    currentlyAt: 'Currently At',
    male: 'Male',
    female: 'Female',
    other: 'Other',
    country: 'Country',
    stateCityProvince: 'State/City/Province',
    titleJobPosition: 'Title/Job/Position',
    personalStatement: 'Personal Statement',
    socialMedia: 'Social Medias',
    linkedIn: 'Add LinkedIn',
    gitHub: 'Add Github',
    facebook: 'Add Facebook',
    twitter: 'Add Twitter',
    onlyMe: 'Only me',
    public: 'Public',
    unsavedChangeReminder: 'You have unsaved changes. Are you sure you want to leave this page without saving?',
    unsavedChange: 'Unsaved Changes',
  },
  contactFormInformation: {
    fullName: 'Fullname',
    email: 'Email',
    textField: 'Text',
    title: 'Send a Message!',
    share: `Share your thoughts, your feelings or what you want for our future activities.`,
    contact: `If you would like to contact about work, cooperation or similar matters, please contact Cocoon via email:`,
    contactEmail: 'project.cocoon.org@gmail.com',
  },
  contactYup: {
    fullNameLabel: 'Fullname',
    emailLabel: 'Email',
    emailError: 'Invalid Email',
    labelText: 'Text',
    textMin: 'Please write text between 10 and 1000 characters',
    textMax: 'Please write text between 10 and 1000 characters',
  },
  addTopicYup: {
    topicName: 'Topic name',
    topicError: 'Topic name should not empty',
  },
  experienceYup: {
    role: 'Role',
    company: 'Company',
    roleRequire: 'Please enter your role',
    companyRequire: 'Please enter a company',
  },
  home: {
    allAboutProject: 'All About Projects',
    projectFeatured: "What's good?",
    projectDiscover: 'Discover more great ideas',
    latestProjectUpdate: 'Weekly breakthroughs',
    latestCommentForum: 'Forum',
    newLogs: 'New logs',
    commentedOn: 'commented on',
    repliedIn: 'replied in',
    categoriesWithDefaultId: -1,
    categoriesWithDefaultName: "What's good",
    heroBoxDescription: 'Show, manage, and document your work.',
    heroSpanDescription: 'Meanwhile, socialize and connect with other creators',
  },
  project: {
    tutorial: `*From here, you can continue to edit in **Project panel** once the project
    has been created.* *Click **Create** to kick off you project!*`,
    tutorialMobile: `From here, you can continue to edit in <b>Project panel</b> once the project
    has been created. Click <b>Create</b> to kick off you project!`,
    markDown: 'Markdown',
    isSupported: 'is supported',
    sponsoredBy: 'Sponsored by',
    fullDescription: 'full description',
    addTitle: 'Add title',
    createdBy: 'Created by',
    completionLabel: 'Completion',
    currentPhaseLabel: 'Current phase',
    progressStats: 'progress & stats',
    filesComponents: 'Files & Components',
    contentDefault: 'Summarize your goals, process, challenges, and outcome of this project.',
    layoutDefault: {
      no: 'No ',
    },
  },
  notification: {
    desktopNewUpdate: 'New updates for Project Cocoon. Check it out now!',
    desktopNewFeature: 'New features from September, 2020 has been released.',
    desktopContent: 'View it now. Content goes here by the content team!',
    mobileNewUpdate: 'New updates for Project Cocoon!',
    mobileNewFeature: 'New features from September, 2020 has been released.',
  },
  profile: {
    minimize: 'Minimize',
    viewAllProjects: 'View All Projects',
  },
  invitation: {
    invitations: 'Invitations',
    hasInvited: 'has invited you to',
    checkItOut: 'Check it out now!',
  },
  gig: {
    babySitting: 'Babysitting',
    tagsAddedByUser: 'Tags added by User',
    publishedDate: 'Published date:',
    assignedMembers: 'Assigned members',
    taskDetails: 'Tasks Details',
    gigOverview: 'Gig Overview',
    requirements: 'Requirements',
    tasksAvailable: 'Tasks available:',
    tasks: 'tasks',
    endDate: 'End Date:',
    gigDetails: 'Gig Details',
    helpText: 'We need your help with these tasks',
  },
  log: {
    removeLogs: 'Remove log(s) selected',
    loadMore: 'Load more logs',
    copyTag: 'Copy tag link',
    copiedTag: 'Link copied',
    publishedDate: 'Published date:',
    completedTasks: 'Completed tasks:',
    overallContribution: 'Overall contributions:',
    updateDetails: 'Update Details',
    done: 'Done!',
    doneContent: 'The task has been fully completed.',
    overdued: 'Overdued!',
    overduedWarning: 'The task has passed its due date. Late submission is acceptable within',
    overduedDay: '5 days',
    longOverdued: 'Long overdued!',
    longOverduedWarning: 'The task has passed its late submission due date.',
    contribution: 'Contribution',
    assignedMembers: 'Assigned Members',
    status: 'Status',
  },
  team: {
    othersTitle: 'Others',
    members: 'Members',
    people: 'people',
    person: 'person',
    nameTeamDefault: 'All',
  },
  projects: {
    myProjects: 'My Projects',
    allProjects: 'All Projects',
  },
  defaultInformation: {
    noComments: 'No comments available',
    noLogs: 'No logs available',
    noProjects: 'No projects available',
    noProjectShow: 'No project to show',
    noFiles: 'No files available',
    noImages: 'No images available',
    noTeams: 'No teams available',
    noPhase: 'No phases available',
    noOverview: 'No overview available',
    noTopics: 'No topics available',
    noTasks: 'No tasks available',
    noGigs: 'No gigs available',
    noSteps: 'No steps available',
    noTutorials: 'No tutorials available',
    noJoin: 'No join requests available',
    noList: 'No list to show',
    noLocation: 'No location to show',
    noPrimaryJob: 'No primary job to show',
    noProfileDescription: 'No profile description to show',
    noAttachment: 'No attachments added',
    noInvitation: 'You do not have any invitations yet',
  },
  button: {
    register: 'Register',
    save: 'Save',
    create: 'Create',
    cancel: 'Cancel',
    send: 'Send',
    like: 'Like',
    changeCover: 'Change cover',
    addCover: 'Add cover',
    addTo: 'Add to',
    projectPanel: 'Project Panel',
    edit: 'Edit',
    forum: 'Go to Forum',
    addList: ' Add to Lists',
    download: 'Download',
    attachment: ' Attachments',
    post: 'Post',
    remove: 'Remove',
    delete: 'Delete',
    addSection: 'Add section',
    moreSteps: 'More steps',
    showMore: 'Show more',
    addLog: 'Add log',
    saveEdit: 'Save Edit',
    seeMore: 'See more',
    viewDetail: 'View details',
    editProfile: 'Edit Profile',
    seeAllMember: 'See all members',
    hide: 'Hide',
    show: 'Show',
    followed: 'Followed',
    follow: 'Follow',
    showHide: 'Show/Hide',
    viewGallery: 'View gallery',
    addComment: 'Add comment',
    moreTopic: 'More topic',
    addTopic: 'Add topic',
    addStep: 'Add step',
    accept: 'Accept',
    decline: 'Decline',
    updateStep: 'Update step',
    addNewStep: 'Add new step',
    back: 'Back',
    addImage: 'Add Image',
    confirmAndDelete: 'Confirm & Delete',
    confirmAndPublish: 'Confirm & Publish',
    invite: 'Invite',
    cancelAndCollapse: 'Cancel & Collapse',
  },
  error: {
    404: 'Not Found',
    unspecified: 'Unspecified',
    badRequestResponse: '400 - Bad Request',
    notFound: 'Not Found',
    notFoundResponse: '404 - Resource Not Found',
    serverErrorResponse: '500 - Server Error',
    unauthorizedResponse: '401 - Unauthorized',
  },
  sign: {
    forgot: 'Forgot password?',
    signInReminder: 'Please log in to use this feature',
    up: {
      title: 'Sign Up',
      quest: "Don't have an account? Sign up",
    },
    in: {
      title: 'Sign In',
      quest: 'Already have an account? Sign in',
    },
    google: 'Sign in with Google',
    password_reset: {
      title: "Enter your account's email",
      action: 'Send email to reset password',
    },
    cookieReminder: `Please remember to allow <b> all cookies (including 3rd party cookies)</b> in your
    browser's settings before proceeding if you haven't done so. For more information on how
    we use cookies, refer <a href = "/how-we-use-cookies" target = "_blank">here</a>.`,
  },
  authCallBack: {
    duringLogin: 'You will be redirected to homepage soon.',
    afterLogin: 'You are being signed in to Cocoon and will be redirected to homepage soon.',
    pleaseWait: 'Please wait!',
  },
  signField: {
    username: 'Username',
    password: 'Password',
    tooShort: 'Too Short!',
    tooLong: 'Too Long!',
    shortMess: 'Seems a bit short...',
    longMess: 'The password is too long!',
    usernameLabel: 'Username/Email',
    passwordLabel: 'Password',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    invalidEmail: 'Invalid email address',
    minimunWord: 'Minimum length is 5!',
    passwordConfirmation: 'Password Confirmation',
    passwordNoti: 'Passwords must match',
  },
};
