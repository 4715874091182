import { Typography, Grid, Link, makeStyles } from '@material-ui/core';
import { bool, func } from 'prop-types';

import { useTranslation } from 'react-i18next';
const useStyles = makeStyles((theme) => ({
  h6Custom: {
    ...theme.typography.h6,
    letterSpacing: '0.1px',
  },
  publishButton: {
    paddingLeft: 5,
    paddingRight: 5,
    textDecoration: 'underline',
    fontWeight: theme.typography.fontWeightBold,
    color: '#989898',
  },
  fullSize: {
    width: '100%',
    height: '100%',
    paddingTop: theme.spacing(1),
  },
  publishedTitle: {
    ...theme.typography.h6,
    color: '#59A76A',
    paddingRight: '5px',
  },
  unPublishedTitle: {
    ...theme.typography.h6,
    color: '#CC5F51',
    paddingRight: '5px',
  },
}));

const SubTitleWithAction = ({ isPublishMilestone, publishMilestone }) => {
  const preventDefault = (event) => {
    event.preventDefault();
    publishMilestone();
  };
  const classes = useStyles();
  const { t } = useTranslation('panel');
  return (
    <div className={classes.fullSize}>
      <Grid item xs={12} container direction="row">
        {isPublishMilestone ? (
          <Typography className={classes.publishedTitle}>{t('published')}</Typography>
        ) : (
          <div>
            <Typography className={classes.unPublishedTitle}>{t('unpublished')}</Typography>
            <Typography className={classes.h6Custom}>
              {t('click')}
              <Link className={classes.publishButton} href="#" onClick={preventDefault}>
                {t('here')}
              </Link>
              {t('publishMilestone')}
            </Typography>
          </div>
        )}
      </Grid>
    </div>
  );
};

SubTitleWithAction.propTypes = {
  isPublishMilestone: bool,
  publishMilestone: func.isRequired,
};

SubTitleWithAction.defaultProps = {
  isPublishMilestone: undefined,
  log: undefined,
};

export default SubTitleWithAction;
