import { Grid, useMediaQuery } from '@material-ui/core';
import { object } from 'prop-types';
import { useState } from 'react';

import { CONTEST_TABS } from 'helpers/constants';

import ContestHeader from './components/header/ContestHeader';
import ContestInfoBar from './components/info/ContestInfoBar';
import ContestProjects from './components/projects/ContestProjects';

const { APPLICANTS_SPONSORS, PROJECTS } = CONTEST_TABS;

const TABS = [
  {
    id: 0,
    key: PROJECTS,
  },
  {
    id: 1,
    key: APPLICANTS_SPONSORS,
  },
];

const PartnerContest = ({ contest }) => {
  const [currentTab, setCurrentTab] = useState(PROJECTS);
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  return (
    <Grid container>
      <Grid item xs={12}>
        <ContestHeader desktop={desktop} contest={contest} />
      </Grid>
      <Grid item xs={12}>
        <ContestInfoBar
          tabs={TABS}
          currentTab={currentTab}
          handleSwitchTab={setCurrentTab}
          desktop={desktop}
          contest={contest}
        />
      </Grid>
      <Grid item xs={12}>
        <ContestProjects currentTab={currentTab} desktop={desktop} projects={contest.projects} />
      </Grid>
    </Grid>
  );
};

PartnerContest.propTypes = {
  contest: object.isRequired,
};

export default PartnerContest;
