import { Typography, makeStyles } from '@material-ui/core';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles((theme) => ({
  emptyInfo: {
    ...theme.typography.body1,
    color: '#000000',
  },
}));

const EmptyField = ({ field }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return <Typography className={classes.emptyInfo}>{`${t('common.no')} ${field} ${t('common.toShow')}`}</Typography>;
};

EmptyField.propTypes = {
  field: string.isRequired,
};

export default EmptyField;
