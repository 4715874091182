import { Box, Grid, makeStyles } from '@material-ui/core';
import _ from 'lodash';

import layoutPropTypes from '../../layoutPropTypes';
import ProjectCard from '../project-card';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles((theme) => ({
  cardFeatType: {
    '& > *:not(:last-child)': {
      [theme.breakpoints.down('sm')]: { marginBottom: theme.spacing(2) },
    },
  },
}));

const LayoutFeatured = ({ projects, type, ...props }) => {
  const classes = useStyles();
  const { desktop } = props;
  const { t } = useTranslation();
  return (
    <>
      {_.isEmpty(projects) ? (
        <Box component={Grid} item xs={12} fontWeight="fontWeightBold">
          {t('defaultInformation.noProjects')}
        </Box>
      ) : desktop ? (
        <>
          <Grid item xs={6} container alignItems="flex-start">
            {projects.slice(0, 2).map((p) => (
              <ProjectCard key={p.id} type={type} project={p} isBig {...props} />
            ))}
          </Grid>
          <Grid
            item
            xs={6}
            className={classes.cardFeatType}
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            {projects.slice(2).map((p) => (
              <ProjectCard type={type} key={p.id} project={p} isBig={false} {...props} />
            ))}
          </Grid>
        </>
      ) : (
        <Grid item xs={12} container alignItems="flex-start" className={classes.cardFeatType}>
          {projects.map((p) => (
            <ProjectCard type={type} key={p.id} project={p} {...props} />
          ))}
        </Grid>
      )}
    </>
  );
};

LayoutFeatured.propTypes = layoutPropTypes;

export default LayoutFeatured;
