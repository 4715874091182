/* eslint-disable graphql/template-strings */
import { useQuery, gql } from '@apollo/client';
import { useMediaQuery } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { useUnload, useSnackbar } from 'hooks';
import { closeBackdrop, openBackdrop } from 'reducers/backdropReducer';
// import { getDialogStore, closeDialog } from 'reducers/dialogReducer';
import { openLightbox } from 'reducers/lightboxReducer';
import { closeDialogAC3 } from 'reducers/testAC3/cache';

import { DIALOG } from '../../helpers/constants';

import AddStepTutorialDialog from './add-step-tutorial/AddStepTutorialDialog';
import AddTopicDialog from './add-topic/AddTopicDialog';
import ArchiveCommentDialog from './archive-comment/ArchiveCommentDialog';
import ContestInfoCard from './contest-info-card/ContestInfoCard';
import CropperDialog from './cropper/CropperDialog';
import DeactivateDialog from './deactive/DeactivateDialog';
import DeleteListDialog from './delete-list-project/DeleteListDialog';
import DeleteSectionTutorialDialog from './delete-section-tutorial/DeleteSectionTutorialDialog';
import DeleteDialog from './delete/DeleteDialog';
import EditProfileDialog from './edit-profile/EditProfileDialog';
import ExperienceContestDetails from './experience-contest-details/ExperienceContestDetailsDialog';
import InviteProjectMembersDialog from './invite-project-members/InviteProjectMembersDialog';
import ListProjectDialog from './list-project';
import ListDialog from './list/ListDialog';
import MemberMobileDialog from './member-mobile-list/MemberMobileDialog';
import PrivacyDialog from './privacy/PrivacyDialog';
import RemoveProjectFromListDialog from './remove-project-from-list/RemoveProjectFromListDialog';
import SubmitProjectsToContest from './submit-projects-to-contest/SubmitProjectsToContest';
import UpdatePublishDialog from './update-publish/UpdatePublishDialog';
import UserConfirmationDialog from './user-confirmation/UserConfirmationDialog';
// import DeleteProjectMemberDialog from './delete-project-member/DeleteProjectMemberDialog';

const Dialogs = {
  [DIALOG.LIST]: ListDialog,
  [DIALOG.PRIVACY]: PrivacyDialog,
  [DIALOG.DELETE]: DeleteDialog,
  [DIALOG.DEACTIVATE]: DeactivateDialog,
  [DIALOG.LIST_PROJECT]: ListProjectDialog,
  [DIALOG.DELETE_LIST_PROJECT]: DeleteListDialog,
  [DIALOG.VIEW_EXPERIENCE_CONTEST_DETAILS]: ExperienceContestDetails,
  [DIALOG.REMOVE_PROJECT_FROM_LIST]: RemoveProjectFromListDialog,
  [DIALOG.UPDATE_PUBLISH]: UpdatePublishDialog,
  [DIALOG.ADD_TOPIC]: AddTopicDialog,
  [DIALOG.EDIT_PROFILE]: EditProfileDialog,
  [DIALOG.INVITE_PROJECT_MEMBER]: InviteProjectMembersDialog,
  [DIALOG.ARCHIVE_COMMENT]: ArchiveCommentDialog,
  [DIALOG.ADD_STEP_TUTORIAL]: AddStepTutorialDialog,
  [DIALOG.CROP_IMAGE]: CropperDialog,
  [DIALOG.DELETE_SECTION_TUTORIAL]: DeleteSectionTutorialDialog,
  // [DIALOG.DELETE_PROJECT_MEMBER]: DeleteProjectMemberDialog,
  [DIALOG.USER_CONFIRMATION]: UserConfirmationDialog,
  [DIALOG.MOBILE_MEMBER]: MemberMobileDialog,
  [DIALOG.SUBMIT_PROJECTS_TO_CONTEST]: SubmitProjectsToContest,
  [DIALOG.CONTEST_INFO_CARD]: ContestInfoCard,
};

const GET_CART_ITEMS = gql`
  query GetDialogData {
    dialogData @client
  }
`;

const CocoonDialog = () => {
  const { data } = useQuery(GET_CART_ITEMS);
  // const { open, type, options } = useSelector(getDialogStore);
  const open = data.dialogData.isDialogOpen;
  const type = data.dialogData.typeDialog;
  const options = data.dialogData.optionsDialog;
  const dispatch = useDispatch();
  const handleCloseDialog = () => closeDialogAC3();
  const handleCloseBackdrop = () => dispatch(closeBackdrop());
  const handleOpenBackdrop = () => dispatch(openBackdrop());
  const handleOpenLightbox = (images, index) => dispatch(openLightbox(images, index));
  const { openSnackbarSuccess, openSnackbarError, openSnackbarWarning, enqueueSnackbarMultipleErrors } = useSnackbar();

  useUnload(handleCloseDialog);

  let Dialog = ListDialog;

  if (type) {
    Dialog = Dialogs[type];
  }

  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  return (
    <Dialog
      desktop={desktop}
      open={open}
      options={options}
      handleCloseDialog={handleCloseDialog}
      handleCloseBackdrop={handleCloseBackdrop}
      handleOpenBackdrop={handleOpenBackdrop}
      handleOpenLightbox={handleOpenLightbox}
      openSnackbarSuccess={openSnackbarSuccess}
      openSnackbarError={openSnackbarError}
      openSnackbarWarning={openSnackbarWarning}
      enqueueSnackbarMultipleErrors={enqueueSnackbarMultipleErrors}
    />
  );
};

export default CocoonDialog;
