import { arrayOf, bool, func, object, string } from 'prop-types';

import AddMembers from './AddMembers';
import Members from './Members';

const InviteContent = ({
  searchMember,
  onSearchMemberChange,
  project,
  setOpenSearch,
  openSearch,
  loading,
  optionsMembers,
  membersInvitation,
  onChangeChosenMembers,
  chosenMembers,
  onClickRemove,
  desktop,
}) => (
  <>
    <AddMembers
      searchMember={searchMember}
      onSearchMemberChange={onSearchMemberChange}
      setOpenSearch={setOpenSearch}
      openSearch={openSearch}
      loading={loading}
      optionsMembers={optionsMembers}
      onChangeChosenMembers={onChangeChosenMembers}
      chosenMembers={chosenMembers}
    />
    <Members
      searchMember={searchMember}
      onSearchMemberChange={onSearchMemberChange}
      project={project}
      membersInvitation={membersInvitation}
      onClickRemove={onClickRemove}
      desktop={desktop}
    />
  </>
);

InviteContent.propTypes = {
  searchMember: string,
  onSearchMemberChange: func,
  project: object,
  setOpenSearch: func,
  openSearch: bool,
  loading: bool,
  optionsMembers: arrayOf(object),
  membersInvitation: arrayOf(object),
  onChangeChosenMembers: func,
  chosenMembers: arrayOf(object),
  onClickRemove: func,
  desktop: bool,
};

export default InviteContent;
