import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import { object, bool, arrayOf } from 'prop-types';

import { ProjectMoreButton } from 'components';

import ProjectCardProgressData from './ProjectCardProgressData';

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  data: {
    color: theme.palette.success.dark,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  iconData: {
    '& h6': {
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
    },
  },
  gridItem: {
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  gridItemRight: {
    textAlign: 'right',
  },
}));

const ProjectCardStats = ({ progressData, statsData, desktop, ...props }) => {
  const classes = useStyles();

  return (
    <>
      {desktop && (
        <Grid item xs={12} className={clsx(classes.gridItem, classes.gridItemRight)}>
          <ProjectMoreButton {...props} />
        </Grid>
      )}
      <Grid item xs={desktop ? 12 : 6} container className={classes.gridItem}>
        {_.map(progressData, (p, i) => (
          <Grid key={`${p.data}-${i}`} item xs={desktop ? 6 : 12} container alignItems="center" justify="flex-start">
            <ProjectCardProgressData label={p.label} data={p.data} classes={classes} desktop={desktop} />
          </Grid>
        ))}
      </Grid>
      <Grid
        item
        xs={desktop ? 12 : 6}
        container
        spacing={desktop ? 4 : 0}
        alignItems="center"
        className={classes.gridItem}
      >
        {_.map(statsData, (p, i) => (
          <Grid key={`${p.data}-${i}`} item xs>
            <ProjectCardProgressData icon={p.icon} data={p.data} classes={classes} desktop={desktop} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

ProjectCardStats.propTypes = {
  desktop: bool.isRequired,
  progressData: arrayOf(object).isRequired,
  statsData: arrayOf(object).isRequired,
};

ProjectCardStats.defaultProps = {};

export default ProjectCardStats;
