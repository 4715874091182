import { Box, Typography, Grid, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { object, func, bool, arrayOf } from 'prop-types';
import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { formatDate_ll } from 'helpers';

import TimelineLog from './components/TimelineLog';
import LineLongSvg from './icons/LineLongSvg';
import LineSvg from './icons/LineSvg';

import { useDispatch, useSelector } from 'react-redux';

import { isEdit, isRead, getModeIsEditing } from 'reducers/panelReducer';

const useStyles = makeStyles((theme) => ({
  timelineStyle: {
    height: '100%',
    backgroundColor: '#E5E5E5',
    direction: 'rtl',
    overflowX: 'scroll',
    overflowY: 'hidden',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'unset',
    // paddingRight: theme.spacing(11),
    paddingTop: theme.spacing(11),
    margin: theme.spacing(2, 0),
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    overflow: '-moz-scrollbars-none',
    scrollBehavior: 'smooth',
    cursor: 'all-scroll',
  },
  fullHeightPad: {
    height: '100%',
    position: 'relative',
    marginBottom: '18px',
  },
  createdAt: {
    color: '#989898',
  },
  createdAtPad: {
    color: '#989898',
  },
  updateTitle: {
    color: '#989898',
    width: '100%',
    height: '100%',
    direction: 'ltr',
  },
  phaseTitle: {
    ...theme.typography.h6,
    color: '#4791CE',
    textAlign: 'center',
    width: 100,
    marginTop: -11,
  },
}));

const truncate = (str, length, ending) => {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = '...';
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  }
  return str;
};

const Timeline = ({
  project,
  handleChangeChosenUpdate,
  handleStatusNewLog,
  isNewLog,
  handleDeleteUpdate,
  updateLogs,
  isReload,
  reloadComponent,
  deleteNewLog,
  setIsShowAutoSave,
}) => {
  const isModeEditing = useSelector(getModeIsEditing);
  const [logSelected, setLogSelected] = useState(0);
  const [dataLogs, setDataLogs] = useState(null);
  const classes = useStyles();
  const logRefs = useRef([]);
  const buttonsRef = useRef([]);
  const scrollerRef = useRef();
  const { members, ownerId } = project;
  const membersId = [..._.map(members, (m) => m.id), ownerId];
  const newLog = {
    id: 0,
    name: '',
    dueDate: '',
    description: '',
    isMilestone: true,
    createdAt: new Date(),
  };

  const { t } = useTranslation(['panel', 'common']);

  useEffect(() => {
    if (updateLogs) {
      if (updateLogs.length === 0) {
        updateLogs.push(newLog);
        handleStatusNewLog(true);
      }
      setDataLogs(updateLogs);
    }
    if (isReload && updateLogs) {
      reloadComponent(false);
    }
  }, [updateLogs, isReload]);

  useEffect(() => {
    if (isNewLog) {
      setLogSelected(0);
    }
  }, [isNewLog]);

  useEffect(() => {
    if (dataLogs) {
      logRefs.current = logRefs.current.slice(0, dataLogs.length);
      buttonsRef.current = buttonsRef.current.slice(0, dataLogs.length);
    }
  }, [dataLogs]);

  const handleChangeLog = (index) => {
    if (!isModeEditing) {
      setLogSelected(index);
      handleChangeChosenUpdate(index);
    } else {
      setIsShowAutoSave(true);
    }
  };

  const handleBackLog = (e, indexBack) => {
    e.preventDefault();
    e.stopPropagation();
    handleChangeLog(indexBack);
  };

  return (
    <Box ref={scrollerRef} className={classes.timelineStyle}>
      {dataLogs &&
        dataLogs.map((element, index) => (
          <Box maxWidth={200} key={index}>
            <Grid container direction="column" justify="center" alignItems="center">
              <Grid xs={12} item container direction="row" justify="center" alignItems="center">
                <TimelineLog
                  projectId={+project.id}
                  log={{ ...element, membersId }}
                  logRef={(el) => {
                    logRefs.current[index] = el;
                  }}
                  buttonRef={(el) => {
                    buttonsRef.current[index] = el;
                  }}
                  index={index}
                  isSelected={logSelected === index}
                  isLast={index === dataLogs.length - 1}
                  handleChangeLog={handleChangeLog}
                  handleBackLog={handleBackLog}
                  handleStatusNewLog={handleStatusNewLog}
                  isNewLog={isNewLog}
                  handleDeleteUpdate={handleDeleteUpdate}
                  logSelected={logSelected}
                  deleteNewLog={deleteNewLog}
                />
                <LineSvg isLast={index === 0} />
              </Grid>
              <Box mt={3} textAlign="center">
                <Box component={Grid} item xs={12} container direction="row" justify="center" alignItems="center">
                  <Typography variant="h6" className={classes.createdAt}>
                    {formatDate_ll(element.createdAt)}
                  </Typography>
                </Box>
                <Box
                  component={Grid}
                  item
                  xs={12}
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  maxWidth={180}
                  height={40}
                  px={1}
                >
                  <Typography variant="body1" className={classes.updateTitle}>
                    {truncate(element.name, 45) || t('common:common.untitled')}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Box>
        ))}
      <Box width={100} className={classes.fullHeightPad}>
        <Typography className={classes.phaseTitle}>{t('panel:filter.phaseOne')}</Typography>
      </Box>
      <Box width={1440} className={classes.fullHeight}>
        <Grid container direction="column" justify="center" alignItems="flex-start">
          <Grid xs={12} item container direction="row" justify="center" alignItems="flex-start">
            <LineLongSvg />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

Timeline.propTypes = {
  project: object.isRequired,
  handleChangeChosenUpdate: func.isRequired,
  handleStatusNewLog: func.isRequired,
  isNewLog: bool.isRequired,
  handleDeleteUpdate: func.isRequired,
  updateLogs: arrayOf(object),
  isReload: bool,
  reloadComponent: func.isRequired,
  deleteNewLog: func.isRequired,
  setIsShowAutoSave: func,
};

export default Timeline;
