import { gql } from '@apollo/client';

// TODO: fragment contest

// done
// updated
export const GET_CONTESTS_BY_PARTNER_ID = gql`
  query contestsByPartnerId($partnerId: ID!, $page: Int!, $limit: PaginationLimit!) {
    contestsByPartnerId(partnerId: $partnerId, page: $page, limit: $limit) {
      id
      name
      description
      createdAt
      updatedAt
      assetLink
      viewsCount
      contestCuratorsCount
      contestFollowersCount
      contestSponsorsCount
      contestProjectsCount
      contestDeadline
      members {
        id
        email
      }
      projects {
        id
        name
      }
      tags {
        id
        tagName
      }
      creator {
        id
        email
        avatarLink
        partnerName
        email
        username
      }
    }
  }
`;

// done
// updated
export const GET_CONTEST_BY_ID = gql`
  query contestById($id: ID!) {
    contestById(id: $id) {
      id
      name
      description
      contestOverview
      createdAt
      updatedAt
      assetLink
      viewsCount
      contestCuratorsCount
      contestFollowersCount
      contestSponsorsCount
      contestProjectsCount
      isFeatured
      isArchived
      contestDeadline
      tags {
        id
        tagName
      }
      creator {
        id
        email
        avatarLink
        partnerName
        email
        avatarLink
        username
      }
      members {
        id
        username
        email
        avatarLink
      }
      sponsors {
        id
        isHidden
        sponsor {
          id
          partnerName
          email
          avatarLink
        }
      }
      projects {
        id
        name
        assetLink
        owner {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

// done
// updated
export const GET_FEATURED_CONTESTS = gql`
  query contestsFeatured($page: Int!, $limit: PaginationLimit!, $contestName: String) {
    contestsFeatured(page: $page, limit: $limit, contestName: $contestName) {
      id
      name
      assetLink
      creator {
        id
        email
        avatarLink
        partnerName
        email
        username
      }
    }
  }
`;

// done
// updated
export const GET_CONTEST_BACKGROUND_PICTURE_PRESIGNED_URL = gql`
  query contestBackgroundPicturePresignedUrl($contestId: ID!, $fileName: String!, $contentType: String!) {
    contestBackgroundPicturePresignedUrl(contestId: $contestId, fileName: $fileName, contentType: $contentType) {
      url
      key
    }
  }
`;
