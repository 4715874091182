import { List, ListItem, ListItemIcon, ListItemText, Popover, Typography, makeStyles } from '@material-ui/core';
import { MoreHoriz as MoreHorizIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { object } from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { cookieUserId } from 'helpers/auth';
import { DIALOG } from 'helpers/constants';
import { handleOpenDialogAC3 } from 'reducers/testAC3/cache';

const useStyles = makeStyles((theme) => ({
  listItemRow: {
    display: 'flex',
    alignItems: 'center',
    verticalAlign: 'center',
    marginBottom: '3%',
  },
  listItemIcon: {
    minWidth: 32,
  },
  listItemTitle: {
    fontSize: 18,
    color: '#000',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
}));

const ListProjectPopover = ({ list }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const match = useRouteMatch();
  const { id } = match.params;
  const userId = +id || +cookieUserId;
  const { t } = useTranslation();
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteList = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const options = {
      ownerId: userId,
      list,
    };
    handleOpenDialogAC3(DIALOG.DELETE_LIST_PROJECT, options);
    handleClose(e);
  };

  const open = Boolean(anchorEl);
  const popoverOverId = open ? 'project-popover' : undefined;

  return (
    <div>
      <MoreHorizIcon style={{ fill: '#989898', marginTop: 5 }} aria-describedby={popoverOverId} onClick={handleClick} />
      <Popover
        id={popoverOverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List>
          <ListItem onClick={(event) => handleDeleteList(event)} onFocus={(event) => event.stopPropagation()} button>
            <ListItemIcon className={classes.listItemIcon}>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText disableTypography>
              <Typography className={classes.listItemTitle}>{t('common.deleteList')}</Typography>
            </ListItemText>
          </ListItem>
        </List>
      </Popover>
    </div>
  );
};

ListProjectPopover.propTypes = {
  list: object.isRequired,
};

export default ListProjectPopover;
