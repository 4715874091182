import { Button, Grid, Typography, makeStyles } from '@material-ui/core';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles((theme) => ({
  btnInteract: {
    textTransform: 'none',
    padding: theme.spacing(0, 1),
    '&:hover': {
      background: 'transparent',
      '& *': {
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.secondary.main,
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& *': {
        fontSize: 12,
      },
    },
  },
}));

const ButtonRemove = ({ handleClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid item>
      <Button className={classes.btnInteract} onClick={handleClick}>
        <Typography variant="body1" color="textSecondary">
          {t('button.remove')}
        </Typography>
      </Button>
    </Grid>
  );
};

ButtonRemove.propTypes = {
  handleClick: func,
};

export default ButtonRemove;
