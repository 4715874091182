import { cookieUserId } from 'helpers/auth';

export const SameUserNull = (userId) => (WrappedComponent) => userId === cookieUserId ? WrappedComponent : null;

export const DiffUserNull = (userId) => (WrappedComponent) => userId !== cookieUserId ? WrappedComponent : null;

export const ProjectAuthNull = (ownerId, memberId) => (WrappedComponent) =>
  ownerId === cookieUserId || memberId === cookieUserId ? WrappedComponent : null;

export const SameUserAlt = (userId) => (Component1, Component2) => +userId === +cookieUserId ? Component1 : Component2;

export const ProjectAuthAlt = (ownerId, member) => (Component1, Component2) => {
  if (member) {
    return +ownerId === +cookieUserId || +member.id === +cookieUserId ? Component1 : Component2;
  }
  return SameUserAlt(ownerId)(Component1, Component2);
};
