import { Button, Grid, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { bool, func, number } from 'prop-types';

import { cookieUserId } from 'helpers/auth';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles((theme) => ({
  btnInteract: {
    textTransform: 'none',
    padding: theme.spacing(0, 1),
    '&:hover': {
      background: 'transparent',
      '& *': {
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.secondary.main,
      },
    },
  },
  btnInteractActive: {
    textTransform: 'none',
    padding: theme.spacing(0, 1),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.secondary.main,
    '&:hover': {
      background: 'transparent',
      '& *': {
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.secondary.main,
      },
    },
  },
  titleActive: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
  },
  titleInactive: {
    ...theme.typography.body1,
    fontSize: 14,
    color: '#989898',
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
  },
}));

const ButtonLike = ({ isLiked, likeCount, handleClick }) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  return (
    <Grid item>
      <Tooltip title={cookieUserId ? '' : t('sign.signInReminder')}>
        <Button className={isLiked ? classes.btnInteractActive : classes.btnInteract} onClick={handleClick}>
          <Typography className={isLiked ? classes.titleActive : classes.titleInactive}>
            <span style={{ marginRight: '6px' }}>{likeCount}</span>
            {t('button.like')}
          </Typography>
        </Button>
      </Tooltip>
    </Grid>
  );
};

ButtonLike.propTypes = {
  handleClick: func.isRequired,
  isLiked: bool,
  likeCount: number.isRequired,
};

ButtonLike.defaultProps = {
  isLiked: null,
};

export default ButtonLike;
