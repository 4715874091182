export const GET_METHOD = 'GET';
export const POST_METHOD = 'POST';

// Authorization purpose ONLY.
export const Everyone = [1, 2];
export const Admin = [1];

export const updateAttachmentDropzone = {
  width: '100%',
  height: 50,
  borderWidth: 2,
  borderColor: '#e3e3e3',
  borderStyle: 'dashed',
  textAlign: 'center',
  display: 'inline-block',
  cursor: 'pointer',
};

export const acceptedBasicFiles = '.jpg, .png, .jpeg, .docx, .doc, .xlsx, .xls, .csv, .pdf, .zip';

export const acceptedImageFiles = '.jpg, .png, .jpeg .gif';

export const TAG_INPUT = {
  tag_length: '20',
  keyCodes: {
    enter: 13,
  },
};

export const BASE_COLORS = {
  main: '#1d8ece',
};

export const FILE_UPLOAD_TYPE = {
  projectPicture: 'ProjectPicture',
  updateAttachment: 'UpdateAttachment',
  userAvatar: 'UserAvatar',
  backgroundPicture: 'BackgroundPicture',
};

export const COOKIES = {
  names: {
    username: 'username',
    userInfo: 'user-info',
  },
  // domain: process.env.DOMAIN_NAME || 'localhost',
};

export const PAGINATION = {
  three: 3,
  five: 5,
};

export const PROJECT_TYPE = {
  ALL: 'ALL',
  FEAT_TYPE: 'FEATURED',
  SUBS_TYPE: 'SUBSCRIPTIONS',
  DISC_TYPE: 'DISCOVER',
  WANT_TYPE: 'WANT',
  LOGS_TYPE: 'LOGS',
  FORUM_TYPE: 'FORUM',
};

export const ROLES = {
  MEMBER: 'member',
  VIEWER: 'viewer',
};

export const CURRENT_ROLE = ROLES.MEMBER;

export const DIALOG = {
  DEACTIVATE: 'DEACTIVATE',
  PRIVACY: 'PRIVACY',
  DELETE: 'DELETE',
  LIST: 'LIST',
  LIST_PROJECT: 'LIST_PROJECT',
  ADD_TOPIC: 'ADD_TOPIC',
  DELETE_LIST_PROJECT: 'DELETE_LIST_PROJECT',
  UPDATE_PUBLISH: 'UPDATE_PUBLISH',
  VIEW_EXPERIENCE_CONTEST_DETAILS: 'VIEW_EXPERIENCE_CONTEST_DETAILS',
  REMOVE_PROJECT_FROM_LIST: 'REMOVE_PROJECT_FROM_LIST',
  EDIT_PROFILE: 'EDIT_PROFILE',
  INVITE_PROJECT_MEMBER: 'INVITE_PROJECT_MEMBER',
  ARCHIVE_COMMENT: 'ARCHIVE_COMMENT',
  ADD_STEP_TUTORIAL: 'ADD_STEP_TUTORIAL',
  CROP_IMAGE: 'CROP_IMAGE',
  // DELETE_PROJECT_MEMBER: 'DELETE_PROJECT_MEMBER'
  DELETE_SECTION_TUTORIAL: 'DELETE_SECTION_TUTORIAL',
  USER_CONFIRMATION: 'USER_CONFIRMATION',
  MOBILE_MEMBER: 'MOBILE_MEMBER',
  SUBMIT_PROJECTS_TO_CONTEST: 'SUBMIT_PROJECTS_TO_CONTEST',
  CONTEST_INFO_CARD: 'CONTEST_INFO_CARD',
};

export const DETAILS_TYPE = {
  EXPERIENCES: 'EXPERIENCES',
  CONTESTS: 'CONTESTS',
};

export const CK_EDITOR_UPLOAD_TYPES = {
  PROJECT_OVERVIEW: 'projectOverview',
  TUTORIAL_SECTION: 'tutorialSection',
  PROJECT_LOG: 'projectLog',
  CONTEST_OVERVIEW: 'contestOverview',
};

export const CK_EDITOR_IMAGE_UPLOAD_URL = `${
  process.env.REST_ENDPOINT || 'http://localhost:5000/api/backend'
}/ckeditor-project-files`;

export const CK_EDITOR_CONTEST_IMAGE_UPLOAD_URL = `${
  process.env.REST_ENDPOINT || 'http://localhost:5000/api/backend'
}/ckeditor-contest-files`;

export const USER_TABS = {
  PERSONAL_PROJECTS: 'PERSONAL_PROJECTS',
  FOLLOWED_PROJECTS: 'FOLLOWED_PROJECTS',
  LISTS_WITH_PROJECTS: 'LISTS_WITH_PROJECTS',
  BOARD: 'BOARD',
  ARCHIVED_PROJECTS: 'ARCHIVED_PROJECTS',
  USER_INFO: 'USER_INFO',
};

export const PARTNER_TABS = {
  PARTNER_PROJECTS: 'PARTNER_PROJECTS',
  SPONSORED_PARTNER_PROJECTS: 'SPONSORED_PARTNER_PROJECTS',
  PARTNER_CONTESTS: 'PARTNER_CONTESTS',
  PARTNER_INFO: 'PARTNER_INFO',
};

export const CONTEST_TABS = {
  PROJECTS: 'Projects',
  APPLICANTS_SPONSORS: 'Applicants/Sponsors',
};

export const PROFILE_TYPES = {
  PARTNER_PROFILE: 'PARTNER_PROFILE',
  USER_PROFILE: 'USER_PROFILE',
  CONTEST_PROFILE: 'CONTEST_PROFILE',
};

export const QUERY_TABS = [
  PARTNER_TABS.PARTNER_PROJECTS,
  PARTNER_TABS.SPONSORED_PARTNER_PROJECTS,
  PARTNER_TABS.PARTNER_CONTESTS,
  USER_TABS.PERSONAL_PROJECTS,
  USER_TABS.FOLLOWED_PROJECTS,
  USER_TABS.LISTS_WITH_PROJECTS,
  USER_TABS.ARCHIVED_PROJECTS,
];
