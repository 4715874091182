import { useMutation } from '@apollo/client';
import { bool, number, string, oneOfType } from 'prop-types';
import { useState, useEffect } from 'react';

import { LIKE_COMMENT } from 'apollo/mutations/forum-mutation';

import ButtonLike from './ButtonLike';

const ButtonLikeContainer = ({ isLiked, likeCount, projectId }) => {
  const [_isLiked, _setIsLiked] = useState(false);
  const [_likeCount, _setLikeCount] = useState(0);

  const [likeComment] = useMutation(LIKE_COMMENT);

  useEffect(() => {
    _setIsLiked(isLiked);
    _setLikeCount(likeCount);
  }, []);

  const toggleLike = async () => {
    const { data: res } = await likeComment({
      variables: { like: { projectCommentId: projectId } },
    });

    if (res?.toggleProjectCommentLike?.status === 'SUCCESS') {
      if (!_isLiked) {
        _setIsLiked(true);
        _setLikeCount((lc) => lc + 1);
      } else {
        _setIsLiked(false);
        _setLikeCount((lc) => lc - 1);
      }
    }
  };

  return <ButtonLike isLiked={_isLiked} likeCount={_likeCount} handleClick={toggleLike} />;
};

ButtonLikeContainer.propTypes = {
  isLiked: bool,
  likeCount: number,
  projectId: oneOfType([number, string]),
};

export default ButtonLikeContainer;
