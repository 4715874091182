import { useMediaQuery, Box, Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  heroButtons: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  heroTitle: {
    fontSize: 80,
    [theme.breakpoints.down('sm')]: {
      fontSize: 28,
    },
  },
  tagline: {
    color: '#333',
  },
}));

const Banner = () => {
  const classes = useStyles();
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const { t } = useTranslation();
  return (
    <>
      <Typography className={classes.heroTitle} variant={desktop ? 'h2' : 'h4'} align="center" gutterBottom>
        {t('home.allAboutProject')}
      </Typography>
      <Typography component="div" className={classes.tagline} variant={desktop ? 'h5' : 'h6'} align="center">
        <Box display="block">{t('home.heroBoxDescription')}</Box>
        <span>{t('home.heroSpanDescription')}</span>
      </Typography>
    </>
  );
};

export default Banner;
