import { useLazyQuery } from '@apollo/client';
import _ from 'lodash';
import { number, bool, func, string, oneOfType } from 'prop-types';
import { useEffect, useState } from 'react';

import { ADD_TUTORIAL_STEP, EDIT_TUTORIAL_STEP } from 'apollo/mutations/tutorial-mutation';
import { GET_TUTORIAL_STEPS } from 'apollo/queries/tutorial-query';
import { DIALOG } from 'helpers/constants';
import { handleOpenDialogAC3 } from 'reducers/testAC3/cache';

import TabSideMenu from '../../../../components/side-menu/TabSideMenu';

import TutorialsList from './TutorialsList';

const LIMIT = 5;

const TutorialsListContainer = ({ desktop, projectId, stepActive, isNewProject, ownerId, handleChangeStep }) => {
  const [page, setPage] = useState(1);
  const [isPaging, setIsPaging] = useState(false);
  const [steps, setSteps] = useState(null);
  const [canFetchMore, setCanFetchMore] = useState(true);

  const variables = { projectId, page, limit: 'TEN' };

  const [getSteps, { data }] = useLazyQuery(GET_TUTORIAL_STEPS, {
    variables,
    fetchPolicy: 'network-only',
    onCompleted: () => {
      const { stepsByProjectId } = data;

      if (stepsByProjectId.length < LIMIT) {
        setCanFetchMore(false);
      }

      setSteps((steps) => {
        if (steps === null) {
          return [...stepsByProjectId];
        }
        setIsPaging(false);
        return [...steps, ...stepsByProjectId];
      });

      if (stepsByProjectId.length > 0 && stepActive === -1) {
        handleChangeStep(undefined, +stepsByProjectId[0].id);
      }
    },
  });

  useEffect(() => {
    if (projectId) {
      setCanFetchMore(true);
      setSteps(null);
      setPage(1);
    }
  }, [projectId]);

  useEffect(() => {
    getSteps();
  }, [page]);

  const handleAddStep = (dataStepCreated) => {
    const tmpSteps = [...steps];
    const { addProjectTutorialStep } = dataStepCreated;

    const newStep = {
      sections: [],
      ...addProjectTutorialStep,
    };

    tmpSteps.push(newStep);
    setSteps(tmpSteps);
    handleChangeStep(undefined, +addProjectTutorialStep.id);
  };

  const handleUpdateStep = (updatedStep) => {
    const tmpSteps = [...steps];
    const selectedStep = _.find(tmpSteps, ({ id }) => +id === +updatedStep.id);

    if (selectedStep) {
      selectedStep.name = updatedStep.name;
      selectedStep.stepNumber = updatedStep.stepNumber;

      setSteps(tmpSteps);
    }
  };

  const handleDeleteStep = async (stepId) => {
    const tmpSteps = [...steps];
    _.remove(tmpSteps, { id: stepId });
    await setSteps(tmpSteps);
    handleChangeStep(undefined, steps.length > 0 ? +steps[0].id : -1);
  };

  const handleLoadMoreSteps = () => {
    if (canFetchMore && !isPaging) {
      setIsPaging(true);
      setPage((page) => {
        if (page === variables.page) {
          return page + 1;
        } else return page;
      });
    }
  };

  const openAddStepDialog = (e) => {
    e.stopPropagation();

    const options = {
      projectId,
      mutation: ADD_TUTORIAL_STEP,
      handlePositiveButton: handleAddStep,
    };

    handleOpenDialogAC3(DIALOG.ADD_STEP_TUTORIAL, options);
  };

  const openEditStepDialog = (e, { id, name, stepNumber }) => {
    e.stopPropagation();

    const options = {
      isEditMode: true,
      step: {
        id,
        name,
        stepNumber: Number(stepNumber),
      },
      mutation: EDIT_TUTORIAL_STEP,
      handlePositiveButton: handleUpdateStep,
      handleDeleteStep: handleDeleteStep,
    };

    handleOpenDialogAC3(DIALOG.ADD_STEP_TUTORIAL, options);
  };

  return (
    <TabSideMenu
      searchName={desktop ? 'step' : undefined}
      options={steps}
      desktop={desktop}
      components={
        <TutorialsList
          steps={_.sortBy(steps, ['index'])}
          stepActive={stepActive}
          isNewProject={isNewProject}
          canFetchMore={canFetchMore}
          handleChangeStep={handleChangeStep}
          handleLoadMoreSteps={handleLoadMoreSteps}
          ownerId={ownerId}
          handleAddStep={openAddStepDialog}
          handleEditStep={openEditStepDialog}
        />
      }
    />
  );
};

TutorialsListContainer.propTypes = {
  handleChangeStep: func.isRequired,
  isNewProject: bool.isRequired,
  ownerId: number,
  projectId: oneOfType([number, string]),
  desktop: bool.isRequired,
  stepActive: number.isRequired,
};

export default TutorialsListContainer;
