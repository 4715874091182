import { Paper, makeStyles } from '@material-ui/core';
import { string, object, bool } from 'prop-types';
import { memo } from 'react';

import { hex2rgba } from 'helpers/common';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  imgFeatured: {
    backgroundColor: '#F5F5F5',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    boxShadow: 'none',
    minHeight: 'inherit',
    position: 'relative',
    overflow: 'hidden',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.6)',
  },
  ribbon: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: theme.spacing(1, 5),
    textAlign: 'center',
    transform: 'translateY(-100%) rotate(90deg) translateX(70.71067811865476%) rotate(-45deg)',
    transformOrigin: 'bottom right',

    /* Demo styles */
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: '100%',
    letterSpacing: 1,
    color: theme.palette.common.white,
    boxShadow: `inset 0 0 0 2000px ${hex2rgba('#1161a3', 0.7)}`,

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 3),
      fontSize: '50%',
    },
  },
}));

const srcPropsAreEqual = (prevProps, nextProps) => {
  let isNotRender = false;
  if (prevProps.src === nextProps.src) {
    if (nextProps.previewImg !== null) {
      isNotRender = false;

      if (prevProps.previewImg === nextProps.previewImg) {
        isNotRender = true;
      }
    }
  }
  return isNotRender;
};

const ImageFeatured = memo(({ src, previewImg, isFeatured, desktop }) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const getBackgroundImage = () => {
    if (previewImg) return `url(${URL.createObjectURL(previewImg)})`;
    if (src) return `url(${src})`;
    return undefined;
  };

  return (
    <Paper square className={classes.imgFeatured} style={{ backgroundImage: getBackgroundImage() }}>
      {src && !previewImg && <img style={{ display: 'none' }} src={src} alt={src} />}
      {previewImg && <img style={{ display: 'none' }} src={previewImg} alt={previewImg.name} />}
      {desktop && <div className={classes.overlay} />}
      {isFeatured && (
        <div className={classes.ribbon}>
          <span>{t('common.featured')}</span>
        </div>
      )}
    </Paper>
  );
}, srcPropsAreEqual);

ImageFeatured.propTypes = {
  src: string,
  previewImg: object,
  isFeatured: bool,
  desktop: bool,
};

ImageFeatured.defaultProps = {
  src: null,
  previewImg: null,
  isFeatured: undefined,
};

export default ImageFeatured;
