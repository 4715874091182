import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  Grid,
  TextField,
} from '@material-ui/core';
import { BookmarkBorder as BookmarkBorderIcon } from '@material-ui/icons';
import { func, string, bool, object, number, arrayOf } from 'prop-types';
import { useTranslation } from 'react-i18next';

import ListGallery from './components/list-gallery/ListGallery';
import ProjectsGallery from './components/projects-gallery/ProjectsGallery';
import listProjectDialogStyle from './listProjectDialogStyle';

const ListProjectDialog = ({
  step,
  listWithoutProject,
  handleCheckList,
  selectedListIds,
  handleCheckProject,
  selectedProjectIds,
  listName,
  setListName,
  open,
  handleCloseDialog,
  handleBack,
  handleContinueOrSave,
  disableSave,
}) => {
  const classes = listProjectDialogStyle();
  const { t } = useTranslation(['common', 'dialog']);
  const renderStep = () => {
    if (step === 0) {
      return (
        <ListGallery
          handleContinueOrSave={handleContinueOrSave}
          lists={listWithoutProject}
          handleCheckList={handleCheckList}
          selectedListIds={selectedListIds}
        />
      );
    }
    if (step === 1) {
      return (
        <TextField
          className={classes.textField}
          placeholder={t('dialog:listProjects.enterListName')}
          onChange={(e) => setListName(e.target.value)}
          variant="outlined"
          fullWidth
        />
      );
    }
    return (
      <ProjectsGallery
        handleContinueOrSave={handleContinueOrSave}
        handleCheckProject={handleCheckProject}
        selectedProjectIds={selectedProjectIds}
      />
    );
  };

  return open ? (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="list-project-dialog-title"
      aria-describedby="list-project-description"
      className={classes.dialog}
      maxWidth="md"
    >
      <DialogTitle disableTypography id="list-project-title">
        <Grid container alignItems="center" alignContent="space-between">
          <Grid item xs>
            <Typography color="textSecondary" variant="h5">
              <Box display="flex" alignItems="center" className={classes.header}>
                <BookmarkBorderIcon color="primary" className={classes.icon} />
                {step === 0 && t('dialog:listProjects.addToList')}
                {step === 1 && t('dialog:listProjects.newList')}
                {step === 2 && `${t('dialog:listProjects.addProject')} ${listName}`}
              </Box>
            </Typography>
          </Grid>
          <Grid item xs>
            <DialogActions>
              <Button variant="outlined" className={classes.button} onClick={handleBack}>
                {step === 2 ? t('common:button.cancel') : t('common:button.back')}
              </Button>
              <Button
                autoFocus
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => handleContinueOrSave()}
                disabled={disableSave()}
              >
                {t('common:button.save')}
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>{renderStep()}</DialogContent>
    </Dialog>
  ) : null;
};

ListProjectDialog.defaultProps = {
  listWithoutProject: [],
  selectedListIds: [],
  selectedProjectIds: [],
};

ListProjectDialog.propTypes = {
  step: number.isRequired,
  listWithoutProject: arrayOf(object),
  handleCheckList: func.isRequired,
  selectedListIds: arrayOf(string),
  handleCheckProject: func.isRequired,
  selectedProjectIds: arrayOf(number),
  listName: string.isRequired,
  setListName: func.isRequired,
  open: bool.isRequired,
  handleBack: func.isRequired,
  handleContinueOrSave: func.isRequired,
  disableSave: func.isRequired,
  handleCloseDialog: func.isRequired,
};

export default ListProjectDialog;
