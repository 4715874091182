/* eslint-disable max-len */
const FlagIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.1318 3.125C11.2126 3.125 9.48707 1.875 6.80707 1.875C5.58437 1.875 4.52961 2.12821 3.65391 2.4675C3.7407 2.20699 3.76926 1.93061 3.73758 1.65785C3.63543 0.764651 2.89715 0.0622677 2.00004 0.00406456C0.907891 -0.0667948 0 0.798088 0 1.875C0 2.56891 0.377227 3.1743 0.9375 3.49852V19.375C0.9375 19.7202 1.2173 20 1.5625 20H2.1875C2.5327 20 2.8125 19.7202 2.8125 19.375V16.1155C4.29176 15.4406 5.59606 15 7.8057 15C9.72492 15 11.4504 16.25 14.1304 16.25C16.4148 16.25 18.1137 15.3665 19.1518 14.6882C19.6815 14.3422 20 13.752 20 13.1193V3.74754C20 2.40149 18.6225 1.49098 17.3866 2.02422C15.9841 2.62926 14.5172 3.125 13.1318 3.125ZM18.125 13.125C17.2741 13.727 15.7491 14.375 14.1304 14.375C11.7888 14.375 10.146 13.125 7.8057 13.125C6.11191 13.125 4.0409 13.4923 2.8125 14.0625V5C3.66344 4.39797 5.18844 3.75 6.80707 3.75C9.14867 3.75 10.7915 5 13.1318 5C14.8221 5 16.8943 4.32164 18.125 3.75V13.125Z"
      fill="#1E6BAB"
    />
  </svg>
);

export default FlagIcon;
