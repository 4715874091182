/* eslint-disable prettier/prettier */
/* eslint-disable max-len */
import { Typography, makeStyles } from '@material-ui/core';
import { func } from 'prop-types';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

// https://kovart.github.io/dashed-border-generator/
const strokeBackground =
  "data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23161616FF' stroke-width='4' stroke-dasharray='11' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e";

const useStyles = makeStyles(() => ({
  dropZoneWrapper: {
    height: 300,
    width: '100%',
    backgroundImage: `url("${strokeBackground}")`,
    borderRadius: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

function ImageDrop({ onChange }) {
  const classes = useStyles();

  const onDrop = useCallback((acceptedFiles) => {
    onChange(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()} className={classes.dropZoneWrapper}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <Typography variant="h6">Drop the files here</Typography>
      ) : (
        <Typography variant="h6">Upload or Drop cover image</Typography>
      )}
    </div>
  );
}

ImageDrop.propTypes = {
  onChange: func.isRequired,
};

export default ImageDrop;
