import { Box, Button, Grid, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { number, string } from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { cookieUserId } from 'helpers/auth';

const useStyles = makeStyles((theme) => ({
  btnInteract: {
    padding: theme.spacing(0, 1),
    '&:hover': {
      background: 'transparent',
      '& *': {
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.secondary.main,
      },
    },
    '& p': {
      textTransform: 'none',
      color: '#989898',
      fontSize: 14,
      [theme.breakpoints.down('sm')]: {
        fontSize: 13,
      },
    },
  },
}));

const ButtonReply = ({ topicLink, replyCount }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid item>
      <Tooltip title={cookieUserId ? '' : t('sign.signInReminder')}>
        <Button className={classes.btnInteract}>
          <Box display="block" component={Link} to={topicLink}>
            <Typography variant="body1">
              <span style={{ marginRight: '6px' }}> {replyCount}</span>
              {replyCount > 1 ? t('common.replies') : t('common.reply')}
            </Typography>
          </Box>
        </Button>
      </Tooltip>
    </Grid>
  );
};

ButtonReply.propTypes = {
  replyCount: number.isRequired,
  topicLink: string.isRequired,
};

export default ButtonReply;
