import { Tooltip, IconButton } from '@material-ui/core';
import { Link as LinkIcon } from '@material-ui/icons';
import { func } from 'prop-types';
import { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

const ButtonCopyLink = ({ handleClickLogName }) => {
  const { t } = useTranslation();
  const [tooltipTitle, setTooltipTitle] = useState(t('log.copyTag'));
  const [colorIcon, setColorIcon] = useState('primary');

  const handleCopyLink = (e) => {
    e.preventDefault();

    handleClickLogName(e);

    const el = document.createElement('input');
    el.value = window.location.href;
    el.id = 'url-input';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    el.remove();

    setTooltipTitle(t('log.copiedTag'));
    setColorIcon('secondary');
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (tooltipTitle === t('log.copiedTag')) {
        setTooltipTitle(t('log.copyTag'));
        setColorIcon('primary');
      }
    }, 5000);
    return () => clearTimeout(timer);
  }, [tooltipTitle]);

  return (
    <Tooltip title={tooltipTitle}>
      <IconButton color={colorIcon} aria-label="copy link" onClick={handleCopyLink}>
        <LinkIcon />
      </IconButton>
    </Tooltip>
  );
};

ButtonCopyLink.propTypes = { handleClickLogName: func.isRequired };

export default ButtonCopyLink;
