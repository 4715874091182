import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { getBackdropOpen, closeBackdrop } from 'reducers/backdropReducer';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: theme.palette.common.white,
  },
}));

const CocoonBackdrop = () => {
  const classes = useStyles();

  const backdropOpen = useSelector(getBackdropOpen);

  const dispatch = useDispatch();
  const handleCloseBackdrop = () => dispatch(closeBackdrop());

  return (
    <Backdrop className={classes.backdrop} open={backdropOpen} onClick={handleCloseBackdrop}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default CocoonBackdrop;
