import { Collapse, Box, Typography, GridList, GridListTile, Link, makeStyles } from '@material-ui/core';
import { DescriptionOutlined as DescriptionOutlinedIcon } from '@material-ui/icons';
import _ from 'lodash';
import { arrayOf, object, bool } from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Image, Truncate } from 'components';
import mimeTypes from 'helpers/mimeTypes';
import { openLightbox } from 'reducers/lightboxReducer';

const useStyles = makeStyles((theme) => ({
  linkAttachment: {
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(0.5, 0),
    '& svg': {
      marginRight: theme.spacing(1),
    },
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
  },
  button: {
    padding: 0,
    '& h6': {
      fontSize: '15px',
    },
  },
  gridList: {
    '& .MuiGridListTile-tile > div': {
      backgroundColor: 'rgba(0,0,0,0.1)',
    },
  },
}));

const LogHomeAttachments = ({ attachments, desktop }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleOpenLightbox = (images, index) => dispatch(openLightbox(images, index));

  const { png, jpg, gif, bmp, ico, tif, svg } = mimeTypes;

  const files = attachments ? [...attachments] : null;
  const images = files ? _.remove(files, (file) => [png, jpg, gif, bmp, ico, tif, svg].includes(file.assetType)) : null;

  // const [expanded, setExpanded] = useState(true);

  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };

  const handleImageZoom = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    handleOpenLightbox(images, index);
  };

  const handleDownload = (e) => {
    if (e) e.stopPropagation();
  };

  const renderAttachments = () => {
    const renderFiles = _.map(files, (file) => (
      <Typography key={file.id} variant="h6" className={classes.linkAttachment} gutterBottom>
        <Box
          component={Link}
          display="flex"
          href={file.assetLink}
          title={file.name}
          download={file.name}
          aria-label="download"
          onClick={handleDownload}
          alignItems="center"
        >
          <Box flexGrow={1} display="flex" alignItems="center" style={{ wordBreak: 'break-all' }} mr={1}>
            <DescriptionOutlinedIcon fontSize={desktop ? 'default' : 'small'} />
            <Truncate lines={1} width={desktop ? 300 : 200}>
              {file.name}
            </Truncate>
          </Box>
          <Box color="black">{t('button.download')}</Box>
        </Box>
      </Typography>
    ));

    const renderImages = (
      <GridList cellHeight="auto" className={classes.gridList} cols={3} style={{ margin: desktop ? -8 : -2 }}>
        {_.map(images, (img, index) => (
          <GridListTile style={{ padding: desktop ? 8 : 2 }} key={img.id} cols={1}>
            <Image
              src={img.assetLink}
              alt={img.name}
              isHoverable
              hasZoom
              hasDownload
              onZoomCLick={(e) => handleImageZoom(e, index)}
              onClick={(e) => handleImageZoom(e, index)}
              onDownloadClick={handleDownload}
              aspectRatio={1}
            />
          </GridListTile>
        ))}
      </GridList>
    );

    return (
      <>
        {renderFiles}
        {renderImages}
      </>
    );
  };

  return (
    <>
      <Collapse in={true} timeout="auto" unmountOnExit>
        {_.isEmpty(attachments) ? <b /> : renderAttachments()}
      </Collapse>
    </>
  );
};

LogHomeAttachments.propTypes = {
  attachments: arrayOf(object).isRequired,
  desktop: bool.isRequired,
};

export default LogHomeAttachments;
