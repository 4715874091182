import { gql } from '@apollo/client';

const FRAGMENT = gql`
  fragment stateFragment on State {
    id
    iso
    name
    countryId
  }
`;

// done
// updated
export const GET_STATES_BY_COUNTRY_ID = gql`
  query statesByCountryId($countryId: ID!, $page: Int, $limit: PaginationLimit) {
    statesByCountryId(countryId: $countryId, page: $page, limit: $limit) {
      ...stateFragment
    }
  }
  ${FRAGMENT}
`;
