import { useLazyQuery } from '@apollo/client';
import _ from 'lodash';
import { object, oneOf } from 'prop-types';
import { useEffect } from 'react';

import { cookieUserId } from 'helpers/auth';
import { PROJECT_TYPE } from 'helpers/constants';

import Forum from '../forum/Forum';
import Logs from '../log-home/Logs';

import HomeProjects from './HomeProjects';

const HomeProjectsContainer = ({ query, variables, type, ...props }) => {
  const [fetchProjects, { loading: loadingProjects, data: dataProjects, error: errorProjects }] = useLazyQuery(query, {
    variables: {
      userId: cookieUserId,
      ...variables,
    },
  });

  const [fetchForum, { loading: loadingForum, data: dataForum, error: errorForum }] = useLazyQuery(query, {
    variables: {
      userId: cookieUserId,
      ...variables,
    },
  });

  const [fetchLogs, { loading: loadingLogs, data: dataLogs, error: errorLogs }] = useLazyQuery(query, {
    variables: {
      ...variables,
    },
  });

  useEffect(() => {
    if (query) {
      if (type === PROJECT_TYPE.WANT_TYPE || type === PROJECT_TYPE.LOGS_TYPE) {
        fetchLogs();
      } else if (type === PROJECT_TYPE.FORUM_TYPE) {
        fetchForum();
      } else {
        fetchProjects();
      }
    }
  }, [query]);

  let dataDisplay;

  if (dataProjects || loadingProjects || errorProjects) {
    dataDisplay = (
      <HomeProjects
        projects={(dataProjects && dataProjects.projects) || []}
        type={type}
        loading={loadingProjects}
        error={errorProjects}
        query={query}
        {...props}
      />
    );
  } else if (dataForum || errorForum || loadingForum) {
    dataDisplay = (
      <Forum
        logs={dataForum && dataForum.landingPageLatestForumComments}
        loading={loadingForum}
        error={errorForum}
        {...props}
      />
    );
  } else {
    dataDisplay = (
      <Logs logs={dataLogs && dataLogs.landingPageLatestLogs} loading={loadingLogs} error={errorLogs} {...props} />
    );
  }

  return dataDisplay;
};

HomeProjectsContainer.propTypes = {
  query: object,
  variables: object,
  type: oneOf(_.values(PROJECT_TYPE)).isRequired,
};

HomeProjectsContainer.defaultProps = {
  variables: {},
  query: undefined,
};

export default HomeProjectsContainer;
