import { Box, Button, Grid, Typography, makeStyles } from '@material-ui/core';
import { func, number, object, string, oneOfType } from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { getQueryStringParams } from 'helpers/url';

const useStyles = makeStyles((theme) => ({
  grid: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0.5),
    textTransform: 'none',
    textAlign: 'left',
    borderRadius: '6px',
    '&:hover': {
      backgroundColor: '#F5F5F5',
    },
    '& > *': {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      '& > *': {
        padding: theme.spacing(0.5, 1),
        fontSize: 13,
      },
    },
  },
  link: {
    '&': { color: theme.palette.primary.main },
    '&:hover': {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
}));

const btnActive = (active) => ({
  backgroundColor: active ? '#F5F5F5' : 'transparent',
});

const ForumTopic = ({ topic, topicActive, handleChangeTopic }) => {
  const classes = useStyles();
  const { hash } = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  useEffect(() => {
    if (getQueryStringParams('topic', hash, '#') === `${topic.id}`) {
      handleChangeTopic(topic.id);
    }
  }, [hash]);

  const onClickChangeTopic = (topicId) => {
    history.push({
      pathname: history.location.pathname,
      hash: `#tab=forum&topic=${topicId}`,
      state: { clicked: true },
    });
  };

  return (
    <Grid
      container
      className={classes.grid}
      component={Button}
      onClick={() => onClickChangeTopic(topic.id)}
      style={btnActive(topicActive === topic.id)}
    >
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.link}>
            {topic.name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box component="span" color="text.secondary">
            {t('comments')}
          </Box>
          <Box component="span" fontWeight="fontWeightBold" ml={1}>
            {topic.totalComments} {t('comments')}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

ForumTopic.propTypes = {
  topic: object.isRequired,
  topicActive: oneOfType([number, string]).isRequired,
  handleChangeTopic: func.isRequired,
};

export default ForumTopic;
