import { Container, Grid, makeStyles } from '@material-ui/core';
import { arrayOf, node, object, oneOfType } from 'prop-types';
import { withRouter } from 'react-router-dom';

import Announcement from './components/announcement/Announcement';
import AppFooter from './components/footer';
import AppHeader from './components/header';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      flexGrow: 1,
      height: '100%',
    },
    fullHeight: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      height: '100%',
    },
    main: {
      backgroundColor: theme.palette.background.paper,
      height: '100%',
      flex: 1,
      margin: theme.spacing(2, 0),
      marginTop: 0,
      marginBottom: 0,
    },
    rootPanel: {
      height: '100%',
      minHeight: '100vh',
      backgroundColor: '#E5E5E5',
    },
  };
});

const AppLayout = ({ children, location }) => {
  const classes = useStyles();
  const isNotPanel = !location.pathname.includes('/panel');

  return isNotPanel ? (
    <div className={classes.root}>
      <Grid container className={classes.fullHeight}>
        <Announcement />
        <AppHeader />
        <Container maxWidth={false} disableGutters className={classes.main}>
          {children}
        </Container>
        <AppFooter />
      </Grid>
    </div>
  ) : (
    <div className={classes.rootPanel}>{children}</div>
  );
};

// console complained about element
AppLayout.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  location: object.isRequired,
};

export default withRouter(AppLayout);
