import { useMediaQuery } from '@material-ui/core';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { useUnload } from 'hooks';
import { getLightboxStore, closeLightbox, changeIndex } from 'reducers/lightboxReducer';

import { Carousel, Slide } from './components';

const CocoonLightbox = () => {
  const { open, index, images } = useSelector(getLightboxStore);

  const dispatch = useDispatch();
  const handleCloseLightbox = () => dispatch(closeLightbox());
  const handleChangeIndex = (index) => dispatch(changeIndex(index));

  const mobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  // Prevent user reload when dialog is opened
  useUnload(handleCloseLightbox);

  return (
    <Carousel
      open={open}
      mobile={mobile}
      index={index}
      onChange={handleChangeIndex}
      onClose={handleCloseLightbox}
      onStart={handleCloseLightbox}
      style={{ position: 'absolute' }}
    >
      {_.map(images, ({ id, name, assetLink }) => (
        <Slide mobile={mobile} key={id} media={<img alt={name} src={assetLink} />} subtitle={name} />
      ))}
    </Carousel>
  );
};

export default CocoonLightbox;
