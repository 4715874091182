import { Box, Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import { number, object, bool, func, arrayOf, string, oneOfType } from 'prop-types';
import { useTranslation } from 'react-i18next';

import CreateProjectTutorial from '../../components/section/CreateProjectTutorial';
import TabSideMenu from '../../components/side-menu/TabSideMenu';
import TabPanel from '../../components/tab-panel/TabPanel';

import ForumDetails from './components/details';
import TopicList from './components/topics';

const useStyles = makeStyles((theme) => ({
  fullHeight: {
    height: '100%',
  },
  leftPanel: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 1.5, 2),
      padding: theme.spacing(2, 1),
      border: '1px solid #575757',
      borderRadius: 6,
    },
  },
  rightPanel: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 1.5, 2),
    },
  },
}));

const ForumTabPanel = ({
  topics,
  tab,
  id,
  topicActive,
  ownerId,
  handleChangeTopic,
  handleAddTopic,
  handleLoadMoreTopics,
  isNewProject,
  showMoreTopics,
  desktop,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const renderComments = () =>
    _.isEmpty(topics) ? (
      <Box fontWeight="fontWeightBold">{t('defaultInformation.noTopics')}</Box>
    ) : (
      <ForumDetails projectId={id} topicActive={topicActive} desktop={desktop} />
    );

  return (
    <TabPanel tab={tab} index={4}>
      <Grid item xs={desktop ? 5 : 12} className={clsx(classes.fullHeight, classes.leftPanel)}>
        <TabSideMenu
          searchName="topic"
          options={[]}
          desktop={desktop}
          components={
            <TopicList
              topics={topics}
              topicActive={topicActive}
              isNewProject={isNewProject}
              showMoreTopics={showMoreTopics}
              handleChangeTopic={handleChangeTopic}
              handleLoadMoreTopics={handleLoadMoreTopics}
              handleAddTopic={handleAddTopic}
              ownerId={ownerId}
            />
          }
        />
      </Grid>
      <Grid item xs={desktop ? 7 : 12} className={clsx(classes.fullHeight, classes.rightPanel)}>
        {isNewProject ? <CreateProjectTutorial desktop={desktop} /> : renderComments()}
      </Grid>
    </TabPanel>
  );
};

ForumTabPanel.propTypes = {
  topics: arrayOf(object).isRequired,
  tab: number.isRequired,
  id: oneOfType([number, string]),
  topicActive: oneOfType([number, string]).isRequired,
  ownerId: number,
  isNewProject: bool.isRequired,
  desktop: bool.isRequired,
  showMoreTopics: bool.isRequired,
  handleChangeTopic: func.isRequired,
  handleLoadMoreTopics: func.isRequired,
  handleAddTopic: func.isRequired,
};

ForumTabPanel.defaultProps = {
  // id: undefined,
  ownerId: undefined,
};

export default ForumTabPanel;
