import { useQuery } from '@apollo/client';
import { GET_ANNOUNCEMENT_ACTIVE } from 'apollo/queries/announcement-query';
import { Announcement } from '@projectcocoon/web-component';

const AnnouncementComponent = () => {
  const { data } = useQuery(GET_ANNOUNCEMENT_ACTIVE);

  return (
    <>
      {data && data.activeAnnouncement && data.activeAnnouncement.text ? (
        <Announcement severity={'success'} variant={'filled'} icon={true}>
          {data.activeAnnouncement.text}
        </Announcement>
      ) : null}
    </>
  );
};

export default AnnouncementComponent;
