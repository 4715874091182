import { Avatar } from '@material-ui/core';
import { string } from 'prop-types';

// TODO: make a smaller chip version
const CocoonAvatar = ({ alt, src, className }) => {
  return <Avatar className={className} alt={alt} src={src} />;
};

CocoonAvatar.propTypes = {
  alt: string.isRequired,
  src: string,
  className: string,
};

CocoonAvatar.defaultProps = {
  src: undefined,
};

export default CocoonAvatar;
