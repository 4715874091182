import { Box, Typography, Grid, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import { arrayOf, object, number, string, bool, func } from 'prop-types';
import PullToRefresh from 'react-simple-pull-to-refresh';

import ProjectCard from '../project-card';

const ProjectsLayout = ({ tab, index, emptyText, projects, error, desktop, canFetchMore, handleFetchMore }) => {
  const handleOnFetchMore = () => {
    return new Promise((res) => {
      res(handleFetchMore());
    });
  };

  const renderProjects = () =>
    projects === null ? (
      <Box height="380px" width="100%" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress color="inherit" />
      </Box>
    ) : (
      <>
        {_.isEmpty(projects) ? (
          <Typography variant="body1" color="textSecondary">
            {emptyText}
          </Typography>
        ) : (
          <>
            <PullToRefresh canFetchMore={canFetchMore} onFetchMore={handleOnFetchMore} fetchMoreThreshold={0}>
              {_.map(projects, (project) => (
                <ProjectCard desktop={desktop} key={+project.id} project={project} />
              ))}
            </PullToRefresh>
            {canFetchMore && (
              <Box height={100} width="100%" display="flex" alignItems="center" justifyContent="center">
                <CircularProgress color="inherit" />
              </Box>
            )}
          </>
        )}
      </>
    );

  return (
    <>
      {error ? (
        <Alert variant="outlined" severity="error">
          {error.message}
        </Alert>
      ) : (
        <Grid
          role="tabpanel"
          hidden={tab !== index}
          id={`projects-tabpanel-${index}`}
          aria-labelledby={`projects-tab-${index}`}
          item
          xs={12}
          container
        >
          {tab === index && renderProjects()}
        </Grid>
      )}
    </>
  );
};

ProjectsLayout.propTypes = {
  projects: arrayOf(object),
  index: number,
  tab: number,
  emptyText: string.isRequired,
  desktop: bool.isRequired,
  error: object,
  canFetchMore: bool,
  handleFetchMore: func,
};

ProjectsLayout.defaultProps = {
  projects: null,
  index: undefined,
  tab: undefined,
  loading: false,
  error: undefined,
};

export default ProjectsLayout;
