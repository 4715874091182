import { AppBar, Toolbar, Grid, Box, IconButton, useMediaQuery, makeStyles } from '@material-ui/core';
import { Menu as MenuIcon, Search as SearchIcon } from '@material-ui/icons';
import { func } from 'prop-types';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import logoH from '@images/logo/logo-h.svg';
import { COOKIES } from 'helpers/constants';
import { getCookie } from 'helpers/cookies';

import { CocoonLogo } from '@projectcocoon/web-component';
import UserButtons from './components/buttons/UserButtons';
import Search from './components/search/Search';

const useStyles = makeStyles((theme) => {
  const borderStyle = `1px solid ${theme.palette.divider}`;
  return {
    appBar: {
      backgroundColor: theme.palette.common.white,
      color: '#333',
      borderBottom: borderStyle,
      height: 80,
      [theme.breakpoints.down('sm')]: {
        height: 56,
      },
    },
    grow: {
      flexGrow: 1,
    },
    toolbar: {
      flexGrow: 1,
      flexWrap: 'wrap',
      height: '80px',
      padding: theme.spacing(0, 6),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 2),
        height: 56,
      },
    },
    searchBar: {
      height: '80px',
      [theme.breakpoints.down('sm')]: {
        height: 56,
      },
    },
    logoLink: {
      '& img': {
        height: 56,
        [theme.breakpoints.down('sm')]: {
          height: 42,
        },
      },
      '& a': {
        display: 'block',
      },
    },
    link: {
      '& > a': {
        ...theme.typography.h6,
        color: '#333',
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1, 2, 1, 0),
      },
    },
    linkRight: {
      '& > a': {
        ...theme.typography.h6,
        color: '#333',
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1, 0, 1, 2),
      },
    },
  };
});

const userInfo = JSON.parse(getCookie(COOKIES.names.userInfo) || null);

const AppHeader = ({ handleLogout }) => {
  const classes = useStyles();
  const { loginWithRedirect } = useAuth0();
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const location = useLocation();

  const [openSearchBar, setOpenSearchBar] = useState(false);
  const [openMenuBar, setOpenMenuBar] = useState(false);

  const renderDesktop = (
    <>
      <Grid item xs={2} className={classes.logoLink}>
        <Link to="/">
          <CocoonLogo src={logoH} alt="logo" />
        </Link>
      </Grid>
      <Grid item xs={2} container alignItems="center" spacing={6}>
        <Grid item className={classes.link}>
          <Link to="/about">About</Link>
        </Grid>
        <Grid item className={classes.link}>
          <Link to="/contact">Contact</Link>
        </Grid>
      </Grid>
      <Grid item xs container alignItems="center" justify="flex-end" spacing={userInfo ? 2 : 6}>
        <Search desktop={desktop} />
        {userInfo ? (
          <UserButtons
            handleLogout={() => handleLogout(userInfo.id)}
            desktop={desktop}
            setOpenMenuBar={setOpenMenuBar}
          />
        ) : (
          <>
            <Grid item className={classes.link}>
              <Link onClick={() => loginWithRedirect()}>Login/Signup</Link>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );

  const renderMobile = (
    <>
      <Grid item xs className={classes.logoLink}>
        <Link to="/">
          <img src={logoH} alt="logo" />
        </Link>
      </Grid>
      <Box component={Grid} item xs textAlign="right">
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={() => {
            setOpenSearchBar((open) => !open);
            setOpenMenuBar(false);
          }}
        >
          <SearchIcon />
        </IconButton>
        <IconButton
          edge="end"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={() => {
            setOpenMenuBar(!openMenuBar);
            setOpenSearchBar(false);
          }}
        >
          <MenuIcon />
        </IconButton>
      </Box>
    </>
  );

  useEffect(() => {
    if (!desktop) {
      setOpenSearchBar(false);
      setOpenMenuBar(false);
    }
  }, [location.pathname]);

  return (
    <>
      <AppBar position="sticky" elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Grid container justify="space-between" alignItems="center">
            {desktop ? renderDesktop : renderMobile}
          </Grid>
        </Toolbar>
        {openSearchBar && (
          <Box width="100%" flexGrow={1} alignSelf="flex-start" borderBottom={1} style={{ backgroundColor: '#fff' }}>
            <Search />
          </Box>
        )}
        {openMenuBar && (
          <Box
            width="100%"
            flexGrow={1}
            borderBottom={1}
            alignSelf="flex-start"
            style={{ backgroundColor: '#fff' }}
            px={2}
          >
            {userInfo ? (
              <Grid container alignItems="center">
                <Grid item xs={12} container alignItems="center" justify="flex-start">
                  <Grid item className={classes.link}>
                    <Link to="/about">About</Link>
                  </Grid>
                  <Grid item className={classes.link}>
                    <Link to="/contact">Contact</Link>
                  </Grid>
                </Grid>
                <Grid item xs={12} container alignItems="center" justify="space-between">
                  <UserButtons
                    handleLogout={() => handleLogout(userInfo.id)}
                    desktop={desktop}
                    setOpenMenuBar={setOpenMenuBar}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container alignItems="center">
                <Grid item xs={6} container alignItems="center" justify="flex-start">
                  <Grid item className={classes.link}>
                    <Link to="/about">About</Link>
                  </Grid>
                  <Grid item className={classes.link}>
                    <Link to="/contact">Contact</Link>
                  </Grid>
                </Grid>
                <Grid item xs={6} container alignItems="center" justify="flex-end">
                  <Grid item className={classes.linkRight}>
                    <Link to="/signup">Signup</Link>
                  </Grid>
                  <Grid item className={classes.linkRight}>
                    <Link to="/signin">Login</Link>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Box>
        )}
      </AppBar>
    </>
  );
};

AppHeader.propTypes = {
  handleLogout: func.isRequired,
};

export default AppHeader;
