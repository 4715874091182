import { makeStyles, useMediaQuery } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import { node } from 'prop-types';

const forceImportant = (style) => `${style}!important`;

const useStyles = makeStyles(({ palette }) => ({
  variantSuccess: {
    backgroundColor: forceImportant(palette.success.main),
  },
  variantError: {
    backgroundColor: forceImportant(palette.error.main),
  },
  variantInfo: {
    backgroundColor: forceImportant(palette.info.main),
  },
  variantWarning: {
    backgroundColor: forceImportant(palette.warning.main),
  },
}));

const CocoonSnackbar = ({ children }) => {
  const classes = useStyles();
  const mobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <SnackbarProvider dense={mobile} classes={classes} autoHideDuration={3000} maxSnack={3}>
      {children}
    </SnackbarProvider>
  );
};

CocoonSnackbar.propTypes = {
  children: node.isRequired,
};

export default CocoonSnackbar;
