import {
  Avatar,
  MenuItem,
  Popper,
  Paper,
  MenuList,
  ClickAwayListener,
  ListItemIcon,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { AccountCircle as AccountCircleIcon, ExitToApp as ExitToAppIcon } from '@material-ui/icons';
import { func } from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { convertToSlug } from 'helpers/common';
import { getValue, KEYS } from 'helpers/localStorage';
import { getCurrentUser } from 'reducers/authReducer';

const useStyles = makeStyles((theme) => ({
  list: {
    '& a, & li': {
      display: 'flex',
      alignItems: 'center',
      color: '#333',
      '& > div': {
        minWidth: '32px',
      },
      '&:focus, &:hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        fontWeight: theme.typography.fontWeightBold,
        textDecoration: 'none',
        '& > div': {
          color: theme.palette.common.white,
        },
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 13,
        minHeight: theme.spacing(3),
      },
    },
  },
  avatar: {
    padding: theme.spacing(1, 2),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(-2),
    },
  },
}));

const UserButtonAvatar = ({ setOpenMenuBar, handleLogout }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const currentUser = useSelector(getCurrentUser);
  const isPartner = currentUser.roleId === 3;

  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
    setOpenMenuBar(false);
  };

  const handleListKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <IconButton
        ref={anchorRef}
        aria-controls={open ? 'avatar-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
        className={classes.avatar}
      >
        <Avatar alt="avatar" src={currentUser.avatarLink || getValue(KEYS.avatarLink)} />
      </IconButton>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal placement="bottom-end">
        {() => (
          <Paper elevation={3} square>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="avatar-list-grow" onKeyDown={handleListKeyDown} className={classes.list}>
                <MenuItem
                  component={Link}
                  onClick={handleClose}
                  to={`/users/${currentUser.id}/${convertToSlug(
                    `${currentUser.firstName} ${currentUser.lastName}`,
                  )}/profile`}
                >
                  <ListItemIcon>
                    <AccountCircleIcon fontSize="small" />
                  </ListItemIcon>
                  Profile
                </MenuItem>
                {isPartner && (
                  <MenuItem
                    component={Link}
                    onClick={handleClose}
                    to={`/partners/${currentUser.id}/${convertToSlug(currentUser.partnerName || 'partner')}/profile`}
                  >
                    <ListItemIcon>
                      <AccountCircleIcon fontSize="small" />
                    </ListItemIcon>
                    Partner Profile
                  </MenuItem>
                )}
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <ExitToAppIcon fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        )}
      </Popper>
    </>
  );
};

UserButtonAvatar.propTypes = {
  handleLogout: func.isRequired,
  setOpenMenuBar: func.isRequired,
};

export default UserButtonAvatar;
