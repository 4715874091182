import { Typography, Grid, Button, InputBase, makeStyles, IconButton } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import { bool, func, object, number, string } from 'prop-types';

import { CRUD_STATUS } from '../../../../helpers/constants';

import { useTranslation } from 'react-i18next';
const useStyles = makeStyles((theme) => ({
  fullSize: {
    width: '100%',
    height: '100%',
    paddingTop: theme.spacing(1),
  },
  title: {
    ...theme.typography.h5,
    color: '#575757',
  },
  buttonStyle: {
    display: 'flex',
    paddingRight: 0,
    justifyContent: 'flex-end',
    width: '50%',
    '&:hover': {
      backgroundColor: '#EBEBEB',
    },
  },
  cancelButton: {
    mixBlendMode: 'normal',
    border: '1.5px solid #575757',
    boxSizing: 'border-box',
    borderRadius: '6px',
    marginRight: theme.spacing(1),
  },
  saveButton: {
    backgroundColor: '#4791CE',
    mixBlendMode: 'normal',
    borderRadius: '6px',
    color: '#ffffff',
  },
}));

const Title = ({
  logName,
  crudStatus,
  handleChangeCrudStatus,
  handleCancelEditMode,
  SaveEditLog,
  handleChangeLogName,
  newLog,
  handleChangeNewLog,
  isLogNew,
  handleCancelSaveNewLog,
  desktop,
}) => {
  const { name } = newLog;
  const classes = useStyles();

  const { t } = useTranslation();
  const editDetail = () => {
    handleChangeCrudStatus(CRUD_STATUS.EDIT);
  };
  
  const viewDetail = () => {
    handleChangeNewLog(false);
    handleCancelEditMode();
  };

  const getSpan = (xs) => (crudStatus === CRUD_STATUS.VIEW ? 10 : xs);
  // const getSpanButton = xs => (crudStatus === CRUD_STATUS.VIEW ? 2 : xs);
  // const getSpanButtonEdit = xs => (crudStatus === CRUD_STATUS.VIEW ? 4 : xs);

  const getTitle = (titleCrudStatus) => {
    switch (titleCrudStatus) {
      case CRUD_STATUS.NEW:
        return (
          <Grid
            item
            xs={desktop ? getSpan(9) : getSpan(5)}
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <InputBase
              className={classes.title}
              inputProps={{ 'aria-label': 'LogName' }}
              placeholder={name}
              fullWidth
              name="newLog"
              onChange={handleChangeLogName}
            />
          </Grid>
        );
      case CRUD_STATUS.EDIT:
        return (
          <Grid
            item
            xs={desktop ? getSpan(9) : getSpan(5)}
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <InputBase
              className={classes.title}
              inputProps={{ 'aria-label': 'LogName' }}
              placeholder="Untitled"
              fullWidth
              name="logName"
              value={logName}
              onChange={handleChangeLogName}
            />
          </Grid>
        );
      default:
        return (
          <Grid
            item
            xs={desktop ? getSpan(9) : getSpan(5)}
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Typography className={classes.title}>{logName}</Typography>
          </Grid>
        );
    }
  };

  const getButtons = (buttonsCrudStatus, buttonsIsLogNew, buttonsIsPublishMilestone) => {
    if (buttonsCrudStatus === CRUD_STATUS.EDIT && !buttonsIsLogNew) {
      return (
        <Grid container direction="row" item xs={desktop ? 3 : 7} justify="flex-end" alignItems="center">
          <Button onClick={viewDetail} className={classes.cancelButton}>
            {t('button.cancel')}
          </Button>
          <Button className={classes.saveButton} onClick={SaveEditLog}>
            {t('button.saveEdit')}
          </Button>
        </Grid>
      );
    }
    if (buttonsIsLogNew) {
      return (
        <Grid container direction="row" item xs={desktop ? 3 : 7} justify="flex-end" alignItems="center">
          <Button onClick={handleCancelSaveNewLog} className={classes.cancelButton}>
            {t('button.cancel')}
          </Button>
          <Button className={classes.saveButton} onClick={SaveEditLog}>
            {t('button.save')}
          </Button>
        </Grid>
      );
    }
    return (
      <Grid item xs={2} container direction="row" justify="flex-end" alignItems="flex-end">
        <IconButton size="small" onClick={editDetail} className={classes.buttonStyle}>
          <EditIcon />
        </IconButton>
      </Grid>
    );
  };

  return (
    <div className={classes.fullSize}>
      <Grid item xs={12} container direction="row">
        {getTitle(crudStatus)}
        {getButtons(crudStatus, isLogNew)}
      </Grid>
    </div>
  );
};

Title.propTypes = {
  logName: string,
  handleChangeCrudStatus: func.isRequired,
  crudStatus: number.isRequired,
  handleCancelEditMode: func.isRequired,
  SaveEditLog: func.isRequired,
  handleChangeLogName: func.isRequired,
  newLog: object.isRequired,
  handleChangeNewLog: func.isRequired,
  isLogNew: bool.isRequired,
  handleCancelSaveNewLog: func,
  desktop: bool,
};

Title.defaultProps = {
  logName: undefined,
  isPublishMilestone: undefined,
};

export default Title;
