import { Typography } from '@material-ui/core';
import { bool, object, func } from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Tags } from 'components';

import Contests from './contests';
import Experiences from './experiences';
import useStyles from './WorksExperiencesContestsStyle';

const WorksExperiencesContests = ({ editProfile, handleSetFieldsOfWork, desktop }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    var tags = [];
    if (editProfile.fieldsOfWork) {
      tags = editProfile.fieldsOfWork.map((str, index) => {
        if (typeof str === 'object') {
          return {
            id: index,
            fieldName: str?.fieldName,
          };
        }
        return {
          id: index,
          fieldName: str,
        };
      });
    }

    handleSetFieldsOfWork(tags);
  }, []);

  return (
    <>
      <div className={classes.editInfoWrapper}>
        <Typography className={classes.editInfoTitle}>{t('common.tags')}</Typography>
        <hr className={classes.divider} />
        <Tags
          desktop={desktop}
          isEditMode
          isChips
          isLightView
          label="fieldName"
          tags={editProfile.fieldsOfWork}
          setTags={handleSetFieldsOfWork}
        />
      </div>
      <div className={classes.editInfoWrapper}>
        <Typography className={classes.editInfoTitle}>{t('common.experiences')}</Typography>
        <hr className={classes.divider} />
        <Experiences desktop={desktop} id={editProfile.id} experiences={editProfile.experiences} />
      </div>
      <div className={classes.editInfoWrapper}>
        <Typography className={classes.editInfoTitle}>{t('common.contests')}</Typography>
        <hr className={classes.divider} />
        <Contests desktop={desktop} id={editProfile.id} contests={editProfile.contests} />
      </div>
    </>
  );
};

WorksExperiencesContests.propTypes = {
  editProfile: object.isRequired,
  handleSetFieldsOfWork: func.isRequired,
  desktop: bool.isRequired,
};

export default WorksExperiencesContests;
