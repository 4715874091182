import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { makeStyles, Typography, Grid, Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { CocoonCarousel } from '@projectcocoon/web-component';

import { convertToSlug } from 'helpers/common';
import { GET_FEATURED_CONTESTS } from 'apollo/queries/contest-query';

// TODO: export/update slider style + make Carousel throw Error if there's only 1 child
const useStyles = makeStyles(() => ({
  autoHeight: {
    height: 'auto',
  },
  sliderStyle: {
    height: 150,
    backgroundSize: 'cover !important',
    color: '#fff',
    cursor: 'pointer',
    marginLeft: 15,
    marginRight: 15,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: 6,
    '&:after': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      background: 'rgba(0,0,0,0.5)',
      opacity: 1,
      zIndex: 1,
      borderRadius: 6,
    },
    '& h1,h2,h3,h4,h5,h6,p,span': {
      zIndex: 2,
      color: '#fff',
    },
  },
  contestName: {
    color: '#fff',
    fontSize: '48px',
  },
}));

function ContestSlide({ id, name, assetLink, creator, redirectToContest }) {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper
          onClick={() => redirectToContest(id, name)}
          className={classes.sliderStyle}
          style={{ background: `url(${assetLink})` }}
        >
          <Typography className={classes.contestName} variant="h4">
            {name}
          </Typography>
          <Typography variant="body1">Hosted by {creator.partnerName || creator.email}</Typography>
        </Paper>
      </Grid>
    </Grid>
  );
}

ContestSlide.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  assetLink: PropTypes.string.isRequired,
  creator: PropTypes.shape({
    partnerName: PropTypes.string,
    email: PropTypes.string.isRequired,
  }).isRequired,
  redirectToContest: PropTypes.func.isRequired,
};

export default function ContestsCarousel() {
  const classes = useStyles();
  const history = useHistory();

  function redirectToContest(id, name) {
    const link = `/contests/${id}/${convertToSlug(name)}`;
    history.push(link);
  }

  const { data: contestsData, loading: contestsLoading } = useQuery(GET_FEATURED_CONTESTS, {
    variables: { page: 1, limit: 'FIVE_O' },
  });

  if (contestsLoading || !contestsData) return null;

  if (contestsData && contestsData.contestsFeatured.length === 1) {
    const { id, name, assetLink, creator } = contestsData.contestsFeatured[0];
    return (
      <ContestSlide id={id} name={name} assetLink={assetLink} creator={creator} redirectToContest={redirectToContest} />
    );
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <CocoonCarousel
          carouselClassname={classes.autoHeight}
          showCustomArrows
          autoPlay
          infiniteLoop
          centerMode
          interval={1500}
          showIndicators={false}
        >
          {contestsData.contestsFeatured.map(({ id, name, assetLink, creator }) => (
            <ContestSlide
              key={id}
              id={id}
              name={name}
              assetLink={assetLink}
              creator={creator}
              redirectToContest={redirectToContest}
            />
          ))}
        </CocoonCarousel>
      </Grid>
    </Grid>
  );
}
