import { useMutation } from '@apollo/client';
import { Box, Typography, ClickAwayListener, Grid, IconButton, makeStyles } from '@material-ui/core';
import { Close as CloseIcon, Add as AddIcon } from '@material-ui/icons';
import { arrayOf, object, func, number, bool } from 'prop-types';
import { useState } from 'react';

import { REMOVE_CONTEST_MEMBERS } from 'apollo/mutations/contest-mutation';
import { CocoonAvatar } from 'components';
import { cookieUserId } from 'helpers/auth';
import { useSnackbar } from 'hooks';

import SelectMembers from './SelectMembers';

const useStyles = makeStyles(() => ({
  memberWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    columnGap: 10,
  },
  avatar: {
    height: 32,
    width: 32,
    borderRadius: 16,
  },
}));

function Icon({ isAddingMembers }) {
  if (isAddingMembers) return <CloseIcon />;
  return <AddIcon />;
}

Icon.propTypes = {
  isAddingMembers: bool.isRequired,
};

// TODO: can generalize to work with Sponsors
function Members({ contestId, members, refetch }) {
  const classes = useStyles();

  const [removeContestMember] = useMutation(REMOVE_CONTEST_MEMBERS);
  const [isAddingMembers, setIsAddingMembers] = useState(false);

  const { openSnackbarSuccess, openSnackbarError } = useSnackbar();

  async function handleRemoveMember(values) {
    try {
      const { data } = await removeContestMember({
        variables: {
          contest: {
            contestId,
            membersIds: [values],
          },
        },
      });
      if (data) {
        openSnackbarSuccess('Members updated!');
        refetch();
      }
    } catch (err) {
      openSnackbarError(err.message);
    }
  }

  function handleToggleAddMembers() {
    if (isAddingMembers) return setIsAddingMembers(false);
    return setIsAddingMembers(true);
  }

  function handleRefetch() {
    refetch();
    setIsAddingMembers(false);
  }

  const hasMembers = members.length !== 0;
  const text = !hasMembers ? 'No members' : `${members.length} members`;

  return (
    <ClickAwayListener onClickAway={() => setIsAddingMembers(false)}>
      <Grid container direction="column" alignItems="flex-end">
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography style={{ marginRight: 12 }} variant="body1">
            {text}
          </Typography>
          <IconButton onClick={handleToggleAddMembers} variant="text" color="primary">
            <Icon isAddingMembers={isAddingMembers} />
          </IconButton>
        </Box>
        {isAddingMembers && <SelectMembers members={members} contestId={contestId} refetch={handleRefetch} />}
        {hasMembers && (
          <Grid container>
            {members.map(({ id, username, avatarLink }) => (
              <Grid className={classes.memberWrapper} key={id} item xs={12}>
                <CocoonAvatar className={classes.avatar} src={avatarLink} alt="Member avatar" />
                <Typography style={{ marginRight: 12 }} variant="body1">
                  {username}
                </Typography>
                {cookieUserId === +id ? (
                  <Typography style={{ padding: 12 }} variant="h6">
                    {`It's you`}
                  </Typography>
                ) : (
                  <IconButton onClick={() => handleRemoveMember(+id)} variant="contained">
                    <CloseIcon />
                  </IconButton>
                )}
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </ClickAwayListener>
  );
}

Members.propTypes = {
  contestId: number.isRequired,
  members: arrayOf(object),
  refetch: func.isRequired,
};

export default Members;
