import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  textField: {
    background: '#ffffff',
    '& .MuiOutlinedInput-root': {
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.1)',
      borderRadius: '6px',
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
  },
  dialog: {
    '& .MuiDialog-paper': {
      width: '678px',
    },
  },
  header: {
    color: '#414141',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  button: {
    borderColor: theme.palette.text.secondary,
    padding: theme.spacing(0.25, 1.5),
    marginLeft: theme.spacing(1),
    textTransform: 'none',
    borderRadius: '6px',
    borderWidth: '1px',
  },
  formControl: {
    '& legend': {
      ...theme.typography.caption2,
      marginBottom: theme.spacing(1),

      '& > span.MuiFormLabel-asterisk': {
        color: '#C53C2A',
      },
    },
    '& label > span:last-child': {
      ...theme.typography.button,
      fontSize: '14px',
      fontWeight: 'normal',
    },
    '& legend, & legend.Mui-focused, & label > span:last-child': {
      color: '#414141',
    },
    '& label > span.Mui-checked': {
      color: '#C34674',
    },
  },
  radioGroup: {
    flexDirection: 'row',
  },
  text: {
    fontWeight: theme.typography.fontWeightBold,
    color: '#575757',
  },
}));

export default useStyles;
