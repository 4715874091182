import { gql } from '@apollo/client';

// done
// updated
export const GET_TOPICS = gql`
  query topicsByProject($projectId: ID!, $page: Int, $limit: PaginationLimit) {
    topicsByProject(projectId: $projectId, page: $page, limit: $limit) {
      id
      name
      isArchived
      createdAt
      updatedAt
      totalComments
    }
  }
`;

// done
// updated
export const GET_LATEST_COMMENTS_OF_FORUM = gql`
  query landingPageLatestForumComments {
    landingPageLatestForumComments {
      id
      content
      totalLikes
      totalReplies
      project {
        id
        name
        isLikedByUser
      }
      topic {
        id
      }
      isLikedByCurrentUser
      user {
        id
        firstName
        lastName
        avatarLink
        username
      }
    }
  }
`;

// done
// updated
export const GET_TOPIC_COMMENTS = gql`
  query projectTopicCommentsReplies($projectId: ID!, $projectTopicId: ID!, $page: Int!, $limit: PaginationLimit!) {
    projectTopicCommentsReplies(projectId: $projectId, projectTopicId: $projectTopicId, page: $page, limit: $limit) {
      id
      isArchived
      content
      createdAt
      updatedAt
      totalLikes
      isLikedByCurrentUser
      user {
        id
        firstName
        lastName
        email
        avatarLink
      }
      replies {
        id
        isArchived
        content
        createdAt
        updatedAt
        totalLikes
        isLikedByCurrentUser
        user {
          id
          firstName
          lastName
          email
          avatarLink
        }
      }
    }
  }
`;

// done
// updated
export const MORE_COMMENT_REPLIES = gql`
  query moreCommentReplies(
    $projectId: ID!
    $projectTopicId: ID!
    $projectCommentId: ID!
    $page: Int!
    $limit: PaginationLimit!
  ) {
    moreCommentReplies(
      projectId: $projectId
      projectTopicId: $projectTopicId
      projectCommentId: $projectCommentId
      page: $page
      limit: $limit
    ) {
      id
      content
      isArchived
      createdAt
      totalLikes
      isLikedByCurrentUser
      user {
        id
        firstName
        lastName
        email
        avatarLink
      }
    }
  }
`;
