import { Grid, Card, makeStyles } from '@material-ui/core';
import { string, bool, object } from 'prop-types';

import { PROJECT_TYPE } from 'helpers/constants';
import { convertToSlug } from 'helpers/common';
import { getDiffNow } from 'helpers/date';

import { ProjectPreview } from '@projectcocoon/web-component';

const { DISC_TYPE } = PROJECT_TYPE;

const useStyles = makeStyles({
  card: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    minHeight: 120,
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
});

const ProjectCard = ({ project, isBig, ...props }) => {
  const { type, desktop } = props;
  const isDiscType = type === DISC_TYPE;
  const classes = useStyles();
  const {
    id,
    owner,
    ownerId,
    isBookmarkedByUser,
    name,
    description,
    assetLink,
    updatedAt,
    tags,
    totalLikes,
    isFeatured,
    ...stats
  } = project;

  return (
    <Grid item xs={desktop ? (isDiscType ? 6 : 12) : 12} container>
      {project && (
        <ProjectPreview
          id={id}
          name={name}
          projectLink={`/projects/${id}/${convertToSlug(name)}`}
          description={description}
          assetLink={assetLink}
          updatedAt={getDiffNow(updatedAt)}
          isFeatured={isFeatured}
          owner={{
            ...owner,
            profileUrl: `/users/${owner.id}/${convertToSlug(`${owner.firstName} ${owner.lastName}`)}/profile`,
          }}
          isBig={isBig}
          isDesktop={desktop}
        />
      )}
    </Grid>
  );
};

ProjectCard.propTypes = {
  project: object,
  type: string.isRequired,
  desktop: bool.isRequired,
};

ProjectCard.defaultProps = {
  project: undefined,
};

export default ProjectCard;
