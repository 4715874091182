import { useMutation } from '@apollo/client';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { BookmarkBorder as BookmarkBorderIcon } from '@material-ui/icons';
import { bool, object, func } from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DELETE_TUTORIAL_STEP } from 'apollo/mutations/tutorial-mutation';
import { useYupValidationResolver } from 'hooks';

import { addStepSchemaValidationFunction } from './helper/addStepYup';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '678px',
    },
  },
  header: {
    color: '#414141',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  button: {
    borderColor: theme.palette.text.secondary,
    padding: theme.spacing(0.25, 1.5),
    marginLeft: theme.spacing(1.5),
    textTransform: 'none',
    borderRadius: '6px',
    borderWidth: '1px',
    [theme.breakpoints.down('sm')]: {
      padding: '7px 8px 4px',
      fontSize: 12,
    },
  },
  text: {
    color: '#575757',
  },
  btnDelete: {
    backgroundColor: 'red',
    color: 'white',
  },
  editInfoTitle: {
    marginTop: '2%',
    ...theme.typography.h1,
    color: '#575757',
    fontSize: '16px',
    fontWeight: '700',
  },
  divider: {
    margin: '0.5em 0 1em 0',
    backgroundColor: '#989898',
    border: 'solid 0.5px #e6e6e6',
  },
  editInfoTextField: {
    background: '#ffffff',
    '& .MuiOutlinedInput-root': {
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.1)',
      borderRadius: '6px',
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
      '& input': {
        padding: theme.spacing(1, 1.5),
      },
      '& p': {
        margin: 0,
      },
    },
  },
}));

const AddStepTutorialDialog = ({
  open,
  options,
  desktop,
  openSnackbarSuccess,
  openSnackbarError,
  enqueueSnackbarMultipleErrors,
  handleCloseDialog,
}) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { isEditMode, projectId, id, name, stepNumber, handlePositiveButton, handleDeleteStep, mutation } = options;
  const [mutationPromise] = useMutation(mutation);
  const [deleteStep] = useMutation(DELETE_TUTORIAL_STEP);
  const validationSchema = useMemo(() => addStepSchemaValidationFunction(t), []);

  const { register, handleSubmit, errors } = useForm({
    resolver: useYupValidationResolver(validationSchema),
    defaultValues: {
      stepName: name,
      stepNumber: stepNumber || 0,
    },
  });

  useEffect(() => {
    enqueueSnackbarMultipleErrors(errors);
  }, [errors]);

  const handleCreate = async ({ stepName, stepNumber }, e) => {
    e.preventDefault();

    const stepVariable = {
      name: stepName,
      stepNumber: +stepNumber,
    };

    try {
      const { data } = await mutationPromise({
        variables: {
          step: isEditMode
            ? {
                id,
                ...stepVariable,
              }
            : {
                projectId,
                ...stepVariable,
              },
        },
      });

      if (data) {
        handleCloseDialog();
        openSnackbarSuccess(`Step ${isEditMode ? 'updated' : 'created'} successfully!`);
        if (isEditMode) {
          handlePositiveButton({
            id,
            name: stepName,
            stepNumber: parseInt(stepNumber),
          });
        } else {
          handlePositiveButton(data);
        }
      }
    } catch (error) {
      openSnackbarError(error.message);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      const { data } = await deleteStep({
        variables: {
          step: {
            id,
          },
        },
      });

      if (data) {
        handleCloseDialog();
        openSnackbarSuccess(`This step is deleted successfully!`);
        handleDeleteStep(id);
      }
    } catch (error) {
      openSnackbarError(error.message);
    }
  };

  const renderDialogActions = (
    <DialogActions>
      {isEditMode && (
        <Button variant="outlined" className={`${classes.button} ${classes.btnDelete}`} onClick={handleDelete}>
          Delete
        </Button>
      )}
      <Button variant="outlined" className={classes.button} onClick={handleCloseDialog}>
        Cancel
      </Button>
      <Button autoFocus variant="contained" color="primary" type="submit" className={classes.button}>
        {isEditMode ? 'Save' : 'Add'}
      </Button>
    </DialogActions>
  );

  return open ? (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="add-step-tutorial-dialog-title"
      aria-describedby="add-step-tutorial-dialog-description"
      className={classes.dialog}
      maxWidth="xs"
    >
      <form onSubmit={handleSubmit(handleCreate)}>
        <DialogTitle disableTypography id="add-step-dialog-title">
          <Grid container alignItems="center" alignContent="space-between">
            <Grid item xs>
              <Typography color="textSecondary" variant={desktop ? 'h5' : 'h6'}>
                <Box display="flex" alignItems="center" className={classes.header}>
                  <BookmarkBorderIcon color="primary" className={classes.icon} />
                  {isEditMode ? 'Update step' : 'Add new step'}
                </Box>
              </Typography>
            </Grid>
            {desktop && (
              <Grid item xs>
                {renderDialogActions}
              </Grid>
            )}
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={desktop ? 3 : 2}>
            <Grid item xs={desktop ? 6 : 12}>
              <Typography className={classes.editInfoTitle}>Step number</Typography>
              <hr className={classes.divider} />
              <TextField
                className={classes.editInfoTextField}
                fullWidth
                variant="outlined"
                name="stepNumber"
                type="number"
                helperText="Step number should greater than zero"
                inputRef={register}
              />
            </Grid>
            <Grid item xs={desktop ? 6 : 12}>
              <Typography className={classes.editInfoTitle}>Name</Typography>
              <hr className={classes.divider} />
              <TextField
                className={classes.editInfoTextField}
                fullWidth
                variant="outlined"
                name="stepName"
                inputRef={register}
              />
            </Grid>
          </Grid>
        </DialogContent>
        {!desktop && renderDialogActions}
      </form>
    </Dialog>
  ) : null;
};

AddStepTutorialDialog.propTypes = {
  open: bool.isRequired,
  options: object.isRequired,
  desktop: bool.isRequired,
  openSnackbarError: func.isRequired,
  openSnackbarSuccess: func.isRequired,
  handleCloseDialog: func.isRequired,
};

export default AddStepTutorialDialog;
