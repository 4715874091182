import { Typography, Button, CircularProgress, makeStyles } from '@material-ui/core';
import { PhotoLibrary as PhotoLibraryIcon, Save as SaveIcon, Clear as ClearIcon } from '@material-ui/icons';
import _ from 'lodash';
import { string, bool, func, object } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { acceptedImageFiles } from 'helpers/constants';

const useStyles = makeStyles((theme) => ({
  button: {
    '&:not(.disabled)': {
      color: theme.palette.common.white,
      [theme.breakpoints.down('sm')]: {
        color: '#575757',
      },
    },
    '&.Mui-disabled': {
      color: '#989898',
    },
    borderColor: theme.palette.common.white,
    marginRight: theme.spacing(2),
    padding: theme.spacing(0.25, 1.5),
    textTransform: 'none',
    borderRadius: '6px',
    borderWidth: '1px',
    '&:hover': {
      background: 'transparent',
      borderWidth: '2px',
      '& *': {
        fontWeight: 'bold',
      },
    },
    '& span': {
      [theme.breakpoints.down('sm')]: {
        fontSize: 13,
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& svg': {
        fontSize: 18,
      },
      color: '#575757',
      borderColor: '#575757',
      padding: theme.spacing(0.5, 1),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      minWidth: 100,
    },
  },
  buttonProgress: {
    color: theme.palette.common.white,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  inputUpload: {
    display: 'none',
  },
}));

const ButtonsOwnerCreateEdit = ({
  isEditMode,
  name,
  loadingButton,
  assetLink,
  uploadedFile,
  handleCancelEditMode,
  handleSubmit,
  handleUploadFile,
  disabledSave,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {isEditMode && (
        <Button
          size="small"
          variant="outlined"
          className={classes.button}
          onClick={() => handleCancelEditMode()}
          startIcon={<ClearIcon />}
        >
          <Typography variant="button" color="inherit">
            {t('button.cancel')}
          </Typography>
        </Button>
      )}
      <label htmlFor="button-cover">
        <input
          accept={acceptedImageFiles}
          className={classes.inputUpload}
          id="button-cover"
          multiple
          type="file"
          onChange={handleUploadFile}
        />
        <Button
          component="span"
          size="small"
          variant="outlined"
          className={classes.button}
          startIcon={<PhotoLibraryIcon />}
        >
          <Typography variant="button" color="inherit">
            {uploadedFile || assetLink ? t('button.changeCover') : t('button.addCover')}
          </Typography>
        </Button>
      </label>
      <Button
        size="small"
        variant="outlined"
        className={classes.button}
        disabled={_.isEmpty(name) || disabledSave || loadingButton}
        onClick={handleSubmit}
        startIcon={<SaveIcon />}
      >
        <Typography variant="button" color="inherit">
          {isEditMode ? t('button.save') : t('button.create')}
        </Typography>
        {loadingButton && <CircularProgress size={24} className={classes.buttonProgress} />}
      </Button>
    </>
  );
};

ButtonsOwnerCreateEdit.propTypes = {
  isEditMode: bool.isRequired,
  name: string.isRequired,
  loadingButton: bool.isRequired,
  assetLink: string,
  uploadedFile: object,
  handleCancelEditMode: func.isRequired,
  handleUploadFile: func.isRequired,
  disabledSave: bool,
};

ButtonsOwnerCreateEdit.defaultProps = {
  assetLink: null,
  uploadedFile: undefined,
  disabledSave: undefined,
};

export default ButtonsOwnerCreateEdit;
