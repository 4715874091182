import { Link, Grid, Typography, Box, makeStyles } from '@material-ui/core';
import { string, bool } from 'prop-types';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { CocoonEditor } from 'components';
import { CK_EDITOR_IMAGE_UPLOAD_URL, CK_EDITOR_UPLOAD_TYPES } from 'helpers/constants';

const useStyles = makeStyles((theme) => ({
  grid: {
    marginBottom: theme.spacing(3),
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
  boxBorderBottom: {
    borderBottom: '1px solid rgba(51, 51, 51, 0.3)',
  },
  italic: {
    fontStyle: 'italic',
  },
  inputDescription: {
    // color: theme.palette.common.white,
    fontSize: '14px',
    '& > input': {
      '&::-webkit-input-placeholder': {
        fontStyle: 'italic',
      },
      '&:-moz-placeholder': {
        fontStyle: 'italic',
      },
      '&::-moz-placeholder': {
        fontStyle: 'italic',
      },
      '&:-ms-input-placeholder': {
        fontStyle: 'italic',
      },
    },
  },
}));

const TabPanelSection = ({ control, title, desktop }) => {
  const classes = useStyles();

  const history = useHistory();
  const projectId = +history.location.pathname.split('/')[2];

  const { t } = useTranslation();

  return (
    <Grid item xs={12} className={classes.grid}>
      {title && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          mb={1}
          className={classes.boxBorderBottom}
        >
          <Typography variant="h6" color="textSecondary" gutterBottom>
            {title}
          </Typography>
        </Box>
      )}
      <Box width={desktop ? 'auto' : 343} maxWidth="100%">
        <Controller
          control={control}
          name="projectOverview"
          render={({ onChange, value }) => (
            <CocoonEditor
              data={value}
              handleChangeData={onChange}
              uploadUrl={CK_EDITOR_IMAGE_UPLOAD_URL}
              type={CK_EDITOR_UPLOAD_TYPES.PROJECT_OVERVIEW}
              projectId={projectId}
            />
          )}
        />
      </Box>
      <Box mt={2} borderTop={1} pt={1} borderColor="grey.200" fontStyle="italic" color="grey.400">
        <Link target="_blank" href="https://www.markdownguide.org/cheat-sheet/">
          {t('project.markDown')}
        </Link>{' '}
        {t('project.isSupported')}
      </Box>
    </Grid>
  );
};

TabPanelSection.propTypes = {
  title: string,
  desktop: bool.isRequired,
};

TabPanelSection.defaultProps = {
  title: undefined,
};

export default TabPanelSection;
