import { Box, Tab, Tabs, Typography, makeStyles, withStyles } from '@material-ui/core';
import { bool, func, number, string } from 'prop-types';

const getBgColorIndicator = (theme) => [theme.palette.secondary.main, undefined, theme.palette.success.main];

import { useTranslation } from 'react-i18next';

const useStyles = (tab) =>
  makeStyles((theme) => ({
    tabsWrapper: {
      boxShadow: 'inset 0 -2px 0px #e8e8e8',
    },
    tabs: {
      '& .MuiTabs-indicator': {
        backgroundColor: getBgColorIndicator(theme)[tab],
      },
      [theme.breakpoints.down('sm')]: { minHeight: theme.spacing(3) },
    },
  }));

const ProjectsTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    minWidth: 72,
    marginRight: theme.spacing(4),
    '&#projects-tab-0': {
      color: theme.palette.secondary.main,
    },
    '&#projects-tab-2:not(.Mui-disabled)': {
      color: theme.palette.success.main,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      minHeight: theme.spacing(3),
    },
  },
}))((props) => <Tab {...props} />);

const a11yProps = (index) => ({
  id: `projects-tab-${index}`,
  'aria-controls': `projects-tabpanel-${index}`,
});

const ProjectsHeader = ({ tab, handleChangeTab, label, desktop }) => {
  const classes = useStyles(tab)();

  const { t } = useTranslation();

  return (
    <>
      <Box display="div" textAlign={desktop ? 'left' : 'center'} mb={3}>
        <Typography variant={desktop ? 'h2' : 'h4'} color="primary">
          {label}
        </Typography>
      </Box>
      {tab !== undefined && (
        <Box display="div" mb={desktop ? 3 : 2} className={classes.tabsWrapper}>
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            textColor="secondary"
            aria-label="projects tabs"
            className={classes.tabs}
          >
            <ProjectsTab label={t('common.published')} {...a11yProps(0)} />
            {/* TODO: uncomment later */}
            {/* <ProjectsTab
              label="Permission required"
              disabled
              {...a11yProps(1)}
            />
            <ProjectsTab label="Only me" disabled {...a11yProps(2)} /> */}
          </Tabs>
        </Box>
      )}
    </>
  );
};

ProjectsHeader.propTypes = {
  label: string.isRequired,
  desktop: bool.isRequired,
  tab: number,
  handleChangeTab: func,
};

ProjectsHeader.defaultProps = {
  tab: undefined,
  handleChangeTab: undefined,
};

export default ProjectsHeader;
