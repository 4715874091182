import { useMutation } from '@apollo/client';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { LockOpen as LockOpenIcon } from '@material-ui/icons';
import { bool, object, func } from 'prop-types';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '678px',
    },
  },
  header: {
    color: '#414141',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  button: {
    borderColor: theme.palette.text.secondary,
    padding: theme.spacing(0.25, 1.5),
    marginLeft: theme.spacing(1.5),
    textTransform: 'none',
    borderRadius: '6px',
    borderWidth: '1px',
    [theme.breakpoints.down('sm')]: {
      padding: '7px 8px 4px',
      fontSize: 12,
    },
  },
  text: {
    color: '#575757',
  },
}));

const DeleteSectionTutorialDialog = ({
  open,
  options,
  desktop,
  openSnackbarSuccess,
  openSnackbarError,
  handleCloseDialog,
}) => {
  const classes = useStyles();

  const { variables, mutation, handleSectionDisplay } = options;
  const [mutationPromise] = useMutation(mutation);

  const { t } = useTranslation(['common', 'dialog']);

  const handleDeleteItem = async (e) => {
    e.preventDefault();
    try {
      const { data } = await mutationPromise({
        variables,
      });

      if (data) {
        handleCloseDialog();
        openSnackbarSuccess(t('dialog:openSnackbarSuccess.deleteSection'));
        handleSectionDisplay();
      }
    } catch (error) {
      openSnackbarError(error.message);
    }
  };

  const renderDialogActions = (
    <DialogActions>
      <Button variant="outlined" className={classes.button} onClick={handleCloseDialog}>
        {t('common:button.back')}
      </Button>
      <Button autoFocus variant="contained" color="primary" className={classes.button} onClick={handleDeleteItem}>
        {t('common:button.delete')}
      </Button>
    </DialogActions>
  );

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="delete-section-dialog-title"
      aria-describedby="delete-section-dialog-description"
      className={classes.dialog}
      maxWidth="md"
    >
      <DialogTitle disableTypography id="delete-section-dialog-title">
        <Grid container alignItems="center" alignContent="space-between">
          <Grid item xs>
            <Typography color="textSecondary" variant={desktop ? 'h5' : 'h6'}>
              <Box display="flex" alignItems="center" className={classes.header}>
                <LockOpenIcon color="primary" className={classes.icon} />
                {t('dialog:deleteSectionTutorial.deleteSection')}
              </Box>
            </Typography>
          </Grid>
          {desktop && (
            <Grid item xs>
              {renderDialogActions}
            </Grid>
          )}
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Box component={Typography} my={desktop ? 2 : 1} variant="body1" className={classes.text}>
          {t('dialog:deleteSectionTutorial.reminder')}
        </Box>
      </DialogContent>
      {!desktop && renderDialogActions}
    </Dialog>
  );
};

DeleteSectionTutorialDialog.propTypes = {
  open: bool.isRequired,
  desktop: bool.isRequired,
  options: object.isRequired,
  openSnackbarError: func.isRequired,
  openSnackbarSuccess: func.isRequired,
  handleCloseDialog: func.isRequired,
};

export default DeleteSectionTutorialDialog;
