import { gql } from '@apollo/client';

// done
// updated
export const GET_USER_INVITATIONS = gql`
  query currentUserInvitations($page: Int, $limit: PaginationLimit) {
    currentUserInvitations(page: $page, limit: $limit) {
      id
      project {
        id
        name
        description
        assetLink
      }
      inviter {
        id
        firstName
        lastName
      }
      status
    }
  }
`;
