import { useQuery } from '@apollo/client';
import {
  Button,
  ClickAwayListener,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { PostAdd as PostAddIcon, Reorder as ReorderIcon } from '@material-ui/icons';
import { object } from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
// import { useHistory } from 'react-router-dom';

import { GET_USER } from 'apollo/queries/user-query';
import { cookieUserId } from 'helpers/auth';
import { DIALOG } from 'helpers/constants';
import { openDialog } from 'reducers/dialogReducer';
import { useTranslation } from 'react-i18next';
import { handleOpenDialogAC3 } from 'reducers/testAC3/cache';
const useStyles = makeStyles((theme) => ({
  list: {
    '& a': {
      display: 'flex',
      alignItems: 'center',
      color: '#333',
      '& > div': {
        minWidth: '32px',
      },
      '&:focus, &:hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        fontWeight: theme.typography.fontWeightBold,
        textDecoration: 'none',
        '& > div': {
          color: theme.palette.common.white,
        },
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 13,
        minHeight: theme.spacing(3),
      },
    },
  },
  button: {
    textTransform: 'none',
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(0.25, 1.5),
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      '& h6': {
        fontSize: 14,
      },
    },
  },
}));

const MemberButton = ({
  // setOpenMenuBar,
  project,
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  // const history = useHistory();
  const [profile, setProfile] = useState(null);
  const anchorRef = useRef(null);

  const { t } = useTranslation('panel');
  const { data: userProfile } = useQuery(GET_USER, { variables: { id: cookieUserId } });

  useEffect(() => {
    if (userProfile) {
      setProfile(userProfile);
    }
  }, [userProfile]);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const openInviteMemberDialog = (e) => {
    if (e) {
      e.preventDefault();
    }
    const options = { project };
    handleOpenDialogAC3(DIALOG.INVITE_PROJECT_MEMBER, options);
  };
  const openMemberMobileDialog = (e) => {
    if (e) {
      e.preventDefault();
    }
    project.profile = profile;
    const options = { project };
    handleOpenDialogAC3(DIALOG.MOBILE_MEMBER, options);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  // const onClickNewProject = (e) => {
  //   e.preventDefault();
  //   history.push('/projects/new');
  //   handleClose(e);
  //   setOpenMenuBar(false);
  // };

  // TODO: uncomment later
  // const onClickNewGroup = e => {
  //   e.preventDefault();
  //   handleClose(e);
  // };

  return (
    <>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'new-list-grow' : undefined}
        aria-haspopup="true"
        color="primary"
        size="small"
        variant="outlined"
        onClick={handleToggle}
        className={classes.button}
      >
        <Typography component="h6" variant="button" color="inherit">
          {t('members')}
        </Typography>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
        style={{ zIndex: 3 }}
      >
        {() => (
          <Paper elevation={3} square>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="new-list-grow" onKeyDown={handleListKeyDown} className={classes.list}>
                <MenuItem onClick={openMemberMobileDialog}>
                  <ListItemIcon>
                    <ReorderIcon fontSize="small" />
                  </ListItemIcon>
                  {t('memberList')}
                </MenuItem>
                <MenuItem onClick={openInviteMemberDialog}>
                  <ListItemIcon>
                    <PostAddIcon fontSize="small" />
                  </ListItemIcon>
                  {t('manageMember')}
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        )}
      </Popper>
    </>
  );
};

MemberButton.propTypes = {
  project: object.isRequired,
  // setOpenMenuBar: func.isRequired,
};

export default MemberButton;
