import { Card, Grid, makeStyles } from '@material-ui/core';
import { object, string } from 'prop-types';

import ForumCardLayout from './components/layout/ForumCardLayout';

const useStyles = makeStyles({
  card: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    minHeight: 120,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    borderRadius: 0,
  },
});

const ForumCard = ({ log, type, ...props }) => {
  const classes = useStyles();

  return (
    <Grid component={Card} item xs={12} container className={classes.card}>
      {log && <ForumCardLayout type={type} log={log} {...props} />}
    </Grid>
  );
};

ForumCard.propTypes = {
  project: object,
  log: object,
  type: string,
};

ForumCard.defaultProps = {
  project: undefined,
};

export default ForumCard;
