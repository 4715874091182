import { Grid } from '@material-ui/core';
import { string, element, bool } from 'prop-types';

import SearchByInput from './SearchByInput';

const TabSideMenu = ({ desktop, searchName, components, ...props }) => (
  <Grid item xs={desktop ? 10 : 12}>
    {searchName && <SearchByInput name={searchName} desktop={desktop} {...props} />}
    {components}
  </Grid>
);

TabSideMenu.propTypes = {
  searchName: string,
  components: element,
  desktop: bool.isRequired,
};

TabSideMenu.defaultProps = {
  searchName: undefined,
};

export default TabSideMenu;
