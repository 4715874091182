import { Button, Container, Grid, Typography, Box, useMediaQuery, makeStyles, TextField } from '@material-ui/core';
import { func, object } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CocoonHelmet } from 'components';
import { META_DATA } from 'helpers/metaData';

import AboutTitle from '../about/components/AboutTitle';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(6),
  },
  form: {
    width: '100%',
    '& > div > div': {
      marginTop: 0,
      marginBottom: theme.spacing(2),
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: 'white',
  },
  pageTitle: {
    color: theme.palette.text.secondary,
  },
  description: {
    fontSize: 20,
    color: theme.palette.text.secondary,
    title: {
      fontWeight: 700,
      fontSize: 50,
      [theme.breakpoints.down('sm')]: {
        fontSize: 30,
      },
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
}));
const Contact = ({ text, handleSubmit, register, errors }) => {
  const classes = useStyles();
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const { t } = useTranslation('common');

  const { title, description, image, url } = META_DATA.CONTACT;

  const commonInputProps = {
    variant: 'outlined',
    size: desktop ? 'medium' : 'small',
    margin: 'normal',
    fullWidth: true,
  };

  return (
    <Container fixed className={classes.container}>
      <CocoonHelmet title={title} description={description} image={image} url={url} />
      <AboutTitle title={t('common.contactUs')} desktop={desktop} />
      <Grid container spacing={6}>
        <Grid item xs={desktop ? 4 : 12} className={classes.description}>
          <Box mb={3} component={Typography} variant={desktop ? 'h4' : 'h5'} gutterBottom>
            {text.title}
          </Box>
          <Typography variant={desktop ? 'body1' : 'body2'} paragraph gutterBottom>
            {text.share}
          </Typography>
          <Typography variant={desktop ? 'body1' : 'body2'} paragraph>
            {text.contact}{' '}
            <a className={classes.link} href={text.email}>
              {text.email}
            </a>
          </Typography>
        </Grid>
        <Grid item xs={desktop ? 6 : 12}>
          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              id="fullName"
              name="fullName"
              label={t('contactFormInformation.fullName')}
              variant="outlined"
              inputRef={register}
              error={!!errors?.fullName}
              {...commonInputProps}
            />
            <TextField
              id="email"
              name="email"
              label={t('contactFormInformation.email')}
              inputRef={register}
              error={!!errors?.email}
              {...commonInputProps}
            />
            <TextField
              id="text"
              name="text"
              label={t('contactFormInformation.textField')}
              multiline
              rows={6}
              inputRef={register}
              error={!!errors?.text}
              {...commonInputProps}
            />
            <Button
              type="submit"
              fullWidth
              size={desktop ? 'medium' : 'small'}
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              <Typography variant="button" color="inherit">
                {t('button.send')}
              </Typography>
            </Button>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

Contact.propTypes = {
  handleSubmit: func.isRequired,
  text: object.isRequired,
  errors: object.isRequired,
  register: func.isRequired,
};

export default Contact;
