import { useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';

import { LOGOUT } from 'apollo/mutations/auth-mutation';
import ReduxAuth from 'config/redux/auth';

import AppHeader from './AppHeader';

const AppHeaderContainer = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { logout } = useAuth0();
  const [backendLogout] = useMutation(LOGOUT);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const handleLogout = async (userId) => {
    ReduxAuth.logout();
    await backendLogout();
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return <AppHeader onClick={toggle} isOpen={collapsed} handleLogout={handleLogout} />;
};

export default AppHeaderContainer;
