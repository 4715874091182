import { Box, Button, Grid, CircularProgress, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import { arrayOf, object, number, bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import PullToRefresh from 'react-simple-pull-to-refresh';

import { isSameUser } from 'helpers/auth';

import StepSection from './components/section';

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: theme.spacing(1, 0),
  },
  nbMembers: {
    color: theme.palette.secondary.main,
  },
  avatar: {
    marginRight: theme.spacing(2.5),
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  btn: {
    color: '#575757',
    borderColor: '#575757',
    borderRadius: '6px',
    borderWidth: '1px',
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(1, 3),
    margin: theme.spacing(0, 1),
    textTransform: 'none',
    '&:hover': {
      background: 'transparent',
      '& *': {
        fontWeight: 'bold',
      },
    },
  },
  btnFluid: {
    color: '#575757',
    borderColor: '#575757',
    borderRadius: '6px',
    borderWidth: '1px',
    width: '100%',
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(1, 3),
    textTransform: 'none',
    '&:hover': {
      background: 'transparent',
      '& *': {
        fontWeight: 'bold',
      },
    },
  },
  btnEdit: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
  },
  linkAttachment: {
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(0.5, 0),
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  btnChangePhoto: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    position: 'absolute',
    right: '2%',
    top: '5%',
    padding: theme.spacing(1, 2),
    borderRadius: '20px',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
  },
}));

const TutorialDetails = ({
  sections,
  canFetchMore,
  handleFetchMore,
  error,
  ownerId,
  addSectionVisible,
  toggleAddSection,
  handleAddSection,
  handleEditSection,
  handleDeleteSection,
  desktop,
}) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const handleOnFetchMore = () => {
    return new Promise((res) => {
      res(handleFetchMore());
    });
  };

  return (
    <Grid item xs={12} className={classes.container}>
      <PullToRefresh canFetchMore={canFetchMore} onFetchMore={handleOnFetchMore} fetchMoreThreshold={0}>
        {_.map(sections, (section) => (
          <StepSection
            key={section.id}
            ownerId={ownerId}
            section={section}
            toggleAddSection={toggleAddSection}
            handleEditSection={handleEditSection}
            handleDeleteSection={handleDeleteSection}
          />
        ))}
      </PullToRefresh>
      {addSectionVisible && (
        <StepSection
          isNewSection
          handleAddSection={handleAddSection}
          toggleAddSection={toggleAddSection}
          desktop={desktop}
        />
      )}
      {canFetchMore && (
        <Box height={100} width="100%" display="flex" alignItems="center" justifyContent="center">
          <CircularProgress color="inherit" />
        </Box>
      )}
      {error && (
        <Alert variant="outlined" severity="error">
          {error.message}
        </Alert>
      )}
      {isSameUser(ownerId) && (
        <Box textAlign="center" mt={2}>
          <Button size="small" variant="outlined" className={classes.btnFluid} onClick={toggleAddSection}>
            {t('button.addSection')}
          </Button>
        </Box>
      )}
    </Grid>
  );
};

TutorialDetails.propTypes = {
  addSectionVisible: bool,
  desktop: bool.isRequired,
  error: object,
  handleAddSection: func,
  handleDeleteSection: func,
  handleEditSection: func,
  ownerId: number,
  sections: arrayOf(object),
  toggleAddSection: func,
  handleFetchMore: func.isRequired,
  canFetchMore: bool,
};

export default TutorialDetails;
