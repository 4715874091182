import { bool, number, func, object, arrayOf } from 'prop-types';

import TreeContentContainer from '../tree-content/TreeContentContainer';

import NotFoundPage from './NotFoundPage';

const ContentCondition = ({
  dashboardTab,
  project,
  isMilestone,
  isPhase,
  changeMilestoneContent,
  changePhaseContent,
  numberLogChosen,
  newLog,
  handleStatusNewLog,
  isNewLog,
  handleChangeNewLog,
  refetchPanel,
  isReload,
  reloadComponent,
  updateLogs,
  deleteNewLog,
  handleCancelEditMode,
  crudStatus,
  setCrudStatus,
  isShowAutoSave,
  setIsShowAutoSave,
  desktop,
}) => {
  if (dashboardTab === 0) {
    return (
      <TreeContentContainer
        project={project}
        isMilestone={isMilestone}
        isPhase={isPhase}
        changeMilestoneContent={changeMilestoneContent}
        changePhaseContent={changePhaseContent}
        numberLogChosen={numberLogChosen}
        newLog={newLog}
        handleStatusNewLog={handleStatusNewLog}
        isNewLog={isNewLog}
        handleChangeNewLog={handleChangeNewLog}
        refetchPanel={refetchPanel}
        isReload={isReload}
        reloadComponent={reloadComponent}
        updateLogs={updateLogs}
        deleteNewLog={deleteNewLog}
        handleCancelEditMode={handleCancelEditMode}
        crudStatus={crudStatus}
        setCrudStatus={setCrudStatus}
        isShowAutoSave={isShowAutoSave}
        setIsShowAutoSave={setIsShowAutoSave}
        desktop={desktop}
      />
    );
  }
  if (dashboardTab !== 0) {
    return (
      <div style={{ height: '100%', width: '100%', position: 'relative' }}>
        <NotFoundPage />
      </div>
    );
  }
  return null;
};

ContentCondition.propTypes = {
  project: object.isRequired,
  dashboardTab: number.isRequired,
  isMilestone: bool.isRequired,
  isPhase: bool.isRequired,
  changeMilestoneContent: func.isRequired,
  handleCancelEditMode: func.isRequired,
  changePhaseContent: func.isRequired,
  numberLogChosen: number.isRequired,
  newLog: object.isRequired,
  handleStatusNewLog: func.isRequired,
  isNewLog: bool.isRequired,
  handleChangeNewLog: func.isRequired,
  refetchPanel: func.isRequired,
  isReload: bool,
  reloadComponent: func,
  updateLogs: arrayOf(object),
  deleteNewLog: func,
  crudStatus: number,
  setCrudStatus: func,
  isShowAutoSave: bool,
  setIsShowAutoSave: func,
  desktop: bool,
};

export default ContentCondition;
