import { InputBase, makeStyles } from '@material-ui/core';
import { number, string, bool, func } from 'prop-types';
import { useState } from 'react';

import SectionHeader from './Header';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.typography.h6,
    color: '#575757',
  },
}));

const SectionHeaderContainer = ({ ownerId, sectionName, isEditMode, changeEditMode, updateTitle }) => {
  const [name, setName] = useState(sectionName);

  const { t } = useTranslation();

  const handleChangeName = (event) => {
    const { value } = event.target;

    setName(value);
  };

  const handleUpdateTitle = (event) => {
    event.preventDefault();

    updateTitle(name);
  };

  const classes = useStyles();

  if (isEditMode) {
    return (
      <InputBase
        className={classes.title}
        inputProps={{ 'aria-label': 'SectionName' }}
        placeholder={t('common.untitled')}
        fullWidth
        name="sectionName"
        value={name}
        onChange={handleChangeName}
        onBlur={handleUpdateTitle}
      />
    );
  }

  return <SectionHeader ownerId={ownerId} sectionName={sectionName} handleEdit={changeEditMode} />;
};

SectionHeaderContainer.propTypes = {
  changeEditMode: func,
  isEditMode: bool,
  ownerId: number,
  sectionName: string,
  updateTitle: func,
};

export default SectionHeaderContainer;
