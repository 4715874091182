import { makeStyles } from '@material-ui/core';
import { object, bool } from 'prop-types';
import { useHistory } from 'react-router-dom';

import { getImg, convertToSlug } from 'helpers/common';

import Image from '../../components/image/Image';

import ProjectOverlay from './components/overlay/ProjectOverlay';

const useStyles = makeStyles(() => ({
  imageContainer: {
    borderRadius: '6px',
    position: 'relative',
  },
  img: {
    display: 'block',
    borderRadius: '6px',
    height: 'auto',
    width: '100%',
  },
  hovered: {
    backgroundColor: 'rgba(0,0,0,0.3)',
    borderRadius: '6px',
    zIndex: 9,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
}));

const ProjectWithOverlay = ({ desktop, project, isHovered, isFromList = false, isFromContest = false }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = (e) => {
    if (isFromList) return;
    e.stopPropagation();
    history.push(getLink());
  };

  const getLink = () => {
    if (isFromContest) {
      return `/contests/${project.id}/${convertToSlug(project.name)}`;
    } else {
      return `/projects/${project.id}/${convertToSlug(project.name)}`;
    }
  };

  const imageLink = () => {
    if (!isFromContest && project.projects && project.projects.length > 0) {
      return project.projects[0].assetLink;
    }

    return project.assetLink;
  };

  return (
    <div className={classes.imageContainer}>
      <Image
        src={getImg(imageLink())}
        alt={project.name}
        aspectRatio={1}
        style={{
          backgroundColor: 'rgba(0,0,0,0.3)',
          borderRadius: '6px',
        }}
        onClick={(e) => handleClick(e)}
      />
      {isHovered && (
        <ProjectOverlay
          desktop={desktop}
          project={project}
          isFromList={isFromList}
          isFromContest={isFromContest}
          link={getLink()}
        />
      )}
    </div>
  );
};

ProjectWithOverlay.propTypes = {
  desktop: bool.isRequired,
  project: object.isRequired,
  isHovered: bool.isRequired,
  isFromList: bool,
  isFromContest: bool,
};

ProjectWithOverlay.defaultProps = {
  isFromList: false,
  isFromContest: false,
};

export default ProjectWithOverlay;
