// import { useMutation } from '@apollo/client';
import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { Reorder as ReorderIcon } from '@material-ui/icons';
import { bool, object, func } from 'prop-types';
import { useEffect, useState } from 'react';
// import { useRouteMatch } from 'react-router-dom';

// import { INVITE_PROJECT_MEMBER, REMOVE_PROJECT_MEMBER } from 'apollo/mutations/invite-member-mutation';

// import DeleteProjectMemberDialog from '../delete-project-member/DeleteProjectMemberDialog';

import { useTranslation } from 'react-i18next';

import MemberList from './components/MemberList';
import useStyles from './InviteProjectMembersDialogStyle';
// let delayTimer;

const MemberMobileDialog = ({ open, options, handleCloseDialog }) => {
  const classes = useStyles();
  const { t } = useTranslation('dialog');
  // const match = useRouteMatch();
  // const { id } = match.params;
  // const projectId = parseInt(id);
  // const [inviteProjectMembers] = useMutation(INVITE_PROJECT_MEMBER);
  // const [removeProjectMembers] = useMutation(REMOVE_PROJECT_MEMBER);

  const [project, setProject] = useState({ id: 0 });

  useEffect(() => {
    setProject(options);
  }, [options]);

  // const handleGoBack = () => {
  //   handleCloseDialog();
  // };

  // const [searchMember, setSearchMember] = useState('');
  // const [listMemberInvite, setListMemberInvite] = useState([]);
  // const [openSearch, setOpenSearch] = React.useState(false);
  // const [optionsMembers, setOptionsMembers] = React.useState([]);
  // const [loading, setLoading] = useState(false);
  // const [membersInvitation, setMembersInvitation] = useState([]);
  // const [chosenMembers, setChosenMembers] = useState([]);
  // const [openDelete, setOpenDelete] = useState(false);
  // const [previousSearch, setPreviousSearch] = useState();
  // const [changeOption, setChangeOption] = useState(false);
  // const loading = openSearch && optionsMembers.length === 0;
  // const [change, setChange] = useState(false);

  // const closeDeleteDialog = () => {
  //   setOpenDelete(false);
  // };

  return open ? (
    <div>
      {/* <DeleteProjectMemberDialog
        open={openDelete}
        closeDeleteDialog={closeDeleteDialog}
        clearAndRefetch={clearAndRefetch}
        options={optionsDelete}
      /> */}
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="edit-profile-dialog-title"
        aria-describedby="edit-profile-description"
        className={classes.dialog}
        maxWidth="md"
      >
        <DialogTitle>
          <Grid container alignItems="center" alignContent="space-between">
            <Grid item xs>
              <Typography color="secondary" variant="h5">
                <Box display="flex" alignItems="center" className={classes.header}>
                  <ReorderIcon className={classes.icon} />
                  {t('addMember.memberList')}
                </Box>
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          {/* <InviteContent
            // searchMember={searchMember}
            // onSearchMemberChange={onSearchMemberChange}
            project={project}
            // setOpenSearch={setOpenSearch}
            // openSearch={openSearch}
            // loading={loading}
            // optionsMembers={optionsMembers}
            // membersInvitation={membersInvitation}
            // onChangeChosenMembers={onChangeChosenMembers}
            // chosenMembers={chosenMembers}
            // onClickRemove={onClickRemove}
            desktop={desktop}
          /> */}
          <MemberList project={project} />
        </DialogContent>
      </Dialog>
    </div>
  ) : null;
};

MemberMobileDialog.propTypes = {
  open: bool.isRequired,
  options: object.isRequired,
  handleCloseDialog: func.isRequired,
};

export default MemberMobileDialog;
