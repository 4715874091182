import { IconButton, Button, Avatar, Box, Grid, Typography, makeStyles } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import _ from 'lodash';
import { number, func, object, bool, arrayOf } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { isSameUser } from 'helpers/auth';

const useStyles = makeStyles((theme) => ({
  btnTutorialStep: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1, 3),
    textTransform: 'none',
    textAlign: 'left',
    borderRadius: '6px',
    '&:hover': {
      backgroundColor: '#F5F5F5',
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1),
    },
  },
  link: {
    '&': { color: theme.palette.primary.main },
    '&:hover': {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  avaStep: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    marginRight: theme.spacing(2.5),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1.5),
      width: theme.spacing(4.5),
      height: theme.spacing(4.5),
    },
  },
  button: {
    color: '#575757',
    width: '100%',
    borderColor: '#575757',
    borderRadius: '6px',
    borderWidth: '1px',
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(0.25, 1.5),
    textTransform: 'none',
    '&:hover': {
      background: 'transparent',
      '& *': {
        fontWeight: 'bold',
      },
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      padding: theme.spacing(0.5, 1),
    },
  },
  btnEditIconWrapper: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const btnActive = (active) => ({
  backgroundColor: active ? '#F5F5F5' : 'transparent',
});

const TutorialStep = ({
  ownerId,
  isNewProject,
  step: { id, stepNumber, name },
  stepActive,
  handleChangeStep,
  handleEditStep,
}) => {
  const classes = useStyles();

  return (
    <Grid
      component={Button}
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
      className={classes.btnTutorialStep}
      onClick={(e) => handleChangeStep(e, +id)}
      style={btnActive(+stepActive === +id)}
    >
      <Grid item xs={2}>
        <Avatar className={classes.avaStep}>{stepNumber}</Avatar>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="h6" className={classes.link}>
          {name}
        </Typography>
      </Grid>
      {!isNewProject && isSameUser(ownerId) && (
        <Grid item xs={2} className={classes.btnEditIconWrapper}>
          <IconButton component="div" onClick={(e) => handleEditStep(e, { id, stepNumber, name })}>
            <EditIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

TutorialStep.propTypes = {
  ownerId: number.isRequired,
  isNewProject: bool.isRequired,
  step: object.isRequired,
  stepActive: number.isRequired,
  handleChangeStep: func.isRequired,
  handleEditStep: func.isRequired,
};

const TutorialsList = ({
  steps,
  isNewProject,
  canFetchMore,
  ownerId,
  handleAddStep,
  handleLoadMoreSteps,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.root}>
      {_.isEmpty(steps) ? (
        <Box mt={2} fontWeight="fontWeightBold">
          {t('defaultInformation.noSteps')}
        </Box>
      ) : (
        _.map(steps, (step) => (
          <TutorialStep key={step.id} step={step} isNewProject={isNewProject} ownerId={ownerId} {...props} />
        ))
      )}
      {canFetchMore && (
        <Box textAlign="center" mt={2}>
          <Button variant="outlined" className={classes.button} onClick={handleLoadMoreSteps}>
            {t('button.moreSteps')}
          </Button>
        </Box>
      )}
      {!isNewProject && isSameUser(ownerId) && (
        <Box textAlign="center" mt={2}>
          <Button variant="outlined" className={classes.button} onClick={handleAddStep}>
            {t('button.addStep')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

TutorialsList.propTypes = {
  steps: arrayOf(object).isRequired,
  isNewProject: bool.isRequired,
  canFetchMore: bool.isRequired,
  ownerId: number,
  handleAddStep: func.isRequired,
  handleLoadMoreSteps: func.isRequired,
};

TutorialsList.defaultProps = {
  ownerId: undefined,
};

export default TutorialsList;
