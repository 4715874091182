import { useQuery } from '@apollo/client';
import DateFnsUtils from '@date-io/date-fns';
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { bool, object } from 'prop-types';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { GET_COUNTRIES } from 'apollo/queries/country-query';
import { GET_STATES_BY_COUNTRY_ID } from 'apollo/queries/state-query';

import AutocompleteBase from './AutocompleteBase';
import useStyles from './basicInfoStyle';

const BasicInfo = ({ watch, control, register, setValue, desktop }) => {
  const { data: dataCountries, loading: loadingCountries } = useQuery(GET_COUNTRIES, {
    variables: { page: 1, limit: 'FIVE_O' },
  });
  const { data: dataStates, loading: loadingStates } = useQuery(GET_STATES_BY_COUNTRY_ID, {
    variables: {
      countryId: watch('country') !== null && watch('country').id,
      page: 1,
      limit: 'FIVE_O',
    },
    skip: watch('country') === null,
  });

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.editInfoWrapper}>
      <Grid container spacing={desktop ? 3 : 1}>
        <Grid item xs={desktop ? 6 : 12}>
          <Typography className={classes.editInfoTitle}>{t('common.firstName')}</Typography>
          <hr className={classes.divider} />
          <TextField
            className={classes.editInfoTextField}
            fullWidth
            variant="outlined"
            name="firstName"
            inputRef={register}
          />
        </Grid>
        <Grid item xs={desktop ? 6 : 12}>
          <Typography className={classes.editInfoTitle}>{t('common.lastName')}</Typography>
          <hr className={classes.divider} />
          <TextField
            className={classes.editInfoTextField}
            fullWidth
            variant="outlined"
            name="lastName"
            inputRef={register}
          />
        </Grid>
      </Grid>

      <Grid container style={{ marginTop: '1em' }} spacing={desktop ? 3 : 1}>
        <Grid item xs={desktop ? 6 : 12}>
          <Typography className={classes.editInfoTitle}>{t('common.gender')}</Typography>
          <hr className={classes.divider} />
          <FormControl component="fieldset">
            <Controller
              control={control}
              name="gender"
              as={
                <RadioGroup row aria-label="gender" name="gender">
                  <FormControlLabel
                    className={classes.formControl}
                    value="male"
                    control={<Radio />}
                    label={t('common.male')}
                  />
                  <FormControlLabel
                    className={classes.formControl}
                    value="female"
                    control={<Radio />}
                    label={t('common.female')}
                  />
                  <FormControlLabel
                    className={classes.formControl}
                    value="other"
                    control={<Radio />}
                    label={t('common.other')}
                  />
                </RadioGroup>
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={desktop ? 6 : 12}>
          <Typography className={classes.editInfoTitle}>{t('common.dob')}</Typography>
          <hr className={classes.divider} />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={<KeyboardDatePicker />}
              name="dob"
              control={control}
              autoOk
              className={classes.editInfoTextField}
              variant="dialog"
              inputVariant="outlined"
              fullWidth
              clearable
              InputAdornmentProps={{ position: 'end' }}
              disableFuture
              format="dd/MM/yyyy"
              views={['year', 'month', 'date']}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>

      <Grid container style={{ marginTop: '1em' }} spacing={desktop ? 2 : 1}>
        <Grid item xs={desktop ? 6 : 12}>
          <Typography className={classes.editInfoTitle}>{t('common.country')}</Typography>
          <hr className={classes.divider} />
          <Controller
            as={({ onChange, value }) => (
              <AutocompleteBase
                name="country"
                options={dataCountries?.countries}
                handleOnChange={(event, value) => {
                  onChange(event, value);
                  setValue('state', null);
                }}
                fieldValue={value}
                loading={loadingCountries}
                desktop={desktop}
              />
            )}
            control={control}
            name="country"
          />
        </Grid>
        <Grid item xs={desktop ? 6 : 12}>
          <Typography className={classes.editInfoTitle}>{t('common.stateCityProvince')}</Typography>
          <hr className={classes.divider} />
          <Controller
            as={({ onChange, value }) => (
              <AutocompleteBase
                name="state"
                options={dataStates?.statesByCountryId}
                handleOnChange={onChange}
                loading={loadingStates}
                disabled={!watch('country')}
                desktop={desktop}
                fieldValue={value}
              />
            )}
            control={control}
            name="state"
          />
        </Grid>
      </Grid>
    </div>
  );
};

BasicInfo.propTypes = {
  editProfile: object.isRequired,
  desktop: bool.isRequired,
};

export default BasicInfo;
