import { gql } from '@apollo/client';

// done
// updated
export const CREATE_USER_EXPERIENCE = gql`
  mutation createUserExperience($experience: NewUserExperienceInput!) {
    createUserExperience(experience: $experience) {
      id
    }
  }
`;

// done
// updated
export const UPDATE_USER_EXPERIENCE = gql`
  mutation updateUserExperience($experience: UpdateUserExperienceInput!) {
    updateUserExperience(experience: $experience) {
      status
      description
    }
  }
`;

// done
// updated
export const DELETE_USER_EXPERIENCE = gql`
  mutation deleteUserExperience($experience: DeleteUserExperienceInput!) {
    deleteUserExperience(experience: $experience) {
      status
      description
    }
  }
`;
