import { gql } from '@apollo/client';

// done
// updated
export const ADD_PROJECT_BOOKMARK = gql`
  mutation addProjectBookmark($bookmark: NewProjectBookmark!) {
    addProjectBookmark(bookmark: $bookmark) {
      id
    }
  }
`;

// done
// updated
export const DELETE_PROJECT_BOOKMARK = gql`
  mutation deleteProjectBookmark($bookmark: RemoveProjectBookmark!) {
    deleteProjectBookmark(bookmark: $bookmark) {
      description
      status
    }
  }
`;
