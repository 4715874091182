import { InputBase, InputAdornment, IconButton, Chip, makeStyles } from '@material-ui/core';
import { Cancel as CancelIcon } from '@material-ui/icons';
import _ from 'lodash';
import { arrayOf, object, func, string, number } from 'prop-types';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  tag: {
    ...theme.typography.h6,
    color: theme.palette.common.white,
    margin: theme.spacing(0.5),
    borderRadius: '6px',
    borderWidth: '1px',
    '& > span': {
      padding: theme.spacing(0, 1.25),
    },
    '& > svg': {
      color: theme.palette.common.white,
    },
    [theme.breakpoints.down('sm')]: {
      '& > span': {
        fontSize: 14,
      },
    },
  },
  tagEdit: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    padding: theme.spacing(0, 1),
    margin: theme.spacing(0.5),
    textTransform: 'none',
    borderRadius: '6px',
    borderWidth: '1px',
    backgroundColor: theme.palette.secondary.main,
    width: '6.5em',
    '& *': {
      fontWeight: 'bold',
    },
    '&.Mui-focused': {
      backgroundColor: '#989898',
    },
    [theme.breakpoints.down('sm')]: {
      '& > *': {
        fontSize: 14,
      },
    },
  },
  clear: {
    padding: 0,
    '& svg': {
      width: '22px',
      height: '22px',
      color: theme.palette.common.white,
    },
  },
}));

const InputTag = ({ index, value, label, tags, setTags, openSnackbarError, tagSizeLimit }) => {
  const classes = useStyles();
  const [isEdit, setIsEdit] = useState(false);
  const { register, handleSubmit, watch, setValue, errors } = useForm();
  const tagValue = watch('tag', value);

  const { t } = useTranslation(['common', 'dialog']);

  useEffect(() => {
    if (value !== tagValue && tagValue === '') {
      setValue('tag', value);
    }
  }, [value]);

  useEffect(() => {
    if (tags) {
      setValue('tag', tags[index] ? tags[index][label] : '');
    }
  }, [tags]);

  const handleClearInput = () => {
    setValue('tag', '');
  };

  const toggleEdit = (e) => {
    e.preventDefault();
    setIsEdit((prevState) => !prevState);
  };

  const handleMouseDownInput = (e) => {
    e.preventDefault();
  };

  const handleDeleteTag = (index) => {
    const tagsUpdated = tags.filter((__, i) => i !== index);
    setTags(tagsUpdated);
  };

  const handleOnSubmit = ({ tag }, e) => {
    e.preventDefault();
    if (tagValue !== '') {
      if (tagValue.length > tagSizeLimit) {
        openSnackbarError(`${t('dialog:openSnackbarError.textLength')} ${tagSizeLimit}`);
        return;
      }

      const updateTag = { ...tags[index], [label]: tagValue };
      const updatedTags = [...tags.slice(0, index), updateTag, ...tags.slice(index + 1)];
      setTags(updatedTags);
      toggleEdit(e);
    } else {
      handleDeleteTag(index);
      toggleEdit(e);
    }
  };

  return !isEdit ? (
    tagValue !== '' && (
      <Chip label={tagValue} className={classes.tag} color="secondary" onClick={toggleEdit} key={index} />
    )
  ) : (
    <form onSubmit={handleSubmit(handleOnSubmit)} noValidate autoComplete="off">
      <InputBase
        name="tag"
        type="text"
        className={classes.tagEdit}
        value={tagValue}
        onBlur={handleSubmit(handleOnSubmit)}
        key={index}
        inputRef={register}
        autoFocus
        endAdornment={
          !_.isEmpty(tagValue) && (
            <InputAdornment position="end">
              <IconButton
                aria-label="clear input"
                onClick={handleClearInput}
                onMouseDown={handleMouseDownInput}
                className={classes.clear}
              >
                <CancelIcon />
              </IconButton>
            </InputAdornment>
          )
        }
      />
    </form>
  );
};

InputTag.propTypes = {
  index: number.isRequired,
  value: string.isRequired,
  label: string.isRequired,
  tags: arrayOf(object).isRequired,
  setTags: func,
  openSnackbarError: func.isRequired,
  tagSizeLimit: number.isRequired,
};

InputTag.defaultProps = {
  setTags: undefined,
};

export default InputTag;
