import { gql } from '@apollo/client';

// done
// updated
export const GET_LATEST_UPDATES_OF_PROJECTS = gql`
  query landingPageLatestLogs {
    landingPageLatestLogs {
      id
      name
      description
      createdAt
      updatedAt
      attachments {
        id
        name
        assetLink
        assetType
        size
      }
      tags {
        id
        name
      }
      project {
        id
        owner {
          id
          firstName
          lastName
          avatarLink
        }
      }
    }
  }
`;

// done
// updated
export const GET_UPDATES_BY_CATEGORY_ID = gql`
  query landingPageLatestLogs($projectCategoryId: ID) {
    landingPageLatestLogs(projectCategoryId: $projectCategoryId) {
      id
      name
      description
      createdAt
      updatedAt
      attachments {
        id
        name
        assetLink
        assetType
        size
      }
      tags {
        id
        name
      }
      project {
        id
        owner {
          id
          firstName
          lastName
          avatarLink
        }
      }
    }
  }
`;
