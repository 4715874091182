import { func, string, object, bool, arrayOf } from 'prop-types';

import DesktopContestInfoBar from './components/desktop/DesktopContestInfoBar';
import MobileContestInfoSelect from './components/mobile/MobileContestInfoSelect';

const ContestInfoBar = ({ tabs, currentTab, handleSwitchTab, desktop, contest }) => {
  function getContestStats({
    viewsCount,
    contestSponsorsCount,
    contestFollowersCount,
    contestCuratorsCount,
    contestProjectsCount,
  }) {
    return {
      viewsCount,
      contestSponsorsCount,
      contestFollowersCount,
      contestCuratorsCount,
      contestProjectsCount,
    };
  }

  return desktop ? (
    <DesktopContestInfoBar
      desktop={desktop}
      partnerAvatar={contest.creator.avatarLink}
      partnerName={contest.creator.partnerName}
      contestApplicants={contest.members}
      contestSponsors={contest.sponsors}
      contestStats={getContestStats(contest)}
    />
  ) : (
    <MobileContestInfoSelect tabs={tabs} currentTab={currentTab} handleSwitchTab={handleSwitchTab} />
  );
};
ContestInfoBar.propTypes = {
  tabs: arrayOf(object).isRequired,
  currentTab: string.isRequired,
  handleSwitchTab: func.isRequired,
  desktop: bool.isRequired,
  contest: object.isRequired,
};

export default ContestInfoBar;
