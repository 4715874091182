import _ from 'lodash';
import { useCallback, useEffect, useRef, useMemo } from 'react';

const createCallback = (debounce, handleOnScroll, options) => {
  if (debounce) {
    return _.debounce(handleOnScroll, debounce, options);
  }
  return handleOnScroll;
};

const useBottomScrollListener = (onBottom, options) => {
  const { offset, triggerOnNoScroll, debounce, debounceOptions } = useMemo(
    () => ({
      // Offset from bottom of page in pixels. E.g. 300 will trigger onBottom 300px from the bottom of the page
      page: options?.offset ?? 300,
      // Optional debounce in milliseconds, defaults to 700ms
      debounce: options?.debounce ?? 700,
      // Overwrite the debounceOptions for lodash.debounce, default to { leading: true }
      debounceOptions: options?.debounceOptions ?? { leading: true },
      // If container is too short, enables a trigger of the callback if that happens, defaults to false
      triggerOnNoScroll: options?.triggerOnNoScroll ?? false,
    }),
    [options?.offset, options?.debounce, options?.debounceOptions, options?.triggerOnNoScroll],
  );

  const debouncedOnBottom = useMemo(() => createCallback(debounce, onBottom, debounceOptions), [debounce, onBottom]);
  const containerRef = useRef(null);
  const handleOnScroll = useCallback(() => {
    if (containerRef.current != null) {
      const scrollNode = containerRef.current;
      const scrollContainerBottomPosition = Math.round(scrollNode.scrollTop + scrollNode.clientHeight);
      const scrollPosition = Math.round(scrollNode.scrollHeight - offset);

      if (scrollPosition <= scrollContainerBottomPosition) {
        debouncedOnBottom();
      }
    } else {
      const scrollNode = document.scrollingElement || document.documentElement;
      const scrollContainerBottomPosition = Math.round(scrollNode.scrollTop + window.innerHeight);
      const scrollPosition = Math.round(scrollNode.scrollHeight - offset);

      if (scrollPosition <= scrollContainerBottomPosition) {
        debouncedOnBottom();
      }
    }
    // ref dependency needed for the tests, doesn't matter for normal execution
  }, [offset, onBottom, containerRef.current]);

  useEffect(() => {
    const ref = containerRef.current;

    if (ref != null) {
      ref.addEventListener('scroll', handleOnScroll);
    } else {
      window.addEventListener('scroll', handleOnScroll);
    }

    if (triggerOnNoScroll) {
      handleOnScroll();
    }

    return () => {
      if (ref != null) {
        ref.removeEventListener('scroll', handleOnScroll);
      } else {
        window.removeEventListener('scroll', handleOnScroll);
      }
    };
  }, [handleOnScroll, debounce]);

  return containerRef;
};

export default useBottomScrollListener;
