import { number, func } from 'prop-types';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { getValue, KEYS } from 'helpers/localStorage';
import { getCurrentUser } from 'reducers/authReducer';

import InputNewComment from '../input-new-comment/InputNewComment';

// import InputNewReply from './InputNewReply';

const InputNewReplyContainer = ({ commentId, submitReply, toggleAddReplyVisible }) => {
  const currentUser = useSelector(getCurrentUser);

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = ({ comment }) => {
    if (comment.length > 0) {
      submitReply(commentId, currentUser, comment.replace(/(\n)/gm, '\n\n'));
      toggleAddReplyVisible();
    }
  };

  return (
    <InputNewComment
      register={register}
      handleSubmit={handleSubmit(onSubmit)}
      handleCancel={toggleAddReplyVisible}
      avatar={currentUser.avatarLink || getValue(KEYS.avatarLink)}
    />
  );
};

InputNewReplyContainer.propTypes = {
  commentId: number.isRequired,
  submitReply: func.isRequired,
  toggleAddReplyVisible: func.isRequired,
};

export default InputNewReplyContainer;
