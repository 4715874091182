import { useQuery } from '@apollo/client';
import { Box, CircularProgress } from '@material-ui/core';
import { useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { GET_PARTNER_BY_ID } from 'apollo/queries/partner-query';
import { PARTNER_TABS } from 'helpers/constants';

import PartnerProfile from './PartnerProfile';
const { PARTNER_PROJECTS, SPONSORED_PARTNER_PROJECTS, PARTNER_CONTESTS, PARTNER_INFO } = PARTNER_TABS;

const PartnerProfileWrapper = () => {
  const [currentTab, setCurrentTab] = useState(PARTNER_PROJECTS);
  const [descriptionHidden, setDescriptionHidden] = useState(false);
  const { id } = useParams();
  const partnerId = +id;

  const {
    data: profile,
    loading: loadingProfile,
    error: errorProfile,
    refetch: refetchProfile,
  } = useQuery(GET_PARTNER_BY_ID, {
    variables: { id: partnerId },
  });

  const TABS = [
    {
      id: 0,
      key: PARTNER_PROJECTS,
      title: 'Projects',
    },
    {
      id: 1,
      key: SPONSORED_PARTNER_PROJECTS,
      title: 'Sponsored',
    },
    {
      id: 2,
      key: PARTNER_CONTESTS,
      title: 'Contest',
    },
  ];

  const MOBILE_TABS = [
    {
      id: 0,
      key: PARTNER_INFO,
      title: 'Partner Info',
    },
    {
      id: 1,
      key: PARTNER_PROJECTS,
      title: 'Projects',
    },
    {
      id: 2,
      key: SPONSORED_PARTNER_PROJECTS,
      title: 'Sponsored',
    },
    {
      id: 3,
      key: PARTNER_CONTESTS,
      title: 'Contest',
    },
  ];

  if (loadingProfile) {
    return (
      <Box height="300px" width="100%" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress color="inherit" />
      </Box>
    );
  }

  if ((!loadingProfile && !profile) || errorProfile) {
    return <Redirect to="/not-found" />;
  }

  return (
    <PartnerProfile
      profile={profile.partnerById}
      refetchProfile={refetchProfile}
      tabs={TABS}
      mobileTabs={MOBILE_TABS}
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
      descriptionHidden={descriptionHidden}
      setDescriptionHidden={setDescriptionHidden}
    />
  );
};

export default PartnerProfileWrapper;
