import DateFnsUtils from '@date-io/date-fns';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { object, func, bool } from 'prop-types';

import useStyles from '../../formStyle';

import { useTranslation } from 'react-i18next';

const ContestForm = ({
  contest,
  editing,
  adding,
  errors,
  handleAddEditContest,
  handleDeleteContest,
  handleTextFieldChange,
  closeForm,
  openForm,
  desktop,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['common', 'dialog']);
  return (
    <>
      {!editing && !adding && (
        <Button onClick={() => openForm('add')} variant="contained" color="primary">
          <Typography className={classes.saveExperienceButton}>{t('dialog:contest.addContest')}</Typography>
        </Button>
      )}
      {(adding || editing) && (
        <>
          <div className={classes.editInfoWrapper}>
            <Typography className={classes.editInfoTitle}>{t('dialog:contest.contestTitle')}</Typography>
            <hr className={classes.divider} />
            <TextField
              autoFocus
              className={classes.editInfoTextField}
              fullWidth
              variant="outlined"
              name="name"
              value={contest.name || ''}
              helperText={errors.name}
              error={!!errors.name}
              onChange={handleTextFieldChange}
            />
          </div>
          <div className={classes.editInfoWrapper}>
            <Grid container spacing={desktop ? 2 : 1}>
              <Grid item xs={desktop ? 8 : 12}>
                <Typography className={classes.editInfoTitle}>{t('dialog:contest.prizesAwards')}</Typography>
                <hr className={classes.divider} />
                <TextField
                  className={classes.editInfoTextField}
                  fullWidth
                  variant="outlined"
                  name="reward"
                  value={contest.reward || ''}
                  onChange={handleTextFieldChange}
                />
              </Grid>
              <Grid item xs={desktop ? 4 : 12}>
                <Typography className={classes.editInfoTitle}>{t('dialog:contest.year')}</Typography>
                <hr className={classes.divider} />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    className={classes.editInfoTextField}
                    variant="inline"
                    inputVariant="outlined"
                    fullWidth
                    InputAdornmentProps={{ position: 'end' }}
                    disableFuture
                    format="yyyy"
                    views={['year']}
                    name="year"
                    value={contest.year}
                    onChange={(e) => handleTextFieldChange(e, 'year')}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </div>

          <div className={classes.editInfoWrapper}>
            <Typography className={classes.editInfoTitle}>{t('dialog:contest.details')}</Typography>
            <hr className={classes.divider} />
            <TextField
              className={classes.editInfoTextField}
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              name="description"
              value={contest.description || ''}
              onChange={handleTextFieldChange}
            />
          </div>

          <div className={classes.editInfoWrapper}>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Typography className={classes.saveExperience} onClick={handleAddEditContest}>
                {!editing ? t('dialog:contest.addContest') : t('common:button.saveEdit')}
              </Typography>
              <Typography onClick={closeForm} className={classes.cancelExperience}>
                {t('common:button.cancelAndCollapse')}
              </Typography>
              {!adding && (
                <Typography className={classes.deleteExperience} onClick={() => handleDeleteContest(contest.id)}>
                  {t('dialog:contest.deleteContest')}
                </Typography>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

ContestForm.propTypes = {
  contest: object.isRequired,
  editing: bool.isRequired,
  adding: bool.isRequired,
  desktop: bool.isRequired,
  errors: object.isRequired,
  handleAddEditContest: func.isRequired,
  handleDeleteContest: func.isRequired,
  handleTextFieldChange: func.isRequired,
  openForm: func.isRequired,
  closeForm: func.isRequired,
};

export default ContestForm;
