import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  memberWrapper: {
    marginTop: '15px',
  },
  certifiedIcon: {
    color: '#fff',
    backgroundColor: '#FAC405',
    borderRadius: '50%',
    height: '16px',
    width: '16px',
    verticalAlign: 'middle',
    marginLeft: '5px',
  },
  memberTitle: {
    marginTop: '2%',
    marginBottom: theme.spacing(1),
    ...theme.typography.h1,
    color: '#575757',
    fontSize: '16px',
    fontWeight: '700',
  },
  memberSpace: {
    marginBottom: theme.spacing(1),
  },
  membersSpace: {
    marginBottom: theme.spacing(2),
  },
  statusStyle: {
    marginTop: '2%',
    marginBottom: theme.spacing(1),
    ...theme.typography.h1,
    color: '#575757',
    fontSize: '16px',
  },
  ownerStarStyle: {
    color: '#fff',
    backgroundColor: '#FAC405',
    borderRadius: '20px',
    width: '0.7em',
    height: '0.7em',
  },
}));

export default useStyles;
