import {
  ClickAwayListener,
  Icon,
  Link,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  makeStyles,
} from '@material-ui/core';
import { Add as AddIcon, Cancel as CancelIcon, ChevronLeft as ChevronLeftIcon } from '@material-ui/icons';
import { SpeedDial, SpeedDialAction } from '@material-ui/lab';
import { bool, func, number, object, string, oneOfType } from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import { DELETE_UPDATE } from 'apollo/mutations/update-mutation';
import { cookieUserId } from 'helpers/auth';
import { DIALOG } from 'helpers/constants';
import { handleOpenDialogAC3 } from 'reducers/testAC3/cache';

import FlagIcon from './FlagIcon';
import FlagIconPublished from './FlagIconPublished';
const boxWidth = 100;
const dialRadius = 15;
const spacingActions = 30;

const useStyles = (isSelected, isLast) =>
  makeStyles((theme) => ({
    defaultIcon: {
      color: 'transparent',
    },
    popper: { marginTop: theme.spacing(1) },
    list: {
      '& a': {
        ...theme.typography.h6,
        display: 'flex',
        alignItems: 'end',
        color: '#333',
        '& > div': {
          minWidth: '32px',
        },
        '&:hover': {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.common.white,
          fontWeight: theme.typography.fontWeightBold,
          textDecoration: 'none',
          '& > div': {
            color: theme.palette.common.white,
            '& > svg > path': {
              fill: theme.palette.common.white,
            },
          },
        },
      },
    },
    speedDialWrapper: {
      position: 'relative',
      zIndex: 2,
    },
    speedDial: {
      position: 'absolute',
      '&.MuiSpeedDial-directionUp': {
        bottom: -dialRadius,
        right: -spacingActions + boxWidth - dialRadius,

        '& .MuiSpeedDial-actions': {
          marginBottom: -spacingActions,
          paddingBottom: spacingActions,
          marginLeft: -dialRadius,
          paddingLeft: spacingActions + dialRadius,
          marginRight: -dialRadius,
          paddingRight: spacingActions + dialRadius,

          '& .MuiSpeedDialAction-fab': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.secondary.main,
            border: '2px solid #fff',
            margin: `${dialRadius / 2}px 0`,

            '&:nth-child(2)': {
              top: 82,
              right: -(spacingActions + dialRadius / 2),
            },

            '&:last-child': {
              top: 127,
              right: spacingActions + dialRadius / 2,
              backgroundColor: isLast && '#989898',
            },
          },
        },
      },
      '& button': {
        backgroundColor: isSelected ? theme.palette.primary.main : '#989898',
        border: isSelected && '2px solid #fff',
        width: dialRadius * 2,
        height: dialRadius * 2,
        minHeight: dialRadius * 2,
      },
    },
  }));

const actions = (anchorRef) => [
  {
    icon: <CancelIcon fontSize="small" />,
    name: 'Delete',
    operation: 'delete',
  },
  { icon: <AddIcon ref={anchorRef} />, name: 'Add', operation: 'add' },
  {
    icon: <ChevronLeftIcon />,
    name: 'Back',
    operation: 'back',
  },
];

const TimelineLog = ({
  projectId,
  log,
  logRef,
  buttonRef,
  index,
  isLast,
  isSelected,
  handleChangeLog,
  handleBackLog,
  handleStatusNewLog,
  isNewLog,
  logSelected,
  deleteNewLog,
  // handleDeleteUpdate,
  // isDisabled,
}) => {
  const classes = useStyles(isSelected, isLast)();

  const [isPublished, setIsPublished] = useState(false);

  useEffect(() => {
    if (log) {
      setIsPublished(log.isPublished);
    }
  }, [log]);

  const { id, name, membersId } = log;

  // SPEED DIAL
  let triggerBlur = true;
  const [openDial, setOpenDial] = useState(false);

  const handleOpenDial = (e) => {
    e.preventDefault();
    // if (!isNewLog || index !== 0) {
    handleChangeLog(index);
    if (index === logSelected) {
      e.stopPropagation();
      setOpenDial(true);
    }
    // }
  };

  const handleCloseDial = (force) => {
    if (force || !triggerBlur) {
      setOpenDial(false);
      triggerBlur = true;
    }
  };

  useEffect(() => {
    if (!isSelected) {
      triggerBlur = false;
      handleCloseDial();
    }
  }, [isSelected]);

  const handleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isNewLog) {
      deleteNewLog();
    } else if (+log.id === 0 && !isNewLog) {
      // openSnackbarError('This log is not allowed to be deleted!');
    } else {
      const options = {
        itemName: 'log',
        variables: {
          update: {
            id,
          },
        },
        mutation: DELETE_UPDATE,
        resultObjectName: 'deleteUpdate',
      };
      handleOpenDialogAC3(DIALOG.DELETE, options);
    }
  };

  // POPPER
  const [openPopper, setOpenPopper] = useState(false);
  const anchorRef = useRef(null);

  const handleToggleMenuAdd = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenPopper((prevOpen) => !prevOpen);
  };

  const handleCloseMenu = (event) => {
    if (anchorRef && anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenPopper(false);
  };

  const handleListKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      setOpenPopper(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpenPopper = useRef(openPopper);

  useEffect(() => {
    if (prevOpenPopper.current === true && openPopper === false) {
      anchorRef.current.focus();
    }
    prevOpenPopper.current = openPopper;
  }, [openPopper]);

  const onClickNewLog = (e) => {
    e.preventDefault();
    handleStatusNewLog(true);
    handleCloseMenu(e);
  };

  // TODO: uncomment later
  // const onClickNewPhase = e => {
  //   e.preventDefault();
  //   handleCloseMenu(e);
  // };

  const handleClick = (e, operation) => {
    e.preventDefault();
    e.stopPropagation();

    if (operation === 'delete') {
      handleDelete(e);
      handleCloseDial(true);
    } else if (operation === 'add') {
      handleToggleMenuAdd(e);
    } else if (!isLast) {
      handleBackLog(e, index + 1);
      handleCloseDial(true);
    }
  };

  return (
    <div ref={logRef} className={classes.speedDialWrapper}>
      <SpeedDial
        ariaLabel="timeline-log"
        className={classes.speedDial}
        hidden={false}
        icon={isPublished ? <FlagIconPublished /> : <Icon className={classes.defaultIcon}>add_circle</Icon>}
        onClick={handleOpenDial}
        onBlur={() => handleCloseDial(true)}
        open={openDial}
        ref={buttonRef}
      >
        {actions(anchorRef).map((action, i) => (
          <SpeedDialAction
            key={action.name}
            tooltipTitle={action.name}
            icon={action.icon}
            onMouseDown={(e) => handleClick(e, action.operation)}
            aria-controls={i === 1 && openPopper ? 'timeline-tag-add' : undefined}
            aria-haspopup={i === 1 ? 'true' : undefined}
          />
        ))}
      </SpeedDial>
      <Popper
        open={openPopper}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        className={classes.popper}
        placement="bottom-start"
      >
        {() => (
          <Paper>
            <ClickAwayListener onClickAway={handleCloseMenu}>
              <MenuList id="timeline-tag-add" onKeyDown={handleListKeyDown} className={classes.list}>
                <MenuItem component={Link} onClick={onClickNewLog}>
                  <ListItemIcon>
                    <FlagIcon />
                  </ListItemIcon>
                  Add log
                </MenuItem>
                {/* <MenuItem component={Link} onClick={onClickNewPhase}>
                  <ListItemIcon>
                    <PhaseIcon />
                  </ListItemIcon>
                  Add phase
                </MenuItem> */}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        )}
      </Popper>
    </div>
  );
};

TimelineLog.propTypes = {
  projectId: oneOfType([number, string]).isRequired,
  log: object.isRequired,
  logRef: func,
  buttonRef: func,
  index: number.isRequired,
  isLast: bool,
  isSelected: bool,
  handleChangeLog: func.isRequired,
  handleBackLog: func.isRequired,
  handleStatusNewLog: func.isRequired,
  isNewLog: bool.isRequired,
  logSelected: number.isRequired,
  deleteNewLog: func.isRequired,
  // handleDeleteUpdate: func.isRequired,
  // isDisabled: bool,
};

TimelineLog.defaultProps = {
  logRef: undefined,
  buttonRef: undefined,
  isLast: false,
  isSelected: false,
  // isDisabled: false,
};

export default TimelineLog;
