import { gql } from '@apollo/client';

// done
// updated
export const LOGIN = gql`
  mutation login($idToken: String!) {
    login(idToken: $idToken) {
      id
      avatarLink
    }
  }
`;

// done
// updated
export const LOGOUT = gql`
  mutation logout {
    logout {
      status
      description
    }
  }
`;
