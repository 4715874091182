import { Typography, Grid, makeStyles } from '@material-ui/core';

import IllustrationSvg from './IllustrationSvg';

import { useTranslation } from 'react-i18next';
const useStyles = makeStyles((theme) => ({
  fontWeight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  paperStyle: {
    backgroundColor: '#EBEBEB',
    height: 'auto',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: '6px',
  },
  fullSize: {
    width: '100%',
    height: '100%',
  },
  title: {
    ...theme.typography.h5,
    color: '#575757',
  },
  subTitle: {
    ...theme.typography.h5,
    color: '#989898',
  },
  content: {
    color: '#575757',
    textAlign: 'center',
  },
}));

const TaskContent = () => {
  const classes = useStyles();
  const { t } = useTranslation('panel');
  return (
    <div className={classes.fullSize}>
      <Grid container direction="column" justify="flex-start" alignItems="center">
        <Grid item xs={12} container direction="row">
          <Grid item xs={8} container direction="row" justify="flex-start" alignItems="center">
            <Typography className={classes.title}>{t('allCompletedTask')}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <IllustrationSvg />
        </Grid>
        <Grid item xs={12} container direction="row" justify="center" alignItems="center">
          <Typography className={classes.subTitle}>{t('noCompletedTask')}</Typography>
        </Grid>
        <Grid item xs={12} container direction="row" justify="center" alignItems="center">
          <Typography variant="body1" color="textSecondary" className={classes.content} gutterBottom>
            {t('taskContentDescription.first') + ' '} <strong>{t('taskContentDescription.second') + ' '}</strong>
            {t('taskContentDescription.third') + ' '} <strong>{t('taskContentDescription.fourth') + ' '}</strong>.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

TaskContent.propTypes = {};

export default TaskContent;
