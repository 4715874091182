import { Avatar, Button, Typography, Dialog, DialogContent, Box, makeStyles } from '@material-ui/core';
import { object, bool, func } from 'prop-types';

import { CocoonEditor, CocoonAvatarGroup } from 'components';
import { DIALOG } from 'helpers/constants';
import { handleOpenDialogAC3 } from 'reducers/testAC3/cache';
import { getDateFromISO } from 'helpers/date';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      minWidth: 720,
      [theme.breakpoints.down('sm')]: {
        minWidth: 340,
        maxHeight: 540,
      },
    },
    '& .MuiDialogContent-root': {
      padding: '0px 0px',
    },
  },
  title: {
    textAlign: 'left',
    fontWeight: 700,
    marginBottom: 12,
  },
  contestInfoWrapper: {
    columnGap: 40,
  },
  contestInfoTitle: {
    paddingBottom: 7,
    color: '#616161',
  },
  fixedHeight: {
    height: 40,
    columnGap: 7,
  },
  button: {
    padding: '25px 16px',
  },
}));

const MIN_HEIGHT = 300;
const MIN_HEIGHT_MOBILE = 120;

function ContestInfoCard({ open, options, desktop, handleCloseDialog }) {
  const classes = useStyles();
  const contest = options;
  const { creator, sponsors } = contest;

  function handleOpenSubmitProject(e) {
    e.preventDefault();
    handleOpenDialogAC3(DIALOG.SUBMIT_PROJECTS_TO_CONTEST, contest);
  }

  const submissionDate = contest.contestDeadline
    ? getDateFromISO(contest.contestDeadline, { showSeconds: false })
    : 'TBD';

  return (
    open && (
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="contest-info-card-title"
        aria-describedby="contest-info-card"
        className={classes.dialog}
        maxWidth="md"
      >
        <DialogContent>
          {contest.assetLink && (
            <Box
              width="100%"
              style={{
                justifyContent: desktop ? 'center' : 'initial',
                backgroundImage: `url(${contest.assetLink})`,
                backgroundSize: 'cover',
                backgroundColor: '#000',
                minHeight: desktop ? MIN_HEIGHT : MIN_HEIGHT_MOBILE,
              }}
            />
          )}
          <Box padding="25px 16px">
            <Typography variant="h3" align="center" className={classes.title}>
              {contest.name}
            </Typography>
            <Box
              className={classes.contestInfoWrapper}
              marginBottom="16px"
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
            >
              <Box>
                <Typography className={classes.contestInfoTitle} variant="h6">
                  Created by
                </Typography>
                <Box className={classes.fixedHeight} display="flex" flexDirection="row" alignItems="center">
                  <Avatar alt="contest-owner-avatar-info" src={creator.avatarLink} />
                  <Typography variant="h6">{creator.partnerName}</Typography>
                </Box>
              </Box>
              <Box>
                <Typography className={classes.contestInfoTitle} variant="h6">
                  Submission close
                </Typography>
                <Box className={classes.fixedHeight} display="flex" flexDirection="row" alignItems="center">
                  <Typography variant="h6">{submissionDate}</Typography>
                </Box>
              </Box>
              <Box>
                <Typography className={classes.contestInfoTitle} variant="h6">
                  Sponsors
                </Typography>
                <Box className={classes.fixedHeight} display="flex" flexDirection="row" alignItems="center">
                  <CocoonAvatarGroup avatars={sponsors} max={4} />
                </Box>
              </Box>
            </Box>
            <Typography className={classes.currentTabTitle} variant="h5">
              Rules & Description
            </Typography>
            <hr />
            <CocoonEditor data={contest.contestOverview} />
          </Box>
          <Box className={classes.button}>
            <Button onClick={handleOpenSubmitProject} color="primary" variant="contained" fullWidth>
              Submit Projects
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    )
  );
}

ContestInfoCard.propTypes = {
  open: bool.isRequired,
  desktop: bool.isRequired,
  options: object.isRequired,
  handleCloseDialog: func.isRequired,
};

export default ContestInfoCard;
