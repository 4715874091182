import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  appBar: {
    backgroundColor: '#F5F5F5',
    boxShadow: 'none',
    zIndex: 1,
    padding: '0px 48px',
  },
  sectionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '80px',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  ownerInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginBottom: '5px',
  },
  contestOwnerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ownerText: {
    color: '#989898',
    fontWeight: 900,
  },
  ownerAvatar: {
    marginRight: '10px',
    width: '30px',
    height: '30px',
  },
}));

export default useStyles;
