import { Button, Grid, Typography, makeStyles } from '@material-ui/core';
import { func, number, string } from 'prop-types';

import { isSameUser } from 'helpers/auth';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  btnEdit: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
}));

const SectionHeader = ({ ownerId, sectionName, handleEdit }) => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <Grid container justify="space-between" alignItems="center">
      <Typography variant="h6" color="textSecondary" gutterBottom>
        {sectionName}
      </Typography>
      {isSameUser(ownerId) && (
        <Button color="primary" className={classes.btnEdit} onClick={handleEdit}>
          {t('button.edit')}
        </Button>
      )}
    </Grid>
  );
};

SectionHeader.propTypes = {
  handleEdit: func,
  ownerId: number,
  sectionName: string,
};

export default SectionHeader;
