import { IconButton, Box, Button, Typography, makeStyles } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import { bool, func, object } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { formatDate_L } from 'helpers';

const useStyles = makeStyles((theme) => ({
  infoWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
  },
  infoTitle: {
    ...theme.typography.h1,
    color: '#989898',
    fontSize: '16px',
    fontWeight: '900',
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.h6,
      display: 'inline-block',
      fontSize: 13,
      minWidth: 90,
    },
  },
  info: {
    marginTop: '10px',
    ...theme.typography.h1,
    color: '#1E6BAB',
    fontSize: '16px',
    fontWeight: '700',
    [theme.breakpoints.down('sm')]: {
      display: 'inline-block',
      fontSize: 13,
    },
  },
  divider: {
    margin: '2% 0 2% 0',
    backgroundColor: '#989898',
    border: 'solid 0.5px #e6e6e6',
  },
  editInfoWrapper: {
    marginTop: '15px',
  },
  editInfoTitle: {
    marginTop: '2%',
    ...theme.typography.h1,
    color: '#575757',
    fontSize: '16px',
    fontWeight: '700',
  },
  button: {
    borderColor: theme.palette.secondary.main,
    padding: theme.spacing(0.25, 1.5),
    marginLeft: theme.spacing(1),
    textTransform: 'none',
    borderRadius: '6px',
    borderWidth: '1px',
    [theme.breakpoints.down('sm')]: {
      padding: '7px 8px 4px',
      fontSize: 12,
    },
  },
}));

const ProfileInfo = ({ editProfile, setEditMore, desktop }) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const renderLocation = () => {
    if (!editProfile.country) {
      return 'N/A';
    }
    return editProfile.state ? `${editProfile.state.name}, ${editProfile.country.name}` : editProfile.country.name;
  };

  return (
    <div className={classes.infoWrapper}>
      <div>
        <Typography className={classes.infoTitle}>{t('common.firstName')}</Typography>
        <Typography className={classes.info}>{editProfile.firstName}</Typography>
      </div>
      <div>
        <Typography className={classes.infoTitle}>{t('common.lastName')}</Typography>
        <Typography className={classes.info}>{editProfile.lastName}</Typography>
      </div>
      <div>
        <Typography className={classes.infoTitle}>{t('common.gender')}</Typography>
        <Typography className={classes.info}>{editProfile.gender ? editProfile.gender : 'N/A'}</Typography>
      </div>
      <div>
        <Typography className={classes.infoTitle}>{t('common.dob')}</Typography>
        <Typography className={classes.info}>{editProfile.dob ? formatDate_L(editProfile.dob) : 'N/A'}</Typography>
      </div>
      <div>
        <Typography className={classes.infoTitle}>{t('common.currentlyAt')}</Typography>
        <Typography className={classes.info}>{renderLocation()}</Typography>
      </div>
      {desktop ? (
        <div>
          <IconButton onClick={() => setEditMore(true)}>
            <EditIcon color="secondary" />
          </IconButton>
        </div>
      ) : (
        <Box textAlign="right" mt={1}>
          <Button
            color="secondary"
            onClick={() => setEditMore(true)}
            variant="outlined"
            className={classes.button}
            startIcon={<EditIcon color="secondary" />}
          >
            {t('button.edit')}
          </Button>
        </Box>
      )}
    </div>
  );
};

ProfileInfo.propTypes = {
  editProfile: object.isRequired,
  setEditMore: func.isRequired,
  desktop: bool.isRequired,
};

export default ProfileInfo;
