import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  backgroundWrapper: {
    minHeight: '420px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'column',
  },
  certifiedIcon: {
    color: '#fff',
    backgroundColor: '#FAC405',
    borderRadius: '50%',
    width: '1.4em',
    height: '1.4em',
    marginLeft: '0.4em',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    minHeight: 'inherit',
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionButtons: {
    position: 'absolute',
    bottom: '5%',
    right: '5%',
  },
  actionButtonsMobile: {
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-around',
      padding: theme.spacing(1.5, 1.5),
    },
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.6)',
  },
  landingName: {
    fontWeight: 700,
    color: theme.palette.common.white,
    padding: theme.spacing(1, 1, 0.5, 1),
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      padding: 0,
      lineHeight: '30px',
    },
  },
  primaryJob: {
    ...theme.typography.body1,
    color: '#FAC405',
    fontSize: '16px',
    fontWeight: 900,
    lineHeight: '30px',
    marginBottom: '2%',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      fontWeight: 700,
      fontSize: 13,
      marginBottom: 0,
      lineHeight: '24px',
    },
  },
  fieldsOfWorkWrapper: {
    marginBottom: '2%',
  },
  description: {
    ...theme.typography.body1,
    lineHeight: '30px',
    fontSize: '20px',
    color: theme.palette.common.white,
    '& textarea': {
      height: '5em',
      width: '100%',
    },
    maxWidth: '60%',
  },
  location: {
    ...theme.typography.h6,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.common.white,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      fontWeight: 700,
      fontSize: 13,
    },
  },
  button: {
    '&:not(.disabled)': {
      color: theme.palette.common.white,
      [theme.breakpoints.down('sm')]: {
        color: '#575757',
      },
    },
    '&.Mui-disabled': {
      color: '#989898',
    },
    borderColor: theme.palette.common.white,
    marginRight: theme.spacing(2),
    textTransform: 'none',
    borderRadius: '6px',
    borderWidth: '1px',
    '&:hover': {
      background: 'transparent',
      borderWidth: '2px',
      '& *': {
        fontWeight: 'bold',
      },
    },
    color: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.white}`,
    padding: theme.spacing(0.25, 1.5),
    [theme.breakpoints.down('sm')]: {
      '& *': { fontSize: 13, color: '#575757' },
      color: '#575757',
      borderColor: '#575757',
      padding: theme.spacing(0.5, 1),
      marginLeft: theme.spacing(0),
      minWidth: 120,
      marginRight: 0,
    },
  },
  fileInput: {
    display: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  userInfo: {
    position: 'absolute',
    zIndex: 3,
    width: '100%',
    bottom: 0,
    paddingBottom: '5%',

    backgroundColor: 'rgba(0,0,0,0.6)',
    '& > button': {
      color: theme.palette.common.white,
      borderRadius: 0,
      '& > span': {
        justifyContent: 'left',
        fontWeight: 400,
      },
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(0),
      '& > div:first-child > div:nth-child(2)': {
        paddingTop: theme.spacing(1),
        alignItems: 'center',
      },
    },
  },
}));

export default useStyles;
