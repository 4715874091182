import { Box, Link, Typography, TextField } from '@material-ui/core';
import { object, func } from 'prop-types';

import useStyles from './jobAndBioStyle';

import { useTranslation } from 'react-i18next';

const JobAndBio = ({ register }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <div className={classes.editInfoWrapper}>
        <Typography className={classes.editInfoTitle}>{t('common.titleJobPosition')}</Typography>
        <hr className={classes.divider} />
        <TextField
          className={classes.editInfoTextField}
          fullWidth
          variant="outlined"
          name="primaryJob"
          inputRef={register}
          placeholder="Add primary job"
        />
      </div>
      <div className={classes.editInfoWrapper}>
        <Typography className={classes.editInfoTitle}>{t('common.personalStatement')}</Typography>
        <hr className={classes.divider} />
        <TextField
          fullWidth
          multiline
          rows={4}
          name="description"
          inputRef={register}
          placeholder="Add personal statement"
          InputProps={{ disableUnderline: true }}
        />
      </div>
      <div className={classes.editInfoWrapper}>
        <Typography className={classes.editInfoTitle}>{t('common.description')}</Typography>
        <hr className={classes.divider} />
        <TextField
          fullWidth
          multiline
          rows={4}
          name="fullBio"
          inputRef={register}
          placeholder="Add description"
          InputProps={{ disableUnderline: true }}
        />
        <Box mt={2} borderTop={1} pt={1} borderColor="grey.200" fontStyle="italic" color="grey.400">
          <Link target="_blank" href="https://www.markdownguide.org/cheat-sheet/">
            {t('project.markDown')}
          </Link>{' '}
          {t('project.isSupported')}
        </Box>
      </div>
    </>
  );
};

JobAndBio.propTypes = {};
export default JobAndBio;
