export const CRUD_STATUS = {
  VIEW: 0,
  VIEW_UPDATED: 1,
  NEW: 2,
  EDIT: 3,
};

export const PROJECT_DEFAULT = {
  name: '',
  description: '',
  assetLink: '',
  projectOverview: '',
  owner: {},
  tags: [],
  members: [],
  attachments: null,
};
