import { Tabs, Tab, Grid, Avatar, makeStyles, withStyles } from '@material-ui/core';
import { number, func, object } from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import logo from '@images/logo/logo.svg';
import { convertToSlug } from 'helpers/common';
import { getValue, KEYS } from 'helpers/localStorage';
import { getCurrentUser } from 'reducers/authReducer';

import NavigationSvg from './components/NavigationSvg';

const useStyles = makeStyles((theme) => ({
  navBar: {
    width: '75px',
    height: '100%',
    position: 'fixed',
    backgroundColor: theme.palette.common.white,
    zIndex: 1300,
  },
}));

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    '& > div': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#FAC405',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#989898',
    marginTop: theme.spacing(1.3),
    fontWeight: theme.typography.fontWeightBold,
    minWidth: '75px',
    minHeight: '31px',
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const a11yProps = (index) => ({
  id: `panel-tab-${index}`,
  'aria-controls': `panel-tabpanel-${index}`,
});

const NavigationBar = ({
  project,
  navigationTab,
  handleChangeNavigationTab,
  // handleToggleDrawer,
}) => {
  const classes = useStyles();

  const currentUser = useSelector(getCurrentUser);

  // const match = useRouteMatch();

  return (
    <Grid item xs container direction="column" justify="flex-start" alignItems="center" className={classes.navBar}>
      <Grid item xs={1} container alignItems="center" justify="center">
        <Link to={`/projects/${project.id}/${convertToSlug(project.name)}`}>
          <Avatar src={logo} alt="Cocoon Logo" />
        </Link>
      </Grid>
      <Grid item xs container direction="column" justify="space-between" alignItems="center">
        <Grid item xs>
          <StyledTabs
            orientation="vertical"
            value={navigationTab}
            onChange={handleChangeNavigationTab}
            textColor="secondary"
            aria-label="project tabs"
          >
            <StyledTab icon={<NavigationSvg />} {...a11yProps(0)} />
            {/* <StyledTab
              icon={<NotificationsNoneIcon size="small" color="primary" />}
              {...a11yProps(1)}
            />
            <StyledTab
              icon={
                <ChatBubbleOutlineRoundedIcon size="small" color="primary" />
              }
              {...a11yProps(2)}
            /> */}
          </StyledTabs>
        </Grid>
        <Grid item xs={3} container direction="column" justify="flex-end" alignItems="center">
          {/* <Grid item xs container alignItems="center">
            <Link to={`/projects/${project.id}/${convertToSlug(project.name)}`}>
              <SettingSvg />
            </Link>
          </Grid>
          <Grid item xs container alignItems="center">
            <Link to={`/projects/${project.id}/${convertToSlug(project.name)}`}>
              <InfoSvg />
            </Link>
          </Grid>  */}
          <Grid item xs={6} container justify="center" alignItems="center">
            <Link
              to={`/users/${currentUser.id}/${convertToSlug(
                `${currentUser.firstName} ${currentUser.lastName}`,
              )}/profile`}
            >
              <Avatar src={currentUser.avatarLink || getValue(KEYS.avatarLink)} alt="User Avatar" />
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

NavigationBar.propTypes = {
  project: object.isRequired,
  navigationTab: number.isRequired,
  handleChangeNavigationTab: func.isRequired,
};

export default NavigationBar;
