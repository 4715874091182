import { useTranslation } from 'react-i18next';

import { Title } from 'components';

const UnauthorizedErrorPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Title level={3}>{t('error.unauthorizedResponse')}</Title>
    </>
  );
};

export default UnauthorizedErrorPage;
