import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { GET_CONTESTS_BY_PARTNER_ID } from 'apollo/queries/contest-query';
import { GET_LIST_OF_USER } from 'apollo/queries/list-query';
import { GET_PROJECTS_BY_USER_ID, GET_PROJECTS_SUBSCRIPTIONS } from 'apollo/queries/project-query';
import { cookieUserId } from 'helpers/auth';
import { USER_TABS, PARTNER_TABS, QUERY_TABS } from 'helpers/constants';

const { USER_INFO, PERSONAL_PROJECTS, FOLLOWED_PROJECTS, ARCHIVED_PROJECTS, LISTS_WITH_PROJECTS } = USER_TABS;
const { PARTNER_INFO, PARTNER_PROJECTS, SPONSORED_PARTNER_PROJECTS, PARTNER_CONTESTS } = PARTNER_TABS;

export default function useFetchProjectsByTab(tab) {
  const { id } = useParams();
  const userId = id || cookieUserId;

  let variables = { userId, page: 1, limit: 'FIVE_O', isArchived: false };

  // TODO: refactor for partner ?
  // USER_INFO and PARTNER_INFO will be defaulted to personal projects query
  const Queries = {
    [USER_INFO]: [GET_PROJECTS_BY_USER_ID, { variables }],
    [PERSONAL_PROJECTS]: [GET_PROJECTS_BY_USER_ID, { variables }],
    [FOLLOWED_PROJECTS]: [GET_PROJECTS_SUBSCRIPTIONS, { variables }],
    [ARCHIVED_PROJECTS]: [GET_PROJECTS_BY_USER_ID, { variables: { ...variables, isArchived: true } }],
    [LISTS_WITH_PROJECTS]: [GET_LIST_OF_USER, { variables: { ...variables, ownerId: userId } }],

    [PARTNER_INFO]: [GET_PROJECTS_BY_USER_ID, { variables }],
    [PARTNER_PROJECTS]: [GET_PROJECTS_BY_USER_ID, { variables }],
    [SPONSORED_PARTNER_PROJECTS]: [GET_PROJECTS_BY_USER_ID, { variables }],
    [PARTNER_CONTESTS]: [
      GET_CONTESTS_BY_PARTNER_ID,
      { variables: { partnerId: variables.userId, page: 1, limit: 'FIVE_O' } },
    ],
  };

  const queryTab = Queries[tab];
  const [query, { data, loading, error }] = useLazyQuery(queryTab[0], queryTab[1]);

  useEffect(() => {
    if (QUERY_TABS.includes(tab)) {
      query();
    }
  }, [tab]);

  return [data, loading, error, { refetch: query }];
}
