import { gql } from '@apollo/client';

// done
// updated
export const CONTACT_EMAIL = gql`
  mutation sendContact($message: ContactMessage!) {
    sendContact(message: $message) {
      description
      status
    }
  }
`;
