import { Grid, Box, Button, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import { arrayOf, object, bool } from 'prop-types';
import { useDispatch } from 'react-redux';

import { Image, CircularCenterLoader } from 'components';
import { DIALOG } from 'helpers/constants';
import { openDialog } from 'reducers/dialogReducer';
import { openLightbox } from 'reducers/lightboxReducer';
import { handleOpenDialogAC3 } from 'reducers/testAC3/cache';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  img: {
    '& > div': { backgroundColor: 'rgba(0,0,0,0.1)' },
  },
  btnFluid: {
    color: '#575757',
    borderColor: '#575757',
    borderRadius: '6px',
    borderWidth: '1px',
    width: '100%',
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(0.25, 1.5),
    textTransform: 'none',
    '&:hover': {
      background: 'transparent',
      '& *': {
        fontWeight: 'bold',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& *': { fontSize: 13 },
      color: '#575757',
      borderColor: '#575757',
      padding: theme.spacing(0.5, 1),
      marginLeft: theme.spacing(0),
      minWidth: 98,
    },
  },
  text: {
    fontWeight: theme.typography.fontWeightBold,
    color: '#575757',
  },
}));

const BriefGallery = ({ images, loadingAttachments, errorAttachments, isNewProject, desktop }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const handleOpenLightbox = (images, index) => dispatch(openLightbox(images, index));

  const handleImageZoom = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    handleOpenLightbox(images, index);
  };

  const { t } = useTranslation();

  const handleOpenGallery = (e) => {
    e.preventDefault();
    const options = {
      title: t('common.gallery'),
      list: images,
      isGallery: true,
      handleOnClickItem: handleImageZoom,
    };
    handleOpenDialogAC3(DIALOG.LIST, options);
  };

  const renderGallery = () =>
    _.isEmpty(images) && !loadingAttachments ? (
      <Box className={classes.text}>{t('defaultInformation.noImages')}</Box>
    ) : (
      <>
        <Grid container spacing={desktop ? 2 : 1}>
          {_.chain(images)
            .take(6)
            .map((image, index) => (
              <Grid key={index} item xs={desktop ? 6 : 4} component={Box}>
                <Box borderRadius={6} className={classes.img}>
                  <Image
                    src={image.assetLink}
                    alt={image.name}
                    onClick={(e) => handleImageZoom(e, index)}
                    onZoomCLick={(e) => handleImageZoom(e, index)}
                    hasZoom
                    hasDownload
                    isHoverable
                    aspectRatio={1}
                  />
                </Box>
              </Grid>
            ))
            .value()}
        </Grid>
        <Box textAlign="center" mt={2}>
          <Button variant="outlined" className={classes.btnFluid} onClick={handleOpenGallery}>
            {t('button.viewGallery')}
          </Button>
        </Box>
      </>
    );

  return (
    <>
      {!isNewProject && images === null ? <CircularCenterLoader /> : renderGallery()}
      {errorAttachments && (
        <Alert variant="outlined" severity="error">
          {errorAttachments.message}
        </Alert>
      )}
    </>
  );
};

BriefGallery.propTypes = {
  images: arrayOf(object),
  loadingAttachments: bool,
  errorAttachments: object,
  isNewProject: bool.isRequired,
  desktop: bool.isRequired,
};

BriefGallery.defaultProps = {
  images: null,
  loadingAttachments: false,
  errorAttachments: undefined,
};

export default BriefGallery;
