import { Box, Button, Grid, Typography, Checkbox, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { arrayOf, object, func, number } from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Image from 'components/image/Image';
import { getImg } from 'helpers/common';

const useStyles = makeStyles((theme) => ({
  overlayContainer: {
    backgroundColor: 'rgba(0,0,0,0.5) !important',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  },
  img: {
    position: 'relative',
    '& > div': {
      backgroundColor: 'rgba(0,0,0,0.1)',
      borderRadius: 6,
    },
  },
  listItem: {
    width: '100%',
    position: 'relative',
    height: 315,
    marginBottom: 30,
  },
  listGalleryCheckbox: {
    position: 'absolute',
    zIndex: 99,
  },
  listItemTitle: {
    ...theme.typography.h3,
    fontSize: 18,
    color: '#000',
  },
}));

const ListOverlay = () => {
  const classes = useStyles();
  return <div className={classes.overlayContainer}></div>;
};

const ListGallery = ({ lists, handleContinueOrSave, handleCheckList, selectedListIds }) => {
  const [mouseOver, setMouseOver] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);

  const { t } = useTranslation('dialog');

  const handleMouseOver = (index) => {
    setMouseOver(true);
    setCurrentIndex(index);
  };

  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      {lists.map((item, index) => (
        <Grid
          key={+item.id}
          item
          xs={6}
          className={classes.listItem}
          onFocus={() => handleMouseOver(index)}
          onMouseOver={() => handleMouseOver(index)}
          onMouseOut={() => setMouseOver(false)}
        >
          <Checkbox
            className={classes.listGalleryCheckbox}
            inputProps={{ 'aria-label': 'primary checkbox' }}
            onChange={() => handleCheckList(+item.id)}
            checked={selectedListIds.includes(+item.id)}
          />
          <Box borderRadius={6} className={classes.img}>
            <Image
              alt={item.name}
              src={getImg(!_.isEmpty(item.projects) && item.projects[0].assetLink)}
              aspectRatio={1}
              onClick={() => handleCheckList(+item.id)}
            />
            {mouseOver && index === currentIndex && <ListOverlay />}
          </Box>
          <Box mt={1} component={Typography} variant="h5" align="center" gutterBottom>
            {item.name}
          </Box>
        </Grid>
      ))}
      <Grid item xs={6} className={classes.listItem}>
        <Button
          style={{ width: '100%', height: '100%' }}
          variant="outlined"
          onClick={() => handleContinueOrSave('add')}
        >
          <Typography className={classes.newListTitle}>{t('listProjects.newListPlus')}</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

ListGallery.defaultProps = {
  selectedListIds: [],
};

ListGallery.propTypes = {
  lists: arrayOf(object).isRequired,
  handleContinueOrSave: func.isRequired,
  handleCheckList: func.isRequired,
  selectedListIds: arrayOf(number),
};

export default ListGallery;
