import { object, number, func, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CRUD_STATUS } from '../../../../../../helpers/constants';
import CreateProjectTutorial from '../../../../components/section/CreateProjectTutorial';
import ProjectOverviewContent from '../../../../components/section/ProjectOverviewContent';
import TabPanelSection from '../../../../components/section/TabPanelSection';

const BriefRightPanel = ({ project, control, crudStatus, desktop, isView }) => {
  const { t } = useTranslation(['dashboard', 'common']);

  return (
    <>
      {!isView ? (
        <>
          <TabPanelSection
            title={t('common:common.projectOverview')}
            placeholder={t('common:project.contentDefault')}
            isInput
            escapeHtml={false}
            desktop={desktop}
            control={control}
          />
          {crudStatus === CRUD_STATUS.NEW && <CreateProjectTutorial desktop={desktop} control={control} />}
        </>
      ) : (
        <ProjectOverviewContent
          project={project}
          control={control}
          title={t('common:common.projectOverview')}
          emptyText={t('common:defaultInformation.noOverview')}
        />
      )}
    </>
  );
};

BriefRightPanel.propTypes = {
  crudStatus: number.isRequired,
  desktop: bool.isRequired,
  isView: bool.isRequired,
};

export default BriefRightPanel;
