import { Switch as MUISwitch, Typography, Box, makeStyles } from '@material-ui/core';
import { bool, string, func, shape } from 'prop-types';

const styles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function Switch({ value, onChange, save, inputRef, activeText, inactiveText, isInverted }) {
  const classes = styles();
  // value is isArchived: isArchived = false -> Active; isArchived = true -> Archived
  return (
    <Box className={classes.wrapper}>
      <Typography>{value ? activeText : inactiveText}</Typography>
      <MUISwitch
        color="primary"
        checked={isInverted ? !value : value}
        onChange={(event, value) => {
          if (isInverted) {
            if (!value) {
              onChange(true);
            } else {
              onChange(false);
            }
          } else {
            onChange(value);
          }
          save();
        }}
        inputRef={inputRef}
      />
    </Box>
  );
}

Switch.propTypes = {
  value: bool.isRequired,
  onChange: func.isRequired,
  save: func.isRequired,
  isInverted: bool,
  inputRef: shape({
    current: shape({}),
  }),
  activeText: string.isRequired,
  inactiveText: string.isRequired,
};

Switch.defaultProps = {
  isInverted: false,
};

export default Switch;
