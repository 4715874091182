import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import { bool, object } from 'prop-types';
import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useUnload } from 'hooks';

import LogsLeftPanel from './components/left/LogsLeftPanel';
import LogsRightPanel from './components/right/LogsRightPanel';

const useStyles = makeStyles((theme) => ({
  fullHeight: {
    height: '100%',
  },
  leftPanel: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 1.5, 2),
      // margin: theme.spacing(0, 1, 2, 1),
      // padding: theme.spacing(2, 1),
      // border: '1px solid #575757',
      // borderRadius: 6,
    },
  },
  rightPanel: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 1.5, 2),
    },
  },
}));

const LogsTabPanel = ({ logsLeftProps, logsRightProps, desktop }) => {
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();

  useUnload(() => {
    history.push({ state: { clicked: false } });
  });

  const [logIdsSelected, setLogIdsSelected] = useState([]);

  const handleToggleLogIdSelected = (logId) => {
    if (logId !== null) {
      const newLogIds = [...logIdsSelected];
      if (logIdsSelected.includes(logId)) {
        _.remove(newLogIds, (id) => +id === +logId);
      } else {
        newLogIds.push(+logId);
      }
      setLogIdsSelected(newLogIds);
    }
  };

  const resetLogIdSelected = (e) => {
    e.preventDefault();
    setLogIdsSelected([]);
  };

  const after = (str, substr) => str.slice(str.indexOf(substr) + substr.length, str.length);

  useEffect(() => {
    if (!location.state && after(location.hash, '&name=') !== 'logs') {
      const logSlugArr = after(location.hash, '&name=').split('-');
      handleToggleLogIdSelected(+logSlugArr.pop());
    }
  }, []);

  return (
    <>
      <Grid item xs={desktop ? 5 : 12} className={clsx(classes.fullHeight, classes.leftPanel)}>
        <LogsLeftPanel
          {...logsLeftProps}
          logIdsSelected={logIdsSelected}
          handleToggleLogIdSelected={handleToggleLogIdSelected}
          resetLogIdsSelected={resetLogIdSelected}
          desktop={desktop}
        />
      </Grid>
      <Grid item xs={desktop ? 7 : 12} className={clsx(classes.fullHeight, classes.rightPanel)}>
        <LogsRightPanel
          {...logsRightProps}
          logIdsSelected={logIdsSelected}
          canFetchMore={_.isEmpty(logIdsSelected) ? logsRightProps.canFetchMore : false}
          desktop={desktop}
        />
      </Grid>
    </>
  );
};

LogsTabPanel.propTypes = {
  logsLeftProps: object.isRequired,
  logsRightProps: object.isRequired,
  desktop: bool.isRequired,
};

export default LogsTabPanel;
