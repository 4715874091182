import { useApolloClient } from '@apollo/client';
import { List, ListItem, ListItemIcon, ListItemText, Popover, Typography, makeStyles } from '@material-ui/core';
import {
  BookmarkBorder as BookmarkBorderIcon,
  MoreHoriz as MoreHorizIcon,
  PowerSettingsNew as PowerSettingsNewIcon,
} from '@material-ui/icons';
import { object, string, bool } from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { GET_LIST_OF_USER } from 'apollo/queries/list-query';
import { SameUserAlt } from 'components/auth/UserAuthorization';
import { cookieUserId } from 'helpers/auth';
import { DIALOG, USER_TABS } from 'helpers/constants';
import { handleOpenDialogAC3 } from 'reducers/testAC3/cache';

const useStyles = makeStyles((theme) => ({
  listItemRow: {
    display: 'flex',
    alignItems: 'center',
    verticalAlign: 'center',
    marginBottom: 10,
  },
  regularIcon: {
    fontSize: '1.8em',
  },
  smallIcon: {
    fontSize: '1.5em',
  },
  listItemTitle: {
    ...theme.typography.h3,
    fontSize: 18,
    color: '#000',
  },
}));

const ProjectPopover = ({ desktop, project, currentTab }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const match = useRouteMatch();
  const { id } = match.params;
  const userId = parseInt(id, 10) || cookieUserId;
  const client = useApolloClient();
  const { t } = useTranslation();

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const triggerAddToListModal = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
    const listQuery = await client.query({
      query: GET_LIST_OF_USER,
      variables: { ownerId: userId },
    });
    const {
      data: { listsByOwnerId },
    } = listQuery;
    const options = {
      list: listsByOwnerId,
      projectId: +project.id,
      ownerId: +userId,
    };
    handleOpenDialogAC3(DIALOG.LIST_PROJECT, options);
  };

  const openArchiveModal = (e, isReactivate) => {
    e.preventDefault();
    let options;
    if (isReactivate) {
      options = {
        project: { id: +project.id, name: project.name, ownerId: +userId },
        isReactivate,
      };
    } else {
      options = {
        project: { id: +project.id, name: project.name, ownerId: +userId },
      };
    }

    handleOpenDialogAC3(DIALOG.DEACTIVATE, options);
    handleClose(e);
  };
  // TODO: uncomment later
  // const handleSetPrivacy = e => {
  //   e.preventDefault();
  //   const options = {
  //     project: { id: project.id, name: project.name, ownerId: userId },
  //   };
  //   handleOpenDialogAC3(DIALOG.PRIVACY, options);
  //   handleClose(e);
  // };

  const open = Boolean(anchorEl);
  const popoverOverId = open ? 'project-popover' : undefined;

  return (
    <div>
      <MoreHorizIcon
        style={{ fill: '#ffffff', cursor: 'pointer' }}
        className={desktop ? classes.regularIcon : classes.smallIcon}
        aria-describedby={popoverOverId}
        onClick={handleClick}
      />
      <Popover
        id={popoverOverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List>
          {SameUserAlt(userId)(
            <div>
              <ListItem button onClick={triggerAddToListModal}>
                <ListItemIcon style={{ minWidth: 40 }}>
                  <BookmarkBorderIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText onClick={(e) => triggerAddToListModal(e)} disableTypography>
                  <Typography className={classes.listItemTitle}>{t('common.addToLists')}</Typography>
                </ListItemText>
              </ListItem>
              {currentTab !== USER_TABS.FOLLOWED_PROJECTS && (
                <>
                  {/* TO DO: uncomment later */}
                  {/* <ListItem button onClick={handleSetPrivacy}>
                    <ListItemIcon style={{ minWidth: 40 }}>
                      <LockOpenIcon className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText disableTypography>
                      <Typography className={classes.listItemTitle}>
                        Set Privacy
                      </Typography>
                    </ListItemText>
                  </ListItem> */}
                  <ListItem button onClick={(e) => openArchiveModal(e, project.isArchived)}>
                    <ListItemIcon style={{ minWidth: 40 }}>
                      <PowerSettingsNewIcon className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText disableTypography>
                      <Typography className={classes.listItemTitle}>
                        {project.isArchived ? t('common.reactivate') : t('common.deactivate')}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </>
              )}
            </div>,
            <div>
              <ListItem button>
                <ListItemIcon style={{ minWidth: 40 }}>
                  <BookmarkBorderIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText disableTypography>
                  <Typography className={classes.listItemTitle}>{t('common.addToLists')}</Typography>
                </ListItemText>
              </ListItem>
            </div>,
          )}
        </List>
      </Popover>
    </div>
  );
};

ProjectPopover.propTypes = {
  desktop: bool.isRequired,
  project: object.isRequired,
  currentTab: string.isRequired,
};

export default ProjectPopover;
