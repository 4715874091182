import { arrayOf, object, number, bool, func } from 'prop-types';
import { useDispatch } from 'react-redux';

import { isSameUser } from 'helpers/auth';
import { DIALOG } from 'helpers/constants';
import { openDialog } from 'reducers/dialogReducer';

import TopicList from './TopicList';
import { handleOpenDialogAC3 } from 'reducers/testAC3/cache';

const TopicListContainer = ({
  topics,
  isNewProject,
  showMoreTopics,
  handleAddTopic,
  handleLoadMoreTopics,
  ownerId,
  ...props
}) => {
  const openAddTopicDialog = () => {
    const options = {
      handlePositiveButton: handleAddTopic,
    };

    handleOpenDialogAC3(DIALOG.ADD_TOPIC, options);
  };

  return (
    <TopicList
      topics={topics}
      showMoreTopics={showMoreTopics}
      btnAddTopicVisible={!isNewProject && isSameUser(ownerId)}
      handleAddTopic={openAddTopicDialog}
      handleLoadMoreTopics={handleLoadMoreTopics}
      {...props}
    />
  );
};

TopicListContainer.propTypes = {
  topics: arrayOf(object).isRequired,
  isNewProject: bool.isRequired,
  ownerId: number,
  showMoreTopics: bool.isRequired,
  handleAddTopic: func.isRequired,
  handleLoadMoreTopics: func.isRequired,
};

TopicListContainer.defaultProps = {
  ownerId: undefined,
};

export default TopicListContainer;
