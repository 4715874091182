import { CircularCenterLoader } from 'components';

const withLoading = (Component) => {
  const wrapped = (props) => {
    const { items, loading } = props;
    return items === null && loading ? <CircularCenterLoader height={loading ? 100 : 380} /> : <Component {...props} />;
  };

  return wrapped;
};

export default withLoading;
