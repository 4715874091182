import { createTheme } from '@material-ui/core/styles';

import { typography, palette, text } from './custom';
import { droneRanger, brandonText, brandonTextBold } from './fonts';

export default createTheme({
  ...typography,
  ...palette,
  ...text,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [brandonText, brandonTextBold, droneRanger],
        '*': {
          margin: 0,
        },
        'body, html': {
          height: '100%',
        },
        a: {
          textDecoration: 'none',
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        border: `2px solid #384885`,
        backgroundColor: '#fff',
        color: '#000',
        boxShadow: '1px 1px 3px 0px rgb(0,0,0)',
        fontSize: 10,
      },
    },
  },
});
