import { arrayOf, bool, func, object, string } from 'prop-types';
import { useEffect, useState } from 'react';

import DesktopNavigation from './desktop/DesktopNavigation';
import MobileNavigation from './mobile/MobileNavigation';

const ProfileNavigation = ({
  desktop,
  tabs,
  mobileTabs,
  currentTab,
  setCurrentTab,
  descriptionHidden,
  setDescriptionHidden,
  profile,
  profileType,
  isGuest,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [previousTab, setPreviousTab] = useState(currentTab);

  useEffect(() => {
    if (!desktop) {
      setActiveTab(0);
      setCurrentTab(mobileTabs[0].key);
    }
  }, [desktop]);

  useEffect(() => {
    setPreviousTab(currentTab);
  }, [currentTab]);

  const handleTabSwitch = (tab, index) => {
    setActiveTab(index);
    setCurrentTab(tab);
    if (descriptionHidden && tab !== previousTab) {
      setDescriptionHidden(false);
    }
  };

  return desktop ? (
    <DesktopNavigation
      tabs={tabs}
      activeTab={activeTab}
      handleTabSwitch={handleTabSwitch}
      isGuest={isGuest}
      profileType={profileType}
      profile={profile}
    />
  ) : (
    <MobileNavigation mobileTabs={mobileTabs} activeTab={activeTab} handleTabSwitch={handleTabSwitch} />
  );
};

ProfileNavigation.propTypes = {
  desktop: bool.isRequired,
  tabs: arrayOf(object).isRequired,
  mobileTabs: arrayOf(object).isRequired,
  currentTab: string.isRequired,
  setCurrentTab: func.isRequired,
  profileType: string.isRequired,
  profile: object.isRequired,
  descriptionHidden: bool.isRequired,
  setDescriptionHidden: func.isRequired,
  isGuest: bool.isRequired,
};

export default ProfileNavigation;
